<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar Posto de Combustível</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Nome</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Contato</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.contact }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Telefone</b>
                    </div>
                    <div class="col-md-12">
                      <span *ngIf="gasStation.whatsapp != null && gasStation.whatsapp != '' ">
                        {{
                        gasStation.phone
                        | mask: "(00) 0000-0000||(00) 0 0000-0000"
                        }}
                      </span>
                      <span *ngIf="gasStation.whatsapp == null || gasStation.whatsapp == '' ">
                        Não informado
                      </span>

                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Whatsapp</b>
                    </div>
                    <div class="col-md-12">
                      <span *ngIf="gasStation.whatsapp != null && gasStation.whatsapp != '' ">
                        {{ gasStation.whatsapp | mask: "+00 00 0 0000-0000" }}
                      </span>
                      <span *ngIf="gasStation.whatsapp == null || gasStation.whatsapp == '' ">
                        Não informado
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Email</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.email }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Status</b>
                    </div>
                    <div class="col-md-12">
                      <span *ngIf="gasStation.active" class="label label-sucess label-mini">Ativo</span>
                      <span *ngIf="!gasStation.active" class="label label-danger label-mini">Inativo</span>
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-2">
                  <b>Endereço</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>CEP</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.address?.zipCode }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Logradouro</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.address?.publicPlace }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Bairro</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.address?.neighborhood }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Cidade</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.address?.city.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Estado</b>
                    </div>
                    <div class="col-md-12">
                      {{ gasStation.address?.city.state.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-4">
                  <a routerLink="/parameters/gas-station/edit/{{ gasStation.id }}" class="btn btn-warning">Editar</a>
                </div>
                <div class="col-md-4">
                  <a routerLink="/parameters/gas-station" class="btn btn-info">Voltar</a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card card-topline-green">
                <div class="card-head">
                  <header>Histórico de Créditos</header>
                </div>
                <div class="card-body">
                  <div class="row mb-3" [formGroup]="form">
                    <div class="col-md-9 mt-1">
                      <!-- <label>Valor</label> -->
                      <input type="text" class="form-control" currencyMask [options]="{
                          prefix: 'R$ ',
                          thousands: '.',
                          decimal: ','
                        }" placeholder="Valor" formControlName="credit" />
                    </div>
                    <div class="col-md-3 mt-1">
                      <button class="btn btn-success" (click)="onSubmit()" [disabled]="!form.valid">
                        Adicionar Crédito
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped custom-table table-hover">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Valor</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let credit of credits; let i = index">
                          <td>{{ credit.date | date: "dd/MM/yyyy" }}</td>

                          <td>
                            {{ credit.value | currency: "BRL" }}
                          </td>
                          <td>
                            <button *ngIf="i == 0 && credit.value <= balance" (click)="deleteCredit(credit)"
                              class="btn btn-danger btn-xs">
                              <i class="fa fa-trash-o"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="table-active">
                          <th>Saldo</th>
                          <td>{{ balance | currency: "BRL" }}</td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                    <div class="row">
                      <div class="col-md-12">
                        <span *ngIf="!credits.length" class="label label-danger label-mini">Nenhum Crédito
                          encontrado.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
