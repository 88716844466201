import { CarFuelEnum } from '../enumerations/car-fuel.enum';
import { SupplyTypeEnum } from '../enumerations/supply-type.enum';
import { Driver } from './driver';
import { GasStation } from './gas-station';

export class CarRefuel {
  id: number;
  gasStation: GasStation;
  fuel: CarFuelEnum;
  date: Date;
  hour: string;
  mileage: number;
  numberOfLiters: number;
  value: number;
  invoiceNumber: number;
  active: boolean;
  driver: Driver;
  supplyType: SupplyTypeEnum;
  avg?: number;

  constructor() { }
}
