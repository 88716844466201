<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Corridas da Solicitação #{{ id }}</div>
            </div>
            <div class="col-md-2">
              <!-- <a type="button" routerLink="/process/trip-request/new"
                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Adicionar
                            </a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head" [formGroup]="searchForm">
              <div class="col-md-4 position-right">
                <select
                  (change)="onSelectedResults($event)"
                  formControlName="per_page"
                  class="custom-select custom-select-sm"
                >
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header style="width: 100%">
                <div class="row">
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Início</label>
                    <input
                      type="date"
                      class="form-control"
                      formControlName="dateStart"
                    />
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Fim</label>
                    <input
                      type="date"
                      class="form-control"
                      formControlName="dateEnd"
                    />
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Status</label>
                    <select
                      class="custom-select custom-select"
                      style="font-size: 14px"
                      formControlName="status"
                    >
                      <option value="">Status</option>
                      <option value="scheduled">Agendada</option>
                      <option value="running">Em Andamento</option>
                      <option value="finished">Finalizada</option>
                      <option value="canceled">Cancelada</option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Pagamento</label
                    >
                    <select
                      formControlName="paymentMethod"
                      class="custom-select custom-select"
                      style="font-size: 14px"
                    >
                      <option value="">Método de Pagamento</option>
                      <option
                        *ngFor="let paymentMethod of paymentMethods"
                        value="{{ paymentMethod.id }}"
                      >
                        {{ paymentMethod.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Veículo</label>
                    <select
                      formControlName="vehicle"
                      class="custom-select custom-select col"
                      style="font-size: 14px"
                    >
                      <option value="">Veículo</option>
                      <option *ngFor="let car of cars" value="{{ car.id }}">
                        {{ car.model.name }} - {{ car.carPlate }}
                      </option>
                    </select>
                  </div>

                  <div class="col-sm-3 mt-4">
                    <button (click)="onSearch()" class="btn btn-info">
                      <i class="fa fa-search trip-request__button__search"></i>
                    </button>
                  </div>
                </div>
                <!-- <div class="row">
                                    <div class="col-sm-12 mt-1">
                                        <input type="text" class="form-control"
                                            placeholder="Pesquisar Cliente/Convênio - por nome, CPF ou CNPJ"
                                            formControlName="id">
                                    </div>
                                </div>
                                <div class="row">





                                </div>
                                <div class="row">

                                    <div class="col-sm-3">
                                        <label style="font-size: 14px;" class="mt-2">Motorista</label>
                                        <select class="custom-select custom-select col" style="font-size: 14px;">
                                            <option value="A">Motorista</option>
                                        </select>
                                    </div>

                                    <div class="col-sm-3">
                                        <label style="font-size: 14px;" class="mt-2">Fim</label>
                                        <input type="date" class="form-control" formControlName="enddate">
                                    </div>

                                </div> -->
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Solicitação</th>
                      <th>Data</th>
                      <th>Hora</th>
                      <th>Cliente/Convênio</th>
                      <th>Tipo de Pagamento</th>
                      <!-- <th>Compartilhada</th> -->
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let run of runs">
                      <!-- <td>
                        {{ run.id }}
                      </td> -->
                      <td>#{{ run.runRequest.id }}</td>
                      <td>
                        {{ run.date | date: "dd/MM/yyyy" }}
                      </td>
                      <td>{{ run.startHour }}</td>
                      <td>
                        <span *ngIf="run.runRequest.client">
                          {{ run.runRequest.client.name }}</span
                        >
                        <span *ngIf="run.runRequest.covenant">
                          {{ run.runRequest.covenant.name }}</span
                        >
                      </td>
                      <td>
                        {{ run.runRequest.paymentMethod.name }}
                      </td>
                      <td>
                        {{ run.status }}
                      </td>
                      <td>
                        <div class="row">
                          <a
                            routerLink="/process/runs/{{ run.id }}"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <!-- <a *ngIf="run.status == 'Agendada' && run.runRequest.covenant"
                                                        href="/#" class="btn btn-danger btn-xs" alt="Cancelar Corrida">
                                                        <i class="fa fa-ban"></i>
                                                    </a> -->
                          <!-- <button
                            [swal]="runEditAlert"
                            *ngIf="run.status == 'Agendada'"
                            (click)="setRunEditForm(run.id, run.vehicleId, run.driverId, run.startHour, run.endHour)"
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </button> -->
                          <button
                            *ngIf="run.status == 'Agendada'"
                            (click)="cancelRun(run.id)"
                            class="btn btn-danger btn-xs"
                            alt="Cancelar Corrida"
                          >
                            <i class="fa fa-ban"></i>
                          </button>

                          <!-- <a class="btn btn-success btn-xs">
                                                        <i class="fa fa-random"></i>
                                                    </a> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <swal #runEditAlert title="Editar corrida" (confirm)="updateRun()" [backdrop]="false">
  <form *swalPortal [formGroup]="runEditForm">
    <div class="row">
      <div class="col-sm-12">
        <label>Veículo</label>
        <ng-select class="custom-select custom-select swal2-select"
                   style="font-size: 14px"
                   (change)="getDriversByCar()"
                   formControlName="carId"
                   [items]="cars"
                   bindValue="id"
                   bindLabel="nameCarPlate">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span>{{item.model.name}} - {{item.carPlate}}</span>
          </ng-template>
        </ng-select>
        <span *ngIf="
            runEditForm.controls.carId?.errors &&
            runEditForm.controls.carId?.errors.invalid
          " class="text-danger position-right">*
          {{ runEditForm.controls.carId.errors.invalid }}
        </span>
      </div>
      <div class="col-sm-12">
        <label>Motorista</label>
        <ng-select class="custom-select custom-select swal2-select"
                   style="font-size: 14px"
                   formControlName="driverId"
                   [items]="drivers"
                   bindLabel="name"
                   bindValue="id">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span>{{item.name}}</span>
          </ng-template>
        </ng-select>
        <span *ngIf="
            runEditForm.controls.driverId?.errors &&
            runEditForm.controls.driverId?.errors.invalid
          " class="text-danger position-right">*
          {{ runEditForm.controls.driverId.errors.invalid }}
        </span>
      </div>
      <div class="col-sm-12">
        <label>Hora de Início</label>
        <input formControlName="startHour" class="swal2-input" type="time">
        <span *ngIf="
            runEditForm.controls.startHour?.errors &&
            runEditForm.controls.startHour?.errors.invalid
          " class="text-danger position-right">*
          {{ runEditForm.controls.startHour.errors.invalid }}
        </span>
      </div>
      <div class="col-sm-12">
        <label>Hora de Fim</label>
        <input formControlName="endHour" class="swal2-input" type="time">
        <span *ngIf="
            runEditForm.controls.endHour?.errors &&
            runEditForm.controls.endHour?.errors.invalid
          " class="text-danger position-right">*
          {{ runEditForm.controls.endHour.errors.invalid }}
        </span>
      </div>
    </div>
  </form>

  <ng-container *swalPortal="swalTargets.cancelButton">
    Cancelar
  </ng-container>
  <ng-container *swalPortal="swalTargets.confirmButton">
    Salvar
  </ng-container>
</swal> -->
