<div class="limiter">
   <div class="container-login100 page-background">
      <div class="wrap-login100">
         <div class="login100-form validate-form" [formGroup]="forgotForm">
            <span class="login100-form-logo">
               <img alt="" src="../../assets/img/logo_2.png">
            </span>
            <p class="text-center txt-small-heading">
               Esqueceu sua senha?
            </p>
            <div class="wrap-input100 validate-input" data-validate="Enter username">
               <input class="input100" type="text" name="email" formControlName="email"
                  placeholder="Digite seu email cadastrado">
               <span class="focus-input100" data-placeholder="&#xf207;"></span>
               <span *ngIf="submited && forgotForm.controls.email.errors && forgotForm.controls.email.errors.required"
                  class="text-danger position-right">*Campo
                  Obrigatório</span>
               <span *ngIf="forgotForm.controls.email.errors && forgotForm.controls.email.errors.invalid"
                  class="text-danger position-right">*{{ forgotForm.controls.email.errors.invalid }}</span>

            </div>
            <span *ngIf="send" class="text-primary position-right">Foi enviado para o seu email instruções para você
               recuperar sua
               senha.</span>
            <span *ngIf="forgotForm.controls.email.errors && forgotForm.controls.email.errors.user"
               class="text-danger position-right"> {{ forgotForm.controls.email.errors.user }}</span>

            <div class="container-login100-form-btn">
               <button (click)="onSubmit()" class="login100-form-btn">
                  Enviar
               </button>
            </div>
            <div class="text-center p-t-27">
               <a class="txt1" href="/login">
                  Voltar para login?
               </a>
            </div>
         </div>
      </div>
   </div>
</div>

<script src="../../assets/js/pages/extra_pages/login.js"></script>