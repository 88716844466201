import { Permission } from './permission';

export class AccessProfile {

   id: number;
   name: string;
   description: string;
   active: boolean;
   permissions: Array<Permission> = [];


   constructor() { }
}