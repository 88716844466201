import { Component, OnInit } from '@angular/core';
import { Driver } from 'src/app/domain/driver';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DriverService } from 'src/app/service/driver/driver.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Address } from 'src/app/domain/address';
import { City } from 'src/app/domain/city';
import { State } from 'src/app/domain/state';
import { AddressService } from 'src/app/service/address/address.service';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css'],
})
export class DriverComponent implements OnInit {
  drivers: Array<Driver> = [];
  states: Array<State> = [];
  cities: Array<City> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  driver: Driver = new Driver();
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: DriverService,
    private serviceAddress: AddressService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.driver.address = new Address();
      this.driver.address.city = new City();
      this.driver.address.city.state = new State();

      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.driver.name = params.name ?? '';
      this.driver.cpf = params.cpf ?? '';
      this.driver.cnh = params.cnh ?? '';
      this.driver.active = params.active || null;
      this.driver.address.city.id = params.city || null;
      this.driver.address.city.state.id = params.state || '';
    });
  }

  ngOnInit(): void {
    // this.pagination.per_page = 10;
    this.createForm();
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getStates();
    this.getDrivers();
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      name: [this.driver.name],
      cpf: [this.driver.cpf],
      cnh: [this.driver.cnh],
      state: [this.driver.address.city.state.id],
      city: [this.driver.address.city.id],
      active: [this.driver.active],
    });
  }

  getStates() {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {}
    );
  }

  getDrivers() {
    this.loading = true;
    this.service.getDrivers(this.driver, this.pagination).subscribe(
      (data) => {
        this.drivers = data['results'];
        this.setPagination(data);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
        },
        (error) => {
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os motoristas!', 'error');
  }

  onSearch() {
    this.driver.address = new Address();
    this.driver.address.city = new City();
    this.driver.address.city.state = new State();

    this.driver.name = this.searchForm.controls.name.value;
    this.driver.cpf = this.searchForm.controls.cpf.value;
    this.driver.cnh = this.searchForm.controls.cnh.value;
    this.driver.address.city.id = this.searchForm.controls.city.value;
    this.driver.address.city.state.id = this.searchForm.controls.state.value;

    switch (this.searchForm.controls.active.value) {
      case 'true':
        this.driver.active = true;
        break;
      case 'false':
        this.driver.active = false;
        break;
      default:
        this.driver.active = null;
        break;
    }
    this.setNavigate();
  }

  onResend(driver) {
    this.service.resendEmailSender(driver.email).subscribe(
      (data) => {
        Swal.fire(
          'Sucesso',
          'Email de Ativação enviado com Sucesso!!',
          'success'
        );
      },
      (error) => {
        Swal.fire('Falha', 'Erro ao enviar Email de Ativação!!', 'error');
      }
    );
  }

  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }

  onActive(driver: Driver) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Motorista? ${driver.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(driver.id).subscribe(
          (data) => {
            // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
            Swal.fire({
              title: 'Ativado',
              text: `Motorista ativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao ativar Motorista!', 'error');
          }
        );
      }
    });
  }

  onDeActive(driver: Driver) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Motorista? ${driver.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(driver.id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Desativado',
              text: `Motorista desativada com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao desativar Motorista!', 'error');
          }
        );
      }
    });
  }

  resendActivationInstruction(driver: Driver) {
    this.service.resendActivationInstruction(driver.id).subscribe(
      (data) => {
        Swal.fire({
          title: 'Instruções de ativação',
          text: `Instruções de ativação foram reenviadas para o motorista!`,
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      },
      (error) => {
        Swal.fire(
          'Ops',
          'Erro ao reenviar instruções de ativação ao motorista!',
          'error'
        );
      }
    );
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }

  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }

  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }

  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/maintenance/driver'], {
      queryParams: {
        name: this.driver.name ?? '',
        cpf: this.driver.cpf ?? '',
        cnh: this.driver.cnh ?? '',
        city: this.driver.address.city.id ?? '',
        state: this.driver.address.city.state.id ?? '',
        active: this.driver.active ?? '',
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getDrivers();
  }
}
