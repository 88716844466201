<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<!-- <ngx-loading [show]="loading"> -->
<!-- </ngx-loading> -->

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mt-3">
              <div class="page-title"></div>
            </div>
          </div>
          <div>
            <div class="card card-topline-green">
              <div class="card-head">
                <header>Visualizar Centro de Custo</header>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <b>Identificador</b>
                      </div>
                      <div class="col-md-12">
                        {{ costCenter.identifier }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <b>Nome</b>
                      </div>
                      <div class="col-md-12">
                        {{ costCenter.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <b>Tipo</b>
                      </div>
                      <div class="col-md-12">
                        {{ costCenter.type }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <b>Centro de Custo</b>
                      </div>
                      <div class="col-md-12">
                        <span *ngIf="!costCenter.parent"
                          >Centro de Custo Pai</span
                        >
                        <span span *ngIf="costCenter.parent">{{
                          costCenter.parent.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <b>Status</b>
                      </div>
                      <div class="col-md-12">
                        <span
                          *ngIf="costCenter.active"
                          class="label label-sucess label-mini"
                          >Ativo</span
                        >
                        <span
                          *ngIf="!costCenter.active"
                          class="label label-danger label-mini"
                          >Inativo</span
                        >
                      </div>
                      <div class="col-md-6"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 ml-3 mb-3">
                <div class="col-md-3 text-right">
                  <a href="cost-center/edit/1"
                    ><button class="btn btn-success">Editar</button></a
                  >
                </div>
                <div class="col-md-3">
                  <a routerLink="/financial/cost-center" class="btn btn-danger">Cancelar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
