<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mt-3">
              <div class="page-title">Visualizar Conta a Pagar</div>
            </div>
          </div>
          <div class="card card-topline-green">
            <div class="form-group container">
              <div class="row mt-3">
                <div class="col-sm-4">
                  <label>Fornecedor</label>
                  <p>{{ billsToPay.provider?.name }}</p>
                </div>
                <div class="col-sm-4">
                  <label>Centro de Custo</label>
                  <p>{{ billsToPay.costCenter?.name }}</p>
                </div>
                <div class="col-sm-4">
                  <label>Nota Fiscal / Recibo</label>
                  <p>{{ billsToPay.invoiceNumber }}</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <label>Valor</label>
                  <p>{{ billsToPay.value | currency: "BRL" }}</p>
                </div>
                <div class="col">
                  <label>Valor Pago</label>
                  <p>{{ billsToPay.paidValue | currency: "BRL" }}</p>
                </div>
                <div class="col">
                  <label>Vencimento</label>
                  <p>{{ billsToPay.dueDate | date: "dd/MM/yyyy" }}</p>
                </div>
                <div class="col">
                  <label>Tipo de Pagemento</label>
                  <p>{{ getPaymentMethod(billsToPay.paymentMethod) }}</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4 form-group">
                  <label>Forma de Pagamento</label>
                  <p>
                    {{
                      billsToPay.paymentType == "parceled"
                        ? "Parcelado"
                        : "A Vista"
                    }}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="name">Situação</label>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      disabled
                      id="paid"
                      [checked]="billsToPay.paid"
                    />
                    <label class="form-check-label" for="paid">Pago</label>
                  </div>
                </div>
                <div
                  *ngIf="
                    billsToPay.paymentMethod == 'credit_card' &&
                    billsToPay.creditCard
                  "
                  class="col-sm-4"
                >
                  <label>Cartão de crédito</label>
                  <p>{{ billsToPay.creditCard }}</p>
                </div>
              </div>
              <div
                *ngIf="
                  billsToPay.paymentMethod == 'bank_check' && billsToPay.bank
                "
                class="row mt-3"
              >
                <div class="col-4">
                  <label>Banco</label>
                  <p>{{ billsToPay.bank?.name }}</p>
                </div>
                <div
                  *ngIf="billsToPay.paymentType !== 'parceled'"
                  class="col-4"
                >
                  <label for="name">Nº do cheque</label>
                  <p>{{ billsToPay.bankCheckNumber }}</p>
                </div>
              </div>
              <div
                *ngIf="
                  billsToPay.paymentType === 'parceled' &&
                  billsToPay.parcels?.length
                "
              >
                <div class="row mt-3">
                  <div class="col-4">
                    <label for="parcAmount">Quantidade de Parcelas</label>
                    <p>{{ billsToPay.parcels.length }}</p>
                  </div>
                  <div class="col-4">
                    <label for="name">Periodicidade</label>
                    <p>{{ this.getFrequency(billsToPay) }}</p>
                  </div>
                </div>
              </div>
              <table
                *ngIf="
                  billsToPay.parcels?.length > 1 &&
                  billsToPay.paymentType === 'parceled'
                "
                class="table table-bordered mt-5"
              >
                <thead>
                  <tr>
                    <th scope="col">Nº da Parcela</th>
                    <th
                      *ngIf="billsToPay.paymentMethod == 'bank_check'"
                      scope="col"
                    >
                      Nº do Cheque
                    </th>
                    <th scope="col">Valor da Parcela</th>
                    <th scope="col">Valor Pago</th>
                    <th scope="col">Data Vencimento Parcela</th>
                    <th scope="col">Pago</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let parcel of billsToPay.parcels">
                    <td>{{ parcel.number }}</td>
                    <td *ngIf="billsToPay.paymentMethod == 'bank_check'">
                      {{ parcel.bankCheckNumber }}
                    </td>
                    <td>
                      {{ parcel.value | currency: "BRL" }}
                    </td>
                    <td>
                      {{ parcel.paidValue | currency: "BRL" }}
                    </td>
                    <td>
                      {{ parcel.dueDate | date: "dd/MM/yyyy" }}
                    </td>
                    <td>
                      <input type="checkbox" [checked]="parcel.paid" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mt-3" *ngIf="billsToPay.observation">
                <div class="col">
                  <label>Observações</label>
                  <p>{{ billsToPay.observation }}</p>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-4">
                  <a
                    routerLink="/financial/bills-to-pay/edit/{{ billsToPay.id }}"
                    class="btn btn-warning"
                    >Editar</a
                  >
                </div>
                <div class="col-md-4">
                  <a routerLink="/financial/bills-to-pay" class="btn btn-info">Voltar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
