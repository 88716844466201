import { Component, OnInit } from '@angular/core';
import { Piece } from 'src/app/domain/piece';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PieceService } from 'src/app/service/piece/piece.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-piece',
  templateUrl: './piece.component.html',
  styleUrls: ['./piece.component.css']
})
export class PieceComponent implements OnInit {

  pieces: Array<Piece> = [];
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  piece: Piece = new Piece();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;


  constructor(
    private service: PieceService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {

    this.route.queryParams.subscribe(params => {


      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.piece.name = params.name ?? "";
      this.piece.active = params.active ?? null;

    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getPieces();

  }

  getPieces() {
    this.loading = true;
    this.service.getPieces(this.piece, this.pagination).subscribe(data => {
      this.pieces = data['results'];
      this.setPagination(data);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });
    this.searchForm = this.fb.group({
      name: [this.piece.name],
      active: [this.piece.active]
    });
  }

  onSearch() {
    this.piece.name = this.searchForm.controls.name.value;

    switch (this.searchForm.controls.active.value) {
      case "true":
        this.piece.active = true;
        break;
      case "false":
        this.piece.active = false;
        break;
      default:
        this.piece.active = null;
        break;
    }
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onActive(piece: Piece) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Peça? ${piece.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(piece.id).subscribe(data => {
          // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
          Swal.fire({
            title: 'Ativado',
            text: `Peça ativada com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar Peça!', 'error');
          });
      }

    });
  }
  onDeActive(piece: Piece) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Peça? ${piece.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(piece.id).subscribe(data => {

          Swal.fire({
            title: 'Desativado',
            text: `Peça desativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao desativar Peça!', 'error');
          });
      }

    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();

  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/parameters/piece'], {
      queryParams: {
        name: this.piece.name ?? "",
        active: this.piece.active ?? "",
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10
      }
    });
    this.getPieces();
  }

}
