import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/domain/alert';
import { AlertService } from 'src/app/service/alert/alert.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alert-view',
  templateUrl: './alert-view.component.html',
  styleUrls: ['./alert-view.component.css']
})
export class AlertViewComponent implements OnInit {

  id: number;
  alert: Alert = new Alert();
  loading: boolean = true;

  constructor(
    private service: AlertService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getAlerts();
  }

  getAlerts() {
    this.loading = true;
    this.service.getAlert(this.id).subscribe(data => {
      this.alert = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Fornecedor!', 'error');
  }


}
