import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { map, take } from 'rxjs/operators';
import { Pagination } from 'src/app/domain/pagination';
import { RunService } from 'src/app/service/run/run.service';
import moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Driver } from 'src/app/domain/driver';
import { DriverService } from 'src/app/service/driver/driver.service';
import { CurrencyPipe } from '@angular/common';
import { Covenant } from 'src/app/domain/covenant';
import { CovenantService } from 'src/app/service/covenant/covenant.service';

@Component({
  selector: 'app-chart-amount-runs-by-driver',
  templateUrl: './chart-amount-runs-by-driver.component.html',
  styleUrls: ['./chart-amount-runs-by-driver.component.css'],
})
export class ChartAmountRunsByDriverComponent implements OnInit {
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barLegend = false;
  barChartType: ChartType = 'bar';
  barChartData: ChartData = {
    labels: [],
    datasets: [],
  };
  filterGroup: FormGroup;

  covenants = Array<Covenant>();
  driver: Driver;
  drivers = Array<Driver>();

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  constructor(
    private runService: RunService,
    private fb: FormBuilder,
    private covenantService: CovenantService,
    private serviceDriver: DriverService
  ) {}

  ngOnInit(): void {
    this.createFilter();
    this.getCovenants();
    this.getDrivers();
    this.getRuns();
  }

  createFilter() {
    this.filterGroup = this.fb.group({
      dateStart: [
        moment().startOf('month').format('YYYY-MM-DD'),
        Validators.required,
      ],
      dateEnd: [
        moment().endOf('month').format('YYYY-MM-DD'),
        Validators.required,
      ],
      driverId: ['', Validators.required],
      covenantIds: [[]],
    });
  }

  getDrivers() {
    this.serviceDriver
      .getAllDriversBasic()
      .subscribe((data) => (this.drivers = data));
  }

  getCovenants() {
    this.covenantService
      .getAllCovenants()
      .subscribe((data) => (this.covenants = data));
  }

  setDriver(event) {
    this.driver = event;
  }

  getRuns() {
    if (this.filterGroup.valid) {
      const filters = this.filterGroup.value;
      filters['covenantIds[]'] = filters.covenantIds;
      this.runService
        .getAmountRunsPerDriver(this.filterGroup.value)
        .pipe(take(1))
        .subscribe((runs: any) => {
          runs = runs.sort((a, b) =>
            a['runRequest.covenant.name'].localeCompare(
              b['runRequest.covenant.name']
            )
          );

          this.barChartData = {
            labels: runs.map((run) => [
              run['runRequest.covenant.name'].split(' ')[0],
              new CurrencyPipe('en-US', 'BRL').transform(run.total_amount),
            ]),
            datasets: [
              {
                label: 'Total por convênio',
                backgroundColor: '#fec307',
                data: runs.map((run) =>
                  run.total_amount.toLocaleString('en', {
                    useGrouping: false,
                    minimumFractionDigits: 2,
                  })
                ),
              },
            ],
          };

          this.barLegend = !!this.barChartData.datasets.length;
          this.chart?.update();
        });
    }
  }
}
