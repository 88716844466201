import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-trip-request-view',
  templateUrl: './trip-request-view.component.html',
  styleUrls: ['./trip-request-view.component.css']
})
export class TripRequestViewComponent implements OnInit {

  moment: any = moment;

  id: number;
  loading: boolean = true;
  tripRequest;

  constructor(
    private service: TripRequestService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getTripRequest();
  }

  getTripRequest() {
    this.service.getTripRequest(this.id).subscribe(data => {
      this.tripRequest = data;
      this.loading = false;

    }, error => {
      this.loading = false;
      Swal.fire("Erro", "Não foi possível carregar solicitação!", "error");
    });
  }

}
