import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Bank } from 'src/app/domain/bank';
import { BankService } from 'src/app/service/bank/bank.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bank-update',
  templateUrl: './bank-update.component.html',
  styleUrls: ['./bank-update.component.css'],
})
export class BankUpdateComponent implements OnInit {
  bankForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  bank: Bank = new Bank();

  constructor(
    private service: BankService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.bank.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();

    this.service.getBank(this.bank.id).subscribe(
      (data) => {
        this.bank = data;
        this.setValues();
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar Banco!', 'error');
      }
    );
  }
  createForm() {
    this.bankForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      number: [
        '',
        [Validators.required, Validators.min(0), Validators.max(999)],
      ],
      active: ['', Validators.required],
    });
  }
  setValues() {
    this.bankForm.controls.name.setValue(this.bank.name);
    this.bankForm.controls.number.setValue(this.bank.number);
    this.bankForm.controls.active.setValue(`${this.bank.active}`);
  }

  onSubmit() {
    this.bank.name = this.bankForm.controls.name.value;
    this.bank.number = this.bankForm.controls.number.value;
    if (this.bankForm.controls.active.value == 'true') {
      this.bank.active = true;
    } else {
      this.bank.active = false;
    }
    this.loading = true;
    this.service.update(this.bank).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire('Editado', 'Banco editado com sucesso!!', 'success').then(
          (result) => {
            if (result.value) {
              this.router.navigate(['parameters/bank']);
            }
          }
        );
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Banco!', 'error');
      }
    );
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'name':
          this.bankForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case 'number':
          this.bankForm.controls.number.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }
}
