<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Motorista</div>
            </div>
          </div>
          <div [formGroup]="driverForm">
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-3">
                <div class="driver-image">
                  <div class="al-upload" *ngIf="!cropped" (click)="_fileInput.click()">
                    <div *ngIf="!cropped">
                      <img class="img-responsive" src="assets/img/user/default.png" />
                      <p>Insira uma foto</p>
                    </div>
                  </div>

                  <div *ngIf="cropped">
                    <button class="btn-fechar" (click)="onRemoveImage($event)">
                      <i class="fa fa-times-circle"></i>
                    </button>
                    <img class="img-responsive" [src]="cropped" (click)="_fileInput.click()">
                  </div>

                  <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">Nome</label>
                      <input type="text" class="form-control" placeholder="Nome" formControlName="name" />
                      <span *ngIf="driverForm.controls.name.errors">
                        <span *ngIf="driverForm.controls.name.errors.required" class="text-danger position-right">*Campo
                          Obrigatório</span>
                        <span *ngIf="
                            driverForm.controls.name.errors &&
                            driverForm.controls.name.errors.maxlength
                          " class="text-danger position-right">*Nome deve ter no máximo 100 caracteres</span>
                        <span *ngIf="
                            !driverForm.controls.name.errors.required &&
                            driverForm.controls.name.errors.invalid
                          " class="text-danger position-right">* {{ driverForm.controls.name.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="birthDate">Data de Nascimento</label>
                      <input type="Date" class="form-control" placeholder="Data de Nascimento"
                        formControlName="birthDate" />
                      <span *ngIf="driverForm.controls.birthDate.errors">
                        <span *ngIf="driverForm.controls.birthDate.errors.required"
                          class="text-danger position-right">*Campo Obrigatório</span>
                        <span *ngIf="
                            !driverForm.controls.birthDate.errors.required &&
                            driverForm.controls.birthDate.errors.invalid
                          " class="text-danger position-right">* {{ driverForm.controls.birthDate.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cpf">CPF</label>
                      <input type="text" class="form-control cpf" placeholder="CPF" formControlName="cpf"
                        mask="000.000.000-00" />
                      <span *ngIf="driverForm.controls.cpf.errors">
                        <span *ngIf="driverForm.controls.cpf.errors.required" class="text-danger position-right">*Campo
                          Obrigatório</span>
                        <span *ngIf="
                            !driverForm.controls.cpf.errors.required &&
                            driverForm.controls.cpf.errors.invalid
                          " class="text-danger position-right">* {{ driverForm.controls.cpf.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="rg">RG</label>
                      <input type="text" class="form-control" placeholder="RG" formControlName="rg" />
                      <span *ngIf="driverForm.controls.rg.errors">
                        <span *ngIf="driverForm.controls.rg.errors.required" class="text-danger position-right">*Campo
                          Obrigatório</span>
                        <span *ngIf="
                            driverForm.controls.rg.errors &&
                            driverForm.controls.rg.errors.maxlength
                          " class="text-danger position-right">*RG deve ter no máximo 20 caracteres</span>
                        <span *ngIf="
                            !driverForm.controls.rg.errors.required &&
                            driverForm.controls.rg.errors.invalid
                          " class="text-danger position-right">* {{ driverForm.controls.rg.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="rgShippingSector">Orgão Expedidor</label>
                      <input type="text" class="form-control" placeholder="Órgão Expedidor"
                        formControlName="rgShippingSector" />
                      <span *ngIf="driverForm.controls.rgShippingSector.errors">
                        <span *ngIf="
                            driverForm.controls.rgShippingSector.errors.required
                          " class="text-danger position-right">*Campo Obrigatório</span>
                        <span *ngIf="
                            driverForm.controls.rgShippingSector.errors &&
                            driverForm.controls.rgShippingSector.errors
                              .maxlength
                          " class="text-danger position-right">*Orgão Expedidor deve ter no máximo 20
                          caracteres</span>
                        <span *ngIf="
                            !driverForm.controls.rgShippingSector.errors
                              .required &&
                            driverForm.controls.rgShippingSector.errors.invalid
                          " class="text-danger position-right">*
                          {{
                          driverForm.controls.rgShippingSector.errors.invalid
                          }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row col-md-12 m-0 p-0">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="phone">Telefone</label>
                    <input type="text" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                      placeholder="Telefone" formControlName="phone" />
                    <span *ngIf="driverForm.controls.phone.errors">
                      <span *ngIf="driverForm.controls.phone.errors.required" class="text-danger position-right">*Campo
                        Obrigatório</span>
                      <span *ngIf="
                          !driverForm.controls.phone.errors.required &&
                          driverForm.controls.phone.errors.invalid
                        " class="text-danger position-right">* {{ driverForm.controls.phone.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="whatsapp">Whatsapp</label>
                    <input type="text" class="form-control" mask="+00 00 0 0000-0000" placeholder="WhatsApp"
                      formControlName="whatsapp" />
                    <span *ngIf="driverForm.controls.whatsapp.errors">
                      <span *ngIf="driverForm.controls.whatsapp.errors.required"
                        class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          !driverForm.controls.whatsapp.errors.required &&
                          driverForm.controls.whatsapp.errors.invalid
                        " class="text-danger position-right">* {{ driverForm.controls.whatsapp.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="email">E-mail</label>
                    <input type="text" class="form-control" placeholder="E-mail" formControlName="email" />
                    <span *ngIf="driverForm.controls.email.errors">
                      <span *ngIf="driverForm.controls.email.errors.required" class="text-danger position-right">*Campo
                        Obrigatório</span>
                      <span *ngIf="
                          !driverForm.controls.email.errors.required &&
                          driverForm.controls.email.errors.maxlength
                        " class="text-danger position-right">*Email deve ter no máximo 60 caracteres</span>
                      <span *ngIf="
                          !driverForm.controls.email.errors.maxlength &&
                          driverForm.controls.email.errors.email
                        " class="text-danger position-right">*Não é um email válido!</span>
                      <span *ngIf="
                          !driverForm.controls.email.errors.email &&
                          driverForm.controls.email.errors.invalid
                        " class="text-danger position-right">* {{ driverForm.controls.email.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row col-md-12 m-0 p-0">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cnh">CNH</label>
                    <input type="text" class="form-control" placeholder="CNH" formControlName="cnh" />
                    <span *ngIf="driverForm.controls.cnh.errors">
                      <span *ngIf="driverForm.controls.cnh.errors.required" class="text-danger position-right">*Campo
                        Obrigatório</span>
                      <span *ngIf="
                          driverForm.controls.cnh.errors &&
                          driverForm.controls.cnh.errors.maxlength
                        " class="text-danger position-right">*CNH deve ter no máximo 11 caracteres</span>
                      <span *ngIf="
                          !driverForm.controls.cnh.errors.required &&
                          driverForm.controls.cnh.errors.invalid
                        " class="text-danger position-right">* {{ driverForm.controls.cnh.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cnhCategory">Categoria</label>
                    <select class="form-control" placeholder="Categoria" formControlName="cnhCategory">
                      <option value="">Selecione a Categoria</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="AB">AB</option>
                      <option value="AC">AC</option>
                      <option value="AD">AD</option>
                      <option value="AE">AE</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                    </select>
                    <span *ngIf="driverForm.controls.cnhCategory.errors">
                      <span *ngIf="driverForm.controls.cnhCategory.errors.required"
                        class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          !driverForm.controls.cnhCategory.errors.required &&
                          driverForm.controls.cnhCategory.errors.invalid
                        " class="text-danger position-right">* {{ driverForm.controls.cnhCategory.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cnhValidity">Data de Validade</label>
                    <input type="date" class="form-control" placeholder="Data de Validade"
                      formControlName="cnhValidity" />
                    <span *ngIf="driverForm.controls.cnhValidity.errors">
                      <span *ngIf="driverForm.controls.cnhValidity.errors.required"
                        class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          !driverForm.controls.cnhValidity.errors.required &&
                          driverForm.controls.cnhValidity.errors.invalid
                        " class="text-danger position-right">* {{ driverForm.controls.cnhValidity.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row col-md-12 m-0 p-0">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="zipCode">CEP</label>
                    <input type="text" class="form-control cep" (blur)="onBlurCep($event)" id="zipCode"
                      formControlName="zipCode" placeholder="CEP" />

                    <span *ngIf="
                        driverForm.controls.zipCode.errors &&
                        driverForm.controls.zipCode.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                    <span *ngIf="
                        driverForm.controls.zipCode.errors &&
                        driverForm.controls.zipCode.errors.maxlength
                      " class="text-danger position-right">*CEP deve ter no máximo 11 caracteres</span>
                    <span *ngIf="
                        driverForm.controls.zipCode.errors &&
                        driverForm.controls.zipCode.errors.invalid
                      " class="text-danger position-right">*
                      {{ driverForm.controls.zipCode.errors.invalid }}
                    </span>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="publicPlace">Logradouro</label>
                    <input type="text" class="form-control" id="publicPlace" formControlName="publicPlace"
                      placeholder="Rua / Complemento / Número" />
                    <span *ngIf="
                        driverForm.controls.publicPlace.errors &&
                        driverForm.controls.publicPlace.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                    <span *ngIf="
                        driverForm.controls.publicPlace.errors &&
                        driverForm.controls.publicPlace.errors.invalid
                      " class="text-danger position-right">*
                      {{ driverForm.controls.publicPlace.errors.invalid }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row col-md-12 m-0 p-0">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="neighborhood">Bairro</label>
                    <input type="text" class="form-control" id="" formControlName="neighborhood" placeholder="Bairro" />

                    <span *ngIf="
                        driverForm.controls.neighborhood.errors &&
                        driverForm.controls.neighborhood.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                    <span *ngIf="
                        driverForm.controls.neighborhood.errors &&
                        driverForm.controls.neighborhood.errors.invalid
                      " class="text-danger position-right">*
                      {{ driverForm.controls.neighborhood.errors.invalid }}
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Estado </label>

                    <select (change)="onSelectedState($event)" class="form-control select2" formControlName="state">
                      <option value="">Selecione o Estado</option>
                      <option value="{{ state.id }}" *ngFor="let state of states">
                        {{ state.name }}
                      </option>
                    </select>
                    <span *ngIf="
                        driverForm.controls.state.errors &&
                        driverForm.controls.state.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Cidade </label>
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal; min-width: 230px;"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Cidade"
                      formControlName="city"
                      [items]="cities"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                    <span *ngIf="
                        driverForm.controls.city.errors &&
                        driverForm.controls.city.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <ngx-dropzone (change)="onSelectFile($event)">
                  <ngx-dropzone-label>
                    <div>
                      <span class="material-icons"> insert_drive_file </span>
                    </div>
                    <div>Anexos</div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemoveFile(f)">
                    <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="m-2">
                <button (click)="onSubmit()" [disabled]="!driverForm.valid" class="btn btn-success">
                  Salvar
                </button>
              </div>
              <div class="m-2">
                <a routerLink="/maintenance/driver" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
