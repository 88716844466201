import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Login } from 'src/app/domain/login';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  url: string = `${environment.BASE_URL}`;

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    external: 'true',
  });

  constructor(private http: HttpClient) {}

  login(login: Login) {
    return this.http.post(`${this.url}/login`, JSON.stringify(login), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  forgot(email: string) {
    return this.http.post(
      `${this.url}/reset_password`,
      { email: email },
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
  reset(token: string, password: string) {
    return this.http.put(
      `${this.url}/reset_password/token`,
      { token: token, password: password },
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !jwtHelper.isTokenExpired(token);
  }
}
