import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GasStation } from 'src/app/domain/gas-station';
import { GasStationService } from 'src/app/service/gas-station/gas-station.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ViaCep } from 'src/app/domain/viacep';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { AddressService } from 'src/app/service/address/address.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { Address } from 'src/app/domain/address';
import { Validations } from '../../validations';

@Component({
  selector: 'app-gas-station-update',
  templateUrl: './gas-station-update.component.html',
  styleUrls: ['./gas-station-update.component.css']
})
export class GasStationUpdateComponent implements OnInit {

  gasStationForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  gasStation: GasStation = new GasStation();
  viacep: ViaCep = new ViaCep;
  states: Array<State> = [];
  cities: Array<City> = [];

  constructor(
    private service: GasStationService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.gasStation.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();

    this.serviceAddress.getStates().subscribe(data => {
      this.states = data;

      this.service.getGasStation(this.gasStation.id).subscribe(data => {
        this.gasStation = data;
        this.serviceAddress.getCitiesByStateId(this.gasStation.address.city.state.id).subscribe(data => {
          this.cities = data;
          this.setValues();
        }, error => {
          Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
        });

      }, error => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar Posto de Combustível!', 'error');
      });
    }, error => {
      Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
    });

  }
  createForm() {
    this.gasStationForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(60)]],
      email: ['', [Validators.maxLength(80), Validators.email]],
      phone: ['', [Validations.validPhone]],
      whatsapp: ['', [Validations.validwhatsapp]],
      contact: ['', [Validators.maxLength(100)]],
      zipCode: ['', [Validators.required, Validators.maxLength(9)]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      active: ['', Validators.required]
    });
  }
  setValues() {
    this.gasStationForm.controls.name.setValue(this.gasStation.name);
    this.gasStationForm.controls.email.setValue(this.gasStation.email);
    this.gasStationForm.controls.phone.setValue(this.gasStation.phone);
    this.gasStationForm.controls.whatsapp.setValue(this.gasStation.whatsapp);
    this.gasStationForm.controls.contact.setValue(this.gasStation.contact);
    this.gasStationForm.controls.zipCode.setValue(this.gasStation.address.zipCode);
    this.gasStationForm.controls.neighborhood.setValue(this.gasStation.address.neighborhood);
    this.gasStationForm.controls.publicPlace.setValue(this.gasStation.address.publicPlace);
    this.gasStationForm.controls.state.setValue(this.gasStation.address.city.state.id);
    this.gasStationForm.controls.city.setValue(this.gasStation.address.city.id);
    this.gasStationForm.controls.active.setValue(`${this.gasStation.active}`);
  }

  onSubmit() {
    this.gasStation.address = new Address;
    this.gasStation.address.city = new City;
    this.gasStation.name = this.gasStationForm.controls.name.value;
    this.gasStation.email = this.gasStationForm.controls.email.value;
    this.gasStation.phone = this.gasStationForm.controls.phone.value;
    this.gasStation.whatsapp = this.gasStationForm.controls.whatsapp.value;
    this.gasStation.contact = this.gasStationForm.controls.contact.value;
    this.gasStation.address.zipCode = this.gasStationForm.controls.zipCode.value;
    this.gasStation.address.neighborhood = this.gasStationForm.controls.neighborhood.value;
    this.gasStation.address.publicPlace = this.gasStationForm.controls.publicPlace.value;
    this.gasStation.address.city.id = this.gasStationForm.controls.city.value;
    this.gasStation.address.city.state = this.gasStationForm.controls.state.value;


    if (this.gasStationForm.controls.active.value == "true") {
      this.gasStation.active = true
    } else {
      this.gasStation.active = false;
    }
    this.loading = true;
    this.service.update(this.gasStation).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Posto de Combustível editado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/gas-station']);
        }
      });
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Posto de Combustível!', 'error');
      });
  }
  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, "").length === 8) {
      this.utilsService.getCep(event.target.value.replace(/[^0-9,.]+/g, "")).subscribe(data => {
        this.viacep = data;

        if (!this.viacep.erro) {
          this.gasStationForm.controls.neighborhood.setValue(this.viacep.bairro);
          this.gasStationForm.controls.publicPlace.setValue(this.viacep.logradouro);
          const state = this.states.find(state => state.initials === this.viacep.uf.toUpperCase());

          this.gasStationForm.controls.state.setValue(state.id);

          // TODO - Implementar busca de cidades por UF
          this.serviceAddress.getCitiesByStateId(state.id).subscribe(data => {
            this.cities = data;
            const city = this.cities.find(elem => elem.name.normalize("NFD") === this.viacep.localidade.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ''));
            if (city) {
              this.gasStationForm.controls.city.setValue(city.id);
            }
          }, error => {
            Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
          });

        } else {
          this.gasStationForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        }
        this.loading = false;
      }, erro => {
        this.gasStationForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.gasStationForm.controls.zipCode.setErrors({ invalid: "CEP inválido!" });
    }
  }
  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(data => {
        this.cities = data;
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
      });
    }
  }
  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "name":
          this.gasStationForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "email":
          this.gasStationForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "phone":
          this.gasStationForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case "whatsapp":
          this.gasStationForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "contact":
          this.gasStationForm.controls.contact.setErrors({ invalid: erro.message });
          break;
        case "zipCode":
          this.gasStationForm.controls.zipCode.setErrors({ invalid: erro.message });
          break;
        case "neighborhood":
          this.gasStationForm.controls.neighborhood.setErrors({ invalid: erro.message });
          break;
        case "publicPlace":
          this.gasStationForm.controls.publicPlace.setErrors({ invalid: erro.message });
          break;
        case "state":
          this.gasStationForm.controls.state.setErrors({ invalid: erro.message });
          break;
        case "city":
          this.gasStationForm.controls.city.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;

      }
    });

  }

}
