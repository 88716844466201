<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title">Cadastrar Fornecedor </div>
                        </div>
                    </div>
                    <div [formGroup]="providerForm">
                        <!--div com o form -->
                        <div *ngIf="messageError.length" class="container">
                            <div class="alert alert-danger" role="alert">
                                {{messageError}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control" id="name" formControlName="name"
                                        placeholder="Nome do Fornecedor">
                                    <span
                                        *ngIf="providerForm.controls.name.errors && providerForm.controls.name.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="providerForm.controls.name.errors && providerForm.controls.name.errors.maxlength"
                                        class="text-danger position-right">*Nome do
                                        Perfil deve ter
                                        no máximo 100 caracteres</span>
                                    <span
                                        *ngIf="providerForm.controls.name.errors && providerForm.controls.name.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.name.errors.invalid}} </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="cpfCnpj">CPF / CNPJ</label>
                                    <input type="text" class="form-control" mask="CPF_CNPJ" id="cpfCnpj"
                                        formControlName="cpfCnpj" placeholder="CPF/CNPJ do Fornecedor">
                                    <span *ngIf="providerForm.controls.cpfCnpj.errors">
                                        <span *ngIf="providerForm.controls.cpfCnpj.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="!providerForm.controls.cpfCnpj.errors.required && providerForm.controls.cpfCnpj.errors.invalid"
                                            class="text-danger position-right">*
                                            {{providerForm.controls.cpfCnpj.errors.invalid}} </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="stateRegistration">Inscrição Estadual</label>
                                    <input type="text" class="form-control" id="stateRegistration"
                                        formControlName="stateRegistration" placeholder="Inscrição Estadual">
                                    <span
                                        *ngIf="providerForm.controls.stateRegistration.errors && providerForm.controls.stateRegistration.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <!-- <span
                                        *ngIf="providerForm.controls.name.errors && providerForm.controls.name.errors.maxlength"
                                        class="text-danger position-right">*Nome do
                                        Perfil deve ter
                                        no máximo 100 caracteres</span> -->
                                    <span
                                        *ngIf="providerForm.controls.stateRegistration.errors && providerForm.controls.stateRegistration.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.stateRegistration.errors.invalid}} </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="municipalRegistration">Inscrição Municipal</label>
                                    <input type="text" class="form-control" id="municipalRegistration"
                                        formControlName="municipalRegistration" placeholder="Inscrição Municipal">
                                    <span
                                        *ngIf="providerForm.controls.municipalRegistration.errors && providerForm.controls.municipalRegistration.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <!-- <span
                                        *ngIf="providerForm.controls.municipalRegistration.errors && providerForm.controls.municipalRegistration.errors.maxlength"
                                        class="text-danger position-right">*CPF / CNPJ
                                        máximo 14 números</span>
                                    <span
                                        *ngIf="providerForm.controls.municipalRegistration.errors && providerForm.controls.municipalRegistration.errors.minlength"
                                        class="text-danger position-right">*CPF / CNPJ
                                        mínimo 11 números</span> -->
                                    <span
                                        *ngIf="providerForm.controls.municipalRegistration.errors && providerForm.controls.municipalRegistration.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.municipalRegistration.errors.invalid}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" id="email" formControlName="email"
                                        placeholder="Email">
                                    <span
                                        *ngIf="providerForm.controls.email.errors && providerForm.controls.email.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="providerForm.controls.email.errors && providerForm.controls.email.errors.email"
                                        class="text-danger position-right">*Não é um email válido!</span>
                                    <span
                                        *ngIf="providerForm.controls.email.errors && providerForm.controls.email.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.email.errors.invalid}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="contact">Contato</label>
                                    <input type="text" class="form-control" id="contact" formControlName="contact"
                                        placeholder="Nome Contato">
                                    <span
                                        *ngIf="providerForm.controls.contact.errors && providerForm.controls.contact.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <!-- <span
                                        *ngIf="providerForm.controls.contact.errors && providerForm.controls.contact.errors.maxlength"
                                        class="text-danger position-right">*CPF / CNPJ
                                        máximo 14 números</span>
                                    <span
                                        *ngIf="providerForm.controls.contact.errors && providerForm.controls.contact.errors.minlength"
                                        class="text-danger position-right">*CPF / CNPJ
                                        mínimo 11 números</span> -->
                                    <span
                                        *ngIf="providerForm.controls.contact.errors && providerForm.controls.contact.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.contact.errors.invalid}} </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="whatsapp">WhatsApp</label>
                                    <input type="text" class="form-control" mask="+00 00 0 0000-0000" id="whatsapp"
                                        formControlName="whatsapp" placeholder="WhatsApp do Fornecedor">

                                    <span
                                        *ngIf="providerForm.controls.whatsapp.errors && providerForm.controls.whatsapp.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="providerForm.controls.whatsapp.errors && providerForm.controls.whatsapp.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.whatsapp.errors.invalid}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="phone">Telefone</label>
                                    <input type="text" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                                        id="phone" formControlName="phone" placeholder="Telefone do Fornecedor">
                                    <span
                                        *ngIf="providerForm.controls.phone.errors && providerForm.controls.phone.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="providerForm.controls.phone.errors && providerForm.controls.phone.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.phone.errors.invalid}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="zipCode">CEP</label>
                                    <input type="text" class="form-control cep" (blur)="onBlurCep($event)" id="zipCode"
                                        formControlName="zipCode" placeholder="CEP">

                                    <span
                                        *ngIf="providerForm.controls.zipCode.errors && providerForm.controls.zipCode.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="providerForm.controls.zipCode.errors && providerForm.controls.zipCode.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.zipCode.errors.invalid}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="publicPlace">Logradouro</label>
                                    <input type="text" class="form-control" id="publicPlace"
                                        formControlName="publicPlace" placeholder="Rua / Complemento / Número">
                                    <span
                                        *ngIf="providerForm.controls.publicPlace.errors && providerForm.controls.publicPlace.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="providerForm.controls.publicPlace.errors && providerForm.controls.publicPlace.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.publicPlace.errors.invalid}}
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="neighborhood">Bairro</label>
                                    <input type="text" class="form-control" id="neighborhood"
                                        formControlName="neighborhood" placeholder="Bairro">

                                    <span
                                        *ngIf="providerForm.controls.neighborhood.errors && providerForm.controls.neighborhood.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="providerForm.controls.neighborhood.errors && providerForm.controls.neighborhood.errors.invalid"
                                        class="text-danger position-right">*
                                        {{providerForm.controls.neighborhood.errors.invalid}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Estado
                                    </label>

                                    <select class="form-control  select2" formControlName="state">
                                        <option value="">
                                            Selecione o Estado </option>
                                        <option value="{{ state.id }}" *ngFor="let state of states">
                                            {{ state.name }} </option>

                                    </select>
                                    <span
                                        *ngIf="providerForm.controls.state.errors && providerForm.controls.state.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Cidade
                                    </label>
                                    <select class="form-control  select2" formControlName="city">
                                        <option value="">
                                            Selecione a Cidade </option>
                                        <option value="{{ city.id }}" *ngFor="let city of cities">
                                            {{ city.name }} </option>

                                    </select>
                                    <span
                                        *ngIf="providerForm.controls.city.errors && providerForm.controls.city.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="active">Status</label>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="true" value="true" name="active"
                                            formControlName="active" class="custom-control-input">
                                        <label class="custom-control-label" for="true">Ativo</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="false" value="false" name="active"
                                            formControlName="active" class="custom-control-input">
                                        <label class="custom-control-label" for="false">Inativo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-3 text-right">
                                <button (click)="onSubmit()" [disabled]="!providerForm.valid"
                                    class="btn btn-success">Salvar</button>
                            </div>
                            <div class="col-md-3">
                                <a routerLink="/parameters/provider" class="btn btn-danger">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
