import { Driver } from './driver';

export class CarFine {
  id: number;
  driver: Driver;
  value: number;
  discountDriver: boolean;
  discounted: boolean;
  type: string;
  date: Date;
  active: boolean;

  constructor() {}
}
