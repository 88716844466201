import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Car } from 'src/app/domain/car';
import { Driver } from 'src/app/domain/driver';
import { CarService } from 'src/app/service/car/car.service';
import { CovenantRunsService } from 'src/app/service/covenant-runs/covenant-runs.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-run-link-vehicle',
  templateUrl: './covenant-run-link-vehicle.component.html',
  styleUrls: ['./covenant-run-link-vehicle.component.css']
})
export class CovenantRunLinkVehicleComponent implements OnInit {
  runId: number;
  loading: boolean;
  linkForm: FormGroup;
  vehicles: Array<Car> = [];
  data;
  drivers: Array<Driver> = [];

  constructor(
    private service: CovenantRunsService,
    private serviceCar: CarService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.runId = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getCars();
    this.service.getRun(this.runId).subscribe(data => {
      this.data = data;

      if (this.data.status != 'scheduled') {
        Swal.fire('Atenção!!', 'Só podem Vincular Motoristas de Veículos com Corridas com Status de Agendadas!!', 'warning');
        this.router.navigate(['convenio']);
      }

      if (this.data.vehicle.id) {
        this.linkForm.controls.vehicle.setValue(this.data.vehicle.id);
        this.linkForm.controls.driver.setValue(this.data.driver.id);
        this.getDriversByCar();
      }



    });
  }

  getCars() {
    this.serviceCar.getCarsActives().subscribe(data => {
      this.vehicles = data;

    }, error => {
      Swal.fire('Erro', 'Erro ao carregar Veículos', 'error');
    });
  }

  createForm() {
    this.linkForm = this.fb.group({
      vehicle: ['', Validators.required],
      driver: ['', Validators.required]
    });
  }

  onSelectedVehicle(e) {
    if (this.linkForm.controls.vehicle.value.length) {
      this.loading = true;
      this.getDriversByCar();
    }
  }

  getDriversByCar() {
    this.serviceCar.getDriversByCar(this.linkForm.controls.vehicle.value).subscribe(data => {
      this.drivers = data;
      this.loading = false;
    }, error => {
      this.loading = false;
      Swal.fire('Erro', 'Não foi possível carregar os motoristas deste veículo! Selecione o Veículo novamente.', 'error');
    });
  }

  onSubmit() {

    this.loading = true;

    const value = {
      vehicleId: this.linkForm.controls.vehicle.value,
      driverId: this.linkForm.controls.driver.value
    };

    this.service.update(this.runId, value).subscribe(data => {

      this.loading = false;
      Swal.fire('Sucesso', 'Motorista Adicionado à Solicitação', 'success');
      this.router.navigate(['convenio']);
    }, error => {

      this.loading = false;
      Swal.fire('Erro', 'Erro ao adicionar Motorista à Solicitação', 'error');
    });


  }

}
