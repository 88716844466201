import { RequestValue } from '../request-value';
import { Time } from '@angular/common';
import { EnumRequestValueType } from 'src/app/enumerations/request-value-type';
import { DestinyPoint } from '../destiny-point';

export class RequestValueDTO {
  id: number
  type: EnumRequestValueType | string; // Cliente ou Convênio
  destiny: string;
  value: number;
  initialHour: Time;
  endHour: Time;
  aditionalValue: number; // Valor adicional de corrida
  tollAmount?: number; // Valor Pedágio
  valueOriginPoint: number
  percentage: number; // Comissão do Motorista
  percentagePenalty: number;
  destinyPoints: Array<DestinyPoint> = [];
  active: boolean;


  constructor(requestValue: RequestValue) {
    this.id = requestValue.id;
    this.type = requestValue.type;
    this.destiny = requestValue.destiny;
    this.value = requestValue.value;
    this.initialHour = requestValue.initialHour;
    this.endHour = requestValue.endHour;
    this.aditionalValue = requestValue.aditionalValue;
    this.tollAmount = requestValue.tollAmount;
    this.valueOriginPoint = requestValue.valueOriginPoint;
    this.percentage = requestValue.percentage;
    this.percentagePenalty = requestValue.percentagePenalty;
    this.destinyPoints = requestValue.destinyPoints;
    this.active = requestValue.active;
  }
}
