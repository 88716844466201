<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Conta Bancária</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/financial/account-bank/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card card-topline-green">
              <div class="card-head">
                <div class="col-md-3 position-right">
                  <select
                    class="custom-select custom-select-sm"
                    (change)="onSelected($event)"
                  >
                    <option value="10" selected>Mostrar 10 resultados</option>
                    <option value="20">Mostrar 20 resultados</option>
                    <option value="30">Mostrar 30 resultados</option>
                    <option value="{{ pagination.totalResults }}">
                      Mostrar Todos os Resultados
                    </option>
                  </select>
                </div>

                <header>
                  <div class="row" [formGroup]="searchForm">
                    <div class="col-12 col-md-6 col-lg mt-2 ">
                      <ng-select
                        class="custom-select"
                        style="font-size: 1rem; font-weight: normal; min-width: 230px;"
                        notFoundText="Nenhum item encontrado"
                        placeholder="Banco"
                        formControlName="bank"
                        [items]="banks"
                        bindLabel="name"
                        bindValue="id"
                        >
                      </ng-select>
                    </div>
                    <div class="col-12 col-md-6 col-lg mt-2" style="min-width: 180px;">
                      <select formControlName="type" class="form-control">
                        <option value="">Tipo da Conta</option>
                        <option value="poupanca">Poupança</option>
                        <option value="investimento">Investimento</option>
                        <option value="corrente">Corrente</option>
                      </select>
                    </div>
                    <div class="col-12 col-md-6 col-lg mt-3" style="min-width: 210px;">

                      <input
                        type="checkbox"
                        id="rp"
                        formControlName="receivePayments"
                      />
                      <small class="ml-1 text-nowrap">Recebe pagamento?</small>
                    </div>

                    <div class="col-12 col-md-6 col-lg mt-2" style="min-width: 130px;">
                      <select formControlName="active" class="form-control">
                        <option value="">Status</option>
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                      </select>
                    </div>

                    <div class="col-12 col-md-6 col-lg mt-2">
                      <button
                        type="button"
                        type="submit"
                        (click)="onSearch()"
                        class="btn btn-primary"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </header>
              </div>

              <div class="row col-12 m-0">
                <div class="col-12 mt-4">
                  <table class="col-12 table table-striped table-hover p-0">
                    <thead>
                      <tr>
                        <th>Banco</th>
                        <th>Agência</th>
                        <th>Conta Bancária</th>
                        <th>Tipo</th>
                        <th>Status</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let bankAccount of bankAccounts">
                        <td>{{ bankAccount.bank.name }}</td>
                        <td>{{ bankAccount.agency }}</td>
                        <td>{{ bankAccount.bankAccount }}</td>
                        <td>{{ bankAccountType[bankAccount.type] }}</td>
                        <td>{{ bankAccount.active ? "Ativo" : "Inativo" }}</td>
                        <td>
                          <a
                            routerLink="/financial/account-bank/view/{{ bankAccount.id }}"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <a
                            routerLink="/financial/account-bank/edit/{{ bankAccount.id }}"
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                          <span *ngIf="bankAccount.active">
                            <button
                              (click)="activeBankAccount(bankAccount)"
                              class="btn btn-pink btn-xs"
                            >
                              <i class="fa fa-random"></i>
                            </button>
                          </span>
                          <span *ngIf="!bankAccount.active">
                            <button
                              (click)="inactiveBankAccount(bankAccount)"
                              class="btn btn-success btn-xs"
                            >
                              <i class="fa fa-random"></i>
                            </button>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="col-md-12">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination justify-content-center">
                        <li class="page-item" [class.disabled]="!first">
                          <button
                            class="page-link"
                            (click)="onFirst()"
                            tabindex="-1"
                          >
                            Primeira
                          </button>
                        </li>
                        <li class="page-item" *ngIf="pagination.page != 1">
                          <button (click)="onPrevious()" class="page-link">
                            {{ pagination.page - 1 }}
                          </button>
                        </li>
                        <li class="page-item active">
                          <button class="page-link">
                            {{ pagination.page }}
                          </button>
                        </li>
                        <li class="page-item" *ngIf="last">
                          <button (click)="onNext()" class="page-link" href="#">
                            {{ pagination.page + 1 }}
                          </button>
                        </li>
                        <li class="page-item" [class.disabled]="!last">
                          <button class="page-link" (click)="onLast()">
                            Última
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="col-md-12">
                    Mostrando página {{ pagination.page }} de
                    {{ pagination.totalPages }} dos
                    {{ pagination.totalResults }} Resultados
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
