import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { RunService } from 'src/app/service/run/run.service';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.css'],
})
export class CashFlowComponent implements OnInit {
  cashFlow: any;
  form: FormGroup;
  constructor(private runService: RunService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();
    this.getCashFlow();
  }

  createForm() {
    this.form = this.fb.group({
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
    });
  }

  getCashFlow() {
    if (this.form.valid) {
      this.runService
        .getCashFlow(this.form.value)
        .pipe(take(1))
        .subscribe((data: any) => {
          this.cashFlow = data;
        });
    }
  }

  exportCashFlow() {
    if (this.form.valid) {
      this.runService
        .getCashFlowExport(this.form.value)
        .pipe(take(1))
        .subscribe((response) => {
          const fileName = `Fluxo_Caixa${moment().format('DDMMyyyy')}.pdf`;
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(response.body);
          link.download = fileName;
          link.click();
        });
    }
  }
}
