import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CovenantTripRequestService } from 'src/app/service/covenant-trip-request/covenant-trip-request.service';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-trip-request-cancel',
  templateUrl: './covenant-trip-request-cancel.component.html',
  styleUrls: ['./covenant-trip-request-cancel.component.css'],
})
export class CovenantTripRequestCancelComponent implements OnInit {
  solicitationId: number;
  loading: boolean;
  cancelForm: FormGroup;
  data;

  constructor(
    private service: TripRequestService,
    private serviceCovenant: CovenantTripRequestService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.solicitationId = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.service.getTripRequest(this.solicitationId).subscribe((data) => {
      this.data = data;

      if (this.data.status != 'waiting') {
        Swal.fire(
          'Atenção!!',
          'Só podem cancelar Solicitações com Status de Aguardando!!',
          'warning'
        );
        this.router.navigate(['convenio/covenant-trip-request-list']);
      }
    });
  }

  createForm() {
    this.cancelForm = this.fb.group({
      motive: ['', [Validators.required, Validators.maxLength(80)]],
      description: ['', Validators.required],
    });
  }

  onSubmit() {
    this.loading = true;
    const { controls } = this.cancelForm;

    const data = {
      motive: controls.motive.value,
      description: controls.description.value,
    };

    this.serviceCovenant.cancel(data, this.solicitationId).subscribe(
      (data) => {
        Swal.fire(
          'Cancelado',
          'Agendamento de Corrida Cancelada com Sucesso!!',
          'success'
        );
        this.loading = false;
        this.router.navigate(['convenio/covenant-trip-request-list']);
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Erro ao Cancelar Agendamento de Corrida!!', 'error');
      }
    );
  }
}
