<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Veículo</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/maintenance/car/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ngx-loading [show]="loading"> </ngx-loading>
      </div>
      <div class="container"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <div [formGroup]="showResultsForm">
                  <select
                    (change)="onSelected($event)"
                    formControlName="per_page"
                    class="custom-select custom-select-sm"
                  >
                    <option value="10">Mostrar 10 Resultados</option>
                    <option value="20">Mostrar 20 Resultados</option>
                    <option value="30">Mostrar 30 Resultados</option>
                    <option value="{{ pagination.totalResults }}">
                      Mostrar Todos os Resultados
                    </option>
                  </select>
                </div>
              </div>
              <header>
                <div class="row" [formGroup]="searchForm">
                  <!-- <div class="col-12 col-md-6 col-lg mt-2">Pesquisar</div> -->
                  <div class="col-3 mt-2">
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Marca"
                      formControlName="marca"
                      [items]="vehicleBrands"
                      bindLabel="name"
                      bindValue="id"
                      (change)="onSelectedBrand($event)"
                    >
                    </ng-select>
                  </div>
                  <div class="col-3 mt-2">
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Modelo"
                      formControlName="modelo"
                      [items]="vehicleModels"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                  </div>
                  <div class="col-2 mt-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Placa"
                      formControlName="placa"
                    />
                  </div>
                  <div class="col-3 mt-2">
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Vencimento IPVA"
                      formControlName="ipva"
                    />
                  </div>
                  <div class="col-1 mt-2">
                    <!-- <span class="input-group-btn search-btn"> -->
                    <a (click)="onSearch()" class="btn btn-info">
                      <i class="fa fa-search"></i>
                    </a>
                    <!-- </span> -->
                  </div>

                  <div class="col-md-1"></div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Marca</th>
                      <th>Modelo</th>
                      <th>Ano</th>
                      <th>Motor</th>
                      <th>Placa</th>
                      <th>Vencimento IPVA</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let car of cars">
                      <td>{{ car.model.brand.name }}</td>
                      <td>{{ car.model.name }}</td>
                      <td>{{ car.year }}</td>
                      <td>{{ car.motor.name }}</td>
                      <td>{{ car.carPlate }}</td>
                      <td>{{ car.expirationDateIpva | date: "dd/MM/yyyy" }}</td>

                      <td>
                        <a
                          routerLink="/maintenance/car/view/{{ car.id }}"
                          class="btn btn-primary btn-xs"
                          title="Visualizar"
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                        <a
                          routerLink="/maintenance/car/edit/{{ car.id }}"
                          class="btn btn-warning btn-xs"
                          title="Editar"
                        >
                          <i class="fa fa-pencil"></i>
                        </a>
                        <span *ngIf="car.active">
                          <button
                            (click)="onDeActive(car)"
                            class="btn btn-pink btn-xs"
                            title="Desativar"
                          >
                            <i class="fa fa-random"></i>
                          </button>
                        </span>
                        <span *ngIf="!car.active">
                          <button
                            (click)="onActive(car)"
                            class="btn btn-success btn-xs"
                            title="Ativar"
                          >
                            <i class="fa fa-random"></i>
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-12">
                    <span
                      *ngIf="!cars.length"
                      class="label label-danger label-mini"
                      >Nenhum Veículo encontrado.</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page || 0 }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
