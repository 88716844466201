import { Provider } from './provider';
import { CostCenter } from './cost-center';
import { Bank } from './bank';
import { CreditCard } from './credit-card';

export class BillsToPay {
  id: number;
  provider: Provider;
  costCenter: CostCenter;
  bank: Bank;
  creditCard: CreditCard;
  paymentMethod: string;
  invoiceNumber: string;
  value: number;
  paidValue: number;
  dueDate: Date;
  paymentType: string;
  paid: boolean;
  parcAmount: string;
  frequency: string;
  bankCheckNumber: string;
  parcels: any[];
  observation: string;
}
