import { RequestValue } from '../request-value';
import { Time } from '@angular/common';
import { CovenantRequestValue } from '../covenant-request-value';
import { RequestValueDTO } from './resquest-value.dto';
import { request } from 'http';

export class CovenantRequestValueDTO {
    destiny: string;
    value: number;
    aditionalValue: number;
    tollAmount: number;
    percentage: number;
    initialHour: Time;
    endHour: Time;
    validity: string;
    requestValue: RequestValueDTO;

    constructor(requestValue: CovenantRequestValue) {
        this.destiny = requestValue.destiny;
        this.value = requestValue.value;
        this.aditionalValue = requestValue.aditionalValue;
        this.tollAmount = requestValue.tollAmount;
        this.percentage = requestValue.percentage;
        this.initialHour = requestValue.initialHour;
        this.endHour = requestValue.endHour;
        this.validity = requestValue.validity;
        this.requestValue = new RequestValueDTO(requestValue.requestValue);

    }
}