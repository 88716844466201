<ngx-loading [show]="loading">
</ngx-loading>
<div class="limiter">
    <div class="container-login100 page-background">
        <div class="wrap-login100">
            <div class="login100-form validate-form" [formGroup]="activeForm">
                <span class="login100-form-logo">
                    <img alt="" src="assets/img/logo_2.png">
                </span>
                <span class="login100-form-title p-b-34 p-t-27">
                    Ativar Cadastro do Cliente
                </span>
                <div *ngIf="activeForm.controls.password.errors && activeForm.controls.password.errors.token"
                    class="text-center text-danger p-t-5"> {{ activeForm.controls.password.errors.token }} </div>


                <div class="wrap-input100 validate-input" data-validate="Enter password">
                    <input class="input100" type="password" name="password" formControlName="password"
                        placeholder="Nova Senha">
                    <span class="focus-input100" data-placeholder="&#xf191;"></span>
                    <span *ngIf="activeForm.controls.password.errors">
                        <span *ngIf="activeForm.controls.password.errors.required"
                            class="text-danger position-right">*Campo
                            Obrigatório</span>
                        <span *ngIf="activeForm.controls.password.errors.minlength"
                            class="text-danger position-right">*Senha deve ter no mínimo 8 caracteres</span>
                        <span
                            *ngIf="!activeForm.controls.password.errors.required && !activeForm.controls.password.errors.minlength && activeForm.controls.password.errors.invalidPassword"
                            class="text-danger position-right">*Senha deve ter letras maiúsculas e minúsculas e
                            números</span>
                    </span>
                </div>
                <div class="wrap-input100 validate-input" data-validate="Enter password">
                    <input class="input100" type="password" name="confirmationPassword"
                        formControlName="confirmationPassword" placeholder="Confirme a Nova Senha">
                    <span class="focus-input100" data-placeholder="&#xf191;"></span>
                    <span
                        *ngIf="activeForm.controls.confirmationPassword.errors && activeForm.controls.confirmationPassword.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                </div>
                <span
                    *ngIf="activeForm.controls.confirmationPassword.errors && activeForm.controls.confirmationPassword.errors.differentPasswords"
                    class="text-danger position-right">*Senhas não coincidem!</span>
                <!-- <div class="contact100-form-checkbox">
               <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
               <label class="label-checkbox100" for="ckb1">
                  Lembrar-me
               </label>
            </div> -->

                <div class="container-login100-form-btn">
                    <button (click)="onSubmit()" class="login100-form-btn">
                        Enviar
                    </button>
                </div>

                <!-- <div class="text-center p-t-50">
                    <button class="txt1" (click)="resendToken()">
                        Solicitar email de ativação novamente!
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- <script src="../../assets/js/pages/extra_pages/login.js"></script> -->