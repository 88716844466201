import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DriverService } from 'src/app/service/driver/driver.service';
import { Driver } from 'src/app/domain/driver';
import Swal from 'sweetalert2';
import { Address } from 'src/app/domain/address';
import { City } from 'src/app/domain/city';
import { ViaCep } from 'src/app/domain/viacep';
import { State } from 'src/app/domain/state';
import { AddressService } from 'src/app/service/address/address.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { Router } from '@angular/router';
import { Validations } from '../../validations';

import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from '../../cropper/cropper-dialog';

@Component({
  selector: 'app-driver-create',
  templateUrl: './driver-create.component.html',
  styleUrls: ['./driver-create.component.scss'],
})
export class DriverCreateComponent implements OnInit {
  driverForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];
  files: File[] = [];

  image: File;
  cropped?: string;

  constructor(
    private service: DriverService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private router: Router,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  createForm() {
    this.driverForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      cpf: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(60)],
      ],
      whatsapp: ['', [Validators.required, Validations.validwhatsapp]],
      phone: ['', [Validators.required, Validations.validPhone]],
      rg: ['', [Validators.required, Validators.maxLength(20)]],
      rgShippingSector: ['', [Validators.required, Validators.maxLength(20)]],
      cnh: ['', [Validators.required, Validators.maxLength(11)]],
      cnhCategory: ['', [Validators.required]],
      cnhValidity: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: [null, [Validators.required]],
      imageUrl: [''],
    });
  }

  onRemoveImage(event) {
    this.cropped = '';
    this.image = null;
    this.driverForm.controls.imageUrl.reset('');
  }

  onSelectImage(file) {
    if (file.size > 10000000) {
      this.image = null;
      Swal.fire('Ops...', 'A imagem não pode ser maior que 10MB!', 'error');
      return false;
    } else {
      this.image = file;
      this.onFileReader();
      return true;
    }
  }

  onFileReader() {
    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () =>
      this.driverForm.controls.imageUrl.setValue(reader.result);
  }

  onRemoveFile(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onSelectFile(event) {
    const files: File[] = event.addedFiles;

    files.forEach((file, i) => {
      if (file.size > 15000000) {
        Swal.fire('Ops...', 'O arquivo não pode ser maior que 15MB!', 'error');
      } else if (i > 5) {
        Swal.fire(
          'Ops...',
          'Número máximo de arquivos permitidos é 6!',
          'error'
        );
        return;
      } else {
        this.files.push(file);
      }
    });
  }

  onImportFile() {}

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            this.viacep = data;

            if (!this.viacep.erro) {
              this.driverForm.controls.neighborhood.setValue(
                this.viacep.bairro
              );
              this.driverForm.controls.publicPlace.setValue(
                this.viacep.logradouro
              );
              const state = this.states.find(
                (state) => state.initials === this.viacep.uf.toUpperCase()
              );

              this.driverForm.controls.state.setValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      this.viacep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );
                  if (city) {
                    this.driverForm.controls.city.setValue(city.id);
                  }
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
            } else {
              this.driverForm.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.driverForm.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.driverForm.controls.zipCode.setErrors({ invalid: 'CEP inválido!' });
    }
  }

  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  onSubmit() {
    const values = this.driverForm.controls;
    console.log(this.driverForm.controls);

    let driver = new Driver();
    driver.address = new Address();
    driver.address.city = new City();
    driver.name = this.driverForm.controls.name.value;
    driver.cpf = this.driverForm.controls.cpf.value;
    driver.birthDate = this.driverForm.controls.birthDate.value;
    driver.email = this.driverForm.controls.email.value;
    driver.phone = this.driverForm.controls.phone.value;
    driver.whatsapp = this.driverForm.controls.whatsapp.value;
    driver.rg = this.driverForm.controls.rg.value;
    driver.rgShippingSector = this.driverForm.controls.rgShippingSector.value;
    driver.cnh = this.driverForm.controls.cnh.value;
    driver.cnhCategory = this.driverForm.controls.cnhCategory.value;
    driver.cnhValidity = this.driverForm.controls.cnhValidity.value;
    driver.address.zipCode = this.driverForm.controls.zipCode.value.replace(
      /[^0-9,.]+/g,
      ''
    );
    driver.address.neighborhood = this.driverForm.controls.neighborhood.value;
    driver.address.publicPlace = this.driverForm.controls.publicPlace.value;
    driver.address.city.id = Number(this.driverForm.controls.city.value);

    this.loading = true;
    this.service.save(driver).subscribe(
      (data) => {
        if (this.image || this.files.length) {
          if (this.image)
            this.service
              .imageUpload(data.body['id'], this.image)
              .subscribe((data) => {
                if (!this.files.length) {
                  this.loading = false;
                  this.showSuccess();
                }
              }),
              (error) => {
                this.loading = false;
                Swal.fire(
                  'Erro ao salvar imagem',
                  'Motorista salvo, mas não foi possível salvar a imagem!',
                  'error'
                );
              };

          if (this.files.length)
            this.service
              .filesUpload(data.body['id'], this.files)
              .subscribe((data) => {
                this.loading = false;
                this.showSuccess();
              }),
              (error) => {
                this.loading = false;
                Swal.fire(
                  'Erro ao salvar imagem',
                  'Motorista salvo, mas não foi possível salvar a imagem!',
                  'error'
                );
              };
        } else {
          this.loading = false;
          this.showSuccess();
        }
        this.createForm();
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao salvar',
          'Não foi possível salvar motorista!',
          'error'
        );
      }
    );
  }

  showSuccess() {
    Swal.fire('Salvo', 'Motorista salvo com sucesso!!', 'success').then(
      (result) => {
        if (result.value) {
          this.router.navigate(['maintenance/driver']);
        }
      }
    );
  }

  resetForm() {
    this.driverForm.controls.name.setValue('');
    this.driverForm.controls.cpf.setValue('');
    this.driverForm.controls.birthDate.setValue('');
    this.driverForm.controls.email.setValue('');
    this.driverForm.controls.phone.setValue('');
    this.driverForm.controls.whatsapp.setValue('');
    this.driverForm.controls.rg.setValue('');
    this.driverForm.controls.rgShippingSector.setValue('');
    this.driverForm.controls.cnh.setValue('');
    this.driverForm.controls.cnhCategory.setValue('');
    this.driverForm.controls.cnhValidity.setValue('');
    this.driverForm.controls.zipCode.setValue('');
    this.driverForm.controls.neighborhood.setValue('');
    this.driverForm.controls.publicPlace.setValue('');
    this.driverForm.controls.city.setValue('');
    this.driverForm.controls.state.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      console.log(erro);

      switch (erro.fieldName) {
        case 'name':
          this.driverForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case 'cpf':
          this.driverForm.controls.cpf.setErrors({ invalid: erro.message });
          break;
        case 'birthDate':
          this.driverForm.controls.contact.setErrors({ invalid: erro.message });
          break;
        case 'email':
          this.driverForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case 'phone':
          this.driverForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case 'whatsapp':
          this.driverForm.controls.whatsapp.setErrors({
            invalid: erro.message,
          });
          break;
        case 'rg':
          this.driverForm.controls.rg.setErrors({ invalid: erro.message });
          break;
        case 'rgShippingSector':
          this.driverForm.controls.rgShippingSector.setErrors({
            invalid: erro.message,
          });
          break;
        case 'cnh':
          this.driverForm.controls.cnh.setErrors({ invalid: erro.message });
          break;
        case 'cnhCategory':
          this.driverForm.controls.cnhCategory.setErrors({
            invalid: erro.message,
          });
          break;
        case 'cnhValidity':
          this.driverForm.controls.cnhValidity.setErrors({
            invalid: erro.message,
          });
          break;
        case 'zipCode':
          this.driverForm.controls.zipCode.setErrors({ invalid: erro.message });
          break;
        case 'neighborhood':
          this.driverForm.controls.neighborhood.setErrors({
            invalid: erro.message,
          });
          break;
        case 'publicPlace':
          this.driverForm.controls.publicPlace.setErrors({
            invalid: erro.message,
          });
          break;
        case 'city':
          this.driverForm.controls.city.setErrors({ invalid: erro.message });
          break;
        case 'state':
          this.driverForm.controls.state.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openCropperDialog(event) {
    const imgFile = event.target.files['0'];
    let selImg = this.onSelectImage(imgFile);

    if (selImg) {
      this.cropped = null!;
      this._dialog
        .open<CropperDialog, Event>(CropperDialog, {
          data: event,
          width: 320,
          disableClose: true,
        })
        .afterClosed.subscribe((result?: ImgCropperEvent) => {
          if (result) {
            this.cropped = result.dataURL;
            this._cd.markForCheck();
          }
        });
    }
  }
}
