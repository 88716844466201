<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Seguradora</div>
            </div>
            <div class="col-md-2">
              <a type="button" routerLink="/parameters/insurer/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ngx-loading [show]="loading"> </ngx-loading>
      </div>
      <div class="container"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right" [formGroup]="showResultsForm">
                <select (change)="onSelected($event)" formControlName="per_page" class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header>
                <div class="row" [formGroup]="searchForm">
                  <!-- <div class="col">Pesquisar</div> -->
                  <div class="col-12 col-md mt-2">
                    <input type="text" class="form-control" placeholder="Nome" formControlName="name" />
                  </div>
                  <div class="col-12 col-md mt-2">
                    <input type="text" class="form-control" placeholder="Contato" formControlName="contact" />
                  </div>
                  <div class="col-12 col-md mt-2">
                    <select formControlName="active" class="form-control">
                      <option value="null">Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                  <div class="col mt-2">
                    <!-- <span class="input-group-btn search-btn"> -->
                    <a (click)="onSearch()" class="btn btn-info">
                      <i class="fa fa-search"></i>
                    </a>
                    <!-- </span> -->
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile" style="overflow-x: auto">
                <table class="table table-striped custom-table table-hover" id="table">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Nome</th>
                      <!-- <th>Contato</th>
                      <th>Email</th> -->
                      <th>Telefone</th>
                      <th>Whatsapp</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let insurer of insurers">
                      <!-- <td>{{ insurer.id }}</td> -->

                      <td>
                        {{ insurer.name }}
                      </td>
                      <!-- <td>
                        {{ insurer.contact }}
                      </td>
                      <td>
                        {{ insurer.email }}
                      </td> -->
                      <td>
                        {{
                        insurer.phone
                        | mask: "(00) 0000-0000||(00) 0 0000-0000"
                        }}
                      </td>
                      <td>
                        {{ insurer.whatsapp | mask: "+00 00 0 0000-0000" }}
                      </td>
                      <td>
                        <span *ngIf="insurer.active" class="label label-sucess label-mini">Ativo</span>
                        <span *ngIf="!insurer.active" class="label label-danger label-mini">Inativo</span>
                      </td>
                      <td>
                        <a routerLink="/parameters/insurer/view/{{ insurer.id }}" class="btn btn-primary btn-xs">
                          <i class="fa fa-eye"></i>
                        </a>
                        <a routerLink="/parameters/insurer/edit/{{ insurer.id }}" class="btn btn-warning btn-xs">
                          <i class="fa fa-pencil"></i>
                        </a>

                        <!-- <button (click)="onDelete(insurer)" class="btn btn-danger btn-xs">
                                                    <i class="fa fa-trash-o "></i>
                                                </button> -->
                        <span *ngIf="insurer.active">
                          <button (click)="onDeActive(insurer)" class="btn btn-pink btn-xs">
                            <i class="fa fa-random"></i>
                          </button>
                        </span>
                        <span *ngIf="!insurer.active">
                          <button (click)="onActive(insurer)" class="btn btn-success btn-xs">
                            <i class="fa fa-random"></i>
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-12">
                    <span *ngIf="!insurers.length" class="label label-danger label-mini">Nenhuma Seguradora
                      encontrada.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
