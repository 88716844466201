import { AlertType } from './alert-type';

export class Alert {

    id: number;
    name: string;
    variable: number;
    alertType: AlertType;
    originNotificationId: number;
    originNotification: {
        id: number;
        name: string;
    }
    active: boolean;

    constructor() { }
}