import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RunService } from 'src/app/service/run/run.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-runs-view',
  templateUrl: './runs-view.component.html',
  styleUrls: ['./runs-view.component.css'],
})
export class RunsViewComponent implements OnInit {
  id: number;
  run;
  loading: boolean = true;

  constructor(private service: RunService, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.getRuns();
  }

  getRuns() {
    this.loading = true;
    this.service.getRun(this.id).subscribe(
      (data) => {
        this.run = data;
        if (!this.run.observation) {
          this.run.observation = this.run.runRequest.observation;
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar Corrida!', 'error');
  }
}
