import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RunService {
  url: string = `${environment.BASE_URL}/runs`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getRuns(filter: any, pagination: Pagination) {
    let getActive = filter.active ?? '';
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        cost_center: `${filter.costCenter ?? ''}`,
        payment_method: `${filter.paymentMethod ?? ''}`,
        date_start: `${filter.dateStart ?? ''}`,
        date_end: `${filter.dateEnd ?? ''}`,
        driver: `${filter.driver ?? ''}`,
        vehicle: `${filter.vehicle ?? ''}`,
        status: `${filter.status ?? ''}`,
        covenant: `${filter.covenant ?? ''}`,
        passenger: `${filter.passenger ?? ''}`,
        active: getActive,
      },
    });
    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  getRunsBackoffice(filter: any, pagination: Pagination) {
    let getActive = filter.active ?? '';
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        cost_center: `${filter.costCenter ?? ''}`,
        payment_method: `${filter.paymentMethod ?? ''}`,
        date_start: `${filter.dateStart ?? ''}`,
        date_end: `${filter.dateEnd ?? ''}`,
        has_driver_linked: `${filter.hasDriverLinked}`,
        driver: `${filter.driver ?? ''}`,
        vehicle: `${filter.vehicle ?? ''}`,
        status: `${filter.status ?? ''}`,
        covenant: `${filter.covenant ?? ''}`,
        passenger: `${filter.passenger ?? ''}`,
        active: getActive,
      },
    });
    return this.http.get(`${this.url}/backoffice`, {
      responseType: 'json',
      params: params,
    });
  }

  getRunsByRequest(request: number, filter: any, pagination: Pagination) {
    let getActive = filter.active ?? '';
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        cost_center: `${filter.costCenter ?? ''}`,
        payment_method: `${filter.paymentMethod ?? ''}`,
        date_start: `${filter.dateStart ?? ''}`,
        date_end: `${filter.dateEnd ?? ''}`,
        driver: `${filter.driver ?? ''}`,
        vehicle: `${filter.vehicle ?? ''}`,
        status: `${filter.status ?? ''}`,
        covenant: `${filter.covenant ?? ''}`,
        passenger: `${filter.passenger ?? ''}`,
        active: getActive,
      },
    });
    return this.http.get(`${this.url}/${request}/run_request`, {
      responseType: 'json',
      params: params,
    });
  }

  getAmountRunsPerDriver(filter: any) {
    return this.http.get(
      `${this.url}/amount_runs_by_driver/${filter.driverId}`,
      {
        params: filter,
      }
    );
  }

  getRun(id: number) {
    return this.http.get(`${this.url}/${id}`, { responseType: 'json' });
  }

  update(id, data) {
    return this.http.put(`${this.url}/${id}`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  finish(id, data) {
    return this.http.patch(`${this.url}/${id}/finish`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  cancel(id) {
    return this.http.put(`${this.url}/${id}/status/backoffice/cancel/`, null, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  exportTripRequestRuns(filter: any, type: string) {
    let getActive = filter.active ?? '';
    const params = new HttpParams({
      fromObject: {
        cost_center: `${filter.costCenter ?? ''}`,
        payment_method: `${filter.paymentMethod ?? ''}`,
        date_start: `${filter.dateStart ?? ''}`,
        date_end: `${filter.dateEnd ?? ''}`,
        has_driver_linked: `${filter.hasDriverLinked}`,
        driver: `${filter.driver ?? ''}`,
        vehicle: `${filter.vehicle ?? ''}`,
        status: `${filter.status ?? ''}`,
        covenant: `${filter.covenant ?? ''}`,
        passenger: `${filter.passenger ?? ''}`,
        active: getActive,
        type: type,
      },
    });
    return this.http.get(`${this.url}/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      params: params,
      observe: 'response',
    });
  }

  getCashFlow(filter: any) {
    const params = new HttpParams({
      fromObject: {
        date_start: `${filter.dateStart ?? ''}`,
        date_end: `${filter.dateEnd ?? ''}`,
      },
    });

    return this.http.get(`${this.url}/cashflow/table`, {
      responseType: 'json',
      params: params,
    });
  }

  getCashFlowExport(filter: any) {
    const params = new HttpParams({
      fromObject: {
        date_start: `${filter.dateStart ?? ''}`,
        date_end: `${filter.dateEnd ?? ''}`,
      },
    });

    return this.http.get(`${this.url}/cashflow/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      params: params,
      observe: 'response',
    });
  }
}
