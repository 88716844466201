import { Component, OnInit } from '@angular/core';
import { Provider } from 'src/app/domain/provider';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-provider-view',
  templateUrl: './provider-view.component.html',
  styleUrls: ['./provider-view.component.css']
})
export class ProviderViewComponent implements OnInit {


  id: number;
  provider: Provider = new Provider();
  loading: boolean = true;

  constructor(
    private service: ProviderService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getProviders();
  }

  getProviders() {
    this.loading = true;
    this.service.getProvider(this.id).subscribe(data => {
      this.provider = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Fornecedor!', 'error');
  }


}
