<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Acerto com motorista</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/financial/pay-driver/create"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Gerar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header style="width: 100%">
                <div class="row" [formGroup]="searchForm">
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Início</label>
                    <input
                      type="date"
                      class="form-control"
                      formControlName="initialDate"
                    />
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Fim</label>
                    <input
                      type="date"
                      class="form-control"
                      formControlName="finalDate"
                    />
                  </div>

                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">
                      Motorista
                    </label>
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Motorista"
                      formControlName="driverId"
                      [items]="drivers"
                      bindLabel="name"
                      bindValue="id"
                      >
                    </ng-select>
                  </div>
                  <div class="col-sm-4">
                    <label style="font-size: 14px" class="mt-2"
                      >Forma de pagamento</label
                    >
                    <select
                      formControlName="paymentType"
                      class="custom-select custom-select"
                      style="font-size: 14px"
                    >
                      <option value="">Forma de pagamento</option>
                      <option value="money">Dinheiro</option>
                      <option value="bank_check">Cheque</option>
                      <option value="bank_deposit">Depósito</option>
                    </select>
                  </div>
                  <div class="col-sm-2">
                    <label style="font-size: 14px" class="mt-2">Pago</label>
                    <select
                      formControlName="paid"
                      class="custom-select custom-select"
                      style="font-size: 14px"
                    >
                      <option value="">Todos</option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </select>
                  </div>

                  <div class="col trip-request__button__search--div mt-4">
                    <a (click)="onSearch()" class="btn btn-info mr-3">
                      <i class="fa fa-search trip-request__button__search"></i>
                    </a>
                    <button
                      (click)="exportDriverPayments('pdf')"
                      class="btn btn-danger mr-3"
                      title="Exportar para PDF"
                    >
                      <i
                        class="fa fa-file-pdf-o fa-lg trip-request__button__search"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <button
                      (click)="exportDriverPayments('excel')"
                      class="btn btn-success"
                      title="Exportar para Excel"
                    >
                      <i
                        class="fa fa-file-excel-o fa-lg trip-request__button__search"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Motorista</th>
                      <th>Forma de Pagamento</th>
                      <th>Período</th>
                      <th>Valor</th>
                      <th>Status</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let payDriver of payDrivers">
                      <td>
                        {{ payDriver.driver?.name }}
                      </td>
                      <td>
                        {{ payDriver.paymentType }}
                      </td>
                      <td>
                        {{ payDriver.initialDate | date: "dd/MM/yyyy" }} -
                        {{ payDriver.finalDate | date: "dd/MM/yyyy" }}
                      </td>
                      <td>{{ payDriver.value | currency: "BRL" }}</td>
                      <td>
                        <span
                          *ngIf="payDriver.paid"
                          class="label label-sucess label-mini"
                          >Pago</span
                        >
                        <span
                          *ngIf="!payDriver.paid"
                          class="label label-danger label-mini"
                          >Pendente</span
                        >
                      </td>
                      <td>
                        <div class="row">
                          <div class="col">
                            <a
                              routerLink="/financial/pay-driver/view/{{ payDriver.id }}"
                              class="btn btn-primary btn-xs"
                              alt="Visualizar"
                            >
                              <i class="fa fa-eye"></i>
                            </a>
                            <a
                              class="btn btn-success btn-xs"
                              *ngIf="!payDriver.paid"
                              title="Marcar como pago"
                              (click)="setPaid(payDriver.id)"
                            >
                              <i class="fa fa-random"></i>
                            </a>
                            <a
                              class="btn btn-danger btn-xs"
                              *ngIf="payDriver.paid"
                              title="Marcar como não-pago"
                              (click)="setUnpaid(payDriver.id)"
                            >
                              <i class="fa fa-random"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-md-12">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" [class.disabled]="!first">
                        <button
                          class="page-link"
                          (click)="onFirst()"
                          tabindex="-1"
                        >
                          Primeira
                        </button>
                      </li>
                      <li class="page-item" *ngIf="pagination.page != 1">
                        <button (click)="onPrevious()" class="page-link">
                          {{ pagination.page - 1 }}
                        </button>
                      </li>
                      <li class="page-item active">
                        <button class="page-link">
                          {{ pagination.page }}
                        </button>
                      </li>
                      <li class="page-item" *ngIf="last">
                        <button (click)="onNext()" class="page-link" href="#">
                          {{ pagination.page + 1 }}
                        </button>
                      </li>
                      <li class="page-item" [class.disabled]="!last">
                        <button class="page-link" (click)="onLast()">
                          Última
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              Mostrando página {{ pagination.page }} de
              {{ pagination.totalPages }} dos
              {{ pagination.totalResults }} Resultados
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
