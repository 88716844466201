import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { BillsToReceiveService } from 'src/app/service/bills-to-receive/bills-to-receive.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-parcels',
  templateUrl: './update-parcels.component.html',
  styleUrls: ['./update-parcels.component.css'],
})
export class BillsToReceiveUpdateParcelsComponent implements OnInit {
  billsToReceiveForm: FormGroup;
  loading: boolean = true;
  messageError: string = '';
  id: number;

  constructor(
    private service: BillsToReceiveService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.setForm();
  }

  setForm() {
    this.service.getBillToReceive(this.id).subscribe(
      (data) => {
        this.billsToReceiveForm.controls.value.setValue(
          (data.value * 100).toString()
        );

        if (data.parcels) {
          this.billsToReceiveForm.controls.frequency.setValue(
            this.getFrequency(data.dueDate, data.parcels[0].dueDate)
          );

          this.billsToReceiveForm.controls.parcAmount.setValue(data.parcels.length);

          this.parcels.clear();

          this.loading = false;

          data.parcels.forEach((item) => {
            this.parcels.push(
              this.fb.group({
                id: [item.id],
                number: [item.number],
                value: [this.parcelValue()],
                paid: [item.paid],
                bankCheckNumber: [
                  item.bankCheckNumber || '',
                  [Validators.maxLength(10)],
                ],
                dueDate: [this.formatDate(item.dueDate)],
              })
            );
          });
        }
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar As parcelas!', 'error');
      }
    );
  }

  createForm() {
    this.billsToReceiveForm = this.fb.group({
      value: [''],
      dueDate: [''],
      parcAmount: [null],
      frequency: [''],
      parcels: this.fb.array([]),
    });
  }

  get parcels() {
    return this.billsToReceiveForm.get('parcels') as FormArray;
  }

  onlyNumber(event: any): void {
    event.key.match(/[^0-9]/) && event.preventDefault();
  }

  parcelValue() {
    const { value, parcAmount } = this.billsToReceiveForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value.replace(/[^0-9]/g, '') / 100 / parcAmount;
  }

  onSubmit() {
    this.messageError = '';

    if (this.billsToReceiveForm.valid) {
      const billsToReceive = this.billsToReceiveForm.getRawValue();

      billsToReceive.id = this.id;

      this.service.updateParcels(billsToReceive).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Editada',
            'Parcelas editadas com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/bills-to-receive']);
            }
          });
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao editar',
            'Não foi possível editar conta a pagar!',
            'error'
          );
        }
      );
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'email':
          this.billsToReceiveForm.controls.email.setErrors({
            invalid: erro.message,
          });
          break;
        case 'name':
          this.billsToReceiveForm.controls.name.setErrors({
            invalid: erro.message,
          });
          break;
        case 'role':
          this.billsToReceiveForm.controls.role.setErrors({
            invalid: erro.message,
          });
          break;
        case 'birthDate':
          this.billsToReceiveForm.controls.birthDate.setErrors({
            invalid: erro.message,
          });
          break;
        case 'phone':
          this.billsToReceiveForm.controls.phone.setErrors({
            invalid: erro.message,
          });
          break;
        case 'login':
          this.billsToReceiveForm.controls.login.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getFrequency(firstDate, secondDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = (year2 - year1) * 12 + (month2 - month1);

    let frequency = '';

    switch (diffDays) {
      case 1:
        frequency = 'days';
        break;
      case 7:
        frequency = 'weeks';
        break;
      case 14:
      case 15:
        frequency = 'fortnights';
        break;
      default:
        frequency = '';
    }

    if (!frequency) {
      switch (diffMonths) {
        case 1:
          frequency = 'months';
          break;
        case 2:
          frequency = 'bimonthly';
          break;
        case 3:
          frequency = 'quarters';
          break;
        case 6:
          frequency = 'semesters';
          break;
        case 12:
          frequency = 'years';
          break;
        default:
          frequency = '';
      }
    }

    return frequency;
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }
}
