import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FinancialParameter } from 'src/app/domain/financial-parameter';
import { FinancialParameterService } from 'src/app/service/financial-parameter/financial-parameter.service';
import Swal from 'sweetalert2';
import { Validations } from '../../validations';

@Component({
  selector: 'app-financial-parameter-create',
  templateUrl: './financial-parameter-create.component.html',
  styleUrls: ['./financial-parameter-create.component.css']
})
export class FinancialParameterCreateComponent implements OnInit {

  financialParameterForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";

  constructor(
    private service: FinancialParameterService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.financialParameterForm = this.fb.group({
      type: ['', [Validators.required]],
      parameter: ['', [Validators.required]],
      value: ['', [Validators.required]]
    });
  }
  onSubmit() {

    const values = this.financialParameterForm.controls;

    let financialParameter = new FinancialParameter();
    financialParameter.type = this.financialParameterForm.controls.type.value;
    financialParameter.parameter = this.financialParameterForm.controls.parameter.value;
    financialParameter.value = this.financialParameterForm.controls.value.value;

    this.loading = true;
    this.service.save(financialParameter).subscribe(data => {
      this.loading = false;
      Swal.fire('Salvo', 'Parâmetro salvo com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/financial-parameter']);
        }
      });
      this.createForm();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar Parâmetro!', 'error');
      });

  }

  resetForm() {
    this.financialParameterForm.controls.type.setValue("");
    this.financialParameterForm.controls.parameter.setValue("");
    this.financialParameterForm.controls.value.setValue("");
  }

  setErrors(errors) {
    errors.forEach(erro => {

      switch (erro.fieldName) {
        case "type":
          this.financialParameterForm.controls.type.setErrors({ invalid: erro.message });
          break;
        case "parameter":
          this.financialParameterForm.controls.parameter.setErrors({ invalid: erro.message });
          break;
        case "value":
          this.financialParameterForm.controls.value.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }
}
