<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Abastecimento</div>
            </div>
            <div class="col-md-2" *ngIf="false">
              <a type="button" routerLink="/maintenance/car/refuel/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select (change)="onSelectedResults($event)" class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header style="width: 100%">
                <div class="row" [formGroup]="searchForm">

                  <div class="col-md-3 col-sm-6">
                    <label style="font-size: 14px" class="mt-2">Início</label>
                    <input type="date" class="form-control" formControlName="dateStart" />
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <label style="font-size: 14px" class="mt-2">Fim</label>
                    <input type="date" class="form-control" formControlName="dateEnd" />
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <label style="font-size: 14px" class="mt-2">Posto</label>
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Posto de Combustível"
                      formControlName="gasStation"
                      [items]="gasStations"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <label style="font-size: 14px" class="mt-2">Motorista</label>
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Motorista"
                      formControlName="driver"
                      [items]="drivers"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <label style="font-size: 14px" class="mt-2">Veículo</label>
                      <ng-select
                        class="custom-select"
                        style="font-size: 1rem; font-weight: normal"
                        notFoundText="Nenhum item encontrado"
                        placeholder="Veículo"
                        formControlName="vehicle"
                        [items]="cars"
                        bindLabel="modelAndName"
                        bindValue="id"
                      >
                      </ng-select>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="form-group">
                      <label style="font-size: 14px" class="mt-2">Combustível</label>
                      <select formControlName="fuel" class="custom-select custom-select" style="font-size: 14px">
                        <option value selected>Combustível</option>
                        <option value="alcool">Álcool</option>
                        <option value="flex">Flex</option>
                        <option value="gasolina">Gasolina</option>
                        <option value="diesel">Diesel</option>
                        <option value="gnv">Gás Natural</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="form-group">
                      <label style="font-size: 14px" class="mt-2">Tipo de Abastecimento</label>
                      <select formControlName="supplyType" class="custom-select custom-select" style="font-size: 14px">
                        <option value selected>Tipo de Abastecimento</option>
                        <option value="golden_credit">Crédito GOLDEN</option>
                        <option value="filling_station_credit">Crédito POSTO</option>
                        <option value="driver_credit">Motorista</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="form-group">
                      <label style="font-size: 14px" class="mt-2">Número da Nota Fiscal</label>
                      <input type="text" class="form-control" id="invoiceNumber" formControlName="invoiceNumber"
                        placeholder="Número da Nota Fiscal">
                    </div>
                  </div>

                  <div class="col-sm-1 trip-request__button__search--div">
                    <button (click)="onSearch()" class="btn btn-info">
                      <i class="fa fa-search trip-request__button__search"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button (click)="exportCarRefuels('pdf')" class="btn buttons-excel ml-1 mt-3 float-right" title="Exportar para PDF">
                      <i class="fa fa-file-pdf-o trip-request__button__search"></i>
                    </button>
                    <button (click)="exportCarRefuels('excel')" class="btn buttons-excel ml-1 mt-3 float-right" title="Exportar para Excel">
                      <i class="fa fa-file-excel-o trip-request__button__search"></i>
                    </button>
                  </div>
                </div>

              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Número da Nota</th>
                      <th>Posto</th>
                      <th>Motorista</th>
                      <th>Veículo</th>
                      <th>Placa</th>
                      <th>Combustível</th>
                      <th>Tipo Abastecimento</th>
                      <th>Média</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let carRefuel of carRefuels">
                      <td>
                        {{ carRefuel.date | date: "dd/MM/yyyy" }}
                      </td>
                      <td>
                        {{ carRefuel.invoiceNumber }}
                      </td>
                      <td>
                        {{ carRefuel.gasStation.name }}
                      </td>
                      <td>
                        {{ carRefuel.driver.name }}
                      </td>
                      <td>
                        {{ carRefuel.vehicle.model.name }}
                      </td>
                      <td>
                        {{ carRefuel.vehicle.carPlate }}
                      </td>
                      <td>
                        {{ carRefuel.fuel }}
                      </td>
                      <td>
                        {{ carRefuel.supplyType }}
                      </td>
                      <td>
                        {{ carRefuel.avg }}
                      </td>
                      <td>
                        <span>{{
                          carRefuel.value | currency: "BRL"
                          }}</span>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
