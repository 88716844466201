import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { BillsToPayService } from 'src/app/service/bills-to-pay/bills-to-pay.service';
import { CreditCardService } from 'src/app/service/credit-card/credit-card.service';
import { Router } from '@angular/router';
import { CostCenter } from 'src/app/domain/cost-center';
import { Provider } from 'src/app/domain/provider';
import Swal from 'sweetalert2';
import { Bank } from 'src/app/domain/bank';
import { BankService } from 'src/app/service/bank/bank.service';
import { CreditCard } from 'src/app/domain/credit-card';
import moment from 'moment';

@Component({
  selector: 'app-bills-to-pay-create',
  templateUrl: './bills-to-pay-create.component.html',
  styleUrls: ['./bills-to-pay-create.component.css'],
})
export class BillsToPayCreateComponent implements OnInit {
  billsToPayForm: FormGroup;
  loading: boolean = true;
  messageError: string = '';
  providers: Array<Provider> = [];
  costCenters: Array<CostCenter> = [];
  banks: Array<Bank> = [];
  creditCards: Array<CreditCard> = [];

  constructor(
    private providerService: ProviderService,
    private costCenterService: CostCenterService,
    private bankService: BankService,
    private creditCardService: CreditCardService,
    private service: BillsToPayService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.providerService.getAllActiveProviders().subscribe(
      (data) => {
        this.providers = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os fornecedores!', 'error');
      }
    );

    this.costCenterService.getAllCostCenters().subscribe(
      (data) => {
        this.costCenters = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro',
          'Não foi possível Buscar os centros de custo!',
          'error'
        );
      }
    );

    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );

    this.creditCardService.getAllActiveCreditCards().subscribe(
      (data) => {
        this.creditCards = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro',
          'Não foi possível Buscar os cartões de crédito!',
          'error'
        );
      }
    );
  }

  createForm() {
    this.billsToPayForm = this.fb.group({
      provider: [null, [Validators.required]],
      costCenter: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      invoiceNumber: ['', [Validators.maxLength(20)]],
      value: ['', [Validators.required]],
      paidValue: [''],
      dueDate: ['', [Validators.required]],
      paymentType: ['', [Validators.required]],
      paid: [false],
      parcAmount: ['', [Validators.maxLength(5)]],
      frequency: [''],
      creditCard: [''],
      bank: [''],
      bankCheckNumber: ['', [Validators.maxLength(10)]],
      parcels: this.fb.array([]),
      observation: [''],
    });

    this.billsToPayForm
      .get('value')
      .valueChanges.subscribe((val) => this.changeValue(val));

    this.billsToPayForm
      .get('dueDate')
      .valueChanges.subscribe((val) => this.changeDueDate(val));

    this.billsToPayForm
      .get('frequency')
      .valueChanges.subscribe((val) => this.changeFrequency(val));

    this.billsToPayForm
      .get('parcAmount')
      .valueChanges.subscribe((val) => this.changeParcAmount(val));

    this.billsToPayForm
      .get('paymentType')
      .valueChanges.subscribe((val) => this.changePaymentType(val));

    this.billsToPayForm
      .get('paymentMethod')
      .valueChanges.subscribe((val) => this.changePaymentMethod());
  }

  changePaymentType(value) {
    if (value === 'in_cash') {
      this.parcels.clear();
      this.billsToPayForm.get('parcAmount').setValue(null);
      this.billsToPayForm.get('frequency').setValue('');
    }
  }

  changePaymentMethod() {
    this.billsToPayForm.get('creditCard').setValue('');
    this.billsToPayForm.get('bank').setValue('');
    this.billsToPayForm.get('bankCheckNumber').setValue('');
    if (this.billsToPayForm.get('parcValue')) {
      this.changeParcAmount(this.billsToPayForm.get('parcValue').value);
    }
  }

  changeFrequency(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  changeDueDate(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  changeValue(val: string) {
    if (!val) return;
    this.setParcValue();
  }

  changeParcAmount(val: string) {
    const parcAmount = parseInt(val);

    if (isNaN(parcAmount) || parcAmount <= 1) {
      this.parcels.clear();
      return;
    }

    const currentLength = this.parcels.length;

    if (currentLength > parcAmount) {
      for (let i = currentLength + 1; i >= parcAmount; i--) {
        this.parcels.removeAt(i);
      }
    } else if (currentLength < parcAmount) {
      for (let i = currentLength + 1; i <= parcAmount; i++) {
        const dueDate = this.parcelDueDate(i - 1) || '';

        const paid = this.billsToPayForm.get('paid').value;

        this.parcels.push(
          this.fb.group({
            number: [i],
            value: [this.parcelValue()],
            paidValue: [''],
            paid: [paid],
            bankCheckNumber: ['', [Validators.maxLength(10)]],
            dueDate: [dueDate],
          })
        );
      }
    }

    this.setParcValue();
  }

  get parcels() {
    return this.billsToPayForm.get('parcels') as FormArray;
  }

  onlyNumber(event: any): void {
    event.key.match(/[^0-9]/) && event.preventDefault();
  }

  setParcValue() {
    const value = this.parcelValue();
    this.parcels.controls.forEach((item) => {
      item.get('value').setValue(value);
    });
  }

  parcelValue() {
    const { value, parcAmount } = this.billsToPayForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value / parcAmount;
  }

  parcelDueDate(index) {
    const { frequency, dueDate } = this.billsToPayForm.getRawValue();
    if (!frequency || !dueDate) return null;

    const date = new Date(dueDate.replace('-', '/'));

    const iteration = index + 1;

    switch (frequency) {
      case 'days':
        date.setDate(date.getDate() + 1 * iteration);
        break;
      case 'weeks':
        date.setDate(date.getDate() + 7 * iteration);
        break;
      case 'fortnights':
        date.setDate(date.getDate() + 15 * iteration);
        break;
      case 'months':
        date.setMonth(date.getMonth() + 1 * iteration);
        break;
      case 'bimonthly':
        date.setMonth(date.getMonth() + 2 * iteration);
        break;
      case 'quarters':
        date.setMonth(date.getMonth() + 3 * iteration);
        break;
      case 'semesters':
        date.setMonth(date.getMonth() + 6 * iteration);
        break;
      case 'years':
        date.setFullYear(date.getFullYear() + 1 * iteration);
        break;
      default:
        break;
    }

    return this.formatDate(date);
  }

  onSubmit() {
    this.messageError = '';

    if (this.billsToPayForm.valid) {
      const billsToPay = this.billsToPayForm.getRawValue();

      billsToPay.provider = {
        id: Number(this.billsToPayForm.get('provider').value) || null,
      };
      billsToPay.costCenter = {
        id: Number(this.billsToPayForm.get('costCenter').value) || null,
      };
      billsToPay.bank = {
        id: Number(this.billsToPayForm.get('bank').value) || null,
      };
      billsToPay.creditCard = {
        id: Number(this.billsToPayForm.get('creditCard').value) || null,
      };

      billsToPay.parcAmount = Number(
        this.billsToPayForm.get('parcAmount').value
      );

      this.loading = true;
      this.service.save(billsToPay).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Salvo',
            'Conta a pagar salva com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/bills-to-pay']);
            }
          });
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Usuário!',
            'error'
          );
        }
      );
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'email':
          this.billsToPayForm.controls.email.setErrors({
            invalid: erro.message,
          });
          break;
        case 'name':
          this.billsToPayForm.controls.name.setErrors({
            invalid: erro.message,
          });
          break;
        case 'role':
          this.billsToPayForm.controls.role.setErrors({
            invalid: erro.message,
          });
          break;
        case 'birthDate':
          this.billsToPayForm.controls.birthDate.setErrors({
            invalid: erro.message,
          });
          break;
        case 'phone':
          this.billsToPayForm.controls.phone.setErrors({
            invalid: erro.message,
          });
          break;
        case 'login':
          this.billsToPayForm.controls.login.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  paidChanged(paid: boolean) {
    if (paid) {
      this.billsToPayForm.get('paidValue').setValidators([Validators.required]);
    } else {
      this.billsToPayForm.get('paidValue').clearValidators();
    }
    this.billsToPayForm.get('paidValue').updateValueAndValidity();
  }

  parcelPaidChanged(i: number, paid: boolean) {
    if (paid) {
      this.parcels.controls[i]
        .get('paidValue')
        .setValidators([Validators.required]);
    } else {
      this.parcels.controls[i].get('paidValue').clearValidators();
    }
    this.parcels.controls[i].get('paidValue').updateValueAndValidity();
    this.checkAllPaidParcels();
  }

  checkAllPaidParcels() {
    const paidParcelsCount = this.parcels.getRawValue().filter((item) => {
      if (item.paid) return item;
    });
    this.billsToPayForm
      .get('paid')
      .setValue(paidParcelsCount.length === this.parcels.getRawValue().length);
    this.paidChanged(
      paidParcelsCount.length === this.parcels.getRawValue().length
    );
  }
}
