import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FinancialParameter } from 'src/app/domain/financial-parameter';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinancialParameterService {

  url: string = `${environment.BASE_URL}/financial-parameter`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getFinancialParameters(financialParameter: FinancialParameter, pagination: Pagination) {

    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      type: `${financialParameter.type ?? ""}`,
      parameter: `${financialParameter.parameter ?? ""}`,
      active: `${financialParameter.active ?? ''}`
    };

    return this.http.get(this.url,
      { responseType: "json", params: params });

  }

  getFinancialParametersSelect() {
    return this.http.get<Array<FinancialParameter>>(`${this.url}/all-actives`,
      { responseType: "json" });
  }

  getFinancialParameter(id: number) {
    return this.http.get<FinancialParameter>(`${this.url}/${id}`,
      { responseType: "json" });

  }
  save(financialParameter: FinancialParameter) {

    return this.http.post(`${this.url}`, JSON.stringify(financialParameter), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(financialParameter: FinancialParameter) {
    return this.http.put(`${this.url}/${financialParameter.id}`, JSON.stringify(financialParameter), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
