import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduling-update',
  templateUrl: './scheduling-update.component.html',
  styleUrls: ['./scheduling-update.component.css']
})
export class SchedulingUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
