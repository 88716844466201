<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<!-- <ngx-loading [show]="loading"> -->
<!-- </ngx-loading> -->

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10 mt-3">
                            <div class="page-title">Atualizar Agendamento</div>
                        </div>
                    </div>
                    <div>
                        <div class="card card-topline-green">
                            <div class="form-group container">
                                <div class="row">
                                    <div class="container">
                                        <fieldset>
                                            <legend>Buscar Cliente</legend>
                                            <div class="col-sm-3">
                                                <label for="id">Identificador</label>
                                                <input type="text" class="form-control" placeholder="Identificador"
                                                    formControlName="id">
                                            </div>
                                            <div class="col-sm-10">
                                                <label for="name">Nome</label>
                                                <input type="text" class="form-control" placeholder="Nome"
                                                    formControlName="name">
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="container">
                                        <fieldset>
                                            <legend>Dados Cliente</legend>
                                            <div class="col-sm-3">
                                                <label for="id">Telefone Fixo</label>
                                                <input type="text" class="form-control" placeholder="Telefone Fixo"
                                                    formControlName="phone">
                                            </div>
                                            <div class="col-sm-3">
                                                <label for="name">Celular</label>
                                                <input type="text" class="form-control" placeholder="Telefone Celular"
                                                    formControlName="name">
                                            </div>
                                            <div class="col-sm-10">
                                                <label for="name">Nome do Cliente</label>
                                                <input type="text" class="form-control" placeholder="Nome"
                                                    formControlName="name">
                                            </div>
                                            <div class="col-sm-10">
                                                <label for="name">Endereço</label>
                                                <input type="text" class="form-control" placeholder="Endereço"
                                                    formControlName="address">
                                            </div>
                                            <div class="col-sm-6">
                                                <label for="name">Bairro/Setor</label>
                                                <input type="text" class="form-control" placeholder="Bairro"
                                                    formControlName="neigborhood">
                                            </div>
                                            <div class="col-sm-6">
                                                <label for="name">Cidade</label>
                                                <input type="text" class="form-control" placeholder="Cidade"
                                                    formControlName="city">
                                            </div>
                                        </fieldset>

                                        <div class="row mt-3">
                                            <div class="container">
                                                <fieldset>
                                                    <legend>Dados do Agendamento</legend>
                                                    <div class="row ml-0">
                                                        <div class="col-sm-2">
                                                            <label>Data</label>
                                                            <input type="text" class="form-control"
                                                                placeholder="09/06/2020" formControlName="date">
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <label>Hora</label>
                                                            <input type="text" class="form-control" placeholder="21:18"
                                                                formControlName="time">
                                                        </div>
                                                    </div>
                                                    <fieldset class="mt-3 ml-3">
                                                        <legend>Recursividade</legend>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Repetir</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2 mt-2">Data Fim</label>
                                                            <input type="text" class="form-control col-sm-2"
                                                                placeholder="09/06/2020" formControlName="finishdate">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Domingo</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Segunda-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Terça-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Quarta-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Quinta-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Sexta-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Sábado</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                    </fieldset>
                                                </fieldset>
                                                <div class="row ml-0 mt-3">
                                                    <label class="col-sm-2">Tipo de Pagamento</label>
                                                    <select class="custom-select custom-select-s col-sm-3 ml-0">
                                                        <option value="">Selecione</option>
                                                        <option value="">Opção 1</option>
                                                        <option value="">Opção 2</option>
                                                    </select>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Solicitação</label>
                                                    <input type="text" class="form-control col-sm-2" placeholder=""
                                                        formControlName="request">
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Centro de Custo</label>
                                                    <input type="text" class="form-control col-sm-2" placeholder=""
                                                        formControlName="costcenter">
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Carro</label>
                                                    <select class="custom-select custom-select-s col-sm-3 ml-0">
                                                        <option value="">Selecione</option>
                                                        <option value="">Opção 1</option>
                                                        <option value="">Opção 2</option>
                                                    </select>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Passageiros</label>
                                                    <textarea rows="2" cols="30"></textarea>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Endereço de Origem</label>
                                                    <textarea rows="2" cols="30"></textarea>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Endereço de Destino</label>
                                                    <textarea rows="2" cols="30"></textarea>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Observações</label>
                                                    <textarea rows="2" cols="30"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- div do container    -->
                                    </div>
                                </div>

                                <div class="row mt-3 ml-3 mb-3">
                                    <div class="col-md-3 text-right">
                                        <button class="btn btn-success">Salvar</button>
                                    </div>
                                    <div class="col-md-3">
                                        <a href="/scheduling" class="btn btn-danger">Cancelar</a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>