import { Component, OnInit } from '@angular/core';
import { Piece } from 'src/app/domain/piece';
import { PieceService } from 'src/app/service/piece/piece.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-piece-view',
  templateUrl: './piece-view.component.html',
  styleUrls: ['./piece-view.component.css']
})
export class PieceViewComponent implements OnInit {
  id: number;
  piece: Piece = new Piece();
  loading: boolean = true;

  constructor(
    private service: PieceService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getPieces();
  }

  getPieces() {
    this.loading = true;
    this.service.getPiece(this.id).subscribe(data => {
      this.piece = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Peça!', 'error');
  }



}
