import { CarFuelEnum } from 'src/app/enumerations/car-fuel.enum';
import { SupplyTypeEnum } from 'src/app/enumerations/supply-type.enum';
import { CarRefuel } from '../car-refuel';
import { Driver } from '../driver';
import { GasStation } from '../gas-station';

export class CarRefuelDTO {
  id: number;
  gasStation: GasStation;
  fuel: CarFuelEnum;
  date: Date;
  hour: string;
  mileage: number;
  numberOfLiters: number;
  value: number;
  invoiceNumber: number;
  active: boolean;
  supplyType: SupplyTypeEnum;
  driver: Driver;

  constructor(carRefuel: CarRefuel) {
    this.id = carRefuel.id;
    this.gasStation = carRefuel.gasStation;
    this.fuel = carRefuel.fuel;
    this.date = carRefuel.date;
    this.hour = carRefuel.hour;
    this.mileage = carRefuel.mileage;
    this.numberOfLiters = carRefuel.numberOfLiters;
    this.value = carRefuel.value;
    this.invoiceNumber = carRefuel.invoiceNumber;
    this.active = carRefuel.active;
    this.supplyType = carRefuel.supplyType;
    this.driver = carRefuel.driver;
  }
}
