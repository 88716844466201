<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<!-- <ngx-loading [show]="loading"> -->
<!-- </ngx-loading> -->

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10 mt-3">
                            <div class="page-title">Visualizar Agendamento</div>
                        </div>
                    </div>
                    <div>
                        <div class="card card-topline-green">
                            <div class="form-group container">
                                <div class="row">
                                    <div class="container">
                                        <fieldset>
                                            <legend>Buscar Cliente</legend>
                                            <div class="col-sm-3">
                                                <label for="id">Identificador</label>
                                                <label>193411</label>
                                            </div>
                                            <div class="col-sm-10">
                                                <label for="name">Nome</label>
                                                <label>Bianca, Lucas</label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="container">
                                        <fieldset>
                                            <legend>Dados Cliente</legend>
                                            <div class="col-sm-3">
                                                <label for="id">Telefone Fixo</label>
                                                <label>(62) 3314-5511</label>
                                            </div>
                                            <div class="col-sm-3">
                                                <label for="name">Celular</label>
                                                <label>(62) 9957-5544</label>
                                            </div>
                                            <div class="col-sm-10">
                                                <label for="name">Nome do Cliente</label>
                                                <label>Joham Sebastian Bach</label>
                                            </div>
                                            <div class="col-sm-10">
                                                <label for="name">Endereço</label>
                                                <label>Rua dos ricos numero 10</label>
                                            </div>
                                            <div class="col-sm-6">
                                                <label for="name">Bairro/Setor</label>
                                                <label>Campos Elísios</label>
                                            </div>
                                            <div class="col-sm-6">
                                                <label for="name">Cidade</label>
                                                <label>Valhalla</label>
                                            </div>
                                        </fieldset>

                                        <div class="row mt-3">
                                            <div class="container">
                                                <fieldset>
                                                    <legend>Dados do Agendamento</legend>
                                                    <div class="row ml-0">
                                                        <div class="col-sm-2">
                                                            <label>Data</label>
                                                            <label>29/05/2021</label>
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <label>Hora</label>
                                                            <label>19:30</label>
                                                        </div>
                                                    </div>
                                                    <fieldset class="mt-3 ml-3">
                                                        <legend>Recursividade</legend>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Repetir</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2 mt-2">Data Fim</label>
                                                            <label>30/12/2022</label>
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Domingo</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Segunda-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Terça-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Quarta-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Quinta-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Sexta-feira</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                        <div class="row ml-3">
                                                            <label class="col-sm-2">Sábado</label>
                                                            <input type="checkbox" class="mt-2 ml-1">
                                                        </div>
                                                    </fieldset>
                                                </fieldset>
                                                <div class="row ml-0 mt-3">
                                                    <label class="col-sm-2">Tipo de Pagamento</label>
                                                    <label>Dinheiro</label>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Solicitação</label>
                                                    <input type="text" class="form-control col-sm-2" placeholder=""
                                                        formControlName="request">
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Centro de Custo</label>
                                                    <label>Despesas</label>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Carro</label>
                                                    <label>Fusion</label>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Passageiros</label>
                                                    <label>Fabricio, Carmen e Alfred</label>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Endereço de Origem</label>
                                                    <label>Residencia do Alfred</label>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Endereço de Destino</label>
                                                    <label>Brasil Parkshopping</label>
                                                </div>
                                                <div class="row ml-0 mt-2">
                                                    <label class="col-sm-2">Observações</label>
                                                    Troco para R$200,00
                                                </div>
                                            </div>
                                        </div>

                                        <!-- div do container    -->
                                    </div>
                                </div>

                                <div class="row mt-3 ml-3 mb-3">
                                    <div class="col-md-3 text-right">
                                        <button class="btn btn-success">Salvar</button>
                                    </div>
                                    <div class="col-md-3">
                                        <a href="/scheduling" class="btn btn-danger">Cancelar</a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>