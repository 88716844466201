<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mt-3">
              <div class="page-title">Cadastrar Cliente</div>
            </div>
          </div>
          <div [formGroup]="clientForm">
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-3">
                <div>
                  <div
                    class="al-upload"
                    *ngIf="!cropped"
                    (click)="_fileInput.click()"
                  >
                    <div *ngIf="!cropped">
                      <img
                        class="img-responsive"
                        src="assets/img/user/default.png"
                      />
                      <p>Insira uma foto</p>
                    </div>
                  </div>

                  <div *ngIf="cropped">
                    <button class="btn-fechar" (click)="onRemoveImage($event)">
                      <i class="fa fa-times-circle"></i>
                    </button>
                    <img
                      class="img-responsive"
                      [src]="cropped"
                      (click)="_fileInput.click()"
                    />
                  </div>

                  <input
                    #_fileInput
                    type="file"
                    (change)="openCropperDialog($event)"
                    accept="image/*"
                    hidden
                  />
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nome"
                        formControlName="name"
                      />
                      <span *ngIf="clientForm.controls.name.errors">
                        <span
                          *ngIf="clientForm.controls.name.errors.required"
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                        <span
                          *ngIf="
                            clientForm.controls.name.errors &&
                            clientForm.controls.name.errors.maxlength
                          "
                          class="text-danger position-right"
                          >*Nome deve ter no máximo 100 caracteres</span
                        >
                        <span
                          *ngIf="
                            !clientForm.controls.name.errors.required &&
                            clientForm.controls.name.errors.invalid
                          "
                          class="text-danger position-right"
                          >* {{ clientForm.controls.name.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="birthDate">Data de Nascimento</label>
                      <input
                        type="Date"
                        class="form-control"
                        placeholder="Data de Nascimento"
                        formControlName="birthDate"
                      />
                      <span *ngIf="clientForm.controls.birthDate.errors">
                        <span
                          *ngIf="clientForm.controls.birthDate.errors.required"
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                        <span
                          *ngIf="
                            !clientForm.controls.birthDate.errors.required &&
                            clientForm.controls.birthDate.errors.invalid
                          "
                          class="text-danger position-right"
                          >* {{ clientForm.controls.birthDate.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="cpfCnpj">CPF/CNPJ</label>
                      <input
                        type="text"
                        class="form-control"
                        mask="CPF_CNPJ"
                        placeholder="CPF/CNPJ"
                        formControlName="cpfCnpj"
                      />
                      <span *ngIf="clientForm.controls.cpfCnpj.errors">
                        <span
                          *ngIf="clientForm.controls.cpfCnpj.errors.required"
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                        <span
                          *ngIf="
                            !clientForm.controls.cpfCnpj.errors.required &&
                            clientForm.controls.cpfCnpj.errors.invalid
                          "
                          class="text-danger position-right"
                          >* {{ clientForm.controls.cpfCnpj.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="email">E-mail</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="E-mail"
                        formControlName="email"
                      />
                      <span *ngIf="clientForm.controls.email.errors">
                        <span
                          *ngIf="clientForm.controls.email.errors.required"
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                        <span
                          *ngIf="
                            !clientForm.controls.email.errors.required &&
                            clientForm.controls.email.errors.maxlength
                          "
                          class="text-danger position-right"
                          >*Email deve ter no máximo 60 caracteres</span
                        >
                        <span
                          *ngIf="
                            !clientForm.controls.email.errors.maxlength &&
                            clientForm.controls.email.errors.email
                          "
                          class="text-danger position-right"
                          >*Não é um email válido!</span
                        >
                        <span
                          *ngIf="
                            !clientForm.controls.email.errors.email &&
                            clientForm.controls.email.errors.invalid
                          "
                          class="text-danger position-right"
                          >* {{ clientForm.controls.email.errors.invalid }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row col-md-12">
                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <label for="telefone">Telefone</label>
                    <input type="text" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                      placeholder="Telefone" formControlName="phone" />
                    <span *ngIf="clientForm.controls.phone.errors">
                      <span *ngIf="clientForm.controls.phone.errors.required" class="text-danger position-right">*Campo
                        Obrigatório</span>
                      <span *ngIf="
                          !clientForm.controls.phone.errors.required &&
                          clientForm.controls.phone.errors.invalid
                        " class="text-danger position-right">* {{ clientForm.controls.phone.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div> -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="whatsapp">Whatsapp</label>
                    <input
                      type="text"
                      class="form-control"
                      mask="+00 00 0 0000-0000"
                      placeholder="WhatsApp"
                      formControlName="whatsapp"
                    />
                    <span *ngIf="clientForm.controls.whatsapp.errors">
                      <span
                        *ngIf="clientForm.controls.whatsapp.errors.required"
                        class="text-danger position-right"
                        >*Campo Obrigatório</span
                      >
                      <span
                        *ngIf="
                          !clientForm.controls.whatsapp.errors.required &&
                          clientForm.controls.whatsapp.errors.invalid
                        "
                        class="text-danger position-right"
                        >* {{ clientForm.controls.whatsapp.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>
                <!-- </div>

              <div class="row col-md-12"> -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="paymentMethods">Forma de Pagamento</label>
                    <select
                      id="multiple"
                      class="form-control select2-multiple"
                      formControlName="paymentMethods"
                      multiple
                    >
                      <option
                        *ngFor="let paymentMethod of paymentMethods"
                        value="{{ paymentMethod.id }}"
                      >
                        {{ paymentMethod.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="active">Status</label>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="true"
                        value="true"
                        name="active"
                        formControlName="active"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="true"
                        >Ativo</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="false"
                        value="false"
                        name="active"
                        formControlName="active"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="false"
                        >Inativo</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row col-md-12">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="zipCode">CEP</label>
                    <input
                      type="text"
                      class="form-control cep"
                      (blur)="onBlurCep($event)"
                      id="zipCode"
                      formControlName="zipCode"
                      placeholder="CEP"
                    />

                    <span
                      *ngIf="
                        clientForm.controls.zipCode.errors &&
                        clientForm.controls.zipCode.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        clientForm.controls.zipCode.errors &&
                        clientForm.controls.zipCode.errors.maxlength
                      "
                      class="text-danger position-right"
                      >*CEP deve ter no máximo 11 caracteres</span
                    >
                    <span
                      *ngIf="
                        clientForm.controls.zipCode.errors &&
                        clientForm.controls.zipCode.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ clientForm.controls.zipCode.errors.invalid }}
                    </span>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="neighborhood">Logradouro</label>
                    <input
                      type="text"
                      class="form-control"
                      id="publicPlace"
                      formControlName="publicPlace"
                      placeholder="Rua / Complemento / Número"
                    />
                    <span
                      *ngIf="
                        clientForm.controls.publicPlace.errors &&
                        clientForm.controls.publicPlace.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        clientForm.controls.publicPlace.errors &&
                        clientForm.controls.publicPlace.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ clientForm.controls.publicPlace.errors.invalid }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row col-md-12">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="neighborhood">Bairro</label>
                    <input
                      type="text"
                      class="form-control"
                      id=""
                      formControlName="neighborhood"
                      placeholder="Bairro"
                    />

                    <span
                      *ngIf="
                        clientForm.controls.neighborhood.errors &&
                        clientForm.controls.neighborhood.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        clientForm.controls.neighborhood.errors &&
                        clientForm.controls.neighborhood.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ clientForm.controls.neighborhood.errors.invalid }}
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Estado </label>

                    <select
                      (change)="onSelectedState($event)"
                      class="form-control select2"
                      formControlName="state"
                    >
                      <option value="">Selecione o Estado</option>
                      <option
                        value="{{ state.id }}"
                        *ngFor="let state of states"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                    <span
                      *ngIf="
                        clientForm.controls.state.errors &&
                        clientForm.controls.state.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Cidade </label>
                    <ng-select
                      class="custom-select"
                      style="
                        font-size: 1rem;
                        font-weight: normal;
                        min-width: 230px;
                      "
                      notFoundText="Nenhum item encontrado"
                      placeholder="Cidade"
                      formControlName="city"
                      [items]="cities"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                    <span
                      *ngIf="
                        clientForm.controls.city.errors &&
                        clientForm.controls.city.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-content-center my-3">
              <div class="m-2">
                <button
                  (click)="onSubmit()"
                  [disabled]="!clientForm.valid"
                  class="btn btn-success"
                >
                  Salvar
                </button>
              </div>
              <div class="m-2">
                <a routerLink="/maintenance/client" class="btn btn-danger"
                  >Cancelar</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
