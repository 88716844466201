import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CarBrand } from 'src/app/domain/car-brand';

@Injectable({
  providedIn: 'root'
})
export class CarBrandService {
  url: string = `${environment.BASE_URL}/vehicle-brands`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCarBrands() {
    return this.http.get(this.url,
      { responseType: "json" });

  }

  getAllCarBrands() {

    return this.http.get<Array<CarBrand>>(`${this.url}/all`,
      { responseType: "json" });

  }


  getCarBrand(id: number) {
    return this.http.get<CarBrand>(`${this.url}/${id}`,
      { responseType: "json" });

  }
  // save(car: CarBrand) {
  //   let dto = new CarBrandDTO(car);

  //   return this.http.post(`${this.url}`, JSON.stringify(dto), {
  //     headers: this.httpHeaders,
  //     observe: 'response',
  //   });
  // }
  // update(car: CarBrand) {
  //   let dto = new CarBrandDTO(car);
  //   console.log(JSON.stringify(dto));
  //   return this.http.put(`${this.url}/${car.id}`, JSON.stringify(dto), {
  //     headers: this.httpHeaders,
  //     observe: 'response',
  //   });
  // }
  // delete(id: number) {
  //   return this.http.delete(`${this.url}/${id}`, {
  //     headers: this.httpHeaders,
  //     observe: 'response',
  //   });
  // }
}
