import { AccessProfile } from './access-profile';

export class User {

   id: number;
   name: string;
   role: string;
   birthDate: Date;
   email: string;
   phone: string;
   login: string;
   password: string;
   accessProfile: AccessProfile = new AccessProfile;
   active: boolean;

   constructor() { }
}