import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerateBillsToReceiveService } from 'src/app/service/generate-bills-to-receive/generate-bills-to-receive.service';

@Component({
  selector: 'app-generate-bills-to-receive-update-bills',
  templateUrl: './generate-bills-to-receive-update-bills.component.html',
  styleUrls: ['./generate-bills-to-receive-update-bills.component.css']
})
export class GenerateBillsToReceiveUpdateBillsComponent implements OnInit {
  generateBillForm: FormGroup;
  loading: boolean = true;
  messageError: string = '';
  id: number;

  constructor(
    private service: GenerateBillsToReceiveService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.setForm();
  }

  createForm() {
    this.generateBillForm = this.fb.group({
      id: [''],
      value: [''],
      dueDate: [''],
      parcAmount: [null],
      frequency: [''],
      covenantClientName: [''],
      costCenterName: [''],
      parcels: this.fb.array([]),
    });
  }

  setForm() {
    this.service.getGenerateBill(this.id).subscribe(
      (data) => {
        console.log(data)
        this.generateBillForm.controls.parcAmount.setValue(data.parcels.length);
        this.generateBillForm.controls.frequency.setValue(
          this.getFrequency(data.dueDate, data.parcels.length ? data.parcels[0].dueDate : null)
        );

        this.generateBillForm.controls.id.setValue(data.id);
        this.generateBillForm.controls.dueDate.setValue(data.dueDate);
        this.generateBillForm.controls.parcAmount.setValue(data.parcels.length);
        this.generateBillForm.controls.costCenterName.setValue(data.costCenter?.name);
        this.generateBillForm.controls.covenantClientName.setValue(data.covenant?.name || data.client?.name);
        this.generateBillForm.controls.value.setValue(this.getTotalValue(data.parcels));

        this.parcels.clear();

        this.loading = false;

        data.parcels.forEach((item) => {
          this.parcels.push(
            this.fb.group({
              id: [item.id],
              number: [item.number],
              value: [item.value],
              paid: [item.paid],
              dueDate: [this.formatDate(item.dueDate)],
            })
          );
        });

        this.loading = false;
      },
      (_error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível carregar a conta gerada!', 'error');
      }
    );
  }

  getTotalValue(bills = []) {
    let value = 0;
    bills.forEach(bill => {
      value += bill.value
    });

    return value;
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getFrequency(firstDate, secondDate) {
    if (!secondDate) return 'days';
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = (year2 - year1) * 12 + (month2 - month1);

    let frequency = '';

    switch (diffDays) {
      case 1:
        frequency = 'days';
        break;
      case 7:
        frequency = 'weeks';
        break;
      case 14:
      case 15:
        frequency = 'fortnights';
        break;
      default:
        frequency = '';
    }

    if (!frequency) {
      switch (diffMonths) {
        case 1:
          frequency = 'months';
          break;
        case 2:
          frequency = 'bimonthly';
          break;
        case 3:
          frequency = 'quarters';
          break;
        case 6:
          frequency = 'semesters';
          break;
        case 12:
          frequency = 'years';
          break;
        default:
          frequency = '';
      }
    }

    return frequency;
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  get parcels() {
    return this.generateBillForm.get('parcels') as FormArray;
  }

  onlyNumber(event: any): void {
    event.key.match(/[^0-9]/) && event.preventDefault();
  }

  parcelValue() {
    const { value, parcAmount } = this.generateBillForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value.replace(/[^0-9]/g, '') / 100 / parcAmount;
  }

  onSubmit() {
    const generateBill = this.generateBillForm.getRawValue();

    this.service.updateParcels(generateBill).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire(
          'Editada',
          'Parcelas editadas com sucesso!!',
          'success'
        ).then((result) => {
          if (result.value) {
            this.router.navigate(['financial/generate-bills-to-receive']);
          }
        });
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro ao editar',
          'Não foi possível editar conta a pagar!',
          'error'
        );
      }
    );
  }

}
