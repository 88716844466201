import { Alert } from '../alert';
import { AlertType } from '../alert-type';

export class AlertDTO {

    name: string;
    variable: number;
    alertType: AlertType;
    originNotificationId: number;

    constructor(alert: Alert) {
        this.name = alert.name;
        this.variable = alert.variable;
        this.alertType = alert.alertType;
        this.originNotificationId = alert.originNotificationId
    }
}