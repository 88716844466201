import { PayDriverViewComponent } from './components/pay-driver/pay-driver-view/pay-driver-view.component';
import { PayDriverComponent } from './components/pay-driver/pay-driver.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { HelpAngularComponent } from './components/help-angular/help-angular.component';
import { CardBoxComponent } from './components/commons/card-box/card-box.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NavHeaderComponent } from './components/commons/nav-header/nav-header.component';
import { SidebarMenuComponent } from './components/commons/sidebar-menu/sidebar-menu.component';
import { AccessProfileComponent } from './components/access-profile/access-profile.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AuthInterceptor } from './security/auth-interceptor/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccessProfileViewComponent } from './components/access-profile/access-profile-view/access-profile-view.component';
import { AccessProfileCreateComponent } from './components/access-profile/access-profile-create/access-profile-create.component';
import { AccessProfileUpdateComponent } from './components/access-profile/access-profile-update/access-profile-update.component';
import { CarComponent } from './components/car/car.component';
import { CarCreateComponent } from './components/car/car-create/car-create.component';
import { CarUpdateComponent } from './components/car/car-update/car-update.component';
import { CarViewComponent } from './components/car/car-view/car-view.component';
import { UserComponent } from './components/user/user.component';
import { UserCreateComponent } from './components/user/user-create/user-create.component';
import { UserUpdateComponent } from './components/user/user-update/user-update.component';
import { UserViewComponent } from './components/user/user-view/user-view.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ProviderComponent } from './components/provider/provider.component';
import { ProviderCreateComponent } from './components/provider/provider-create/provider-create.component';
import { ProviderUpdateComponent } from './components/provider/provider-update/provider-update.component';
import { ProviderViewComponent } from './components/provider/provider-view/provider-view.component';
import { PieceViewComponent } from './components/piece/piece-view/piece-view.component';
import { PieceCreateComponent } from './components/piece/piece-create/piece-create.component';
import { PieceUpdateComponent } from './components/piece/piece-update/piece-update.component';
import { PieceComponent } from './components/piece/piece.component';
import { InsurerUpdateComponent } from './components/insurer/insurer-update/insurer-update.component';
import { InsurerCreateComponent } from './components/insurer/insurer-create/insurer-create.component';
import { InsurerViewComponent } from './components/insurer/insurer-view/insurer-view.component';
import { InsurerComponent } from './components/insurer/insurer.component';
import { BankComponent } from './components/bank/bank.component';
import { BankViewComponent } from './components/bank/bank-view/bank-view.component';
import { BankCreateComponent } from './components/bank/bank-create/bank-create.component';
import { BankUpdateComponent } from './components/bank/bank-update/bank-update.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertViewComponent } from './components/alert/alert-view/alert-view.component';
import { AlertCreateComponent } from './components/alert/alert-create/alert-create.component';
import { AlertUpdateComponent } from './components/alert/alert-update/alert-update.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { CreditCardViewComponent } from './components/credit-card/credit-card-view/credit-card-view.component';
import { CreditCardCreateComponent } from './components/credit-card/credit-card-create/credit-card-create.component';
import { GasStationComponent } from './components/gas-station/gas-station.component';
import { GasStationCreateComponent } from './components/gas-station/gas-station-create/gas-station-create.component';
import { GasStationUpdateComponent } from './components/gas-station/gas-station-update/gas-station-update.component';
import { GasStationViewComponent } from './components/gas-station/gas-station-view/gas-station-view.component';
import { RequestValueComponent } from './components/request-value/request-value.component';
import { RequestValueCreateComponent } from './components/request-value/request-value-create/request-value-create.component';
import { RequestValueUpdateComponent } from './components/request-value/request-value-update/request-value-update.component';
import { RequestValueViewComponent } from './components/request-value/request-value-view/request-value-view.component';
import { CovenantComponent } from './components/covenant/covenant.component';
import { CovenantViewComponent } from './components/covenant/covenant-view/covenant-view.component';
import { CovenantCreateComponent } from './components/covenant/covenant-create/covenant-create.component';
import { CovenantUpdateComponent } from './components/covenant/covenant-update/covenant-update.component';
import { DriverComponent } from './components/driver/driver.component';
import { DriverViewComponent } from './components/driver/driver-view/driver-view.component';
import { DriverCreateComponent } from './components/driver/driver-create/driver-create.component';
import { DriverUpdateComponent } from './components/driver/driver-update/driver-update.component';
import { CovenantUserComponent } from './components/covenant/covenant-user/covenant-user.component';
import { CovenantCollaboratorsComponent } from './components/covenant/covenant-collaborators/covenant-collaborators.component';
import { CovenantRequestValuesComponent } from './components/covenant/covenant-request-values/covenant-request-values.component';
import { CarRefuelComponent } from './components/car/car-refuel/car-refuel.component';
import { CarMaintenanceComponent } from './components/car/car-maintenance/car-maintenance.component';
import { CarFineComponent } from './components/car/car-fine/car-fine.component';
import { ClientComponent } from './components/client/client.component';
import { ClientCreateComponent } from './components/client/client-create/client-create.component';
import { ClientViewComponent } from './components/client/client-view/client-view.component';
import { ClientUpdateComponent } from './components/client/client-update/client-update.component';
import { CostCenterComponent } from './components/cost-center/cost-center.component';
import { CostCenterCreateComponent } from './components/cost-center/cost-center-create/cost-center-create.component';
import { CostCenterViewComponent } from './components/cost-center/cost-center-view/cost-center-view.component';
import { CostCenterUpdateComponent } from './components/cost-center/cost-center-update/cost-center-update.component';
import { BillsToPayComponent } from './components/bills-to-pay/bills-to-pay.component';
import { BillsToPayCreateComponent } from './components/bills-to-pay/bills-to-pay-create/bills-to-pay-create.component';
import { BillsToPayViewComponent } from './components/bills-to-pay/bills-to-pay-view/bills-to-pay-view.component';
import { BillsToPayUpdateComponent } from './components/bills-to-pay/bills-to-pay-update/bills-to-pay-update.component';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { SchedulingCreateComponent } from './components/scheduling/scheduling-create/scheduling-create.component';
import { SchedulingViewComponent } from './components/scheduling/scheduling-view/scheduling-view.component';
import { SchedulingUpdateComponent } from './components/scheduling/scheduling-update/scheduling-update.component';
import { CovenantLoginComponent } from './gateway/covenant-login/covenant-login.component';
import { CovenantDashboardComponent } from './gateway/covenant-dashboard/covenant-dashboard.component';
import { TripRequestComponent } from './components/trip-request/trip-request.component';
import { TripRequestCreateComponent } from './components/trip-request/trip-request-create/trip-request-create.component';
import { TripRequestViewComponent } from './components/trip-request/trip-request-view/trip-request-view.component';
import { TripRequestUpdateComponent } from './components/trip-request/trip-request-update/trip-request-update.component';
import { CovenantForgotPasswordComponent } from './gateway/covenant-forgot-password/covenant-forgot-password.component';
import { CovenantNewPasswordComponent } from './gateway/covenant-new-password/covenant-new-password.component';
import { CovenantMyaccountComponent } from './gateway/covenant-myaccount/covenant-myaccount.component';
import { CovenantDataComponent } from './gateway/covenant-data/covenant-data.component';
import { CovenantWorkerComponent } from './gateway/covenant-worker/covenant-worker.component';
import { CovenantDashboardUpdateComponent } from './gateway/covenant-dashboard/covenant-dashboard-update/covenant-dashboard-update.component';
import { CovenantDashboardViewComponent } from './gateway/covenant-dashboard/covenant-dashboard-view/covenant-dashboard-view.component';
import { LinkVehicleComponent } from './components/trip-request/link-vehicle/link-vehicle.component';
import { CancelComponent } from './components/trip-request/cancel/cancel.component';
import { CovenantTripRequestComponent } from './gateway/covenant-trip-request/covenant-trip-request.component';
import { TripContributorsComponent } from './components/trip-contributors/trip-contributors.component';
import { CovenantShareTripComponent } from './gateway/covenant-share-trip/covenant-share-trip.component';
import { CovenantTripRequestListComponent } from './gateway/covenant-trip-request-list/covenant-trip-request-list.component';
import { BankAccountComponent } from './components/bank-account/bank-account.component';
import { BankAccountCreateComponent } from './components/bank-account/bank-account-create/bank-account-create.component';
import { BankAccountViewComponent } from './components/bank-account/bank-account-view/bank-account-view.component';
import { BankAccountUpdateComponent } from './components/bank-account/bank-account-update/bank-account-update.component';
import { BillsToReceiveComponent } from './components/bills-to-receive/bills-to-receive.component';
import { BillsToReceiveCreateComponent } from './components/bills-to-receive/bills-to-receive-create/bills-to-receive-create.component';
import { BillsToReceiveViewComponent } from './components/bills-to-receive/bills-to-receive-view/bills-to-receive-view.component';
import { BillsToReceiveUpdateComponent } from './components/bills-to-receive/bills-to-receive-update/bills-to-receive-update.component';
import { ActiveAccountComponent } from './components/driver/active-account/active-account.component';
import { GenerateBillsToReceiveComponent } from './components/generate-bills-to-receive/generate-bills-to-receive.component';
import { GenerateBillsToReceiveCreateComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-create/generate-bills-to-receive-create.component';
import { GenerateBillsToReceiveDeleteComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-delete/generate-bills-to-receive-delete.component';
import { GenerateBillsToReceiveUpdateBillsComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-update-bills/generate-bills-to-receive-update-bills.component';
import { LinkDriverCarComponent } from './components/link-driver-car/link-driver-car.component';
import { TripApprovalComponent } from './components/trip-request/trip-approval/trip-approval.component';
import { ActiveAccountUserComponent } from './components/covenant/covenant-user/active-account/active-account-user.component';
import { RunsComponent } from './components/runs/runs.component';
import { BillsToPayUpdateParcelsComponent } from './components/bills-to-pay/update-parcels/update-parcels.component';
import { BillsToReceiveUpdateParcelsComponent } from './components/bills-to-receive/update-parcels/update-parcels.component';
import { RunsByRequestComponent } from './components/runs/runs-by-request/runs-by-request.component';
import { RunsViewComponent } from './components/runs/runs-view/runs-view.component';
import { CovenantTripRequestCancelComponent } from './gateway/covenant-trip-request-cancel/covenant-trip-request-cancel.component';
import { CovenantRunViewComponent } from './gateway/covenant-run-view/covenant-run-view.component';
import { CovenantRunTripRequestViewComponent } from './gateway/covenant-run-trip-request-view/covenant-run-trip-request-view.component';
import { CovenantRunTripRequestLinkVehicleComponent } from './gateway/covenant-run-trip-request-link-vehicle/covenant-run-trip-request-link-vehicle.component';
import { CovenantRunLinkVehicleComponent } from './gateway/covenant-run-link-vehicle/covenant-run-link-vehicle.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { ChartsModule } from 'ng2-charts';
import { ClientActiveAccountComponent } from './components/client/client-active-account/client-active-account.component';
import { FinancialParameterComponent } from './components/financial-parameter/financial-parameter.component';
import { FinancialParameterViewComponent } from './components/financial-parameter/financial-parameter-view/financial-parameter-view.component';
import { FinancialParameterUpdateComponent } from './components/financial-parameter/financial-parameter-update/financial-parameter-update.component';
import { FinancialParameterCreateComponent } from './components/financial-parameter/financial-parameter-create/financial-parameter-create.component';
import { PaymentDriverComponent } from './components/payment-driver/payment-driver.component';
import { PaymentDriverCreateComponent } from './components/payment-driver/payment-driver-create/payment-driver-create.component';
import { PaymentDriverUpdateComponent } from './components/payment-driver/payment-driver-update/payment-driver-update.component';
import { PaymentDriverViewComponent } from './components/payment-driver/payment-driver-view/payment-driver-view.component';
import { AccountBankComponent } from './components/account-bank/account-bank.component';
import { AccountBankCreateComponent } from './components/account-bank/account-bank-create/account-bank-create.component';
import { AccountBankUpdateComponent } from './components/account-bank/account-bank-update/account-bank-update.component';
import { AccountBankViewComponent } from './components/account-bank/account-bank-view/account-bank-view.component';
import { UserAlterPasswordComponent } from './components/user/user-alter-password/user-alter-password.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CarRefuelListComponent } from './components/car/car-refuel-list/car-refuel-list.component';
import { PayDriverCreateComponent } from './components/pay-driver/pay-driver-create/pay-driver-create.component';
import { ActiveAccountCollaboratorComponent } from './components/covenant/active-account-collaborator/active-account-collaborator.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxPrintModule } from 'ngx-print';

/** Alyle UI */
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig,
} from '@alyle/ui';

// Gestures
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

/** Módulos Alyle */
import { LyButtonModule } from '@alyle/ui/button';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyTooltipModule } from '@alyle/ui/tooltip';

/** Temas Alyle */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { CropperDialog } from './components/cropper/cropper-dialog';
import { RunsUpdateComponent } from './components/runs/runs-update/runs-update.component';
import { GenerateBillsToReceiveViewComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-view/generate-bills-to-receive-view.component';
import { GenerateBillsToReceivePaidComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-paid/generate-bills-to-receive-paid.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ChartRunsByHourComponent } from './components/chart-runs-by-hour/chart-runs-by-hour.component';
import { CashFlowComponent } from './components/cash-flow/cash-flow.component';
import { FinishRunComponent } from './components/runs/finish/finish.component';
import { RecentsComponent } from './components/runs/recents/recents.component';
import { ChartAmountRunsByDriverComponent } from './components/chart-amount-runs-by-driver/chart-amount-runs-by-driver.component';
import { ModalCovenantCollaboratorFormComponent } from './components/modal-covenant-collaborator-form/modal-covenant-collaborator-form.component';
import { ModalFilterCovenantCollaboratorComponent } from './components/modal-filter-covenant-collaborator/modal-filter-covenant-collaborator.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalUpdateCovenantAddressFormComponent } from './components/modal-update-covenant-address-form/modal-update-covenant-address-form.component';
import { ModalUpdateClientAddressFormComponent } from './components/modal-update-client-address-form/modal-update-client-address-form.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HelpAngularComponent,
    CardBoxComponent,
    NotfoundComponent,
    LoginComponent,
    ForgotPasswordComponent,
    NavHeaderComponent,
    SidebarMenuComponent,
    AccessProfileComponent,
    AccessProfileViewComponent,
    AccessProfileCreateComponent,
    AccessProfileUpdateComponent,
    CarComponent,
    CarCreateComponent,
    CarUpdateComponent,
    CarViewComponent,
    UserComponent,
    UserCreateComponent,
    UserUpdateComponent,
    UserViewComponent,
    UserAlterPasswordComponent,
    LogoutComponent,
    ResetPasswordComponent,
    ProviderComponent,
    ProviderCreateComponent,
    ProviderUpdateComponent,
    ProviderViewComponent,
    PieceViewComponent,
    PieceCreateComponent,
    PieceUpdateComponent,
    PieceComponent,
    InsurerUpdateComponent,
    InsurerCreateComponent,
    InsurerViewComponent,
    InsurerComponent,
    BankComponent,
    BankViewComponent,
    BankCreateComponent,
    BankUpdateComponent,
    AlertComponent,
    AlertViewComponent,
    AlertCreateComponent,
    AlertUpdateComponent,
    CreditCardComponent,
    CreditCardViewComponent,
    CreditCardCreateComponent,
    GasStationComponent,
    GasStationCreateComponent,
    GasStationUpdateComponent,
    GasStationViewComponent,
    RequestValueComponent,
    RequestValueCreateComponent,
    RequestValueUpdateComponent,
    RequestValueViewComponent,
    CovenantComponent,
    CovenantViewComponent,
    CovenantCreateComponent,
    CovenantUpdateComponent,
    DriverComponent,
    DriverViewComponent,
    DriverCreateComponent,
    DriverUpdateComponent,
    CovenantUserComponent,
    CovenantCollaboratorsComponent,
    CovenantRequestValuesComponent,
    CarRefuelComponent,
    CarRefuelListComponent,
    CarMaintenanceComponent,
    CarFineComponent,
    ClientComponent,
    ClientCreateComponent,
    ClientViewComponent,
    ClientUpdateComponent,
    CostCenterComponent,
    CostCenterCreateComponent,
    CostCenterViewComponent,
    CostCenterUpdateComponent,
    BillsToPayUpdateParcelsComponent,
    BillsToReceiveUpdateParcelsComponent,
    BillsToPayComponent,
    BillsToPayCreateComponent,
    BillsToPayViewComponent,
    BillsToPayUpdateComponent,
    SchedulingComponent,
    SchedulingCreateComponent,
    SchedulingViewComponent,
    SchedulingUpdateComponent,
    CovenantLoginComponent,
    CovenantDashboardComponent,
    TripRequestComponent,
    TripRequestCreateComponent,
    TripRequestViewComponent,
    TripRequestUpdateComponent,
    CovenantForgotPasswordComponent,
    CovenantNewPasswordComponent,
    CovenantMyaccountComponent,
    CovenantDataComponent,
    CovenantWorkerComponent,
    CovenantDashboardUpdateComponent,
    CovenantDashboardViewComponent,
    LinkVehicleComponent,
    CancelComponent,
    CovenantTripRequestComponent,
    TripContributorsComponent,
    CovenantShareTripComponent,
    CovenantTripRequestListComponent,
    TripApprovalComponent,
    BankAccountComponent,
    BankAccountCreateComponent,
    BankAccountViewComponent,
    BankAccountUpdateComponent,
    BillsToReceiveComponent,
    BillsToReceiveCreateComponent,
    BillsToReceiveViewComponent,
    BillsToReceiveUpdateComponent,
    ActiveAccountComponent,
    ActiveAccountUserComponent,
    GenerateBillsToReceiveComponent,
    GenerateBillsToReceiveCreateComponent,
    GenerateBillsToReceiveDeleteComponent,
    GenerateBillsToReceiveUpdateBillsComponent,
    GenerateBillsToReceiveViewComponent,
    LinkDriverCarComponent,
    RunsComponent,
    RunsByRequestComponent,
    RunsViewComponent,
    ActiveAccountCollaboratorComponent,
    CovenantTripRequestCancelComponent,
    CovenantRunViewComponent,
    CovenantRunTripRequestViewComponent,
    CovenantRunTripRequestLinkVehicleComponent,
    CovenantRunLinkVehicleComponent,
    ClientActiveAccountComponent,
    FinancialParameterComponent,
    FinancialParameterViewComponent,
    FinancialParameterUpdateComponent,
    FinancialParameterCreateComponent,
    PaymentDriverComponent,
    PaymentDriverCreateComponent,
    PaymentDriverUpdateComponent,
    PaymentDriverViewComponent,
    AccountBankComponent,
    AccountBankCreateComponent,
    AccountBankUpdateComponent,
    AccountBankViewComponent,
    NotificationComponent,
    PayDriverComponent,
    PayDriverViewComponent,
    PayDriverCreateComponent,
    CropperDialog,
    RunsUpdateComponent,
    GenerateBillsToReceivePaidComponent,
    DashboardComponent,
    ChartRunsByHourComponent,
    CashFlowComponent,
    FinishRunComponent,
    RecentsComponent,
    ChartAmountRunsByDriverComponent,
    ModalCovenantCollaboratorFormComponent,
    ModalFilterCovenantCollaboratorComponent,
    ModalUpdateCovenantAddressFormComponent,
    ModalUpdateClientAddressFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxCurrencyModule,
    ChartsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(255,255,255,0.35)',
      backdropBorderRadius: '4px',
      primaryColour: '#FFC200',
      secondaryColour: '#DCDCDC',
      tertiaryColour: '#DCDCDC',
      fullScreenBackdrop: true,
    }),
    NgxDropzoneModule,
    NgSelectModule,
    SweetAlert2Module.forRoot(),
    // Alyle
    LyButtonModule,
    LyToolbarModule,
    LyImageCropperModule,
    LySliderModule,
    LyDialogModule,
    LyIconModule,
    LyTooltipModule,
    HammerModule,
    NgbModule,
  ],
  providers: [
    NgxPrintModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    [LyTheme2],
    [StyleRenderer],
    // Tema Alyle
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
  ],
  exports: [CropperDialog],
  bootstrap: [AppComponent],
})
export class AppModule {}
