import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduling-view',
  templateUrl: './scheduling-view.component.html',
  styleUrls: ['./scheduling-view.component.css']
})
export class SchedulingViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
