import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/components/validations';
import { CovenantMyaccountService } from 'src/app/service/covenant-myaccount/covenant-myaccount.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-myaccount',
  templateUrl: './covenant-myaccount.component.html',
  styleUrls: ['./covenant-myaccount.component.css']
})
export class CovenantMyaccountComponent implements OnInit {
  myAccountForm: FormGroup;
  loading: boolean = false;
  name: string = "";
  email: string = "";
  role: string = "";
  submited: boolean = false;

  constructor(
    private service: CovenantMyaccountService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getUserData();
    this.createForm();
  }

  getUserData() {
    this.loading = true;
    this.service.getData().subscribe(data => {
      this.loading = false;
      this.name = data.body['name'];
      this.email = data.body['email'];
      this.role = data.body['role'];
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao buscar dados', 'Não foi possível carregar o email do usuário!', 'error');
      });
  }

  createForm() {
    this.myAccountForm = this.fb.group({
      actualPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8), Validations.validatePassword]],
      confirmationPassword: ['', [Validators.required, Validations.equalsPassword]]
    });
  }

  onSubmit() {
    this.submited = true;
    const oldPassword = this.myAccountForm.controls.actualPassword.value;
    const newPassword = this.myAccountForm.controls.password.value;
    const confirmationPassword = this.myAccountForm.controls.confirmationPassword.value;

    if (newPassword !== confirmationPassword) {
      this.myAccountForm.controls.confirmationPassword.setErrors({ differentPasswords: true })
    }
    if (this.myAccountForm.valid) {

      this.loading = true;
      this.service.reset(oldPassword, newPassword, confirmationPassword).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Nova senha salva com sucesso!!', 'success');
        this.createForm();
        this.submited = false;

      },
        error => {
          console.log(error);
          this.loading = false;
          if (error.error.errors.find((err) => err.fieldName === 'oldPassword')) {
            Swal.fire('Erro ao salvar', 'Senha atual incorreta!', 'error');
          } else {
            this.setErrors(error.error.errors);
            Swal.fire('Erro ao salvar', 'Não foi possível atualizar os dados!', 'error');
          }
        });
    }
  }

  setErrors(errors) {
    errors.forEach(erro => {
      if (erro.fieldName === 'oldPassword') {
        this.myAccountForm.controls.actualPassword.setErrors({ actualPassword: erro.message });
      }
      if (erro.fieldName === 'newPassword') {
        this.myAccountForm.controls.password.setErrors({ password: erro.message });
      }
      if (erro.fieldName === 'newPasswordConfirmation') {
        this.myAccountForm.controls.confirmationPassword.setErrors({ newPasswordConfirmation: erro.message });
      }

    });

  }

}
