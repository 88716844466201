import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CovenantLogin } from 'src/app/domain/covenant-login';
import { Login } from 'src/app/domain/login';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CovenantLoginService {
  url: string = `${environment.BASE_URL}/covenants`;

  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  login(login: CovenantLogin) {
    return this.http.post(`${this.url}/login`, JSON.stringify(login), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  forgot(email: string) {
    return this.http.post(`${this.url}/reset_password`, { email: email }, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  reset(token: string, password: string) {
    return this.http.put(`${this.url}/reset_password/token`, { token: token, password: password }, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getEmail(token: string) {
    return this.http.get(`${this.url}/reset_password/get-email/${token}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !jwtHelper.isTokenExpired(token);
  }
}
