import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/domain/user';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/service/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  users: Array<User> = [];
  user: User = new User;
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe(params => {
      // this.filter = params.filter;
      params.per_page != null ? this.pagination.per_page = params.per_page : this.pagination.per_page = 10;
      params.page != null ? this.pagination.page = params.page : this.pagination.page = 1;
      params.name != null ? this.user.name = params.name : this.user.name = "";
      params.email != null ? this.user.email = params.email : this.user.email = "";
      params.login != null ? this.user.login = params.login : this.user.login = "";
      params.active != null ? this.user.active = params.active : this.user.active = null;
    });
  }

  ngOnInit(): void {
    console.log("Passando pelo OnInit");

    this.createForm();

    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getUsers();
  }

  getUsers() {
    this.loading = true;

    this.service.getUsers(this.user, this.pagination).subscribe(data => {
      this.users = data['results'];
      this.setPagination(data);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });
    this.searchForm = this.fb.group({
      name: [this.user.name],
      email: [this.user.email],
      login: [this.user.login],
      active: [this.user.active],
    });
  }
  onSearch() {
    this.user.name = this.searchForm.controls.name.value;
    this.user.email = this.searchForm.controls.email.value;
    this.user.login = this.searchForm.controls.login.value;
    // this.user.active =  === "" ? null : this.searchForm.controls.active.value;
    switch (this.searchForm.controls.active.value) {
      case "true":
        this.user.active = true;
        break;
      case "false":
        this.user.active = false;
        break;
      default:
        this.user.active = null;
    }
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onActive(user: User) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Usuário? ${user.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.service.active(user.id).subscribe(data => {
          // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
          Swal.fire({
            title: 'Ativado',
            text: `Usuário ativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar Usuário!', 'error');
          });
      }

    });
  }
  onDeActive(user: User) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar usuário? ${user.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.service.deactive(user.id).subscribe(data => {
          // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
          console.log("Voltando com sucesso");

          Swal.fire({
            title: 'Desativado',
            text: `Usuário desativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            console.log("Voltando com erro");
            Swal.fire('Ops', 'Erro ao desativar Usuário!', 'error');
          });
      }

    });
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();

  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/security/user'], {
      queryParams: {
        name: this.user.name,
        email: this.user.email,
        login: this.user.login,
        active: this.user.active,
        page: this.pagination.page,
        per_page: this.pagination.per_page
      }
    });
    this.getUsers();
  }


}
