<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div *ngIf="messageError.length" class="container">
            <div class="alert alert-danger" role="alert">
              {{ messageError }}
            </div>
          </div>
          <div class="row mt-4 mb-2">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Abastecimento</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Marca</b>
                </div>
                <div class="col-md-12">
                  {{ car.model?.brand?.name }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Modelo</b>
                </div>
                <div class="col-md-12">
                  {{ car.model?.name }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Placa</b>
                </div>
                <div class="col-md-12">
                  {{ car.carPlate }}
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="carRefuelForm">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="gasStation">Posto</label>
                  <select
                    formControlName="gasStation"
                    class="custom-select custom-select-md"
                  >
                    <option value="">Selecione o Posto de Combustível</option>
                    <option
                      value="{{ gasStation.id }}"
                      *ngFor="let gasStation of gasStations"
                    >
                      {{ gasStation.name }}
                    </option>
                  </select>
                  <span
                    *ngIf="
                      carRefuelForm.controls.gasStation.errors &&
                      carRefuelForm.controls.gasStation.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.gasStation.errors &&
                      carRefuelForm.controls.gasStation.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carRefuelForm.controls.gasStation.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fuel">Combustível</label>
                  <select
                    formControlName="fuel"
                    class="custom-select custom-select-md"
                  >
                    <option value selected>
                      Selecione o tipo de conbustível
                    </option>
                    <option value="alcool">Álcool</option>
                    <option value="flex">Flex</option>
                    <option value="gasolina">Gasolina</option>
                    <option value="diesel">Diesel</option>
                    <option value="gnv">Gás Natural</option>
                  </select>
                  <span
                    *ngIf="
                      carRefuelForm.controls.fuel.errors &&
                      carRefuelForm.controls.fuel.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.fuel.errors &&
                      carRefuelForm.controls.fuel.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carRefuelForm.controls.fuel.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="date">Data</label>
                  <input
                    type="Date"
                    class="form-control"
                    placeholder="Data"
                    formControlName="date"
                  />
                  <span
                    *ngIf="
                      carRefuelForm.controls.date.errors &&
                      carRefuelForm.controls.date.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.date.errors &&
                      carRefuelForm.controls.date.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carRefuelForm.controls.date.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="hour">Hora</label>
                  <input
                    type="time"
                    class="form-control time_without_seconds"
                    id="hour"
                    formControlName="hour"
                    placeholder="Hora"
                  />
                  <span
                    *ngIf="
                      carRefuelForm.controls.hour.errors &&
                      carRefuelForm.controls.hour.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.hour.errors &&
                      carRefuelForm.controls.hour.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carRefuelForm.controls.hour.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="driver">Motorista</label>
                  <select
                    formControlName="driver"
                    class="custom-select custom-select-md"
                  >
                    <option value selected>Selecione o Motorista</option>
                    <option
                      value="{{ driver.id }}"
                      *ngFor="let driver of drivers"
                    >
                      {{ driver.name }}
                    </option>
                  </select>
                  <span
                    *ngIf="
                      carRefuelForm.controls.driver.errors &&
                      carRefuelForm.controls.driver.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="type">Tipo</label>
                  <select
                    formControlName="type"
                    class="custom-select custom-select-md"
                  >
                    <option value selected>
                      Selecione do Tipo de Abastecimento
                    </option>
                    <option value="golden_credit">Crédito GOLDEN</option>
                    <option value="filling_station_credit">
                      Crédito POSTO
                    </option>
                    <option value="driver_credit">Motorista</option>
                  </select>
                  <span
                    *ngIf="
                      carRefuelForm.controls.type.errors &&
                      carRefuelForm.controls.type.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="mileage">Quilometragem</label>
                  <input
                    type="text"
                    class="form-control"
                    id="mileage"
                    formControlName="mileage"
                    placeholder="Quilometragem"
                  />
                  <span
                    *ngIf="
                      carRefuelForm.controls.mileage.errors &&
                      carRefuelForm.controls.mileage.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.mileage.errors &&
                      carRefuelForm.controls.mileage.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Quilometragem deve ter no máximo 6 caracteres</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.mileage.errors &&
                      carRefuelForm.controls.mileage.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carRefuelForm.controls.mileage.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="numberOfLiters">Quantidade de Litros</label>
                  <input
                    type="text"
                    class="form-control"
                    id="numberOfLiters"
                    formControlName="numberOfLiters"
                    placeholder="Quantidade de Litros"
                    currencyMask
                    [options]="{
                      prefix: '',
                      thousands: '.',
                      decimal: ',',
                      align: 'left',
                      allowNegative: false
                    }"
                    min="0.01"
                    max="999"
                  />
                  <span
                    *ngIf="
                      carRefuelForm.controls.numberOfLiters.errors &&
                      carRefuelForm.controls.numberOfLiters.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.numberOfLiters.errors &&
                      carRefuelForm.controls.numberOfLiters.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Quantidade de Litros deve ter no máximo 3 caracteres</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.numberOfLiters.errors &&
                      carRefuelForm.controls.numberOfLiters.errors.invalid
                    "
                    class="text-danger position-right"
                    >*
                    {{ carRefuelForm.controls.numberOfLiters.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="value">Valor (R$)</label>
                  <input
                    type="text"
                    class="form-control"
                    id="value"
                    formControlName="value"
                    placeholder="Valor (R$)"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ',',
                      align: 'left',
                      allowNegative: false
                    }"
                  />
                  <span
                    *ngIf="
                      carRefuelForm.controls.value.errors &&
                      carRefuelForm.controls.value.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.value.errors &&
                      carRefuelForm.controls.value.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Valor deve ter no máximo 10 caracteres</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.value.errors &&
                      carRefuelForm.controls.value.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carRefuelForm.controls.value.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="invoiceNumber">Número da Nota Fiscal</label>
                  <input
                    type="text"
                    class="form-control"
                    id="invoiceNumber"
                    formControlName="invoiceNumber"
                    placeholder="Número da Nota Fiscal"
                  />
                  <span
                    *ngIf="
                      carRefuelForm.controls.invoiceNumber.errors &&
                      carRefuelForm.controls.invoiceNumber.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.invoiceNumber.errors &&
                      carRefuelForm.controls.invoiceNumber.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Nota Fiscal deve ter no máximo 20 caracteres</span
                  >
                  <span
                    *ngIf="
                      carRefuelForm.controls.invoiceNumber.errors &&
                      carRefuelForm.controls.invoiceNumber.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carRefuelForm.controls.invoiceNumber.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button
                (click)="onSubmit()"
                [disabled]="!carRefuelForm.valid"
                class="btn mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
              >
                Adicionar
              </button>
            </div>
          </div>
          <div class="row mt-3" [formGroup]="searchCarRefuelForm">
            <div class="col-md-3">
              <div class="form-group">
                <label for="gasStation">Posto</label>
                <select
                  formControlName="gasStationId"
                  class="custom-select custom-select-md"
                >
                  <option value="">Selecione o Posto de Combustível</option>
                  <option
                    value="{{ gasStation.id }}"
                    *ngFor="let gasStation of gasStations"
                  >
                    {{ gasStation.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="date">Data Inicial</label>
                <input
                  type="Date"
                  class="form-control"
                  formControlName="start_date"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="date">Data Final</label>
                <input
                  type="Date"
                  class="form-control"
                  formControlName="end_date"
                />
              </div>
            </div>
            <div class="col mt-4">
              <!-- <span class="input-group-btn search-btn"> -->
              <a (click)="getRefuels()" class="btn btn-info">
                <i class="fa fa-search"></i>
              </a>
              <!-- </span> -->
            </div>
          </div>
          <div class="table-responsive car-refuels-table">
            <table class="table table-striped custom-table table-hover">
              <thead>
                <tr>
                  <th>Posto</th>
                  <th>Data</th>
                  <th>Hora</th>
                  <th>Combustível</th>
                  <th>Quilometro</th>
                  <th>Quantidade (L)</th>
                  <th>Vlr. Litro</th>
                  <th>Valor</th>
                  <th>Número da Nota Fiscal</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let refuel of refuels">
                  <td>
                    {{ refuel.gasStation.name }}
                  </td>
                  <td>
                    {{ refuel.date | date : "dd/MM/yyyy" }}
                  </td>
                  <td>
                    {{ refuel.hour }}
                  </td>
                  <td>{{ getFuelType(refuel.fuel) }}</td>
                  <td>
                    {{ refuel.mileage }}
                  </td>
                  <td>
                    {{ refuel.numberOfLiters }}
                  </td>
                  <td>
                    {{
                      refuel.value / refuel.numberOfLiters
                        | currency : "BRL" : true : "1.3-3"
                    }}
                  </td>
                  <td>
                    {{ refuel.value | currency : "BRL" }}
                  </td>
                  <td>
                    {{ refuel.invoiceNumber }}
                  </td>
                  <td>
                    <div class="dropdown">
                      <button
                        class="btn btn-outline-light dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-bars text-info"></i>
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          *ngIf="refuel.files && refuel.files.length"
                          href="{{
                            refuel.files[refuel.files.length - 1].path
                          }}"
                          class="dropdown-item"
                          target="blank"
                          title="Visualizar Comprovante"
                        >
                          <i class="fa fa-eye text-info"></i>
                          Visualizar Comprovante
                        </a>
                        <div
                          *ngIf="!refuel.driverPaymentId"
                          class="dropdown-divider"
                        ></div>
                        <button
                          *ngIf="!refuel.driverPaymentId"
                          (click)="editRefuel(refuel)"
                          class="dropdown-item"
                        >
                          <i class="fa fa-edit"></i>
                          Editar Abastecimento
                        </button>
                        <div class="dropdown-divider"></div>
                        <button
                          [swal]="imageUploadAlert"
                          (click)="openFormDialog(refuel.id)"
                          class="dropdown-item"
                          title="Carregar Comprovante"
                        >
                          <i class="fa fa-upload text-warning"></i>
                          Carregar Comprovante
                        </button>
                        <div class="dropdown-divider"></div>
                        <button
                          *ngIf="refuel.files && refuel.files.length"
                          (click)="onFileRefuelDelete(refuel.id)"
                          class="dropdown-item"
                          title="Excluir Comprovante"
                        >
                          <i class="fa fa-minus-square-o text-danger"></i>
                          Excluir Comprovante
                        </button>
                        <div class="dropdown-divider"></div>
                        <button
                          (click)="onRefuelDelete(refuel.id)"
                          class="dropdown-item"
                          title="Excluir Abastecimento"
                        >
                          <i class="fa fa-trash-o text-danger"></i>
                          Excluir Abastecimento
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-12">
                <span
                  *ngIf="!refuels.length"
                  class="label label-danger label-mini"
                >
                  Nenhum Abastecimento adicionado.
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item" [class.disabled]="!first">
                    <button class="page-link" (click)="onFirst()" tabindex="-1">
                      Primeira
                    </button>
                  </li>
                  <li class="page-item" *ngIf="pagination.page != 1">
                    <button (click)="onPrevious()" class="page-link">
                      {{ pagination.page - 1 }}
                    </button>
                  </li>
                  <li class="page-item active">
                    <button class="page-link">{{ pagination.page }}</button>
                  </li>
                  <li class="page-item" *ngIf="last">
                    <button (click)="onNext()" class="page-link" href="#">
                      {{ pagination.page + 1 }}
                    </button>
                  </li>
                  <li class="page-item" [class.disabled]="!last">
                    <button class="page-link" (click)="onLast()">Última</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-md-12">
              Mostrando página {{ pagination.page }} de
              {{ pagination.totalPages }} dos
              {{ pagination.totalResults }} Resultados
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-12 text-center">
              <a
                routerLink="/maintenance/car/view/{{ carId }}"
                class="btn btn-danger"
                >Voltar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<swal
  #imageUploadAlert
  title="Carregar Comprovante"
  (confirm)="onFileUpload()"
  (dismiss)="onCancelFileUpload($event)"
  [backdrop]="false"
>
  <form *swalPortal>
    <div class="row">
      <div class="col-sm-12">
        <!-- <label>Comprovante</label> -->
        <ngx-dropzone
          class="upload"
          [ngClass]="{ uploaded: image }"
          (change)="onSelectImage($event)"
          [multiple]="false"
          accept=".jpg, .jpeg, .png"
        >
          <ngx-dropzone-label>
            <div class="image-default">
              <div>Clique ou solte um comprovante aqui para carregar</div>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-preview
            *ngIf="image"
            [removable]="true"
            (removed)="onRemoveImage(image)"
          >
            <ngx-dropzone-label>
              <img class="img-responsive" [src]="inUpload.imageUrl" />
            </ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
  </form>
  <!-- This targets the confirm button's inner content
       Notice the usage of ng-container to avoid creating an useless DOM element inside the button -->
  <ng-container *swalPortal="swalTargets.cancelButton"> Cancelar </ng-container>
  <ng-container *swalPortal="swalTargets.confirmButton"> Enviar </ng-container>
</swal>
