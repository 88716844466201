import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsurerService } from 'src/app/service/insurer/insurer.service';
import { Insurer } from 'src/app/domain/insurer';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Validations } from '../../validations';

@Component({
  selector: 'app-insurer-create',
  templateUrl: './insurer-create.component.html',
  styleUrls: ['./insurer-create.component.css']
})
export class InsurerCreateComponent implements OnInit {

  insurerForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";

  constructor(
    private service: InsurerService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.insurerForm = this.fb.group({
      name: ['', [Validators.required]],
      contact: ['',],
      email: ['',],
      phone: ['', [Validators.required, Validations.validPhone]],
      whatsapp: ['', [Validations.validwhatsapp]],
    });
  }
  onSubmit() {

    const values = this.insurerForm.controls;

    let insurer = new Insurer();
    insurer.name = this.insurerForm.controls.name.value;
    insurer.contact = this.insurerForm.controls.contact.value;
    insurer.email = this.insurerForm.controls.email.value;
    insurer.phone = this.insurerForm.controls.phone.value;
    insurer.whatsapp = this.insurerForm.controls.whatsapp.value;

    this.loading = true;
    this.service.save(insurer).subscribe(data => {
      this.loading = false;
      Swal.fire('Salvo', 'Seguradora salva com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/insurer']);
        }
      });
      this.createForm();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar Seguradora!', 'error');
      });

  }

  resetForm() {
    this.insurerForm.controls.name.setValue("");
    this.insurerForm.controls.contact.setValue("");
    this.insurerForm.controls.email.setValue("");
    this.insurerForm.controls.phone.setValue("");
    this.insurerForm.controls.whatsapp.setValue("");
  }

  setErrors(errors) {
    errors.forEach(erro => {
      console.log(erro);

      switch (erro.fieldName) {
        case "name":
          this.insurerForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "contact":
          this.insurerForm.controls.contact.setErrors({ invalid: erro.message });
          break;
        case "email":
          this.insurerForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "phone":
          this.insurerForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case "whatsapp":
          this.insurerForm.controls.whatsapp.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }


}
