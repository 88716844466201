<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading">
</ngx-loading>
<div class="page-content-wrapper">
   <div class="page-content">
      <div class="page-bar">
         <div class="page-title-breadcrumb">
            <div class="container">
               <div class="card card-topline-green">
                  <div class="card-head">
                    <header>Alterar Senha</header>
                  </div>
                   <div class="card-body ">
                    <div class="row infoContainer">
                      <div class="col-md-4">
                        <b>Nome:</b>
                        <p>{{ user.name }}</p>
                      </div>
                      <div class="col-md-4">
                        <b>Celular:</b>
                        <p> {{ user.phone | mask: '(00) 0000-0000||(00) 0 0000-0000' }} </p>
                      </div>
                    </div>

                    <div class="row infoContainer">
                      <div class="col-md-4">
                        <b>Perfil de acesso:</b>
                        <p>{{ user.accessProfile.name }}</p>
                      </div>
                      <div class="col-md-4">
                        <b>Login:</b>
                        <p> {{ user.login }} </p>
                      </div>
                    </div>

                  <div
                    class="updateContainer"
                    [formGroup]="alterPasswordForm"
                  >
                    <div class="form-group">
                      <label for="inputPassword">Senha Atual</label>
                      <input
                        type="password"
                        class="form-control"
                        id="inputPassword"
                        placeholder="Password"
                        formControlName="actualPassword"
                      />
                      <span *ngIf="alterPasswordForm.controls.actualPassword.errors && alterPasswordForm.controls.actualPassword.errors.required"
                      class="text-danger position-right">*Campo
                      Obrigatório</span>
                      <span
                        *ngIf="alterPasswordForm.controls.actualPassword.errors && alterPasswordForm.controls.actualPassword.errors.invalid"
                        class="text-danger position-right">* {{alterPasswordForm.controls.actualPassword.errors.invalid}}
                      </span>
                    </div>

                    <div class="form-group">
                      <label for="inputNewPassword">Nova Senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="inputNewPassword"
                        placeholder="Nova Senha"
                        formControlName="password"
                      />

                      <span *ngIf="alterPasswordForm.controls.password.errors && alterPasswordForm.controls.password.errors.required"
                      class="text-danger position-right">*Campo
                      Obrigatório</span>
                      <span
                        *ngIf="alterPasswordForm.controls.password.errors && alterPasswordForm.controls.password.errors.invalid"
                        class="text-danger position-right">* {{alterPasswordForm.controls.password.errors.invalid}}
                      </span>
                    </div>

                    <div class="form-group">
                      <label for="inputConfirmNewPassword">Confirmar Nova Senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="inputConfirmNewPassword"
                        placeholder="Confirmar Nova Senha"
                        formControlName="confirmPassword"
                      />
                      <span *ngIf="alterPasswordForm.controls.confirmPassword.errors && alterPasswordForm.controls.confirmPassword.errors.required"
                      class="text-danger position-right">*Campo
                      Obrigatório</span>

                      <span
                      *ngIf="alterPasswordForm.controls.confirmPassword.errors && alterPasswordForm.controls.confirmPassword.errors.invalid"
                      class="text-danger position-right">* {{alterPasswordForm.controls.confirmPassword.errors.invalid}}
                    </span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 text-right">
                        <button (click)="onSubmit()" [disabled]="!alterPasswordForm.valid"
                            class="btn btn-success">Salvar</button>
                    </div>
                    <div class="col-md-3">
                        <a routerLink="/security/user" class="btn btn-danger">Cancelar</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</div>
