<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Visualizar Motorista</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card card-topline-green">
          <div class="row col-md-12">
            <div class="row col-md-12">
              <div class="col-md-4 mt-3">
                <div class="driver-image" *ngIf="driver.imageUrl">
                  <img class="img-responsive" [src]="driver.imageUrl" />
                </div>
              </div>

              <div class="col-md-8">
                <div class="row col-md-12">
                  <div class="col-md-8 mt-3">
                    <b>Nome</b>
                    <br />
                    {{ driver.name }}
                  </div>
                  <div class="col-md-4 mt-3">
                    <b>Data de Nascimento</b>
                    <br />
                    {{ driver.birthDate | date: "dd/MM/yyyy" }}
                  </div>
                </div>

                <div class="row col-md-12">
                  <div class="col-md-4 mt-3">
                    <b>CPF</b>
                    <br />
                    {{ driver.cpf | mask: "000.000.000-00" }}
                  </div>
                  <div class="col-md-4 mt-3">
                    <b>RG</b>
                    <br />
                    {{ driver.rg }}
                  </div>
                  <div class="col-md-4 mt-3">
                    <b>Orgão Expeditor</b>
                    <br />
                    {{ driver.rgShippingSector }}
                  </div>
                </div>

                <div class="row col-md-12">
                  <div class="col-md-4 mt-3">
                    <b>CNH</b>
                    <br />
                    {{ driver.cnh }}
                  </div>
                  <div class="col-md-4 mt-3">
                    <b>Categoria</b>
                    <br />
                    {{ driver.cnhCategory }}
                  </div>
                  <div class="col-md-4 mt-3">
                    <b>Data de Vencimento</b>
                    <br />
                    {{ driver.cnhValidity | date: "dd/MM/yyyy" }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row col-md-12 ml-0">
              <div class="col-md-4 mt-3">
                <b>Telefone</b>
                <br />
                {{ driver.phone | mask: (driver.phone.length == 10 ? "(00) 0000-0000" : "(00) 0 0000-0000") }}
              </div>

              <div class="col-md-4 mt-3">
                <b>Whatsapp</b>
                <br />
                {{ driver.whatsapp | mask: "+00 00 0 0000-0000" }}
              </div>

              <div class="col-md-4 mt-3">
                <b>E-mail</b>
                <br />
                {{ driver.email }}
              </div>
            </div>

            <div class="row col-md-12 ml-0">
              <div class="col-md-4 mt-3">
                <b>CEP</b>
                <br />
                {{ driver.address.zipCode }}
              </div>
              <div class="col-md-8 mt-3">
                <b>Logradouro</b>
                <br />
                {{ driver.address.publicPlace }}
              </div>
            </div>

            <div class="row col-md-12 ml-0">
              <div class="col-md-4 mt-3">
                <b>Bairro</b>
                <br />
                {{ driver.address.neighborhood }}
              </div>
              <div class="col-md-4 mt-3">
                <b>Cidade</b>
                <br />
                {{ driver.address.city.name }}
              </div>
              <div class="col-md-4 mt-3">
                <b>Estado</b>
                <br />
                {{ driver.address.city.state.initials }}
              </div>
            </div>

            <div class="row col-md-12 mt-5 ml-0" *ngIf="driver.files?.length">
              <div class="col-12 mb-3">
                <label class="control-label"><b>Anexos</b> </label>
              </div>

              <div class="col-md-2 col-sm-4 col-6" *ngFor="let item of driver.files; let i = index;">
                <div class="file-preview" [title]="item.filename">
                  <i class="fa fa-file-{{item.filename.substring(item.filename.length-3)}}-o fa-3x file__icon"></i>
                  <div class="box mt-3">
                    <div class="mt-3">
                      <a class="text-info" [href]="item.path" target="_blank" title="Visualizar">
                        <i class="fa fa-eye fa-2x"></i>
                      </a>
                      <a *ngIf="false" class="text-danger" title="Remover" (click)="onRemoveUploadedFile(item, i)">
                        <i class="fa fa-trash-o fa-2x ml-5"></i>
                      </a>
                    </div>
                    <span class="p-3">{{item.filename}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row col-md-12 mt-3 mb-3">
            <div class="col-md-3"></div>

            <div class="col-md-3 text-right">
              <a routerLink="/maintenance/driver/edit/{{ driver.id }}"><button class="btn btn-success" onclick="">Editar</button></a>
              <!-- <div class="col-md-3">
                                <a routerLink="/maintenance/driver/edit/1" class="btn btn-alert">editar</a>
                            </div> -->
            </div>

            <div class="col-md-3">
              <a routerLink="/maintenance/driver" class="btn btn-danger">Cancelar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
