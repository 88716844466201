import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { RequestValue } from 'src/app/domain/request-value';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Validations } from '../../validations';
import { DestinyPoint } from 'src/app/domain/destiny-point';

@Component({
  selector: 'app-request-value-update',
  templateUrl: './request-value-update.component.html',
  styleUrls: ['./request-value-update.component.css']
})
export class RequestValueUpdateComponent implements OnInit {


  requestValueForm: FormGroup;
  pointForm: FormGroup;
  points: Array<DestinyPoint> = [];
  loading: boolean = false;
  messageError: string = "";
  requestValue: RequestValue = new RequestValue();

  constructor(
    private service: RequestValueService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.requestValue.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.createPointForm();
    this.service.getRequestValue(this.requestValue.id).subscribe(data => {
      this.requestValue = data;
      this.setValues();
    }, error => {
      this.loading = false;
      Swal.fire('Erro', 'Não foi possível buscar valor da corrida!', 'error');
    });

  }
  createForm() {
    this.requestValueForm = this.fb.group({
      destiny: ['', [Validators.required, Validators.maxLength(100)]],
      type: ['', [Validators.required]],
      value: ['', [Validators.required, Validators.maxLength(20)]],
      aditionalValue: ['', [Validators.required, Validators.maxLength(20)]],
      tollAmount: ['', [Validators.maxLength(20)]],
      percentage: ['', [Validators.required, Validations.validPercentage]],
      percentagePenalty: ['', [Validators.required,]],
      initialHour: ['', [Validators.required]],
      endHour: ['', [Validators.required]],
      valueOriginPoint: ['', [Validators.required]],
      active: ['', Validators.required],
    });
  }
  createPointForm() {
    this.pointForm = this.fb.group({
      namePoint: ['', [Validators.required, Validators.maxLength(100)]],
      valuePoint: ['0', [Validators.required, Validators.maxLength(20)]],
    });
  }

  addPoint() {
    if (!this.pointForm.valid) {
      return;
    }

    const { namePoint: name, valuePoint } = this.pointForm.getRawValue();
    const point = new DestinyPoint(name, Number(valuePoint));

    if (this.points.some((el) => el.name === point.name)) {
      this.pointForm.controls.namePoint.setErrors({ invalid: 'Já existe um destino com esse nome.' })
      return;
    }

    this.points.push(point);
    this.pointForm.reset();
  }
  rmPoint(id: number) {
    this.points.splice(id, 1);
  }
  setValues() {
    this.requestValueForm.controls.destiny.setValue(this.requestValue.destiny);
    this.requestValueForm.controls.type.setValue(this.requestValue.type);
    this.requestValueForm.controls.value.setValue(`${this.requestValue.value}`);
    this.requestValueForm.controls.aditionalValue.setValue(`${this.requestValue.aditionalValue}`);
    this.requestValueForm.controls.tollAmount.setValue(this.requestValue.tollAmount);
    this.requestValueForm.controls.percentage.setValue(`${this.requestValue.percentage * 100}`);
    this.requestValueForm.controls.initialHour.setValue(`${this.requestValue.initialHour}`);
    this.requestValueForm.controls.endHour.setValue(`${this.requestValue.endHour}`);
    this.requestValueForm.controls.active.setValue(`${this.requestValue.active}`);
    this.requestValueForm.controls.percentagePenalty.setValue(`${this.requestValue.percentagePenalty * 100}`);
    this.requestValueForm.controls.valueOriginPoint.setValue(`${this.requestValue.valueOriginPoint}`);


    this.requestValue.destinyPoints?.forEach((point) => this.points.push(point));

  }

  onSubmit() {

    this.requestValue.type = this.requestValueForm.controls.type.value;
    this.requestValue.destiny = this.requestValueForm.controls.destiny.value;
    this.requestValue.value = this.requestValueForm.controls.value.value;
    this.requestValue.initialHour = this.requestValueForm.controls.initialHour.value;
    this.requestValue.endHour = this.requestValueForm.controls.endHour.value;
    this.requestValue.aditionalValue = this.requestValueForm.controls.aditionalValue.value;
    this.requestValue.tollAmount = this.requestValueForm.controls.tollAmount.value;
    this.requestValue.valueOriginPoint = this.requestValueForm.controls.valueOriginPoint.value;
    this.requestValue.percentage = this.requestValueForm.controls.percentage.value / 100;
    this.requestValue.percentagePenalty = this.requestValueForm.controls.percentagePenalty.value / 100;

    this.requestValue.destinyPoints = this.points;



    if (this.requestValueForm.controls.active.value == "true") {
      this.requestValue.active = true
    } else {
      this.requestValue.active = false;
    }
    this.loading = true;
    this.messageError = '';

    this.service.update(this.requestValue).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Valor de Corrida editado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/request-value']);
        }
      });
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar valor da corrida!', 'error');
      });
  }

  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "destiny":
          this.requestValueForm.controls.destiny.setErrors({ invalid: erro.message });
          break;
        case "value":
          this.requestValueForm.controls.value.setErrors({ invalid: erro.message });
          break;
        case "aditionalValue":
          this.requestValueForm.controls.aditionalValue.setErrors({ invalid: erro.message });
          break;
        case "tollAmount":
          this.requestValueForm.controls.tollAmount.setErrors({ invalid: erro.message });
          break;
        case "percentage":
          this.requestValueForm.controls.percentage.setErrors({ invalid: erro.message });
          break;
        case "initialHour":
          this.requestValueForm.controls.initialHour.setErrors({ invalid: erro.message });
          break;
        case "endHour":
          this.requestValueForm.controls.endHour.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;

      }
    });

  }
}
