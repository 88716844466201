import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination } from 'src/app/domain/pagination';
import { PaymentmethodEnum } from 'src/app/enumerations/payment-method.enum';
import { GenerateBillsToReceiveService } from 'src/app/service/generate-bills-to-receive/generate-bills-to-receive.service';
import Swal from 'sweetalert2';
import moment from 'moment';

@Component({
  selector: 'app-generate-bills-to-receive',
  templateUrl: './generate-bills-to-receive.component.html',
  styleUrls: ['./generate-bills-to-receive.component.css']
})
export class GenerateBillsToReceiveComponent implements OnInit {
  billsToReceiveGenerated: Array<any> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  filter: Filter;
  paymentMethod = PaymentmethodEnum;

  paymentMethods = PaymentmethodEnum;
  paymentMethodKeys() : Array<string> {
    var keys = Object.keys(this.paymentMethods);
    return keys;
  }

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: GenerateBillsToReceiveService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      params.per_page != null
        ? (this.pagination.per_page = params.per_page)
        : (this.pagination.per_page = 10);
      params.page != null
        ? (this.pagination.page = params.page)
        : (this.pagination.page = 1);

      this.filter = new Filter();
      this.filter.dateStart = params.date_start ?? moment().format('yyyy-MM-DD');
      this.filter.dateEnd = params.date_end || null;
      this.filter.paymentMethod = params.payment_method || null;
      this.filter.clientCovenant = params.client_covenant ?? null;
      this.filter.status = params.status || null;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getGeneratedBillsToPay();
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      date_start: [this.filter.dateStart],
      date_end: [this.filter.dateEnd],
      payment_method: [this.filter.paymentMethod],
      status: [this.filter.status],
      client_covenant: [this.filter.clientCovenant],
    });
  }

  getTotalValue(runs = []) {
    let value = 0;
    runs.forEach(run => {
      value += run.runEndValue;
    });

    return value;
  }

  translateStatus(status) {
    const statusNames = {
      1: 'Pendente',
      2: 'Aberto',
      3: 'Paga',
    }

    return statusNames[status];
  }

  getGeneratedBillsToPay(): void {
    this.loading = true;

    this.service.getGeneratedBillsToPay(this.filter, this.pagination).subscribe(
      (data) => {
        this.billsToReceiveGenerated = data['results'];
        this.setPagination(data);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  onSearch() {
    this.filter.status = this.searchForm.controls.status.value;
    this.filter.paymentMethod = this.searchForm.controls.payment_method.value;
    this.filter.dateStart = this.searchForm.controls.date_start.value;
    this.filter.dateEnd = this.searchForm.controls.date_end.value;
    this.filter.clientCovenant = this.searchForm.controls.client_covenant.value;

    this.setNavigate();
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }

  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }

  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }

  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/financial/generate-bills-to-receive'], {
      queryParams: {
        page: this.pagination.page,
        per_page: this.pagination.per_page,
        date_start: this.filter.dateStart,
        date_end: this.filter.dateEnd,
        client_covenant: this.filter.clientCovenant,
        payment_method: this.filter.paymentMethod,
        status: this.filter.status
      },
    });
    this.getGeneratedBillsToPay();
  }

  exportBills(type: 'excel' | 'pdf') {
    this.loading = true;

    this.service.exportBills(this.filter, type).subscribe(
      (response) => {
        const fileName = `Relatorio_ContasReceber_${moment().format('DDMMyyyy')}.${
          type == 'excel' ? '.xlsx' : 'pdf'
        }`;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(response.body);
        link.download = fileName;
        link.click();

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  exportGeneratedBillReceiptPdf(id: number, clientCovenant: String) {
    this.loading = true;

    this.service.exportGeneratedBillReceiptPdf(id).subscribe(
      (response) => {
        const fileName = `Recibo_Convênio_${clientCovenant}_${moment().format('DDMMyyyy')}.pdf`;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(response.body);
        link.download = fileName;
        link.click();

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }
}

class Filter {
  dateStart: Date;
  dateEnd: Date;
  status: string;
  clientCovenant: string;
  paymentMethod: string;
}
