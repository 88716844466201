import { Component, OnInit } from '@angular/core';
import { BillsToPay } from 'src/app/domain/bills-to-pay';
import { Provider } from 'src/app/domain/provider';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BillsToPayService } from 'src/app/service/bills-to-pay/bills-to-pay.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CostCenter } from 'src/app/domain/cost-center';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { BillsToPayFilter } from 'src/app/domain/dto/filters/bills-to-pay.filter';

@Component({
  selector: 'app-bills-to-pay',
  templateUrl: './bills-to-pay.component.html',
  styleUrls: ['./bills-to-pay.component.css'],
})
export class BillsToPayComponent implements OnInit {
  billsToPays: Array<BillsToPay> = [];
  providers: Array<Provider> = [];
  costCenters: Array<CostCenter> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  billsToPayFilter: BillsToPayFilter = new BillsToPayFilter();

  paymentMethods = {
    billet: 'Boleto',
    credit_card: 'Cartão de crédito',
    bank_check: 'Cheque',
    deposit: 'Depósito',
    money: 'Dinheiro',
  };

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: BillsToPayService,
    private costCenterService: CostCenterService,
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe({
      next: (params) => {
        this.billsToPayFilter.provider = new Provider();

        this.pagination.per_page = params.per_page ?? 10;
        this.pagination.page = params.page ?? 1;
        this.billsToPayFilter.due_date_start = params.due_date_start ?? '';
        this.billsToPayFilter.due_date_end = params.due_date_end ?? '';
        this.billsToPayFilter.value_start = params.value_start ?? '';
        this.billsToPayFilter.value_end = params.value_end ?? '';
        this.billsToPayFilter.provider.id = params.provider ?? null;
        this.billsToPayFilter.paid = params.paid ?? null;
      },
    });
  }

  ngOnInit(): void {
    this.createForm();

    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getBillsToPay();
    this.getCostCenters();
    this.getProviders();
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });

    this.searchForm = this.fb.group({
      due_date_start: [this.billsToPayFilter.due_date_start],
      due_date_end: [this.billsToPayFilter.due_date_end],
      value_start: [this.billsToPayFilter.value_start],
      value_end: [this.billsToPayFilter.value_end],
      provider: [this.billsToPayFilter.provider.id],
      paid: [this.billsToPayFilter.paid],
    });
  }

  getBillsToPay(): void {
    this.loading = true;

    this.service
      .getBillsToPay(this.billsToPayFilter, this.pagination)
      .subscribe({
        next: (data) => {
          this.billsToPays = data['results'];
          this.setPagination(data);

          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.openAlertError();
        },
      });
  }

  getProviders(): void {
    this.providerService.getAllActiveProviders().subscribe({
      next: (data) => {
        this.providers = data;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.openAlertError();
      },
    });
  }

  onSearch() {
    this.billsToPayFilter.provider = new Provider();

    this.billsToPayFilter.due_date_start =
      this.searchForm.controls.due_date_start.value;
    this.billsToPayFilter.due_date_end =
      this.searchForm.controls.due_date_end.value;
    this.billsToPayFilter.value_start =
      this.searchForm.controls.value_start.value !== 0
        ? this.searchForm.controls.value_start.value
        : null;
    this.billsToPayFilter.value_end =
      this.searchForm.controls.value_end.value !== 0
        ? this.searchForm.controls.value_end.value
        : null;
    this.billsToPayFilter.provider.id = this.searchForm.controls.provider.value;

    switch (this.searchForm.controls.paid.value) {
      case 'true': {
        this.billsToPayFilter.paid = true;
        break;
      }
      case 'false': {
        this.billsToPayFilter.paid = false;
        break;
      }
      default: {
        this.billsToPayFilter.paid = null;
        break;
      }
    }

    this.setNavigate();
  }

  getCostCenters(): void {
    this.costCenterService.getAllCostCenters().subscribe(
      (data) => {
        this.costCenters = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }

  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }

  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }

  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/financial/bills-to-pay'], {
      queryParams: {
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
        due_date_start: this.billsToPayFilter.due_date_start ?? '',
        due_date_end: this.billsToPayFilter.due_date_end ?? '',
        value_start: this.billsToPayFilter.value_start ?? '',
        value_end: this.billsToPayFilter.value_end ?? '',
        provider: this.billsToPayFilter.provider.id ?? '',
        paid: this.billsToPayFilter.paid ?? null,
      },
    });
    this.getBillsToPay();
  }
}
