import { Flag } from '../enumerations/flag';

export class CreditCard {

    id: number;
    number: number;
    flag: Flag;
    active: boolean;

    constructor() { }
}