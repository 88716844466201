import { Permission } from '../permission';
import { AccessProfile } from '../access-profile';
import { User } from '../user';


export class UserDTO {

   name: string;
   role: string;
   birthDate: Date;
   email: string;
   phone: string;
   login: string;
   password: string;
   accessProfile: AccessProfile;
   active: boolean;


   constructor(user: User) {
      this.name = user.name;
      this.role = user.role;
      this.birthDate = user.birthDate;
      this.email = user.email;
      this.phone = user.phone;
      this.login = user.login;
      this.password = user.password;
      this.accessProfile = user.accessProfile;
      this.active = user.active;
   }
}