<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row bg-white p-3">
            <div class="col-md-10">
              <div class="page-title pt-4 pl-3 mb-5">Acerto com Motorista</div>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row col-12 m-0">
                <div class="col-12 col-md-6 col-lg-3">
                  <p>Data Inicial</p>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="initialDate"
                  />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <p>Data Final</p>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="finalDate"
                  />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <p>Motorista</p>
                  <select formControlName="driver" class="custom-select">
                    <option>Motorista</option>
                  </select>
                </div>
                <div class="col-12 col-md-6 col-lg-3 d-flex align-items-end">
                  <button type="button" class="btn btn-primary mt-3">
                    Gerar Acerto
                  </button>
                </div>

                <div class="col-12 mt-4">
                  <table class="col-12 table table-striped table-hover p-0">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Lançamento</th>
                        <th>Tipo</th>
                        <th>Valor</th>
                        <th>Seleção</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-12 mt-5">
                  <p>Totais</p>
                  <table class="col-12 table table-striped table-hover p-0">
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Créditos</th>
                        <th>Débitos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="row col-12 m-0 p-0">
                  <div class="col-12 col-md-6 col-lg-3">
                    <p>Descrição</p>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="description"
                    />
                  </div>

                  <div class="col-12 col-md-6 col-lg-3">
                    <p>Tipo de lançamento</p>
                    <select class="custom-select" formControlName="releaseType">
                      <option>1</option>
                    </select>
                  </div>

                  <div class="col-12 col-md-6 col-lg-3">
                    <p>Valor</p>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="value"
                    />
                  </div>

                  <div class="col d-flex align-items-end">
                    <button class="btn btn-primary mt-3">Adicionar</button>
                  </div>
                </div>

                <div class="row col-12 m-0 mt-5 p-0">
                  <div class="col-12 col-md-6 col-lg-3">
                    <p>Tipo de Pagamento</p>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="paymentType"
                    />
                  </div>

                  <div class="col-12 col-md-6 col-lg-3">
                    <p>Banco</p>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="bank"
                    />
                  </div>
                </div>

                <div
                  class="row col-12 m-0 p-0 d-flex justify-content-center my-5"
                >
                  <div class="m-2">
                    <button
                      (click)="onSubmit()"
                      [disabled]=""
                      class="btn btn-success"
                    >
                      Salvar
                    </button>
                  </div>
                  <div class="m-2">
                    <a href="/payment-driver" class="btn btn-danger"
                      >Cancelar</a
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
