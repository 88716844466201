import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Car } from 'src/app/domain/car';
import { Pagination } from 'src/app/domain/pagination';
import { EnumLocation } from 'src/app/enumerations/location';
import { EnumStatusSolicitation } from 'src/app/enumerations/status-solicitation';
import { CarService } from 'src/app/service/car/car.service';
import { GasStationService } from 'src/app/service/gas-station/gas-station.service';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { GasStation } from 'src/app/domain/gas-station';
import { CarRefuel } from 'src/app/domain/car-refuel';
import { Driver } from 'src/app/domain/driver';
import { DriverService } from 'src/app/service/driver/driver.service';
import { CarRefuelService } from 'src/app/service/car-refuel/car-refuel.service';

@Component({
  selector: 'app-car-refuel-list',
  templateUrl: './car-refuel-list.component.html',
  styleUrls: ['./car-refuel-list.component.css'],
})
export class CarRefuelListComponent implements OnInit {
  carRefuels: CarRefuel[] = [];
  gasStations: Array<GasStation> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  drivers: Driver[] = [];
  driver: Driver = new Driver();
  refuels: Array<CarRefuel> = [];
  cars: Array<Car> = [];
  car: Car = new Car();
  carRefuelForm: FormGroup;
  searchCarRefuelForm: FormGroup;
  first: boolean = false;
  last: boolean = false;

  filter: Filter;

  constructor(
    private service: CarRefuelService,
    private serviceGasStation: GasStationService,
    private serviceCar: CarService,
    private serviceDriver: DriverService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      const {
        dateStart,
        dateEnd,
        gasStation,
        driver,
        vehicle,
        fuel,
        supplyType,
        invoiceNumber,
        page = 1,
        per_page = 10,
      } = params;

      this.pagination.per_page = per_page;
      this.pagination.page = page;

      this.filter = new Filter();
      this.filter.dateStart = dateStart ?? moment().format('yyyy-MM-DD');
      this.filter.dateEnd = dateEnd;
      this.filter.gasStation = gasStation;
      this.filter.driver = driver;
      this.filter.vehicle = vehicle;
      this.filter.fuel = fuel;
      this.filter.supplyType = supplyType;
      this.filter.invoiceNumber = invoiceNumber;
    });
  }

  ngOnInit(): void {
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getGasStations();
    this.getDrivers();
    this.getCars();

    this.createForm();
    this.getCarRefuels();
  }
  onSearch() {
    const {
      dateStart,
      dateEnd,
      gasStation,
      driver,
      vehicle,
      fuel,
      supplyType,
      invoiceNumber,
    } = this.searchForm.value;

    this.filter.gasStation = gasStation;
    this.filter.driver = driver;
    this.filter.fuel = fuel;
    this.filter.supplyType = supplyType;
    this.filter.vehicle = vehicle;
    this.filter.invoiceNumber = invoiceNumber;
    this.filter.dateStart = dateStart;
    this.filter.dateEnd = dateEnd;

    this.setNavigate();
  }

  onSelectedResults(e) {
    this.pagination.per_page = e.target.value;
    this.setNavigate();
  }

  getGasStations() {
    this.loading = true;
    this.serviceGasStation.getGasStationsSelect().subscribe(
      (data) => {
        this.gasStations = data['results'];
      },
      (error) => {
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados de Posto de Combustível!',
          'error'
        );
      }
    );
  }

  getDrivers() {
    this.serviceDriver.getAllDrivers().subscribe({
      next: (data) => (this.drivers = data),
      error: () =>
        Swal.fire({
          title: 'Erro ao carregar dados',
          text: 'Erro ao buscar os motoristas',
          icon: 'error',
        }),
    });
  }

  getCars() {
    this.serviceCar.getAllCars().subscribe({
      next: (data) =>
        (this.cars = data.map((car) => {
          return {
            ...car,
            modelAndName: `${car.model.name} - ${car.carPlate}`,
          };
        })),
      error: () =>
        Swal.fire({
          title: 'Erro ao carregar dados',
          text: 'Erro ao buscar os veículos',
          icon: 'error',
        }),
    });
  }

  createForm() {
    const {
      dateStart,
      dateEnd,
      gasStation,
      driver,
      vehicle,
      fuel,
      supplyType,
      invoiceNumber,
    } = this.filter;

    this.searchForm = this.fb.group({
      dateStart: [dateStart],
      dateEnd: [dateEnd],
      gasStation: [gasStation],
      driver: [driver],
      vehicle: [vehicle],
      fuel: [fuel],
      supplyType: [supplyType],
      invoiceNumber: [invoiceNumber],
    });
  }

  getCarRefuels() {
    this.loading = true;

    this.service.getAllCarRefuels(this.filter, this.pagination).subscribe(
      (data) => {
        this.carRefuels = data['results'];
        this.setPagination(data);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  exportCarRefuels(type: string) {
    this.loading = true;

    this.service.exportCarRefuels(this.filter, type).subscribe(
      (response) => {
        const fileName = `Relatorio_Abastecimento_${moment().format(
          'DDMMyyyy'
        )}.${type == 'excel' ? '.xlsx' : 'pdf'}`;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(response.body);
        link.download = fileName;
        link.click();

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  seeReason(data) {
    Swal.fire(data.motive, data.description, 'error');
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    const {
      dateStart,
      dateEnd,
      gasStation,
      driver,
      vehicle,
      fuel,
      supplyType,
      invoiceNumber,
    } = this.filter;
    const { page = 1, per_page = 10 } = this.pagination;

    this.router.navigate(['/reports/car-refuel'], {
      queryParams: {
        dateStart,
        dateEnd,
        gasStation,
        driver,
        vehicle,
        fuel,
        supplyType,
        invoiceNumber,
        page,
        per_page,
      },
    });
    this.getCarRefuels();
  }
}

class Filter {
  dateStart: Date;
  dateEnd: Date;
  gasStation: number;
  driver: number;
  vehicle: number;
  fuel: string;
  supplyType: string;
  invoiceNumber: string;
}
