import { Component, OnInit, NgModule } from '@angular/core';
import { Permission } from 'src/app/domain/permission';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserService } from 'src/app/service/user/user.service';
import { PermissionsService } from 'src/app/service/permissions/permissions.service';
import Swal from 'sweetalert2';
import { AccessProfile } from 'src/app/domain/access-profile';
import { AccessProfileService } from 'src/app/service/access-profile/access-profile.service';
import { User } from 'src/app/domain/user';
import { Validations } from '../../validations';
import { Pagination } from 'src/app/domain/pagination';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css'],
})

export class UserCreateComponent implements OnInit {

  accessProfiles: Array<AccessProfile> = [];
  userForm: FormGroup;
  loading: boolean = true;
  messageError: string = "";


  constructor(
    private service: UserService,
    private serviceAcessProfile: AccessProfileService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.serviceAcessProfile.getAllAccessProfiles().subscribe(data => {
      this.accessProfiles = data;

      this.loading = false;
    },
      error => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar Perfis de Acesso!', 'error');
      });

  }

  createForm() {
    this.userForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email]],
      birthDate: ['', [Validators.required, Validations.biggerThanToday]],
      role: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      login: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8), Validations.validatePassword]],
      confirmationPassword: ['', [Validators.required, Validations.equalsPassword]],
      accessProfile: ['', [Validators.required]],
    });
  }
  onSubmit() {
    this.messageError = "";
    const password = this.userForm.controls.password.value;
    const confirmationPassword = this.userForm.controls.confirmationPassword.value;

    if (password !== confirmationPassword) {
      this.userForm.controls.confirmationPassword.setErrors({ differentPasswords: true })
    }
    if (this.userForm.valid) {

      let user = new User();
      user.name = this.userForm.controls.name.value;
      user.email = this.userForm.controls.email.value;
      user.role = this.userForm.controls.role.value;
      user.accessProfile = new AccessProfile();
      user.accessProfile.id = Number(this.userForm.controls.accessProfile.value);
      user.birthDate = this.userForm.controls.birthDate.value;
      user.login = this.userForm.controls.login.value;
      user.phone = this.userForm.controls.phone.value.replace(/[^0-9,.]+/g, "");
      user.password = this.userForm.controls.password.value;

      this.loading = true;
      this.service.save(user).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Usuário salvo com sucesso!!', 'success').then((result) => {
          if (result.value) {
            this.router.navigate(['security/user']);
          }
        });
        this.createForm();
        this.resetForm();
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível salvar Usuário!', 'error');
        });
    }
  }
  resetForm() {
    this.userForm.controls.name.setValue("");
    this.userForm.controls.email.setValue("");
    this.userForm.controls.birthDate.setValue("");
    this.userForm.controls.phone.setValue("");
    this.userForm.controls.login.setValue("");
    this.userForm.controls.accessProfile.setValue("");
    this.userForm.controls.role.setValue("");
  }
  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "email":
          this.userForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "name":
          this.userForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "role":
          this.userForm.controls.role.setErrors({ invalid: erro.message });
          break;
        case "birthDate":
          this.userForm.controls.birthDate.setErrors({ invalid: erro.message });
          break;
        case "phone":
          this.userForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case "login":
          this.userForm.controls.login.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;

      }
    });

  }

}
