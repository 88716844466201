import { Insurer } from '../insurer';

export class InsurerDTO {
    name: string;
    phone: string;
    whatsapp: string;
    contact: string;
    email: string
    active: boolean;

    constructor(insurer: Insurer) {
        this.name = insurer.name;
        this.phone = insurer.phone;
        this.whatsapp = insurer.whatsapp;
        this.contact = insurer.contact;
        this.email = insurer.email;
        this.active = insurer.active;
    }
}