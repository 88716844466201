import { Permission } from './permission';
import { City } from './city';
import { State } from './state';
import { CarMotor } from './car-motor';
import { CarModel } from './car-model';
import { Insurer } from './insurer';
import { CarBrand } from './car-brand';
import { VehicleTypes } from '../enumerations/vehicle-types';

export class Car {
  id: number;
  vehicleModel: CarModel;
  model: CarModel;
  motor: CarMotor;
  insurer: Insurer;
  city: City;
  year: string;
  color: string;
  fuel: string;
  carPlate: string;
  renavam: string;
  chassi: string;
  expirationDateIpva: Date;
  pedometer: string;
  carAir: boolean;
  owner: string;
  category: string;
  numberPassengers: number;
  numberLargeSuitcases: number;
  numberSmallSuitcases: number;
  active: boolean;
  insurancePolicy: string;
  dueDateInsurancePolicy: Date;
  type: VehicleTypes;
  imageUrl: string;

  // Campo não relacionado ao model, usado para bind no select.
  nameCarPlate: string;

  constructor() {
    this.model = new CarModel();
  }
}
