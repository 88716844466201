<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title">Vincular Motorista ao Veículo</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <ngx-loading></ngx-loading>
            </div>
            <div class="container">
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-topline-green">
                        <div class="card-head">
                            <header>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="row"><b>Motorista</b></div>
                                        <div class="row">
                                            <p>{{driver.name}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row"><b>CPF</b></div>
                                        <div class="row">
                                            <p>{{ driver.cpf | mask: '000.000.000-00' }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row"><b>Email</b></div>
                                        <div class="row">
                                            <p>{{driver.email}}</p>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                        <div class="card-body ">
                            <div class="row mb-3">
                                <div class="col-md-8" [formGroup]="linkForm">
                                    <select formControlName="vehicle" class="form-control">
                                        <option value="">Selecione o Veículo</option>
                                        <option *ngFor="let car of cars" value="{{car.id}}">{{car.model.name }} -
                                            {{car.carPlate}}
                                        </option>
                                    </select>
                                    <span
                                        *ngIf="linkForm.controls.vehicle.errors && linkForm.controls.vehicle.errors.invalid"
                                        class="text-danger position-right">*
                                        {{linkForm.controls.vehicle.errors.invalid}} </span>
                                </div>
                                <div class="col-md-3 text-right">
                                    <button (click)="onSubmit()" [disabled]="!linkForm.valid"
                                        class="btn btn-success">Adicionar</button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Marca</th>
                                            <th>Nome</th>
                                            <th>Ano</th>
                                            <th>Motor</th>
                                            <th>Placa</th>
                                            <th>Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let car of driver.driverVehicles">
                                            <td>
                                                {{car.vehicle.model.brand.name}}
                                            </td>
                                            <td>
                                                {{car.vehicle.model.name}}
                                            </td>
                                            <td>
                                                {{car.vehicle.year}}
                                            </td>
                                            <td>
                                                {{car.vehicle.motor.name}}
                                            </td>
                                            <td>
                                                {{car.vehicle.carPlate}}
                                            </td>
                                            <td>
                                                <div class="row">
                                                    <button (click)="onRemove(car.vehicle.id)"
                                                        class="btn btn-danger btn-xs">
                                                        <i class="fa fa-trash-o"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-1 ml-5 mb-3">
                                <a routerLink="/maintenance/driver" class="btn btn-danger">Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
