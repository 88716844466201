import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/components/validations';
import { CovenantLoginService } from 'src/app/service/covenant-login/covenant-login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-new-password',
  templateUrl: './covenant-new-password.component.html',
  styleUrls: ['./covenant-new-password.component.css']
})
export class CovenantNewPasswordComponent implements OnInit {

  token: string = "";
  resetForm: FormGroup;
  loading: boolean = false;
  email: string = "";

  constructor(
    private service: CovenantLoginService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit(): void {
    this.getUserEmail();
    this.createForm();
  }


  getUserEmail() {
    this.loading = true;
    this.service.getEmail(this.token).subscribe(data => {
      this.loading = false;
      this.email = data.body['email'];
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao buscar dados', 'Não foi possível carregar o email do usuário!', 'error');
      });
  }

  createForm() {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validations.validatePassword]],
      confirmationPassword: ['', [Validators.required, Validations.equalsPassword]]
    });
  }

  onSubmit() {

    const password = this.resetForm.controls.password.value;
    const confirmationPassword = this.resetForm.controls.confirmationPassword.value;

    if (password !== confirmationPassword) {
      this.resetForm.controls.confirmationPassword.setErrors({ differentPasswords: true })
    }
    if (this.resetForm.valid) {

      this.loading = true;
      this.service.reset(this.token, this.resetForm.controls.password.value).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Nova senha salva com sucesso!!', 'success');
        this.router.navigate(['convenio/login']);
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível editar senha!', 'error');
        });
    }
  }
  setErrors(errors) {
    errors.forEach(erro => {
      if (erro.fieldName === 'password') {
        this.resetForm.controls.password.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'token') {
        this.resetForm.controls.password.setErrors({ token: erro.message });
      }

    });

  }

}
