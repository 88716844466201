import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Insurer } from 'src/app/domain/insurer';
import { Pagination } from 'src/app/domain/pagination';
import { InsurerDTO } from 'src/app/domain/dto/insurer.dto';

@Injectable({
  providedIn: 'root'
})
export class InsurerService {

  url: string = `${environment.BASE_URL}/insurers`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getInsurers(insurer: Insurer, pagination: Pagination) {

    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${insurer.name ?? ""}`,
      contact: `${insurer.contact ?? ""}`,
      active: `${insurer.active ?? ''}`
    };

    return this.http.get(this.url,
      { responseType: "json", params: params });

  }

  getInsurer(id: number) {
    return this.http.get<Insurer>(`${this.url}/${id}`,
      { responseType: "json" });

  }
  save(insurer: Insurer) {
    let dto = new InsurerDTO(insurer);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(insurer: Insurer) {
    let dto = new InsurerDTO(insurer);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${insurer.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
