<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row mt-4 mb-2">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Veículo</div>
            </div>
          </div>
          <div [formGroup]="carForm">
            <!--div com o form -->
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 mt-3">
                <div class="image-vehicle">
                  <div class="al-upload" *ngIf="!cropped" (click)="_fileInput.click()">
                    <div *ngIf="!cropped">
                      <img class="img-responsive" src="assets/img/vehicle/default.png" />
                      <p>Insira uma foto</p>
                    </div>
                  </div>

                  <div *ngIf="cropped">
                    <button class="btn-fechar" (click)="onRemoveImage($event)">
                      <i class="fa fa-times-circle"></i>
                    </button>
                    <img class="img-responsive" [src]="cropped" (click)="_fileInput.click()">
                  </div>

                  <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="marca">Marca</label>
                      <select formControlName="marca" (change)="onSelected($event)"
                        class="custom-select custom-select-md">
                        <option value="">Selecione a Marca do Veículo</option>
                        <option value="{{ vehicleBrand.id }}" *ngFor="let vehicleBrand of vehicleBrands">
                          {{ vehicleBrand.name }}
                        </option>
                      </select>
                      <span *ngIf="
                          carForm.controls.marca.errors &&
                          carForm.controls.marca.errors.required
                        " class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          carForm.controls.marca.errors &&
                          carForm.controls.marca.errors.invalid
                        " class="text-danger position-right">* {{ carForm.controls.marca.errors.invalid }}
                      </span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="modelo">Modelo</label>
                      <select formControlName="modelo" class="custom-select custom-select-md">
                        <option value="">Selecione o Modelo do Veículo</option>
                        <option value="{{ vehicleModel.id }}" *ngFor="let vehicleModel of vehicleModels">
                          {{ vehicleModel.name }}
                        </option>
                      </select>
                      <span *ngIf="
                          carForm.controls.modelo.errors &&
                          carForm.controls.modelo.errors.required
                        " class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          carForm.controls.modelo.errors &&
                          carForm.controls.modelo.errors.invalid
                        " class="text-danger position-right">* {{ carForm.controls.modelo.errors.invalid }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="motor">Motor</label>
                      <select formControlName="motor" class="custom-select custom-select-md">
                        <option value="">Selecione o Motor do Veículo</option>
                        <option value="{{ motor.id }}" *ngFor="let motor of motors">
                          {{ motor.name }}
                        </option>
                      </select>
                      <span *ngIf="
                          carForm.controls.motor.errors &&
                          carForm.controls.motor.errors.required
                        " class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          carForm.controls.motor.errors &&
                          carForm.controls.motor.errors.invalid
                        " class="text-danger position-right">* {{ carForm.controls.motor.errors.invalid }}
                      </span>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="ano">Ano</label>
                      <select formControlName="ano" class="custom-select custom-select-md">
                        <option value="">Selecione o ano do Veículo</option>
                        <option value="{{ year }}" *ngFor="let year of years">
                          {{ year }}
                        </option>
                      </select>
                      <span *ngIf="
                          carForm.controls.ano.errors &&
                          carForm.controls.ano.errors.required
                        " class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          carForm.controls.ano.errors &&
                          carForm.controls.ano.errors.invalid
                        " class="text-danger position-right">* {{ carForm.controls.ano.errors.invalid }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cor">Cor</label>
                  <input type="text" class="form-control" id="cor" formControlName="cor" placeholder="Cor" />
                  <span *ngIf="
                      carForm.controls.cor.errors &&
                      carForm.controls.cor.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.cor.errors &&
                      carForm.controls.cor.errors.maxlength
                    " class="text-danger position-right">*Cor deve ter no máximo 10 caracteres</span>
                  <span *ngIf="
                      carForm.controls.cor.errors &&
                      carForm.controls.cor.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.cor.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="combustivel">Combustível</label>
                  <select formControlName="combustivel" class="custom-select custom-select-md">
                    <option value="Alcool">Álcool</option>
                    <option value="Flex">Flex</option>
                    <option value="Gasolina">Gasolina</option>
                    <option value="GNV">GNV</option>
                    <option value="Diesel">Diesel</option>
                  </select>
                  <span *ngIf="
                      carForm.controls.combustivel.errors &&
                      carForm.controls.combustivel.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.combustivel.errors &&
                      carForm.controls.combustivel.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.combustivel.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="placa">Placa</label>
                  <input type="text" class="form-control" id="placa" formControlName="placa" placeholder="Placa" />
                  <span *ngIf="
                      carForm.controls.placa.errors &&
                      carForm.controls.placa.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.placa.errors &&
                      carForm.controls.placa.errors.maxlength
                    " class="text-danger position-right">*Placa deve ter no máximo 7 caracteres</span>
                  <span *ngIf="
                      carForm.controls.placa.errors &&
                      carForm.controls.placa.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.placa.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="renavam">RENAVAM</label>
                  <input type="text" class="form-control" id="renavam" formControlName="renavam"
                    placeholder="RENAVAM" />
                  <span *ngIf="
                      carForm.controls.renavam.errors &&
                      carForm.controls.renavam.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.renavam.errors &&
                      carForm.controls.renavam.errors.maxlength
                    " class="text-danger position-right">*RENAVAM deve ter no máximo 11 caracteres</span>

                  <span *ngIf="
                      carForm.controls.renavam.errors &&
                      carForm.controls.renavam.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.renavam.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="chassi">Chassi</label>
                  <input type="text" class="form-control" id="chassi" formControlName="chassi" placeholder="Chassi" />
                  <span *ngIf="
                      carForm.controls.chassi.errors &&
                      carForm.controls.chassi.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.chassi.errors &&
                      carForm.controls.chassi.errors.maxlength
                    " class="text-danger position-right">*Chassi deve ter no máximo 17 caracteres</span>

                  <span *ngIf="
                      carForm.controls.chassi.errors &&
                      carForm.controls.chassi.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.chassi.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="ipva">Vencimento IPVA</label>
                  <input type="Date" class="form-control" placeholder="Vencimento IPVA" formControlName="ipva" />
                  <span *ngIf="
                      carForm.controls.ipva.errors &&
                      carForm.controls.ipva.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.ipva.errors &&
                      carForm.controls.ipva.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.ipva.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="hodometro">Hodômetro</label>
                  <input type="text" class="form-control" id="hodometro" formControlName="hodometro"
                    placeholder="Hodômetro" />
                  <span *ngIf="
                      carForm.controls.hodometro.errors &&
                      carForm.controls.hodometro.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.hodometro.errors &&
                      carForm.controls.hodometro.errors.maxlength
                    " class="text-danger position-right">*Hodômetro deve ter no máximo 10 caracteres</span>
                  <span *ngIf="
                      carForm.controls.hodometro.errors &&
                      carForm.controls.hodometro.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.hodometro.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="arCondicionado">Ar-condicionado</label>
                  <div class="custom-control custom-radio custom-control-inline mt-2">
                    <input type="radio" id="true" value="true" name="arCondicionado" formControlName="arCondicionado"
                      class="custom-control-input" />
                    <label class="custom-control-label" for="true">Sim</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="false" value="false" name="arCondicionado" formControlName="arCondicionado"
                      class="custom-control-input" />
                    <label class="custom-control-label" for="false">Não</label>
                  </div>
                  <span *ngIf="
                      carForm.controls.arCondicionado.errors &&
                      carForm.controls.arCondicionado.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.arCondicionado.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="proprietario">Proprietário</label>
                  <select formControlName="proprietario" class="custom-select custom-select-md">
                    <option value="">Proprietário</option>
                    <option value="Golden">Golden</option>
                    <option value="Terceiros">Terceiros</option>
                  </select>
                  <span *ngIf="
                      carForm.controls.proprietario.errors &&
                      carForm.controls.proprietario.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.proprietario.errors &&
                      carForm.controls.proprietario.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.proprietario.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="categoria">Categoria</label>
                  <select formControlName="categoria" class="custom-select custom-select-md">
                    <option value="">Selecione a categoria do Veículo</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                  <span *ngIf="
                      carForm.controls.categoria.errors &&
                      carForm.controls.categoria.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.categoria.errors &&
                      carForm.controls.categoria.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.categoria.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="type">Tipo</label>
                  <select formControlName="type" class="custom-select custom-select-md">
                    <option value="">Selecione o tipo do Veículo</option>
                    <option value="TOUR">Passeio</option>
                    <option value="SUV">SUV</option>
                    <option value="MICROBUS">Micro ônibus</option>
                    <option value="VAN">Van</option>
                  </select>
                  <span *ngIf="
                      carForm.controls.type.errors &&
                      carForm.controls.type.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.type.errors &&
                      carForm.controls.type.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.type.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="seguradora">Seguradora</label>
                  <select formControlName="seguradora" class="custom-select custom-select-md">
                    <option value="">Selecione a Seguradora do Veículo</option>
                    <option value="{{ insurer.id }}" *ngFor="let insurer of insurers">
                      {{ insurer.name }}
                    </option>
                  </select>
                  <span *ngIf="
                      carForm.controls.seguradora.errors &&
                      carForm.controls.seguradora.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.seguradora.errors &&
                      carForm.controls.seguradora.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.seguradora.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="insurancePolicy">Número da Apólice</label>
                  <input type="text" class="form-control" id="insurancePolicy" formControlName="insurancePolicy"
                    placeholder="Número da Apólice" />
                  <span *ngIf="
                      carForm.controls.insurancePolicy.errors &&
                      carForm.controls.insurancePolicy.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.insurancePolicy.errors &&
                      carForm.controls.insurancePolicy.errors.maxlength
                    " class="text-danger position-right">*Número da Apólice deve ter no máximo 30 caracteres</span>
                  <span *ngIf="
                      carForm.controls.insurancePolicy.errors &&
                      carForm.controls.insurancePolicy.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.insurancePolicy.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="dueDateInsurancePolicy">Data de Vencimento Seguro</label>
                  <input type="Date" class="form-control" placeholder="Data de Vencimento Seguro"
                    formControlName="dueDateInsurancePolicy" />
                  <span *ngIf="
                      carForm.controls.dueDateInsurancePolicy.errors &&
                      carForm.controls.dueDateInsurancePolicy.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.dueDateInsurancePolicy.errors &&
                      carForm.controls.dueDateInsurancePolicy.errors.invalid
                    " class="text-danger position-right">*
                    {{ carForm.controls.dueDateInsurancePolicy.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="qtdPassageiros">Quantidade Passageiros</label>
                  <input type="text" class="form-control" id="qtdPassageiros" formControlName="qtdPassageiros"
                    placeholder="Quantidade Passageiros" />
                  <span *ngIf="
                      carForm.controls.qtdPassageiros.errors &&
                      carForm.controls.qtdPassageiros.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.qtdPassageiros.errors &&
                      carForm.controls.qtdPassageiros.errors.maxlength
                    " class="text-danger position-right">*Quantidade Passageiros deve ter no máximo 2
                    caracteres</span>
                  <span *ngIf="
                      carForm.controls.qtdPassageiros.errors &&
                      carForm.controls.qtdPassageiros.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.qtdPassageiros.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="nMalasGrandes">Quantidade Malas Grandes</label>
                  <input type="text" class="form-control" id="nMalasGrandes" formControlName="nMalasGrandes"
                    placeholder="Quantidade Malas Grandes" />
                  <span *ngIf="
                      carForm.controls.nMalasGrandes.errors &&
                      carForm.controls.nMalasGrandes.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.nMalasGrandes.errors &&
                      carForm.controls.nMalasGrandes.errors.maxlength
                    " class="text-danger position-right">*Quantidade Malas deve ter no máximo 2 caracteres</span>
                  <span *ngIf="
                      carForm.controls.nMalasGrandes.errors &&
                      carForm.controls.nMalasGrandes.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.nMalasGrandes.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="nMalasPequenas">Quantidade Malas Pequenas</label>
                  <input type="text" class="form-control" id="nMalasPequenas" formControlName="nMalasPequenas"
                    placeholder="Quantidade Malas Pequenas" />
                  <span *ngIf="
                      carForm.controls.nMalasPequenas.errors &&
                      carForm.controls.nMalasPequenas.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.nMalasPequenas.errors &&
                      carForm.controls.nMalasPequenas.errors.maxlength
                    " class="text-danger position-right">*Quantidade Malas deve ter no máximo 2 caracteres</span>
                  <span *ngIf="
                      carForm.controls.nMalasPequenas.errors &&
                      carForm.controls.nMalasPequenas.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.nMalasPequenas.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="estado">Estado</label>
                  <select (change)="onSelectedState($event)" formControlName="estado"
                    class="custom-select custom-select-md">
                    <option value="">Selecione o Estado</option>
                    <option value="{{ state.id }}" *ngFor="let state of states">
                      {{ state.name }}
                    </option>
                  </select>
                  <span *ngIf="
                      carForm.controls.estado.errors &&
                      carForm.controls.estado.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.estado.errors &&
                      carForm.controls.estado.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.estado.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="cidade">Cidade</label>
                  <ng-select
                    class="custom-select"
                    style="font-size: 1rem; font-weight: normal; min-width: 230px;"
                    notFoundText="Nenhum item encontrado"
                    placeholder="Cidade"
                    formControlName="cidade"
                    [items]="cities"
                    bindLabel="name"
                    bindValue="id"
                  >
                  </ng-select>
                  <span *ngIf="
                      carForm.controls.cidade.errors &&
                      carForm.controls.cidade.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      carForm.controls.cidade.errors &&
                      carForm.controls.cidade.errors.invalid
                    " class="text-danger position-right">* {{ carForm.controls.cidade.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-center my-3">
            <div class="m-2">
              <button (click)="onSubmit()" [disabled]="!carForm.valid" class="btn btn-success">
                Salvar
              </button>
            </div>
            <div class="m-2">
              <a routerLink="/maintenance/car" class="btn btn-danger">Cancelar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
