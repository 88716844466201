import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CarModel } from 'src/app/domain/car-model';

@Injectable({
  providedIn: 'root'
})
export class CarModelService {

  url: string = `${environment.BASE_URL}/vehicle-models`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCarModels() {
    return this.http.get(this.url,
      { responseType: "json" });

  }
  getCarModelsByBrand(id: number) {
    return this.http.get<Array<CarModel>>(`${this.url}/${id}/brand`,
      { responseType: "json" });

  }


  getCarModel(id: number) {
    return this.http.get<CarModel>(`${this.url}/${id}`,
      { responseType: "json" });

  }
}
