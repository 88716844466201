import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Address } from 'src/app/domain/address';
import { Car } from 'src/app/domain/car';
import { City } from 'src/app/domain/city';
import { CostCenter } from 'src/app/domain/cost-center';
import { Driver } from 'src/app/domain/driver';
import { PaymentMethod } from 'src/app/domain/paymentMethod';
import { RequestValue } from 'src/app/domain/request-value';
import { State } from 'src/app/domain/state';
import { AddressService } from 'src/app/service/address/address.service';
import { CarService } from 'src/app/service/car/car.service';
import { ClientService } from 'src/app/service/client/client.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { PaymentMethodsService } from 'src/app/service/payment-methods/payment-methods.service';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ValidationsTripRequest } from '../validations/validations-trip-request';
import { Validations } from '../../validations';
import { DestinyPoint } from 'src/app/domain/destiny-point';
import { Collaborator } from 'src/app/domain/collaborator';
import { Covenant } from '../../../domain/covenant';
import { Client } from '../../../domain/client';

@Component({
  selector: 'app-trip-request-update',
  templateUrl: './trip-request-update.component.html',
  styleUrls: ['./trip-request-update.component.scss'],
})
export class TripRequestUpdateComponent implements OnInit {
  tripId: number;
  tripRequest;

  message: string = '';

  nameTrip: String = '';

  tripForm: FormGroup;
  searchClientForm: FormGroup;
  loading: boolean;
  states: Array<State> = [];
  originCities: Array<City> = [];
  destinyCities: Array<City> = [];
  paymentMethods: Array<PaymentMethod> = [];
  clientAddress: Address;
  covenantAddress: Address;
  costCenters: Array<CostCenter> = [];
  cars: Array<Car> = [];
  drivers: Array<Driver> = [];
  covenantId: number;
  clientId: number;
  requestValues: Array<RequestValue> = [];
  destinyPoints: Array<DestinyPoint> = [];
  messageError: string = '';
  collaborators: Collaborator[];
  cities: Array<Array<City>> = [];
  selectDestinyCities: Array<Array<City>> = [];

  modalFireCondition: boolean = false;
  collaboratorToEdit: Collaborator;

  covenant?: Covenant;
  client?: Client;

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private serviceTrip: TripRequestService,
    private serviceAddress: AddressService,
    private serviceClient: ClientService,
    private serviceCovenant: CovenantService,
    private serviceCostCenter: CostCenterService,
    private carService: CarService,
    private serviceRequestValue: RequestValueService,
    private paymentMethodService: PaymentMethodsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.tripId = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.createClientForm();
    this.disableForms();
    this.getStates();
    this.getAllCostCenters();
    this.getTripRequest();
  }

  changePassenger(e) {
    this.resetDataForm();
    this.searchClientForm.controls.searchClient.setValue('');
    this.originCities = [];
    this.destinyCities = [];

    if (this.tripForm.controls.passenger.value == 'client') {
      this.getPaymentMethods();
    }
  }

  getTripRequest() {
    this.serviceTrip.getTripRequest(this.tripId).subscribe(
      (data) => {
        this.tripRequest = data;
        this.loading = false;
        this.getAllCars();
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível carregar solicitação!', 'error');
      }
    );
  }

  populateForms() {
    this.tripForm.controls.covenantCode.setValue(this.tripRequest.covenantCode);
    if (this.tripRequest.clientId) {
      this.tripForm.controls.passenger.setValue('client');
      this.getClientSearch();
      this.searchClientForm.controls.searchClient.setValue(
        this.tripRequest.client.name
      );
    } else {
      this.tripForm.controls.passenger.setValue('covenant');
      this.getCovenantSearch();
      this.searchClientForm.controls.searchClient.setValue(
        this.tripRequest.covenant.name
      );
    }
    this.tripForm.controls.car.setValue(this.tripRequest.vehicleId);
    this.getDriversByCar();
    this.tripForm.controls.starttime.setValue(this.tripRequest.startHour);
    this.tripForm.controls.driver.setValue(this.tripRequest.driverId);
    this.tripForm.controls.starttime.setValue(this.tripRequest.startHour);
    this.tripForm.controls.endtime.setValue(this.tripRequest.endHour);
    this.tripForm.controls.paymentMethod.setValue(
      this.tripRequest.paymentMethodId
    );
    this.tripForm.controls.costcenter.setValue(this.tripRequest.costCenterId);
    this.tripForm.controls.valueSolicitation.setValue(
      this.tripRequest.requestValueId
    );
    if (this.tripRequest.shedule) {
      this.tripForm.controls.scheduled.setValue(true);
      this.tripForm.controls.startDate.setValue(
        moment(this.tripRequest.shedule.startDate).format('YYYY-MM-DD')
      );
      this.tripForm.controls.endDate.setValue(
        moment(this.tripRequest.shedule.endDate).format('YYYY-MM-DD')
      );

      if (this.tripRequest.shedule.runRequestScheduleRecursive) {
        this.tripForm.controls.recursive.setValue(true);
        Object.keys(
          this.tripRequest.shedule.runRequestScheduleRecursive
        ).forEach((key) => {
          if (
            typeof this.tripRequest.shedule.runRequestScheduleRecursive[key] ===
              'boolean' &&
            this.tripForm.get(key)
          ) {
            this.tripForm
              .get(key)
              .setValue(
                this.tripRequest.shedule.runRequestScheduleRecursive[key]
              );
          }
        });
      }
    }
    this.tripForm.controls.observation.setValue(this.tripRequest.observation);
  }

  resetDataForm() {
    this.tripForm.controls.covenantId.setValue('');
    this.tripForm.controls.clientId.setValue('');
    this.tripForm.controls.name.setValue('');
    this.tripForm.controls.whatsapp.setValue('');
    this.tripForm.controls.phone.setValue('');
    this.tripForm.controls.starttime.setValue('');
    this.tripForm.controls.endtime.setValue('');
    this.tripForm.controls.costcenter.setValue('');
    this.tripForm.controls.collaborator.setValue('');
    this.tripForm.controls.otherAddress.setValue('');
    this.tripForm.controls.car.setValue('');
    this.tripForm.controls.cost.setValue('');
    this.tripForm.controls.valueSolicitation.setValue('');
    this.tripForm.controls.observation.setValue('');
    this.tripForm.controls.recursive.setValue(false);
    this.tripForm.controls.allDays.setValue(false);
    this.tripForm.controls.sunday.setValue(false);
    this.tripForm.controls.monday.setValue(false);
    this.tripForm.controls.tuesday.setValue(false);
    this.tripForm.controls.wednesday.setValue(false);
    this.tripForm.controls.thursday.setValue(false);
    this.tripForm.controls.friday.setValue(false);
    this.tripForm.controls.saturday.setValue(false);
    this.tripForm.controls.passengers.setValue([]);
    this.tripForm.controls.paymentMethod.setValue('');
  }

  getAllCostCenters() {
    this.serviceCostCenter.getAllCostCenters().subscribe((data) => {
      this.costCenters = data;
      const defaultCostCenter = this.costCenters.find(
        (item) => item.name.toLocaleLowerCase() === 'corridas'
      );
      this.tripForm.get('costcenter')?.patchValue(defaultCostCenter.id);
    });
  }
  getAllCars() {
    this.carService.getCarsActives().subscribe((data) => {
      this.cars = data;
      for (const car of this.cars)
        car.nameCarPlate = `${car.model.name} ${car.carPlate}`;
      this.populateForms();
    });
  }

  createForm() {
    this.tripForm = this.fb.group({
      covenantCode: ['', [Validators.max(999999999999999)]],
      passenger: ['', [Validators.required]],
      name: ['', [Validators.required]],
      whatsapp: ['', [Validations.validwhatsapp]],
      phone: ['', [Validations.validPhone]],
      startDate: [this.getAtualDate(), Validators.required],
      endDate: ['', [ValidationsTripRequest.validaEndDate]],
      starttime: ['', [Validators.required]],
      endtime: ['', [Validators.required]],
      costcenter: ['', [Validators.required]],
      covenantId: [''],
      clientId: [''],
      collaborator: [''],
      otherAddress: [''],
      otherDestinyAddress: [''],
      car: [''],
      driver: [''],
      valueSolicitation: ['', [Validators.required]],
      destinyPoint: ['', [ValidationsTripRequest.validaClient]],
      observation: [''],
      recursive: [false],
      scheduled: [false],
      allDays: [false],
      sunday: [false],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      passengers: this.fb.array([]),
      paymentMethod: ['', [Validators.required]],
      originAddress: this.fb.group({
        id: [''],
        zipcode: ['', [ValidationsTripRequest.validaClient]],
        publicPlace: ['', [ValidationsTripRequest.validaClient]],
        neighborhood: ['', [ValidationsTripRequest.validaClient]],
        state: ['', [ValidationsTripRequest.validaClient]],
        city: ['', [ValidationsTripRequest.validaClient]],
      }),
      destinyAddress: this.fb.group({
        id: [''],
        zipcode: ['', [ValidationsTripRequest.validaClient]],
        publicPlace: ['', [ValidationsTripRequest.validaClient]],
        neighborhood: ['', [ValidationsTripRequest.validaClient]],
        state: ['', [ValidationsTripRequest.validaClient]],
        city: ['', [ValidationsTripRequest.validaClient]],
      }),
    });
  }

  onAddCollaborator(passenger?) {
    const collaborators = this.collaborators.filter(
      (elem) => elem['id'] == this.tripForm.controls.collaborator.value
    );

    if (!collaborators.length) return;

    const collaboratorsAdd = this.passengers.value.filter(
      (elem) => elem['id'] == this.tripForm.controls.collaborator.value
    );
    if (collaboratorsAdd.length == 0) {
      this.passengers.push(this.newCollaborator(collaborators[0], passenger));

      const i = this.passengers.length - 1;

      this.passengers['controls'][i]['controls'].main.setValue(
        passenger?.isMain
      );
      this.passengers['controls'][i]['controls'].destinyPoint.setValue(
        passenger?.destinyPointId
      );

      if (this.covenantAddress.zipCode === passenger?.origin.zipCode) {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(
          true
        );
        this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(
          false
        );
        this.passengers['controls'][i]['controls'].address.disable();
      } else if (
        passenger?.origin.zipCode === collaborators[0]['address'].zipCode
      ) {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(
          false
        );
        this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
        this.passengers['controls'][i]['controls'].address.disable();
      } else {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(
          false
        );
        this.passengers['controls'][i]['controls'].otherAddress.setValue(true);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(
          false
        );
      }

      if (this.covenantAddress.zipCode === passenger?.destiny.zipCode) {
        this.passengers['controls'][i][
          'controls'
        ].covenantDestinyAddress.setValue(true);
        this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
          false
        );
        this.passengers['controls'][i][
          'controls'
        ].clientDestinyAddress.setValue(false);
        this.passengers['controls'][i]['controls'].destinyAddress.disable();
      } else if (
        passenger?.destiny.zipCode === collaborators[0]['address'].zipCode
      ) {
        this.passengers['controls'][i][
          'controls'
        ].covenantDestinyAddress.setValue(false);
        this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
          false
        );
        this.passengers['controls'][i][
          'controls'
        ].clientDestinyAddress.setValue(true);
        this.passengers['controls'][i]['controls'].destinyAddress.disable();
      } else {
        this.passengers['controls'][i][
          'controls'
        ].covenantDestinyAddress.setValue(false);
        this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
          true
        );
        this.passengers['controls'][i][
          'controls'
        ].clientDestinyAddress.setValue(false);
      }

      this.tripForm.controls.collaborator.setValue('');
    } else {
      Swal.fire(
        'Atenção',
        'Colaborador já foi adicionado à Solicitação',
        'warning'
      );
    }
  }
  removeCollaborator(i: number) {
    this.passengers.removeAt(i);
  }

  editCollaborator(passenger: FormGroup) {
    const { id } = passenger.value;

    this.collaboratorToEdit = this.collaborators.find(
      (elem) => +elem.id === +id
    );
    this.modalFireCondition = true;
  }

  newCollaborator(collaborator, passenger?): FormGroup {
    if (!collaborator) return;
    this.serviceAddress
      .getCitiesByStateId(collaborator.address?.city?.state?.id)
      .subscribe((data) => {
        this.cities.push(data);
        this.selectDestinyCities.push(data);
      });

    //this.serviceAddress.getCitiesByStateId(collaborator.address.city.state.id).subscribe((data => this.cities.push(data)));

    if (!passenger) {
      return this.fb.group({
        id: [collaborator.id],
        name: collaborator.name,
        phone: collaborator.phone,
        whatsapp: collaborator.whatsapp,
        costCenter: collaborator.costCenter,
        main: false,
        destinyPoint: '',
        otherAddress: false,
        covenantAddress: false,
        clientAddress: false,
        otherDestinyAddress: true,
        covenantDestinyAddress: false,
        clientDestinyAddress: false,
        address: this.fb.group({
          id: [collaborator.address.id],
          zipcode: [collaborator.address.zipCode, [Validators.maxLength(9)]],
          publicPlace: [collaborator.address.publicPlace, []],
          neighborhood: [collaborator.address.neighborhood, []],
          state: [collaborator.address.city.state.id, []],
          city: [collaborator.address.city.id, []],
        }),
        destinyAddress: this.fb.group({
          id: [''],
          zipcode: ['', [Validators.required, Validators.maxLength(9)]],
          publicPlace: ['', [Validators.required]],
          neighborhood: ['', [Validators.required]],
          state: ['', [Validators.required]],
          city: ['', [Validators.required]],
        }),
      });
    } else {
      return this.fb.group({
        id: [collaborator.id],
        name: collaborator.name,
        phone: collaborator.phone,
        whatsapp: collaborator.whatsapp,
        costCenter: collaborator.costCenter,
        main: false,
        destinyPoint: '',
        otherAddress: false,
        covenantAddress: false,
        clientAddress: true,
        otherDestinyAddress: true,
        covenantDestinyAddress: false,
        clientDestinyAddress: false,
        address: this.fb.group({
          id: [passenger.origin.id],
          zipcode: [passenger.origin.zipCode, [Validators.maxLength(9)]],
          publicPlace: [passenger.origin.publicPlace, []],
          neighborhood: [passenger.origin.neighborhood, []],
          state: [passenger.origin.city.state.id, []],
          city: [passenger.origin.city.id, []],
        }),
        destinyAddress: this.fb.group({
          id: [passenger.destiny.id],
          zipcode: [passenger.destiny.zipCode, [Validators.maxLength(9)]],
          publicPlace: [passenger.destiny.publicPlace, []],
          neighborhood: [passenger.destiny.neighborhood, []],
          state: [passenger.destiny.city.state.id, []],
          city: [passenger.destiny.city.id, []],
        }),
      });
    }
  }

  selectRequestValue() {
    const requestValue = this.requestValues.find(
      (elem) => this.tripForm.controls.valueSolicitation.value == elem.id
    );
    this.destinyPoints = requestValue ? requestValue.destinyPoints : [];
  }

  get passengers() {
    return this.tripForm.get('passengers') as FormArray;
  }

  onChangeScheduded(e) {
    if (e.target.checked) {
      this.tripForm.controls.scheduled.setValue(true);
      this.tripForm.controls.startDate.setValue(this.getAtualDate());
    } else {
      this.tripForm.controls.scheduled.setValue(false);
    }
  }
  onClickCovenantAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.disable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(true);
      this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.enable();
      // const collaborators = this.collaborators.filter(elem => elem['id'] == this.passengers['controls'][i]['controls'].id.value);
      this.setAddressPassengers(i, this.covenantAddress);
    } else {
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      this.setAddressPassengers(i, collaborators[0]['address']);
      this.passengers['controls'][i]['controls'].address.disable();
    }
  }
  onClickClientAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.disable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setAddressPassengers(i, collaborators[0]['address']);
    }
  }
  onClickOtherAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].address.enable();
      this.passengers['controls'][i]['controls'].clientAddress.enable();
      this.passengers['controls'][i]['controls'].covenantAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      this.cities[i] = [];
      this.resetValueAddressPassenger(i);
    } else {
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      this.passengers['controls'][i]['controls'].clientAddress.disable();
      this.setAddressPassengers(i, collaborators[0]['address']);
      this.passengers['controls'][i]['controls'].address.disable();
    }
  }
  setAddressPassengers(i, { id, zipCode, publicPlace, neighborhood, city }) {
    this.passengers['controls'][i]['controls'].address.controls.id.setValue(id);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.zipcode.setValue(zipCode);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.publicPlace.setValue(publicPlace);
    this.passengers['controls'][i][
      'controls'
    ].address.controls.neighborhood.setValue(neighborhood);
    this.passengers['controls'][i]['controls'].address.controls.state.setValue(
      city.state.id
    );
    this.serviceAddress.getCitiesByStateId(city.state.id).subscribe(
      (data) => {
        this.cities[i] = data;
        this.passengers['controls'][i][
          'controls'
        ].address.controls.city.setValue(city.id);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
      }
    );
  }

  onClickCovenantDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(true);
      this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.enable();
      // const collaborators = this.collaborators.filter(elem => elem['id'] == this.passengers['controls'][i]['controls'].id.value);
      this.setDestinyAddressPassengers(i, this.covenantAddress);
    } else {
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      this.setDestinyAddressPassengers(i, collaborators[0]['address']);
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
    }
  }

  onClickClientDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(false);
      this.passengers['controls'][i]['controls'].otherDestinyAddress.setValue(
        false
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.setDestinyAddressPassengers(i, collaborators[0]['address']);
    }
  }

  onClickOtherDestinyAddress(e, i) {
    if (e.target.checked) {
      this.passengers['controls'][i]['controls'].destinyAddress.enable();
      this.passengers['controls'][i]['controls'].clientDestinyAddress.enable();
      this.passengers['controls'][i][
        'controls'
      ].covenantDestinyAddress.setValue(false);
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        false
      );
      this.selectDestinyCities[i] = [];
      this.resetValueDestinyAddressPassenger(i);
    } else {
      const collaborators = this.collaborators.filter(
        (elem) =>
          elem['id'] == this.passengers['controls'][i]['controls'].id.value
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.setValue(
        true
      );
      this.passengers['controls'][i]['controls'].clientDestinyAddress.disable();
      this.setDestinyAddressPassengers(i, collaborators[0]['address']);
      this.passengers['controls'][i]['controls'].destinyAddress.disable();
    }
  }

  setDestinyAddressPassengers(
    i,
    { id, zipCode, publicPlace, neighborhood, city }
  ) {
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.id.setValue(id);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.zipcode.setValue(zipCode);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.publicPlace.setValue(publicPlace);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.neighborhood.setValue(neighborhood);
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.state.setValue(city.state.id);
    this.serviceAddress.getCitiesByStateId(city.state.id).subscribe(
      (data) => {
        this.selectDestinyCities[i] = data;
        this.passengers['controls'][i][
          'controls'
        ].destinyAddress.controls.city.setValue(city.id);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
      }
    );
  }

  resetValueDestinyAddressPassenger(i) {
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.id.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.zipcode.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.neighborhood.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.publicPlace.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.state.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].destinyAddress.controls.city.setValue('');
  }

  /* setCheckedAddress() {

    this.tripRequest.passengers.forEach( ({id, origin}, i) => {
      const collaborators = this.collaborators.filter(elem => elem['id'] == id);
      const collaboratorsAddress = collaborators[0]['address'];

      if (this.covenantAddress.zipCode === origin.zipCode) {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(true);
        this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      } else if (collaboratorsAddress.zipCode === origin.zipCode) {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(false);
        this.passengers['controls'][i]['controls'].otherAddress.setValue(false);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(true);
      } else {
        this.passengers['controls'][i]['controls'].covenantAddress.setValue(false);
        this.passengers['controls'][i]['controls'].otherAddress.setValue(true);
        this.passengers['controls'][i]['controls'].clientAddress.setValue(false);
      }
    })
  } */

  resetValueAddressPassenger(i) {
    this.passengers['controls'][i][
      'controls'
    ].address.controls.zipcode.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].address.controls.neighborhood.setValue('');
    this.passengers['controls'][i][
      'controls'
    ].address.controls.publicPlace.setValue('');
    this.passengers['controls'][i]['controls'].address.controls.state.setValue(
      ''
    );
    this.passengers['controls'][i]['controls'].address.controls.city.setValue(
      ''
    );
  }

  onCheckboxChangeRecursive(e) {
    if (e.target.checked) {
      this.tripForm.controls.recursive.setValue(true);
    } else {
      this.tripForm.controls.recursive.setValue(false);
    }
  }
  onCheckboxChangeAllDays(e) {
    if (e.target.checked) {
      this.tripForm.controls.sunday.setValue(true);
      this.tripForm.controls.monday.setValue(true);
      this.tripForm.controls.tuesday.setValue(true);
      this.tripForm.controls.wednesday.setValue(true);
      this.tripForm.controls.thursday.setValue(true);
      this.tripForm.controls.friday.setValue(true);
      this.tripForm.controls.saturday.setValue(true);

      this.tripForm.controls.sunday.disable();
      this.tripForm.controls.monday.disable();
      this.tripForm.controls.tuesday.disable();
      this.tripForm.controls.wednesday.disable();
      this.tripForm.controls.thursday.disable();
      this.tripForm.controls.friday.disable();
      this.tripForm.controls.saturday.disable();
    } else {
      this.tripForm.controls.sunday.setValue(false);
      this.tripForm.controls.monday.setValue(false);
      this.tripForm.controls.tuesday.setValue(false);
      this.tripForm.controls.wednesday.setValue(false);
      this.tripForm.controls.thursday.setValue(false);
      this.tripForm.controls.friday.setValue(false);
      this.tripForm.controls.saturday.setValue(false);

      this.tripForm.controls.sunday.enable();
      this.tripForm.controls.monday.enable();
      this.tripForm.controls.tuesday.enable();
      this.tripForm.controls.wednesday.enable();
      this.tripForm.controls.thursday.enable();
      this.tripForm.controls.friday.enable();
      this.tripForm.controls.saturday.enable();
    }
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.tripForm.controls.originAddress.get('id').setValue('');
      this.tripForm.controls.originAddress.get('zipcode').setValue('');
      this.tripForm.controls.originAddress.get('publicPlace').setValue('');
      this.tripForm.controls.originAddress.get('state').setValue('');
      this.tripForm.controls.originAddress.get('neighborhood').setValue('');
      this.tripForm.controls.originAddress.get('city').setValue('');

      this.tripForm.controls.originAddress.get('zipcode').enable();
      this.tripForm.controls.originAddress.get('publicPlace').enable();
      this.tripForm.controls.originAddress.get('city').enable();
      this.tripForm.controls.originAddress.get('state').enable();
      this.tripForm.controls.originAddress.get('neighborhood').enable();
    } else {
      this.tripForm.controls.originAddress.get('zipcode').disable();
      this.tripForm.controls.originAddress.get('publicPlace').disable();
      this.tripForm.controls.originAddress.get('city').disable();
      this.tripForm.controls.originAddress.get('state').disable();
      this.tripForm.controls.originAddress.get('neighborhood').disable();

      // reinserindo os dados de cliente após bloquear os dados
      this.tripForm.controls.originAddress
        .get('id')
        .setValue(this.clientAddress.id);
      this.tripForm.controls.originAddress
        .get('zipcode')
        .setValue(this.clientAddress.zipCode);
      this.tripForm.controls.originAddress
        .get('publicPlace')
        .setValue(this.clientAddress.publicPlace);
      this.tripForm.controls.originAddress
        .get('state')
        .setValue(this.clientAddress.city.state.id);
      this.tripForm.controls.originAddress
        .get('neighborhood')
        .setValue(this.clientAddress.neighborhood);
      this.tripForm.controls.originAddress
        .get('city')
        .setValue(this.clientAddress.city.id);
      this.serviceAddress
        .getCitiesByStateId(this.clientAddress.city.state.id)
        .subscribe((data) => {
          this.originCities = data;
        });
    }
  }

  onCheckboxChangeDestinyAddress(e) {
    if (e.target.checked) {
      this.tripForm.controls.destinyAddress.get('id').setValue('');
      this.tripForm.controls.destinyAddress.get('zipcode').setValue('');
      this.tripForm.controls.destinyAddress.get('publicPlace').setValue('');
      this.tripForm.controls.destinyAddress.get('state').setValue('');
      this.tripForm.controls.destinyAddress.get('neighborhood').setValue('');
      this.tripForm.controls.destinyAddress.get('city').setValue('');

      this.tripForm.controls.destinyAddress.get('zipcode').enable();
      this.tripForm.controls.destinyAddress.get('publicPlace').enable();
      this.tripForm.controls.destinyAddress.get('city').enable();
      this.tripForm.controls.destinyAddress.get('state').enable();
      this.tripForm.controls.destinyAddress.get('neighborhood').enable();
    } else {
      this.tripForm.controls.destinyAddress.get('zipcode').disable();
      this.tripForm.controls.destinyAddress.get('publicPlace').disable();
      this.tripForm.controls.destinyAddress.get('city').disable();
      this.tripForm.controls.destinyAddress.get('state').disable();
      this.tripForm.controls.destinyAddress.get('neighborhood').disable();

      // reinserindo os dados de cliente após bloquear os dados
      this.tripForm.controls.destinyAddress
        .get('id')
        .setValue(this.clientAddress.id);
      this.tripForm.controls.destinyAddress
        .get('zipcode')
        .setValue(this.clientAddress.zipCode);
      this.tripForm.controls.destinyAddress
        .get('publicPlace')
        .setValue(this.clientAddress.publicPlace);
      this.tripForm.controls.destinyAddress
        .get('state')
        .setValue(this.clientAddress.city.state.id);
      this.tripForm.controls.destinyAddress
        .get('neighborhood')
        .setValue(this.clientAddress.neighborhood);
      this.tripForm.controls.destinyAddress
        .get('city')
        .setValue(this.clientAddress.city.id);
      this.serviceAddress
        .getCitiesByStateId(this.clientAddress.city.state.id)
        .subscribe((data) => {
          this.originCities = data;
        });
    }
  }

  getStates() {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  getSearchUser() {
    if (this.tripForm.controls.passenger.value == 'client') {
      this.getClientSearch();
    }
    if (this.tripForm.controls.passenger.value == 'covenant') {
      this.getCovenantSearch();
    }
  }
  getCovenantSearch() {
    this.serviceCovenant
      .getCovenant(this.tripRequest.covenantId)
      .subscribe((data) => {
        const value = data;
        this.covenant = value;
        if (data) {
          const { address } = value;
          this.covenantAddress = new Address();
          this.covenantAddress.zipCode = address.zipCode;
          this.covenantAddress.neighborhood = address.neighborhood;
          this.covenantAddress.publicPlace = address.publicPlace;
          this.covenantAddress.city = address.city;

          this.covenantId = value.id;
          this.tripForm.controls.covenantId.setValue(value.id);
          this.tripForm.controls.name.setValue(value.name);
          this.tripForm.controls.phone.setValue(value.phone);
          this.tripForm.controls.whatsapp.setValue(value.whatsapp);
          this.tripForm.controls.originAddress
            .get('id')
            .setValue(value.address.id);
          this.tripForm.controls.originAddress
            .get('zipcode')
            .setValue(value.address.zipCode);
          this.tripForm.controls.originAddress
            .get('publicPlace')
            .setValue(value.address.publicPlace);
          this.tripForm.controls.originAddress
            .get('state')
            .setValue(value.address.city.state.id);
          this.tripForm.controls.originAddress
            .get('neighborhood')
            .setValue(value.address.neighborhood);
          this.tripForm.controls.originAddress
            .get('city')
            .setValue(value.address.city.id);

          this.serviceAddress
            .getCitiesByStateId(value.address.city.state.id)
            .subscribe((data) => {
              this.originCities = data;
            });

          this.paymentMethods = value.covenantPaymentMethods;

          this.requestValues = [];
          value.covenantRequestValues.forEach((element) => {
            this.requestValues.push(element.requestValue);
          });
          this.selectRequestValue();
          this.serviceCovenant
            .getCollaborators(this.covenantId, '')
            .subscribe((data) => {
              this.collaborators = data['results'];
              this.tripRequest.passengers.forEach((passenger) => {
                this.tripForm.controls.collaborator.setValue(
                  passenger.collaboratorId
                );
                const findCollaborator = this.collaborators.find(
                  (e) => e['id'] === passenger.id
                );
                if (!findCollaborator) {
                  const { origin: address, collaborator } = passenger;
                  this.collaborators.push({ ...collaborator, address });
                }
                this.onAddCollaborator(passenger);
              });
              this.collaborators = this.collaborators.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
            });
        } else {
          this.resetDataForm();
          this.searchClientForm.controls.searchClient.setErrors({
            invalid: 'Convênio não encontrado!',
          });
        }
      });
  }

  getClientSearch() {
    this.serviceClient
      .getClient(this.tripRequest.clientId)
      .subscribe((data) => {
        const value = data;
        this.client = value;
        if (data) {
          const { address } = value;
          this.clientAddress = new Address();
          this.clientAddress.zipCode = address.zipCode;
          this.clientAddress.neighborhood = address.neighborhood;
          this.clientAddress.publicPlace = address.publicPlace;
          this.clientAddress.city = address.city;

          this.clientId = value.id;

          this.tripForm.controls.clientId.setValue(value.id);

          this.tripForm.controls.name.setValue(value.name);
          this.tripForm.controls.phone.setValue(value.phone);
          this.tripForm.controls.whatsapp.setValue(value.whatsapp);

          if (
            this.tripRequest.passengers[0].origin.zipCode !== address.zipCode
          ) {
            this.tripForm.controls.otherAddress.setValue(true);
            this.tripForm.controls.originAddress.get('zipcode').enable();
            this.tripForm.controls.originAddress.get('publicPlace').enable();
            this.tripForm.controls.originAddress.get('city').enable();
            this.tripForm.controls.originAddress.get('state').enable();
            this.tripForm.controls.originAddress.get('neighborhood').enable();
          } else {
            this.tripForm.controls.originAddress.get('zipcode').disable();
            this.tripForm.controls.originAddress.get('publicPlace').disable();
            this.tripForm.controls.originAddress.get('city').disable();
            this.tripForm.controls.originAddress.get('state').disable();
            this.tripForm.controls.originAddress.get('neighborhood').disable();
          }

          if (
            this.tripRequest.passengers[0].destiny.zipCode !== address.zipCode
          ) {
            this.tripForm.controls.otherDestinyAddress.setValue(true);
            this.tripForm.controls.destinyAddress.get('zipcode').enable();
            this.tripForm.controls.destinyAddress.get('publicPlace').enable();
            this.tripForm.controls.destinyAddress.get('city').enable();
            this.tripForm.controls.destinyAddress.get('state').enable();
            this.tripForm.controls.destinyAddress.get('neighborhood').enable();
          } else {
            this.tripForm.controls.destinyAddress.get('zipcode').disable();
            this.tripForm.controls.destinyAddress.get('publicPlace').disable();
            this.tripForm.controls.destinyAddress.get('city').disable();
            this.tripForm.controls.destinyAddress.get('state').disable();
            this.tripForm.controls.destinyAddress.get('neighborhood').disable();
          }

          this.tripForm.controls.originAddress
            .get('id')
            .setValue(this.tripRequest.passengers[0].origin.id);
          this.tripForm.controls.originAddress
            .get('zipcode')
            .setValue(this.tripRequest.passengers[0].origin.zipCode);
          this.tripForm.controls.originAddress
            .get('publicPlace')
            .setValue(this.tripRequest.passengers[0].origin.publicPlace);
          this.tripForm.controls.originAddress
            .get('state')
            .setValue(this.tripRequest.passengers[0].origin.city.state.id);
          this.tripForm.controls.originAddress
            .get('neighborhood')
            .setValue(this.tripRequest.passengers[0].origin.neighborhood);
          this.tripForm.controls.originAddress
            .get('city')
            .setValue(this.tripRequest.passengers[0].origin.city.id);

          this.serviceAddress
            .getCitiesByStateId(
              this.tripRequest.passengers[0].origin.city.state.id
            )
            .subscribe((data) => {
              this.originCities = data;
            });

          this.tripForm.controls.destinyPoint.setValue(
            this.tripRequest.passengers[0].destinyPointId
          );
          this.tripForm.controls.destinyAddress
            .get('id')
            .setValue(this.tripRequest.passengers[0].destiny.id);
          this.tripForm.controls.destinyAddress
            .get('zipcode')
            .setValue(this.tripRequest.passengers[0].destiny.zipCode);
          this.tripForm.controls.destinyAddress
            .get('publicPlace')
            .setValue(this.tripRequest.passengers[0].destiny.publicPlace);
          this.tripForm.controls.destinyAddress
            .get('state')
            .setValue(this.tripRequest.passengers[0].destiny.city.state.id);
          this.tripForm.controls.destinyAddress
            .get('neighborhood')
            .setValue(this.tripRequest.passengers[0].destiny.neighborhood);
          this.tripForm.controls.destinyAddress
            .get('city')
            .setValue(this.tripRequest.passengers[0].destiny.city.id);

          this.serviceAddress
            .getCitiesByStateId(
              this.tripRequest.passengers[0].destiny.city.state.id
            )
            .subscribe((data) => {
              this.destinyCities = data;
            });

          this.serviceRequestValue
            .getAllActiveRequestValueClient()
            .subscribe((data) => {
              this.requestValues = data;
              this.selectRequestValue();
            });

          this.paymentMethods = value.paymentMethods;
        } else {
          this.resetDataForm();
          this.searchClientForm.controls.searchClient.setErrors({
            invalid: 'Cliente não encontrado!',
          });
        }
      });
  }
  getPaymentMethods() {
    this.paymentMethodService
      .getPaymentMethods()
      .subscribe((data) => (this.paymentMethods = data));
  }
  disableForms() {
    this.tripForm.controls.name.disable();
    this.tripForm.controls.whatsapp.disable();
    this.tripForm.controls.phone.disable();
    this.tripForm.controls.originAddress.get('zipcode').disable();
    this.tripForm.controls.originAddress.get('publicPlace').disable();
    this.tripForm.controls.originAddress.get('city').disable();
    this.tripForm.controls.originAddress.get('state').disable();
    this.tripForm.controls.originAddress.get('neighborhood').disable();
  }
  createClientForm() {
    this.searchClientForm = this.fb.group({
      searchClient: ['', [Validators.required]],
    });
  }

  totalSolicitation() {
    const { passenger, destinyPoint } = this.tripForm.getRawValue();

    const requestValue = this.requestValues.find(
      (elem) => this.tripForm.controls.valueSolicitation.value == elem.id
    );

    let totalSolicitationValue = 0;
    switch (passenger) {
      case 'client':
        const dp = requestValue.destinyPoints.find(
          (element) => element.id == destinyPoint
        );

        totalSolicitationValue =
          (requestValue?.value ?? 0) + Number(dp?.value ?? 0);
        break;
      case 'covenant':
        const passengers = this.passengers.getRawValue();
        const destinyPoints = passengers.reduce((acc, item) => {
          const destiny = this.destinyPoints.find(
            (elem) => item.destinyPoint == elem.id
          );
          if (destiny) {
            const index = acc.findIndex((a) => a?.id === destiny.id);

            if (index == -1) {
              acc.push({ id: destiny.id, value: destiny.value });
              // acc[index].id = destiny.id;
              // acc[index].value = destiny.value;
            }
          }
          return acc;
        }, []);

        const valuePoints = destinyPoints.reduce((acc, item) => {
          acc += Number(item.value);
          return acc;
        }, 0);

        const origins = passengers.reduce((acc, item) => {
          const index = acc.findIndex((a) => a === item.address.zipcode);
          if (index == -1) {
            acc.push(item.address.zipcode);
          }
          return acc;
        }, []);
        totalSolicitationValue =
          (requestValue?.value ?? 0) +
          (valuePoints ?? 0) +
          ((origins?.length != 0 ? origins?.length - 1 : 0) *
            requestValue?.valueOriginPoint ?? 0);
        break;

      default:
        break;
    }

    let { initialHour, endHour, percentagePenalty } = requestValue ?? {};

    if (initialHour && endHour && percentagePenalty) {
      const beginningTime = moment(initialHour, 'hh:mm');
      const endTime = moment(endHour, 'hh:mm');
      const beginingRunTime = moment(
        this.tripForm.controls.starttime.value,
        'hh:mm'
      );

      const hasPenality =
        (beginningTime.isBefore(endTime) &&
          beginingRunTime.isSameOrAfter(beginningTime)) ||
        (beginningTime.isAfter(endTime) &&
          (beginingRunTime.isSameOrAfter(beginningTime) ||
            beginingRunTime.isSameOrBefore(endTime)));

      if (hasPenality) {
        const overtimePenalityValue =
          totalSolicitationValue * percentagePenalty;
        totalSolicitationValue += overtimePenalityValue;
      }
    }

    return totalSolicitationValue;
  }

  onBlurCepOrigin(event) {
    // event.target.value
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      this.buscaCep(cep).subscribe((viacep) => {
        if (!viacep.erro) {
          this.originCities = [];

          this.tripForm.controls.originAddress.get('id').setValue('');
          this.tripForm.controls.originAddress
            .get('neighborhood')
            .setValue(viacep.bairro);
          this.tripForm.controls.originAddress
            .get('publicPlace')
            .setValue(viacep.logradouro);

          this.tripForm.controls.originAddress.get('city').setValue('');
          this.tripForm.controls.originAddress.get('state').setValue('');

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );

          this.tripForm.controls.originAddress.get('state').setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.originCities = data;
              const city = this.originCities.find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                this.tripForm.controls.originAddress
                  .get('city')
                  .setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );
        } else {
          this.loading = false;
          this.tripForm.controls.originAddress
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.originAddress
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  async onBlurCepDestiny(event) {
    // event.target.value
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          this.tripForm.controls.destinyAddress.get('id').setValue('');
          this.tripForm.controls.destinyAddress
            .get('neighborhood')
            .setValue(viacep.bairro);
          this.tripForm.controls.destinyAddress
            .get('publicPlace')
            .setValue(viacep.logradouro);

          this.tripForm.controls.destinyAddress.get('city').setValue('');
          this.tripForm.controls.destinyAddress.get('state').setValue('');

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );

          this.tripForm.controls.destinyAddress.get('state').setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.destinyCities = data;
              const city = this.destinyCities.find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                this.tripForm.controls.destinyAddress
                  .get('city')
                  .setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );
        } else {
          this.loading = false;
          this.tripForm.controls.destinyAddress
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.destinyAddress
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  onBlurCepOriginCollaborator(event, i) {
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      this.buscaCep(cep).subscribe((viacep) => {
        if (!viacep.erro) {
          this.cities[i] = [];

          const { controls } =
            this.passengers['controls'][i]['controls'].address;
          controls.id.setValue(null);
          controls.neighborhood.setValue(viacep.bairro);
          controls.publicPlace.setValue(viacep.logradouro);

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );
          controls.state.setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.cities[i] = data;
              const city = this.cities[i].find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                controls.city.setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );

          controls.city.setValue('');

          this.loading = false;
        } else {
          this.loading = false;
          this.tripForm.controls.passengers
            .get(i)
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.passengers
        .get(i)
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  onBlurCepDestinyCollaborator(event, i) {
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, '');

    if (cep.length === 8) {
      this.buscaCep(cep).subscribe((viacep) => {
        if (!viacep.erro) {
          this.selectDestinyCities[i] = [];

          const { controls } =
            this.passengers['controls'][i]['controls'].destinyAddress;

          controls.id.setValue(null);
          controls.neighborhood.setValue(viacep.bairro);
          controls.publicPlace.setValue(viacep.logradouro);

          const state = this.states.find(
            (state) => state.initials === viacep.uf.toUpperCase()
          );
          controls.state.setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(
            (data) => {
              this.selectDestinyCities[i] = data;
              const city = this.selectDestinyCities[i].find(
                (elem) =>
                  elem.name.normalize('NFD') ===
                  viacep.localidade
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
              );
              if (city) {
                controls.city.setValue(city.id);
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
            }
          );

          controls.city.setValue('');

          this.loading = false;
        } else {
          this.loading = false;
          this.tripForm.controls.passengers
            .get(i)
            .get('zipcode')
            .setErrors({ invalid: 'CEP inválido!' });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.passengers
        .get(i)
        .get('zipcode')
        .setErrors({ invalid: 'CEP inválido!' });
    }
  }

  buscaCep(cep: string) {
    return this.utilsService.getCep(cep);
  }

  onSelectedStateCollaborator(event, id) {
    this.serviceAddress
      .getCitiesByStateId(event.target.value)
      .subscribe((data) => {
        this.cities[id] = data;
      });
  }
  onSelectedStateOrigin(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.originCities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }
  onSelectedStateDestiny(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.destinyCities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  getDriversByCar() {
    if (this.tripForm.controls.car.value) {
      this.loading = true;
      this.carService
        .getDriversByCar(this.tripForm.controls.car.value)
        .subscribe(
          (data) => {
            this.drivers = data;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            Swal.fire(
              'Erro',
              'Não foi possível carregar os motoristas deste veículo! Selecione o Veículo novamente.',
              'error'
            );
          }
        );
    }
  }

  getAtualDate() {
    return moment().format('yyyy-MM-DD');
  }

  onSubmit() {
    const {
      covenantCode,
      passenger,
      passengers,
      covenantId,
      clientId,
      costcenter,
      paymentMethod,
      scheduled,
      recursive,
      startDate,
      endDate,
      starttime,
      endtime,
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      originAddress,
      destinyAddress,
      destinyPoint,
      car,
      driver,
      friday,
      saturday,
      observation,
      valueSolicitation,
      cost,
    } = this.tripForm.controls;

    this.messageError = '';

    switch (passenger.value) {
      case 'covenant':
        const passengersSelected = [];
        passengers['controls'].forEach((passenger) => {
          passengersSelected.push({
            id: passenger.controls.id.value,
            isMain: passenger.controls.main.value ?? false,
            destinyPoint: passenger.controls.destinyPoint.value,
            originAddress: {
              originAddressId: passenger.controls.address.controls.id.value,
              zipCode: passenger.controls.address.controls.zipcode.value,
              publicPlace:
                passenger.controls.address.controls.publicPlace.value,
              neighborhood:
                passenger.controls.address.controls.neighborhood.value,
              cityId: passenger.controls.address.controls.city.value,
            },
            destinyAddress: {
              destinyAddressId:
                passenger.controls.destinyAddress.controls.id.value,
              zipCode: passenger.controls.destinyAddress.controls.zipcode.value,
              publicPlace:
                passenger.controls.destinyAddress.controls.publicPlace.value,
              neighborhood:
                passenger.controls.destinyAddress.controls.neighborhood.value,
              cityId: passenger.controls.destinyAddress.controls.city.value,
            },
          });
        });

        var value = {};
        const _startDate = startDate.value == '' ? null : startDate.value;
        const _endDate = !recursive.value
          ? _startDate
          : endDate.value == ''
          ? null
          : endDate.value;
        value = {
          covenantCode: covenantCode.value,
          id: this.tripId,
          covenant: { id: covenantId.value },
          costCenter: { id: Number(costcenter.value) },
          paymentMethod: { id: Number(paymentMethod.value) },
          scheduled: scheduled.value,
          recursive: recursive.value,
          startDate: _startDate,
          endDate: _endDate,
          startHour: starttime.value == '' ? null : starttime.value,
          endHour: endtime.value == '' ? null : endtime.value,
          passengers: passengersSelected,
          sunday: sunday.value,
          monday: monday.value,
          tuesday: tuesday.value,
          wednesday: wednesday.value,
          thursday: thursday.value,
          friday: friday.value,
          saturday: saturday.value,
          driver: driver.value ? { id: Number(driver.value) } : null,
          vehicle: car.value ? { id: Number(car.value) } : null,
          requestValue: { id: Number(valueSolicitation.value) },
          runValue: this.totalSolicitation(),
          observation: observation.value,
          status: 'waiting',
        };
        this.serviceTrip.update(value).subscribe(
          (data) => {
            Swal.fire(
              'Salvo',
              'Solicitação de Convênio editada com Sucesso!',
              'success'
            );
            this.loading = false;
            this.router.navigate(['process/trip-request']);
          },
          (error) => {
            Swal.fire(
              'Erro',
              'Erro ao editar Solicitação de Convênio!',
              'error'
            );
            this.setErrors(error.error.errors);
            this.loading = false;
          }
        );
        break;

      case 'client':
        this.loading = true;
        var value = {};
        const _startDateClient = startDate.value == '' ? null : startDate.value;
        const _endDateClient = !recursive.value
          ? _startDateClient
          : endDate.value == ''
          ? null
          : endDate.value;
        value = {
          id: this.tripId,
          client: { id: clientId.value },
          costCenter: { id: Number(costcenter.value) },
          paymentMethod: { id: Number(paymentMethod.value) },
          scheduled: scheduled.value,
          startDate: _startDateClient,
          endDate: _endDateClient,
          startHour: starttime.value == '' ? null : starttime.value,
          endHour: endtime.value == '' ? null : endtime.value,
          sunday: sunday.value,
          monday: monday.value,
          tuesday: tuesday.value,
          wednesday: wednesday.value,
          thursday: thursday.value,
          friday: friday.value,
          saturday: saturday.value,
          vehicle: car.value != '' ? { id: Number(car.value) } : null,
          driver: driver.value != '' ? { id: Number(driver.value) } : null,
          originAddress: {
            originAddressId: originAddress.get('id').value,
            zipCode: originAddress.get('zipcode').value,
            publicPlace: originAddress.get('publicPlace').value,
            neighborhood: originAddress.get('neighborhood').value,
            cityId: Number(originAddress.get('city').value),
          },
          destinyAddress: {
            destinyAddressId: destinyAddress.get('id').value,
            zipCode: destinyAddress.get('zipcode').value,
            publicPlace: destinyAddress.get('publicPlace').value,
            neighborhood: destinyAddress.get('neighborhood').value,
            cityId: Number(destinyAddress.get('city').value),
          },
          requestValue: { id: Number(valueSolicitation.value) },
          destinyPoint: { id: Number(destinyPoint.value) },
          runValue: this.totalSolicitation(),
          observation: observation.value,
          status: 'waiting',
        };
        this.serviceTrip.update(value).subscribe(
          (data) => {
            Swal.fire(
              'Salvo',
              'Solicitação de Cliente editada com Sucesso!',
              'success'
            );
            this.loading = false;
            this.router.navigate(['process/trip-request']);
          },
          (error) => {
            Swal.fire(
              'Erro',
              'Erro ao editar Solicitação de Cliente!',
              'error'
            );
            this.setErrors(error.error.errors);
            this.loading = false;
          }
        );
        break;

      default:
        Swal.fire(
          'Atenção',
          'Tipo de Solicitação não identificada!',
          'warning'
        );
        break;
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      console.log(erro);

      switch (erro.fieldName) {
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  onCollaboratorSearch($e) {
    if ($e.term) {
      this.serviceCovenant
        .getCollaborators(this.covenantId, $e.term)
        .subscribe((response) => {
          response['results']?.forEach((item) => {
            const findCollaborator = this.collaborators.find(
              (e) => e['id'] === item.id
            );
            if (!findCollaborator) {
              this.collaborators = [...this.collaborators, ...[item]].sort(
                (a, b) => a.name.localeCompare(b.name)
              );
            }
          });
        });
    }
  }

  onCloseModalCreateCollaborator(res: any) {
    if (res.id) {
      const passenger = this.newCollaborator(res);
      const index = this.passengers.value.indexOf(
        this.passengers.value.find((elem: any) => +elem.id === +res.id)
      );
      if (index !== -1) {
        const {
          main,
          destinyPoint,
          clientAddress,
          clientDestinyAddress,
          costCenter,
          covenantAddress,
          covenantDestinyAddress,
          otherAddress,
          otherDestinyAddress,
        } = this.passengers['controls'][index].value;

        const { address: originAddress, destinyAddress } =
          this.passengers['controls'][index]['controls'];

        passenger.patchValue({
          main,
          destinyPoint,
          clientAddress,
          clientDestinyAddress,
          costCenter,
          covenantAddress,
          covenantDestinyAddress,
          otherAddress,
          otherDestinyAddress,
        });

        this.passengers['controls'][index] = passenger;

        if (!otherAddress)
          this.passengers['controls'][index]['controls'].address.disable();

        const originAddressPassenger = clientAddress
          ? res.address
          : covenantAddress
          ? this.covenantAddress
          : {
              ...originAddress.value,
              city: {
                id: originAddress.value.city,
                state: { id: originAddress.value.state },
              },
            };

        if (originAddressPassenger.zipCode)
          this.setAddressPassengers(index, originAddressPassenger);

        const destinyAddressPassenger = clientDestinyAddress
          ? res.address
          : covenantDestinyAddress
          ? this.covenantAddress
          : {
              ...destinyAddress.value,
              city: {
                id: destinyAddress.value.city,
                state: { id: destinyAddress.value.state },
              },
            };
        if (destinyAddressPassenger.zipCode)
          this.setDestinyAddressPassengers(index, destinyAddressPassenger);
      } else {
        this.passengers.push(passenger);
        this.passengers['controls'][this.passengers.length - 1][
          'controls'
        ].address.disable();
        this.passengers['controls'][this.passengers.length - 1][
          'controls'
        ].clientAddress.disable();
        this.passengers['controls'][this.passengers.length - 1][
          'controls'
        ].destinyAddress.disable();
      }
      this.tripForm.controls.collaborator.setValue('');
    }

    const indexCollaborators = this.collaborators.indexOf(
      this.collaborators.find((e) => +e.id === +res.id)
    );
    if (indexCollaborators !== -1) this.collaborators[indexCollaborators] = res;
    else this.collaborators.push(res);

    this.modalFireCondition = false;
  }

  onCovanantUpdated(covenant: Covenant) {
    this.message = 'Convênio atualizado com sucesso!';
    this.ngOnInit();
  }

  onClientUpdated(client: Client) {
    this.message = 'Cliente atualizado com sucesso!';
    this.ngOnInit();
  }
}
