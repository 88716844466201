
import { Piece } from './piece';
import { Provider } from './provider';

export class CarMaintenance {
    id: number;
    provider: Provider;
    pieces: Array<Piece>;
    value: number;
    paid: boolean;
    odometer: number;
    date: Date;
    active: boolean;

    constructor() { }
}
