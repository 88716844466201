<div class="card-body">
  <h3>Corridas do dia - {{ now }}</h3>
  <div class="table-responsive">
    <table class="table table-striped custom-table table-hover recents">
      <thead>
        <tr>
          <th>Código</th>
          <th>Solicitação</th>
          <th>Data do Serviço</th>
          <th>Hora Início</th>
          <th>Passageiro/Cliente</th>
          <th>Origem</th>
          <th>Destino</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let run of runs">
          <td>
            {{ run.id }}
          </td>
          <td>#{{ run.runRequest.id }}</td>
          <td>
            {{ run.date | date: "dd/MM/yyyy" }}
          </td>
          <td>
            {{ run.startHour }}
          </td>
          <td [innerHtml]="getPassengersName(run.runPassengers)"></td>
          <td>
            {{ run.origin }}
          </td>
          <td>
            {{ run.destiny }}
          </td>
          <td>
            {{ run.status }}
            <span *ngIf="run.driverAccepted" class="btn btn-success btn-xs" title="Aceita pelo motorista">
              <i class="fa fa-check"></i>
            </span>
          </td>
          <td>
            <div class="row">
              <span *ngIf="run.driverId" class="btn btn-info btn-xs" title="{{ run.driver?.name }}">
                <i class="fa fa-user"></i>
              </span>
              <a routerLink="/process/runs/{{ run.id }}" class="btn btn-primary btn-xs" title="Visualizar corrida">
                <i class="fa fa-eye"></i>
              </a>
              <a *ngIf="!run.driverPaymentId" routerLink="/process/runs/{{ run.id }}/edit"
                class="btn btn-warning btn-xs" title="Editar corrida">
                <i class="fa fa-pencil"></i>
              </a>
              <a *ngIf="!run.realEndHour" routerLink="/process/runs/{{ run.id }}/finish"
                class="btn btn-secondary btn-xs" title="Finalizar corrida">
                <i class="fa fa-check"></i>
              </a>
              <button *ngIf="run.status !== 'Cancelada'" (click)="cancelRun(run.id)" class="btn btn-danger btn-xs"
                title="Cancelar Corrida">
                <i class="fa fa-ban"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>