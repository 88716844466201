<div class="card-body">
  <div [formGroup]="filterGroup">
    <div class="row runs-filter-options">
      <div class="col">
        <label style="font-size: 14px">Início</label>
        <input type="date" class="form-control" formControlName="dateStart" />
      </div>
      <div class="col">
        <label style="font-size: 14px">Fim</label>
        <input type="date" class="form-control" formControlName="dateEnd" />
      </div>
      <div class="col">
        <button (click)="getRuns()" class="btn btn-info" title="Buscar">
          <i
            class="fa fa-search fa-lg trip-request__button__search"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div class="row">
      <p *ngIf="filterGroup.invalid" class="fild-invalid">
        Necessário informar início e fim!
      </p>
    </div>
  </div>
  <div class="d-block chart mt-5">
    <h3 class="page-title">Gráfico Chamadas/Hora</h3>
    <canvas
      baseChart
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [plugins]="[]"
      [legend]="barLegend"
      [chartType]="barChartType"
    >
    </canvas>
  </div>
</div>
