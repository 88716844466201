<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb bg-light">
        <div class="container">
          <div class="row mx-3">
            <div class="col-md-10 mt-3">
              <div class="page-title">Cadastrar Centro de Custo</div>
            </div>
            <div>
              <div [formGroup]="costForm">
                <div *ngIf="messageError.length" class="container">
                  <div class="alert alert-danger" role="alert">
                    {{ messageError }}
                  </div>
                </div>
                <div class="form-group container col-12 m-0 p-0">
                  <div class="row">
                    <div class="d-flex flex-row col-12 mt-3">
                      <input class="mt-1" type="checkbox" formControlName="father"
                        (change)="onCheckboxChange($event)" />
                      <label class="ml-2" style="font-size: 16px">Pai</label>
                    </div>

                    <div class="row col-12 m-0 p-0 mt-3">
                      <div class="col-12 col-md-6">
                        <label for="id">Identificador</label>
                        <input type="text" class="form-control" formControlName="identifier" placeholder="Identificador"
                          disabled />
                        <span *ngIf="costForm.controls.identifier.errors">
                          <span *ngIf="costForm.controls.identifier.errors.required"
                            class="text-danger position-right">*Campo Obrigatório</span>
                          <span *ngIf="
                              !costForm.controls.identifier.errors.required &&
                              costForm.controls.identifier.errors.invalid
                            " class="text-danger position-right">*
                            {{ costForm.controls.identifier.errors.invalid }}
                          </span>
                        </span>
                      </div>
                      <!-- <div class="col-sm-2 row ml-3 mt-3">
                                            <input class="mt-1" type="checkbox">
                                            <label class="ml-2" style="font-size: 16px;">Ativo</label>
                                        </div> -->
                      <div class="col-12 col-md-6">
                        <label for="name">Nome</label>
                        <input type="text" class="form-control" placeholder="Nome" formControlName="name" />
                        <span *ngIf="costForm.controls.name.errors">
                          <span *ngIf="costForm.controls.name.errors.required" class="text-danger position-right">*Campo
                            Obrigatório</span>
                          <span *ngIf="
                              !costForm.controls.name.errors.required &&
                              costForm.controls.name.errors.invalid
                            " class="text-danger position-right">* {{ costForm.controls.name.errors.invalid }}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div class="row col-12 m-0 p-0">
                      <div class="col-12 col-md-6">
                        <label for="type">Tipo</label>
                        <select class="form-control select2" formControlName="type">
                          <option value="">Selecione o Tipo</option>
                          <option value="{{ type.name }}" *ngFor="let type of types">
                            {{ type.name }}
                          </option>
                        </select>
                        <span *ngIf="costForm.controls.type.errors">
                          <span *ngIf="costForm.controls.type.errors.required" class="text-danger position-right">*Campo
                            Obrigatório</span>
                          <span *ngIf="
                              !costForm.controls.type.errors.required &&
                              costForm.controls.type.errors.invalid
                            " class="text-danger position-right">* {{ costForm.controls.type.errors.invalid }}
                          </span>
                        </span>
                      </div>
                      <div class="col-12 col-md-6">
                        <label for="costcenter">Centro de Custo</label>
                        <select class="form-control select2" formControlName="costcenter"
                          (change)="onSelectCostCenter($event)">
                          <option value="">Selecione o Centro de Custo</option>
                          <option value="{{ costcenter.id }}" *ngFor="let costcenter of costcenters">
                            {{ costcenter.name }}
                          </option>
                        </select>
                        <!--
                            <span
                            *ngIf="costForm.controls.costcenter.errors && costForm.controls.costcenter.errors.invalid"
                            class="text-danger position-right">*
                            {{costForm.controls.costcenter.errors.invalid}} </span> -->
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center my-3">
                    <div class="m-2">
                      <button class="btn btn-success" (click)="onSubmit()" [disabled]="!costForm.valid">
                        Salvar
                      </button>
                    </div>
                    <div class="m-2">
                      <a routerLink="/financial/cost-center" class="btn btn-danger">Cancelar</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
