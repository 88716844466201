<div class="limiter">
    <div class="container-login100 page-background">
        <div class="wrap-login100">
            <div class="login100-form validate-form" [formGroup]="resetForm">
                <span class="login100-form-logo">
                    <img alt="" src="assets/img/logo_2.png">
                </span>
                <span class="login100-form-title p-b-34 p-t-27">
                    Cadastrar Nova Senha
                </span>
                <div *ngIf="resetForm.controls.password.errors && resetForm.controls.password.errors.token"
                    class="text-center text-danger p-t-5"> {{ resetForm.controls.password.errors.token }} </div>

                <div class="wrap-input100 validate-input" data-validate="Enter username">
                    <input class="input100" type="text" name="email" value="{{email || ''}}" placeholder="Email"
                        disabled>
                    <span class="focus-input100" data-placeholder="&#xf207;"></span>
                </div>
                <div class="wrap-input100 validate-input" data-validate="Enter password">
                    <input class="input100" type="password" name="password" formControlName="password"
                        placeholder="Nova Senha">
                    <span class="focus-input100" data-placeholder="&#xf191;"></span>
                    <span *ngIf="resetForm.controls.password.errors">
                        <span *ngIf="resetForm.controls.password.errors.required"
                            class="text-danger position-right">*Campo
                            Obrigatório</span>
                        <span *ngIf="resetForm.controls.password.errors.minlength"
                            class="text-danger position-right">*Senha deve ter no mínimo 8 caracteres</span>
                        <span
                            *ngIf="!resetForm.controls.password.errors.required && !resetForm.controls.password.errors.minlength && resetForm.controls.password.errors.invalidPassword"
                            class="text-danger position-right">*Senha deve ter letras maiúsculas e minúsculas e
                            números</span>
                    </span>
                </div>
                <div class="wrap-input100 validate-input" data-validate="Enter password">
                    <input class="input100" type="password" name="confirmationPassword"
                        formControlName="confirmationPassword" placeholder="Confirme a Nova Senha">
                    <span class="focus-input100" data-placeholder="&#xf191;"></span>
                    <span
                        *ngIf="resetForm.controls.confirmationPassword.errors && resetForm.controls.confirmationPassword.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                </div>
                <span
                    *ngIf="resetForm.controls.confirmationPassword.errors && resetForm.controls.confirmationPassword.errors.differentPasswords"
                    class="text-danger position-right">*Senhas não coincidem!</span>

                <div class="container-login100-form-btn">
                    <button (click)="onSubmit()" class="login100-form-btn">
                        Enviar
                    </button>
                </div>

                <div class="text-center p-t-50">
                    <a class="txt1" routerLink="convenio/login">
                        Voltar para o Login?
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>