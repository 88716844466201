import { Component, OnInit } from '@angular/core';
import { AccessProfileService } from 'src/app/service/access-profile/access-profile.service';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { PermissionsService } from 'src/app/service/permissions/permissions.service';
import { Permission } from 'src/app/domain/permission';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { AccessProfile } from 'src/app/domain/access-profile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-profile-create',
  templateUrl: './access-profile-create.component.html',
  styleUrls: ['./access-profile-create.component.css'],
})
export class AccessProfileCreateComponent implements OnInit {
  perm: Array<Permission> = [];
  accessProfileForm: FormGroup;
  loading: boolean = true;

  constructor(
    private service: AccessProfileService,
    private servicePermissions: PermissionsService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.servicePermissions.getPermissions().subscribe((data) => {
      this.perm = data;
      data.forEach((permission) => {
        this.addPermissions();
      });
      this.loading = false;
    });
  }

  createForm() {
    this.accessProfileForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.minLength(5),
        ],
      ],
      description: ['', [Validators.maxLength(250)]],
      permissions: this.fb.array([]),
    });
  }
  addPermissions() {
    this.permissions.push(this.fb.control(false));
  }

  get permissions() {
    return this.accessProfileForm.get('permissions') as FormArray;
  }
  onSubmit() {
    if (
      !this.accessProfileForm.controls.permissions.value.some(
        (element) => element === true
      )
    ) {
      Swal.fire(
        'Não foi possível salvar',
        'Para cadastrar um novo Perfil de Acesso você deve associá-lo a pelo menos uma Permissão!',
        'error'
      );
    } else {
      let accessProfile = new AccessProfile();
      accessProfile.name = this.accessProfileForm.controls.name.value;
      accessProfile.description =
        this.accessProfileForm.controls.description.value;

      for (
        let i = 0;
        i < this.accessProfileForm.controls.permissions.value.length;
        i++
      ) {
        if (this.accessProfileForm.controls.permissions.value[i]) {
          accessProfile.permissions.push(this.perm[i]);
        }
      }
      this.loading = true;
      this.service.save(accessProfile).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Salvo',
            'Novo Perfil de Acesso salvo com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['security/access-profile']);
            }
          });
        },
        (error) => {
          this.loading = false;

          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Perfil de Acesso!',
            'error'
          );
        }
      );
    }
  }
  validPermissions() {
    if (
      !this.accessProfileForm.controls.permissions.value.some(
        (element) => element === true
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  resetForm() {
    this.accessProfileForm.controls.name.setValue('');
    this.accessProfileForm.controls.description.setValue('');
    this.perm.forEach((element) => {
      this.addPermissions();
    });
  }
}
