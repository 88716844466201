import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { RequestValue } from 'src/app/domain/request-value';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Validations } from '../../validations';
import { DestinyPoint } from 'src/app/domain/destiny-point';

@Component({
  selector: 'app-request-value-create',
  templateUrl: './request-value-create.component.html',
  styleUrls: ['./request-value-create.component.css']
})
export class RequestValueCreateComponent implements OnInit {
  requestValueForm: FormGroup;
  pointForm: FormGroup;
  points: Array<DestinyPoint> = [];
  loading: boolean = false;
  messageError: string = "";

  constructor(
    private service: RequestValueService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.createPointForm();
  }

  createForm() {
    this.requestValueForm = this.fb.group({
      destiny: ['', [Validators.required, Validators.maxLength(100)]],
      type: ['', [Validators.required]],
      value: ['', [Validators.required]],
      aditionalValue: ['', [Validators.required]],
      tollAmount: ['', []],
      percentage: ['', [Validators.required, Validations.validPercentage]],
      percentagePenalty: ['', [Validators.required,]],
      initialHour: ['', [Validators.required]],
      endHour: ['', [Validators.required]],
      valueOriginPoint: ['', [Validators.required]],
    });
  }
  createPointForm() {
    this.pointForm = this.fb.group({
      namePoint: ['', [Validators.required, Validators.maxLength(100)]],
      valuePoint: ['0', [Validators.required, Validators.maxLength(20)]],
    });
  }

  addPoint() {
    if (!this.pointForm.valid) {
      return;
    }

    const { namePoint: name, valuePoint } = this.pointForm.getRawValue();
    const point = new DestinyPoint(name, Number(valuePoint));

    if (this.points.some((el) => el.name === point.name)) {
      this.pointForm.controls.namePoint.setErrors({ invalid: 'Já existe um destino com esse nome.' })
      return;
    }

    this.points.push(point);
    this.pointForm.reset();
  }

  rmPoint(id: number) {
    this.points.splice(id, 1);
  }

  onSubmit() {
    let requestValue = new RequestValue();
    requestValue.type = this.requestValueForm.controls.type.value;
    requestValue.destiny = this.requestValueForm.controls.destiny.value;
    requestValue.value = Number(this.requestValueForm.controls.value.value);
    requestValue.initialHour = this.requestValueForm.controls.initialHour.value;
    requestValue.endHour = this.requestValueForm.controls.endHour.value;
    requestValue.aditionalValue = this.requestValueForm.controls.aditionalValue.value;
    requestValue.tollAmount = this.requestValueForm.controls.tollAmount.value.length !== 0
      ? this.requestValueForm.controls.tollAmount.value
      : null;
    requestValue.valueOriginPoint = this.requestValueForm.controls.valueOriginPoint.value;
    requestValue.percentage = this.requestValueForm.controls.percentage.value / 100;
    requestValue.percentagePenalty = this.requestValueForm.controls.percentagePenalty.value / 100;


    requestValue.destinyPoints = this.points;

    this.messageError = '';

    console.log(requestValue)

    this.loading = true;
    this.service.save(requestValue).subscribe(data => {
      this.loading = false;
      Swal.fire('Salvo', 'Valor de corrida salvo com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/request-value']);
        }
      });
      this.createForm();
    },
      error => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar valor da corrida!', 'error');
      });

  }

  resetForm() {
    this.requestValueForm.controls.name.setValue("");
    this.requestValueForm.controls.exchangeOdometer.setValue("");
  }

  setErrors(errors) {
    errors.forEach(erro => {
      console.log(erro);

      switch (erro.fieldName) {
        case "destiny":
          this.requestValueForm.controls.destiny.setErrors({ invalid: erro.message });
          break;
        case "value":
          this.requestValueForm.controls.value.setErrors({ invalid: erro.message });
          break;
        case "aditionalValue":
          this.requestValueForm.controls.aditionalValue.setErrors({ invalid: erro.message });
          break;
        case "tollAmount":
          this.requestValueForm.controls.tollAmount.setErrors({ invalid: erro.message });
          break;
        case "percentage":
          this.requestValueForm.controls.percentage.setErrors({ invalid: erro.message });
          break;
        case "initialHour":
          this.requestValueForm.controls.initialHour.setErrors({ invalid: erro.message });
          break;
        case "endHour":
          this.requestValueForm.controls.endHour.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }
}
