<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Aprovar Agendamento de Corrida</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="messageError.length" class="container-fluid mt-2 mb-2">
        <div class="alert alert-danger" role="alert">
          {{ messageError }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <header style="width: 100%">
                <div class="row">
                  <label>Solicitação #{{ tripRequest?.id }}</label>
                </div>
                <div class="row mt-3">
                  <div class="col-md-8">
                    <b class="tripapproval__labels">Cliente/Convênio</b>
                    <p *ngIf="tripRequest?.covenant" class="tripapproval__data">
                      {{ tripRequest?.covenant.name }}
                    </p>
                    <p *ngIf="tripRequest?.client" class="tripapproval__data">
                      {{ tripRequest?.client.name }}
                    </p>
                  </div>
                  <div *ngIf="tripRequest?.covenant" class="col-md-4">
                    <b class="tripapproval__labels">Contato</b>
                    <p class="tripapproval__data">
                      {{ tripRequest?.covenant.contact }}
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-4">
                    <b class="tripapproval__labels">Telefone</b>
                    <p *ngIf="tripRequest?.covenant">
                      {{
                        tripRequest?.covenant.phone
                          | mask
                            : "(00)
                      0000-0000||(00) 0 0000-0000"
                      }}
                    </p>
                    <p *ngIf="tripRequest?.client">
                      {{
                        tripRequest?.client.phone
                          | mask
                            : "(00)
                      0000-0000||(00) 0 0000-0000"
                      }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <b class="tripapproval__labels">Whatsapp</b>
                    <p *ngIf="tripRequest?.covenant">
                      {{
                        tripRequest?.covenant.whatsapp
                          | mask
                            : "+00 00
                      0 0000-0000"
                      }}
                    </p>
                    <p *ngIf="tripRequest?.client">
                      {{
                        tripRequest?.client.whatsapp
                          | mask
                            : "+00 00 0
                      0000-0000"
                      }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <b class="tripapproval__labels">Tipo de Pagamento</b>
                    <p>{{ tripRequest?.paymentMethod.name }}</p>
                  </div>
                </div>
                <!-- <div class="row mt-4">
                                    <div class="col-md-3">
                                        <label class="tripapproval__labels">Data Início:</label>
                                        <label class="tripapproval__data">05/11/2020</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="tripapproval__labels">Hora Início:</label>
                                        <label class="tripapproval__data">09:00</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="tripapproval__labels">Data Fim:</label>
                                        <label class="tripapproval__data">05/11/2020</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="tripapproval__labels">Hora Fim:</label>
                                        <label class="tripapproval__data">22:00</label>
                                    </div>
                                </div> -->
                <div class="row mt-4">
                  <div class="col-md-4">
                    <b class="tripapproval__labels">Valor da Corrida</b>
                    <p>
                      {{ tripRequest?.runValue | currency : "BRL" }}
                    </p>
                  </div>
                </div>
                <!-- <div class="row mt-3 ml-0">
                                    <div class="col-sm-3">
                                        <div class="row">
                                            <input type="checkbox" />
                                            <label class="tripapproval__data ml-1">Agendamento?</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="row">
                                            <input type="checkbox" />
                                            <label class="tripapproval__data ml-1">Recursividade</label>
                                        </div>
                                    </div>
                                </div>-->
                <!-- <div class="row mt-3">
                                    <label>Endereço de Origem</label>
                                </div>
                                <div class="row mt-3 ml-0">
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <input type="checkbox" />
                                            <label class="tripapproval__data ml-1">Buscar no Endereço?</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <input type="checkbox" />
                                            <label class="tripapproval__data ml-1">Buscar em outro Endereço?</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-sm-2">
                                        <label class="tripapproval__labels">Cep:</label>
                                        <label class="tripapproval__data">75120-410</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label class="tripapproval__labels">Logradouro:</label>
                                        <label class="tripapproval__data">Viela VPR-123</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label class="tripapproval__labels">Bairro</label>
                                        <label class="tripapproval__data">Daia</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label class="tripapproval__labels">Cidade:</label>
                                        <label class="tripapproval__data">Anápolis</label>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="tripapproval__labels">Estado</label>
                                        <label class="tripapproval__data">Goiás</label>
                                    </div>
                                </div> -->
              </header>
            </div>
            <div class="card-body">
              <div [formGroup]="linkForm">
                <div class="row mt-4">
                  <div class="col-sm-6">
                    <label style="font-size: 14px">Veículo</label>
                    <select
                      (change)="onSelectedVehicle($event)"
                      class="form-control select2"
                      formControlName="vehicle"
                    >
                      <option value="">Selecione o Veículo</option>
                      <option
                        value="{{ vehicle.id }}"
                        *ngFor="let vehicle of vehicles"
                      >
                        {{ vehicle.model.name }} - {{ vehicle.carPlate }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-6">
                    <label style="font-size: 14px">Motorista</label>
                    <select
                      class="custom-select custom-select"
                      style="font-size: 14px"
                      formControlName="driver"
                    >
                      <option value="">Motorista</option>
                      <option
                        value="{{ driver.id }}"
                        *ngFor="let driver of drivers"
                      >
                        {{ driver.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                                <label>Passageiros</label>
                            </div> -->
              <!-- <div class=" table-responsive">
                                <table class="table table-striped custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Cargo</th>
                                            <th>Principal</th>
                                            <th>CEP</th>
                                            <th>Logradouro</th>
                                            <th>Bairro</th>
                                            <th>Cidade</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Carla Cristina
                                            </td>
                                            <td>
                                                Gerente Administrativa
                                            </td>
                                            <td>
                                                <input type="checkbox" />
                                            </td>
                                            <td>
                                                75.103-123
                                            </td>
                                            <td>
                                                Av. Comercial
                                            </td>
                                            <td>
                                                Bairro de Lourdes
                                            </td>
                                            <td>
                                                Anápolis
                                            </td>
                                            <td>
                                                Goiás
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
              <!-- <div class="row">
                                <div class="col-sm-3">
                                    <label style="font-size: 14px;" class="mt-2">Centro de Custo</label>
                                    <select class="custom-select custom-select" style="font-size: 14px;">
                                        <option value="A">Despesas</option>
                                    </select>
                                </div>
                                <div class="col-sm-3">
                                    <label style="font-size: 14px;" class="mt-2">Veículo</label>
                                    <select class="custom-select custom-select" style="font-size: 14px;">
                                        <option value="A">Corolla</option>
                                    </select>
                                </div>
                                <div class="col-sm-3">
                                    <label style="font-size: 14px;" class="mt-2">Motorista</label>
                                    <select class="custom-select custom-select" style="font-size: 14px;">
                                        <option value="A">Ayrton Senna</option>
                                    </select>
                                </div>
                            </div> -->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <h4>Deseja realmente aprovar a solicitação?</h4>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Não
                      </button>
                      <button
                        data-dismiss="modal"
                        (click)="onAproved()"
                        type="button"
                        class="btn btn-primary"
                      >
                        Sim
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center">
                <div class="col-md-4 text-right mt-4">
                  <button
                    class="btn btn-success"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Aprovar
                  </button>
                </div>
                <div class="col-md-4 text-right mt-4">
                  <a routerLink="/process/trip-request" class="btn btn-danger"
                    >Cancelar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
