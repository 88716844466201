<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Contas a Receber</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                href="/financial/bills-to-receive/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="">Mostrar Todos os Resultados</option>
                </select>
              </div>
              <header [formGroup]="searchForm">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <label style="font-size: 14px" class="mt-2"
                      >Pesquisar</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Pesquisar Cliente/Convênio - por nome, CPF ou CNPJ"
                      formControlName="client_covenant"
                    />
                  </div>

                  <!-- <div class="col-12 col-md-6 col-lg-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Centro de Custo</label
                    >
                    <select
                      class="custom-select custom-select"
                      style="font-size: 14px"
                      formControlName="cost_center"
                    >
                      <option value="">Centro de Custo</option>
                      <option
                        *ngFor="let costCenter of costCenters"
                        value="{{ costCenter.id }}"
                      >
                        {{ costCenter.name }}
                      </option>
                    </select>
                  </div> -->

                  <div class="col-12 col-md-6 col-lg-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Nota Fiscal/Recibo</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nota Fiscal/Recibo"
                      formControlName="invoice_number"
                    />
                  </div>

                  <div class="col-12 col-md-6 col-lg-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Data de Vencimento Inicial</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      formControlName="due_date_start"
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Data de Vencimento Final</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      formControlName="due_date_end"
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Tipo de Recebimento</label
                    >
                    <select
                      class="custom-select custom-select"
                      style="font-size: 14px"
                      formControlName="payment_method"
                    >
                      <option value="">Tipo de Recebimento</option>
                      <option value="billet">Boleto</option>
                      <option value="bank_check">Cheque</option>
                      <option value="deposit">Depósito</option>
                      <option value="money">Dinheiro</option>
                    </select>
                  </div>
                  <div class="row col-12 col-lg-3 m-0 p-0">
                    <div class="col-12 col-md-6">
                      <label style="font-size: 14px" class="mt-2"
                        >Valor Inicial</label
                      >
                      <input
                        type="text"
                        class="form-control money2"
                        placeholder="Inicial"
                        formControlName="value_start"
                      />
                    </div>
                    <label
                      style="font-size: 14px"
                      class="bills-to-receive--label"
                    ></label>
                    <div class="col-12 col-md-6">
                      <label style="font-size: 14px" class="mt-2"
                        >Valor Final</label
                      >
                      <input
                        type="text"
                        class="form-control money2"
                        placeholder="Final"
                        formControlName="value_end"
                      />
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <label style="font-size: 14px" class="mt-2">Status</label>
                    <select
                      class="custom-select custom-select"
                      formControlName="paid"
                      style="font-size: 14px"
                    >
                      <option value="">Todos</option>
                      <option value="false">Aberto</option>
                      <option value="true">Paga</option>
                    </select>
                  </div>
                  <div class="col d-flex align-items-end mt-2">
                    <a class="btn btn-info" (click)="onSearch()">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Cliente/Convênio</th>
                      <!-- <th>Centro de Custo</th> -->
                      <th>Data de Vencimento</th>
                      <th>Valor</th>
                      <th>Tipo de Recebimento</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let billsToReceive of billsToReceives">
                      <!-- <td>{{ billsToReceive.id }}</td> -->
                      <td>
                        {{
                          billsToReceive.client?.name ||
                            billsToReceive.covenant?.name
                        }}
                      </td>
                      <!-- <td>{{ billsToReceive.costCenter?.name }}</td> -->
                      <td>
                        {{ billsToReceive.dueDate | date: "dd/MM/yyyy":"GMT" }}
                      </td>
                      <td>{{ billsToReceive.value | currency: "BRL" }}</td>
                      <td>
                        {{ this.paymentMethods[billsToReceive.paymentMethod] }}
                      </td>
                      <td>{{ billsToReceive.paid ? "Pago" : "Em aberto" }}</td>
                      <td>
                        <div class="row">
                          <div class="col">
                            <a
                              routerLink="/financial/bills-to-receive/view/{{
                                billsToReceive.id
                              }}"
                              class="btn btn-primary btn-xs"
                              alt="Visualizar"
                            >
                              <i class="fa fa-eye"></i>
                            </a>
                            <a
                              routerLink="/financial/bills-to-receive/edit/{{
                                billsToReceive.id
                              }}"
                              class="btn btn-warning btn-xs"
                            >
                              <i class="fa fa-pencil"></i>
                            </a>
                            <a
                              *ngIf="billsToReceive.parcels.length"
                              routerLink="/bills-to-receive/update-parcels/{{
                                billsToReceive.id
                              }}"
                              class="btn btn-info btn-xs"
                            >
                              <i class="fa fa-list"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item" [class.disabled]="!first">
                    <button class="page-link" (click)="onFirst()" tabindex="-1">
                      Primeira
                    </button>
                  </li>
                  <li class="page-item" *ngIf="pagination.page != 1">
                    <button (click)="onPrevious()" class="page-link">
                      {{ pagination.page - 1 }}
                    </button>
                  </li>
                  <li class="page-item active">
                    <button class="page-link">{{ pagination.page }}</button>
                  </li>
                  <li class="page-item" *ngIf="last">
                    <button (click)="onNext()" class="page-link" href="#">
                      {{ pagination.page + 1 }}
                    </button>
                  </li>
                  <li class="page-item" [class.disabled]="!last">
                    <button class="page-link" (click)="onLast()">Última</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-md-12 mb-2">
              Mostrando página {{ pagination.page }} de
              {{ pagination.totalPages }} dos
              {{ pagination.totalResults }} Resultados
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
