import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BillsToPay } from 'src/app/domain/bills-to-pay';
import { Pagination } from 'src/app/domain/pagination';
import { BillsToPayFilter } from 'src/app/domain/dto/filters/bills-to-pay.filter';

@Injectable({
  providedIn: 'root',
})
export class BillsToPayService {
  url: string = `${environment.BASE_URL}/bills-to-pay`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) { }

  getBillsToPay(billsToPayFilters: BillsToPayFilter, pagination: Pagination) {
    const params = {
      page: `${pagination.page}`,
      per_page: `${pagination.per_page}`,
      due_date_start: `${billsToPayFilters.due_date_start ?? ''}`,
      due_date_end: `${billsToPayFilters.due_date_end ?? ''}`,
      value_start: `${billsToPayFilters.value_start ?? ''}`,
      value_end: `${billsToPayFilters.value_end ?? ''}`,
      provider: `${billsToPayFilters.provider.id ?? ''}`,
      paid: `${billsToPayFilters.paid ?? ''}`,
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  save(billsToPay: BillsToPay) {
    return this.http.post(`${this.url}`, JSON.stringify(billsToPay), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getBillToPay(id: number) {
    return this.http.get<BillsToPay>(`${this.url}/${id}`, {
      responseType: 'json',
    });
  }

  update(billsToPay: BillsToPay) {
    return this.http.put(
      `${this.url}/${billsToPay.id}`,
      JSON.stringify(billsToPay),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  updateParcels(billsToPay: BillsToPay) {
    return this.http.put(
      `${this.url}/${billsToPay.id}/update-parcels`,
      JSON.stringify(billsToPay),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
}
