import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/domain/address';
import { City } from 'src/app/domain/city';
import { Covenant } from 'src/app/domain/covenant';
import { State } from 'src/app/domain/state';
import { ViaCep } from 'src/app/domain/viacep';
import { AddressService } from 'src/app/service/address/address.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import Swal from 'sweetalert2';
import { Validations } from '../../validations';

import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from '../../cropper/cropper-dialog';

@Component({
  selector: 'app-covenant-update',
  templateUrl: './covenant-update.component.html',
  styleUrls: ['./covenant-update.component.scss'],
})
export class CovenantUpdateComponent implements OnInit {
  covenantForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  covenant: Covenant = new Covenant();
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];
  covenantPaymentMethods;

  image: File;
  cropped?: string;

  constructor(
    private service: CovenantService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => {
      this.covenant.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getPaymentMethods();

    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
        this.service.getCovenant(this.covenant.id).subscribe(
          (data) => {
            this.covenant = data;
            this.serviceAddress
              .getCitiesByStateId(this.covenant.address.city.state.id)
              .subscribe(
                (data) => {
                  this.cities = data;
                  this.setValues();
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
          },
          (error) => {
            this.loading = false;
            Swal.fire(
              'Erro',
              'Não foi possível Buscar Posto de Combustível!',
              'error'
            );
          }
        );
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  getPaymentMethods() {
    this.loading = true;
    this.service.getPaymentMethods().subscribe(
      (data) => {
        this.covenantPaymentMethods = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openPaymentAlertError();
      }
    );
  }

  openPaymentAlertError() {
    Swal.fire(
      'Ops...',
      'Ocorreu um erro ao carregar as formas de pagamento!',
      'error'
    );
  }

  createForm() {
    this.covenantForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(60)]],
      cpfCnpj: ['', [Validators.required, Validators.maxLength(18)]],
      email: ['', [Validators.maxLength(80), Validators.email]],
      emailContact: ['', [Validators.maxLength(80), Validators.email]],
      stateRegistration: ['', [Validators.maxLength(30)]],
      municipalRegistration: ['', [Validators.maxLength(30)]],
      paymentMethods: ['', Validators.required],
      phone: ['', [Validations.validPhone]],
      whatsapp: ['', [Validations.validwhatsapp]],
      contact: ['', [Validators.maxLength(100)]],
      zipCode: ['', [Validators.required, Validators.maxLength(9)]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      active: ['', [Validators.required]],
      invoice: ['', [Validators.required]],
      shareRun: ['', [Validators.required]],
      imageUrl: [''],
    });
  }

  onSelectedPaymentValue(event) {
    //IMPLEMENTAR
  }

  onRemoveImage(event) {
    this.cropped = '';
    this.image = null;
    this.covenantForm.controls.imageUrl.reset('');
  }

  onSelectImage(file) {
    if (file.size > 10000000) {
      this.image = null;
      Swal.fire('Ops...', 'A imagem não pode ser maior que 10MB!', 'error');
      return false;
    } else {
      this.image = file;
      this.onFileReader();
      return true;
    }
  }

  onFileReader() {
    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () =>
      this.covenantForm.controls.imageUrl.setValue(reader.result);
  }

  setValues() {
    this.covenantForm.controls.name.setValue(this.covenant.name);
    this.covenantForm.controls.cpfCnpj.setValue(this.covenant.cpfCnpj);
    this.covenantForm.controls.email.setValue(this.covenant.email);
    this.covenantForm.controls.emailContact.setValue(
      this.covenant.emailContact
    );
    this.covenantForm.controls.stateRegistration.setValue(
      this.covenant.stateRegistration
    );
    this.covenantForm.controls.municipalRegistration.setValue(
      this.covenant.municipalRegistration
    );
    this.covenantForm.controls.phone.setValue(this.covenant.phone);
    this.covenantForm.controls.whatsapp.setValue(this.covenant.whatsapp);
    this.covenantForm.controls.contact.setValue(this.covenant.contact);
    this.covenantForm.controls.zipCode.setValue(this.covenant.address.zipCode);
    this.covenantForm.controls.neighborhood.setValue(
      this.covenant.address.neighborhood
    );
    this.covenantForm.controls.publicPlace.setValue(
      this.covenant.address.publicPlace
    );
    this.covenantForm.controls.state.setValue(
      this.covenant.address.city.state.id
    );
    this.covenantForm.controls.city.setValue(this.covenant.address.city.id);
    this.covenantForm.controls.active.setValue(this.covenant.active);
    this.covenantForm.controls.invoice.setValue(this.covenant.invoice);
    this.covenantForm.controls.shareRun.setValue(this.covenant.shareRun);
    this.covenantForm.controls.imageUrl.setValue(this.covenant.imageUrl);

    let methods = [];
    this.covenant.covenantPaymentMethods.forEach((method) =>
      methods.push(`${method.id}`)
    );
    this.covenantForm.controls.paymentMethods.setValue(methods);
  }

  onSubmit() {
    this.covenant.address = new Address();
    this.covenant.address.city = new City();
    this.covenant.name = this.covenantForm.controls.name.value;
    this.covenant.cpfCnpj = this.covenantForm.controls.cpfCnpj.value;
    this.covenant.email = this.covenantForm.controls.email.value;
    this.covenant.emailContact = this.covenantForm.controls.emailContact.value;
    this.covenant.stateRegistration =
      this.covenantForm.controls.stateRegistration.value;
    this.covenant.municipalRegistration =
      this.covenantForm.controls.municipalRegistration.value;
    this.covenant.phone = this.covenantForm.controls.phone.value;
    this.covenant.whatsapp = this.covenantForm.controls.whatsapp.value;
    this.covenant.contact = this.covenantForm.controls.contact.value;
    this.covenant.address.zipCode = this.covenantForm.controls.zipCode.value;
    this.covenant.address.neighborhood =
      this.covenantForm.controls.neighborhood.value;
    this.covenant.address.publicPlace =
      this.covenantForm.controls.publicPlace.value;
    this.covenant.address.city.id = this.covenantForm.controls.city.value;
    this.covenant.address.city.state = this.covenantForm.controls.state.value;
    this.covenant.covenantPaymentMethods = [];
    this.covenant.active = this.covenantForm.controls.active.value;
    this.covenant.invoice = this.covenantForm.controls.invoice.value;
    this.covenant.shareRun = this.covenantForm.controls.shareRun.value;

    if (this.covenantForm.controls.paymentMethods.value) {
      this.covenantForm.controls.paymentMethods.value.forEach((method) => {
        this.covenant.covenantPaymentMethods.push({
          id: Number(method),
          name: null,
        });
      });
    }

    this.loading = true;
    this.service.update(this.covenant).subscribe(
      (data) => {
        if (this.image) {
          this.service
            .imageUpload(this.covenant.id, this.image)
            .subscribe((data) => {
              this.loading = false;
              this.showSuccess();
            }),
            (error) => {
              this.loading = false;
              Swal.fire(
                'Erro ao salvar imagem',
                'Convênio salvo, mas não foi possível salvar a imagem!',
                'error'
              );
            };
        } else {
          this.loading = false;
          this.showSuccess();
        }
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao editar',
          'Não foi possível editar Convênio!',
          'error'
        );
      }
    );
  }

  showSuccess() {
    Swal.fire('Editado', 'Convênio editado com sucesso!!', 'success').then(
      (result) => {
        if (result.value) {
          this.router.navigate(['maintenance/covenant']);
        }
      }
    );
  }

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            this.viacep = data;

            if (!this.viacep.erro) {
              this.covenantForm.controls.neighborhood.setValue(
                this.viacep.bairro
              );
              this.covenantForm.controls.publicPlace.setValue(
                this.viacep.logradouro
              );
              const state = this.states.find(
                (state) => state.initials === this.viacep.uf.toUpperCase()
              );

              this.covenantForm.controls.state.setValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      this.viacep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );
                  if (city) {
                    this.covenantForm.controls.city.setValue(city.id);
                  }
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
            } else {
              this.covenantForm.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.covenantForm.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.covenantForm.controls.zipCode.setErrors({
        invalid: 'CEP inválido!',
      });
    }
  }
  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }
  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'name':
          this.covenantForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case 'cpfCnpj':
          this.covenantForm.controls.cpfCnpj.setErrors({
            invalid: erro.message,
          });
          break;
        case 'email':
          this.covenantForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case 'emailContact':
          this.covenantForm.controls.emailContact.setErrors({
            invalid: erro.message,
          });
          break;
        case 'municipalRegistration':
          this.covenantForm.controls.municipalRegistration.setErrors({
            invalid: erro.message,
          });
          break;
        case 'stateRegistration':
          this.covenantForm.controls.stateRegistration.setErrors({
            invalid: erro.message,
          });
          break;
        case 'phone':
          this.covenantForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case 'whatsapp':
          this.covenantForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case 'contact':
          this.covenantForm.controls.contact.setErrors({
            invalid: erro.message,
          });
          break;
        case 'zipCode':
          this.covenantForm.controls.zipCode.setErrors({
            invalid: erro.message,
          });
          break;
        case 'neighborhood':
          this.covenantForm.controls.neighborhood.setErrors({
            invalid: erro.message,
          });
          break;
        case 'publicPlace':
          this.covenantForm.controls.publicPlace.setErrors({
            invalid: erro.message,
          });
          break;
        case 'state':
          this.covenantForm.controls.state.setErrors({ invalid: erro.message });
          break;
        case 'city':
          this.covenantForm.controls.city.setErrors({ invalid: erro.message });
          break;
        case 'invoice':
          this.covenantForm.controls.invoice.setErrors({
            invalid: erro.message,
          });
          break;
        case 'shareRun':
          this.covenantForm.controls.shareRun.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openCropperDialog(event) {
    const imgFile = event.target.files['0'];
    let selImg = this.onSelectImage(imgFile);

    if (selImg) {
      this.cropped = null!;
      this._dialog
        .open<CropperDialog, Event>(CropperDialog, {
          data: event,
          width: 320,
          disableClose: true,
        })
        .afterClosed.subscribe((result?: ImgCropperEvent) => {
          if (result) {
            this.cropped = result.dataURL;
            this._cd.markForCheck();
          }
        });
    }
  }
}
