import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bank-account-update',
  templateUrl: './bank-account-update.component.html',
  styleUrls: ['./bank-account-update.component.css']
})
export class BankAccountUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
