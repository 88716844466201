<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading">
</ngx-loading>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">

                    <div class="card card-topline-green">
                        <div class="card-head">
                            <header>Vincular Motorista à Solicitação</header>
                        </div>
                        <div class="card-body ">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="row"><label for="">Convênio/Cliente</label></div>
                                    <div class="row">
                                        <span *ngIf="data && data.client != null">{{data.client.name}}</span>
                                        <span *ngIf="data && data.covenant != null">{{data.covenant.name}}</span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row"><label for="">CNPJ/CPF</label></div>
                                    <div class="row">
                                        <span *ngIf="data && data.client != null">{{data.client.cpfCnpj}}</span>
                                        <span *ngIf="data && data.covenant != null">{{data.covenant.cpfCnpj}}</span>
                                    </div>
                                </div>
                            </div>

                            <div [formGroup]="linkForm">

                                <div class="row mt-4">
                                    <div class="col-sm-6">
                                        <label style="font-size: 14px;">Veículo</label>
                                        <select (change)="onSelectedVehicle($event)" class="form-control  select2"
                                            formControlName="vehicle">
                                            <option value="">
                                                Selecione o Veículo </option>
                                            <option value="{{ vehicle.id }}" *ngFor="let vehicle of vehicles">
                                                {{ vehicle.model.name }} - {{vehicle.carPlate}}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6">
                                        <label style="font-size: 14px;">Motorista</label>
                                        <select class="custom-select custom-select" style="font-size: 14px;"
                                            formControlName="driver">
                                            <option value="">Motorista</option>
                                            <option value="{{ driver.id }}" *ngFor="let driver of drivers">
                                                {{ driver.name }} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-sm-3">
                                        <button class="btn btn-success" (click)="onSubmit()"
                                            [disabled]="!linkForm.valid">Salvar</button>
                                    </div>
                                    <div class="col-sm-3">
                                        <a routerLink="/process/trip-request" class="btn btn-danger">Cancelar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
