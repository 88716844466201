import { Component, OnInit, Provider } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Bank } from 'src/app/domain/bank';
import { BankAccount } from 'src/app/domain/bank-account';
import { BillsToReceive } from 'src/app/domain/bills-to-receive';
import { Client } from 'src/app/domain/client';
import { CostCenter } from 'src/app/domain/cost-center';
import { Covenant } from 'src/app/domain/covenant';
import { BankAccountService } from 'src/app/service/bank-account/bank-account.service';
import { BankService } from 'src/app/service/bank/bank.service';
import { BillsToReceiveService } from 'src/app/service/bills-to-receive/bills-to-receive.service';
import { ClientService } from 'src/app/service/client/client.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bills-to-receive-update',
  templateUrl: './bills-to-receive-update.component.html',
  styleUrls: ['./bills-to-receive-update.component.css']
})
export class BillsToReceiveUpdateComponent implements OnInit {
  billsToReceiveForm: FormGroup;
  loading: boolean = true;
  messageError: string = '';
  covenants: Array<Covenant> = [];
  clients: Array<Client> = [];
  costCenters: Array<CostCenter> = [];
  bankAccounts: Array<BankAccount> = [];
  banks: Array<Bank> = [];
  id: number;

  constructor(
    private covenantService: CovenantService,
    private clientService: ClientService,
    private costCenterService: CostCenterService,
    private bankAccountService: BankAccountService,
    private bankService: BankService,
    private service: BillsToReceiveService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getCostCenters();
    this.getBanks();
    this.getBankAccounts();
    this.setForm();
  }

  setForm() {
    this.service.getBillToReceive(this.id).subscribe(
      (data) => {
        this.billsToReceiveForm.controls.receiverType.setValue(data.client ? 'client' : 'covenant');
        this.billsToReceiveForm.controls.receiverName.setValue(data.client?.name || data.covenant?.name);
        this.billsToReceiveForm.controls.costCenter.setValue(data.costCenter.id);
        this.billsToReceiveForm.controls.bankAccount.setValue(data.bankAccount?.id);
        this.billsToReceiveForm.controls.paymentMethod.setValue(data.paymentMethod);
        this.billsToReceiveForm.controls.invoiceNumber.setValue(data.invoiceNumber);
        this.billsToReceiveForm.controls.covenant.setValue(data.covenant);
        this.billsToReceiveForm.controls.client.setValue(data.client);
        this.billsToReceiveForm.controls.value.setValue(
          (data.value * 100).toString()
        );
        this.billsToReceiveForm.controls.dueDate.setValue(
          this.formatDate(data.dueDate)
        );
        this.billsToReceiveForm.controls.paymentType.setValue(data.paymentType);
        this.billsToReceiveForm.controls.paid.setValue(data.paid);
        this.billsToReceiveForm.controls.bank.setValue(data.bank?.id);
        this.billsToReceiveForm.controls.bankCheckNumber.setValue(
          data.bankCheckNumber
        );

        if (data.parcels?.length) {
          this.billsToReceiveForm.controls.frequency.setValue(
            this.getFrequency(data.dueDate, data.parcels[0].dueDate)
          );

          this.billsToReceiveForm.controls.parcAmount.setValue(data.parcels.length);

          this.parcels.clear();

          data.parcels.forEach((item) => {
            this.parcels.push(
              this.fb.group({
                id: [item.id],
                number: [item.number],
                value: [this.parcelValue()],
                paid: [item.paid],
                bankCheckNumber: [
                  item.bankCheckNumber || '',
                  [Validators.maxLength(10)],
                ],
                dueDate: [this.formatDate(item.dueDate)],
              })
            );
          });
        }
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar A conta a pagar!', 'error');
      }
    );
  }

  createForm() {
    this.billsToReceiveForm = this.fb.group({
      receiverType: [''],
      receiverName: [''],
      searcher: [''],
      covenant: [''],
      client: [''],
      bankAccount: [''],
      costCenter: ['', [Validators.required]],
      paymentType: ['', [Validators.required]],
      invoiceNumber: ['', [Validators.maxLength(20)]],
      value: ['', [Validators.required]],
      dueDate: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      paid: [false, [Validators.required]],
      parcAmount: ['', [Validators.maxLength(20)]],
      frequency: [''],
      bank: [''],
      bankCheckNumber: ['', [Validators.maxLength(10)]],
      parcels: this.fb.array([]),
    });

    this.billsToReceiveForm
      .get('value')
      .valueChanges.subscribe((val) => this.changeValue(val));

    this.billsToReceiveForm
      .get('dueDate')
      .valueChanges.subscribe((val) => this.changeDueDate(val));

    this.billsToReceiveForm
      .get('frequency')
      .valueChanges.subscribe((val) => this.changeFrequency(val));

    this.billsToReceiveForm
      .get('parcAmount')
      .valueChanges.subscribe((val) => this.changeParcAmount(val));

    this.billsToReceiveForm
      .get('paymentType')
      .valueChanges.subscribe((val) => this.changePaymentType(val));

    this.billsToReceiveForm
      .get('paymentMethod')
      .valueChanges.subscribe((val) => this.changePaymentMethod());

    this.billsToReceiveForm
      .get('searcher')
      .valueChanges.subscribe((val) => this.getSearchUser());
  }

  getFrequency(firstDate, secondDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = (year2 - year1) * 12 + (month2 - month1);

    let frequency = '';

    switch (diffDays) {
      case 1:
        frequency = 'days';
        break;
      case 7:
        frequency = 'weeks';
        break;
      case 14:
      case 15:
        frequency = 'fortnights';
        break;
      default:
        frequency = '';
    }

    if (!frequency) {
      switch (diffMonths) {
        case 1:
          frequency = 'months';
          break;
        case 2:
          frequency = 'bimonthly';
          break;
        case 3:
          frequency = 'quarters';
          break;
        case 6:
          frequency = 'semesters';
          break;
        case 12:
          frequency = 'years';
          break;
        default:
          frequency = '';
      }
    }

    return frequency;
  }

  get parcels() {
    return this.billsToReceiveForm.get('parcels') as FormArray;
  }

  onlyNumber(event: any): void {
    event.key.match(/[^0-9]/) && event.preventDefault();
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getCostCenters() {
    this.costCenterService.getAllCostCenters().subscribe(
      (data) => {
        this.costCenters = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro',
          'Não foi possível Buscar os centros de custo!',
          'error'
        );
      }
    );
  }

  getBanks() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );
  }

  getBankAccounts() {
    this.bankAccountService.getAllActiveBanckAccounts().subscribe(
      (data) => {
        this.bankAccounts = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );
  }

  changeValue(val: string) {
    if (!val) return;
    this.setParcValue();
  }

  setParcValue() {
    const value = this.parcelValue();
    this.parcels.controls.forEach((item) => {
      item.get('value').setValue(value);
    });
  }

  parcelValue() {
    const { value, parcAmount } = this.billsToReceiveForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value.replace(/[^0-9]/g, '') / 100 / parcAmount;
  }

  changeDueDate(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  parcelDueDate(index) {
    const { frequency, dueDate } = this.billsToReceiveForm.getRawValue();
    if (!frequency || !dueDate) return null;

    const date = new Date(dueDate.replace('-', '/'));

    const iteration = index + 1;

    switch (frequency) {
      case 'days':
        date.setDate(date.getDate() + 1 * iteration);
        break;
      case 'weeks':
        date.setDate(date.getDate() + 7 * iteration);
        break;
      case 'fortnights':
        date.setDate(date.getDate() + 15 * iteration);
        break;
      case 'months':
        date.setMonth(date.getMonth() + 1 * iteration);
        break;
      case 'bimonthly':
        date.setMonth(date.getMonth() + 2 * iteration);
        break;
      case 'quarters':
        date.setMonth(date.getMonth() + 3 * iteration);
        break;
      case 'semesters':
        date.setMonth(date.getMonth() + 6 * iteration);
        break;
      case 'years':
        date.setFullYear(date.getFullYear() + 1 * iteration);
        break;
      default:
        break;
    }

    return this.formatDate(date);
  }

  changeFrequency(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  changeParcAmount(val: string) {
    const parcAmount = parseInt(val);

    if (isNaN(parcAmount) || parcAmount <= 1) {
      this.parcels.clear();
      return;
    }

    const currentLength = this.parcels.length;

    if (currentLength > parcAmount) {
      for (let i = currentLength + 1; i >= parcAmount; i--) {
        this.parcels.removeAt(i);
      }
    } else if (currentLength < parcAmount) {
      for (let i = currentLength + 1; i <= parcAmount; i++) {
        const dueDate = this.parcelDueDate(i - 1) || '';

        const paid = this.billsToReceiveForm.get('paid').value;

        this.parcels.push(
          this.fb.group({
            number: [i],
            value: [this.parcelValue()],
            paid: [paid],
            bankCheckNumber: ['', [Validators.maxLength(10)]],
            dueDate: [dueDate],
          })
        );
      }
    }

    this.setParcValue();
  }

  changePaymentType(value) {
    if (value === 'in_cash') {
      this.parcels.clear();
      this.billsToReceiveForm.get('parcAmount').setValue(null);
      this.billsToReceiveForm.get('frequency').setValue('');
    }
  }

  changePaymentMethod() {
    this.billsToReceiveForm.get('bank').setValue('');
    this.billsToReceiveForm.get('bankCheckNumber').setValue('');
    if (this.billsToReceiveForm.get('parcValue')) {
      this.changeParcAmount(this.billsToReceiveForm.get('parcValue').value);
    }
  }

  getSearchUser() {
    if (this.billsToReceiveForm.controls.searcher.value.length < 3) return;

    if (this.billsToReceiveForm.controls.receiverType.value === 'client') {
      this.getClientSearch();
    }
    if (this.billsToReceiveForm.controls.receiverType.value === 'covenant') {
      this.getCovenantSearch();
    }
  }

  getCovenantSearch() {
    this.covenantService.getCovenantSearch(this.billsToReceiveForm.controls.searcher.value).subscribe((data) => {
      if (data["results"].length > 0 && data["results"][0]) {
        this.billsToReceiveForm.get('covenant').setValue(data["results"][0])
        this.billsToReceiveForm.get('client').setValue('')
        this.billsToReceiveForm.get('receiverName').setValue(data["results"][0].name)
      } else {
        this.billsToReceiveForm.get('covenant').setValue('')
        this.billsToReceiveForm.get('client').setValue('')
        this.billsToReceiveForm.get('receiverName').setValue('')
      }
    })
  }

  getClientSearch() {
    this.clientService.getClientsSearch(this.billsToReceiveForm.controls.searcher.value).subscribe((data) => {
      if (data["results"].length > 0 && data["results"][0]) {
        this.billsToReceiveForm.get('covenant').setValue('')
        this.billsToReceiveForm.get('client').setValue(data["results"][0])
        this.billsToReceiveForm.get('receiverName').setValue(data["results"][0].name)
      } else {
        this.billsToReceiveForm.get('covenant').setValue('')
        this.billsToReceiveForm.get('client').setValue('')
        this.billsToReceiveForm.get('receiverName').setValue('')
      }
    });
  }

  onSubmit() {
    this.messageError = '';

    if (this.billsToReceiveForm.valid) {
      const billsToReceive = this.billsToReceiveForm.getRawValue();

      const billsToReceiveToSubmit :any = {
        id: this.id,
        paymentMethod: billsToReceive.paymentMethod,
        paymentType: billsToReceive.paymentType,
        receiverType: billsToReceive.receiverType,
        client: billsToReceive.client || null,
        covenant: billsToReceive.covenant || null,
        bankAccount: Number(billsToReceive.bankAccount) ? {
          id: Number(billsToReceive.bankAccount)
        } : null,
        costCenter: Number(billsToReceive.costCenter) ? {
          id: Number(billsToReceive.costCenter)
        } : null,
        bank: Number(billsToReceive.bank) ? {
          id: Number(billsToReceive.bank)
        } : null,
        invoiceNumber: billsToReceive.invoiceNumber,
        value: billsToReceive.value.replace(/[^0-9]/g, '') / 100,
        dueDate: billsToReceive.dueDate,
        paid: billsToReceive.paid,
        parcAmount: Number(billsToReceive.parcAmount),
        frequency: billsToReceive.frequency,
        bankCheckNumber: billsToReceive.bankCheckNumber,
        parcels: billsToReceive.parcels,
      }

      this.loading = true;
      this.service.update(billsToReceiveToSubmit).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Editada',
            'Conta a receber editada com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/bills-to-receive']);
            }
          });
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao editar',
            'Não foi possível editar Conta a receber!',
            'error'
          );
        }
      );
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      this.messageError += `${erro.message}\n`;
    });
  }
}
