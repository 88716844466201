import { Component, OnInit, NgModule } from '@angular/core';
import { User } from 'src/app/domain/user';
import { UserService } from 'src/app/service/user/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-alter-password',
  templateUrl: './user-alter-password.component.html',
  styleUrls: ['./user-alter-password.component.css']
})

export class UserAlterPasswordComponent implements OnInit {

  id: number;
  user: User = new User();
  loading: boolean = true;
  alterPasswordForm: FormGroup;
  token: any;

  constructor(
    private service: UserService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
  ) {
    // this.route.params.subscribe(params => {
    //   this.id = params['id']
    // });
  }

  ngOnInit(): void {
    this.token = this.getDecodedAccessToken()
    this.getUser();
    this.createForm();
  }
  createForm() {
    this.alterPasswordForm = this.fb.group({
      id: this.token.userId,
      actualPassword: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  getDecodedAccessToken(): any {
    try {
      return jwt_decode(localStorage.getItem("token"));
    }
    catch (Error) {
      return null;
    }
  }

  getUser() {
    this.loading = true;
    this.service.getUser(this.token.userId).subscribe(data => {
      this.user = data;
      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  onSubmit() {
    this.service.alterPassword(this.alterPasswordForm.value).subscribe(response => {
      Swal.fire('Alteado', 'Senha Alterado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['/']);
        }
      })
    },
      error => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao alterar', 'Não foi possível alterar sua senha!', 'error');
      });

  }

  setErrors(errors) {
    errors.forEach(erro => {
      if (erro.fieldName === 'actualPassword') {
        this.alterPasswordForm.controls.actualPassword.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'password') {
        this.alterPasswordForm.controls.password.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'confirmPassword') {
        this.alterPasswordForm.controls.confirmPassword.setErrors({ invalid: erro.message });
      }

    });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Usuário!', 'error');
  }

}
