import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from 'src/app/service/driver/driver.service';
import { LoginService } from 'src/app/service/login/login.service';
import Swal from 'sweetalert2';
import { Validations } from '../../validations';

@Component({
  selector: 'app-active-account',
  templateUrl: './active-account.component.html',
  styleUrls: ['./active-account.component.css']
})
export class ActiveAccountComponent implements OnInit {

  token: string = "";
  id: number;
  activeForm: FormGroup;
  loading: boolean = false;

  constructor(
    private service: DriverService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit(): void {
    this.createForm();

  }
  createForm() {
    this.activeForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validations.validatePassword]],
      confirmationPassword: ['', [Validators.required, Validations.equalsPassword]]
    });
  }
  onSubmit() {

    const password = this.activeForm.controls.password.value;
    const confirmationPassword = this.activeForm.controls.confirmationPassword.value;

    if (password !== confirmationPassword) {
      this.activeForm.controls.confirmationPassword.setErrors({ differentPasswords: true })
    }
    if (this.activeForm.valid) {

      this.loading = true;

      const value = {
        token: this.token,
        password: this.activeForm.controls.password.value
      }
      this.service.activeAccount(value).subscribe(data => {
        this.loading = false;
        Swal.fire('Sucesso', 'Motorista Ativado com Sucesso!!', 'success');
        window.location.href = 'http://goldentaxi.com.br/appgolden/';
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Erro ao Ativar Motorista!', 'error');
        });
    }
  }
  setErrors(errors) {
    errors.forEach(erro => {
      if (erro.fieldName === 'password') {
        this.activeForm.controls.password.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'token') {
        this.activeForm.controls.password.setErrors({ token: erro.message });
      }

    });

  }
}
