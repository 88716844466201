<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="bg-light py-3">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="page-title ml-1">Cadastrar Conta Bancária</div>
            </div>
            <div class="col-md-6">

              <form [formGroup]="bankForm">
                <div *ngIf="messageError.length" class="container">
                  <div class="alert alert-danger" role="alert">
                    {{ messageError }}
                  </div>
                </div>
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="bank">Banco</label>
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal; min-width: 230px;"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Banco"
                      formControlName="bank"
                      [items]="banks"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                    <span
                      *ngIf="
                        bankForm.controls.bank.errors &&
                        bankForm.controls.bank.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        bankForm.controls.bank.errors &&
                        bankForm.controls.bank.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ bankForm.controls.bank.errors.invalid }}
                    </span>
                  </div>

                  <div class="col-10 mt-1">
                    <label for="agency">Agência</label>
                    <input
                      type="text"
                      formControlName="agency"
                      class="form-control"
                    />
                    <span
                      *ngIf="
                        bankForm.controls.agency.errors &&
                        bankForm.controls.agency.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório
                    </span>
                    <span
                      *ngIf="
                        bankForm.controls.agency.errors &&
                        bankForm.controls.agency.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ bankForm.controls.agency.errors.invalid }}
                    </span>
                  </div>

                  <div class="col-2 mt-1">
                    <label for="agencyDv">DV</label>
                    <input
                      type="text"
                      formControlName="agencyDv"
                      class="form-control"
                    />
                    <span
                      *ngIf="
                        bankForm.controls.agencyDv.errors &&
                        bankForm.controls.agencyDv.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório
                    </span>
                    <span
                      *ngIf="
                        bankForm.controls.agencyDv.errors &&
                        bankForm.controls.agencyDv.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ bankForm.controls.agencyDv.errors.invalid }}
                    </span>
                  </div>

                  <div class="col-10 mt-1">
                    <label for="bankAccount">Conta Bancária</label>
                    <input
                      type="text"
                      formControlName="bankAccount"
                      class="form-control"
                    />
                    <span
                      *ngIf="
                        bankForm.controls.bankAccount.errors &&
                        bankForm.controls.bankAccount.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        bankForm.controls.bankAccount.errors &&
                        bankForm.controls.bankAccount.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ bankForm.controls.bankAccount.errors.invalid }}
                    </span>
                  </div>

                  <div class="col-2 mt-1">
                    <label for="bankAccountDv">DV</label>
                    <input
                      type="text"
                      formControlName="bankAccountDv"
                      class="form-control"
                    />
                    <span
                      *ngIf="
                        bankForm.controls.bankAccountDv.errors &&
                        bankForm.controls.bankAccountDv.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        bankForm.controls.bankAccountDv.errors &&
                        bankForm.controls.bankAccountDv.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ bankForm.controls.bankAccountDv.errors.invalid }}
                    </span>
                  </div>

                  <div class="col-12 mt-3">
                    <label for="type">Tipo</label>
                    <select formControlName="type" class="custom-select">
                      <option value="corrente">Corrente</option>
                      <option value="poupanca">Poupança</option>
                      <option value="investimento">Investimento</option>
                    </select>
                    <span
                      *ngIf="
                        bankForm.controls.type.errors &&
                        bankForm.controls.type.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                  </div>

                  <div class="col-12 mt-1">
                    <label for="socialNumber">Documento do Titular</label>
                    <input
                      type="text"
                      formControlName="socialNumber"
                      class="form-control"
                    />
                    <span
                      *ngIf="
                        bankForm.controls.socialNumber.errors &&
                        bankForm.controls.socialNumber.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório
                    </span>
                    <span
                      *ngIf="
                        bankForm.controls.socialNumber.errors &&
                        bankForm.controls.socialNumber.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ bankForm.controls.socialNumber.errors.invalid }}
                    </span>
                  </div>

                  <div class="col-12 mt-1">
                    <label for="socialName">Nome do Titular</label>
                    <input
                      type="text"
                      formControlName="socialName"
                      class="form-control"
                    />
                    <span
                      *ngIf="
                        bankForm.controls.socialName.errors &&
                        bankForm.controls.socialName.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório
                    </span>
                    <span
                      *ngIf="
                        bankForm.controls.socialName.errors &&
                        bankForm.controls.socialName.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ bankForm.controls.socialName.errors.invalid }}
                    </span>
                  </div>

                  <div class="col-12 mt-1 d-flex flex-row">
                    <span
                      class="d-inline-block"
                      tabindex="0"
                      data-toggle="tooltip"
                      title="Disabled tooltip"
                    >
                      <input
                        type="checkbox"
                        id="rp"
                        formControlName="receivePayments"
                      />
                    </span>

                    <label for="rp" class="ml-2">Recebe pagamento?</label>
                    <small
                      class="ml-3"
                      *ngIf="bankForm.get('receivePayments').value === true"
                      >*Obs: Com esta opção marcada a conta receberá os
                      pagamentos no aplicativo Golden Taxi.
                      <p>
                        <b
                          >Caso exista uma conta bancária marcada, essa ação irá
                          desmarcar a outra conta. Deseja prosseguir?</b
                        >
                      </p>
                    </small>
                  </div>

                  <div
                    class="
                      row
                      col-12
                      m-0
                      p-0
                      d-flex
                      justify-content-center
                      my-5
                    "
                  >
                    <div class="m-2">
                      <button
                        (click)="onSubmit()"
                        [disabled]=""
                        class="btn btn-success"
                      >
                        Salvar
                      </button>
                    </div>
                    <div class="m-2">
                      <a routerLink="/financial/account-bank" class="btn btn-danger"
                        >Cancelar</a
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
