import { Component, OnInit } from '@angular/core';
import { AccessProfile } from 'src/app/domain/access-profile';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Car } from 'src/app/domain/car';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { AddressService } from 'src/app/service/address/address.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/domain/address';
import Swal from 'sweetalert2';
import { CarService } from 'src/app/service/car/car.service';
import { CarModel } from 'src/app/domain/car-model';
import { CarBrand } from 'src/app/domain/car-brand';
import { CarModelService } from 'src/app/service/car-model/car-model.service';
import { CarBrandService } from 'src/app/service/car-brand/car-brand.service';

@Component({
  selector: 'app-cars',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.css'],
})
export class CarComponent implements OnInit {
  cars: Array<Car> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  car: Car = new Car();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;

  vehicleModels: Array<CarModel> = [];
  vehicleBrands: Array<CarBrand> = [];

  constructor(
    private service: CarService,
    private serviceModel: CarModelService,
    private serviceBrand: CarBrandService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.car.model = new CarModel();
    this.car.model.brand = new CarBrand();

    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
      this.car.model.brand.id = params.brand || null;
      this.car.model.id = params.model || null;
      this.car.carPlate = params.car_plate || '';
      this.car.expirationDateIpva = params.expiration_date_ipva || null;
    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getBrands();
    // this.getModels();
    this.getCars();
  }

  getModels(id) {
    this.loading = true;
    this.serviceModel.getCarModelsByBrand(id).subscribe(
      (data) => {
        this.vehicleModels = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível buscar os modelos!', 'error');
      }
    );
  }

  getBrands() {
    this.serviceBrand.getAllCarBrands().subscribe(
      (data) => {
        this.vehicleBrands = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível buscar as marcas!', 'error');
      }
    );
  }

  getCars() {
    this.loading = true;

    this.service.getCars(this.car, this.pagination).subscribe(
      (data) => {
        this.cars = data['results'];
        this.setPagination(data);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      marca: [this.car.model.brand.id],
      modelo: [this.car.model.id],
      placa: [this.car.carPlate],
      ipva: [this.car.expirationDateIpva],
    });
  }

  onSelectedBrand(e) {
    this.getModels(e.id);
  }

  onSearch() {
    this.car.model.brand.id = this.searchForm.controls.marca.value;
    this.car.model.id = this.searchForm.controls.modelo.value;
    this.car.carPlate = this.searchForm.controls.placa.value;
    this.car.expirationDateIpva = this.searchForm.controls.ipva.value;

    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }

  onActive(car: Car) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Veículo? ${car.model.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(car.id).subscribe(
          (data) => {
            // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
            Swal.fire({
              title: 'Ativado',
              text: `Veículo ativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao ativar Veículo!', 'error');
          }
        );
      }
    });
  }
  onDeActive(car: Car) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Veículo? ${car.model.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(car.id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Desativado',
              text: `Veículo desativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao desativar Veículo!', 'error');
          }
        );
      }
    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/maintenance/car'], {
      queryParams: {
        brand: this.car.model.brand.id ?? '',
        model: this.car.model.id ?? '',
        car_plate: this.car.carPlate ?? '',
        expiration_date_ipva: this.car.expirationDateIpva ?? '',
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getCars();
  }
}
