import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Pagination } from 'src/app/domain/pagination';
import { CarMaintenance } from 'src/app/domain/car-maintenance';
import { CarMaintenanceDTO } from 'src/app/domain/dto/car-maintenance.dto';

@Injectable({
  providedIn: 'root',
})
export class CarMaintenanceService {
  url: string = `${environment.BASE_URL}/vehicles`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getCarMaintenances(carId: number, filter: any, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      ...filter,
    };

    return this.http.get(`${this.url}/${carId}/maintenances`, {
      responseType: 'json',
      params: params,
    });
  }

  getCarMaintenance(carId: number, id: number) {
    return this.http.get<CarMaintenance>(
      `${this.url}/${carId}/maintenances/${id}`,
      { responseType: 'json' }
    );
  }

  save(carId: number, carMaintenance: CarMaintenance) {
    let dto = new CarMaintenanceDTO(carMaintenance);
    return this.http.post(
      `${this.url}/${carId}/maintenances`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  update(carId: number, carMaintenance: CarMaintenance) {
    let dto = new CarMaintenanceDTO(carMaintenance);
    console.log(JSON.stringify(dto));
    return this.http.put(
      `${this.url}/${carId}/maintenances/${carMaintenance.id}`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  delete(carId: number, id: number) {
    return this.http.delete(`${this.url}/${carId}/maintenances/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(carId: number, id: number) {
    return this.http.put(`${this.url}/${carId}/maintenances/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  deactive(carId: number, id: number) {
    return this.http.put(`${this.url}/${carId}/maintenances/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  exportCarMaintenances(filter: any, type: string, vehicleId: number) {
    const { value } = filter;

    const params = new HttpParams({
      fromObject: {
        start_date: `${value.start_date ?? ''}`,
        end_date: `${value.end_date ?? ''}`,
        paid: `${value.paid ?? ''}`,
        providerId: `${value.providerId ?? ''}`,
      },
    });
    return this.http.get(`${this.url}/${vehicleId}/maintenances/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      params: params,
      observe: 'response',
    });
  }
}
