import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { RunService } from 'src/app/service/run/run.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { Driver } from 'src/app/domain/driver';
import { Car } from 'src/app/domain/car';
import { CarService } from 'src/app/service/car/car.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css'],
})
export class FinishRunComponent implements OnInit {
  moment: any = moment;

  id: number;
  cars: Array<Car> = [];
  drivers: Array<Driver> = [];
  run;
  runForm: FormGroup;
  loading: boolean = true;
  messageError: string = '';
  canGoBack: boolean = false;

  constructor(
    private service: RunService,
    private carService: CarService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private readonly location: Location
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.canGoBack = !!this.router.getCurrentNavigation()?.previousNavigation;
  }

  ngOnInit(): void {
    this.createForm();
    this.getRuns();
  }

  createForm() {
    this.runForm = this.fb.group({
      car: [this.run?.vehicleId ?? '', [Validators.required]],
      driver: [this.run?.driverId, [Validators.required]],
      realStartHour: [
        moment(this.run?.realStartHour ?? '').format('HH:mm'),
        [Validators.required],
      ],
      realEndHour: [
        moment(this.run?.realEndHour ?? '').format('HH:mm'),
        [Validators.required],
      ],
      runOtherValue: [this.run?.runOtherValue ?? ''],
      runDiscount: [this.run?.runDiscount ?? ''],
      observation: [
        this.run?.observation ?? this.run?.runRequest?.observation ?? '',
      ],
    });

    if (this.run?.vehicleId) this.getDriversByCar();
  }

  getRuns() {
    this.loading = true;
    this.service.getRun(this.id).subscribe(
      (data) => {
        this.run = data;
        this.getAllCars();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  getAllCars() {
    this.carService.getCarsActives().subscribe((data) => {
      this.cars = data;
      for (const car of this.cars)
        car.nameCarPlate = `${car.model.name} ${car.carPlate}`;
      this.createForm();
      this.totalSolicitation();
    });
  }

  getDriversByCar() {
    if (this.runForm.controls.car.value) {
      this.loading = true;
      this.carService
        .getDriversByCar(this.runForm.controls.car.value)
        .subscribe(
          (data) => {
            this.drivers = data;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            Swal.fire(
              'Erro',
              'Não foi possível carregar os motoristas deste veículo! Selecione o Veículo novamente.',
              'error'
            );
          }
        );
    }
  }

  totalSolicitation() {
    const { date, client, destinyPoint } = this.run;

    const passenger = client ? 'client' : 'covenant';

    const requestValue = this.run.requestValue;

    let totalSolicitationValue = 0.0;
    let runPenality = 0.0;

    if (requestValue) {
      switch (passenger) {
        case 'client':
          const dp = requestValue.destinyPoints.find(
            (element) => element.id == destinyPoint
          );

          totalSolicitationValue =
            (requestValue?.value ?? 0) + Number(dp?.value ?? 0);
          break;
        case 'covenant':
          const passengers = this.run.runPassengers;
          const destinyPoints = passengers.reduce((acc, item) => {
            const destiny = item.destinyPoint;
            if (destiny) {
              const index = acc.findIndex((a) => a?.id === destiny.id);

              if (index == -1) {
                acc.push({ id: destiny.id, value: destiny.value });
              }
            }
            return acc;
          }, []);

          const valuePoints = destinyPoints.reduce((acc, item) => {
            acc += Number(item.value);
            return acc;
          }, 0);

          const origins = passengers.reduce((acc, item) => {
            const index = acc.findIndex((a) => a === item.destiny.zipCode);
            if (index == -1) {
              acc.push(item.destiny.zipCode);
            }
            return acc;
          }, []);

          totalSolicitationValue =
            (requestValue?.value ?? 0) +
            (valuePoints ?? 0) +
            (origins?.length != 0 ? origins?.length - 1 : 0) *
              (requestValue?.valueOriginPoint ?? 0);
          break;

        default:
          break;
      }

      const { initialHour, endHour, percentagePenalty } = requestValue;

      // hora de início da corrida
      const beginingRunTime = moment(`${date}T${this.run.startHour}:00`);

      if (initialHour && endHour && percentagePenalty) {
        // período do valor de corrida para aplicar a multa
        const beginningTime = moment(`${date}T${initialHour}:00`);
        const endTime = moment(`${date}T${endHour}:00`);

        // se hora de início do período do valor de corrida para aplicar a multa for maior que
        // a hora fin do período do valor de corrida para aplicar a multa
        // o período inicia em um dia e termina no outro
        if (beginningTime.isAfter(endTime)) {
          endTime.add(1, 'days');
        }

        // se a hora de início da corrida está dentro do período do valor de corrida para aplicar a multa
        const hasPenality =
          beginingRunTime.isSameOrAfter(beginningTime) &&
          beginingRunTime.isSameOrBefore(endTime);

        if (hasPenality) {
          const overtimePenalityValue = (
            +totalSolicitationValue * percentagePenalty
          ).toFixed(2);
          runPenality = +overtimePenalityValue;
        }
        this.run.runPenality = runPenality;
      }

      // calculo de valor adicional
      let runAditionalValue = 0.0;
      const realStartDateHour = moment(
        `${this.run.date}T${this.runForm.controls.realStartHour.value}:00`
      );

      if (realStartDateHour.diff(beginingRunTime) < -3600000) {
        realStartDateHour.add(1, 'day');
      }

      const difference = realStartDateHour.diff(beginingRunTime, 'minutes');

      if (difference > 20) {
        runAditionalValue =
          this.run.requestValue.aditionalValue *
          Math.round(difference / 60 + 0.3);
      }

      this.run.runAditionalValue = runAditionalValue;
    }

    const runOtherValue: number =
      this.runForm.controls.runOtherValue.value ?? 0;
    const runDiscount: number = this.runForm.controls.runDiscount.value ?? 0;

    this.run.runEndValue = (
      +totalSolicitationValue +
      (this.run.runAditionalValue ?? 0) +
      (this.run.runPenality ?? 0) +
      runOtherValue -
      runDiscount
    ).toFixed(2);

    console.log(this.run);
  }

  onSubmit() {
    const {
      car,
      driver,
      realStartHour,
      realEndHour,
      observation,
      runOtherValue,
      runDiscount,
    } = this.runForm.getRawValue();

    this.messageError = '';

    var value = {};

    const realStartDateHour = moment(`${this.run.date}T${realStartHour}:00`);

    const realEndDateHour = moment(`${this.run.date}T${realEndHour}:00`);

    const scheduledRun = moment(`${this.run.date}T${this.run.startHour}:00`);

    if (realStartDateHour.diff(scheduledRun) < -3600000) {
      realStartDateHour.add(1, 'day');
    }

    if (realEndDateHour.diff(scheduledRun) < -3600000) {
      realEndDateHour.add(1, 'day');
    }

    value = {
      id: this.id,
      driver: driver ? { id: Number(driver) } : null,
      vehicle: car ? { id: Number(car) } : null,
      realStartHour: realStartDateHour,
      realEndHour: realEndDateHour,
      runOtherValue: runOtherValue,
      runDiscount: runDiscount,
      observation: observation,
    };

    this.service.finish(this.id, value).subscribe(
      (data) => {
        Swal.fire('Salvo', 'Corrida finalizada com Sucesso!', 'success');
        this.loading = false;
        this.goBack();
      },
      (error) => {
        Swal.fire('Erro', 'Erro ao finalizar corrida!', 'error');
        this.setErrors(error.error.errors);
        this.loading = false;
      }
    );
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      console.log(erro);

      switch (erro.fieldName) {
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar Corrida!', 'error');
  }

  goBack(): void {
    if (this.canGoBack) {
      // We can safely go back to the previous location as
      // we know it's within our app.
      this.location.back();
    } else {
      this.router.navigate(['process/runs']);
    }
  }
}
