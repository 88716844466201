import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CovenantService } from '../../service/covenant/covenant.service';
import { AddressService } from '../../service/address/address.service';
import { UtilsService } from '../../service/utils/utils.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { State } from '../../domain/state';
import { City } from '../../domain/city';
import { Covenant } from '../../domain/covenant';

@Component({
  selector: 'app-modal-update-covenant-address-form',
  templateUrl: './modal-update-covenant-address-form.component.html',
  styleUrls: ['./modal-update-covenant-address-form.component.css'],
})
export class ModalUpdateCovenantAddressFormComponent implements OnInit {
  constructor(
    private service: CovenantService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    public readonly swalTargets: SwalPortalTargets
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  error: string = '';

  form: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  states: State[] = [];
  cities: City[] = [];
  modalFireCondition: boolean = false;

  @Input() covenant: Covenant;

  @Output() modalConfirm: EventEmitter<Covenant> = new EventEmitter();

  createForm() {
    this.form = this.fb.group({
      id: new FormControl(''),
      zipCode: new FormControl('', [Validators.maxLength(9)]),
      publicPlace: new FormControl('', [Validators.maxLength(100)]),
      neighborhood: new FormControl(''),
      cityId: new FormControl(null),
      stateId: new FormControl(null),
    });
  }

  onFormUpdate() {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        error = 'Não foi possível Buscar os Estados!';
      }
    );

    if (this.covenant?.address?.city?.state?.id)
      this.fetchCities(this.covenant.address.city.state.id);

    const { id, zipCode, publicPlace, neighborhood, city } =
      this.covenant.address ?? {};
    this.form.patchValue({
      id,
      zipCode,
      publicPlace,
      neighborhood,
      cityId: city?.id,
      stateId: city?.state?.id,
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    const { id, zipCode, publicPlace, neighborhood, cityId } = this.form.value;

    this.covenant.address = {
      ...this.covenant.address,
      zipCode: zipCode.replace(/[^0-9,.]+/g, ''),
      publicPlace,
      neighborhood,
      cityId,
    };

    this.covenant.address.city.id = cityId;

    this.loading = true;
    this.service.update(this.covenant).subscribe(
      (data) => {
        this.loading = false;
        this.modalConfirm.emit(data.body);
        this.form.reset();
        this.cities = [];
        this.states = [];
        this.modalFireCondition = false;
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
      }
    );
  }

  setErrors(errors) {
    errors.forEach((err: any) => {
      this.form.get(err.fieldName)?.setErrors({ invalid: err.message });
      this.messageError += `${err.message}\n`;
    });
  }

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            const viaCep = data;

            if (!viaCep.erro) {
              this.cities = [];
              this.form.get('neighborhood').patchValue(viaCep.bairro);
              this.form.get('publicPlace').patchValue(viaCep.logradouro);

              this.form.get('stateId').patchValue(null);
              this.form.get('cityId').patchValue(null);
              const state = this.states.find(
                (state) => state.initials === viaCep.uf.toUpperCase()
              );

              this.form.get('stateId').patchValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      viaCep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );

                  if (city) {
                    this.form.get('cityId')?.patchValue(city.id);
                  }
                },
                (error) => {
                  error = 'Erro ao buscar cidades!';
                }
              );
            } else {
              this.form.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.form.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.form.controls.zipCode.setErrors({
        invalid: 'CEP inválido!',
      });
    }
  }

  onSelectedState(event) {
    if (event.target.value) {
      this.fetchCities(event.target.value);
    }
  }

  fetchCities(stateId: number) {
    this.loading = true;
    this.serviceAddress.getCitiesByStateId(stateId).subscribe(
      (data) => {
        this.cities = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        error = 'Erro ao buscar cidades!';
      }
    );
  }

  onClose() {
    this.form.reset();
    this.cities = [];
    this.states = [];
    this.modalFireCondition = false;
  }
}
