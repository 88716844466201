import { Address } from "./address";


export class Provider {

   id: number
   name: string;
   cpfCnpj: string;
   stateRegistration: string;
   municipalRegistration: string;
   phone: string;
   whatsapp: string;
   contact: string;
   email: string;
   address: Address = new Address;
   active: boolean;

   constructor() { }
}