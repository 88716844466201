import { Driver } from './../../../domain/driver';
import { Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DriverPaymentService } from 'src/app/service/driver-payment/driver-payment.service';
import { ActivatedRoute } from '@angular/router';
import { EnumRequestValueType } from 'src/app/enumerations/request-value-type';
import { DriverPaymentTypeEnum } from 'src/app/enumerations/driver-payment-type.enum';
import moment from 'moment';

moment.locale('pt-BR');

@Component({
  selector: 'app-pay-driver-view',
  templateUrl: './pay-driver-view.component.html',
  styleUrls: ['./pay-driver-view.component.scss'],
})
export class PayDriverViewComponent implements OnInit {
  loading: boolean;
  private id: number;

  launchs = [];
  credits = [];
  fuelSupplies = [];
  maintenances = [];
  trafficTickets = [];
  tolls = [];
  resume = [];
  refunds = [];
  commission: number;
  commissionType: string;
  taxiDriverDiscountCovenant: number;
  taxiDriverDiscountCovenantType: string;
  taxiDriverDiscountCard: number;
  taxiDriverDiscountCardType: string;
  issueInvoiceToCreditCard: boolean;

  now: String = moment().format('LL');

  initialDate: string = '';
  finalDate: string = '';
  driver: Driver = new Driver();
  paymentType: string = '';
  bank: string = '';
  checkNumber: string = '';
  paid: boolean = false;

  totalValueCredit: number;
  finalValueCredit: number;
  finalValueDebit: number;
  finalValueTrafficTicket: number;
  finalValueFuelSupplies: number;
  finalValueToll: number;
  finalRefundValue: number;
  commissionValue: number;
  paymentValue: number;
  finalValue: number;
  newValues = [];

  constructor(
    private driverPaymentService: DriverPaymentService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.getDriverPayment();
  }

  getDriverPayment() {
    this.loading = true;
    this.driverPaymentService.findOneDriverPayment(this.id).subscribe(
      (data) => {
        this.loading = false;
        this.newValues = data['driverPaymentOthers'];
        this.fuelSupplies = data['vehicleFuelSupplies'];
        this.trafficTickets = data['vehicleTrafficTickets'];
        this.launchs = data['runs'];
        this.commissionType = data['taxiDriverCommissionType'];
        this.commission = data['taxiDriverCommission'];
        this.taxiDriverDiscountCard = data['taxiDriverDiscountCard'];
        this.taxiDriverDiscountCardType = data['taxiDriverDiscountCardType'];
        this.taxiDriverDiscountCovenant = data['taxiDriverDiscountCovenant'];
        this.taxiDriverDiscountCovenantType =
          data['taxiDriverDiscountCovenantType'];
        this.initialDate = moment(data['initialDate']).format('YYYY-MM-DD');
        this.finalDate = moment(data['finalDate']).format('YYYY-MM-DD');
        this.driver = data['driver'];
        this.paymentType = DriverPaymentTypeEnum[data['paymentType']];
        this.bank = data['bank']?.name;
        this.checkNumber = data['checkNumber'];
        this.paid = data['paid'];
        this.issueInvoiceToCreditCard = data['issueInvoiceToCreditCard'];

        Promise.all(
          this.launchs.map((launch) => {
            const type =
              EnumRequestValueType[launch['runRequest'].requestValue.type];
            launch['runRequest'].requestValue.type = type
              ? type
              : launch['runRequest'].requestValue.type;
          })
        ).then((_) => this.calculate());
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  async calculate() {
    this.credits = [];
    this.resume = [];
    this.refunds = [];

    this.totalValueCredit = 0;
    this.finalValueCredit = 0;
    this.finalValueTrafficTicket = 0;
    this.finalValueToll = 0;
    this.finalValueFuelSupplies = 0;
    this.commissionValue = 0;
    this.finalValue = 0;

    /*-----------------------------------------------------------------------------
      Filtra os registros de pedágio.
    ------------------------------------------------------------------------------*/
    this.tolls = this.launchs.filter(
      (item) => item['runRequest'].requestValue.tollAmount > 0
    );

    this.tolls.forEach((toll) => {
      this.finalValueToll += toll['runRequest'].requestValue.tollAmount;
      const resume = this.resume.find((res) => res.description === 'Pedágio');

      if (resume) {
        resume.value += toll['runRequest'].requestValue.tollAmount;
      } else {
        this.resume.push({
          description: 'Pedágio',
          value: toll['runRequest'].requestValue.tollAmount,
          type: 'debit',
        });
      }
    });

    /*-----------------------------------------------------------------------------
      Filtra os registros do tipo cliente, que é pagamento cartão.
    ------------------------------------------------------------------------------*/
    await Promise.all(
      this.launchs.filter(
        (item) =>
          //item['runRequest'].requestValue.type === 'Cliente' &&
          !item.runRequest.covenant && item['runRequest'].paymentMethodId === 2
      )
    ).then((clientIssueInvoiceHasCardFee) =>
      clientIssueInvoiceHasCardFee.forEach((launch) => {
        const type = launch.runRequest.requestValue.type;
        const credit = this.credits.find(
          (credit) =>
            credit.description === type &&
            //credit.issueInvoice &&
            credit.hasCardFee
        );

        if (credit) {
          credit.value += launch.runEndValue;
        } else {
          this.credits.push({
            description: type,
            value: launch.runEndValue,
            //issueInvoice: launch.runRequest.covenant?.invoice,
            //percentage: launch.runRequest.requestValue.percentage,
            hasCardFee: launch.runRequest.paymentMethodId === 2,
          });
        }
        this.totalValueCredit += launch.runEndValue;
      })
    );

    /*-------------------------------------------------------------------------------
      Filtra os registros do tipo cliente, que não é pagamento cartão.
    --------------------------------------------------------------------------------*/
    await Promise.all(
      this.launchs.filter(
        (item) =>
          //item['runRequest'].requestValue.type === 'Cliente' &&
          !item.runRequest.covenant && item['runRequest'].paymentMethodId !== 2
      )
    ).then((clientIssueInvoiceNotHasCardFee) =>
      clientIssueInvoiceNotHasCardFee.forEach((launch) => {
        const type = launch.runRequest.requestValue.type;
        const credit = this.credits.find(
          (credit) =>
            credit.description === type &&
            //credit.issueInvoice &&
            !credit.hasCardFee
        );

        if (credit) {
          credit.value += launch.runEndValue;
        } else {
          this.credits.push({
            description: type,
            value: launch.runEndValue,
            //issueInvoice: launch.runRequest.covenant?.invoice,
            //percentage: launch.runRequest.requestValue.percentage,
            hasCardFee: launch.runRequest.paymentMethodId === 2,
          });
        }
        this.totalValueCredit += launch.runEndValue;
      })
    );

    /*-----------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que emite nf e que é pagamento cartão.
    ------------------------------------------------------------------------------*/
    await Promise.all(
      this.launchs.filter(
        (item) =>
          item.runRequest.covenant &&
          item.runRequest.covenant.invoice &&
          item['runRequest'].paymentMethodId === 2
      )
    ).then((covenantIssueInvoiceHasCardFee) =>
      covenantIssueInvoiceHasCardFee.forEach((launch) => {
        const type = launch.runRequest.requestValue.type;
        const credit = this.credits.find(
          (credit) =>
            credit.description === type &&
            credit.issueInvoice === launch.issueInvoiceToCreditCard &&
            launch.runRequest.covenant.invoice &&
            credit.hasCardFee
        );

        if (credit) {
          credit.value += launch.runEndValue;
        } else {
          this.credits.push({
            description: type,
            value: launch.runEndValue,
            //issueInvoice: launch.runRequest.covenant.invoice,
            issueInvoice:
              launch.issueInvoiceToCreditCard &&
              launch.runRequest.covenant.invoice,
            //percentage: launch.runRequest.requestValue.percentage,
            hasCardFee: launch.runRequest.paymentMethodId === 2,
          });
        }
        this.totalValueCredit += launch.runEndValue;
      })
    );

    /*--------------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que emite nf e que não é pagamento cartão.
    ---------------------------------------------------------------------------------*/
    await Promise.all(
      this.launchs.filter(
        (item) =>
          item.runRequest.covenant &&
          item.runRequest.covenant.invoice &&
          item['runRequest'].paymentMethodId !== 2
      )
    ).then((covenantIssueInvoiceNotHasCardFee) =>
      covenantIssueInvoiceNotHasCardFee.forEach((launch) => {
        const type = launch.runRequest.requestValue.type;
        const credit = this.credits.find(
          (credit) =>
            credit.description === type &&
            credit.issueInvoice &&
            !credit.hasCardFee
        );

        if (credit) {
          credit.value += launch.runEndValue;
        } else {
          this.credits.push({
            description: type,
            value: launch.runEndValue,
            issueInvoice: launch.runRequest.covenant.invoice,
            //percentage: launch.runRequest.requestValue.percentage,
            hasCardFee: launch.runRequest.paymentMethodId === 2,
          });
        }
        this.totalValueCredit += launch.runEndValue;
      })
    );

    /*--------------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que não emite nf e que é pagamento cartão.
    ---------------------------------------------------------------------------------*/
    await Promise.all(
      this.launchs.filter(
        (item) =>
          item.runRequest.covenant &&
          !item.runRequest.covenant.invoice &&
          item['runRequest'].paymentMethodId === 2
      )
    ).then((covenantNotIssueInvoiceHasCardFee) =>
      covenantNotIssueInvoiceHasCardFee.forEach((launch) => {
        const type = launch.runRequest.requestValue.type;
        const credit = this.credits.find(
          (credit) =>
            credit.description === type &&
            !credit.issueInvoice &&
            credit.hasCardFee
        );

        if (credit) {
          credit.value += launch.runEndValue;
        } else {
          this.credits.push({
            description: type,
            value: launch.runEndValue,
            issueInvoice: launch.runRequest.covenant.invoice,
            //percentage: launch.runRequest.requestValue.percentage,
            hasCardFee: launch.runRequest.paymentMethodId === 2,
          });
        }
        this.totalValueCredit += launch.runEndValue;
      })
    );

    /*------------------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que não emite nf e que não é pagamento cartão.
    -------------------------------------------------------------------------------------*/
    await Promise.all(
      this.launchs.filter(
        (item) =>
          item.runRequest.covenant &&
          !item.runRequest.covenant.invoice &&
          item['runRequest'].paymentMethodId !== 2
      )
    ).then((covenantNotIssueInvoiceNotHasCardFee) =>
      covenantNotIssueInvoiceNotHasCardFee.forEach((launch) => {
        const type = launch.runRequest.requestValue.type;
        const credit = this.credits.find(
          (credit) =>
            credit.description === type &&
            !credit.issueInvoice &&
            !credit.hasCardFee
        );

        if (credit) {
          credit.value += launch.runEndValue;
        } else {
          this.credits.push({
            description: type,
            value: launch.runEndValue,
            issueInvoice: launch.runRequest.covenant.invoice,
            //percentage: launch.runRequest.requestValue.percentage,
            hasCardFee: launch.runRequest.paymentMethodId === 2,
          });
        }
        this.totalValueCredit += launch.runEndValue;
      })
    );

    this.fuelSupplies.forEach((item) => {
      this.finalValueFuelSupplies += item.value;
      if (item.supplyType === 'DRIVER') {
        const resume = this.refunds.find(
          (res) => res.description === 'Reembolso Abastecimento'
        );

        if (resume) {
          resume.value += item.value;
        } else {
          this.refunds.push({
            description: 'Reembolso Abastecimento',
            value: item.value,
            type: 'credit',
          });
        }
      }
    });

    this.trafficTickets.forEach((item) => {
      this.finalValueTrafficTicket += item['value'];
      // const resume = this.resume.find((res) => res.description === 'Multas');

      // if (resume) {
      //   resume.value += item['value'];
      // } else {
      //   this.resume.push({
      //     description: 'Multas',
      //     value: item['value'],
      //     type: 'debit',
      //   });
      // }
    });

    this.credits.forEach((item) => {
      if (!item.finalValue) {
        item.finalValue = item.value;
      }

      if (item.issueInvoice) {
        if (this.taxiDriverDiscountCovenant) {
          item.covenantDiscount = this.taxiDriverDiscountCovenant;

          if (this.taxiDriverDiscountCovenantType === 'percentage') {
            item.covenantDiscount += ` %`;
            item.finalValue -=
              item.value * (this.taxiDriverDiscountCovenant * 0.01);
          } else {
            item.finalValue -= this.taxiDriverDiscountCovenant;
          }
        }
      }

      if (item.hasCardFee) {
        if (this.taxiDriverDiscountCard) {
          item.cardDiscount = this.taxiDriverDiscountCard;

          if (this.taxiDriverDiscountCardType === 'percentage') {
            item.cardDiscount += ` %`;
            item.finalValue -=
              item.value * (this.taxiDriverDiscountCard * 0.01);
          } else {
            item.finalValue -= this.taxiDriverDiscountCard;
          }
        }
      }
      this.finalValueCredit += item.finalValue;

      const resume = this.resume.find(
        (res) => res.description === 'Lançamentos'
      );

      if (resume) {
        resume.value += item.finalValue;
      } else {
        this.resume.push({
          description: 'Lançamentos',
          value: item.finalValue,
          type: 'credit',
        });
      }
    });

    const fuelSupplies = {
      description: 'Abastecimentos',
      type: 'debit',
      value: 0,
    };
    this.fuelSupplies.forEach((item) => (fuelSupplies.value += item.value));
    this.resume.push(fuelSupplies);

    this.finalValueDebit =
      // this.finalValueTrafficTicket +
      this.finalValueFuelSupplies + this.finalValueToll;

    this.finalValue = this.finalValueCredit - this.finalValueDebit;

    if (this.commissionType === 'percentage') {
      this.commissionValue =
        this.finalValue * (this.commission ? this.commission * 0.01 : 0);
    } else {
      this.commissionValue = this.commission;
    }

    this.paymentValue = this.commissionValue - this.finalValueTrafficTicket;

    this.newValues.forEach((item) => {
      if (item.type === 'credit') {
        this.paymentValue += item.value;
      } else {
        ('');
        this.paymentValue -= item.value;
      }
    });

    this.refunds.forEach((item) => (this.paymentValue += item.value));
  }
}
