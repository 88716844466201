import { Bank } from './bank';

export class BankAccount {
    id: number;
    agency: number;
    agencyDv: number;
    bankAccount: number;
    bankAccountDv: number;
    type: number;
    active: boolean;
    receivePayments: boolean;
    bankId: number;
    bank: Bank;
    socialNumber: string;
    socialName: string;

    constructor() { }
}
