<div
  class="page-header sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-white">
  <div class="page-wrapper">
    <div class="page-header navbar">
      <div class="page-header-inner">
        <!-- logo start -->
        <div class="page-logo">
          <a href="index.html">
            <img alt="Logo da Golden Taxi" src="assets/img/logo.png" />
            <span class="logo-default ml-2"></span>
          </a>

        </div>
        <!-- logo end -->
        <ul class="nav navbar-nav navbar-left in">

          <b *ngIf="convenant" class="mt-4">Convênio {{convenantName}}, Seja Bem-vindo ao seu Portal de Solicitação de
            Corridas Golden
            Táxi.</b>

          <!-- <li><a class="menu-toggler sidebar-toggler font-size-23"><i class="fa fa-bars"></i></a></li> -->
        </ul>
        <ul class="nav navbar-nav navbar-left in"></ul>
        <!-- <form class="search-form-opened" action="#" method="GET">
               <div class="input-group">
                  <input type="text" class="form-control" placeholder="Buscar" name="query">
                  <span class="input-group-btn search-btn">
                     <a href="javascript:;" class="btn submit">
                        <i class="fa fa-search"></i>
                     </a>
                  </span>
               </div>
            </form> -->
        <!-- start mobile menu -->
        <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse"
          data-target=".navbar-collapse">
          <span></span>
        </a>
        <!-- end mobile menu -->
        <!-- start header menu -->
        <div class="top-menu">
          <ul class="nav navbar-nav pull-right">

            <!-- start notification dropdown -->
            <li *ngIf="user == 'Administrador'" class="dropdown dropdown-extended dropdown-notification"
              id="header_notification_bar">
              <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"
                data-close-others="true">
                <i class="fa fa-bell-o"></i>
                <div *ngIf="notifications?.length > 0">
                  <span class="notify"></span>
                  <span class="heartbeat"></span>
                </div>
              </a>
              <ul class="dropdown-menu pullDown">
                <li class="external">
                  <h3><span class="bold">Notificações</span></h3>
                  <span class="notification-label purple-bgcolor">{{notifications?.length}}</span>
                </li>
                <li>
                  <ul class="dropdown-menu-list small-slimscroll-style" data-handle-color="#637283">
                    <li *ngFor="let notification of notifications">
                      <a href="/notification">
                        <!-- <span class="time">just now</span> -->
                        <span class="details">
                          <span class="notification-icon circle deepPink-bgcolor box-shadow-1"><i
                              class="fa fa-warning"></i></span> {{notification.description}}. </span>
                      </a>
                    </li>

                  </ul>
                  <div class="dropdown-menu-footer">
                    <a href="/notification"> Marcar como Lida </a>
                  </div>
                </li>
              </ul>
            </li>
            <!-- end notification dropdown -->
            <li *ngIf="user == 'Administrador'">
              <a title="UserSettings" routerLink="/user/alter-password"><i
                  class="material-icons logout-icon">settings</i></a>
            </li>
            <li>
              <a title="Logout" href="/logout"><i class="material-icons logout-icon">power_settings_new</i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>