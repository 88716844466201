import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Car } from 'src/app/domain/car';
import { CostCenter } from 'src/app/domain/cost-center';
import { Pagination } from 'src/app/domain/pagination';
import { PaymentMethod } from 'src/app/domain/paymentMethod';
import { EnumStatusRun } from 'src/app/enumerations/status-run';
import { CarService } from 'src/app/service/car/car.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { PaymentMethodsService } from 'src/app/service/payment-methods/payment-methods.service';
import { RunService } from 'src/app/service/run/run.service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Passenger } from 'src/app/domain/passenger';
import { Driver } from 'src/app/domain/driver';
import { DriverService } from 'src/app/service/driver/driver.service';
import { Covenant } from 'src/app/domain/covenant';
import { CovenantService } from 'src/app/service/covenant/covenant.service';

@Component({
  selector: 'app-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.css'],
})
export class RunsComponent implements OnInit {
  runs = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  paymentMethods: Array<PaymentMethod> = [];
  costCenters: Array<CostCenter> = [];
  cars: Array<Car> = [];
  drivers: Array<Driver> = [];
  covenants: Covenant[] = [];

  first: boolean = false;
  last: boolean = false;

  filter: Filter;

  constructor(
    private service: RunService,
    private servicePaymentMethod: PaymentMethodsService,
    private serviceCostCenter: CostCenterService,
    private serviceCar: CarService,
    private serviceDriver: DriverService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private covenantService: CovenantService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;

      this.filter = new Filter();
      // this.filter.active = params.active ?? null;
      // this.filter.costCenter = params.cost_center ?? null;
      this.filter.status =
        params.status != undefined ? params.status : 'scheduled';
      // this.filter.paymentMethod = params.payment_method ?? null;
      this.filter.dateStart =
        params.date_start ?? moment().format('yyyy-MM-DD');
      this.filter.dateEnd = params.date_end ?? moment().format('yyyy-MM-DD');
      this.filter.hasDriverLinked =
        params.has_driver_linked != undefined
          ? params.has_driver_linked
          : 'false';
      this.filter.driver = params.driver ?? null;
      this.filter.vehicle = params.vehicle ?? null;
      this.filter.covenant = params.covenant ?? null;
      this.filter.passenger = params.passenger ?? null;
    });
  }

  ngOnInit(): void {
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getCovenants();
    this.getPaymentMethods();
    this.getCostCenters();
    this.getCars();

    this.createForm();
    this.getRuns();
    this.getDrivers();
  }
  onSearch(page?: number) {
    if (page) this.pagination.page = 1;
    this.filter.costCenter = this.searchForm.controls.costCenter.value;
    this.filter.status = this.searchForm.controls.status.value;
    this.filter.paymentMethod = this.searchForm.controls.paymentMethod.value;
    this.filter.vehicle = this.searchForm.controls.vehicle.value;
    this.filter.dateStart = this.searchForm.controls.dateStart.value;
    this.filter.dateEnd = this.searchForm.controls.dateEnd.value;
    this.filter.hasDriverLinked =
      this.searchForm.controls.hasDriverLinked.value;
    this.filter.driver = this.searchForm.controls.driver.value;
    this.filter.covenant = this.searchForm.controls.covenant.value;
    this.filter.passenger = this.searchForm.controls.passenger.value;

    this.setNavigate();
  }
  onSelectedResults(e) {
    this.pagination.per_page = e.target.value;
    this.setNavigate();
  }

  getCovenants() {
    this.covenantService
      .getAllCovenants()
      .subscribe((data) => (this.covenants = data));
  }

  getPaymentMethods() {
    this.servicePaymentMethod
      .getPaymentMethods()
      .subscribe((data) => (this.paymentMethods = data));
  }

  getCostCenters() {
    this.serviceCostCenter
      .getAllCostCenters()
      .subscribe((data) => (this.costCenters = data));
  }

  getCars() {
    this.serviceCar.getCarsActives().subscribe((data) => {
      this.cars = data.map((car) => {
        return {
          ...car,
          modelAndName: `${car.model.name} - ${car.carPlate}`,
        };
      });
    });
  }

  getDrivers() {
    this.serviceDriver
      .getAllDriversBasic()
      .subscribe((data) => (this.drivers = data));
  }

  createForm() {
    this.searchForm = this.fb.group({
      per_page: [this.pagination.per_page],
      costCenter: [this.filter.costCenter],
      status: [this.filter.status],
      paymentMethod: [this.filter.paymentMethod],
      dateStart: [this.filter.dateStart],
      dateEnd: [this.filter.dateEnd],
      hasDriverLinked: [this.filter.hasDriverLinked ?? 'false'],
      driver: [this.filter.driver],
      vehicle: [this.filter.vehicle],
      covenant: [this.filter.covenant],
      passenger: [this.filter.passenger],
    });
  }

  linkVehicle(id: number) {
    Swal.fire('Vincular Motorista', `Numero do Veículo - ${id}`);
  }

  getRuns() {
    this.loading = true;

    this.service.getRunsBackoffice(this.filter, this.pagination).subscribe(
      (data) => {
        this.runs = data['results'].map((Run, index, array) => {
          Run.status = EnumStatusRun[Run.status];
          Run.runPassengers?.forEach(({ isMain, origin, destiny }) => {
            if (isMain && origin) {
              // Run.runRequest.origin = `${origin.publicPlace} ${origin.neighborhood} ${origin.city.name} ${origin.zipCode}`;
              Run.origin = origin.city.name;
              Run.destiny = destiny.city.name;
            }
          });
          return Run;
        });
        this.setPagination(data);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  exportTripRequestRuns(type: string) {
    this.loading = true;

    this.filter.costCenter = this.searchForm.controls.costCenter.value;
    this.filter.status = this.searchForm.controls.status.value;
    this.filter.paymentMethod = this.searchForm.controls.paymentMethod.value;
    this.filter.vehicle = this.searchForm.controls.vehicle.value;
    this.filter.dateStart = this.searchForm.controls.dateStart.value;
    this.filter.dateEnd = this.searchForm.controls.dateEnd.value;
    this.filter.hasDriverLinked =
      this.searchForm.controls.hasDriverLinked.value;
    this.filter.driver = this.searchForm.controls.driver.value;
    this.filter.covenant = this.searchForm.controls.covenant.value;
    this.filter.passenger = this.searchForm.controls.passenger.value;

    this.service.exportTripRequestRuns(this.filter, type).subscribe(
      (response) => {
        const fileName = `Relatorio_Corridas_${moment().format('DDMMyyyy')}.${
          type == 'excel' ? '.xlsx' : 'pdf'
        }`;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(response.body);
        link.download = fileName;
        link.click();

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  seeReason(data) {
    Swal.fire(data.motive, data.description, 'error');
  }

  cancelRun(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Cancelar Corrida?',
      // showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: `Confirmar`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      // denyButtonText: `Voltar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.service.cancel(id).subscribe(
          (data) => {
            Swal.fire('Cancelado!', '', 'success');
            this.setNavigate();
          },
          (error) => {
            Swal.fire('Erro ao Cancelar', '', 'error');
          }
        );
      }
    });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router
      .navigate(['/process/runs'], {
        queryParams: {
          cost_center: this.filter.costCenter ?? null,
          status: this.filter.status ?? null,
          paymentMethod: this.filter.paymentMethod ?? null,
          date_start: this.filter.dateStart ?? null,
          date_end: this.filter.dateEnd ?? null,
          has_driver_linked: this.filter.hasDriverLinked ?? null,
          driver: this.filter.driver ?? null,
          vehicle: this.filter.vehicle ?? null,
          covenant: this.filter.covenant ?? null,
          passenger: this.filter.passenger ?? null,
          page: this.pagination.page ?? 1,
          per_page: this.pagination.per_page ?? 10,
        },
      })
      .finally(() => this.getRuns());
  }

  getPassengersName(passengers: Array<Passenger>) {
    const names = [];
    passengers.forEach(({ client, collaborator }) => {
      const _name = (client ? client.name : collaborator?.name) ?? '';
      names.push(_name);
    });
    return names.join('<br>');
  }
}

class Filter {
  costCenter: number;
  paymentMethod: number;
  dateStart: Date;
  dateEnd: Date;
  hasDriverLinked: boolean;
  driver: number;
  vehicle: number;
  status: string;
  active: boolean;
  covenant: string;
  passenger: string;
}
