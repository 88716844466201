export enum Flag {
    Amex = "assets/img/flags/amex.jpg",
    Diners = "assets/img/flags/diners.png",
    Discover = "assets/img/flags/discover.png",
    Elo = "assets/img/flags/elo.png",
    Hipercard = "assets/img/flags/hipercard.svg",
    JCB = "assets/img/flags/jcb.png",
    Mastercard = "assets/img/flags/mastercard.png",
    Visa = "assets/img/flags/visa.jpg",
    Aura = "assets/img/flags/aura.png"
}