import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Bank } from 'src/app/domain/bank';
import { Pagination } from 'src/app/domain/pagination';
import { BankDTO } from 'src/app/domain/dto/bank.dto';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  url: string = `${environment.BASE_URL}/banks`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getBanks(bank: Bank, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${bank.name ?? ''}`,
      number: `${bank.number ?? ''}`,
      active: `${bank.active ?? ''}`,
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  getAllBanks() {
    return this.http.get<Array<Bank>>(`${this.url}/all-active`, {
      responseType: 'json',
    });
  }

  getBank(id: number) {
    return this.http.get<Bank>(`${this.url}/${id}`, { responseType: 'json' });
  }
  save(bank: Bank) {
    let dto = new BankDTO(bank);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(bank: Bank) {
    let dto = new BankDTO(bank);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${bank.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
