import { Component, OnInit } from '@angular/core';
import { CreditCard } from 'src/app/domain/credit-card';
import { CreditCardService } from 'src/app/service/credit-card/credit-card.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Flag } from 'src/app/enumerations/flag';

@Component({
  selector: 'app-credit-card-view',
  templateUrl: './credit-card-view.component.html',
  styleUrls: ['./credit-card-view.component.css']
})
export class CreditCardViewComponent implements OnInit {

  id: number;
  creditCard: CreditCard = new CreditCard();
  loading: boolean = true;

  constructor(
    private service: CreditCardService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getCreditCards();
  }

  getCreditCards() {
    this.loading = true;
    this.service.getCreditCard(this.id).subscribe(data => {
      data['flag'] = Flag[data['flag']];
      this.creditCard = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  onDelete(creditCard: CreditCard) {
    Swal.fire({
      title: 'Excluir Cartão de Crédito?',
      text: `final: ${creditCard.number}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero excluir!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.delete(creditCard.id).subscribe(data => {
          // Swal.fire('Excluído', 'Cartão de Crédito excluído com sucesso!', 'success');
          Swal.fire({
            title: 'Excluído',
            text: `Cartão de Crédito excluído com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            this.router.navigate(['parameters/credit-card']);
          })
        },
          error => {
            Swal.fire('Ops', 'Erro ao excluir Cartão de Crédito!', 'error');
          });
      }

    });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Cartão de Crédito!', 'error');
  }
}
