import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CarMotor } from 'src/app/domain/car-motor';

@Injectable({
  providedIn: 'root'
})
export class CarMotorService {

  url: string = `${environment.BASE_URL}/motors`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCarMotors() {
    return this.http.get(this.url,
      { responseType: "json" });

  }


  getCarMotor(id: number) {
    return this.http.get<CarMotor>(`${this.url}/${id}`,
      { responseType: "json" });

  }
}
