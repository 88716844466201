import { Address } from './address';

export class GasStation {

    id: number;
    name: string;
    phone: string;
    whatsapp: string;
    contact: string;
    email: string;
    address: Address;
    active: boolean;

    constructor() { }
}