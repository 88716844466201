import { Component, OnInit } from '@angular/core';
import { GasStation } from 'src/app/domain/gas-station';
import { GasStationService } from 'src/app/service/gas-station/gas-station.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Pagination } from 'src/app/domain/pagination';

@Component({
  selector: 'app-gas-station-view',
  templateUrl: './gas-station-view.component.html',
  styleUrls: ['./gas-station-view.component.css']
})
export class GasStationViewComponent implements OnInit {

  id: number;
  gasStation: GasStation = new GasStation();
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  credits = [];
  balance: number;
  form: FormGroup;

  constructor(
    private service: GasStationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getGasStation();
    this.createForm();
    this.getListCredit();
  }

  createForm() {
    this.form = this.fb.group({
      credit: ['', [Validators.required]],
    });
  }

  onSubmit() {

    this.loading = true;

    const data = {
      gasStation: {
        id: this.id
      },
      value: this.form.controls.credit.value
    }

    this.service.saveCredit(data).subscribe(data => {
      this.loading = false;
      location.reload();
    }, error => {
      Swal.fire('Erro', 'Erro ao adicionar Crédito ao Posto', 'error');
      this.loading = false;
    });
  }

  getGasStation() {
    this.loading = true;
    this.service.getGasStation(this.id).subscribe(data => {
      this.gasStation = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }
  getListCredit() {
    this.service.listCredit(this.id, this.pagination).subscribe(data => {
      this.credits = data['results'];
      this.balance = data['balance'];
      this.loading = false;
    },
      error => {
        this.loading = false;
        // this.openAlertError();
      });
  }

  deleteCredit(credit) {
    this.service.deleteCredit(credit.id).subscribe(data => {
      Swal.fire('Sucesso', 'Crédito excluído com sucesso', 'success');
      this.credits.splice(this.credits.indexOf(credit), 1);
      this.balance -= credit.value;
      // location.reload();
    }, error => {
      Swal.fire('Ops...', 'Não foi possível excluir crédito!', 'error');
    });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Posto de Combustível!', 'error');
  }


}
