import { Component, OnInit } from '@angular/core';
import { Login } from 'src/app/domain/login';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login/login.service';
import { Router } from '@angular/router';
import { User } from 'src/app/enumerations/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: Login = new Login();
  loginForm: FormGroup;
  loading: boolean = false;
  submited: boolean = false;

  constructor(
    private service: LoginService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }
  onSubmit() {
    this.submited = true;

    if (this.loginForm.controls.login.invalid && this.loginForm.controls.login.value) {
      this.loginForm.controls.login.setErrors(null);
    }
    if (!this.loginForm.invalid) {
      this.login.login = this.loginForm.controls.login.value;
      this.login.password = this.loginForm.controls.password.value;
      this.loading = true;

      this.service.login(this.login).subscribe(data => {
        localStorage.setItem("token", data.headers.get('X-Authorization'))
        localStorage.setItem("user", User.ADMIN);
        this.loading = false;
        this.router.navigate(['/'])
      }, error => {
        this.loading = false;
        this.loginForm.controls.login.setErrors({ invalid: 'Usuário e/ou senha inválidos!!' })
      });
    } else {
      // if(this.loginForm.controls.login.value.length() === 0){this.loginForm.controls.email.setErrors()}
    }


  }

  createForm() {
    this.loginForm = this.fb.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

}
