import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import Swal from 'sweetalert2';
import { CovenantUser } from 'src/app/domain/coventant-user';
import { ActivatedRoute } from '@angular/router';
import { Pagination } from 'src/app/domain/pagination';
import { CovenantUserService } from 'src/app/service/covenant-user/covenant-user.service';

@Component({
  selector: 'app-covenant-user',
  templateUrl: './covenant-user.component.html',
  styleUrls: ['./covenant-user.component.css']
})
export class CovenantUserComponent implements OnInit {
  searchForm: FormGroup;
  covenantUserForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  pagination: Pagination = new Pagination;
  covenantId: number;
  users: Array<CovenantUser> = [];
  isEditingId: number = null;
  covenantUser: CovenantUser = new CovenantUser;

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: CovenantUserService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.covenantId = params['id'];
    });

    this.route.queryParams.subscribe(params => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getUsers();
  }

  // ---------------- FUNÇÕES DE LISTAGEM DE USUÁRIOS --------------------
  getUsers() {
    this.loading = true;

    this.service.getCovenantUsers(this.covenantId, this.pagination, this.covenantUser).subscribe(
      data => {
        this.users = data['results'];
        this.setPagination(data);
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os usuários!', 'error');
  }

  onSearch() {
    this.covenantUser.name = this.searchForm.controls.name.value;
    this.covenantUser.role = this.searchForm.controls.role.value;

    this.getUsers();
  }

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE USUÁRIOS --------------------
  createForm() {
    this.covenantUserForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
      role: ['', [Validators.required, Validators.maxLength(100)]],
    });
    this.isEditingId = null;

    this.searchForm = this.fb.group({
      name: [this.covenantUser.name],
      role: [this.covenantUser.role],
    });
  }

  onSubmit() {
    const values = this.covenantUserForm.controls;
    let covenantUser = new CovenantUser();
    if (this.isEditingId) {
      covenantUser.id = this.isEditingId;
    }
    covenantUser.name = values.name.value;
    covenantUser.email = values.email.value;
    covenantUser.role = values.role.value;

    if (!this.isEditingId) {
      this.loading = true;
      this.service.save(this.covenantId, covenantUser).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Usuário salvo com sucesso!!', 'success');
        this.createForm();
        this.getUsers();
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível salvar Usuário!', 'error');
        });
    } else {
      this.loading = true;
      this.service.update(this.covenantId, covenantUser).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Usuário salvo com sucesso!!', 'success');
        this.createForm();
        this.getUsers();
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível salvar Usuário!', 'error');
        });
    }


  }

  onUserEdit(covenantUser) {
    this.isEditingId = covenantUser.id;
    this.covenantUserForm.controls.name.setValue(covenantUser.name);
    this.covenantUserForm.controls.email.setValue(covenantUser.email);
    this.covenantUserForm.controls.role.setValue(covenantUser.role);
  }

  onUserDelete(covenantUser) {
    this.service.delete(this.covenantId, covenantUser.id).subscribe(data => {
      this.loading = false;
      Swal.fire('Excluido!', 'Usuário excluído com sucesso!!', 'success');
      this.createForm();
      this.getUsers();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao excluir', 'Não foi possível excluir Usuário!', 'error');
      });
  }

  resetForm() {
    this.covenantUserForm.controls.name.setValue("");
    this.covenantUserForm.controls.email.setValue("");
    this.covenantUserForm.controls.role.setValue("");
  }

  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "name":
          this.covenantUserForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "email":
          this.covenantUserForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "role":
          this.covenantUserForm.controls.role.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }
  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE USUÁRIOS --------------------


}
