import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CostCenter } from 'src/app/domain/cost-center';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cost-center-update',
  templateUrl: './cost-center-update.component.html',
  styleUrls: ['./cost-center-update.component.css']
})
export class CostCenterUpdateComponent implements OnInit {

  costForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  costcenters: Array<CostCenter> = [];
  costcenter: CostCenter = new CostCenter();

  types = [{ name: "Crédito" }, { name: "Débito" }];

  constructor(
    private service: CostCenterService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.costcenter.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.service.getCostCenter(this.costcenter.id).subscribe(data => {
      this.costcenter = data;
      this.setValues();
    });

    this.service.getAllCostCenters().subscribe(data => {
      this.costcenters = data;
      console.log(data);

    });
  }

  createForm() {
    this.costForm = this.fb.group({
      father: ['', []],
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      identifier: ['', [Validators.required]],
      costcenter: ['', []],
      active: ['', []]
    });
  }
  setValues() {
    this.costForm.controls.name.setValue(this.costcenter.name);
    this.costForm.controls.type.setValue(this.costcenter.type);
    this.costForm.controls.identifier.setValue(this.costcenter.identifier);
    this.costForm.controls.active.setValue(`${this.costcenter.active}`);

    if (this.costcenter.parent != null) {
      this.costForm.controls.costcenter.setValue(this.costcenter.parent.id);
    } else {
      this.costForm.controls.father.setValue(true);
      this.costForm.controls.costcenter.disable();
    }
  }
  onCheckboxChange(e) {
    // const website: FormArray = this.form.get('website') as FormArray;

    if (e.target.checked) {
      this.costForm.controls.costcenter.disable();
      this.costForm.controls.costcenter.setValue("");
      if (!this.costcenter.parent) {
        this.costForm.controls.identifier.setValue(this.costcenter.identifier);
      } else {
        this.costForm.controls.identifier.setValue("");
        this.service.getIdentifier(null).subscribe(data => {
          this.costForm.controls.identifier.setValue(data["nextId"]);
        });
      }

    } else {
      this.costForm.controls.costcenter.enable();
      this.costForm.controls.identifier.setValue("");
    }
  }

  onSelectCostCenter(event) {

    if (this.costcenter.parent && this.costForm.controls.costcenter.value == this.costcenter.parent.id) {
      this.costForm.controls.identifier.setValue(this.costcenter.identifier);
    } else {
      this.service.getIdentifier(this.costForm.controls.costcenter.value).subscribe(data => {
        this.costForm.controls.identifier.setValue(data["nextId"]);
        console.log(data["nextId"]);
      });
    }
  }

  onSubmit() {
    if (this.costForm.controls.costcenter.value) {
      const costcenter = this.costcenters.filter(element => this.costForm.controls.costcenter.value == element.id);
      if (costcenter[0].type != this.costForm.controls.type.value) {
        this.costForm.controls.type.setErrors({ invalid: "Tipo da classe filha deve ser do mesmo tipo da classe pai" });
      }
    }
    if (this.costForm.valid) {
      let costcenter = new CostCenter();
      costcenter.id = this.costcenter.id
      costcenter.name = this.costForm.controls.name.value;
      costcenter.type = this.costForm.controls.type.value;
      costcenter.identifier = this.costForm.controls.identifier.value;
      if (this.costForm.controls.active.value == "true") {
        costcenter.active = true
      } else {
        costcenter.active = false;
      }
      if (this.costForm.controls.costcenter.value.length) {
        costcenter.parent = new CostCenter();
        costcenter.parent.id = this.costForm.controls.costcenter.value;
      }
      console.log(costcenter);

      this.loading = true;

      this.service.update(costcenter).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Centro de Custo editado com sucesso!!', 'success').then((result) => {
          if (result.value) {
            this.router.navigate(['financial/cost-center']);
          }
        });
        this.createForm();
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível editar Centro de Custo!', 'error');
        });
    }
  }

  resetForm() {
    this.costForm.controls.name.setValue("");
    this.costForm.controls.father.setValue("");
    this.costForm.controls.type.setValue("");

  }

  setErrors(errors) {
    errors.forEach(erro => {
      console.log(erro);

      switch (erro.fieldName) {
        case "name":
          this.costForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "father":
          this.costForm.controls.father.setErrors({ invalid: erro.message });
          break;
        case "type":
          this.costForm.controls.type.setErrors({ invalid: erro.message });
          break;
        case "identifier":
          this.costForm.controls.identifier.setErrors({ invalid: erro.message });
          break;
        case "costcenter":
          this.costForm.controls.costcenter.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }
}
