import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { take } from 'rxjs/operators';
import { Pagination } from 'src/app/domain/pagination';
import { RunService } from 'src/app/service/run/run.service';
import moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-chart-runs-by-hour',
  templateUrl: './chart-runs-by-hour.component.html',
  styleUrls: ['./chart-runs-by-hour.component.css'],
})
export class ChartRunsByHourComponent implements OnInit {
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barLegend = false;
  barChartLabels: Label[] = Array(24)
    .fill('')
    .map((_, index) => String(index));
  barChartType: ChartType = 'bar';
  barChartData: ChartDataSets[] = [];
  filterGroup: FormGroup;
  pagination: Pagination = new Pagination();

  constructor(private runService: RunService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.createFilter();
    this.getRuns();
  }

  createFilter() {
    this.filterGroup = this.fb.group({
      dateStart: [
        moment().startOf('month').format('YYYY-MM-DD'),
        Validators.required,
      ],
      dateEnd: [
        moment().endOf('month').format('YYYY-MM-DD'),
        Validators.required,
      ],
      hasDriverLinked: '',
    });
  }

  getRuns() {
    if (this.filterGroup.valid) {
      this.runService
        .getRunsBackoffice(this.filterGroup.value, {
          ...this.pagination,
          page: 1,
          per_page: 99999,
        })
        .pipe(take(1))
        .subscribe((data: any) => {
          const runsParsedData = [];
          data.results.forEach((run) => {
            const dateLabel = moment(run.date).format('MM/YYYY');
            const [hour, minute] = run.startHour.split(':');
            const timer = Number(minute) > 30 ? String(Number(hour) + 1) : hour;
            const labelIndex = runsParsedData.findIndex(
              (runParsed) => runParsed.label === dateLabel
            );

            if (labelIndex !== -1) {
              runsParsedData[labelIndex].data[timer] = String(
                Number(runsParsedData[labelIndex].data[timer]) + 1
              );
            } else {
              const data = Array(24).fill(0);
              data[timer] = 1;
              data.find;
              runsParsedData.push({
                label: dateLabel,
                data,
              });
            }
          });
          this.barLegend = !!runsParsedData.length;
          this.barChartData = runsParsedData;
        });
    }
  }
}
