import { Car } from '../car';
import { Permission } from '../permission';
import { State } from '../state';
import { City } from '../city';
import { CarModel } from '../car-model';
import { CarMotor } from '../car-motor';
import { Insurer } from '../insurer';
import { CarBrand } from '../car-brand';

export class CarDTO {

    id: number;
    vehicleModel: CarModel;
    motor: CarMotor;
    insurer: Insurer;
    insurancePolicy: string;
    dueDateInsurancePolicy: Date;
    city: City;
    year: string;
    color: string;
    fuel: string;
    carPlate: string;
    renavam: string;
    chassi: string;
    expirationDateIpva: Date;
    pedometer: string;
    carAir: boolean;
    owner: string;
    category: string;
    type: string;
    numberPassengers: number;
    numberLargeSuitcases: number;
    numberSmallSuitcases: number;
    active: boolean;


    constructor(car: Car) {
        this.id = car.id;
        this.vehicleModel = car.vehicleModel;
        this.motor = car.motor;
        this.insurer = car.insurer;
        this.insurancePolicy = car.insurancePolicy;
        this.dueDateInsurancePolicy = car.dueDateInsurancePolicy;
        this.city = car.city;
        this.year = car.year;
        this.color = car.color;
        this.fuel = car.fuel;
        this.carPlate = car.carPlate;
        this.renavam = car.renavam;
        this.chassi = car.chassi;
        this.expirationDateIpva = car.expirationDateIpva;
        this.pedometer = car.pedometer;
        this.carAir = car.carAir;
        this.owner = car.owner;
        this.category = car.category;
        this.type = car.type;
        this.numberPassengers = car.numberPassengers;
        this.numberLargeSuitcases = car.numberLargeSuitcases;
        this.numberSmallSuitcases = car.numberSmallSuitcases;
        this.active = car.active;
    }
}