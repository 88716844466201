<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading">
</ngx-loading>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">

                    <div class="card card-topline-green">
                        <div class="card-head">
                            <header>Visualizar Fornecedor</header>
                        </div>
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Nome</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ provider.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>CPF / CNPJ</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ provider.cpfCnpj | mask: 'CPF_CNPJ' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Inscrição estadual</b>
                                        </div>
                                        <div class="col-md-12">
                                            <span *ngIf="provider.stateRegistration">{{ provider.stateRegistration
                                                }}</span>
                                            <span *ngIf="!provider.stateRegistration">Não Informado.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Inscrição Municipal</b>
                                        </div>
                                        <div class="col-md-12">
                                            <span *ngIf="provider.municipalRegistration">{{
                                                provider.municipalRegistration }}</span>
                                            <span *ngIf="!provider.municipalRegistration">Não Informado.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Email</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ provider.email }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Contato</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ provider.contact }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>WhatsApp</b>
                                        </div>
                                        <div class="col-md-12">
                                            <span *ngIf="provider.whatsapp">{{ provider.whatsapp | mask: '+00 00 0
                                                0000-0000' }}</span>
                                            <span *ngIf="!provider.whatsapp">Não informado</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Telefone</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{provider.phone | mask: '(00) 0000-0000||(00) 0 0000-0000' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Status</b>
                                        </div>
                                        <div class="col-md-12">
                                            <span *ngIf="provider.active"
                                                class="label label-sucess label-mini">Ativo</span>
                                            <span *ngIf="!provider.active"
                                                class="label label-danger label-mini">Inativo</span>

                                        </div>
                                        <div class="col-md-6">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b>Endereço</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>CEP</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ provider.address.zipCode }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Logradouro</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{provider.address.publicPlace}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Bairro</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ provider.address.neighborhood }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Cidade</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{provider.address.city.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Estado</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ provider.address.city.state.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-4">
                                    <a routerLink="/parameters/provider/edit/{{ provider.id }}" class="btn btn-warning">Editar</a>
                                </div>
                                <div class="col-md-4">
                                    <a routerLink="/parameters/provider" class="btn btn-info">Voltar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
