import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Bank } from 'src/app/domain/bank';
import { BankAccount } from 'src/app/domain/bank-account';
import { Pagination } from 'src/app/domain/pagination';
import { BankAccountService } from 'src/app/service/bank-account/bank-account.service';
import { BankService } from 'src/app/service/bank/bank.service';
import Swal from 'sweetalert2';
import { BankAccountType } from '../../enumerations/bank-account-type';

@Component({
  selector: 'app-account-bank',
  templateUrl: './account-bank.component.html',
  styleUrls: ['./account-bank.component.css'],
})
export class AccountBankComponent implements OnInit {
  searchForm: FormGroup;
  pagination: Pagination = new Pagination();
  showResultsForm: FormGroup;
  bankAccountType = BankAccountType;
  bankAccounts: Array<BankAccount> = [];
  filter: any;
  banks: Array<Bank> = [];
  loading: boolean = true;
  first: boolean = false;
  last: boolean = false;

  constructor(
    private fb: FormBuilder,
    private bankAccountService: BankAccountService,
    private bankService: BankService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.createForm();
    this.getAllBanks();
    this.getAllBankActive();
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      type: [''],
      receivePayments: [''],
      active: [''],
      bank: [null],
    });
  }

  getAllBankActive() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível buscar os bancos!', 'error');
      }
    );
  }

  activeBankAccount(bankAccount: BankAccount) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Conta Bancária? ${bankAccount.bankAccount}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        bankAccount.active = false;
        this.bankAccountService.update(bankAccount).subscribe((response) => {
          Swal.fire(
            'Alterado',
            'Conta Bancária desativada com sucesso!!',
            'success'
          );
        }),
          (error) => {
            this.loading = false;

            Swal.fire(
              'Erro ao desativar',
              'Não foi possível desativar conta bancária!',
              'error'
            );
          };
      }
    });
  }

  inactiveBankAccount(bankAccount: BankAccount) {
    const sendBankAccount = bankAccount;

    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Conta Bancária? ${bankAccount.bankAccount}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        sendBankAccount.active = true;
        this.bankAccountService
          .update(sendBankAccount)
          .subscribe((response) => {
            Swal.fire(
              'Alterado',
              'Conta Bancária ativada com sucesso!!',
              'success'
            );
          }),
          (error) => {
            this.loading = false;
            Swal.fire(
              'Erro ao ativada',
              'Não foi possível ativada conta bancária!',
              'error'
            );
          };
      }
    });
  }

  getAllBanks() {
    if (this.filter && !this.filter?.bank) {
      delete this.filter.bank;
    }
    this.bankAccountService
      .getBanksAccounts(this.filter, this.pagination)
      .subscribe((data) => {
        this.bankAccounts = data['results'];
        this.setPagination(data);
      });
  }

  onSearch() {
    this.setNavigate();
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }

  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }

  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }

  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }

  setNavigate() {
    this.filter = this.searchForm.getRawValue();
    this.router.navigate(['/financial/account-bank'], {
      queryParams: {
        page: this.pagination.page,
        per_page: this.pagination.per_page,
        ...this.filter,
      },
    });
    this.getAllBanks();
  }
}
