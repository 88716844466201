import { Component, OnInit } from '@angular/core';
import { GasStation } from 'src/app/domain/gas-station';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GasStationService } from 'src/app/service/gas-station/gas-station.service';
import { AddressService } from 'src/app/service/address/address.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/domain/address';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gas-station',
  templateUrl: './gas-station.component.html',
  styleUrls: ['./gas-station.component.css'],
})
export class GasStationComponent implements OnInit {
  gasStations: Array<GasStation> = [];
  states: Array<State> = [];
  cities: Array<City> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  gasStation: GasStation = new GasStation();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: GasStationService,
    private serviceAddress: AddressService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.gasStation.address = new Address();
      this.gasStation.address.city = new City();
      this.gasStation.address.city.state = new State();

      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
      this.gasStation.name = params.name || '';
      this.gasStation.contact = params.contact || '';
      this.gasStation.active = params.active || null;
      this.gasStation.address.city.id = params.city || null;
      this.gasStation.address.city.state.id = params.state || '';
    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getStates();
    this.getGasStations();
  }
  getStates() {
    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {}
    );
  }
  getGasStations() {
    this.loading = true;
    this.service.getGasStations(this.gasStation, this.pagination).subscribe(
      (data) => {
        this.gasStations = data['results'];
        this.setPagination(data);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      name: [this.gasStation.name],
      contact: [this.gasStation.contact],
      state: [this.gasStation.address.city.state.id],
      city: [this.gasStation.address.city.id],
      active: [this.gasStation.active],
    });
  }

  onSearch() {
    this.gasStation.name = this.searchForm.controls.name.value;
    this.gasStation.contact = this.searchForm.controls.contact.value;
    this.gasStation.address.city.state.id =
      this.searchForm.controls.state.value;
    this.gasStation.address.city.id = this.searchForm.controls.city.value;
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
        },
        (error) => {
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }
  onActive(gasStation: GasStation) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Posto de Combustível? ${gasStation.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(gasStation.id).subscribe(
          (data) => {
            // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
            Swal.fire({
              title: 'Ativado',
              text: `Posto de Combustível ativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao ativar Posto de Combustível!', 'error');
          }
        );
      }
    });
  }
  onDeActive(gasStation: GasStation) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Posto de Combustível? ${gasStation.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(gasStation.id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Desativado',
              text: `Posto de Combustível desativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire(
              'Ops',
              'Erro ao desativar Posto de Combustível!',
              'error'
            );
          }
        );
      }
    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/parameters/gas-station'], {
      queryParams: {
        name: this.gasStation.name ?? '',
        contact: this.gasStation.contact ?? '',
        state: this.gasStation.address.city.state.id ?? '',
        city: this.gasStation.address.city.id ?? '',
        active: this.gasStation.active ?? '',
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getGasStations();
  }
}
