import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CostCenter } from 'src/app/domain/cost-center';
import { Pagination } from 'src/app/domain/pagination';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.css']
})
export class CostCenterComponent implements OnInit {
  costcenters: Array<CostCenter> = [];
  costcenter: CostCenter = new CostCenter();
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: CostCenterService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe(params => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.costcenter.name = params.name ?? '';
      this.costcenter.active = params.active ?? null;
      this.costcenter.type = params.type ?? null;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getCostCenters();
  }

  getCostCenters() {
    this.loading = true;

    this.service.getCostCenters(this.costcenter, this.pagination).subscribe({
      next: (data) => {
        this.costcenters = data['results'];
        this.setPagination(data);

        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.openAlertError();
      },
    });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire({
      title: 'Ops...',
      text: 'Ocorreu um erro ao carregar os dados!',
      icon: 'error',
    });
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });

    this.searchForm = this.fb.group({
      name: [this.costcenter.name],
      active: [this.costcenter.active],
      type: [this.costcenter.type],
    });
  }

  onSearch() {
    this.costcenter.name = this.searchForm.controls.name.value;

    switch (this.searchForm.controls.active.value) {
      case 'true': {
        this.costcenter.active = true;
        break;
      }

      case 'false': {
        this.costcenter.active = false;
        break;
      }

      default: {
        this.costcenter.active = null;
        break;
      }
    }

    switch (this.searchForm.controls.type.value) {
      case 'credit': {
        this.costcenter.type = 'Crédito';
        break;
      }

      case 'debit': {
        this.costcenter.type = 'Débito';
        break;
      }

      default: {
        this.costcenter.type = null;
        break;
      }
    }

    this.setNavigate();
  }

  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }

  onActive(costcenter: CostCenter) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Centro de Custo? ${costcenter.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(costcenter.id).subscribe(data => {
          Swal.fire({
            title: 'Ativado',
            text: `Centro de Custo ativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar Centro de Custo!', 'error');
          });
      }

    });
  }

  onDeActive(costcenter: CostCenter) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Centro de Custo? ${costcenter.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(costcenter.id).subscribe(data => {

          Swal.fire({
            title: 'Desativado',
            text: `Centro de Custo desativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao desativar Centro de Custo!', 'error');
          });
      }

    });
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }

  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }

  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }

  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/financial/cost-center'], {
      queryParams: {
        name: this.costcenter.name ?? "",
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
        active: this.costcenter.active ?? null,
        type: this.costcenter.type ?? null,
      }
    });

    this.getCostCenters();
  }
}
