import { Component, OnInit, Provider } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Bank } from 'src/app/domain/bank';
import { BankAccount } from 'src/app/domain/bank-account';
import { BillsToReceive } from 'src/app/domain/bills-to-receive';
import { Client } from 'src/app/domain/client';
import { CostCenter } from 'src/app/domain/cost-center';
import { Covenant } from 'src/app/domain/covenant';
import { BankAccountService } from 'src/app/service/bank-account/bank-account.service';
import { BankService } from 'src/app/service/bank/bank.service';
import { BillsToReceiveService } from 'src/app/service/bills-to-receive/bills-to-receive.service';
import { ClientService } from 'src/app/service/client/client.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bills-to-receive-create',
  templateUrl: './bills-to-receive-create.component.html',
  styleUrls: ['./bills-to-receive-create.component.css']
})
export class BillsToReceiveCreateComponent implements OnInit {
  billsToReceiveForm: FormGroup;
  loading: boolean = true;
  messageError: string = '';
  covenants: Array<Covenant> = [];
  clients: Array<Client> = [];
  costCenters: Array<CostCenter> = [];
  bankAccounts: Array<BankAccount> = [];
  banks: Array<Bank> = [];

  constructor(
    private covenantService: CovenantService,
    private clientService: ClientService,
    private costCenterService: CostCenterService,
    private bankAccountService: BankAccountService,
    private bankService: BankService,
    private service: BillsToReceiveService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getCostCenters();
    this.getBanks();
    this.getBankAccounts();
  }

  createForm() {
    this.billsToReceiveForm = this.fb.group({
      receiverType: [''],
      receiverName: [''],
      searcher: [''],
      covenant: [''],
      client: [''],
      bankAccount: [''],
      costCenter: ['', [Validators.required]],
      paymentType: ['', [Validators.required]],
      invoiceNumber: ['', [Validators.maxLength(20)]],
      value: ['', [Validators.required]],
      dueDate: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      paid: [false, [Validators.required]],
      parcAmount: ['', [Validators.maxLength(20)]],
      frequency: [''],
      bank: [''],
      bankCheckNumber: ['', [Validators.maxLength(10)]],
      parcels: this.fb.array([]),
    });

    this.billsToReceiveForm
      .get('value')
      .valueChanges.subscribe((val) => this.changeValue(val));

    this.billsToReceiveForm
      .get('dueDate')
      .valueChanges.subscribe((val) => this.changeDueDate(val));

    this.billsToReceiveForm
      .get('frequency')
      .valueChanges.subscribe((val) => this.changeFrequency(val));

    this.billsToReceiveForm
      .get('parcAmount')
      .valueChanges.subscribe((val) => this.changeParcAmount(val));

    this.billsToReceiveForm
      .get('paymentType')
      .valueChanges.subscribe((val) => this.changePaymentType(val));

    this.billsToReceiveForm
      .get('paymentMethod')
      .valueChanges.subscribe((val) => this.changePaymentMethod());

    this.billsToReceiveForm
      .get('searcher')
      .valueChanges.subscribe((val) => this.getSearchUser());
  }

  get parcels() {
    return this.billsToReceiveForm.get('parcels') as FormArray;
  }

  onlyNumber(event: any): void {
    event.key.match(/[^0-9]/) && event.preventDefault();
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getCostCenters() {
    this.costCenterService.getAllCostCenters().subscribe(
      (data) => {
        this.costCenters = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro',
          'Não foi possível Buscar os centros de custo!',
          'error'
        );
      }
    );
  }

  getBanks() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );
  }

  getBankAccounts() {
    this.bankAccountService.getAllActiveBanckAccounts().subscribe(
      (data) => {
        this.bankAccounts = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );
  }

  changeValue(val: string) {
    if (!val) return;
    this.setParcValue();
  }

  setParcValue() {
    const value = this.parcelValue();
    this.parcels.controls.forEach((item) => {
      item.get('value').setValue(value);
    });
  }

  parcelValue() {
    const { value, parcAmount } = this.billsToReceiveForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value.replace(/[^0-9]/g, '') / 100 / parcAmount;
  }

  changeDueDate(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  parcelDueDate(index) {
    const { frequency, dueDate } = this.billsToReceiveForm.getRawValue();
    if (!frequency || !dueDate) return null;

    const date = new Date(dueDate.replace('-', '/'));

    const iteration = index + 1;

    switch (frequency) {
      case 'days':
        date.setDate(date.getDate() + 1 * iteration);
        break;
      case 'weeks':
        date.setDate(date.getDate() + 7 * iteration);
        break;
      case 'fortnights':
        date.setDate(date.getDate() + 15 * iteration);
        break;
      case 'months':
        date.setMonth(date.getMonth() + 1 * iteration);
        break;
      case 'bimonthly':
        date.setMonth(date.getMonth() + 2 * iteration);
        break;
      case 'quarters':
        date.setMonth(date.getMonth() + 3 * iteration);
        break;
      case 'semesters':
        date.setMonth(date.getMonth() + 6 * iteration);
        break;
      case 'years':
        date.setFullYear(date.getFullYear() + 1 * iteration);
        break;
      default:
        break;
    }

    return this.formatDate(date);
  }

  changeFrequency(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  changeParcAmount(val: string) {
    const parcAmount = parseInt(val);

    if (isNaN(parcAmount) || parcAmount <= 1) {
      this.parcels.clear();
      return;
    }

    const currentLength = this.parcels.length;

    if (currentLength > parcAmount) {
      for (let i = currentLength + 1; i >= parcAmount; i--) {
        this.parcels.removeAt(i);
      }
    } else if (currentLength < parcAmount) {
      for (let i = currentLength + 1; i <= parcAmount; i++) {
        const dueDate = this.parcelDueDate(i - 1) || '';

        const paid = this.billsToReceiveForm.get('paid').value;

        this.parcels.push(
          this.fb.group({
            number: [i],
            value: [this.parcelValue()],
            paid: [paid],
            bankCheckNumber: ['', [Validators.maxLength(10)]],
            dueDate: [dueDate],
          })
        );
      }
    }

    this.setParcValue();
  }

  changePaymentType(value) {
    if (value === 'in_cash') {
      this.parcels.clear();
      this.billsToReceiveForm.get('parcAmount').setValue(null);
      this.billsToReceiveForm.get('frequency').setValue('');
    }
  }

  changePaymentMethod() {
    this.billsToReceiveForm.get('bank').setValue('');
    this.billsToReceiveForm.get('bankCheckNumber').setValue('');
    if (this.billsToReceiveForm.get('parcValue')) {
      this.changeParcAmount(this.billsToReceiveForm.get('parcValue').value);
    }
  }

  getSearchUser() {
    if (this.billsToReceiveForm.controls.searcher.value.length < 3) return;

    if (this.billsToReceiveForm.controls.receiverType.value === 'client') {
      this.getClientSearch();
    }
    if (this.billsToReceiveForm.controls.receiverType.value === 'covenant') {
      this.getCovenantSearch();
    }
  }

  getCovenantSearch() {
    this.covenantService.getCovenantSearch(this.billsToReceiveForm.controls.searcher.value).subscribe((data) => {
      if (data["results"].length > 0 && data["results"][0]) {
        this.billsToReceiveForm.get('covenant').setValue(data["results"][0])
        this.billsToReceiveForm.get('client').setValue('')
        this.billsToReceiveForm.get('receiverName').setValue(data["results"][0].name)
      } else {
        this.billsToReceiveForm.get('covenant').setValue('')
        this.billsToReceiveForm.get('client').setValue('')
        this.billsToReceiveForm.get('receiverName').setValue('')
      }
    })
  }

  getClientSearch() {
    this.clientService.getClientsSearch(this.billsToReceiveForm.controls.searcher.value).subscribe((data) => {
      if (data["results"].length > 0 && data["results"][0]) {
        this.billsToReceiveForm.get('covenant').setValue('')
        this.billsToReceiveForm.get('client').setValue(data["results"][0])
        this.billsToReceiveForm.get('receiverName').setValue(data["results"][0].name)
      } else {
        this.billsToReceiveForm.get('covenant').setValue('')
        this.billsToReceiveForm.get('client').setValue('')
        this.billsToReceiveForm.get('receiverName').setValue('')
      }
    });
  }

  onSubmit() {
    this.messageError = '';

    if (this.billsToReceiveForm.valid) {
      const billsToReceive = this.billsToReceiveForm.getRawValue();

      const billsToReceiveToSubmit :any = {
        paymentMethod: billsToReceive.paymentMethod,
        paymentType: billsToReceive.paymentType,
        receiverType: billsToReceive.receiverType,
        client: billsToReceive.client || null,
        covenant: billsToReceive.covenant || null,
        bankAccount: Number(billsToReceive.bankAccount) ? {
          id: Number(billsToReceive.bankAccount)
        } : null,
        costCenter: Number(billsToReceive.costCenter) ? {
          id: Number(billsToReceive.costCenter)
        } : null,
        bank: Number(billsToReceive.bank) ? {
          id: Number(billsToReceive.bank)
        } : null,
        invoiceNumber: billsToReceive.invoiceNumber,
        value: billsToReceive.value.replace(/[^0-9]/g, '') / 100,
        dueDate: billsToReceive.dueDate,
        paid: billsToReceive.paid,
        parcAmount: Number(billsToReceive.parcAmount),
        frequency: billsToReceive.frequency,
        bankCheckNumber: billsToReceive.bankCheckNumber,
        parcels: billsToReceive.parcels,
      }

      this.loading = true;
      this.service.save(billsToReceiveToSubmit).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Salvo',
            'Conta a receber salva com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/bills-to-receive']);
            }
          });
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Conta a receber!',
            'error'
          );
        }
      );
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      this.messageError += `${erro.message}\n`;
    });
  }
}
