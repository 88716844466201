<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <header class="mt-4 ml-4 mr-4">
              <div class="row page-title">
                <label>Dados da Solicitação</label>
              </div>
              <div class="row">
                <div class="col-sm-6 mt-1">
                  <label>Data de Início</label>
                  <input type="date" class="form-control" formControlName="startDate" />
                </div>
                <div class="col-sm-6 mt-1">
                  <label>Data Fim</label>
                  <input type="date" class="form-control" formControlName="endDate" />
                  <span *ngIf="
                      tripForm.controls.endDate.errors &&
                      tripForm.controls.endDate.errors.required
                    " class="text-danger position-right">{{ tripForm.controls.endDate.errors.required }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 mt-1">
                  <label>Hora início</label>
                  <input type="time" class="form-control" placeholder="Hora início" formControlName="starttime" />
                  <span *ngIf="
                      tripForm.controls.starttime.errors &&
                      tripForm.controls.starttime.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                </div>
                <div class="col-sm-6 mt-1">
                  <label>Hora Fim</label>
                  <input type="time" class="form-control" placeholder="Hora fim" formControlName="endtime" />
                  <span *ngIf="
                      tripForm.controls.endtime.errors &&
                      tripForm.controls.endtime.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                </div>
              </div>
              <div class="row page-title mt-3">
                <label>Endereços</label>
              </div>
              <div class="container">
                <label class="mt-3 mb-4" style="font-size: 20px">Passageiros</label>
                <div class="row">
                  <div class="col-sm-9">
                    <select class="custom-select custom-select" style="font-size: 14px" formControlName="collaborator">
                      <option value="">Colaboradores</option>
                      <option value="{{ collaborator.id }}" *ngFor="let collaborator of collaborators">
                        {{ collaborator.name }}
                      </option>
                    </select>
                    <span *ngIf="tripForm.controls.passengers.value.length == 0" class="text-danger position-right">*
                      Adicione ao menos um Passageiro</span>
                  </div>
                  <div class="col-sm-3">
                    <button type="button" class="btn btn-success" (click)="onAddCollaborator()">
                      Adicionar
                    </button>
                  </div>
                </div>
                <div class="table-responsive-md mt-3">
                  <table class="table table-striped custom-table table-hover" formArrayName="passengers">
                    <thead>
                      <tr>
                        <th>Colaborador</th>
                        <th>Telefone</th>
                        <th>Whatsapp</th>
                        <th>Centro de Custo</th>
                        <th>Principal</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="
                        let passenger of passengers.controls;
                        let i = index
                      " [formGroupName]="i">
                      <tr>
                        <td>
                          {{ passenger.controls.name.value }}
                        </td>
                        <td>
                          {{ passenger.controls.phone.value }}
                        </td>
                        <td>
                          {{ passenger.controls.whatsapp.value }}
                        </td>
                        <td>
                          {{ passenger.controls.costCenter.value.name }}
                        </td>
                        <td>
                          <input type="checkbox" formControlName="main" />
                        </td>
                        <td>
                          <div class="row">
                            <a class="btn btn-primary" data-toggle="collapse" href="#collapseExample{{ i }}"
                              role="button" aria-expanded="false" aria-controls="collapseExample">
                              Endereço
                            </a>
                            <button (click)="removeCollaborator(i)" class="btn btn-danger btn-xs">
                              <i class="fa fa-trash-o"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr class="collapse" id="collapseExample{{ i }}">
                        <td colspan="6" class="hiddenRow">
                          <div class="card card-body">
                            <div class="row mt-2 mb-3">
                              <div class="col-sm-1">
                                <input type="checkbox" />
                              </div>
                              <div class="col-sm-5">
                                <label for="">Buscar no Endereço do Convênio?</label>
                              </div>
                              <div class="col-sm-1">
                                <input type="checkbox" />
                              </div>
                              <div class="col-sm-5">
                                <label for="">Buscar em outro Endereço?</label>
                              </div>
                            </div>
                            <div formGroupName="address">
                              <div class="row">
                                <div class="col-sm-4 mt-1">
                                  <label>CEP</label>
                                  <input type="text" class="form-control cep" placeholder="CEP"
                                    (blur)="onBlurCepCollaborator($event, i)" formControlName="zipcode" />
                                  <!-- <span
                                                                            *ngIf="tripForm.controls.originAddress.get('zipcode').errors && tripForm.controls.originAddress.get('zipcode').errors.invalid"
                                                                            class="text-danger position-right">*
                                                                            {{tripForm.controls.originAddress.get('zipcode').errors.invalid}}
                                                                        </span> -->
                                </div>
                                <div class="col-sm-8 mt-1">
                                  <label>Logradouro</label>
                                  <input type="text" class="form-control" placeholder="Logradouro"
                                    formControlName="neighborhood" />
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-4">
                                  <label style="font-size: 14px" class="mt-2">Bairro</label>
                                  <input type="text" class="form-control" placeholder="Bairro"
                                    formControlName="publicPlace" />
                                </div>
                                <div class="col-sm-4">
                                  <label style="font-size: 14px" class="mt-2">Estado</label>
                                  <select (change)="
                                      onSelectedStateCollaborator($event, i)
                                    " class="form-control select2" formControlName="state">
                                    <option value="">Selecione o Estado</option>
                                    <option value="{{ state.id }}" *ngFor="let state of states">
                                      {{ state.name }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-sm-4">
                                  <label style="font-size: 14px" class="mt-2">Cidade</label>
                                  <select class="form-control select2" formControlName="city">
                                    <option value="">Selecione a Cidade</option>
                                    <option value="{{ city.id }}" *ngFor="let city of cities[i]">
                                      {{ city.name }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <label class="mt-3">Destino</label>
                <div formGroupName="destinyAddress">
                  <div class="row">
                    <div class="col-sm-4 mt-1">
                      <label>CEP</label>
                      <input type="text" class="form-control cep" placeholder="CEP" (blur)="onBlurCepDestiny($event)"
                        formControlName="zipcode" />
                      <span *ngIf="
                          tripForm.controls.destinyAddress.value.errors &&
                          tripForm.controls.destinyAddress.value.zipcode
                            .required
                        " class="text-danger position-right">*Campo Obrigatório</span>
                      <span *ngIf="
                          tripForm.controls.destinyAddress.get('zipcode')
                            .errors &&
                          tripForm.controls.destinyAddress.get('zipcode').errors
                            .invalid
                        " class="text-danger position-right">*
                        {{
                        tripForm.controls.destinyAddress.get("zipcode").errors
                        .invalid
                        }}
                      </span>
                    </div>
                    <div class="col-sm-8 mt-1">
                      <label>Logradouro</label>
                      <input type="text" class="form-control" placeholder="Logradouro" formControlName="neighborhood" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <label style="font-size: 14px" class="mt-2">Bairro</label>
                      <input type="text" class="form-control" placeholder="Logradouro" formControlName="publicPlace" />
                    </div>
                    <div class="col-sm-4">
                      <label style="font-size: 14px" class="mt-2">Estado</label>
                      <select (change)="onSelectedStateDestiny($event)" class="form-control select2"
                        formControlName="state">
                        <option value="">Selecione o Estado</option>
                        <option value="{{ state.id }}" *ngFor="let state of states">
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-4">
                      <label style="font-size: 14px" class="mt-2">Cidade</label>
                      <select class="form-control select2" formControlName="city">
                        <option value="">Selecione a Cidade</option>
                        <option value="{{ city.id }}" *ngFor="let city of destinyCities">
                          {{ city.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div class="container mt-3">
              <label>Dados da Corrida</label>
              <div class="row">
                <div class="col-sm-5">
                  <label>Veículo</label>
                  <select class="custom-select custom-select" style="font-size: 14px" (change)="getDriversByCar()"
                    formControlName="car">
                    <option value="">Veículo</option>
                    <option value="{{ car.id }}" *ngFor="let car of cars">
                      {{ car.model.name }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-7">
                  <label>Motorista</label>
                  <select class="custom-select custom-select" style="font-size: 14px" formControlName="driver">
                    <option value="">Motorista</option>
                    <option value="{{ driver.id }}" *ngFor="let driver of drivers">
                      {{ driver.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="container mt-3">
              <label>Financeiro</label>
              <div class="row">
                <div class="col-sm-4">
                  <label>Tipo de Pagamento</label>
                  <select class="custom-select custom-select" formControlName="paymentMethod" style="font-size: 14px">
                    <option value="">Pagamento</option>
                    <option value="{{ paymentMethod.id }}" *ngFor="let paymentMethod of paymentMethods">
                      {{ paymentMethod.name }}
                    </option>
                  </select>
                  <span *ngIf="
                      tripForm.controls.paymentMethod.errors &&
                      tripForm.controls.paymentMethod.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                </div>
                <div class="col-sm-4">
                  <label>Centro de custo</label>
                  <select class="custom-select custom-select" style="font-size: 14px" formControlName="costcenter">
                    <option value="">Centro de Custo</option>
                    <option value="{{ costCenter.id }}" *ngFor="let costCenter of costCenters">
                      {{ costCenter.name }}
                    </option>
                  </select>
                  <span *ngIf="
                      tripForm.controls.costcenter.errors &&
                      tripForm.controls.costcenter.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                </div>
                <div class="col-sm-4">
                  <label>Valor da Corrida</label>
                  <select class="custom-select custom-select" style="font-size: 14px"
                    formControlName="valueSolicitation">
                    <option value="">Valor da Corrida</option>
                    <option value="{{ requestValue.id }}" *ngFor="let requestValue of requestValues">
                      {{ requestValue.destiny }} -
                      {{ requestValue.value | currency: "BRL" }}
                    </option>
                  </select>
                  <span *ngIf="
                      tripForm.controls.valueSolicitation.errors &&
                      tripForm.controls.valueSolicitation.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                </div>
                <div class="col-sm-4" *ngIf="tripForm.controls.passenger.value == 'client'">
                  <label>Valor</label>
                  <input type="text" class="form-control" placeholder="Valor da Corrida" formControlName="cost" />
                  <span *ngIf="
                      tripForm.controls.cost.errors &&
                      tripForm.controls.cost.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                </div>
              </div>
            </div>
            <div class="row-md-12">
              <div class="col">
                <label for="exampleFormControlTextarea1">Observação</label>
                <textarea class="form-control" formControlName="observation" rows="5"></textarea>
              </div>
            </div>
            <div class="row mt-3 ml-3 mb-3">
              <div class="col-md-3"></div>
              <div class="col-md-3 text-right">
                <button class="btn btn-success">Salvar</button>
              </div>
              <div class="col-md-3">
                <a href="/convenio" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>