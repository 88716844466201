import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankAccount } from 'src/app/domain/bank-account';
import { BankAccountService } from 'src/app/service/bank-account/bank-account.service';

@Component({
  selector: 'app-account-bank-view',
  templateUrl: './account-bank-view.component.html',
  styleUrls: ['./account-bank-view.component.css']
})
export class AccountBankViewComponent implements OnInit {
  id: number;
  bankAccount: BankAccount;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bankAccountService: BankAccountService,) { 
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.getAccountBank()
  }

  getAccountBank() {
    this.bankAccountService.getBankAccount(this.id).subscribe(data => {
      this.bankAccount = data;
    })
  }

}
