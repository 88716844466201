import { CovenantUser } from '../coventant-user';


export class CovenantUserDTO {

    name: string;
    role: string;
    email: string;
    active: boolean;


    constructor(coventantUser: CovenantUser) {
        this.name = coventantUser.name;
        this.role = coventantUser.role;
        this.email = coventantUser.email;
        this.active = coventantUser.active;
    }
}