import { CarFine } from '../car-fine';
import { Driver } from '../driver';

export class CarFineDTO {
  id: number;
  driver: Driver;
  value: number;
  discountDriver: boolean;
  discounted: boolean;
  type: string;
  date: Date;
  active: boolean;

  constructor(carFine: CarFine) {
    this.id = carFine.id;
    this.date = carFine.date;
    this.type = carFine.type;
    this.driver = carFine.driver;
    this.discountDriver = carFine.discountDriver;
    this.discounted = carFine.discounted;
    this.value = carFine.value;
    this.active = carFine.active;
  }
}
