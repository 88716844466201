import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/domain/permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  url: string = `${environment.BASE_URL}/permissions`;

  constructor(private http: HttpClient) { }

  getPermissions() {
    return this.http.get<Array<Permission>>(this.url,
      { responseType: "json" });

  }
}
