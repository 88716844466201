<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Gerar Contas a Receber</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div
              class="card-head"
              [formGroup]="generateBillToReceiveSearchForm"
            >
              <header style="width: 100%">
                <div class="row">
                  <div class="col-12 col-md-4 col-lg-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Data de início</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      formControlName="startDate"
                    />
                    <span
                      *ngIf="
                        generateBillToReceiveSearchForm.controls.startDate
                          .errors &&
                        generateBillToReceiveSearchForm.controls.startDate
                          .errors.required
                      "
                      class="text-danger position-right h6 mt-4"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        generateBillToReceiveSearchForm.controls.startDate
                          .errors &&
                        generateBillToReceiveSearchForm.controls.startDate
                          .errors.invalid
                      "
                      class="text-danger position-right h6 mt-4"
                      >*
                      {{
                        generateBillToReceiveSearchForm.controls.startDate
                          .errors.invalid
                      }}
                    </span>
                  </div>
                  <div class="col-12 col-md-4 col-lg-3">
                    <label style="font-size: 14px" class="mt-2">Data fim</label>
                    <input
                      type="date"
                      class="form-control"
                      formControlName="endDate"
                    />
                    <span
                      *ngIf="
                        generateBillToReceiveSearchForm.controls.endDate
                          .errors &&
                        generateBillToReceiveSearchForm.controls.endDate.errors
                          .required
                      "
                      class="text-danger position-right h6 mt-4"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        generateBillToReceiveSearchForm.controls.endDate
                          .errors &&
                        generateBillToReceiveSearchForm.controls.endDate.errors
                          .invalid
                      "
                      class="text-danger position-right h6 mt-4"
                      >*
                      {{
                        generateBillToReceiveSearchForm.controls.endDate.errors
                          .invalid
                      }}
                    </span>
                  </div>
                  <div class="col-12 col-md-4 col-lg-6">
                    <label style="font-size: 14px" class="mt-2"
                      >Pesquisar</label
                    >
                    <ng-select
                      class="custom-select custom-select"
                      style="font-size: 14px"
                      formControlName="searchClientCovenant"
                      [items]="covenants"
                      bindLabel="name"
                      bindValue="id"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                        let-search="searchTerm"
                      >
                        <span>{{ item.name }}</span>
                      </ng-template>
                    </ng-select>
                    <span
                      *ngIf="
                        generateBillToReceiveSearchForm.controls
                          .searchClientCovenant.errors &&
                        generateBillToReceiveSearchForm.controls
                          .searchClientCovenant.errors.required
                      "
                      class="text-danger position-right h6 mt-4"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        generateBillToReceiveSearchForm.controls
                          .searchClientCovenant.errors &&
                        generateBillToReceiveSearchForm.controls
                          .searchClientCovenant.errors.invalid
                      "
                      class="text-danger position-right h6 mt-4"
                      >*
                      {{
                        generateBillToReceiveSearchForm.controls
                          .searchClientCovenant.errors.invalid
                      }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-3">
                    <label style="font-size: 14px" class="mt-2"
                      >Centro de Custo</label
                    >
                    <select
                      class="form-control select2"
                      id="cost-center"
                      formControlName="costCenter"
                    >
                      <option value=""></option>
                      <option
                        *ngFor="let costCenter of costCenters"
                        value="{{ costCenter.id }}"
                      >
                        {{ costCenter.name }}
                      </option>
                    </select>
                    <span
                      *ngIf="
                        generateBillToReceiveSearchForm.controls.costCenter
                          .errors &&
                        generateBillToReceiveSearchForm.controls.costCenter
                          .errors.invalid
                      "
                      class="text-danger position-right h6 mt-4"
                      >*
                      {{
                        generateBillToReceiveSearchForm.controls.costCenter
                          .errors.invalid
                      }}
                    </span>
                  </div>
                  <div class="col-sm-1 mt-4">
                    <button class="btn btn-success" (click)="findBills()">
                      Gerar
                    </button>
                  </div>
                </div>
              </header>
            </div>
            <div
              class="card-body"
              [formGroup]="generateBillToReceiveForm"
              *ngIf="runs.length"
            >
              <div class="table-responsive table-mobile">
                <table
                  class="table table-striped custom-table"
                  formArrayName="runs"
                >
                  <thead>
                    <tr>
                      <th>Corrida</th>
                      <th>Data</th>
                      <th>Hora</th>
                      <th>Origem</th>
                      <th>Destino</th>
                      <th>Valor</th>
                      <th>
                        <div class="row">
                          <label>Selecionar</label>
                          <input
                            type="checkbox"
                            class="mt-1 ml-2"
                            (click)="handleSelectAll($event)"
                            id="select-all"
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let run of runs.controls; let i = index"
                      [formGroupName]="i"
                    >
                      <td>{{ run.controls.id.value }}</td>
                      <td>
                        {{ run.controls.date.value | date: "dd/MM/yyyy" }}
                      </td>
                      <td>
                        {{ run.controls.startHour.value }}
                      </td>
                      <td>{{ run.controls.origin.value }}</td>
                      <td>{{ run.controls.destiny.value }}</td>
                      <td>
                        {{ run.controls.value.value | currency: "BRL":true }}
                      </td>
                      <td>
                        <div class="row" aling="center">
                          <input
                            type="checkbox"
                            class="ml-5"
                            formControlName="selected"
                            (click)="handleSingleSelect($event)"
                            (change)="setBillValue()"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-4">
                <div class="col-sm-4">
                  <label style="font-size: 14px" class="mt-2"
                    >Data de Vencimento</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    formControlName="dueDate"
                  />
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.dueDate.errors &&
                      generateBillToReceiveForm.controls.dueDate.errors.required
                    "
                    class="text-danger position-right h6 mt-4"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.dueDate.errors &&
                      generateBillToReceiveForm.controls.dueDate.errors.invalid
                    "
                    class="text-danger position-right h6 mt-4"
                    >*
                    {{
                      generateBillToReceiveForm.controls.dueDate.errors.invalid
                    }}
                  </span>
                </div>
                <div class="col-sm-4">
                  <label style="font-size: 14px" class="mt-2"
                    >Nota Fiscal/Recibo</label
                  >
                  <input
                    placeholder="Nota Fiscal/Recibo"
                    class="form-control"
                    formControlName="invoiceNumber"
                  />
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.invoiceNumber.errors &&
                      generateBillToReceiveForm.controls.invoiceNumber.errors
                        .required
                    "
                    class="text-danger position-right h6 mt-4"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.invoiceNumber.errors &&
                      generateBillToReceiveForm.controls.invoiceNumber.errors
                        .invalid
                    "
                    class="text-danger position-right h6 mt-4"
                    >*
                    {{
                      generateBillToReceiveForm.controls.invoiceNumber.errors
                        .invalid
                    }}
                  </span>
                </div>
                <div class="col-sm-4">
                  <label
                    style="font-size: 14px"
                    class="mt-2"
                    for="paymentMethod"
                    >Tipo de Recebimento</label
                  >
                  <select
                    class="form-control select2"
                    formControlName="paymentMethod"
                    id="paymentMethod"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let item of paymentMethodKeys()"
                      [value]="item"
                    >
                      {{ paymentMethods[item] }}
                    </option>
                  </select>
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.paymentMethod.errors &&
                      generateBillToReceiveForm.controls.paymentMethod.errors
                        .required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                </div>
                <div
                  class="col-sm-4"
                  *ngIf="
                    this.generateBillToReceiveForm.getRawValue()
                      .paymentMethod == 'deposit'
                  "
                >
                  <label for="bank-account">Conta Bancária</label>
                  <select
                    class="form-control select2"
                    id="bank-account"
                    formControlName="bankAccountId"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let bankAccount of bankAccounts"
                      value="{{ bankAccount.id }}"
                    >
                      {{ bankAccount.bankAccount + " - " + bankAccount.agency }}
                    </option>
                  </select>
                </div>
                <!-- <div class="col-sm-4">
                  <label style="font-size: 14px" class="mt-2"
                    >Quantidade de Parcelas</label
                  >
                  <input
                    placeholder="Quantidade de Parcelas"
                    class="form-control"
                    formControlName="parcAmount"
                    maxlength="5"
                    (keypress)="onlyNumber($event)"
                  />
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.parcAmount.errors &&
                      generateBillToReceiveForm.controls.parcAmount.errors
                        .required
                    "
                    class="text-danger position-right h6 mt-4"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.parcAmount.errors &&
                      generateBillToReceiveForm.controls.parcAmount.errors
                        .invalid
                    "
                    class="text-danger position-right h6 mt-4"
                    >*
                    {{
                      generateBillToReceiveForm.controls.parcAmount.errors
                        .invalid
                    }}
                  </span>
                </div> -->
                <div class="col-sm-4">
                  <label style="font-size: 14px" class="mt-2">Valor</label>
                  <input
                    placeholder="Valor"
                    class="form-control"
                    [value]="
                      generateBillToReceiveForm.controls.value.value
                        | currency: 'BRL'
                    "
                    disabled
                  />
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.value.errors &&
                      generateBillToReceiveForm.controls.value.errors.required
                    "
                    class="text-danger position-right h6 mt-4"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.value.errors &&
                      generateBillToReceiveForm.controls.value.errors.invalid
                    "
                    class="text-danger position-right h6 mt-4"
                    >*
                    {{
                      generateBillToReceiveForm.controls.value.errors.invalid
                    }}
                  </span>
                </div>
                <div class="col-sm-4">
                  <label style="font-size: 14px" class="mt-2"
                    >Valor Líquido</label
                  >
                  <input
                    placeholder="Valor"
                    class="form-control"
                    formControlName="paidValue"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ',',
                      align: 'left',
                      allowNegative: false
                    }"
                  />
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.paidValue.errors &&
                      generateBillToReceiveForm.controls.paidValue.errors
                        .required
                    "
                    class="text-danger position-right h6 mt-4"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.paidValue.errors &&
                      generateBillToReceiveForm.controls.paidValue.errors
                        .invalid
                    "
                    class="text-danger position-right h6 mt-4"
                    >*
                    {{
                      generateBillToReceiveForm.controls.paidValue.errors
                        .invalid
                    }}
                  </span>
                </div>
                <div class="col-sm-4">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="paid"
                      formControlName="paid"
                    />
                    <label class="form-check-label" for="paid">Pago</label>
                  </div>
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.paid.errors &&
                      generateBillToReceiveForm.controls.paid.errors.required
                    "
                    class="text-danger position-right h6 mt-4"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      generateBillToReceiveForm.controls.paid.errors &&
                      generateBillToReceiveForm.controls.paid.errors.invalid
                    "
                    class="text-danger position-right h6 mt-4"
                    >*
                    {{ generateBillToReceiveForm.controls.paid.errors.invalid }}
                  </span>
                </div>
              </div>
              <div
                class="row mt-4"
                *ngIf="generatedParcels?.length && generatedParcels.length > 1"
              >
                <div class="col-4">
                  <label for="name">Banco</label>
                  <select class="form-control select2" formControlName="bank">
                    <option value=""></option>
                    <option *ngFor="let bank of banks" value="{{ bank.id }}">
                      {{ bank.name }}
                    </option>
                  </select>
                </div>
                <div class="col-4">
                  <label for="name">Periodicidade</label>
                  <select class="form-control" formControlName="frequency">
                    <option></option>
                    <option value="days">Dias</option>
                    <option value="weeks">Semanas</option>
                    <option value="fortnights">Quinzenas</option>
                    <option value="months">Meses</option>
                    <option value="bimonthly">Bimestres</option>
                    <option value="quarters">Trimestres</option>
                    <option value="semesters">Semestres</option>
                    <option value="years">Anos</option>
                  </select>
                </div>
              </div>
              <div
                class="mt-4"
                *ngIf="
                  generatedParcels?.length &&
                  generatedParcels.length > 1 &&
                  generateBillToReceiveForm.get('frequency')
                "
              >
                <table
                  class="table table-bordered mt-5"
                  formArrayName="generatedParcels"
                >
                  <thead>
                    <tr>
                      <th scope="col">Nº da Parcela</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Data Vencimento</th>
                      <th scope="col">Pago</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let generatedParcel of generatedParcels.controls;
                        let i = index
                      "
                      [formGroupName]="i"
                    >
                      <td>{{ generatedParcel.controls.number.value }}</td>
                      <td>
                        {{
                          generatedParcel.controls.value.value | currency: "BRL"
                        }}
                      </td>
                      <td>
                        {{
                          generatedParcel.controls.dueDate.value
                            | date: "dd/MM/yyyy"
                        }}
                      </td>
                      <td>
                        <input type="checkbox" formControlName="paid" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <div class="row">
                  <div class="col-md-12 ml-5 mt-3">
                    <span
                      *ngIf="!runs.length"
                      class="label label-danger label-mini"
                      >Nenhuma Conta a Receber Gerada.</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 ml-3 mb-3">
        <div class="col-12 d-flex justify-content-center">
          <div class="m-2">
            <button
              (click)="onSubmit()"
              [disabled]="!generateBillToReceiveForm.valid"
              class="btn btn-success"
            >
              Salvar
            </button>
          </div>
          <div class="m-2">
            <a routerLink="/financial/generate-bills-to-receive" class="btn btn-danger"
              >Cancelar</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
