<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Visualizar Cliente</div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-topline-green">
        <div class="row col-md-12">
          <div class="row col-md-12">
            <div class="col-md-4 mt-3">
              <div class="card-head" *ngIf="client.imageUrl">
                <img class="client__image" [src]="client.imageUrl" />
              </div>
            </div>
            <div class="col-md-8 mt-3">
              <div class="row col-md-12">
                <div class="col-md-4">
                  <b>Nome</b>
                  <br />
                  {{ client.name }}
                </div>
                <div class="col-md-4">
                  <b>CPF/CNPJ</b>
                  <br />
                  {{ client.cpfCnpj | mask: "CPF_CNPJ" }}
                </div>
                <div class="col-md-4">
                  <b>Status</b>
                  <br />
                  <span *ngIf="client.active" class="label label-sucess label-mini">Ativo</span>
                  <span *ngIf="!client.active" class="label label-danger label-mini">Inativo</span>
                </div>
              </div>
              <div class="row col-md-12 mt-3">
                <div class="col-md-4">
                  <b>Data de Nascimento</b>
                  <br />
                  <span> {{ client.birthDate | date: 'dd/MM/yyyy' }}</span>
                </div>
                <div class="col-md-4">
                  <b>Telefone</b>
                  <br />
                  {{ client.phone | mask: (client.phone.length == 10 ? "(00) 0000-0000" : "(00) 0 0000-0000") }}
                </div>
                <div class="col-md-4">
                  <b>Whatsapp</b>
                  <br />
                  {{ client.whatsapp | mask: "+00 00 0 0000-0000" }}
                </div>
              </div>

              <div class="row col-md-12 mt-3">
                <div class="col-md-6">
                  <b>E-mail</b>
                  <br />
                  {{ client.email }}
                </div>
                <div class="col-md-6">
                  <b>Formas de Pagamento</b>
                  <br />
                  <div class="col-md-12" *ngFor="let pm of client.paymentMethods">
                    {{ pm.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row col-md-12 ml-0">
            <div class="col-md-4 mt-3">
              <b>CEP</b>
              <br />
              {{ client.address.zipCode }}
            </div>
            <div class="col-md-8 mt-3">
              <b>Logradouro</b>
              <br />
              {{ client.address.publicPlace }}
            </div>
          </div>

          <div class="row col-md-12 ml-0">
            <div class="col-md-4 mt-3">
              <b>Bairro</b>
              <br />
              {{ client.address.neighborhood }}
            </div>
            <div class="col-md-4 mt-3">
              <b>Cidade</b>
              <br />
              {{ client.address.city.name }}
            </div>
            <div class="col-md-4 mt-3">
              <b>Estado</b>
              <br />
              {{ client.address.city.state.initials }}
            </div>
          </div>

          <div class="row col-12 m-0 my-3 d-flex justify-content-center">
            <div class="m-2">
              <a routerLink="/maintenance/client/edit/{{ client.id }}"><button class="btn btn-success" onclick="">Editar</button></a>
              <!-- <div class="col-md-3">
                                <a routerLink="/maintenance/client/edit/1" class="btn btn-alert">editar</a>
                            </div> -->
            </div>

            <div class="m-2">
              <a routerLink="/maintenance/client" class="btn btn-danger">Cancelar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
