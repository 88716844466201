<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title">Colaboradores da Corrida</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-topline-green">
                        <div class="card-body ">
                            <div class="table-responsive">
                                <table class="table table-striped custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Cargo</th>
                                            <th>CEP</th>
                                            <th>Logradouro</th>
                                            <th>Bairro</th>
                                            <th>Cidade</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Carla Cristina
                                            </td>
                                            <td>
                                                Gerente Administrativa
                                            </td>
                                            <td>
                                                75.103-123
                                            </td>
                                            <td>
                                                Av. Comercial
                                            </td>
                                            <td>
                                                Bairro de Lourdes
                                            </td>
                                            <td>
                                                Anápolis
                                            </td>
                                            <td>
                                                Goiás
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mb-2">
                        <a routerLink="/process/trip-request" class="btn btn-danger">Voltar</a>
                    </div>
                </div>
                <div class="col-md-12">Mostrando página 1 de 1 dos
                    1 Resultados
                </div>
            </div>
        </div>
    </div>
</div>
