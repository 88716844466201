import { Provider } from '../provider';
import { AddressDTO } from './address.dto';


export class ProviderDTO {

   id: number;
   name: string;
   cpfCnpj: string;
   stateRegistration: string;
   municipalRegistration: string
   phone: string;
   whatsapp: string;
   contact: string;
   email: string;
   address: AddressDTO;
   active: boolean;


   constructor(provider: Provider) {
      this.id = provider.id;
      this.name = provider.name;
      this.cpfCnpj = provider.cpfCnpj;
      this.stateRegistration = provider.stateRegistration;
      this.municipalRegistration = provider.municipalRegistration;
      this.phone = provider.phone;
      this.whatsapp = provider.whatsapp;
      this.contact = provider.contact;
      this.email = provider.email;
      this.active = provider.active;
      this.address = new AddressDTO(provider.address);
   }
}