import { CovenantRequestValue } from 'src/app/domain/covenant-request-value';
import { Address } from './address';
import { Collaborator } from './collaborator';
import { PaymentMethod } from './paymentMethod';

export class Covenant {
  id: number;
  name: string;
  cpfCnpj: string;
  stateRegistration: string;
  municipalRegistration: string;
  phone: string;
  whatsapp: string;
  contact: string;
  emailContact: string;
  email: string;
  address: Address;
  covenantPaymentMethods: Array<PaymentMethod>;
  active: boolean;
  invoice: boolean;
  shareRun: number;
  imageUrl: string;
  covenantRequestValues: Array<CovenantRequestValue>;
  collaborators: Collaborator[];

  constructor() {}
}
