import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Bank } from 'src/app/domain/bank';
import { BankAccount } from 'src/app/domain/bank-account';
import { BillsToReceive } from 'src/app/domain/bills-to-receive';
import { Client } from 'src/app/domain/client';
import { CostCenter } from 'src/app/domain/cost-center';
import { Covenant } from 'src/app/domain/covenant';
import { Run } from 'src/app/domain/run';
import { PaymentmethodEnum } from 'src/app/enumerations/payment-method.enum';
import { BankAccountService } from 'src/app/service/bank-account/bank-account.service';
import { BankService } from 'src/app/service/bank/bank.service';
import { ClientService } from 'src/app/service/client/client.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { GenerateBillsToReceiveService } from 'src/app/service/generate-bills-to-receive/generate-bills-to-receive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generate-bills-to-receive-create',
  templateUrl: './generate-bills-to-receive-create.component.html',
  styleUrls: ['./generate-bills-to-receive-create.component.css'],
})
export class GenerateBillsToReceiveCreateComponent implements OnInit {
  costCenters: Array<CostCenter> = [];
  bankAccounts: Array<BankAccount> = [];
  billsToReceives: Array<BillsToReceive> = [];
  banks: Array<Bank> = [];
  clients: Array<Client> = [];
  covenants: Array<Covenant> = [];
  loading: boolean = true;
  billsToReceiveForms;
  generateBillToReceiveSearchForm: FormGroup;
  generateBillToReceiveForm: FormGroup;
  messageError: string = '';

  paymentMethods = PaymentmethodEnum;
  paymentMethodKeys(): Array<string> {
    var keys = Object.keys(this.paymentMethods);
    return keys;
  }

  constructor(
    private service: GenerateBillsToReceiveService,
    private covenantService: CovenantService,
    private clientService: ClientService,
    private costCenterService: CostCenterService,
    private bankAccountService: BankAccountService,
    private bankService: BankService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getCostCenters();
    this.getBanks();
    this.getBankAccounts();
    this.getClients();
    this.getCovenants();
  }

  createForm() {
    this.generateBillToReceiveSearchForm = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      searchClientCovenant: ['', [Validators.required]],
      costCenter: [''],
    });

    this.generateBillToReceiveForm = this.fb.group({
      runs: this.fb.array([]),
      dueDate: ['', [Validators.required]],
      invoiceNumber: ['', [Validators.required, Validators.maxLength(20)]],
      parcAmount: [''],
      value: [''],
      paidValue: ['', [Validators.required]],
      bank: [''],
      bankAccountId: [''],
      frequency: [''],
      paid: [false, [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      generatedParcels: this.fb.array([]),
    });

    this.generateBillToReceiveForm
      .get('parcAmount')
      .valueChanges.subscribe((val) => this.changeParcAmount(val));

    this.generateBillToReceiveForm
      .get('dueDate')
      .valueChanges.subscribe((val) => this.changeDueDate(val));

    this.generateBillToReceiveForm
      .get('frequency')
      .valueChanges.subscribe((val) => this.changeFrequency(val));
  }

  changeFrequency(value: string) {
    if (!value) return;

    this.generatedParcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  changeDueDate(value: string) {
    if (!value) return;

    this.generatedParcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  get runs() {
    return this.generateBillToReceiveForm.get('runs') as FormArray;
  }

  get generatedParcels() {
    return this.generateBillToReceiveForm.get('generatedParcels') as FormArray;
  }

  setRuns(item: Run) {
    let originAddress,
      destinyAddress = '';

    item.runPassengers.forEach(({ isMain, origin, destiny }) => {
      if (isMain && origin) {
        originAddress = origin.city.name;
        destinyAddress = destiny.city.name;
      }
    });

    this.runs.push(
      this.fb.group({
        id: [item.id],
        date: [item.date],
        startHour: [item.startHour],
        origin: [originAddress],
        destiny: [destinyAddress],
        value: [item.runEndValue],
        selected: [false],
      })
    );
  }

  setBillValue() {
    let totalValue = 0;
    this.runs.value.forEach((run) => {
      if (run.selected) totalValue += +run.value;
    });
    this.generateBillToReceiveForm.controls.value.setValue(totalValue);
  }

  getCostCenters() {
    this.costCenterService.getAllCostCenters().subscribe(
      (data) => {
        this.costCenters = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro',
          'Não foi possível Buscar os centros de custo!',
          'error'
        );
      }
    );
  }

  getBanks() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );
  }

  getBankAccounts() {
    this.bankAccountService.getAllActiveBanckAccounts().subscribe(
      (data) => {
        this.bankAccounts = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );
  }

  getClients() {
    this.clientService.getAllActiveClients().subscribe(
      (data) => {
        this.clients = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os clientes!', 'error');
      }
    );
  }

  getCovenants() {
    this.covenantService.getAllActiveCovenants().subscribe(
      (data) => {
        this.covenants = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os convenios!', 'error');
      }
    );
  }

  findBills() {
    if (!this.generateBillToReceiveSearchForm.valid) {
      return;
    }

    this.loading = true;

    this.runs.clear();
    this.service
      .getBillsToReceive(this.generateBillToReceiveSearchForm.getRawValue())
      .subscribe(
        (data) => {
          this.loading = false;
          data.forEach((item) => {
            this.setRuns(item);
          });
          this.setBillValue();
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
        }
      );
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'startDate':
          this.generateBillToReceiveSearchForm.controls.startDate.setErrors({
            invalid: erro.message,
          });
          break;
        case 'endDate':
          this.generateBillToReceiveSearchForm.controls.endDate.setErrors({
            invalid: erro.message,
          });
          break;
        case 'searchClientCovenant':
          this.generateBillToReceiveSearchForm.controls.searchClientCovenant.setErrors(
            { invalid: erro.message }
          );
          break;
        case 'costCenterId':
          this.generateBillToReceiveSearchForm.controls.costCenter.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  parcelDueDate(index) {
    const { frequency, dueDate } = this.generateBillToReceiveForm.getRawValue();
    if (!frequency || !dueDate) return null;

    const date = new Date(dueDate.replace('-', '/'));

    const iteration = index + 1;

    switch (frequency) {
      case 'days':
        date.setDate(date.getDate() + 1 * iteration);
        break;
      case 'weeks':
        date.setDate(date.getDate() + 7 * iteration);
        break;
      case 'fortnights':
        date.setDate(date.getDate() + 15 * iteration);
        break;
      case 'months':
        date.setMonth(date.getMonth() + 1 * iteration);
        break;
      case 'bimonthly':
        date.setMonth(date.getMonth() + 2 * iteration);
        break;
      case 'quarters':
        date.setMonth(date.getMonth() + 3 * iteration);
        break;
      case 'semesters':
        date.setMonth(date.getMonth() + 6 * iteration);
        break;
      case 'years':
        date.setFullYear(date.getFullYear() + 1 * iteration);
        break;
      default:
        break;
    }

    return this.formatDate(date);
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  parcelValue() {
    const { value, parcAmount } = this.generateBillToReceiveForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value / parcAmount;
  }

  changeParcAmount(val: string) {
    const parcAmount = parseInt(val);

    if (isNaN(parcAmount) || parcAmount <= 1) {
      this.generatedParcels.clear();
      return;
    }

    const currentLength = this.generatedParcels.length;

    if (currentLength > parcAmount) {
      for (let i = currentLength + 1; i >= parcAmount; i--) {
        this.generatedParcels.removeAt(i);
      }
    } else if (currentLength < parcAmount) {
      for (let i = currentLength + 1; i <= parcAmount; i++) {
        const dueDate = this.parcelDueDate(i - 1) || '';

        const paid = this.generateBillToReceiveForm.get('paid').value;

        this.generatedParcels.push(
          this.fb.group({
            number: [i],
            value: [this.parcelValue()],
            paid: [paid],
            dueDate: [dueDate],
          })
        );
      }
    }

    this.setParcValue();
  }

  setParcValue() {
    const value = this.parcelValue();
    this.generatedParcels.controls.forEach((item) => {
      item.get('value').setValue(value);
    });
  }

  onlyNumber(event: any): void {
    event.key.match(/[^0-9]/) && event.preventDefault();
  }

  handleSelectAll(event) {
    this.runs.controls.forEach((item, index) => {
      item.get('selected').setValue(event.target.checked);
    });
    this.setBillValue();
  }

  handleSingleSelect(event) {
    if (!event.target.value) {
      document.getElementById('select-all')['checked'] = false;
    }
  }

  onSubmit() {
    const generatedBillToPay = this.generateBillToReceiveForm.getRawValue();
    const { startDate, endDate } =
      this.generateBillToReceiveSearchForm.getRawValue();

    generatedBillToPay.runs = generatedBillToPay.runs.filter(
      (item) => item.selected
    );
    generatedBillToPay.startDate = startDate;
    generatedBillToPay.endDate = endDate;

    const { searchClientCovenant: covenantId, costCenter: costCenterId } =
      this.generateBillToReceiveSearchForm.getRawValue();

    // let clientId, covenantId = null;

    // const item = searchClientCovenant.split(' ')

    // if (item[0] === 'client') {
    //   clientId = item[1]
    // } else if (item[0] === 'covenant') {
    //   covenantId = item[1]
    // }

    //    generatedBillToPay.clientId = clientId || null
    generatedBillToPay.covenantId = covenantId || null;
    generatedBillToPay.costCenterId = costCenterId || null;

    if (!generatedBillToPay.runs.length) {
      Swal.fire(
        'Erro ao salvar',
        'É necessário selecionar ao menos uma corrida',
        'error'
      );
      return;
    }

    this.loading = true;
    this.service.save(generatedBillToPay).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire(
          'Salvo',
          'Conta a Receber salvo com sucesso!!',
          'success'
        ).then((result) => {
          if (result.value) {
            this.router.navigate(['financial/generate-bills-to-receive']);
          }
        });
        this.createForm();
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao salvar',
          'Não foi possível salvar Conta a receber!',
          'error'
        );
      }
    );
  }
}
