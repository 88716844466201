import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProviderDTO } from '../../domain/dto/provider.dto';
import { Provider } from '../../domain/provider';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  url: string = `${environment.BASE_URL}/providers`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getProviders(provider: Provider, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${provider.name ?? ''}`,
      cpfCnpj: `${provider.cpfCnpj ?? ''}`,
      contact: `${provider.contact ?? ''}`,
      state: `${provider.address.city.state.id ?? ''}`,
      city: `${provider.address.city.id ?? ''}`,
      active: `${provider.active ?? ''}`,
    };

    console.log(params);

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  getProvidersSelect() {
    return this.http.get(this.url, { responseType: 'json' });
  }

  getAllActiveProviders() {
    return this.http.get<Array<Provider>>(`${this.url}/all-active`, {
      responseType: 'json',
    });
  }

  getProvider(id: number) {
    return this.http.get<Provider>(`${this.url}/${id}`, {
      responseType: 'json',
    });
  }
  save(provider: Provider) {
    let dto = new ProviderDTO(provider);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(provider: Provider) {
    let dto = new ProviderDTO(provider);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${provider.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
