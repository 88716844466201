import { DriverService } from 'src/app/service/driver/driver.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Driver } from 'src/app/domain/driver';
import { Pagination } from 'src/app/domain/pagination';
import { PayDriver } from 'src/app/domain/payDriver';
import Swal from 'sweetalert2';
import { DriverPaymentService } from 'src/app/service/driver-payment/driver-payment.service';
import { DriverPaymentTypeEnum } from 'src/app/enumerations/driver-payment-type.enum';
import moment from 'moment';

@Component({
  selector: 'app-pay-driver',
  templateUrl: './pay-driver.component.html',
  styleUrls: ['./pay-driver.component.css'],
})
export class PayDriverComponent implements OnInit {
  drivers: Array<Driver> = [];
  payDrivers: Array<PayDriver> = [];

  payDriverSearch: PayDriver = new PayDriver();

  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;

  constructor(
    private driverPaymentService: DriverPaymentService,
    private driverService: DriverService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.payDriverSearch.driver = new Driver();
      this.payDriverSearch.driver.id = params.driverId ?? null;
      this.payDriverSearch.paymentType = params.paymentType ?? '';
      this.payDriverSearch.initialDate = params.initialDate ?? '';
      this.payDriverSearch.finalDate = params.finalDate || null;
      this.payDriverSearch.paid = params.paid || '';
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getDriverPayments();
    this.getDrivers();
  }

  getDriverPayments() {
    this.loading = true;

    const { driverId, paymentType, paid, initialDate, finalDate } =
      this.searchForm.value;

    this.driverPaymentService
      .getDriverPayments(
        initialDate,
        finalDate,
        driverId,
        paymentType,
        paid,
        this.pagination
      )
      .subscribe(
        (res) => {
          this.payDrivers = res['results'];
          this.payDrivers.forEach(
            (item) =>
              (item.paymentType = DriverPaymentTypeEnum[item.paymentType])
          );
          this.setPagination(res);
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
  }

  getDrivers() {
    this.loading = true;
    this.driverService.getAllDrivers().subscribe(
      (data) => {
        this.drivers = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      driverId: [this.payDriverSearch.driver.id],
      initialDate: [this.payDriverSearch.initialDate],
      finalDate: [this.payDriverSearch.finalDate],
      paymentType: [this.payDriverSearch.paymentType],
      paid: [this.payDriverSearch.paid],
    });
  }

  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  onSearch() {
    this.payDriverSearch.driver = new Driver();

    this.payDriverSearch.driver.id = this.searchForm.controls.driverId.value;
    this.payDriverSearch.paymentType =
      this.searchForm.controls.paymentType.value;

    this.payDriverSearch.initialDate =
      this.searchForm.controls.initialDate.value;
    this.payDriverSearch.finalDate = this.searchForm.controls.finalDate.value;

    switch (this.searchForm.controls.paid.value) {
      case 'true':
        this.payDriverSearch.paid = true;
        break;
      case 'false':
        this.payDriverSearch.paid = false;
        break;
      default:
        this.payDriverSearch.paid = null;
        break;
    }
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/financial/pay-driver'], {
      queryParams: {
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
        driverId: this.searchForm.controls.driverId.value,
        paymentType: this.searchForm.controls.paymentType.value,
        paid: this.searchForm.controls.paid.value,
        initialDate: this.searchForm.controls.initialDate.value,
        finalDate: this.searchForm.controls.finalDate.value,
      },
    });
    this.getDriverPayments();
  }

  setPaid(id) {
    Swal.fire({
      title: 'Atenção!',
      text: `Definir como pago?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero definir como pago!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.driverPaymentService.setPaidDriverPayment(id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Feito',
              text: `Acerto definido como pago com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao definir acerto como pago!', 'error');
          }
        );
      }
    });
  }

  setUnpaid(id) {
    Swal.fire({
      title: 'Atenção!',
      text: `Definir como não-pago?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero definir como não-pago!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.driverPaymentService.setUnpaidDriverPayment(id).subscribe(
          (data) => {
            Swal.fire({
              title: 'Feito',
              text: `Acerto definido como não-pago com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao definir acerto como não-pago!', 'error');
          }
        );
      }
    });
  }

  exportDriverPayments(type: string) {
    this.loading = true;

    const { initialDate, finalDate, driver, paymentType, paid } =
      this.payDriverSearch;

    this.driverPaymentService
      .exportDriverPayments(
        {
          initialDate,
          finalDate,
          driverId: driver.id,
          paymentType,
          paid,
        },
        type
      )
      .subscribe(
        (response) => {
          const fileName = `Relatorio_Acerto_De_Motorista_${moment().format(
            'DDMMyyyy'
          )}.${type == 'excel' ? '.xlsx' : 'pdf'}`;
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(response.body);
          link.download = fileName;
          link.click();

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
        }
      );
  }
}
