import { Component, OnInit } from '@angular/core';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { RequestValue } from 'src/app/domain/request-value';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { EnumRequestValueType } from 'src/app/enumerations/request-value-type';

@Component({
  selector: 'app-request-value-view',
  templateUrl: './request-value-view.component.html',
  styleUrls: ['./request-value-view.component.css']
})
export class RequestValueViewComponent implements OnInit {
  id: number;
  requestValue: RequestValue = new RequestValue();
  loading: boolean = true;

  constructor(
    private service: RequestValueService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getRequestValues();
  }

  getRequestValues() {
    this.loading = true;
    this.service.getRequestValue(this.id).subscribe(data => {
      this.requestValue = data;
      this.requestValue.type = EnumRequestValueType[data.type];
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar Valor das corridas!', 'error');
  }
}
