import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { WebsocketService } from 'src/app/service/websocket/websocket.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {

  user = '';
  notifications;

  convenant;
  convenantName = '';
  constructor(private service: NotificationService, private wsService: WebsocketService) {
    this.user = localStorage.getItem("user");
    this.convenant = localStorage.getItem('convenant');
  }

  ngOnInit(): void {
    if (this.user == 'Administrador') {
      this.getNotifications();

      this.wsService.getNewNotification().subscribe((notification: any) => {
        this.getNotifications();

        const { title, description } = notification;

        if (title && description)
          Swal.fire(title, description, 'success').finally(() => {
            this.wsService.notification$.next('');
          });
      });

    }

    if (this.user == 'Convênio') {
      this.convenantName = JSON.parse(this.convenant).name
    }

  }

  getNotifications() {
    this.service.getAllNotification().subscribe((response) => {
      this.notifications = response;
    });
  }

}
