import { Component, OnInit } from '@angular/core';
import { Provider } from 'src/app/domain/provider';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { City } from 'src/app/domain/city';
import { Address } from 'src/app/domain/address';
import { State } from 'src/app/domain/state';
import { AddressService } from 'src/app/service/address/address.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {

  providers: Array<Provider> = [];
  states: Array<State> = [];
  cities: Array<City> = [];
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  provider: Provider = new Provider();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;


  constructor(
    private service: ProviderService,
    private serviceAddress: AddressService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {

    this.route.queryParams.subscribe(params => {
      this.provider.address = new Address;
      this.provider.address.city = new City;
      this.provider.address.city.state = new State;

      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
      this.provider.name = params.name || "";
      this.provider.cpfCnpj = params.cpfCnpj || "";
      this.provider.contact = params.contact || "";
      this.provider.active = params.active || null;
      this.provider.address.city.id = params.city || null;
      this.provider.address.city.state.id = params.state || null;
    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getStates();
    this.getProviders();

  }
  getStates() {
    this.serviceAddress.getStates().subscribe(data => {
      this.states = data;
    }, error => { });
  }
  getProviders() {
    this.loading = true;
    this.service.getProviders(this.provider, this.pagination).subscribe(data => {
      this.providers = data['results'];
      this.setPagination(data);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });
    this.searchForm = this.fb.group({
      name: [this.provider.name],
      cpfCnpj: [this.provider.cpfCnpj],
      contact: [this.provider.contact],
      state: [this.provider.address.city.state.id],
      city: [this.provider.address.city.id],
      active: [this.provider.active]
    });
  }

  onSearch() {
    this.provider.name = this.searchForm.controls.name.value;
    this.provider.cpfCnpj = this.searchForm.controls.cpfCnpj.value;
    this.provider.contact = this.searchForm.controls.contact.value;
    this.provider.address.city.state.id = this.searchForm.controls.state.value;
    this.provider.address.city.id = this.searchForm.controls.city.value;
    this.provider.name = this.searchForm.controls.name.value;

    switch (this.searchForm.controls.active.value) {
      case "true":
        this.provider.active = true;
        break;
      case "false":
        this.provider.active = false;
        break;
      default:
        this.provider.active = null;
        break;
    }
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(data => {
        this.cities = data;
      }, error => {
        Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
      });
    }
  }
  onActive(provider: Provider) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Fornecedor? ${provider.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(provider.id).subscribe(data => {
          // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
          Swal.fire({
            title: 'Ativado',
            text: `Fornecedor ativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar Fornecedor!', 'error');
          });
      }

    });
  }
  onDeActive(provider: Provider) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Fornecedor? ${provider.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(provider.id).subscribe(data => {

          Swal.fire({
            title: 'Desativado',
            text: `Fornecedor desativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao desativar Fornecedor!', 'error');
          });
      }

    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();

  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/parameters/provider'], {
      queryParams: {
        name: this.provider.name ?? "",
        cpfCnpj: this.provider.cpfCnpj ?? "",
        contact: this.provider.contact ?? "",
        state: this.provider.address.city.state.id ?? "",
        city: this.provider.address.city.id ?? "",
        active: this.provider.active ?? "",
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10
      }
    });
    this.getProviders();
  }


}
