import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Driver } from 'src/app/domain/driver';
import { Pagination } from 'src/app/domain/pagination';
import { DriverDTO } from 'src/app/domain/dto/driver.dto';
import { TripRequest } from 'src/app/domain/trip-request';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  url: string = `${environment.BASE_URL}/drivers`;
  paymentMethodsUrl: string = `${environment.BASE_URL}/payment-methods`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) { }

  activeAccount(value: { token: string; password: string }) {
    return this.http.put(
      `${this.url}/reset_password/token`,
      JSON.stringify(value),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
  resendEmailSender(email: string) {
    return this.http.post(
      `${this.url}/reset_password`,
      { email },
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
  getDrivers(driver: Driver, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${driver.name ?? ''}`,
      cpf: `${driver.cpf ?? ''}`,
      cnh: `${driver.cnh ?? ''}`,
      state: `${driver.address.city.state.id ?? ''}`,
      city: `${driver.address.city.id ?? ''}`,
      active: `${driver.active ?? ''}`,
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  addVehicleInDriver(id, vehicle) {
    console.log(vehicle);

    const data = {
      vehicle,
    };
    return this.http.post(`${this.url}/${id}/vehicle`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  removeVehicleInDriver(driver, vehicle) {
    return this.http.delete(`${this.url}/${driver}/vehicle/${vehicle}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getDriver(id: number) {
    return this.http.get<Driver>(`${this.url}/${id}`, { responseType: 'json' });
  }

  save(driver: Driver) {
    let dto = new DriverDTO(driver);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  update(driver: Driver) {
    let dto = new DriverDTO(driver);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${driver.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  resendActivationInstruction(id: number) {
    return this.http.put(`${this.url}/${id}/resend-activation-instructions`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getAllDrivers() {
    return this.http.get<Array<Driver>>(`${this.url}/all`);
  }

  getAllDriversBasic() {
    return this.http.get<Array<Driver>>(`${this.url}/all/basic`);
  }

  getActiveDrivers() {
    return this.http.get<Array<Driver>>(`${this.url}/all/active`);
  }

  imageUpload(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.url}/${id}/image-upload`, formData);
  }

  filesUpload(id: number, files: File[]) {
    const formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return this.http.post(`${this.url}/${id}/files-upload`, formData);
  }

  removeUploadedFile(driverId: number, id: number) {
    return this.http.delete(`${this.url}/${driverId}/remove-file/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  fetchLaunches(
    driverId: number,
    initialDate: string,
    finalDate: string
  ): Observable<TripRequest[]> {
    return this.http.get<TripRequest[]>(
      `${this.url}/${driverId}/fetch-launches`,
      {
        params: { initialDate, finalDate },
      }
    );
  }
}
