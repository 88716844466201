import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car } from 'src/app/domain/car';
import { VehicleTypes } from 'src/app/enumerations/vehicle-types';
import { CarService } from 'src/app/service/car/car.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-car-view',
  templateUrl: './car-view.component.html',
  styleUrls: ['./car-view.component.scss']
})
export class CarViewComponent implements OnInit {


  id: number;
  car: Car = new Car();
  loading: boolean = true;

  constructor(
    private service: CarService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getCar();
  }

  getCar() {
    this.loading = true;
    this.service.getCar(this.id).subscribe(data => {
      this.car = data;
      this.car.type = VehicleTypes[data.type];
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Veículo!', 'error');
  }

}
