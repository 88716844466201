<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row mt-4 mb-2">
            <div class="col-md-10">
              <div class="page-title">
                {{ this.isEditingId ? "Editar" : "Cadastrar" }} Valor de Corrida
                do Convênio
              </div>
            </div>
          </div>
          <div [formGroup]="covenantRequestValueForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="requestValue">Valor da Corrida</label>
                  <ng-select
                    *ngIf="!this.isEditingId"
                    class="custom-select"
                    style="font-size: 1rem; font-weight: normal"
                    notFoundText="Nenhum item encontrado"
                    placeholder="Valor da Corrida"
                    formControlName="requestValue"
                    [items]="requestValues"
                    bindLabel="requestAndValue"
                    bindValue="id"
                  >
                  </ng-select>
                  <input
                    *ngIf="this.isEditingId"
                    class="form-control"
                    disabled
                    type="text"
                    [value]="editingRequestValue"
                  />
                  <span
                    *ngIf="
                      covenantRequestValueForm.controls.requestValue.errors &&
                      covenantRequestValueForm.controls.requestValue.errors
                        .required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      covenantRequestValueForm.controls.requestValue.errors &&
                      covenantRequestValueForm.controls.requestValue.errors
                        .invalid
                    "
                    class="text-danger position-right"
                    >*
                    {{
                      covenantRequestValueForm.controls.requestValue.errors
                        .invalid
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="validity">Data de Validade</label>
                  <input
                    type="date"
                    class="form-control"
                    id="validity"
                    formControlName="validity"
                  />
                  <span
                    *ngIf="
                      covenantRequestValueForm.controls.validity.errors &&
                      covenantRequestValueForm.controls.validity.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      covenantRequestValueForm.controls.validity.errors &&
                      covenantRequestValueForm.controls.validity.errors.invalid
                    "
                    class="text-danger position-right"
                    >*
                    {{
                      covenantRequestValueForm.controls.validity.errors.invalid
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button
                (click)="onSubmit()"
                [disabled]="!covenantRequestValueForm.valid"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 mr-3 btn-circle btn-primary"
              >
                {{ this.isEditingId ? "Salvar" : "Adicionar" }}
              </button>
              <button
                (click)="createForm()"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-warning"
              >
                Cancelar
              </button>
            </div>
          </div>
          <p class="page-title">Valores de Corrida</p>
          <div class="row form-group" [formGroup]="searchForm">
            <div class="col-3">
              <input
                type="text"
                class="form-control"
                placeholder="Destino"
                formControlName="destiny"
              />
            </div>
            <div class="col-2">
              <input
                type="text"
                class="form-control"
                currencyMask
                formControlName="value"
                [options]="{
                  prefix: 'R$ ',
                  thousands: '.',
                  decimal: ',',
                  align: 'left',
                  allowNegative: false,
                  nullable: true
                }"
                placeholder="Valor"
              />
            </div>
            <div class="col-3">
              <input
                type="text"
                class="form-control"
                suffix="%"
                mask="percent"
                placeholder="Comissão do Motorista"
                formControlName="percentage"
              />
            </div>
            <div class="col-3">
              <input
                type="text"
                onfocus="(this.type='date')"
                class="form-control"
                placeholder="Data de Validade"
                formControlName="validity"
              />
            </div>
            <div class="col-1">
              <a (click)="onSearch()" class="btn btn-info">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped custom-table table-hover">
              <thead>
                <tr>
                  <th>Destino</th>
                  <th>Valor</th>
                  <th>Valor Hora Adicional</th>
                  <th>Valor Pedágio</th>
                  <th>Comissão do Motorista</th>
                  <th>Data de Validade</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let value of covenantRequestValues">
                  <td>
                    {{ value.requestValue.destiny }}
                  </td>
                  <td>
                    {{ value.requestValue.value | currency : "BRL" }}
                  </td>
                  <td>
                    {{ value.requestValue.aditionalValue | currency : "BRL" }}
                  </td>
                  <td>
                    {{ value.requestValue.tollAmount | currency : "BRL" }}
                  </td>
                  <td>
                    {{ value.requestValue.percentage | percent }}
                  </td>
                  <td>
                    {{ value.validity | date : "dd/MM/yyyy" }}
                  </td>
                  <td>
                    <a
                      (click)="onRequestValueEdit(value)"
                      class="btn btn-warning btn-xs"
                      title="Editar"
                    >
                      <i class="fa fa-pencil"></i>
                    </a>
                    <button
                      (click)="onRequestValueDelete(value.id)"
                      class="btn btn-danger btn-xs"
                      title="Excluir"
                    >
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-12">
                <span
                  *ngIf="!covenantRequestValues.length"
                  class="label label-danger label-mini"
                >
                  Nenhum Valor das corridas adicionado.
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-12 text-center">
              <a
                routerLink="/maintenance/covenant/view/{{ covenantId }}"
                class="btn btn-danger"
                >Voltar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
