import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CovenantRunsService {

  url: string = `${environment.BASE_URL}/covenant/runs`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getRuns(filter: any, pagination: Pagination) {
    let getActive = filter.active ?? "";
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        cost_center: `${filter.costCenter ?? ""}`,
        payment_method: `${filter.paymentMethod ?? ""}`,
        date_start: `${filter.dateStart ?? ""}`,
        date_end: `${filter.dateEnd ?? ""}`,
        driver: `${filter.driver ?? ""}`,
        vehicle: `${filter.vehicle ?? ""}`,
        status: `${filter.status ?? ""}`,
        active: getActive
      }
    });
    return this.http.get(this.url,
      { responseType: "json", params: params });

  }

  getRun(id: number) {
    return this.http.get(`${this.url}/${id}`,
      { responseType: "json" });
  }

  update(id, data) {
    return this.http.put(`${this.url}/${id}`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  cancel(id) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  shared(data) {
    return this.http.post(`${this.url}/share`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  exportTripRequestRuns(filter: any, type: string) {
    let getActive = filter.active ?? "";
    const params = new HttpParams({
      fromObject: {
        cost_center: `${filter.costCenter ?? ""}`,
        payment_method: `${filter.paymentMethod ?? ""}`,
        date_start: `${filter.dateStart ?? ""}`,
        date_end: `${filter.dateEnd ?? ""}`,
        driver: `${filter.driver ?? ""}`,
        vehicle: `${filter.vehicle ?? ""}`,
        status: `${filter.status ?? ""}`,
        active: getActive,
        type: type
      }
    });
    return this.http.get(`${this.url}/get/export`, {
      headers: this.httpHeaders,
      responseType: "blob",
      params: params,
      observe: 'response'
    });
  }
  // getRunsByRequest(request: number, filter: any, pagination: Pagination) {
  //   let getActive = filter.active ?? "";
  //   const params = new HttpParams({
  //     fromObject: {
  //       page: `${pagination.page}`,
  //       per_page: `${pagination.per_page}`,
  //       cost_center: `${filter.costCenter ?? ""}`,
  //       payment_method: `${filter.paymentMethod ?? ""}`,
  //       date_start: `${filter.dateStart ?? ""}`,
  //       date_end: `${filter.dateEnd ?? ""}`,
  //       driver: `${filter.driver ?? ""}`,
  //       vehicle: `${filter.vehicle ?? ""}`,
  //       status: `${filter.status ?? ""}`,
  //       active: getActive
  //     }
  //   });
  //   return this.http.get(`${this.url}/${request}/run_request`,
  //     { responseType: "json", params: params });
  // }

}
