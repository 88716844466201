import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CovenantMyaccountService {
  url: string = `${environment.BASE_URL}/my-account/covenant`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });
  constructor(private http: HttpClient) { }

  getData() {
    return this.http.get(this.url, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  reset(oldPassword: string, newPassword: string, confirmationPassword: string) {
    return this.http.put(`${this.url}/update-password`,
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordConfirmation: confirmationPassword
      }, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
