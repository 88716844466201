import { Component, OnInit } from '@angular/core';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { Validations } from '../../validations';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { ViaCep } from 'src/app/domain/viacep';
import { AddressService } from 'src/app/service/address/address.service';
import Swal from 'sweetalert2';
import { Provider } from 'src/app/domain/provider';
import { Router } from '@angular/router';

@Component({
  selector: 'app-provider-create',
  templateUrl: './provider-create.component.html',
  styleUrls: ['./provider-create.component.css']
})
export class ProviderCreateComponent implements OnInit {


  states: Array<State> = [];
  cities: Array<City> = [];
  providerForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  viacep: ViaCep = new ViaCep;

  constructor(
    private service: ProviderService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.serviceAddress.getStates().subscribe(data => {
      this.states = data;
      console.log(this.states);

    }, error => {
      Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
    });
    // this.serviceAcessProfile.getAllAccessProfiles().subscribe(data => {
    //   this.accessProfiles = data;

    //   this.loading = false;
    // },
    //   error => {
    //     this.loading = false;
    //     Swal.fire('Erro', 'Não foi possível Buscar Perfis de Acesso!', 'error');
    //   });

  }

  createForm() {
    this.providerForm = this.fb.group({
      name: ['', [Validators.required]],
      cpfCnpj: ['', [Validators.required, Validations.validCpfOrCnpj]],
      contact: ['', [Validators.required]],
      stateRegistration: ['', []],
      municipalRegistration: ['', []],
      phone: ['', [Validators.required, Validations.validPhone]],
      whatsapp: ['', [Validations.validwhatsapp]],
      email: ['', [Validators.required, Validators.email]],
      zipCode: ['', [Validators.required]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
    });
  }
  onSubmit() {

    const values = this.providerForm.controls;
    console.log(this.providerForm.controls);


    let provider = new Provider();
    provider.name = this.providerForm.controls.name.value;
    provider.email = this.providerForm.controls.email.value;
    provider.cpfCnpj = this.providerForm.controls.cpfCnpj.value;
    provider.stateRegistration = this.providerForm.controls.stateRegistration.value;
    provider.municipalRegistration = this.providerForm.controls.municipalRegistration.value;
    provider.contact = this.providerForm.controls.contact.value;
    provider.whatsapp = this.providerForm.controls.whatsapp.value;
    provider.phone = this.providerForm.controls.phone.value;
    provider.address.zipCode = this.providerForm.controls.zipCode.value.replace(/[^0-9,.]+/g, "");
    provider.address.neighborhood = this.providerForm.controls.neighborhood.value;
    provider.address.publicPlace = this.providerForm.controls.publicPlace.value;
    provider.address.city.id = Number(this.providerForm.controls.city.value);

    this.loading = true;
    this.service.save(provider).subscribe(data => {
      this.loading = false;
      Swal.fire('Salvo', 'Fornecedor salvo com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/provider']);
        }
      });
      this.createForm();

    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar Fornecedor!', 'error');
      });

  }
  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, "").length === 8) {
      this.utilsService.getCep(event.target.value.replace(/[^0-9,.]+/g, "")).subscribe(data => {
        this.viacep = data;

        if (!this.viacep.erro) {
          this.providerForm.controls.neighborhood.setValue(this.viacep.bairro);
          this.providerForm.controls.publicPlace.setValue(this.viacep.logradouro);
          const state = this.states.find(state => state.initials === this.viacep.uf.toUpperCase());

          this.providerForm.controls.state.setValue(state.id);

          // TODO - Implementar busca de cidades por UF
          this.serviceAddress.getCitiesByStateId(state.id).subscribe(data => {
            this.cities = data;
            const city = this.cities.find(elem => elem.name.normalize("NFD") === this.viacep.localidade.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ''));
            if (city) {
              this.providerForm.controls.city.setValue(city.id);
            }
          }, error => {
            Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
          });

        } else {
          this.providerForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        }
        this.loading = false;
      }, erro => {
        this.providerForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.providerForm.controls.zipCode.setErrors({ invalid: "CEP inválido!" });
    }
  }
  resetForm() {
    this.providerForm.controls.name.setValue("");
    this.providerForm.controls.cpfCnpj.setValue("");
    this.providerForm.controls.stateRegistration.setValue("");
    this.providerForm.controls.municipalRegistration.setValue("");
    this.providerForm.controls.email.setValue("");
    this.providerForm.controls.contact.setValue("");
    this.providerForm.controls.whatsapp.setValue("");
    this.providerForm.controls.phone.setValue("");
    this.providerForm.controls.zipCode.setValue("");
    this.providerForm.controls.neighborhood.setValue("");
    this.providerForm.controls.publicPlace.setValue("");
    this.providerForm.controls.state.setValue("");
    this.providerForm.controls.city.setValue("");
  }

  setErrors(errors) {
    errors.forEach(erro => {
      console.log(erro);

      switch (erro.fieldName) {
        case "name":
          this.providerForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "cpfCnpj":
          this.providerForm.controls.cpfCnpj.setErrors({ invalid: erro.message });
          break;
        case "stateRegistration":
          this.providerForm.controls.stateRegistration.setErrors({ invalid: erro.message });
          break;
        case "municipalRegistration":
          this.providerForm.controls.municipalRegistration.setErrors({ invalid: erro.message });
          break;
        case "whatsapp":
          this.providerForm.controls.whatsapp.setErrors({ invalid: erro.message });
          break;
        case "phone":
          this.providerForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case "email":
          this.providerForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "zipCode":
          this.providerForm.controls.zipCode.setErrors({ invalid: erro.message });
          break;
        case "publicPlace":
          this.providerForm.controls.publicPlace.setErrors({ invalid: erro.message });
          break;
        case "neighborhood":
          this.providerForm.controls.neighborhood.setErrors({ invalid: erro.message });
          break;
        case "state":
          this.providerForm.controls.state.setErrors({ invalid: erro.message });
          break;
        case "city":
          this.providerForm.controls.city.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }

}
