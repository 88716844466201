<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Lista de Solicitações de Corrida</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                href="/convenio/covenant-trip-request"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="">Mostrar Todos os Resultados</option>
                </select>
              </div>
              <header style="width: 100%">
                <div [formGroup]="searchForm">
                  <div class="row">
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">Início</label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="dateStart"
                      />
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">Fim</label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="dateEnd"
                      />
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2">Status</label>
                      <select
                        class="custom-select custom-select"
                        style="font-size: 14px"
                        formControlName="status"
                      >
                        <option value="">Status</option>
                        <option value="waiting">Aguardando</option>
                        <option value="approved">Aprovada</option>
                        <option value="canceled">Cancelada</option>
                      </select>
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2"
                        >Pagamento</label
                      >
                      <select
                        formControlName="paymentMethod"
                        class="custom-select custom-select"
                        style="font-size: 14px"
                      >
                        <option value="">Método de Pagamento</option>
                        <option
                          *ngFor="let paymentMethod of paymentMethods"
                          value="{{ paymentMethod.id }}"
                        >
                          {{ paymentMethod.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2"
                        >Centro de Custo</label
                      >
                      <select
                        formControlName="costCenter"
                        class="custom-select custom-select"
                        style="font-size: 14px"
                      >
                        <option value="">Centro de Custo</option>
                        <option
                          *ngFor="let costCenter of costCenters"
                          value="{{ costCenter.id }}"
                        >
                          {{ costCenter.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-3">
                      <label style="font-size: 14px" class="mt-2"
                        >Veículo</label
                      >
                      <select
                        formControlName="vehicle"
                        class="custom-select custom-select col"
                        style="font-size: 14px"
                      >
                        <option value="">Veículo</option>
                        <option *ngFor="let car of cars" value="{{ car.id }}">
                          {{ car.model.name }} - {{ car.carPlate }}
                        </option>
                      </select>
                    </div>

                    <div class="col trip-request__button__search--div">
                      <button
                        (click)="onSearch()"
                        class="btn btn-info mr-3"
                        title="Buscar"
                      >
                        <i
                          class="fa fa-search fa-lg trip-request__button__search"
                        ></i>
                      </button>
                      <button
                        (click)="exportTripRequests('pdf')"
                        class="btn btn-danger mr-3"
                        title="Exportar para PDF"
                      >
                        <i
                          class="fa fa-file-pdf-o fa-lg trip-request__button__search"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <button
                        (click)="exportTripRequests('excel')"
                        class="btn btn-success"
                        title="Exportar para Excel"
                      >
                        <i
                          class="fa fa-file-excel-o fa-lg trip-request__button__search"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive-md">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Data da Solicitação</th>
                      <!-- <th>Cliente/Convênio</th> -->
                      <th>Tipo de Pagamento</th>
                      <th>Valor Solicitação</th>
                      <th>Solicitação</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tripRequest of tripRequests">
                      <!-- <td>
                        {{ tripRequest.id }}
                      </td> -->
                      <td>
                        {{
                          moment(tripRequest.createdAt).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        }}
                      </td>
                      <!-- <td>
                                                <span *ngIf="tripRequest.client"> {{ tripRequest.client.name }}</span>
                                                <span *ngIf="tripRequest.covenant">
                                                    {{ tripRequest.covenant.name }}</span>
                                            </td> -->
                      <td>
                        {{ tripRequest.paymentMethod.name }}
                      </td>
                      <td>
                        {{ tripRequest.runValue | currency : "BRL" }}
                      </td>
                      <td>
                        {{ tripRequest.location }}
                      </td>
                      <!-- <td>
                                                <span *ngIf="tripRequest.passengers.length > 1">Sim</span>
                                                <span *ngIf="tripRequest.passengers.length <= 1">Não</span>
                                            </td> -->
                      <td>
                        {{ tripRequest.status }}
                      </td>
                      <td>
                        <div class="row">
                          <a
                            href="/convenio/covenant-trip-request/{{
                              tripRequest.id
                            }}"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <!-- <a *ngIf="tripRequest.status == 'Aguardando'" href="/convenio/covenant-trip-request/{{
                              tripRequest.id
                            }}/link-vehicle" class="btn btn-warning" alt="Vincular Veículo">
                            <i class="fa fa-car"></i>
                          </a> -->
                          <!--<a *ngIf="tripRequest.status !== 'Aguardando'"
                                                        routerLink="/process/runs/{{tripRequest.id}}/request"
                                                        class="btn btn-outline-info" alt="Ver Corridas">
                                                        <i class="fa fa-angle-double-right"></i>
                                                    </a>-->
                          <a
                            *ngIf="tripRequest.status == 'Aguardando'"
                            href="/convenio/covenant-trip-request-cancel/{{
                              tripRequest.id
                            }}"
                            class="btn btn-danger"
                            alt="Cancelar Corrida"
                          >
                            <i class="fa fa-ban"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item" [class.disabled]="!first">
                    <button class="page-link" (click)="onFirst()" tabindex="-1">
                      Primeira
                    </button>
                  </li>
                  <li class="page-item" *ngIf="pagination.page != 1">
                    <button (click)="onPrevious()" class="page-link">
                      {{ pagination.page - 1 }}
                    </button>
                  </li>
                  <li class="page-item active">
                    <button class="page-link">{{ pagination.page }}</button>
                  </li>
                  <li class="page-item" *ngIf="last">
                    <button (click)="onNext()" class="page-link" href="#">
                      {{ pagination.page + 1 }}
                    </button>
                  </li>
                  <li class="page-item" [class.disabled]="!last">
                    <button class="page-link" (click)="onLast()">Última</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-md-12">
              Mostrando página {{ pagination.page }} de
              {{ pagination.totalPages }} dos
              {{ pagination.totalResults }} Resultados
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
