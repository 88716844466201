import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialParameter } from 'src/app/domain/financial-parameter';
import { FinancialParameterService } from 'src/app/service/financial-parameter/financial-parameter.service';
import Swal from 'sweetalert2';
import { Validations } from '../../validations';

@Component({
  selector: 'app-financial-parameter-update',
  templateUrl: './financial-parameter-update.component.html',
  styleUrls: ['./financial-parameter-update.component.css']
})
export class FinancialParameterUpdateComponent implements OnInit {

  financialParameterForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  financialParameter: FinancialParameter = new FinancialParameter();

  constructor(
    private service: FinancialParameterService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.financialParameter.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();


    this.service.getFinancialParameter(this.financialParameter.id).subscribe(data => {
      this.financialParameter = data;
      this.setValues();
    }, error => {
      this.loading = false;
      Swal.fire('Erro', 'Não foi possível Buscar Parâmetro Financeiro!', 'error');
    });

  }
  createForm() {
    this.financialParameterForm = this.fb.group({
      type: ['', [Validators.required]],
      parameter: ['', [Validators.required]],
      value: ['', [Validators.required]],
      active: ['', Validators.required]
    });
  }
  setValues() {
    this.financialParameterForm.controls.type.setValue(this.financialParameter.type);
    this.financialParameterForm.controls.parameter.setValue(this.financialParameter.parameter);
    this.financialParameterForm.controls.value.setValue(this.financialParameter.value);
    this.financialParameterForm.controls.active.setValue(`${this.financialParameter.active}`);
  }

  onSubmit() {
    this.financialParameter.type = this.financialParameterForm.controls.type.value;
    this.financialParameter.parameter = this.financialParameterForm.controls.parameter.value;
    this.financialParameter.value = this.financialParameterForm.controls.value.value;

    if (this.financialParameterForm.controls.active.value == "true") {
      this.financialParameter.active = true
    } else {
      this.financialParameter.active = false;
    }
    this.loading = true;
    this.service.update(this.financialParameter).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Parâmetro Financeiro editado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/financial-parameter']);
        }
      });
      this.router.navigate(['parameters/financial-parameter']);
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Parâmetro Financeiro!', 'error');
      });
  }

  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "type":
          this.financialParameterForm.controls.type.setErrors({ invalid: erro.message });
          break;
        case "parameter":
          this.financialParameterForm.controls.parameter.setErrors({ invalid: erro.message });
          break;
        case "value":
          this.financialParameterForm.controls.value.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }
}
