<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title"> Editar Conta Bancária </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-topline-green">
                        <div class="card-head">
                            <header style="width: 100%;">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <label style="font-size: 14px;" class="mt-2">Banco</label>
                                        <select class="custom-select custom-select" style="font-size: 14px;">
                                            <option value="A">Itaú</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-2">
                                        <label style="font-size: 14px;" class="mt-2">Agência</label>
                                        <input type="text" class="form-control" placeholder="Agência"
                                            formControlName="bankaccount">
                                    </div>
                                    <div class="col-sm-2">
                                        <label style="font-size: 14px;" class="mt-2">Conta Bancária</label>
                                        <input type="text" class="form-control" placeholder="15.5026-55"
                                            formControlName="bankaccount">
                                    </div>
                                    <div class="col-sm-3">
                                        <label style="font-size: 14px;" class="mt-2">Tipo</label>
                                        <select class="custom-select custom-select" style="font-size: 14px;">
                                            <option value="A">Conta Corrente</option>
                                        </select>
                                    </div>

                                    <div class="col mt-4">
                                        <a class="btn btn-info">
                                            <i class="fa fa-search"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row mt-3 ml-0 mb-2">
                                    <label style="font-size: 14px;">Status</label>
                                </div>
                                <div class="row ml-0">
                                    <input type="checkbox">
                                    <label style="font-size: 14px;" class="ml-1">Ativo</label>
                                    <input type="checkbox" class="ml-3">
                                    <label style="font-size: 14px;" class="ml-1">Inativo</label>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-3">
                                        <button class="btn btn-success">Salvar</button>
                                    </div>
                                    <div class="col-md-3">
                                        <a routerLink="/parameters/bank-account" class="btn btn-danger">Cancelar</a>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
