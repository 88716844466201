import { Component, OnInit } from '@angular/core';
import { AccessProfile } from 'src/app/domain/access-profile';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user/user.service';
import { AccessProfileService } from 'src/app/service/access-profile/access-profile.service';
import { Pagination } from 'src/app/domain/pagination';
import Swal from 'sweetalert2';
import { Validations } from '../../validations';
import { User } from 'src/app/domain/user';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit {

  accessProfiles: Array<AccessProfile> = [];
  userForm: FormGroup;
  loading: boolean = true;
  user: User = new User();


  constructor(
    private service: UserService,
    private serviceAcessProfile: AccessProfileService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.user.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();

    let controls = this.userForm.controls;
    console.log(controls);

    this.serviceAcessProfile.getAllAccessProfiles().subscribe(data => {
      this.accessProfiles = data;

      this.service.getUser(this.user.id).subscribe(data => {
        this.user = data;
        this.setValues();
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar Usuário!', 'error');
      });
    },
      error => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar Perfis de Acesso!', 'error');
      });


  }
  setValues() {
    this.userForm.controls.name.setValue(this.user.name);
    this.userForm.controls.email.setValue(this.user.email);
    this.userForm.controls.birthDate.setValue(this.user.birthDate);
    this.userForm.controls.phone.setValue(this.user.phone);
    this.userForm.controls.login.setValue(this.user.login);

    if (this.user.accessProfile != null) {
      this.userForm.controls.accessProfile.setValue(this.user.accessProfile.id);
    }

    this.userForm.controls.role.setValue(this.user.role);
    this.userForm.controls.active.setValue(`${this.user.active}`);
  }

  createForm() {
    this.userForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email]],
      birthDate: ['', [Validators.required, Validations.biggerThanToday]],
      role: ['', [Validators.required]],
      active: ['', Validators.required],
      phone: ['', [Validators.required]],
      login: ['', [Validators.required]],
      accessProfile: ['', [Validators.required]],
    });
  }
  onSubmit() {
    this.user.name = this.userForm.controls.name.value;
    this.user.email = this.userForm.controls.email.value;
    this.user.role = this.userForm.controls.role.value;
    this.user.accessProfile = new AccessProfile();
    this.user.accessProfile.id = Number(this.userForm.controls.accessProfile.value);
    this.user.birthDate = this.userForm.controls.birthDate.value;
    this.user.login = this.userForm.controls.login.value;
    if (this.userForm.controls.active.value == "true") {
      this.user.active = true
    } else {
      this.user.active = false;
    }
    this.user.phone = this.userForm.controls.phone.value.replace(/[^0-9,.]+/g, "");

    this.loading = true;
    this.service.update(this.user).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Usuário editado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['security/user']);
        }
      });
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Usuário!', 'error');
      });

  }
  setErrors(errors) {
    errors.forEach(erro => {
      if (erro.fieldName === 'email') {
        this.userForm.controls.email.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'name') {
        this.userForm.controls.name.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'role') {
        this.userForm.controls.role.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'birthDate') {
        this.userForm.controls.birthDate.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'phone') {
        this.userForm.controls.phone.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'login') {
        this.userForm.controls.login.setErrors({ invalid: erro.message });
      }
    });

  }

}
