import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduling-create',
  templateUrl: './scheduling-create.component.html',
  styleUrls: ['./scheduling-create.component.css']
})
export class SchedulingCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
