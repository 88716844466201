<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar valor de corrida</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Tipo</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.type }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Destino</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.destiny }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Valor</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.value | currency: "BRL" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Hora Início</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.initialHour }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Hora Fim</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.endHour }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Valor Hora Adicional</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.aditionalValue | currency: "BRL" }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Valor Pedágio</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.tollAmount | currency: "BRL" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Valor Ponto Adicional</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.valueOriginPoint | currency: "BRL" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Comissão Motorista</b>
                    </div>
                    <div class="col-md-12">
                      {{ requestValue.percentage | percent }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Multa</b>
                    </div>
                    <div class="col-md-12">
                      <span *ngIf="requestValue.percentagePenalty">{{ requestValue.percentagePenalty | percent
                        }}</span>
                      <span *ngIf="!requestValue.percentagePenalty">Não Informado</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Status</b>
                    </div>
                    <div class="col-md-12">
                      <span *ngIf="requestValue.active" class="label label-sucess label-mini">Ativo</span>
                      <span *ngIf="!requestValue.active" class="label label-danger label-mini">Inativo</span>
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <th>Nome do Ponto</th>
                    <th>Valor do Ponto</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let point of requestValue.destinyPoints let i = index">
                      <td>{{point.name}}</td>
                      <td>{{point.value | currency: 'BRL'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col-md-4">
                  <a routerLink="/parameters/request-value/edit/{{ requestValue.id }}" class="btn btn-warning">Editar</a>
                </div>
                <div class="col-md-4">
                  <a routerLink="/parameters/request-value" class="btn btn-info">Voltar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
