<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Visualizar Conta a Receber</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <header style="width: 100%">
                <div class="row">
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Código</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      billsToReceive.id
                    }}</label>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Cliente/Convênio</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      billsToReceive.client?.name ||
                        billsToReceive.covenant?.name
                    }}</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Data de Vencimento</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      billsToReceive.dueDate | date: "dd/MM/yyyy"
                    }}</label>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Valor</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      billsToReceive.value | currency: "BRL"
                    }}</label>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      *ngIf="billsToReceive.parcels?.length > 1"
                      class="table table-bordered mt-5"
                    >
                      <thead>
                        <tr>
                          <th scope="col">Nº</th>
                          <th
                            *ngIf="billsToReceive.paymentMethod == 'bank_check'"
                            scope="col"
                          >
                            Nº do Cheque
                          </th>
                          <th scope="col">Valor</th>
                          <th scope="col">Data Vencimento</th>
                          <th scope="col">Pago</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let parcel of billsToReceive.parcels">
                          <td>{{ parcel.number }}</td>
                          <td
                            *ngIf="billsToReceive.paymentMethod == 'bank_check'"
                          >
                            {{ parcel.bankCheckNumber }}
                          </td>
                          <td>
                            {{ parcel.value | currency: "BRL" }}
                          </td>
                          <td>
                            {{ parcel.dueDate | date: "dd/MM/yyyy" }}
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              [checked]="parcel.paid"
                              disabled
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-3"></div>
                  <div class="col-md-3 text-right">
                    <div class="col-md-4">
                      <a
                        routerLink="/financial/bills-to-receive/edit/{{ billsToReceive.id }}"
                        class="btn btn-warning"
                        >Editar</a
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <a routerLink="/financial/bills-to-receive" class="btn btn-danger"
                      >Cancelar</a
                    >
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
