import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Covenant } from 'src/app/domain/covenant';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import Swal from 'sweetalert2';
import { Collaborator } from 'src/app/domain/collaborator';
import { CollaboratorDTO } from 'src/app/domain/dto/collaborator.dto';
import { CovenantCollaboratorService } from 'src/app/service/covenant-collaborator/covenant-collaborator.service';
import { Pagination } from 'src/app/domain/pagination';

@Component({
  selector: 'app-covenant-view',
  templateUrl: './covenant-view.component.html',
  styleUrls: ['./covenant-view.component.css']
})
export class CovenantViewComponent implements OnInit {

  id: number;
  covenant: Covenant = new Covenant();
  collaborators: Array<Collaborator> = [];
  loading: boolean = true;

  constructor(
    private service: CovenantService,
    private collaboratorsService: CovenantCollaboratorService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getCovenant();
    this.getCollaborators();
  }

  getCollaborators() {
    this.loading = true;
    this.collaboratorsService.getCollaborators(this.id, new Pagination()).subscribe(data => {
      this.collaborators = data['results'];
      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  getCovenant() {
    this.loading = true;
    this.service.getCovenantBasic(this.id).subscribe(data => {
      this.covenant = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Convênio!', 'error');
  }

}
