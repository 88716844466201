<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Conta Bancária</div>
            </div>
            <div class="col-md-2">
              <a type="button" href="bank-account/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header style="width: 100%">
                <div class="row">
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Banco</label>
                    <select class="form-control" formControlName="bank">
                      <option value="null">Banco</option>
                      <option value="{{ bank.id }}" *ngFor="let bank of banks">
                        {{ bank.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-2">
                    <label style="font-size: 14px" class="mt-2">Conta Bancária</label>
                    <input type="text" class="form-control" placeholder="15.5026-55" formControlName="bankaccount" />
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Tipo</label>
                    <select class="custom-select custom-select" style="font-size: 14px">
                      <option value="1">Conta Corrente</option>
                      <option value="2">Conta Poupança</option>
                      <option value="3">Invertimento</option>
                    </select>
                  </div>
                  <div class="col-sm-2">
                    <label style="font-size: 14px" class="mt-2">Status</label>
                    <select class="custom-select custom-select" style="font-size: 14px">
                      <option value="null">Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                  <div class="col mt-4">
                    <a class="btn btn-info">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Banco</th>
                      <th>Agência</th>
                      <th>Conta Bancária</th>
                      <th>Tipo</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bankAccount of bankAccounts">
                      <!-- <td>
                        {{ bankAccount.id }}
                      </td> -->
                      <td>
                        {{ bankAccount.bank.name }}
                      </td>
                      <td>
                        {{ bankAccount.agency }}
                      </td>
                      <td>
                        {{ bankAccount.bankAccount }}
                      </td>
                      <td>Conta Corrente</td>
                      <td>
                        <span *ngIf="bankAccount.active" class="label label-sucess label-mini">Ativo</span>
                        <span *ngIf="!bankAccount.active" class="label label-danger label-mini">Inativo</span>
                      </td>
                      <td>
                        <div class="row">
                          <a routerLink="/parameters/bank-account/view:id" class="btn btn-primary btn-xs" alt="Visualizar">
                            <i class="fa fa-eye"></i>
                          </a>
                          <a routerLink="/parameters/bank-account/edit:id" class="btn btn-warning btn-xs">
                            <i class="fa fa-pencil"></i>
                          </a>
                          <a class="btn btn-danger btn-xs">
                            <i class="fa fa-trash-o"></i>
                          </a>
                          <a class="btn btn-success btn-xs">
                            <i class="fa fa-random"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              Mostrando página 1 de 1 dos 1 Resultados
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
