<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="bg-light py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Valor de Corrida</div>
            </div>
          </div>
          <div [formGroup]="requestValueForm">
            <!--div com o form -->
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="type">Tipo</label>

                  <select formControlName="type" class="form-control">
                    <option value="">
                      Selecione um tipo de Valor das corridas
                    </option>
                    <option value="covenant">Convênio</option>
                    <option value="client">Cliente</option>
                  </select>
                  <span *ngIf="requestValueForm.controls.type.errors">
                    <span
                      *ngIf="requestValueForm.controls.type.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.type.errors.type &&
                        requestValueForm.controls.type.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ requestValueForm.controls.type.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="destiny">Destino</label>
                  <input
                    type="text"
                    class="form-control"
                    id="destiny"
                    formControlName="destiny"
                    placeholder="Destino"
                  />
                  <span *ngIf="requestValueForm.controls.destiny.errors">
                    <span
                      *ngIf="requestValueForm.controls.destiny.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.destiny.errors.required &&
                        requestValueForm.controls.destiny.errors.maxlength
                      "
                      class="text-danger position-right"
                      >*Destino deve ter no máximo 100 caracteres</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.destiny.errors.destiny &&
                        requestValueForm.controls.destiny.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ requestValueForm.controls.destiny.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="value">Valor</label>
                  <input
                    type="text"
                    class="form-control"
                    currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    id="value"
                    formControlName="value"
                    placeholder="Valor"
                  />
                  <span *ngIf="requestValueForm.controls.value.errors">
                    <span
                      *ngIf="requestValueForm.controls.value.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.value.errors.required &&
                        requestValueForm.controls.value.errors.maxlength
                      "
                      class="text-danger position-right"
                      >*Valor deve ter no máximo 20 caracteres</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.value.errors.destiny &&
                        requestValueForm.controls.value.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ requestValueForm.controls.value.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="initialHour">Hora Inicial da Multa</label>
                  <input
                    type="time"
                    class="form-control"
                    id="initialHour"
                    formControlName="initialHour"
                    placeholder="Hora Inicial"
                  />
                  <span *ngIf="requestValueForm.controls.initialHour.errors">
                    <span
                      *ngIf="
                        requestValueForm.controls.initialHour.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.initialHour.errors.destiny &&
                        requestValueForm.controls.initialHour.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ requestValueForm.controls.initialHour.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="endHour">Hora Fim da Multa</label>
                  <input
                    type="time"
                    class="form-control"
                    id="endHour"
                    formControlName="endHour"
                    placeholder="Hora Fim"
                  />
                  <span *ngIf="requestValueForm.controls.endHour.errors">
                    <span
                      *ngIf="requestValueForm.controls.endHour.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.endHour.errors.destiny &&
                        requestValueForm.controls.endHour.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ requestValueForm.controls.endHour.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="aditionalValue">Valor Hora Adicional</label>
                  <input
                    type="text"
                    class="form-control"
                    currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    id="aditionalValue"
                    formControlName="aditionalValue"
                    placeholder="Valor Hora Adicional"
                  />
                  <span *ngIf="requestValueForm.controls.aditionalValue.errors">
                    <span
                      *ngIf="
                        requestValueForm.controls.aditionalValue.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.aditionalValue.errors
                          .required &&
                        requestValueForm.controls.aditionalValue.errors
                          .maxlength
                      "
                      class="text-danger position-right"
                      >*Valor adicional deve ter no máximo 20 caracteres</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.aditionalValue.errors
                          .destiny &&
                        requestValueForm.controls.aditionalValue.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ requestValueForm.controls.value.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="tollAmount">Valor Pedágio</label>
                  <input
                    type="text"
                    class="form-control"
                    currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    id="tollAmount"
                    formControlName="tollAmount"
                    placeholder="Valor Pedágio"
                  />
                  <span *ngIf="requestValueForm.controls.tollAmount.errors">
                    <span
                      *ngIf="
                        !requestValueForm.controls.tollAmount.errors.required &&
                        requestValueForm.controls.tollAmount.errors.maxlength
                      "
                      class="text-danger position-right"
                      >*Valor do Pedágio deve ter no máximo 20 caracteres</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.tollAmount.errors.destiny &&
                        requestValueForm.controls.tollAmount.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ requestValueForm.controls.value.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="valueOriginPoint">Valor Ponto de Origem</label>
                  <input
                    type="text"
                    class="form-control"
                    currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    id="valueOriginPoint"
                    formControlName="valueOriginPoint"
                    placeholder="Valor Ponto de Origem"
                  />
                  <span
                    *ngIf="requestValueForm.controls.valueOriginPoint.errors"
                  >
                    <span
                      *ngIf="
                        requestValueForm.controls.valueOriginPoint.errors
                          .required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.valueOriginPoint.errors
                          .destiny &&
                        requestValueForm.controls.valueOriginPoint.errors
                          .invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{
                        requestValueForm.controls.valueOriginPoint.errors
                          .invalid
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="percentage">Comissão Motorista</label>
                  <input
                    type="text"
                    class="form-control"
                    suffix="%"
                    mask="percent"
                    id="percentage"
                    formControlName="percentage"
                    placeholder="Comissão Motorista"
                  />
                  <span *ngIf="requestValueForm.controls.percentage.errors">
                    <span
                      *ngIf="
                        requestValueForm.controls.percentage.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.percentage.errors.destiny &&
                        requestValueForm.controls.percentage.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ requestValueForm.controls.percentage.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="percentagePenalty">Multa</label>
                  <input
                    type="text"
                    class="form-control"
                    suffix="%"
                    mask="percent"
                    id="percentagePenalty"
                    formControlName="percentagePenalty"
                    placeholder="Multa"
                  />
                  <span
                    *ngIf="requestValueForm.controls.percentagePenalty.errors"
                  >
                    <span
                      *ngIf="
                        requestValueForm.controls.percentagePenalty.errors
                          .required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !requestValueForm.controls.percentagePenalty.errors
                          .destiny &&
                        requestValueForm.controls.percentagePenalty.errors
                          .invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{
                        requestValueForm.controls.percentagePenalty.errors
                          .invalid
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <b>Pontos de Destino adicionais de corrida</b>
              </div>
            </div>
            <div class="row" [formGroup]="pointForm">
              <div class="col-md-5">
                <div class="form-group">
                  <label for="namePoint">Nome do Ponto</label>
                  <input
                    type="text"
                    class="form-control"
                    id="namePoint"
                    formControlName="namePoint"
                    placeholder="Nome do Ponto"
                  />
                  <span *ngIf="pointForm.controls.namePoint.errors">
                    <span
                      *ngIf="pointForm.controls.namePoint.errors.invalid"
                      class="text-danger position-right invalid-size"
                    >
                      *{{ pointForm.controls.namePoint.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label for="valuePoint">Valor</label>
                  <input
                    type="text"
                    class="form-control"
                    currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    id="valuePoint"
                    formControlName="valuePoint"
                    placeholder="Valor do Ponto Adicional"
                  />
                </div>
              </div>
              <div class="col-md-2 mt-4">
                <button (click)="addPoint()" class="btn btn-primary">
                  Adicionar
                </button>
              </div>
            </div>

            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <th>Nome do Ponto</th>
                    <th>Valor do Ponto</th>
                    <th>Ações</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let point of points; let i = index">
                      <td>{{ point.name }}</td>
                      <td>{{ point.value | currency: "BRL" }}</td>
                      <td>
                        <button
                          (click)="rmPoint(i)"
                          class="btn btn-danger btn-xs"
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row d-flex justify-content-center my-3">
              <div class="m-2">
                <button (click)="onSubmit()" class="btn btn-success">
                  Salvar
                </button>
              </div>
              <div class="m-2">
                <a routerLink="/parameters/request-value" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
