<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar Convênio</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-3 col-sm-12" *ngIf="covenant.imageUrl">
                  <img class="client__image" [src]="covenant.imageUrl" />
                </div>
                <div class="col-md-9 col-sm-12">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Nome</b>
                        </div>
                        <div class="col-md-12">
                          {{ covenant.name }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <b>CPF/CNPF</b>
                        </div>
                        <div class="col-md-12">
                          {{ covenant.cpfCnpj | mask: "CPF_CNPJ" }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Inscrição Estadual</b>
                        </div>
                        <div class="col-md-12">
                          {{ covenant.stateRegistration }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Inscrição Municipal </b>
                        </div>
                        <div class="col-md-12">
                          {{ covenant.municipalRegistration }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3 mb-3">
                <div class="col-md-12">
                  <b>Endereço</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b>CEP</b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant?.address?.zipCode }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Logradouro</b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant?.address?.publicPlace }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Bairro</b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant?.address?.neighborhood }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Cidade</b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant.address?.city.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Estado</b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant.address?.city.state.name }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3 mb-3">
                <div class="col-md-12">
                  <b>Contato</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Telefone</b>
                    </div>
                    <div class="col-md-12">
                      {{
                        covenant.phone
                          | mask
                            : (covenant.phone?.length == 10
                                ? "(00) 0000-0000"
                                : "(00) 0 0000-0000")
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b>WhatsApp</b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant.whatsapp | mask: "+00 00 0 0000-0000" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Email</b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant.email }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Contato Administrativo: </b>
                    </div>
                    <div class="col-md-12">
                      {{ covenant.emailContact }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-md-4 text-center">
                  <a
                    routerLink="/maintenance/covenant/users/{{ covenant.id }}"
                    class="btn btn-primary"
                  >
                    Gerenciar Usuário
                  </a>
                </div>
                <div class="col-md-4 text-center">
                  <a
                    routerLink="/maintenance/covenant/collaborators/{{
                      covenant.id
                    }}"
                    class="btn btn-primary"
                  >
                    Gerenciar Colaborador
                  </a>
                </div>
                <div class="col-md-4 text-center">
                  <a
                    routerLink="/maintenance/covenant/request-values/{{
                      covenant.id
                    }}"
                    class="btn btn-primary"
                  >
                    Gerenciar Valor de Corrida
                  </a>
                </div>
              </div>
              <!-- <div class="row mb-3 mt-3">
                <div class="row">
                  <div class="col-md-12">
                    <h2>Colaboradores</h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-striped custom-table table-hover">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Cargo</th>
                        <th>WhatsApp</th>
                        <th>Departamento</th>
                        <th>CEP</th>
                        <th>Logradouro</th>
                        <th>Bairro</th>
                        <th>Cidade</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let collaborator of collaborators">
                        <td>
                          {{ collaborator.name }}
                        </td>
                        <td>
                          {{ collaborator.role }}
                        </td>
                        <td>
                          {{
                          collaborator.whatsapp | mask: "+00 00 0 0000-0000"
                          }}
                        </td>
                        <td>
                          {{ collaborator.department }}
                        </td>
                        <td>
                          {{ collaborator.address.zipCode }}
                        </td>
                        <td>
                          {{ collaborator.address.publicPlace }}
                        </td>
                        <td>
                          {{ collaborator.address.neighborhood }}
                        </td>
                        <td>
                          {{ collaborator.address.city.name }}
                        </td>
                        <td>
                          {{ collaborator.address.city.state.name }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-md-12">
                      <span *ngIf="!collaborators.length" class="label label-danger label-mini">Nenhum Colaborador
                        encontrado.</span>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="card-footer">
              <div class="row mt-3 mb-3">
                <div class="col-md-3"></div>
                <div class="col-md-3 text-right">
                  <a
                    routerLink="/maintenance/covenant/edit/{{ covenant.id }}"
                    class="btn btn-warning"
                    >Editar</a
                  >
                </div>
                <div class="col-md-3">
                  <a routerLink="/maintenance/covenant" class="btn btn-info"
                    >Voltar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
