import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CovenantRequestValue } from 'src/app/domain/covenant-request-value';
import { CovenantRequestValueDTO } from 'src/app/domain/dto/covenant-request-value.dto';
import { Pagination } from 'src/app/domain/pagination';

@Injectable({
  providedIn: 'root'
})
export class CovenantRequestValueService {

  url: string = `${environment.BASE_URL}/covenants`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCovenantRequestValues(id: number, pagination: Pagination, covenantRequestValue?: CovenantRequestValue) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      validity: `${covenantRequestValue?.validity ?? ''}`,
      destiny: `${covenantRequestValue?.destiny ?? ''}`,
      percentage: `${covenantRequestValue?.percentage ?? ''}`,
      value: `${covenantRequestValue?.value ?? ''}`,
    }

    return this.http.get<CovenantRequestValue>(`${this.url}/${id}/request-values`,
      { responseType: "json", params: params });
  }

  save(covenantId: number, covenantRequestValue: CovenantRequestValue) {
    let dto = new CovenantRequestValueDTO(covenantRequestValue);
    return this.http.post(`${this.url}/${covenantId}/request-values`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  update(covenantId: number, covenantRequestValue: CovenantRequestValue) {
    let dto = new CovenantRequestValueDTO(covenantRequestValue);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${covenantId}/request-values/${covenantRequestValue.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  delete(covenantId: number, covenantRequestValueId: number) {
    return this.http.delete(`${this.url}/${covenantId}/request-values/${covenantRequestValueId}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
