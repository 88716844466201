import { Covenant } from '../covenant';
import { PaymentMethod } from '../paymentMethod';
import { AddressDTO } from './address.dto';


export class CovenantDTO {
  name: string;
  cpfCnpj: string;
  stateRegistration: string;
  municipalRegistration: string;
  phone: string;
  whatsapp: string;
  contact: string;
  emailContact: string;
  email: string;
  address: AddressDTO;
  paymentMethods: Array<PaymentMethod>;
  active: boolean;
  invoice: boolean;
  shareRun: number;

  constructor(covenant: Covenant) {
    this.name = covenant.name;
    this.cpfCnpj = covenant.cpfCnpj;
    this.stateRegistration = covenant.stateRegistration;
    this.municipalRegistration = covenant.municipalRegistration;
    this.phone = covenant.phone;
    this.whatsapp = covenant.whatsapp;
    this.contact = covenant.contact;
    this.emailContact = covenant.emailContact;
    this.email = covenant.email;
    this.address = new AddressDTO(covenant.address);
    this.paymentMethods = covenant.covenantPaymentMethods;
    this.active = covenant.active;
    this.invoice = covenant.invoice;
    this.shareRun = covenant.shareRun;
  }
}
