import { CostCenter } from './cost-center';
import { Bank } from './bank';
import { Client } from './client';
import { Covenant } from './covenant';
import { BankAccount } from './bank-account';

export class BillsToReceive {
  id: number;
  invoiceNumber: string;
  dueDate: Date;
  value: number;
  paymentType: string;
  paymentMethod: string;
  paid: boolean;
  bank: Bank;
  bankCheckNumber: string;
  costCenter: CostCenter;
  client: Client;
  covenant: Covenant;
  bankAccount: BankAccount;
  parcels: any[];
}
