import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { TripRequest } from 'src/app/domain/trip-request';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CovenantTripRequestService {

  url: string = `${environment.BASE_URL}/covenants/run-requests`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getTripRequests(filter: any, pagination: Pagination) {
    let getActive = filter.active ?? "";
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        cost_center: `${filter.costCenter ?? ""}`,
        payment_method: `${filter.paymentMethod ?? ""}`,
        date_start: `${filter.dateStart ?? ""}`,
        date_end: `${filter.dateEnd ?? ""}`,
        driver: `${filter.driver ?? ""}`,
        vehicle: `${filter.vehicle ?? ""}`,
        status: `${filter.status ?? ""}`,
        active: getActive
      }
    });
    return this.http.get(`${this.url}/all`,
      { responseType: "json", params: params });

  }
  getTripRequest(id: number) {
    return this.http.get<TripRequest>(`${this.url}/${id}`,
      { responseType: "json" });
  }
  cancel(data, id) {
    return this.http.put(`${this.url}/cancel/${id}`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  save(data) {
    return this.http.post(`${this.url}`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  exportTripRequests(filter: any, type: string) {
    let getActive = filter.active ?? "";
    const params = new HttpParams({
      fromObject: {
        cost_center: `${filter.costCenter ?? ""}`,
        payment_method: `${filter.paymentMethod ?? ""}`,
        date_start: `${filter.dateStart ?? ""}`,
        date_end: `${filter.dateEnd ?? ""}`,
        driver: `${filter.driver ?? ""}`,
        vehicle: `${filter.vehicle ?? ""}`,
        status: `${filter.status ?? ""}`,
        active: getActive,
        type: type
      }
    });
    return this.http.get(`${this.url}/export`, {
      headers: this.httpHeaders,
      responseType: "blob",
      params: params,
      observe: 'response'
    });
  }
}
