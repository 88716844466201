import { Address } from '../address';


export class AddressDTO {

    zipCode: string;
    publicPlace: string;
    neighborhood: string;
    cityId: number;

    constructor(address: Address) {
        this.zipCode = address.zipCode;
        this.publicPlace = address.publicPlace;
        this.neighborhood = address.neighborhood;
        this.cityId = address.city.id;
    }
}