import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CovenantUser } from 'src/app/domain/coventant-user';
import { Pagination } from 'src/app/domain/pagination';
import { CovenantUserDTO } from 'src/app/domain/dto/covenant-user.dto';

@Injectable({
  providedIn: 'root'
})
export class CovenantUserService {

  url: string = `${environment.BASE_URL}/covenants`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCovenantUsers(covenantId: number, pagination: Pagination, covenantUser: CovenantUser) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${covenantUser?.name ?? ''}`,
      role: `${covenantUser?.role ?? ''}`,
    };

    return this.http.get(`${this.url}/${covenantId}/users`,
      { responseType: "json", params: params });

  }

  getCovenantUser(covenantId: number, id: number) {
    return this.http.get<CovenantUser>(`${this.url}/${covenantId}/users/${id}`,
      { responseType: "json" });
  }

  save(covenantId: number, covenantUser: CovenantUser) {
    let dto = new CovenantUserDTO(covenantUser);

    return this.http.post(`${this.url}/${covenantId}/users`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  activeAccount(data) {
    return this.http.put(`${this.url}/users/active/token`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  update(covenantId: number, covenantUser: CovenantUser) {
    let dto = new CovenantUserDTO(covenantUser);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${covenantId}/users/${covenantUser.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  delete(covenantId: number, id: number) {
    return this.http.delete(`${this.url}/${covenantId}/users/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(covenantId: number, id: number) {
    return this.http.put(`${this.url}/${covenantId}/users/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(covenantId: number, id: number) {
    return this.http.put(`${this.url}/${covenantId}/users/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
