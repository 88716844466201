import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CovenantDashboardService {
  url: string = `${environment.BASE_URL}/covenant/dashboard`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getDashboardData() {
    return this.http.get(`${this.url}`,
      { responseType: "json" });
  }
}
