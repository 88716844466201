<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <!-- <div class="container ml-2">
                            <div class="row">
                                <div class="col page-title">
                                    <label>Gráfico de Solicitações</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card card-topline-lightblue">
                                        <div class="card-head">
                                            <header>BAR CHART</header>
                                            <div class="tools">
                                                <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                                <a class="t-collapse btn-color fa fa-chevron-down"
                                                    href="javascript:;"></a>
                                            </div>
                                        </div>
                                        <div class="card-body " id="chartjs_bar_parent">
                                            <div class="row">
                                                <canvas id="chartjs_bar"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
            <div class="card-body">
              <div class="row">
                <h2>Corridas</h2>
              </div>
              <div class="row" [formGroup]="searchForm">
                <div class="col-sm-3">
                  <label style="font-size: 14px">Início</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="dateStart"
                  />
                </div>
                <div class="col-sm-3">
                  <label style="font-size: 14px">Fim</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="dateEnd"
                  />
                </div>
                <div class="col-sm-3">
                  <label style="font-size: 14px">Status</label>
                  <select
                    class="custom-select custom-select"
                    style="font-size: 14px"
                    formControlName="status"
                  >
                    <option [value]="''">Status</option>
                    <option [value]="'scheduled'">Agendada</option>
                    <option [value]="'running'">Em Andamento</option>
                    <option [value]="'finished'">Finalizada</option>
                    <option [value]="'canceled'">Cancelada</option>
                  </select>
                </div>

                <div class="col trip-request__button__search--div">
                  <button
                    (click)="onSearch()"
                    class="btn btn-info mr-3"
                    title="Buscar"
                  >
                    <i
                      class="fa fa-search fa-lg trip-request__button__search"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    (click)="exportTripRequests('pdf')"
                    class="btn btn-danger mr-3"
                    title="Exportar para PDF"
                  >
                    <i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
                  </button>
                  <button
                    (click)="exportTripRequests('excel')"
                    class="btn btn-success"
                    title="Exportar para Excel"
                  >
                    <i class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div class="table-responsive-md">
                <div class="row mb-3 ml-4 mt-3">
                  <button (click)="shared()" class="btn btn-success">
                    Compartilhar Corrida
                  </button>
                </div>
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th><i class="fa fa-share-alt"></i></th>
                      <th>Cód.</th>
                      <th>Solicitação</th>
                      <th>Data</th>
                      <th>Hora</th>
                      <!-- <th>Cliente/Convênio</th> -->
                      <!-- <th>Pagamento</th> -->
                      <!-- <th>Compartilhada</th> -->
                      <th>Destino</th>
                      <th>Veículo</th>
                      <th>Motorista</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let run of runs">
                      <td>
                        <input
                          (change)="onCheckboxChange($event, run.id)"
                          type="checkbox"
                          *ngIf="run.status == 'Agendada'"
                        />
                      </td>
                      <td>
                        {{ run.id }}
                      </td>
                      <td>
                        <a
                          class="btn btn-outline-info"
                          href="/convenio/covenant-trip-request/{{
                            run.runRequest.id
                          }}"
                        >
                          #{{ run.runRequest.id }}
                        </a>
                      </td>
                      <td>
                        {{ run.date | date : "dd/MM/yyyy" }}
                      </td>
                      <td>{{ run.startHour }}</td>
                      <!-- <td>
                                                <span *ngIf="run.runRequest.client">
                                                    {{ run.runRequest.client.name }}</span>
                                                <span *ngIf="run.runRequest.covenant">
                                                    {{ run.runRequest.covenant.name }}</span>
                                            </td> -->
                      <!-- <td>
                                                {{ run.runRequest.paymentMethod.name }}
                                            </td> -->
                      <td>{{ run.runRequest.requestValue.destiny }}</td>
                      <td>{{ run.vehicle?.model.name }}</td>
                      <td>{{ run.driver?.name }}</td>
                      <td>
                        {{ run.status }}
                      </td>
                      <td>
                        <div class="row">
                          <a
                            href="/convenio/run/view/{{ run.id }}"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <button
                            *ngIf="run.status == 'Agendada'"
                            (click)="
                              updateRun(run.id, run.startHour, run.endHour)
                            "
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          <!-- <a *ngIf="run.status == 'Agendada'" href="/convenio/run/{{run.id}}/link-vehicle"
                            class="btn btn-warning btn-xs" alt="Vincular Veículo">
                            <i class="fa fa-car"></i>
                          </a> -->
                          <button
                            *ngIf="run.status == 'Agendada'"
                            (click)="cancelRun(run.id)"
                            class="btn btn-danger btn-xs"
                            alt="Cancelar Corrida"
                          >
                            <i class="fa fa-ban"></i>
                          </button>

                          <!-- <a class="btn btn-success btn-xs">
                                                        <i class="fa fa-random"></i>
                                                    </a> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-12">
                    <span
                      *ngIf="!runs.length"
                      class="label label-danger label-mini"
                      >Nenhuma Corrida encontrada.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item" [class.disabled]="!first">
                      <button
                        class="page-link"
                        (click)="onFirst()"
                        tabindex="-1"
                      >
                        Primeira
                      </button>
                    </li>
                    <li class="page-item" *ngIf="pagination.page != 1">
                      <button (click)="onPrevious()" class="page-link">
                        {{ +pagination.page - 1 }}
                      </button>
                    </li>
                    <li class="page-item active">
                      <button class="page-link">{{ pagination.page }}</button>
                    </li>
                    <li class="page-item" *ngIf="last">
                      <button (click)="onNext()" class="page-link" href="#">
                        {{ +pagination.page + 1 }}
                      </button>
                    </li>
                    <li class="page-item" [class.disabled]="!last">
                      <button class="page-link" (click)="onLast()">
                        Última
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-md-12">
                Mostrando página {{ pagination.page }} de
                {{ pagination.totalPages }}
                dos
                {{ pagination.totalResults }} Resultados
              </div>
            </div>
            <header class="mt-5 ml-4 mr-4">
              <div class="row">
                <div class="col page-title">
                  <label>Gráfico de Solicitações de Corrida</label>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-topline-lightblue">
                        <div class="card-head">
                          <!-- <header>BAR CHART</header> -->
                        </div>
                        <div class="card-body">
                          <div style="display: block">
                            <canvas
                              baseChart
                              [datasets]="barChartData"
                              [labels]="barChartLabels"
                              [options]="barChartOptions"
                              [plugins]="barChartPlugins"
                              [legend]="barChartLegend"
                              [chartType]="barChartType"
                            >
                            </canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                                            <div class="card card-topline-lightblue">
                                                <div class="card-head">


                                                </div>
                                                <div class="card-body">
                                                    <div style="display: block;">
                                                        <canvas baseChart width="400" height="400"
                                                            [datasets]="lineChartData" [labels]="lineChartLabels"
                                                            [options]="lineChartOptions" [colors]="lineChartColors"
                                                            [legend]="lineChartLegend" [chartType]="lineChartType"
                                                            [plugins]="lineChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
                <!-- <div class="col page-title">
                                    <label>Custos</label>
                                    <img class="client__image" src="assets/img/costs.png">
                                </div> -->
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
