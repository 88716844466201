import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CostCenter } from 'src/app/domain/cost-center';
import { CostCenterDTO } from 'src/app/domain/dto/cost-center.dto';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CostCenterService {
  url: string = `${environment.BASE_URL}/cost-centers`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCostCenters(costCenter: CostCenter, pagination: Pagination) {
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        name: `${costCenter.name ?? ''}`,
        active: `${costCenter.active ?? ''}`,
        type: `${costCenter.type ?? ''}`,
      }
    });
    return this.http.get(this.url,
      { responseType: "json", params: params });

  }
  getAllCostCenters() {
    return this.http.get<Array<CostCenter>>(`${this.url}/all-active`,
      { responseType: "json" });

  }
  getCostCenter(id: number) {
    return this.http.get<CostCenter>(`${this.url}/${id}`,
      { responseType: "json" });
  }
  getIdentifier(id: number) {
    let getIdentifierUrl = `${this.url}/get/identifier`;
    if (id) {
      getIdentifierUrl += `?parent_id=${id}`;
    }
    return this.http.get(getIdentifierUrl,
      { responseType: "json" });
  }
  save(accessProfile: CostCenter) {
    let dto = new CostCenterDTO(accessProfile);
    dto.active = true;

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(accessProfile: CostCenter) {
    let dto = new CostCenterDTO(accessProfile);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${accessProfile.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

}
