import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { User } from 'src/app/domain/user';
import { environment } from 'src/environments/environment';
import { UserDTO } from 'src/app/domain/dto/user.dto';
import { AlterPassword } from 'src/app/domain/alter-password';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url: string = `${environment.BASE_URL}/users`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getUsers(user: User, pagination: Pagination) {
    const params = {
      page: `${pagination.page}`,
      per_page: `${pagination.per_page}`,
      name: `${user.name}`,
      email: `${user.email}`,
      login: `${user.login}`,
      active: `${user.active ?? ''}`
    };
    return this.http.get(this.url,
      { responseType: "json", params: params });

  }
  getUser(id: number) {
    return this.http.get<User>(`${this.url}/${id}`,
      { responseType: "json" });

  }
  save(User: User) {
    let dto = new UserDTO(User);
    dto.active = true;

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(User: User) {
    let dto = new UserDTO(User);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${User.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  alterPassword(alterPassword: AlterPassword) {
    return this.http.put(`${this.url}/${alterPassword.id}/alter_password`, JSON.stringify(alterPassword),{
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

}
