import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  url: string = `${environment.BASE_URL}/notification`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getAllNotification() {
    return this.http.get(`${this.url}`, {
      responseType: 'json',
    });
  }

  updateToRead(id: number) {
    return this.http.post(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
