import { Permission } from '../permission';
import { AccessProfile } from '../access-profile';


export class AccessProfileDTO {

   name: String;
   description: String;
   active: boolean;
   permissions: Array<Permission> = [];


   constructor(accessProfile: AccessProfile) {
      this.name = accessProfile.name;
      this.description = accessProfile.description;
      this.permissions = accessProfile.permissions;
      this.active = accessProfile.active;
   }
}