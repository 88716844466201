import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-payment-driver',
  templateUrl: './payment-driver.component.html',
  styleUrls: ['./payment-driver.component.css']
})
export class PaymentDriverComponent implements OnInit {

  form: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.createForm()
  }

  onSubmit() {
    console.log(this.form.value)
  }

  createForm() {
    this.form = new FormGroup({
      findNumber: new FormControl(),
      driver: new FormControl(),
      paymentType: new FormControl(),
      status: new FormControl(),
    })
  }
}
