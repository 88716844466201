import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantRunsService } from 'src/app/service/covenant-runs/covenant-runs.service';
import { PaymentMethodsService } from 'src/app/service/payment-methods/payment-methods.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-share-trip',
  templateUrl: './covenant-share-trip.component.html',
  styleUrls: ['./covenant-share-trip.component.css']
})
export class CovenantShareTripComponent implements OnInit {

  selected = [];
  runs = [];
  form: FormGroup;
  messageError = '';
  paymentMethods = [];
  costCenters = [];

  constructor(
    private service: CovenantRunsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private servicePaymentMethods: PaymentMethodsService,
    private serviceCostCenter: CostCenterService,
    private router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.r == null || params.r == undefined) {
        Swal.fire('Erro', 'Você deve selecionar pelo menos duas corridas para serem compartilhadas', 'error');
        this.router.navigate(['/convenio']);
      } else {
        this.selected = params.r.split(',');
        console.log(this.selected);

        if (this.selected.length == 1) {
          Swal.fire('Erro', 'Você deve selecionar pelo menos duas corridas para serem compartilhadas', 'error');
          this.router.navigate(['/convenio']);
        }

      }
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.selected.forEach(run => {
      this.service.getRun(run).subscribe(data => {
        if (data['status'] != 'scheduled') {
          Swal.fire('Erro', `Todas as corridas devem estar com status de Agendada para serem compartilhadas`, 'error');
          this.router.navigate(['/convenio']);
        } else {
          this.runs.push(data);
          this.servicePaymentMethods.getPaymentMethods().subscribe(data => this.paymentMethods = data);
          this.serviceCostCenter.getAllCostCenters().subscribe(data => this.costCenters = data);
        }
      },
        error => {
          Swal.fire('Erro', `Corrida ${run} não encontrada`, 'error');
          this.router.navigate(['/convenio']);
        });
    });
  }
  createForm() {
    this.form = this.fb.group({
      paymentMethod: ['', [Validators.required]],
      costCenter: ['', []],
    });
  }

  onSubmit() {
    const runs = [];
    this.messageError = "";

    this.runs.forEach(run => {
      runs.push({ id: run.id });
    });

    const share = {
      paymentMethod: { id: this.form.controls.paymentMethod.value },
      costCenter: this.form.controls.costCenter.value,
      runs,
    };
    this.service.shared(share).subscribe(data => {
      Swal.fire('Sucesso', `Corridas Compartilhadas com sucesso`, 'success');
      this.router.navigate(['/convenio']);
    },
      ({ error }) => {
        console.log(error);
        error.errors.forEach((erro) => {
          this.messageError += `${erro.message}\n`;
        })
        Swal.fire('Erro', `Não foi possível compartilhar solicitações.`, 'error');
      });
  }

}
