import { CarService } from 'src/app/service/car/car.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Car } from 'src/app/domain/car';
import { Driver } from 'src/app/domain/driver';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-trip-approval',
  templateUrl: './trip-approval.component.html',
  styleUrls: ['./trip-approval.component.css']
})
export class TripApprovalComponent implements OnInit {

  id: number;
  loading: boolean = true;
  tripRequest;
  messageError: string = '';
  drivers: Array<Driver> = [];
  vehicles: Array<Car> = [];
  linkForm: FormGroup;

  constructor(
    private service: TripRequestService,
    private route: ActivatedRoute,
    private serviceCar: CarService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getTripRequest();
    this.createForm();
    this.getCars();
  }

  createForm() {
    this.linkForm = this.fb.group({
      vehicle: ['', Validators.required],
      driver: ['', Validators.required]
    });
  }

  getCars() {
    this.serviceCar.getCarsActives().subscribe(data => {
      this.vehicles = data;
      console.log(data);

    }, error => {
      Swal.fire('Erro', 'Erro ao carregar Veículos', 'error');
    });
  }

  getTripRequest() {
    this.service.getTripRequest(this.id).subscribe(data => {
      this.tripRequest = data;
      this.loading = false;
      console.log(this.tripRequest);

      if (this.tripRequest.status != 'waiting' && this.tripRequest.status != 'rejected') {
        Swal.fire('Atenção!!', 'Só podem Vincular Motoristas de Veículos com Solicitações com Status de Aguardando ou Rejeitada!!', 'warning');
        this.router.navigate(['process/trip-request']);
      }

      if (this.tripRequest.vehicle?.id) {
        this.linkForm.controls.vehicle.setValue(this.tripRequest.vehicle.id);
        this.linkForm.controls.driver.setValue(this.tripRequest.driver.id);
        this.getDriversByCar();
      }

    }, error => {
      this.loading = false;
      Swal.fire("Erro", "Não foi possível carregar solicitação!", "error");
    });
  }

  getDriversByCar() {
    this.serviceCar.getDriversByCar(this.linkForm.controls.vehicle.value).subscribe(data => {
      this.drivers = data;
      this.loading = false;
    }, error => {
      this.loading = false;
      Swal.fire('Erro', 'Não foi possível carregar os motoristas deste veículo! Selecione o Veículo novamente.', 'error');
    });
  }

  onSelectedVehicle(e) {
    if (this.linkForm.controls.vehicle.value.length) {
      this.loading = true;
      this.getDriversByCar();
    }
  }

  onAproved() {
    this.loading = true;

    const value = {
      vehicle: { id: this.linkForm.controls.vehicle.value },
      driver: { id: this.linkForm.controls.driver.value }
    };

    this.service.linkDriver(value, this.id).subscribe(data => {

      this.service.approveRunRequest(this.id).subscribe(data => {
        this.loading = false;
        Swal.fire('Sucesso', 'Solicitação Aprovada com Sucesso!!', 'success');
        this.router.navigate(['process/trip-request']);
      }, ({ error }) => {
        this.loading = false;
        error.errors.forEach(err => {
          this.messageError += `${err.message}\n`;
        });
        // Swal.fire('Erro', 'Erro ao Aprovar Solicitação!!', 'error');
      });
    }, error => {

      this.loading = false;
      Swal.fire('Erro', 'Erro ao adicionar Motorista à Solicitação', 'error');
    });


  }

}
