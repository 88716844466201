<swal
  *ngIf="modalFireCondition"
  [swalFireOnInit]="true"
  (willOpen)="onFormUpdate()"
  (didClose)="modalFireCondition = false"
  [title]="collaborator?.id ? 'Editar Colaborador' : 'Cadastrar Colaborador'"
  [showConfirmButton]="false"
  [showCloseButton]="false"
  [showDenyButton]="false"
  [width]="900"
>
  <!-- This form will be displayed as the alert main content
       Targets the alert's main content zone by default -->
  <form *swalPortal="swalTargets.content" [formGroup]="form" class="text-left">
    <div class="row" *ngIf="form">
      <div class="col-md-6">
        <div class="form-group">
          <label for="name">Nome</label>
          <input
            type="text"
            class="form-control"
            id="name"
            formControlName="name"
            placeholder="Nome"
          />
          <ng-container *ngIf="form.get('name').touched">
            <span
              *ngIf="form.controls.name?.errors?.required"
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.name.errors &&
                form.controls.name?.errors?.maxlength
              "
              class="text-danger position-right"
              >*Nome do colaborador deve ter no máximo 100 caracteres</span
            >
            <span
              *ngIf="
                form.controls.name.errors && form.controls.name?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.name?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            type="text"
            class="form-control"
            placeholder="E-mail"
            formControlName="email"
          />
          <span *ngIf="form.get('email').touched">
            <span
              *ngIf="form.controls.email?.errors?.required"
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                !form.controls.email?.errors?.required &&
                form.controls.email?.errors?.maxlength
              "
              class="text-danger position-right"
              >*Email deve ter no máximo 60 caracteres</span
            >
            <span
              *ngIf="
                !form.controls.email?.errors?.maxlength &&
                form.controls.email?.errors?.email
              "
              class="text-danger position-right"
              >*Não é um email válido!</span
            >
            <span
              *ngIf="
                !form.controls.email?.errors?.email &&
                form.controls.email?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.email?.errors?.invalid }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="role">Cargo</label>
          <input
            type="text"
            class="form-control"
            id="role"
            formControlName="role"
            placeholder="Cargo"
          />
          <div *ngIf="form.get('role').touched">
            <span
              *ngIf="form.controls.role?.errors?.required"
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.role.errors &&
                form.controls.role?.errors?.maxlength
              "
              class="text-danger position-right"
              >*Cargo deve ter no máximo 18 caracteres</span
            >
            <span
              *ngIf="
                form.controls.role.errors && form.controls.role?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.role?.errors?.invalid }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="department">Departamento</label>
          <input
            type="text"
            class="form-control"
            id="department"
            formControlName="department"
            placeholder="Departamento"
          />
          <span
            *ngIf="
              form.controls.department.errors &&
              form.controls.department?.errors?.required
            "
            class="text-danger position-right"
            >*Campo Obrigatório</span
          >
          <span
            *ngIf="
              form.controls.department.errors &&
              form.controls.department?.errors?.maxlength
            "
            class="text-danger position-right"
            >*Departamento deve ter no máximo 30 caracteres</span
          >
          <span
            *ngIf="
              form.controls.department.errors &&
              form.controls.department?.errors?.invalid
            "
            class="text-danger position-right"
            >*
            {{ form.controls.department?.errors?.invalid }}
          </span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="costCenter">Centro de Custo</label>
          <input
            type="text"
            class="form-control"
            id="costCenter"
            formControlName="costCenter"
            placeholder="Centro de Custo"
          />
          <span
            *ngIf="
              form.controls.costCenter.errors &&
              form.controls.costCenter?.errors?.invalid
            "
            class="text-danger position-right"
            >*
            {{ form.controls.costCenter?.errors?.invalid }}
          </span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="whatsapp">WhatsApp</label>
          <input
            type="text"
            class="form-control"
            mask="+00 00 0 0000-0000"
            id="whatsapp"
            formControlName="whatsapp"
            placeholder="WhatsApp"
          />
          <ng-container *ngIf="form.get('whatsapp').touched">
            <span
              *ngIf="
                form.controls.whatsapp.errors &&
                form.controls.whatsapp?.errors?.required
              "
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.whatsapp.errors &&
                form.controls.whatsapp?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.whatsapp?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="requestAppRace">Solicita Corrida APP</label>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="false"
              [value]="false"
              name="requestAppRace"
              formControlName="requestAppRace"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="false">Não</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="true"
              [value]="true"
              name="requestAppRace"
              formControlName="requestAppRace"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="true">Sim</label>
          </div>
          <span *ngIf="form.get('requestAppRace').touched">
            <span
              *ngIf="form.controls.requestAppRace?.errors?.required"
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="form.controls.requestAppRace?.errors?.invalid"
              class="text-danger position-right"
              >*
              {{ form.controls.requestAppRace?.errors?.invalid }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="zipCode">CEP</label>
          <input
            type="text"
            class="form-control cep"
            (blur)="onBlurCep($event)"
            id="zipCode"
            formControlName="zipCode"
            placeholder="CEP"
          />
          <ng-container *ngIf="form.get('zipCode').touched">
            <span
              *ngIf="
                form.controls.zipCode.errors &&
                form.controls.zipCode?.errors?.required
              "
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.zipCode.errors &&
                form.controls.zipCode?.errors?.maxlength
              "
              class="text-danger position-right"
              >*CEP deve ter no máximo 11 caracteres</span
            >
            <span
              *ngIf="
                form.controls.zipCode.errors &&
                form.controls.zipCode?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.zipCode?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label for="publicPlace">Logradouro</label>
          <input
            type="text"
            class="form-control"
            id="publicPlace"
            formControlName="publicPlace"
            placeholder="Rua / Complemento / Número"
          />
          <ng-container *ngIf="form.get('publicPlace').touched">
            <span
              *ngIf="
                form.controls.publicPlace.errors &&
                form.controls.publicPlace?.errors?.required
              "
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.publicPlace.errors &&
                form.controls.publicPlace?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.publicPlace?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="neighborhood">Bairro</label>
          <input
            type="text"
            class="form-control"
            id="neighborhood"
            formControlName="neighborhood"
            placeholder="Bairro"
          />
          <ng-container *ngIf="form.get('neighborhood').touched">
            <span
              *ngIf="
                form.controls.neighborhood.errors &&
                form.controls.neighborhood?.errors?.required
              "
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.neighborhood.errors &&
                form.controls.neighborhood?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.neighborhood?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Estado </label>
          <select
            (change)="onSelectedState($event)"
            class="form-control select2"
            formControlName="stateId"
          >
            <option [value]="null" disabled>Selecione um Estado</option>
            <option
              [value]="state.id"
              *ngFor="let state of states"
              [selected]="form.get('stateId').value == state.id"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Cidade </label>
          <select class="form-control select2" formControlName="cityId">
            <option [value]="null" disabled>Selecione uma Cidade</option>
            <option
              [value]="city.id"
              *ngFor="let city of cities"
              [selected]="form.get('cityId').value == city.id"
            >
              {{ city.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center mt-3">
      <button (click)="onSubmit()" class="btn btn-success">Salvar</button>

      <a type="button" (click)="onClose()" class="btn btn-danger ml-3">
        Cancelar
      </a>
    </div>
  </form>
</swal>
