import { TripRequest } from './trip-request';

export class TripRequestSchedule {
    runRequestSchedule: {
        id: number,
        startDate: string,
        startHour: string,
        endDate: string,
        endHour: string,
        active: boolean,
        runRequest: TripRequest,
        runRequestScheduleRecursive: {
            id: number,
            sunday: boolean,
            monday: boolean,
            tuesday: boolean,
            wednesday: boolean,
            thursday: boolean,
            friday: boolean,
            saturday: boolean,
            active: boolean,
            scheduleId: number
        }
    }

}