<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar Cartão de Crédito</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Número</b>
                    </div>
                    <div class="col-md-12">
                      {{ creditCard.number }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Bandeira</b>
                    </div>
                    <div class="col-md-12">
                      <img
                        class="img-thumbnail"
                        width="100px"
                        src="{{ creditCard.flag }}"
                        alt="Bandeira"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Status</b>
                    </div>
                    <div class="col-md-12">
                      <span
                        *ngIf="creditCard.active"
                        class="label label-sucess label-mini"
                        >Ativo</span
                      >
                      <span
                        *ngIf="!creditCard.active"
                        class="label label-danger label-mini"
                        >Inativo</span
                      >
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row col-md-12">
                <div class="col-md-3"></div>
                <div class="col-md-3 text-right">
                  <button (click)="onDelete(creditCard)" class="btn btn-danger">
                    Excluir
                  </button>
                </div>
                <div class="col-md-3">
                  <a routerLink="/parameters/credit-card" class="btn btn-info">Voltar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
