<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper" id="printSection">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col print">
              <div class="page-title text-center">
                RECIBO DE PAGAMENTO - ACERTO MOTORISTA
              </div>
            </div>
            <div class="col-md-10 hidden-print">
              <div class="page-title">Visualizar Acerto com Motorista</div>
            </div>
            <div class="col-md-2 hidden-print text-right">
              <button
                class="btn btn-info trip-request__button__search"
                title="Imprimir"
                [useExistingCss]="true"
                printTitle="{{ 'Acerto Motorista ' + driver.name }}"
                printSectionId="printSection"
                ngxPrint
              >
                <i
                  class="fa fa-print fa-lg trip-request__button__search"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <header style="width: 100%">
                <div class="row mt-2">
                  <div class="col-sm-4">
                    <p class="ml-3">Período de Geração</p>
                    <div class="border row p-2 ml-1">
                      <div class="col-sm-6">
                        <p>Data Inicial</p>
                        <input
                          disabled
                          type="date"
                          class="form-control"
                          [(ngModel)]="initialDate"
                        />
                      </div>
                      <div class="col-sm-6">
                        <p>Data Final</p>
                        <input
                          disabled
                          type="date"
                          class="form-control"
                          [(ngModel)]="finalDate"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 align-self-end">
                    <label style="font-size: 14px" class="mt-2"
                      >Motorista</label
                    >
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      [(ngModel)]="driver.name"
                    />
                  </div>
                  <div class="col-sm-4 align-self-end">
                    <label style="font-size: 14px" class="mt-2">
                      Desconto da Taxa do Convênio para Cartão de Crédito
                    </label>
                    <label
                      for="issueInvoiceToCreditCard_yes"
                      style="font-size: 14px"
                      class="mt-2"
                    >
                      <input
                        type="radio"
                        class="checkbox"
                        id="issueInvoiceToCreditCard_yes"
                        [value]="true"
                        [(ngModel)]="issueInvoiceToCreditCard"
                      />
                      Sim</label
                    >
                    <label
                      for="issueInvoiceToCreditCard_no"
                      style="font-size: 14px"
                      class="mt-2"
                    >
                      <input
                        type="radio"
                        class="checkbox"
                        id="issueInvoiceToCreditCard_no"
                        [value]="false"
                        [(ngModel)]="issueInvoiceToCreditCard"
                      />
                      Não</label
                    >
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Cod</th>
                      <th>Data / Hora</th>
                      <th>Lançamento</th>
                      <th>Emitir Nota Fiscal</th>
                      <th>Tipo</th>
                      <th>Método de pagamento</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let launch of launchs">
                    <tr>
                      <td>
                        {{ launch.id }}
                      </td>
                      <td>
                        {{ launch.date | date : "dd/MM/yyyy" }}
                        {{ launch.startHour }}
                      </td>
                      <td>
                        {{ launch.runRequest.costCenter?.type }}
                      </td>
                      <td>
                        {{
                          launch.runRequest.covenant?.invoice
                            ? "Emitir Nota Fiscal"
                            : ""
                        }}
                      </td>
                      <td>
                        {{ launch.runRequest.requestValue.type }}
                      </td>
                      <td>
                        {{ launch.runRequest.paymentMethod?.name }}
                      </td>
                      <td>
                        {{ launch.runEndValue | currency : "BRL" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card">
                <h3 class="ml-4"><b>Total de Créditos</b></h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered custom-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Descriçao</th>
                          <th>Emitir Nota Fiscal</th>
                          <th>Valor</th>
                          <th>Desconto Cartão</th>
                          <th>Desconto Convênio</th>
                          <th>Valor Final</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of credits">
                          <td>{{ item.description }}</td>
                          <td>
                            {{ item.issue_invoice ? "Emitir Nota Fiscal" : "" }}
                          </td>
                          <td>
                            {{ item.value | currency : "BRL" }}
                          </td>
                          <td>
                            {{ item.cardDiscount }}
                          </td>
                          <td>
                            {{ item.covenantDiscount }}
                          </td>
                          <td>
                            {{ item.finalValue | currency : "BRL" }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"><b>TOTAL DE CRÉDITOS:</b></td>
                          <td>{{ totalValueCredit | currency : "BRL" }}</td>
                          <td></td>
                          <td></td>
                          <td>{{ finalValueCredit | currency : "BRL" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- TOTAL ABASTECIMENTOS -->
              <div class="card" *ngIf="fuelSupplies.length">
                <h3 class="ml-4"><b>Abastecimentos</b></h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered custom-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Hora</th>
                          <th>Veículo</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of fuelSupplies">
                          <td>
                            {{ item.date | date : "dd/MM/yyyy" }}
                          </td>
                          <td>
                            {{ item.hour }}
                          </td>
                          <td>
                            {{ item.vehicle.model.name }} -
                            {{ item.vehicle.carPlate }}
                          </td>
                          <td>
                            {{ item.value | currency : "BRL" }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"><b>TOTAL ABASTECIMENTOS</b></td>
                          <td>
                            {{ finalValueFuelSupplies | currency : "BRL" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- TOTAL PEDÁGIOS -->
              <div class="card" *ngIf="tolls.length">
                <h3 class="ml-4"><b>Pedágios</b></h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered custom-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Veículo</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of tolls">
                          <td>
                            {{ item.date | date : "dd/MM/yyyy" }}
                          </td>
                          <td>
                            {{ item.vehicle.model.name }} -
                            {{ item.vehicle.carPlate }}
                          </td>
                          <td>
                            {{
                              item.runRequest.requestValue.tollAmount
                                | currency : "BRL"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"><b>TOTAL PEDÁGIOS</b></td>
                          <td>{{ finalValueToll | currency : "BRL" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- TOTAL GERAL -->
              <div class="card">
                <h3 class="ml-4"><b>Total Geral</b></h3>
                <div class="card-body row">
                  <div class="col-sm-12 border">
                    <div class="table-responsive mt-2">
                      <table
                        class="table table-bordered custom-table table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Descrição</th>
                            <th>+</th>
                            <th>-</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of resume">
                            <td>{{ item.description }}</td>
                            <td>
                              <span *ngIf="item.type === 'credit'">
                                {{ item.value | currency : "BRL" }}
                              </span>
                            </td>
                            <td>
                              <span *ngIf="item.type === 'debit'">
                                {{ item.value | currency : "BRL" }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td><b>TOTAL GERAL</b></td>
                            <td>{{ finalValueCredit | currency : "BRL" }}</td>
                            <td>{{ finalValueDebit | currency : "BRL" }}</td>
                          </tr>
                          <tr>
                            <td colspan="2"><b>TOTAL</b></td>
                            <td>
                              {{
                                finalValueCredit - finalValueDebit
                                  | currency : "BRL"
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row border m-1" *ngIf="newValues.length">
                      <div class="col-sm-12">
                        <h4><b>Valores Avulsos</b></h4>
                        <div class="table-responsive mt-2">
                          <table
                            class="table table-bordered custom-table table-hover"
                          >
                            <thead>
                              <tr>
                                <th>Descrição</th>
                                <th>+</th>
                                <th>-</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let newValue of newValues">
                                <td>
                                  {{ newValue.description }}
                                </td>
                                <td>
                                  <span *ngIf="newValue.type === 'credit'">
                                    {{ newValue.value | currency : "BRL" }}
                                  </span>
                                </td>
                                <td>
                                  <span *ngIf="newValue.type === 'debit'">
                                    {{ newValue.value | currency : "BRL" }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row m-1 mt-3">
                      <div class="col-6">Comissão do Motorista:</div>
                      <div
                        class="col-3"
                        *ngIf="commissionType === 'percentage'"
                      >
                        {{ commission }} %
                      </div>
                      <div class="col-3" *ngIf="commissionType === 'monetary'">
                        {{ commission | currency : "BRL" }}
                      </div>
                      <div class="col-3">
                        {{ commissionValue | currency : "BRL" }}
                      </div>
                    </div>

                    <!-- TOTAL MULTAS -->
                    <div class="row m-1 mt-3" *ngIf="trafficTickets.length">
                      <h4><b>Multas</b></h4>
                      <div class="table-responsive">
                        <table
                          class="table table-bordered custom-table table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Veículo</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of trafficTickets">
                              <td>
                                {{ item.date | date : "dd/MM/yyyy" }}
                              </td>
                              <td>
                                {{ item.vehicle.model.name }} -
                                {{ item.vehicle.carPlate }}
                              </td>
                              <td>
                                {{ item.value | currency : "BRL" }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2"><b>TOTAL MULTAS</b></td>
                              <td>
                                {{ finalValueTrafficTicket | currency : "BRL" }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row m-1 mt-3" *ngIf="refunds.length">
                      <h4><b>Reembolsos</b></h4>
                      <div class="table-responsive">
                        <table
                          class="table table-bordered custom-table table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Descrição</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of refunds">
                              <td>
                                {{ item.description }}
                              </td>
                              <td>
                                {{ item.value | currency : "BRL" }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row m-1 mt-3">
                      <div class="col-6">Valor líquido:</div>
                      <div class="col-3"></div>
                      <div class="col-3">
                        {{ paymentValue | currency : "BRL" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- INFORMACOES DE PAGAMENTO -->
              <div class="card">
                <h3 class="ml-4"><b>Informações de Pagamento</b></h3>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3" *ngIf="paymentType">
                      <label>Forma de Pagamento</label>
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        [(ngModel)]="paymentType"
                      />
                    </div>

                    <div class="col-sm-3" *ngIf="bank">
                      <label>Banco</label>
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Banco"
                        [(ngModel)]="bank"
                      />
                    </div>
                    <div class="col-sm-3" *ngIf="checkNumber">
                      <label>Número do Cheque</label>
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Número do Cheque"
                        [(ngModel)]="checkNumber"
                      />
                    </div>

                    <div class="col">
                      <label>&nbsp;</label>
                      <input disabled type="checkbox" [(ngModel)]="paid" /> Pago
                    </div>
                  </div>
                </div>
                <div class="row col-12 d-flex justify-content-center my-3">
                  <div class="ml-4 hidden-print">
                    <a routerLink="/financial/pay-driver" class="btn btn-info"
                      >Voltar</a
                    >
                  </div>

                  <div class="col print">
                    <div class="text-center">
                      <h4>Anápolis, {{ now }}</h4>
                      <hr class="signature-line" />
                      <h4>{{ driver.name }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
