<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title">Cadastrar Posto de Combustível</div>
                        </div>
                    </div>
                    <div [formGroup]="gasStationForm">
                        <!--div com o form -->
                        <div *ngIf="messageError.length" class="container">
                            <div class="alert alert-danger" role="alert">
                                {{messageError}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control" id="name" formControlName="name"
                                        placeholder="Nome do Posto de Combustível">
                                    <span
                                        *ngIf="gasStationForm.controls.name.errors && gasStationForm.controls.name.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="gasStationForm.controls.name.errors && gasStationForm.controls.name.errors.maxlength"
                                        class="text-danger position-right">*Nome da
                                        Posto de Combustível deve ter
                                        no máximo 60 caracteres</span>
                                    <span
                                        *ngIf="gasStationForm.controls.name.errors && gasStationForm.controls.name.errors.invalid"
                                        class="text-danger position-right">*
                                        {{gasStationForm.controls.name.errors.invalid}} </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="contact">Contato</label>
                                    <input type="text" class="form-control" id="contact" formControlName="contact"
                                        placeholder="Contato">
                                    <span *ngIf="gasStationForm.controls.contact.errors">
                                        <span *ngIf="gasStationForm.controls.contact.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="gasStationForm.controls.contact.errors && gasStationForm.controls.contact.errors.maxlength"
                                            class="text-danger position-right">*Contato deve ter
                                            no máximo 100 caracteres</span>
                                        <span
                                            *ngIf="!gasStationForm.controls.contact.errors.required && gasStationForm.controls.contact.errors.invalid"
                                            class="text-danger position-right">*
                                            {{gasStationForm.controls.contact.errors.invalid}} </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" id="email" formControlName="email"
                                        placeholder="Email do Posto de Combustível">
                                    <span *ngIf="gasStationForm.controls.email.errors">
                                        <span *ngIf="gasStationForm.controls.email.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="!gasStationForm.controls.email.errors.required && gasStationForm.controls.email.errors.maxlength"
                                            class="text-danger position-right">*Email deve ter
                                            no máximo 80 caracteres</span>
                                        <span
                                            *ngIf="!gasStationForm.controls.email.errors.maxlength && gasStationForm.controls.email.errors.email"
                                            class="text-danger position-right">*Não é um email válido!</span>
                                        <span
                                            *ngIf="!gasStationForm.controls.email.errors.email && gasStationForm.controls.email.errors.invalid"
                                            class="text-danger position-right">*
                                            {{gasStationForm.controls.email.errors.invalid}} </span>
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="phone">Telefone</label>
                                    <input type="text" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                                        id="phone" formControlName="phone" placeholder="Telefone">
                                    <span *ngIf="gasStationForm.controls.phone.errors">
                                        <span *ngIf="gasStationForm.controls.phone.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="gasStationForm.controls.phone.errors && gasStationForm.controls.phone.errors.maxlength"
                                            class="text-danger position-right">*Telefone deve ter
                                            no máximo 13 caracteres</span>
                                        <span
                                            *ngIf="!gasStationForm.controls.phone.errors.required && gasStationForm.controls.phone.errors.invalid"
                                            class="text-danger position-right">*
                                            {{gasStationForm.controls.phone.errors.invalid}} </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="whatsapp">Whatsapp</label>
                                    <input type="text" class="form-control" mask="+00 00 0 0000-0000" id="whatsapp"
                                        formControlName="whatsapp" placeholder="Whatsapp">
                                    <span *ngIf="gasStationForm.controls.whatsapp.errors">
                                        <span *ngIf="gasStationForm.controls.whatsapp.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="gasStationForm.controls.whatsapp.errors && gasStationForm.controls.whatsapp.errors.maxlength"
                                            class="text-danger position-right">*Whatsapp deve ter
                                            no máximo 18 caracteres</span>
                                        <span
                                            *ngIf="!gasStationForm.controls.whatsapp.errors.required && gasStationForm.controls.whatsapp.errors.invalid"
                                            class="text-danger position-right">*
                                            {{gasStationForm.controls.whatsapp.errors.invalid}} </span>
                                    </span>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="zipCode">CEP</label>
                                    <input type="text" class="form-control cep" (blur)="onBlurCep($event)" id="zipCode"
                                        formControlName="zipCode" placeholder="CEP">

                                    <span
                                        *ngIf="gasStationForm.controls.zipCode.errors && gasStationForm.controls.zipCode.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="gasStationForm.controls.zipCode.errors && gasStationForm.controls.zipCode.errors.maxlength"
                                        class="text-danger position-right">*CEP deve ter
                                        no máximo 11 caracteres</span>
                                    <span
                                        *ngIf="gasStationForm.controls.zipCode.errors && gasStationForm.controls.zipCode.errors.invalid"
                                        class="text-danger position-right">*
                                        {{gasStationForm.controls.zipCode.errors.invalid}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="publicPlace">Logradouro</label>
                                    <input type="text" class="form-control" id="publicPlace"
                                        formControlName="publicPlace" placeholder="Rua / Complemento / Número">
                                    <span
                                        *ngIf="gasStationForm.controls.publicPlace.errors && gasStationForm.controls.publicPlace.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="gasStationForm.controls.publicPlace.errors && gasStationForm.controls.publicPlace.errors.invalid"
                                        class="text-danger position-right">*
                                        {{gasStationForm.controls.publicPlace.errors.invalid}}
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="neighborhood">Bairro</label>
                                    <input type="text" class="form-control" id="neighborhood"
                                        formControlName="neighborhood" placeholder="Bairro">

                                    <span
                                        *ngIf="gasStationForm.controls.neighborhood.errors && gasStationForm.controls.neighborhood.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="gasStationForm.controls.neighborhood.errors && gasStationForm.controls.neighborhood.errors.invalid"
                                        class="text-danger position-right">*
                                        {{gasStationForm.controls.neighborhood.errors.invalid}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Estado
                                    </label>

                                    <select (change)="onSelectedState($event)" class="form-control  select2"
                                        formControlName="state">
                                        <option value="">
                                            Selecione o Estado </option>
                                        <option value="{{ state.id }}" *ngFor="let state of states">
                                            {{ state.name }} </option>

                                    </select>
                                    <span
                                        *ngIf="gasStationForm.controls.state.errors && gasStationForm.controls.state.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="control-label">Cidade
                                    </label>
                                    <select class="form-control  select2" formControlName="city">
                                        <option value="">
                                            Selecione a Cidade </option>
                                        <option value="{{ city.id }}" *ngFor="let city of cities">
                                            {{ city.name }} </option>

                                    </select>
                                    <span
                                        *ngIf="gasStationForm.controls.city.errors && gasStationForm.controls.city.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="active">Status</label>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="true" value="true" name="active"
                                                formControlName="active" class="custom-control-input">
                                            <label class="custom-control-label" for="true">Ativo</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="false" value="false" name="active"
                                                formControlName="active" class="custom-control-input">
                                            <label class="custom-control-label" for="false">Inativo</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-3 text-right">
                                <button (click)="onSubmit()" [disabled]="!gasStationForm.valid"
                                    class="btn btn-success">Salvar</button>
                            </div>
                            <div class="col-md-3">
                                <a routerLink="/parameters/gas-station" class="btn btn-danger">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
