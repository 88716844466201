<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-title-breadcrumb">
      <div class="ml-3 page-title">Fluxo de Caixa</div>
    </div>
    <div class="card card-topline-green">
      <div class="row mt-2 ml-3 mb-5" [formGroup]="form">
        <div class="col-sm-3">
          <label style="font-size: 14px">Início</label>
          <input type="date" class="form-control" formControlName="dateStart" />
        </div>
        <div class="col-sm-3">
          <label style="font-size: 14px">Fim</label>
          <input type="date" class="form-control" formControlName="dateEnd" />
        </div>
        <div>
          <button
            (click)="getCashFlow()"
            class="btn btn-info mt-4"
            title="Buscar"
          >
            <i class="fa fa-search fa-lg trip-request__button__search"></i>
          </button>
          <button
            (click)="exportCashFlow()"
            class="btn buttons-excel ml-3 mt-4 float-right"
            title="Exportar para PDF"
          >
            <i class="fa fa-file-pdf-o trip-request__button__search"></i>
          </button>
        </div>
      </div>
      <div *ngIf="cashFlow" class="flow">
        <table class="table table-striped table-bordered">
          <thead>
            <caption>
              Receitas
            </caption>
            <tr>
              <th>Data Efetivação</th>
              <th>Descrição</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let income of cashFlow?.incomes">
              <td>{{ income.date | date: "dd/MM/yyyy" }}</td>
              <td>{{ income.runRequest?.covenant?.name }}</td>
              <td>{{ income.amount | currency: "BRL" }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-striped table-bordered">
          <thead>
            <caption>
              Despesas
            </caption>
            <tr>
              <th>Data Efetivação</th>
              <th>Descrição</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let expenditure of cashFlow?.expenditures">
              <td>{{ expenditure.date | date: "dd/MM/yyyy" }}</td>
              <td>{{ expenditure.description }}</td>
              <td>{{ expenditure.price | currency: "BRL" }}</td>
            </tr>
            <br />
            <tr>
              <td colspan="2">SALDO ANTERIOR</td>
              <td>{{ cashFlow.balance | currency: "BRL" }}</td>
            </tr>

            <tr class="text-success font-weight-bold">
              <td colspan="2">RECEITAS</td>
              <td>{{ cashFlow.totalIncome | currency: "BRL" }}</td>
            </tr>

            <tr class="text-danger">
              <td colspan="2">DESPESAS</td>
              <td>({{ cashFlow.totalExpenditure | currency: "BRL" }})</td>
            </tr>

            <tr
              [ngClass]="{
                green: cashFlow?.periodBalance > 0,
                redColor: cashFlow?.periodBalance < 0
              }"
              class="font-weight-bold"
            >
              <td colspan="2">SALDO DO PERÍODO</td>
              <td>
                {{ cashFlow?.periodBalance > 0 ? "" : "(" }}
                {{ cashFlow?.periodBalance | currency: "BRL" }}
                {{ cashFlow?.periodBalance > 0 ? "" : ")" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
