<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row col-md-12 mt-3">
                        <div class="col-md-10">
                            <div class="page-title">Cadastrar Cartão de Crédito</div>
                        </div>
                    </div>
                    <div [formGroup]="creditCardForm">
                        <!--div com o form -->
                        <div *ngIf="messageError.length" class="container">
                            <div class="alert alert-danger" role="alert">
                                {{messageError}}
                            </div>
                        </div>
                        <div class="row col-md-12">

                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="number">Número</label>
                                    <input type="text" class="form-control credit_card" id="number"
                                        (keyup)="onKey($event)" formControlName="number" placeholder="Número do Cartão">
                                    <span
                                        *ngIf="creditCardForm.controls.number.errors && creditCardForm.controls.number.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="creditCardForm.controls.number.errors && creditCardForm.controls.number.errors.invalid"
                                        class="text-danger position-right">*
                                        {{creditCardForm.controls.number.errors.invalid}} </span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" *ngIf="flag">
                                    <!-- <label for="flag">Bandeira</label> -->
                                    <img class="img-thumbnail" src="{{ flag }}" alt="Bandeira">

                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="active">Status</label>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="true" value="true" number="active"
                                            formControlName="active" class="custom-control-input">
                                        <label class="custom-control-label" for="true">Ativo</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="false" value="false" number="active"
                                            formControlName="active" class="custom-control-input">
                                        <label class="custom-control-label" for="false">Inativo</label>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="row mb-3 mt-3">
                            <div class="col-md-3"></div>
                            <div class="col-md-3 text-right">
                                <button (click)="onSubmit()" [disabled]="!creditCardForm.valid"
                                    class="btn btn-success">Salvar</button>
                            </div>
                            <div class="col-md-3">
                                <a routerLink="/parameters/credit-card" class="btn btn-danger">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
