import { Address } from './address';
import { Covenant } from './covenant';

export class Collaborator {
  id: number;
  name: string;
  email: string;
  role: string;
  phone: string;
  whatsapp: string;
  main: boolean;
  department: string;
  costCenter: string;
  address: Address;
  destinyPoint: string;
  originAddress: Address;
  active: boolean;
  requestAppRace: boolean;
  canResendActivation: boolean;
  covenant?: Covenant;

  constructor(attrs?: Partial<Collaborator>) {
    Object.assign(this, attrs);
    this.address = new Address(attrs?.address);
  }
}
