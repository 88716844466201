import { Component, OnInit } from '@angular/core';
import { Piece } from 'src/app/domain/piece';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PieceService } from 'src/app/service/piece/piece.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Validations } from '../../validations';

@Component({
  selector: 'app-piece-update',
  templateUrl: './piece-update.component.html',
  styleUrls: ['./piece-update.component.css']
})
export class PieceUpdateComponent implements OnInit {

  pieceForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  piece: Piece = new Piece();

  constructor(
    private service: PieceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.piece.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();


    this.service.getPiece(this.piece.id).subscribe(data => {
      this.piece = data;
      this.setValues();
    }, error => {
      this.loading = false;
      Swal.fire('Erro', 'Não foi possível Buscar Peça!', 'error');
    });

  }
  createForm() {
    this.pieceForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      exchangeOdometer: ['', [Validators.required, Validations.validNegativo]],
      active: ['', Validators.required]
    });
  }
  setValues() {
    this.pieceForm.controls.name.setValue(this.piece.name);
    this.pieceForm.controls.exchangeOdometer.setValue(this.piece.exchangeOdometer);
    this.pieceForm.controls.active.setValue(`${this.piece.active}`);
  }

  onSubmit() {
    this.piece.name = this.pieceForm.controls.name.value;
    this.piece.exchangeOdometer = this.pieceForm.controls.exchangeOdometer.value;
    if (this.pieceForm.controls.active.value == "true") {
      this.piece.active = true
    } else {
      this.piece.active = false;
    }
    this.loading = true;
    this.service.update(this.piece).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Peça editada com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/piece']);
        }
      });
      this.router.navigate(['parameters/piece']);
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Peça!', 'error');
      });
  }

  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "name":
          this.pieceForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "exchangeOdometer":
          this.pieceForm.controls.exchangeOdometer.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;

      }
    });

  }

}
