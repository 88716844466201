<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Motorista</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/maintenance/driver/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ngx-loading [show]="loading"> </ngx-loading>
      </div>
      <div class="container"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div
                class="col-md-4 position-right"
                [formGroup]="showResultsForm"
              >
                <select
                  (change)="onSelected($event)"
                  formControlName="per_page"
                  class="custom-select custom-select-sm"
                >
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">
                    Mostrar Todos os Resultados
                  </option>
                </select>
              </div>
              <header>
                <div class="row" [formGroup]="searchForm">
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nome"
                      formControlName="name"
                    />
                  </div>
                  <!-- <div class="col-12 col-md-6 col-lg mt-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="CPF"
                      formControlName="cpf"
                      mask="000.000.000-00"
                    />
                  </div> -->
                  <!-- <div class="col-12 col-md-6 col-lg mt-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="CNH"
                      formControlName="cnh"
                    />
                  </div> -->
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <select
                      (change)="onSelectedState($event)"
                      formControlName="state"
                      class="form-control"
                    >
                      <option value="">Estado</option>
                      <option
                        value="{{ state.id }}"
                        *ngFor="let state of states"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-6 col-lg mt-2">
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Cidade"
                      formControlName="city"
                      [items]="cities"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                  </div>

                  <div class="col-12 col-md-6 col-lg mt-2">
                    <!-- <span class="input-group-btn search-btn"> -->
                    <a (click)="onSearch()" class="btn btn-info">
                      <i class="fa fa-search"></i>
                    </a>
                    <!-- </span> -->
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th>Cód.</th> -->
                      <th>Nome</th>
                      <!-- <th>CPF</th> -->
                      <!-- <th>Telefone</th> -->
                      <!-- <th>WhatsApp</th> -->
                      <!-- <th>CNH</th> -->
                      <!-- <th>Categoria</th> -->
                      <!-- <th>Validade</th> -->
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let driver of drivers">
                      <!-- <td>
                        {{ driver.id }}
                      </td> -->
                      <td>
                        {{ driver.name }}
                      </td>
                      <!-- <td>
                        {{ driver.cpf | mask: "000.000.000-00" }}
                      </td> -->
                      <!-- <td>
                                                {{driver.phone | mask: '(00) 0000-0000||(00) 0 0000-0000' }}
                                            </td> -->
                      <!-- <td>
                        {{ driver.whatsapp | mask: "+00 00 0 0000-0000" }}
                      </td> -->
                      <!-- <td>
                        {{ driver.cnh }}
                      </td> -->
                      <!-- <td>
                        {{ driver.cnhCategory }}
                      </td> -->
                      <!-- <td>
                        {{ driver.cnhValidity | date: "dd/MM/yyyy" }}
                      </td> -->
                      <td>
                        <span
                          *ngIf="driver.active"
                          class="label label-sucess label-mini"
                          >Ativo</span
                        >
                        <span
                          *ngIf="!driver.active"
                          class="label label-danger label-mini"
                          >Inativo</span
                        >
                      </td>
                      <td>
                        <div class="row">
                          <div class="col">
                            <a
                              routerLink="/maintenance/driver/view/{{ driver.id }}"
                              class="btn btn-primary btn-xs"
                              alt="Visualizar"
                              title="Visualizar"
                            >
                              <i class="fa fa-eye"></i>
                            </a>
                            <a
                              routerLink="/maintenance/driver/edit/{{ driver.id }}"
                              class="btn btn-warning btn-xs"
                              title="Editar"
                            >
                              <i class="fa fa-pencil"></i>
                            </a>
                            <a
                              (click)="onResend(driver)"
                              class="btn btn-success btn-xs"
                              title="Enviar redefinição de senha"
                            >
                              <i class="fa fa-envelope"></i>
                            </a>
                            <span *ngIf="driver.active">
                              <button
                                (click)="onDeActive(driver)"
                                class="btn btn-pink btn-xs"
                                title="Desativar"
                              >
                                <i class="fa fa-random"></i>
                              </button>
                            </span>
                            <span *ngIf="!driver.active">
                              <button
                                (click)="onActive(driver)"
                                class="btn btn-success btn-xs"
                                title="Ativar"
                              >
                                <i class="fa fa-random"></i>
                              </button>
                            </span>
                            <a
                              *ngIf="driver.active"
                              routerLink="/maintenance/driver/{{ driver.id }}/vehicle"
                              class="btn btn-info btn-xs"
                              title="Vincular Motorista ao Veículo"
                            >
                              <i class="fa fa-car"></i>
                            </a>
                            <span *ngIf="driver.canResendActivation">
                              <button
                                (click)="resendActivationInstruction(driver)"
                                class="btn btn-success btn-xs"
                                title="Reenviar instruções de ativação"
                              >
                                <i class="fa fa-retweet"></i>
                              </button>
                            </span>
                            <!-- <a  class="btn btn-success btn-xs">
                                                        <i class="fa fa-random"></i>
                                                    </a> -->
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-12">
                    <span
                      *ngIf="!drivers.length"
                      class="label label-danger label-mini"
                      >Nenhum Motorista encontrado.</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page || 0 }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
