import { Directive, ElementRef, SimpleChanges, OnChanges, isDevMode } from '@angular/core';
import {
   } from '../parse';
import { StyleRenderer, Style, WithStyles, InputStyle } from '../minimal/renderer-style';
import { ThemeVariables } from './theme-config';

const STYLE_PRIORITY = -0.5;

/**
 * @dynamic
 */
@Directive({
  selector: `[lyStyle],
              [lyColor],
              [lyBg],
              [lyP], [lyPf], [lyPe], [lyPt], [lyPb], [lyPx], [lyPy],
              [lyM], [lyMf], [lyMe], [lyMt], [lyMb], [lyMx], [lyMy],
              [lySize],
              [lyWidth], [lyMaxWidth], [lyMinWidth],
              [lyHeight], [lyMaxHeight], [lyMinHeight],
              [lyDisplay],
              [lyFlex],
              [lyFlexBasis],
              [lyFlexDirection],
              [lyFlexGrow],
              [lyFlexSelf],
              [lyFlexShrink],
              [lyFlexWrap],
              [lyJustifyContent],
              [lyJustifyItems],
              [lyJustifySelf],
              [lyAlignContent],
              [lyAlignItems],
              [lyOrder]`,
  providers: [
    StyleRenderer
  ],
  inputs: [
    'lyStyle',
    'color: lyColor',
    'bg: lyBg',
    'p: lyP', 'pf: lyPf', 'pe: lyPe', 'pt: lyPt', 'pb: lyPb', 'px: lyPx', 'py: lyPy',
    'm: lyM', 'mf: lyMf', 'me: lyMe', 'mt: lyMt', 'mb: lyMb', 'mx: lyMx', 'my: lyMy',
    'size: lySize',
    'width: lyWidth', 'maxWidth: lyMaxWidth', 'minWidth: lyMinWidth',
    'height: lyHeight', 'maxHeight: lyMaxHeight', 'minHeight: lyMinHeight',
    'display: lyDisplay',
    'flex: lyFlex',
    'flexBasis: lyFlexBasis',
    'flexDirection: lyFlexDirection',
    'flexGrow: lyFlexGrow',
    'flexSelf: lyFlexSelf',
    'flexShrink: lyFlexShrink',
    'flexWrap: lyFlexWrap',
    'justifyContent: lyJustifyContent',
    'justifyItems: lyJustifyItems',
    'justifySelf: lyJustifySelf',
    'alignContent: lyAlignContent',
    'alignItems: lyAlignItems',
    'order: lyOrder'
  ]
})
export class LyStyle implements WithStyles {

  set size(value: string | number | null) {
    this.width = value;
    this.height = value;
  }

  constructor(
    readonly sRenderer: StyleRenderer
  ) { }
  /** @docs-private */
  static readonly и = 'LyStyle';
  static readonly $priority = STYLE_PRIORITY;

  static readonly with: InputStyle<string | number | null>;

  @Style<string | null>(
    (value) => (theme: ThemeVariables) => (
      (className: string) => `${className}{color:${theme.colorOf(value)};}`
    )
  ) color: string | number | null;

  @Style<string | null>(
    (value) => (theme: ThemeVariables) => (
      (className: string) => `${className}{background:${theme.colorOf(value)};}`
    )
  ) bg: string | number | null;

  @Style<string | number | null>(
    (value, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{padding:${to8Px(value)};}}`
    )
  ) p: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints, after }) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{padding-${after}:${to8Px(val)};}}`
    )
  ) pf: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints, before }) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{padding-${before}:${to8Px(val)};}}`
    )
  ) pe: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{padding-top:${to8Px(val)};}}`
    )
  ) pt: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{padding-bottom:${to8Px(val)};}}`
    )
  ) pb: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{padding:0 ${to8Px(val)};}}`
    )
  ) px: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{padding:${to8Px(val)} 0;}}`
    )
  ) py: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{margin:${to8Px(val)};}}`
    )
  ) m: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints, after }) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{margin-${after}:${to8Px(val)};}}`
    )
  ) mf: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints, before }) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{margin-${before}:${to8Px(val)};}}`
    )
  ) me: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{margin-top:${to8Px(val)};}}`
    )
  ) mt: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{margin-bottom:${to8Px(val)};}}`
    )
  ) mb: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{margin:0 ${to8Px(val)};}}`
    )
  ) mx: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{margin:${to8Px(val)} 0;}}`
    )
  ) my: string | number | null;
  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{width:${transform(val)};}}`
    )
  ) width: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{max-width:${transform(val)};}}`
    )
  ) maxWidth: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{min-width:${transform(val)};}}`
    )
  ) minWidth: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{height:${transform(val)};}}`
    )
  ) height: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{max-height:${transform(val)};}}`
    )
  ) maxHeight: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{min-height:${transform(val)};}}`
    )
  ) minHeight: string | number | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{display:${val};}}`
    )
  ) display: string | null;


  // Flexbox

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{flex:${val};}}`
    )
  ) flex: string | number | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{flex-basis:${val};}}`
    )
  ) flexBasis: string | number | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{flex-direction:${val};}}`
    )
  ) flexDirection: string | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{flex-grow:${val};}}`
    )
  ) flexGrow: string | number | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{flex-self:${val};}}`
    )
  ) flexSelf: string | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{flex-shrink:${val};}}`
    )
  ) flexShrink: string | number | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{flex-wrap:${val};}}`
    )
  ) flexWrap: string | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{justify-content:${val};}}`
    )
  ) justifyContent: string | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{justify-items:${val};}}`
    )
  ) justifyItems: string | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{justify-self:${val};}}`
    )
  ) justifySelf: string | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{align-content:${val};}}`
    )
  ) alignContent: string | null;

  @Style<string | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{align-items:${val};}}`
    )
  ) alignItems: string | null;

  @Style<string | number | null>(
    (val, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && breakpoints[ media ]) || 'all'}{${className}{order:${val};}}`
    )
  ) order: string | number | null;

  @Style<string | number | null>(
    (value, media) => ({ breakpoints }: ThemeVariables) => (
      (className: string) => `@media ${(media && (breakpoints[ media ] || media)) || 'all'}{${className}{${value};}}`
    )
  )
  lyStyle: string | null;
}

/**
 * @dynamic
 * @deprecated
 * Spacing
 * [p], [pf], [pe], [pt], [pb], [px], [py],
 * [m], [mf], [me], [mt], [mb], [mx], [my],
 * Sizing
 * [size],
 * [width], [maxWidth], [minWidth],
 * [height], [maxHeight], [minHeight],
 * Others
 * [lyStyle]
 */
@Directive({
  selector: `
              [p], [pf], [pe], [pt], [pb], [px], [py],
              [m], [mf], [me], [mt], [mb], [mx], [my],
              [size]:not([ly-button]),
              [width]:not(svg):not(canvas):not(embed):not(iframe):not(img):not(input):not(object):not(video),
              [maxWidth], [minWidth],
              [height]:not(svg):not(canvas):not(embed):not(iframe):not(img):not(input):not(object):not(video),
              [maxHeight], [minHeight],
              [display],
              [flex],
              [flexBasis],
              [flexDirection],
              [flexGrow],
              [flexSelf],
              [flexShrink],
              [flexWrap],
              [justifyContent],
              [justifyItems],
              [justifySelf],
              [alignContent],
              [alignItems],
              [order]`,
  providers: [
    StyleRenderer
  ],
  inputs: [
    'p', 'pf', 'pe', 'pt', 'pb', 'px', 'py',
    'm', 'mf', 'me', 'mt', 'mb', 'mx', 'my',
    'size',
    'width', 'maxWidth', 'minWidth',
    'height', 'maxHeight', 'minHeight',
    'display',
    'flex',
    'flexBasis',
    'flexDirection',
    'flexGrow',
    'flexSelf',
    'flexShrink',
    'flexWrap',
    'justifyContent',
    'justifyItems',
    'justifySelf',
    'alignContent',
    'alignItems',
    'order',
  ]
})
export class LyStyleDeprecated extends LyStyle implements OnChanges {
  constructor(
    sRenderer: StyleRenderer,
    private _el: ElementRef
  ) {
    super(sRenderer);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (isDevMode()) {
      for (const key in changes) {
        if (changes.hasOwnProperty(key)) {
          const message = `[${key}] is deprecated, use [ly${key.charAt(0).toUpperCase() + key.slice(1)}] instead.`;
          console.warn({
            message,
            element: this._el.nativeElement
          });
        }
      }
    }
  }
}

/**
 * Convert to px if the value is a number, otherwise leave it as is
 * @docs-private
 */
function to8Px(val: number | string) {
  return typeof val === 'number'
    ? `${val * 8}px`
    : val.includes(' ')
      ? val.split(' ').map(_ => strTo8Px(_)).join(' ')
      : strTo8Px(val);
}

function strTo8Px(val: string) {
  const num = +val;
  return isNaN(num) ? val : `${num * 8}px`;
}

function strToPx(val: string) {
  const num = +val;
  return isNaN(num) ? val : `${num}px`;
}

function transform(value: number | string) {
  return value <= 1
    ? `${value as number * 100}%`
    : typeof value === 'number'
      ? `${value}px`
      : value.includes(' ')
        ? value.split(' ').map(_ => strToPx(_)).join(' ')
        : strToPx(value);
}
