import { Component, OnInit } from '@angular/core';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViaCep } from 'src/app/domain/viacep';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { Validations } from '../../validations';
import { Provider } from 'src/app/domain/provider';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AddressService } from 'src/app/service/address/address.service';

@Component({
  selector: 'app-provider-update',
  templateUrl: './provider-update.component.html',
  styleUrls: ['./provider-update.component.css']
})
export class ProviderUpdateComponent implements OnInit {

  states: Array<State> = [];
  cities: Array<City> = [];
  providerForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  viacep: ViaCep = new ViaCep;
  provider: Provider = new Provider();

  constructor(
    private service: ProviderService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.provider.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.serviceAddress.getStates().subscribe(data => {
      this.states = data;

      this.service.getProvider(this.provider.id).subscribe(data => {
        this.provider = data;
        this.serviceAddress.getCitiesByStateId(this.provider.address.city.state.id).subscribe(data => {
          this.cities = data;
          this.setValues();
        }, error => {
          Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
        });

      }, error => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar Fornecedor!', 'error');
      });
    }, error => {
      Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
    });
  }
  createForm() {
    this.providerForm = this.fb.group({
      name: ['', [Validators.required]],
      cpfCnpj: ['', [Validators.required, Validations.validCpfOrCnpj]],
      contact: ['', [Validators.required]],
      stateRegistration: ['', []],
      municipalRegistration: ['', []],
      phone: ['', [Validators.required, Validations.validPhone]],
      whatsapp: ['', [Validations.validwhatsapp]],
      email: ['', [Validators.required, Validators.email]],
      zipCode: ['', [Validators.required]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      active: ['', [Validators.required]]
    });
  }
  setValues() {
    this.providerForm.controls.name.setValue(this.provider.name);
    this.providerForm.controls.cpfCnpj.setValue(this.provider.cpfCnpj);
    this.providerForm.controls.stateRegistration.setValue(this.provider.stateRegistration);
    this.providerForm.controls.municipalRegistration.setValue(this.provider.municipalRegistration);
    this.providerForm.controls.email.setValue(this.provider.email);
    this.providerForm.controls.contact.setValue(this.provider.contact);
    this.providerForm.controls.whatsapp.setValue(this.provider.whatsapp);
    this.providerForm.controls.phone.setValue(this.provider.phone);
    this.providerForm.controls.active.setValue(`${this.provider.active}`);
    this.providerForm.controls.zipCode.setValue(this.provider.address.zipCode);
    this.providerForm.controls.neighborhood.setValue(this.provider.address.neighborhood);
    this.providerForm.controls.publicPlace.setValue(this.provider.address.publicPlace);
    this.providerForm.controls.state.setValue(this.provider.address.city.state.id);
    this.providerForm.controls.city.setValue(this.provider.address.city.id);
  }

  onSubmit() {

    // let provider = new Provider();
    // provider.id = this.provider.id;
    this.provider.name = this.providerForm.controls.name.value;
    this.provider.email = this.providerForm.controls.email.value;
    this.provider.cpfCnpj = this.providerForm.controls.cpfCnpj.value;
    this.provider.stateRegistration = this.providerForm.controls.stateRegistration.value;
    this.provider.municipalRegistration = this.providerForm.controls.municipalRegistration.value;
    this.provider.contact = this.providerForm.controls.contact.value;
    this.provider.whatsapp = this.providerForm.controls.whatsapp.value;
    this.provider.phone = this.providerForm.controls.phone.value;
    this.provider.address.zipCode = this.providerForm.controls.zipCode.value;
    this.provider.address.neighborhood = this.providerForm.controls.neighborhood.value;
    this.provider.address.publicPlace = this.providerForm.controls.publicPlace.value;
    this.provider.address.city.id = Number(this.providerForm.controls.city.value);
    if (this.providerForm.controls.active.value == "true") {
      this.provider.active = true
    } else {
      this.provider.active = false;
    }
    this.loading = true;
    this.service.update(this.provider).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Fornecedor editado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/provider']);
        }
      });
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Fornecedor!', 'error');
      });
  }
  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, "").length === 8) {
      this.utilsService.getCep(event.target.value.replace(/[^0-9,.]+/g, "")).subscribe(data => {
        this.viacep = data;

        if (!this.viacep.erro) {
          this.providerForm.controls.neighborhood.setValue(this.viacep.bairro);
          this.providerForm.controls.publicPlace.setValue(this.viacep.logradouro);
          const state = this.states.find(state => state.initials === this.viacep.uf.toUpperCase());

          this.providerForm.controls.state.setValue(state.id);

          // TODO - Implementar busca de cidades por UF
          this.serviceAddress.getCitiesByStateId(state.id).subscribe(data => {
            this.cities = data;
            const city = this.cities.find(elem => elem.name.normalize("NFD") === this.viacep.localidade.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ''));
            if (city) {
              this.providerForm.controls.city.setValue(city.id);
            }
          }, error => {
            Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
          });

        } else {
          this.providerForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        }
        this.loading = false;
      }, erro => {
        this.providerForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.providerForm.controls.zipCode.setErrors({ invalid: "CEP inválido!" });
    }
  }

  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "name":
          this.providerForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "cpfCnpj":
          this.providerForm.controls.cpfCnpj.setErrors({ invalid: erro.message });
          break;
        case "stateRegistration":
          this.providerForm.controls.stateRegistration.setErrors({ invalid: erro.message });
          break;
        case "municipalRegistration":
          this.providerForm.controls.municipalRegistration.setErrors({ invalid: erro.message });
          break;
        case "whatsapp":
          this.providerForm.controls.whatsapp.setErrors({ invalid: erro.message });
          break;
        case "phone":
          this.providerForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case "email":
          this.providerForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "zipCode":
          this.providerForm.controls.zipCode.setErrors({ invalid: erro.message });
          break;
        case "publicPlace":
          this.providerForm.controls.publicPlace.setErrors({ invalid: erro.message });
          break;
        case "neighborhood":
          this.providerForm.controls.neighborhood.setErrors({ invalid: erro.message });
          break;
        case "state":
          this.providerForm.controls.state.setErrors({ invalid: erro.message });
          break;
        case "city":
          this.providerForm.controls.city.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;

      }
    });

  }
}
