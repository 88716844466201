import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { Validations } from '../validations';
import { Collaborator } from '../../domain/collaborator';
import { CovenantCollaboratorService } from '../../service/covenant-collaborator/covenant-collaborator.service';
import Swal from 'sweetalert2';
import { AddressService } from '../../service/address/address.service';
import { UtilsService } from '../../service/utils/utils.service';
import { City } from '../../domain/city';
import { State } from '../../domain/state';

@Component({
  selector: 'app-modal-covenant-collaborator-form',
  templateUrl: './modal-covenant-collaborator-form.component.html',
  styleUrls: ['./modal-covenant-collaborator-form.component.css'],
})
export class ModalCovenantCollaboratorFormComponent implements OnInit {
  constructor(
    private service: CovenantCollaboratorService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    public readonly swalTargets: SwalPortalTargets
  ) {}

  form: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  states: State[] = [];
  cities: City[] = [];

  @Input() covenantId: number;
  @Input() collaborator: Collaborator;
  @Input() modalFireCondition: boolean = false;

  @Output() modalClose: EventEmitter<boolean | Collaborator> =
    new EventEmitter();

  ngOnInit(): void {
    this.createForm();
  }

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE COLABORADORES --------------------
  createForm() {
    this.form = this.fb.group({
      id: new FormControl(''),
      name: new FormControl('', [
        Validators.maxLength(100),
        Validators.required,
      ]),
      email: new FormControl(''),
      role: new FormControl('', [Validators.maxLength(100)]),
      whatsapp: new FormControl('', [
        Validations.validwhatsapp,
        Validators.required,
      ]),
      department: new FormControl('', [Validators.maxLength(100)]),
      costCenter: new FormControl('', [Validators.maxLength(100)]),
      zipCode: new FormControl('', [Validators.maxLength(9)]),
      publicPlace: new FormControl('', [Validators.maxLength(100)]),
      neighborhood: new FormControl(''),
      cityId: new FormControl(null),
      stateId: new FormControl(null),
      requestAppRace: new FormControl(false, [Validators.required]),
    });
  }

  onFormUpdate() {
    const {
      id,
      name,
      email,
      role,
      whatsapp,
      department,
      costCenter,
      requestAppRace,
      address,
    } = this.collaborator ?? {};

    const { zipCode, publicPlace, neighborhood, city } = address ?? {};

    this.form.patchValue({
      id,
      name,
      email,
      role,
      whatsapp,
      department,
      costCenter,
      requestAppRace,
      zipCode,
      publicPlace,
      neighborhood,
      cityId: city?.id,
      stateId: city?.state?.id,
    });

    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );

    if (this.collaborator?.address?.city?.state?.id)
      this.fetchCities(this.collaborator.address.city.state.id);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    const {
      id,
      zipCode,
      publicPlace,
      neighborhood,
      cityId,
      requestAppRace,
      ...formData
    } = this.form.value;

    const covenantCollaborator = new Collaborator({
      ...formData,
      id,
      address: {
        zipCode: zipCode.replace(/[^0-9,.]+/g, ''),
        publicPlace,
        neighborhood,
        city: { id: +cityId },
      },
      requestAppRace,
    });

    this.loading = true;

    if (!id) {
      this.service.save(this.covenantId, covenantCollaborator).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire('Salvo', 'Colaborador salvo com sucesso!!', 'success');
          this.form.reset();
          this.cities = [];
          this.states = [];

          this.modalClose.emit(data);
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);

          const isActiveInAnotherCovenant = error.error?.errors?.find(
            (item) => item.fieldName === 'isActiveInAnotherCovenant'
          );

          const activeCollaboratorId = error.error?.errors?.find(
            (item) => item.fieldName === 'activeCollaboratorId'
          );

          if (isActiveInAnotherCovenant && activeCollaboratorId) {
            Swal.fire({
              title: 'Colaborador ativo em outro convênio',
              showCancelButton: true,
              confirmButtonText: 'Sim, inativar e cadastrar novamente',
              showLoaderOnConfirm: true,
              text: isActiveInAnotherCovenant.message,
              allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
              if (result.isConfirmed) {
                this.loading = true;
                this.service
                  .deactiveColaborattor(
                    this.covenantId,
                    activeCollaboratorId.message
                  )
                  .subscribe(
                    (data) => this.onSubmit(),
                    (error) => {
                      this.loading = false;
                      Swal.fire(
                        'Erro ao salvar',
                        'Não foi possível salvar Colaborador!',
                        'error'
                      );
                    }
                  );
              }
            });
          } else {
            Swal.fire(
              'Erro ao salvar',
              'Não foi possível salvar Colaborador!',
              'error'
            );
          }
        }
      );
    } else {
      this.loading = true;
      this.service.update(this.covenantId, covenantCollaborator).subscribe(
        (data) => {
          this.loading = false;
          this.form.reset();
          this.cities = [];
          this.states = [];

          Swal.fire('Salvo', 'Colaborador salvo com sucesso!', 'success');
          this.modalClose.emit(data);
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Colaborador!',
            'error'
          );
        }
      );
    }
  }

  setErrors(errors) {
    errors.forEach((err: any) => {
      this.form.get(err.fieldName).setErrors({ invalid: err.message });
      this.messageError += `${err.message}\n`;
    });
  }

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            const viaCep = data;

            if (!viaCep.erro) {
              this.cities = [];

              this.form.get('neighborhood').patchValue(viaCep.bairro);
              this.form.get('publicPlace').patchValue(viaCep.logradouro);

              this.form.get('stateId').patchValue('');
              this.form.get('cityId').patchValue('');
              const state = this.states.find(
                (state) => state.initials === viaCep.uf.toUpperCase()
              );

              this.form.get('stateId').patchValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      viaCep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );

                  if (city) {
                    this.form.get('cityId').patchValue(city.id);
                  }
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
            } else {
              this.form.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.form.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.form.controls.zipCode.setErrors({
        invalid: 'CEP inválido!',
      });
    }
  }

  onSelectedState(event) {
    if (event.target.value) {
      this.fetchCities(event.target.value);
    }
  }

  fetchCities(stateId: number) {
    this.loading = true;
    this.serviceAddress.getCitiesByStateId(stateId).subscribe(
      (data) => {
        this.cities = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
      }
    );
  }

  onClose() {
    this.form.reset();
    this.cities = [];
    this.states = [];

    this.modalClose.emit(true);
  }
}
