<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title"> Minha Conta </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <ngx-loading [show]="loading">
                </ngx-loading>
            </div> -->
            <div class="container" [formGroup]="myAccountForm">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-topline-green">
                            <header>
                            </header>
                            <div class="row ml-2 mr-2">
                                <div class="col-sm-4 mt-3">
                                    <label>Nome</label>
                                    <input type="text" class="form-control" id="name" value="{{name}}"
                                        placeholder="Nome" disabled>
                                </div>
                                <div class="col-sm-4 mt-3">
                                    <label>E-mail</label>
                                    <input type="text" class="form-control" id="email" value="{{email}}"
                                        placeholder="E-mail" disabled>
                                </div>
                                <div class="col-sm-4 mt-3">
                                    <label>Cargo</label>
                                    <input type="text" class="form-control" id="role" value="{{role}}"
                                        placeholder="Cargo" disabled>

                                </div>
                            </div>

                            <div class="row ml-2 mr-2">
                                <div class="col-sm-12 mt-2">
                                    <label>Senha Atual</label>
                                    <input class="form-control" type="password" name="actualPassword"
                                        formControlName="actualPassword" placeholder="Senha Atual">
                                    <span *ngIf="submited && myAccountForm.controls.actualPassword.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                </div>
                            </div>
                            <div class="row ml-2 mr-2">
                                <div class="col-sm-12 mt-2">
                                    <label>Nova Senha</label>
                                    <input class="form-control" type="password" name="password"
                                        formControlName="password" placeholder="Nova Senha">
                                    <span *ngIf="myAccountForm.controls.password.errors">
                                        <span *ngIf="submited && myAccountForm.controls.password.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span *ngIf="submited && myAccountForm.controls.password.errors.minlength"
                                            class="text-danger position-right">*Senha deve ter no mínimo 8
                                            caracteres</span>
                                        <span
                                            *ngIf="submited && !myAccountForm.controls.password.errors.required && !myAccountForm.controls.password.errors.minlength && myAccountForm.controls.password.errors.invalidPassword"
                                            class="text-danger position-right">*Senha deve ter letras maiúsculas e
                                            minúsculas e
                                            números</span>
                                    </span>
                                </div>
                            </div>

                            <div class="row ml-2 mr-2">
                                <div class="col-sm-12 mt-2">
                                    <label>Confirme a Nova Senha</label>
                                    <input class="form-control" type="password" name="confirmationPassword"
                                        formControlName="confirmationPassword" placeholder="Confirme a Nova Senha">
                                    <span
                                        *ngIf="submited && myAccountForm.controls.confirmationPassword.errors && myAccountForm.controls.confirmationPassword.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                </div>
                            </div>


                            <div class="row mt-3 mb-3">
                                <div class="col-md-3"></div>
                                <div class="col-md-3 text-right">
                                    <button (click)="onSubmit()" [disabled]="!myAccountForm.valid"
                                        class="btn btn-success">Salvar</button>
                                </div>

                                <div class="col-md-3">
                                    <a href="/convenio" class="btn btn-danger">Cancelar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>