import { Piece } from '../piece';

export class PieceDTO {
    name: string;
    exchangeOdometer: string;
    active: boolean;

    constructor(piece: Piece) {
        this.name = piece.name;
        this.exchangeOdometer = piece.exchangeOdometer;
        this.active = piece.active;
    }
}