import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Bank } from 'src/app/domain/bank';
import { BankAccountService } from 'src/app/service/bank-account/bank-account.service';
import { BankService } from 'src/app/service/bank/bank.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-bank-create',
  templateUrl: './account-bank-create.component.html',
  styleUrls: ['./account-bank-create.component.css']
})
export class AccountBankCreateComponent implements OnInit {
  loading: boolean = true;
  bankForm: FormGroup;
  banks: Array<Bank> = [];

  messageError: string = "";
  constructor(
    private service: BankAccountService,
    private bankService: BankService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getAllBankActive();
  }

  getAllBankActive() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    )
  }

  createForm() {
    this.bankForm = this.fb.group({
      agency: ['', [Validators.required]],
      agencyDv: [''],
      type: ['', [Validators.required]],
      bankAccount: ['', [Validators.required]],
      bankAccountDv: ['', [Validators.required]],
      socialNumber: ['', [Validators.required]],
      socialName: ['', [Validators.required]],
      receivePayments: [false],
      bank: [null, [Validators.required]]
    })
  }

  onSubmit() {
    const bankValues = this.bankForm.getRawValue();

    if(this.bankForm.valid) {
      bankValues.bank = {
        id: Number(this.bankForm.get('bank').value) || null,
      };
      this.messageError =""
      this.loading = true;

      this.service.save(bankValues).subscribe(
        data => {

          Swal.fire(
            'Salvo',
            'Conta Bancária salva com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/account-bank']);
            }
          });
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível cadastrar conta bancária!',
            'error'
          );
        }
      )
    }
  }

  setErrors(errors) {
    errors.forEach(erro => {
      if(erro.message == "Já existe uma conta bancária cadastrada com estes dados") {
        this.messageError = erro.message
        return ;
      }
      this.bankForm.controls[erro.fieldName].setErrors({ invalid: erro.message });
      //switch (erro.fieldName) {
      //  case "agency":
      //    this.bankForm.controls.agency.setErrors({ invalid: erro.message });
      //    break;
      //  case "bankAccount":

      //    this.bankForm.controls.bankAccount.setErrors({ invalid: erro.message });
      //    break;
      //  case "bank":
      //    this.bankForm.controls.bank.setErrors({ invalid: erro.message });
      //    break;
      //  default:
          this.messageError += `${erro.message}\n`;
      //}
    });

  }

}
