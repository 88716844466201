<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading">
</ngx-loading>
<div class="page-content-wrapper">
   <div class="page-content">
      <div class="page-bar">
         <div class="page-title-breadcrumb">
            <div class="container">

               <div class="card card-topline-green">
                  <div class="card-head">
                     <header>Visualizar Usuário</header>
                  </div>
                  <div class="card-body ">
                     <div class="row">
                        <div class="col-md-12">
                           {{ user.name }}
                        </div>

                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <b>Cargo</b>
                        </div>
                        <div class="col-md-12">
                           <p> {{ user.role }} </p>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <b>Login</b>
                        </div>
                        <div class="col-md-12">
                           <p> {{ user.login }}</p>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <b>Email</b>
                        </div>
                        <div class="col-md-12">
                           <p> {{ user.email }}</p>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <b>Telefone</b>
                        </div>
                        <div class="col-md-12">
                           <p> {{ user.phone | mask: '(00) 0000-0000||(00) 0 0000-0000' }} </p>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <b>Data de Nascimento</b>
                        </div>
                        <div class="col-md-12">
                           <p> {{ user.birthDate | date: 'dd/MM/yyyy' }}</p>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <b>Perfil de Acesso</b>
                        </div>
                        <div class="col-md-12">
                           <p *ngIf="user.accessProfile != null"> {{ user.accessProfile.name }} </p>
                           <p class="text-danger" *ngIf="user.accessProfile == null"> Nenhum Perfil de Acesso associado.
                           </p>
                        </div>
                     </div>
                     <div class="row space-botton">
                        <div class="col-md-2">
                           <b>Status</b>
                        </div>
                        <div class="col-md-10">
                           <span *ngIf="user.active" class="label label-sucess label-mini">Ativo</span>
                           <span *ngIf="!user.active" class="label label-danger label-mini">Inativo</span>
                        </div>
                     </div>
                     <div class="row">
                        <!-- <div class="col-md-12">
                           <b>Permissões</b>
                        </div>
                        <div class="col-md-12">
                           <div class="table-responsive">
                              <table class="table table-striped custom-table table-hover">
                                 <tbody>
                                    <tr *ngFor="let permission of user.permissions">
                                       <td>{{ permission.name }}</td>
                                    </tr>
                                    <span *ngIf="!user.permissions.length" class="label label-danger label-mini">Nenhuma
                                       Permissão associada a este
                                       Perfil.</span>
                                 </tbody>
                              </table>
                           </div>
                        </div> -->


                     </div>
                  </div>
                  <div class="card-footer">
                     <div class="row">
                        <div class="col-md-4">
                           <a routerLink="/security/user/edit/{{ user.id }}" class="btn btn-warning">Editar</a>
                        </div>
                        <div class="col-md-4">
                           <a routerLink="/security/user" class="btn btn-info">Voltar</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
