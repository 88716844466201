<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Contas a Receber</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="p-3" [formGroup]="billsToReceiveForm">
              <div class="row mt-2">
                <div class="col-sm-4 form-group">
                  <label>Tipo de Recebedor</label>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      name="receiverType"
                      value="client"
                      id="client"
                      formControlName="receiverType"
                    />
                    <label for="client" class="form-check-label">Cliente</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      name="receiverType"
                      value="covenant"
                      id="covenant"
                      formControlName="receiverType"
                    />
                    <label for="covenant" class="form-check-label"
                      >Convênio</label
                    >
                  </div>
                </div>
                <div class="col-sm-8">
                  <label style="font-size: 14px" class="mt-2">Pesquisar</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar Cliente/Convênio - por nome, CPF ou CNPJ"
                    formControlName="searcher"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-8">
                  <label>Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    formControlName="receiverName"
                    disabled
                  />
                </div>
                <div class="col-sm-4">
                  <label for="cost-center">Centro de Custo</label>
                  <select
                    class="form-control select2"
                    id="cost-center"
                    formControlName="costCenter"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let costCenter of costCenters"
                      value="{{ costCenter.id }}"
                    >
                      {{ costCenter.name }}
                    </option>
                  </select>
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.costCenter.errors &&
                      billsToReceiveForm.controls.costCenter.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-4">
                  <label>Data de Vencimento</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="dueDate"
                  />
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.dueDate.errors &&
                      billsToReceiveForm.controls.dueDate.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                </div>
                <div class="col-sm-4">
                  <label>Nota Fiscal/Recibo</label>
                  <input
                    class="form-control"
                    placeholder="Nota Fiscal/Recibo"
                    formControlName="invoiceNumber"
                  />
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.invoiceNumber.errors &&
                      billsToReceiveForm.controls.invoiceNumber.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Nota Fiscal/Recibo deve ter no máximo 20 caracteres</span
                  >
                </div>
                <div class="col-sm-4">
                  <label>Valor</label>
                  <input class="form-control money2" formControlName="value" />
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.value.errors &&
                      billsToReceiveForm.controls.value.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-4 form-group">
                  <label>Forma de Recebimento</label>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      name="paymentType"
                      value="in_cash"
                      id="in_cash"
                      formControlName="paymentType"
                    />
                    <label for="in_cash" class="form-check-label"
                      >A vista</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      name="paymentType"
                      value="parceled"
                      id="parceled"
                      formControlName="paymentType"
                    />
                    <label for="parceled" class="form-check-label"
                      >Parcelado</label
                    >
                  </div>
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.paymentType.errors &&
                      billsToReceiveForm.controls.paymentType.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <!-- <span
                    *ngIf="
                      billsToReceiveForm.controls.paymentType.errors &&
                      billsToReceiveForm.controls.paymentType.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  > -->
                </div>
                <div class="col-sm-4">
                  <label for="paymentMethod">Tipo de Recebimento</label>
                  <select
                    class="form-control select2"
                    formControlName="paymentMethod"
                    id="paymentMethod"
                  >
                    <option value=""></option>
                    <option value="billet">Boleto</option>
                    <option value="bank_check">Cheque</option>
                    <option value="deposit">Depósito</option>
                    <option value="money">Dinheiro</option>
                  </select>
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.paymentMethod.errors &&
                      billsToReceiveForm.controls.paymentMethod.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                </div>
                <div class="col-sm-4">
                  <label for="name">Situação</label>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="paid"
                      formControlName="paid"
                    />
                    <label class="form-check-label" for="paid">Pago</label>
                  </div>
                </div>
              </div>
              <div
                class="row mt-2"
                *ngIf="
                  this.billsToReceiveForm.getRawValue().paymentMethod ==
                  'bank_check'
                "
              >
                <div class="col-sm-4">
                  <label for="name">Banco</label>
                  <select class="form-control select2" formControlName="bank">
                    <option value=""></option>
                    <option *ngFor="let bank of banks" value="{{ bank.id }}">
                      {{ bank.name }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <label for="name">Nº do cheque</label>
                  <input
                    type="text"
                    class="form-control"
                    maxlength="10"
                    placeholder="Nº do cheque"
                    (keypress)="onlyNumber($event)"
                    formControlName="bankCheckNumber"
                  />
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.bankCheckNumber.errors &&
                      billsToReceiveForm.controls.bankCheckNumber.errors
                        .maxlength
                    "
                    class="text-danger position-right"
                    >*Máximo 10 de caracteres
                  </span>
                </div>
              </div>
              <div
                class="row mt-2"
                *ngIf="
                  this.billsToReceiveForm.getRawValue().paymentType ===
                  'parceled'
                "
              >
                <div class="col-4">
                  <label for="parcAmount">Quantidade de Parcelas</label>
                  <input
                    type="text"
                    maxlength="5"
                    placeholder="Quantidade de Parcelas"
                    class="form-control"
                    formControlName="parcAmount"
                    id="parcAmount"
                    (keypress)="onlyNumber($event)"
                  />
                  <span
                    *ngIf="
                      billsToReceiveForm.controls.parcAmount.errors &&
                      billsToReceiveForm.controls.parcAmount.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Máximo 5 de caracteres
                  </span>
                </div>
                <div class="col-4">
                  <label for="name">Periodicidade</label>
                  <select class="form-control" formControlName="frequency">
                    <option></option>
                    <option value="days">Dias</option>
                    <option value="weeks">Semanas</option>
                    <option value="fortnights">Quinzenas</option>
                    <option value="months">Meses</option>
                    <option value="bimonthly">Bimestres</option>
                    <option value="quarters">Trimestres</option>
                    <option value="semesters">Semestres</option>
                    <option value="years">Anos</option>
                  </select>
                </div>
              </div>
              <div
                class="row"
                *ngIf="
                  this.billsToReceiveForm.getRawValue().paymentMethod ==
                  'deposit'
                "
              >
                <div class="col-sm-4">
                  <label for="bank-account">Conta Bancária</label>
                  <select
                    class="form-control select2"
                    id="bank-account"
                    formControlName="bankAccount"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let bankAccount of bankAccounts"
                      value="{{ bankAccount.id }}"
                    >
                      {{ bankAccount.bankAccount + " - " + bankAccount.agency }}
                    </option>
                  </select>
                </div>
              </div>

              <table
                *ngIf="
                  this.parcels.length > 1 &&
                  this.billsToReceiveForm.getRawValue().paymentType ===
                    'parceled'
                "
                class="table table-bordered mt-5 table-mobile"
                formArrayName="parcels"
              >
                <thead>
                  <tr>
                    <th scope="col">Nº da Parcela</th>
                    <th
                      *ngIf="
                        this.billsToReceiveForm.getRawValue().paymentMethod ==
                        'bank_check'
                      "
                      scope="col"
                    >
                      Nº do Cheque
                    </th>
                    <th scope="col">Valor da Parcela</th>
                    <th scope="col">Data Vencimento Parcela</th>
                    <th scope="col">Pago</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let parcel of parcels.controls; let i = index"
                    [formGroupName]="i"
                  >
                    <td>{{ parcel.controls.number.value }}</td>
                    <td
                      *ngIf="
                        this.billsToReceiveForm.getRawValue().paymentMethod ==
                        'bank_check'
                      "
                    >
                      <input
                        type="text"
                        class="form-control"
                        formControlName="bankCheckNumber"
                        maxlength="10"
                      />
                    </td>
                    <td>
                      {{ parcel.controls.value.value | currency: "BRL" }}
                    </td>
                    <td>
                      {{ parcel.controls.dueDate.value | date: "dd/MM/yyyy" }}
                    </td>
                    <td>
                      <input type="checkbox" formControlName="paid" />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row d-flex justify-content-center my-3">
                <div class="m-2">
                  <button
                    (click)="onSubmit()"
                    [disabled]="!billsToReceiveForm.valid"
                    class="btn btn-success"
                  >
                    Salvar
                  </button>
                </div>
                <div class="m-2">
                  <a routerLink="/financial/bills-to-receive" class="btn btn-danger"
                    >Cancelar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
