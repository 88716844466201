import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { TripRequest } from 'src/app/domain/trip-request';
import { TripRequestSchedule } from 'src/app/domain/trip-request-schedule';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TripRequestService {

  url: string = `${environment.BASE_URL}/run-requests`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getTripRequests(filter: any, pagination: Pagination) {
    let getActive = filter.active ?? "";
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        cost_center: `${filter.costCenter ?? ""}`,
        payment_method: `${filter.paymentMethod ?? ""}`,
        date_start: `${filter.dateStart ?? ""}`,
        date_end: `${filter.dateEnd ?? ""}`,
        driver: `${filter.driver ?? ""}`,
        vehicle: `${filter.vehicle ?? ""}`,
        status: `${filter.status ?? ""}`,
        covenant: `${filter.covenant ?? ""}`,
        passenger: `${filter.passenger ?? ""}`,
        active: getActive
      }
    });
    return this.http.get(this.url,
      { responseType: "json", params: params });

  }

  linkDriver(data, id) {
    return this.http.put(`${this.url}/link-driver/${id}`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getTripRequest(id: number) {
    return this.http.get<TripRequest>(`${this.url}/${id}`,
      { responseType: "json" });
  }
  // getIdentifier(id: number) {
  //   let getIdentifierUrl = `${this.url}/get/identifier`;
  //   if (id) {
  //     getIdentifierUrl += `?parent_id=${id}`;
  //   }
  //   return this.http.get(getIdentifierUrl,
  //     { responseType: "json" });
  // }

  getScheduleByTripRequest(id: number) {
    return this.http.get<TripRequestSchedule>(`${this.url}/schedule/${id}`,
      { responseType: "json" });
  }

  save(data) {
    return this.http.post(`${this.url}`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(accessProfile) {
    return this.http.put(`${this.url}/${accessProfile.id}`, JSON.stringify(accessProfile), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  approveRunRequest(id: number) {
    return this.http.put(`${this.url}/approve/${id}`, JSON.stringify({}), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  cancel(data, id) {
    return this.http.put(`${this.url}/cancel/${id}`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  exportTripRequests(filter: any, type: string) {
    let getActive = filter.active ?? "";
    const params = new HttpParams({
      fromObject: {
        cost_center: `${filter.costCenter ?? ""}`,
        payment_method: `${filter.paymentMethod ?? ""}`,
        date_start: `${filter.dateStart ?? ""}`,
        date_end: `${filter.dateEnd ?? ""}`,
        driver: `${filter.driver ?? ""}`,
        vehicle: `${filter.vehicle ?? ""}`,
        status: `${filter.status ?? ""}`,
        covenant: `${filter.covenant ?? ""}`,
        passenger: `${filter.passenger ?? ""}`,
        active: getActive,
        type: type
      }
    });
    return this.http.get(`${this.url}/export`, {
      headers: this.httpHeaders,
      responseType: "blob",
      params: params,
      observe: 'response'
    });
  }

}
