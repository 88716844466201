import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Driver } from 'src/app/domain/driver';
import { ViaCep } from 'src/app/domain/viacep';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { DriverService } from 'src/app/service/driver/driver.service';
import { AddressService } from 'src/app/service/address/address.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Address } from 'src/app/domain/address';
import { Validations } from '../../validations';

import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from '../../cropper/cropper-dialog';

@Component({
  selector: 'app-driver-update',
  templateUrl: './driver-update.component.html',
  styleUrls: ['./driver-update.component.scss'],
})
export class DriverUpdateComponent implements OnInit {
  driverForm: FormGroup;
  loading = false;
  messageError = '';
  driver: Driver = new Driver();
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];
  files: File[] = [];

  image: File;
  cropped?: string;

  constructor(
    private service: DriverService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => (this.driver.id = params.id));
  }

  ngOnInit(): void {
    this.createForm();

    this.serviceAddress.getStates().subscribe({
      next: (data) => {
        this.states = data;

        this.service.getDriver(this.driver.id).subscribe({
          next: (data) => {
            this.driver = data;

            this.serviceAddress
              .getCitiesByStateId(this.driver.address.city.state.id)
              .subscribe({
                next: (data) => {
                  this.cities = data;
                  this.setValues();
                },
                error: () => {
                  Swal.fire({
                    title: 'Erro',
                    text: 'Erro ao buscar cidades!',
                    icon: 'error',
                  });
                },
              });
          },
          error: () => {
            Swal.fire({
              title: 'Erro',
              text: 'Não foi possível buscar o motorista!',
              icon: 'error',
            });
          },
        });
      },
      error: () => {
        Swal.fire({
          title: 'Erro',
          text: 'Não foi possível Buscar os Estados!',
          icon: 'error',
        });
      },
    });
  }

  onRemoveImage(event) {
    this.cropped = '';
    this.image = null;
    this.driverForm.controls.imageUrl.reset('');
  }

  onSelectImage(file) {
    if (file.size > 10000000) {
      this.image = null;
      Swal.fire('Ops...', 'A imagem não pode ser maior que 10MB!', 'error');
      return false;
    } else {
      this.image = file;
      this.onFileReader();
      return true;
    }
  }

  onFileReader() {
    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () =>
      this.driverForm.controls.imageUrl.setValue(reader.result);
  }

  onRemoveFile(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onSelectFile(event) {
    const files: File[] = event.addedFiles;

    files.forEach((file, i) => {
      if (file.size > 15000000) {
        Swal.fire('Ops...', 'O arquivo não pode ser maior que 15MB!', 'error');
      } else if (i > 5) {
        Swal.fire(
          'Ops...',
          'Número máximo de arquivos permitidos é 6!',
          'error'
        );
        return;
      } else {
        this.files.push(file);
      }
    });
  }

  onRemoveUploadedFile(file, i) {
    Swal.fire({
      title: 'Excluir anexo',
      text: 'Deseja excluir este anexo? Esta operação é irreversível',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#188ae2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((action) => {
      if (action.isConfirmed)
        this.service
          .removeUploadedFile(this.driver.id, file.id)
          .subscribe((res) => this.driver.files.splice(i, 1));
    });
  }

  onImportFile() {}

  createForm() {
    this.driverForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      cpf: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(60)],
      ],
      whatsapp: ['', [Validators.required, Validations.validwhatsapp]],
      phone: ['', [Validators.required, Validations.validPhone]],
      rg: ['', [Validators.required, Validators.maxLength(20)]],
      rgShippingSector: ['', [Validators.required, Validators.maxLength(20)]],
      cnh: ['', [Validators.required, Validators.maxLength(11)]],
      cnhCategory: ['', [Validators.required]],
      cnhValidity: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      active: ['', []],
      imageUrl: [''],
    });
  }

  setValues() {
    this.driverForm.controls.name.setValue(this.driver.name);
    this.driverForm.controls.cpf.setValue(this.driver.cpf);
    this.driverForm.controls.birthDate.setValue(this.driver.birthDate);
    this.driverForm.controls.rg.setValue(this.driver.rg);
    this.driverForm.controls.rgShippingSector.setValue(
      this.driver.rgShippingSector
    );
    this.driverForm.controls.cnh.setValue(this.driver.cnh);
    this.driverForm.controls.cnhCategory.setValue(this.driver.cnhCategory);
    this.driverForm.controls.cnhValidity.setValue(this.driver.cnhValidity);
    this.driverForm.controls.email.setValue(this.driver.email);
    this.driverForm.controls.phone.setValue(this.driver.phone);
    this.driverForm.controls.whatsapp.setValue(this.driver.whatsapp);
    this.driverForm.controls.zipCode.setValue(this.driver.address.zipCode);
    this.driverForm.controls.neighborhood.setValue(
      this.driver.address.neighborhood
    );
    this.driverForm.controls.publicPlace.setValue(
      this.driver.address.publicPlace
    );
    this.driverForm.controls.state.setValue(this.driver.address.city.state.id);
    this.driverForm.controls.city.setValue(this.driver.address.city.id);
    this.driverForm.controls.active.setValue(`${this.driver.active}`);
    if (this.driver.imageUrl)
      this.driverForm.controls.imageUrl.setValue(`${this.driver.imageUrl}`);
  }

  onSubmit() {
    this.driver.address = new Address();
    this.driver.address.city = new City();
    this.driver.name = this.driverForm.controls.name.value;
    this.driver.cpf = this.driverForm.controls.cpf.value;
    this.driver.birthDate = this.driverForm.controls.birthDate.value;
    this.driver.email = this.driverForm.controls.email.value;
    this.driver.phone = this.driverForm.controls.phone.value;
    this.driver.whatsapp = this.driverForm.controls.whatsapp.value;
    this.driver.rg = this.driverForm.controls.rg.value;
    this.driver.rgShippingSector =
      this.driverForm.controls.rgShippingSector.value;
    this.driver.cnh = this.driverForm.controls.cnh.value;
    this.driver.cnhCategory = this.driverForm.controls.cnhCategory.value;
    this.driver.cnhValidity = this.driverForm.controls.cnhValidity.value;
    this.driver.address.zipCode =
      this.driverForm.controls.zipCode.value.replace(/[^0-9,.]+/g, '');
    this.driver.address.neighborhood =
      this.driverForm.controls.neighborhood.value;
    this.driver.address.publicPlace =
      this.driverForm.controls.publicPlace.value;
    this.driver.address.city.id = Number(this.driverForm.controls.city.value);

    if (this.driverForm.controls.active.value == 'true') {
      this.driver.active = true;
    } else {
      this.driver.active = false;
    }

    this.loading = true;

    this.service.update(this.driver).subscribe(
      (data) => {
        if (this.image || this.files.length) {
          if (this.image)
            this.service
              .imageUpload(this.driver.id, this.image)
              .subscribe((data) => {
                if (!this.files.length) {
                  this.loading = false;
                  this.showSuccess();
                }
              }),
              (error) => {
                this.loading = false;
                Swal.fire(
                  'Erro ao salvar imagem',
                  'Motorista salvo, mas não foi possível salvar a imagem!',
                  'error'
                );
              };

          if (this.files.length)
            this.service
              .filesUpload(this.driver.id, this.files)
              .subscribe((data) => {
                this.loading = false;
                this.showSuccess();
              }),
              (error) => {
                this.loading = false;
                Swal.fire(
                  'Erro ao salvar imagem',
                  'Motorista salvo, mas não foi possível salvar a imagem!',
                  'error'
                );
              };
        } else {
          this.loading = false;
          this.showSuccess();
        }
        this.createForm();
      },
      (err) => {
        this.loading = false;
        this.setErrors(err.error.errors);
        Swal.fire({
          title: 'Erro ao editar',
          text: 'Não foi possível editar o motorista!',
          icon: 'error',
        });
      }
    );
  }

  showSuccess() {
    Swal.fire({
      title: 'Editado',
      text: 'Motorista editado com sucesso!!',
      icon: 'success',
    }).then(() => this.router.navigate(['maintenance/driver']));
  }

  getNormalizedText(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  onBlurCep(event): void {
    this.loading = true;

    const formattedCep: string = event.target.value.replace(/[^0-9,.]+/g, '');

    if (formattedCep.length !== 8) {
      this.driverForm.controls.zipCode.setErrors({ invalid: 'CEP inválido!' });
      this.loading = false;
      return;
    }

    this.utilsService.getCep(formattedCep).subscribe({
      next: (data) => {
        this.viacep = data;

        if (this.viacep.erro) {
          this.driverForm.controls.zipCode.setErrors({
            invalid: 'CEP não encontrado!',
          });
          this.loading = false;
          return;
        }

        this.driverForm.controls.neighborhood.setValue(this.viacep.bairro);
        this.driverForm.controls.publicPlace.setValue(this.viacep.logradouro);

        const state = this.states.find(
          (state) => state.initials === this.viacep.uf.toUpperCase()
        );

        this.driverForm.controls.state.setValue(state.id);

        this.serviceAddress.getCitiesByStateId(state.id).subscribe({
          next: (data) => {
            this.cities = data;

            const city = this.cities.find(
              (city) =>
                this.getNormalizedText(city.name) ===
                this.getNormalizedText(this.viacep.localidade.toUpperCase())
            );

            if (city) {
              this.driverForm.controls.city.setValue(city.id);
            }

            this.loading = false;
          },
          error: () => {
            this.loading = false;
            Swal.fire({
              title: 'Ops',
              text: 'Erro ao buscar cidades!',
              icon: 'error',
            });
          },
        });
      },
      error: () => {
        this.driverForm.controls.zipCode.setErrors({
          invalid: 'CEP não encontrado!',
        });
        this.loading = false;
      },
    });
  }

  onSelectedState(event): void {
    this.loading = true;

    this.serviceAddress.getCitiesByStateId(1).subscribe({
      next: (data) => {
        this.cities = data;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        Swal.fire({
          title: 'Ops',
          text: 'Erro ao buscar cidades!',
          icon: 'error',
        });
      },
    });
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'name':
          this.driverForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case 'cpf':
          this.driverForm.controls.cpf.setErrors({ invalid: erro.message });
          break;
        case 'birthDate':
          this.driverForm.controls.contact.setErrors({ invalid: erro.message });
          break;
        case 'email':
          this.driverForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case 'phone':
          this.driverForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case 'whatsapp':
          this.driverForm.controls.whatsapp.setErrors({
            invalid: erro.message,
          });
          break;
        case 'rg':
          this.driverForm.controls.rg.setErrors({ invalid: erro.message });
          break;
        case 'rgShippingSector':
          this.driverForm.controls.rgShippingSector.setErrors({
            invalid: erro.message,
          });
          break;
        case 'cnh':
          this.driverForm.controls.cnh.setErrors({ invalid: erro.message });
          break;
        case 'cnhCategory':
          this.driverForm.controls.cnhCategory.setErrors({
            invalid: erro.message,
          });
          break;
        case 'cnhValidity':
          this.driverForm.controls.cnhValidity.setErrors({
            invalid: erro.message,
          });
          break;
        case 'zipCode':
          this.driverForm.controls.zipCode.setErrors({ invalid: erro.message });
          break;
        case 'neighborhood':
          this.driverForm.controls.neighborhood.setErrors({
            invalid: erro.message,
          });
          break;
        case 'publicPlace':
          this.driverForm.controls.publicPlace.setErrors({
            invalid: erro.message,
          });
          break;
        case 'city':
          this.driverForm.controls.city.setErrors({ invalid: erro.message });
          break;
        case 'state':
          this.driverForm.controls.state.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openCropperDialog(event) {
    const imgFile = event.target.files['0'];
    let selImg = this.onSelectImage(imgFile);

    if (selImg) {
      this.cropped = null!;
      this._dialog
        .open<CropperDialog, Event>(CropperDialog, {
          data: event,
          width: 320,
          disableClose: true,
        })
        .afterClosed.subscribe((result?: ImgCropperEvent) => {
          if (result) {
            this.cropped = result.dataURL;
            this._cd.markForCheck();
          }
        });
    }
  }
}
