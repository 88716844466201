import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Car } from 'src/app/domain/car';
import { Pagination } from 'src/app/domain/pagination';
import { CarDTO } from 'src/app/domain/dto/car.dto';
import { Driver } from 'src/app/domain/driver';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  url: string = `${environment.BASE_URL}/vehicles`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCars(car: Car, pagination: Pagination) {

    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      brand: `${car.model.brand.id ?? ""}`,
      model: `${car.model.id ?? ""}`,
      car_plate: `${car.carPlate ?? ""}`,
      expiration_date_ipva: `${car.expirationDateIpva ?? ""}`,
    };


    return this.http.get(this.url,
      { responseType: "json", params: params });

  }

  getCarsActives() {
    return this.http.get<Array<Car>>(`${this.url}/all-active`,
      { responseType: "json" });

  }

  getCar(id: number) {
    return this.http.get<Car>(`${this.url}/${id}`,
      { responseType: "json" });

  }

  getDriversByCar(id: number) {
    return this.http.get<Array<Driver>>(`${this.url}/${id}/drivers`,
      { responseType: "json" });

  }

  save(car: Car) {
    let dto = new CarDTO(car);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(car: Car) {
    let dto = new CarDTO(car);
    return this.http.put(`${this.url}/${car.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/activate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getAllCars() {
    return this.http.get<Array<Car>>(`${this.url}/all`,
      { responseType: "json" });
  }

  imageUpload(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.url}/${id}/image-upload`, formData);
  }

}
