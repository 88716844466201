<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar Peça</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Nome</b>
                    </div>
                    <div class="col-md-12">
                      {{ piece.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Hodômetro da Troca</b>
                    </div>
                    <div class="col-md-12">
                      {{ piece.exchangeOdometer }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Status</b>
                    </div>
                    <div class="col-md-12">
                      <span
                        *ngIf="piece.active"
                        class="label label-sucess label-mini"
                        >Ativo</span
                      >
                      <span
                        *ngIf="!piece.active"
                        class="label label-danger label-mini"
                        >Inativo</span
                      >
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-4">
                  <a routerLink="/parameters/piece/edit/{{ piece.id }}" class="btn btn-warning"
                    >Editar</a
                  >
                </div>
                <div class="col-md-4">
                  <a routerLink="/parameters/piece" class="btn btn-info">Voltar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
