import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BillsToReceive } from 'src/app/domain/bills-to-receive';
import { BillsToReceiveService } from 'src/app/service/bills-to-receive/bills-to-receive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bills-to-receive-view',
  templateUrl: './bills-to-receive-view.component.html',
  styleUrls: ['./bills-to-receive-view.component.css']
})
export class BillsToReceiveViewComponent implements OnInit {
  billsToPayForm: FormGroup;
  billsToReceive: BillsToReceive = new BillsToReceive();
  loading: boolean = true;
  id: number;

  constructor(
    private service: BillsToReceiveService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.service.getBillToReceive(this.id).subscribe(
      (data) => {
        this.billsToReceive = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar A conta a receber!', 'error');
      }
    )
  }

  getPaymentMethod(method: string) {
    const paymentMethods = {
      billet: 'Boleto',
      bank_check: 'Cheque',
      deposit: 'Depósito',
      money: 'Dinheiro',
    };

    return paymentMethods[method];
  }

  getFrequency(item: BillsToReceive) {
    const date1 = new Date(item.dueDate);
    const date2 = new Date(item.parcels[0].dueDate);
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = (year2 - year1) * 12 + (month2 - month1);

    let frequency = '';

    switch (diffDays) {
      case 1:
        frequency = 'Dias';
        break;
      case 7:
        frequency = 'Semanas';
        break;
      case 14:
      case 15:
        frequency = 'Quinzenas';
        break;
      default:
        frequency = '';
    }

    if (!frequency) {
      switch (diffMonths) {
        case 1:
          frequency = 'Meses';
          break;
        case 2:
          frequency = 'Bimestres';
          break;
        case 3:
          frequency = 'Trimestres';
          break;
        case 6:
          frequency = 'Semestres';
          break;
        case 12:
          frequency = 'Anos';
          break;
        default:
          frequency = '';
      }
    }

    return frequency;
  }
}
