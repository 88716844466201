<!-- <app-card-box color="green" icon="group" title="Vendas" total=500 percentage="50" description="50% do total">
</app-card-box>

<app-card-box color="black" icon="person" title="Receitas" total=500.90 percentage="80" description="80% do total">
</app-card-box> -->

<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <app-runs-recents></app-runs-recents>
    <app-chart-runs-by-hour></app-chart-runs-by-hour>
  </div>
</div>

<!-- <app-footer></app-footer> -->
