import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  loading: boolean = false;
  send: boolean = false;
  submited: boolean = false;

  constructor(
    private service: LoginService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  onSubmit() {

    this.submited = true;
    if (!this.forgotForm.invalid) {
      this.loading = true;

      this.service.forgot(this.forgotForm.controls.email.value).subscribe(data => {
        console.log(data);
        this.loading = false;
        this.send = true;

      }, error => {
        this.loading = false;
        this.send = false;
        error.error.errors.forEach(erro => {
          if (erro.fieldName === 'email') {
            this.forgotForm.controls.email.setErrors({ invalid: erro.message });
          }
          if (erro.fieldName === 'user') {
            this.forgotForm.controls.email.setErrors({ user: erro.message });
          }
        });


      });
    } else {
      // if(this.forgotForm.controls.email.value.length() === 0){this.forgotForm.controls.email.setErrors()}
    }


  }

  createForm() {
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
}
