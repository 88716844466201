import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerateBillsToReceiveService } from 'src/app/service/generate-bills-to-receive/generate-bills-to-receive.service';

@Component({
  selector: 'app-generate-bills-to-receive-paid',
  templateUrl: './generate-bills-to-receive-paid.component.html',
  styleUrls: ['./generate-bills-to-receive-paid.component.css']
})
export class GenerateBillsToReceivePaidComponent implements OnInit {
  billToPaid: any = {};
  loading: boolean = true;
  messageError: string = '';
  id: number;

  constructor(
    private service: GenerateBillsToReceiveService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.service.getGenerateBill(this.id).subscribe(
      (data) => {
        this.billToPaid = data;

        this.billToPaid.runs.forEach( run => {
          let originAddress, destinyAddress = ''

          run.runPassengers.forEach(({ isMain, origin, destiny }) => {
            if (isMain && origin) {
              originAddress = origin.city.name;
              destinyAddress = destiny.city.name;
            }
          });
          run.origin = originAddress;
          run.destiny = destinyAddress;
        })

        this.loading = false;
      },
      (_error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível carregar a conta gerada!', 'error');
      }
    );
  }

  getTotalValue(runs = []) {
    let value = 0;
    runs.forEach(run => {
      value += run.runEndValue
    });

    return value;
  }

  paidGenerateBill() {
    Swal.fire({
      title: 'Pagar Conta a receber?',
      text: `Código: ${this.id}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero marcar como paga!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.setPaid(this.id).subscribe(data => {
          Swal.fire({
            title: 'Pago',
            text: `Conta a receber paga com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            this.router.navigate(['financial/generate-bills-to-receive']);
          })
        },
          error => {
            Swal.fire('Erro ao pagar Conta a Receber!', `${error.error.errors[0].message}`, 'error');
          });
      }
    });

  }
}
