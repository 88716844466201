<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div *ngIf="messageError.length" class="container">
            <div class="alert alert-danger" role="alert">
              {{ messageError }}
            </div>
          </div>
          <div class="row mt-4 mb-2">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Manutenção</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Marca</b>
                </div>
                <div class="col-md-12">
                  {{ car?.model?.brand?.name }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Modelo</b>
                </div>
                <div class="col-md-12">
                  {{ car?.model?.name }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Placa</b>
                </div>
                <div class="col-md-12">
                  {{ car?.carPlate }}
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="carMaintenanceForm">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fornecedor">Fornecedor</label>
                      <ng-select
                        class="custom-select custom-select"
                        style="font-size: 14px"
                        formControlName="fornecedor"
                        [items]="providers"
                        bindLabel="name"
                        bindValue="id"
                      >
                      </ng-select>
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.fornecedor.errors &&
                          carMaintenanceForm.controls.fornecedor.errors.required
                        "
                        class="text-danger position-right"
                        >*Campo obrigatório</span
                      >
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.fornecedor.errors &&
                          carMaintenanceForm.controls.fornecedor.errors.invalid
                        "
                        class="text-danger position-right"
                        >*
                        {{
                          carMaintenanceForm.controls.fornecedor.errors.invalid
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="data">Data</label>
                      <input
                        type="Date"
                        class="form-control"
                        placeholder="Data"
                        formControlName="data"
                      />
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.data.errors &&
                          carMaintenanceForm.controls.data.errors.required
                        "
                        class="text-danger position-right"
                        >*Campo obrigatório</span
                      >
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.data.errors &&
                          carMaintenanceForm.controls.data.errors.invalid
                        "
                        class="text-danger position-right"
                        >* {{ carMaintenanceForm.controls.data.errors.invalid }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="odometer">Hodômetro</label>
                      <input
                        type="text"
                        class="form-control"
                        id="odometer"
                        formControlName="odometer"
                        placeholder="Hodômetro"
                      />
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.odometer.errors &&
                          carMaintenanceForm.controls.odometer.errors.required
                        "
                        class="text-danger position-right"
                        >*Campo obrigatório</span
                      >
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.odometer.errors &&
                          carMaintenanceForm.controls.odometer.errors.maxlength
                        "
                        class="text-danger position-right"
                        >*Hodômetro deve ter no máximo 10 caracteres</span
                      >
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.odometer.errors &&
                          carMaintenanceForm.controls.odometer.errors.invalid
                        "
                        class="text-danger position-right"
                        >*
                        {{
                          carMaintenanceForm.controls.odometer.errors.invalid
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="valor">Valor (R$)</label>
                      <input
                        type="text"
                        class="form-control money"
                        id="valor"
                        formControlName="valor"
                        placeholder="Valor (R$)"
                        currencyMask
                        [options]="{
                          prefix: 'R$ ',
                          thousands: '.',
                          decimal: ','
                        }"
                      />
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.valor.errors &&
                          carMaintenanceForm.controls.valor.errors.required
                        "
                        class="text-danger position-right"
                        >*Campo obrigatório</span
                      >
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.valor.errors &&
                          carMaintenanceForm.controls.valor.errors.maxlength
                        "
                        class="text-danger position-right"
                        >*Valor deve ter no máximo 10 caracteres</span
                      >
                      <span
                        *ngIf="
                          carMaintenanceForm.controls.valor.errors &&
                          !carMaintenanceForm.controls.valor.errors.required &&
                          carMaintenanceForm.controls.valor.errors.invalid
                        "
                        class="text-danger position-right"
                        >*
                        {{ carMaintenanceForm.controls.valor.errors.invalid }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pago">Pago </label>
                      <div
                        class="custom-control custom-radio custom-control-inline mt-2"
                      >
                        <input
                          type="radio"
                          id="true"
                          [value]="true"
                          name="pago"
                          formControlName="pago"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" for="true"
                          >Sim</label
                        >
                      </div>
                      <div
                        class="custom-control custom-radio custom-control-inline"
                      >
                        <input
                          type="radio"
                          id="false"
                          [value]="false"
                          name="pago"
                          formControlName="pago"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" for="false"
                          >Não</label
                        >
                      </div>
                    </div>
                    <span
                      *ngIf="
                        carMaintenanceForm.controls.pago.errors &&
                        carMaintenanceForm.controls.pago.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo obrigatório</span
                    >
                    <span
                      *ngIf="
                        carMaintenanceForm.controls.pago.errors &&
                        carMaintenanceForm.controls.pago.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ carMaintenanceForm.controls.pago.errors.invalid }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="pecas">Peças</label>
                  <div class="input-group">
                    <ng-select
                      class="custom-select custom-select"
                      style="font-size: 14px"
                      formControlName="pecas"
                      [items]="pieces"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                    <div class="input-group-append">
                      <button
                        class="btn btn-info btn-xs"
                        (click)="
                          onPieceAdd(carMaintenanceForm.controls.pecas.value)
                        "
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <span
                    *ngIf="
                      carMaintenanceForm.controls.pecas.errors &&
                      carMaintenanceForm.controls.pecas.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carMaintenanceForm.controls.pecas.errors &&
                      carMaintenanceForm.controls.pecas.errors.invalid
                    "
                    class="text-danger position-right"
                    >*
                    {{ carMaintenanceForm.controls.pecas.errors.invalid }}
                  </span>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped custom-table table-hover">
                    <thead>
                      <tr>
                        <th>Peça</th>
                        <th>Próxima troca</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let piece of maintenancePieces; let i = index"
                      >
                        <td>
                          {{ piece.name }}
                        </td>
                        <td>
                          {{
                            +piece.exchangeOdometer +
                              (carMaintenanceForm.controls.odometer.value
                                ? +carMaintenanceForm.controls.odometer.value
                                : 0)
                          }}
                        </td>
                        <td>
                          <button
                            (click)="onPieceDelete(i)"
                            class="btn btn-danger btn-xs"
                          >
                            <i class="fa fa-trash-o"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12 mt-3 text-center">
                <button
                  (click)="onSubmit()"
                  [disabled]="!carMaintenanceForm.valid"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >
                  Salvar
                </button>
              </div>
            </div>
            <div class="row mt-5" [formGroup]="searchCarMaintenancesForm">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="posto">Fornecedor</label>
                  <ng-select
                    class="custom-select custom-select"
                    style="font-size: 14px"
                    formControlName="providerId"
                    [items]="providers"
                    bindLabel="name"
                    bindValue="id"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="data">Data Inicial</label>
                  <input
                    type="Date"
                    class="form-control"
                    formControlName="start_date"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="data">Data Final</label>
                  <input
                    type="Date"
                    class="form-control"
                    formControlName="end_date"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="paid">Pago</label>
                  <select
                    formControlName="paid"
                    class="custom-select custom-select-md"
                  >
                    <option value="">Ambos</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>
              <div class="col mt-4">
                <button
                  (click)="exportCarMaintenance('pdf')"
                  class="btn btn-danger mr-3"
                  title="Exportar para PDF"
                >
                  <i
                    class="fa fa-file-pdf-o fa-lg trip-request__button__search"
                    aria-hidden="true"
                  ></i>
                </button>
                <button
                  (click)="exportCarMaintenance('excel')"
                  class="btn btn-success"
                  title="Exportar para Excel"
                >
                  <i
                    class="fa fa-file-excel-o fa-lg trip-request__button__search"
                    aria-hidden="true"
                  ></i>
                </button>
                <!-- <span class="input-group-btn search-btn"> -->
                <a (click)="getMaintenances()" class="btn btn-info ml-5">
                  <i class="fa fa-search"></i>
                </a>
                <!-- </span> -->
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped custom-table table-hover">
                <thead>
                  <tr>
                    <th>Fornecedor</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Pago</th>
                    <th>Hodômetro</th>
                    <th>Peça</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let maintenance of maintenances">
                    <td>
                      {{ maintenance.provider.name }}
                    </td>
                    <td>
                      {{ maintenance.date | date : "dd/MM/yyyy" }}
                    </td>
                    <td>
                      {{ maintenance.value | currency : "BRL" }}
                    </td>
                    <td>
                      <span *ngIf="maintenance.paid"> Sim </span>
                      <span *ngIf="!maintenance.paid"> Não </span>
                    </td>
                    <td>
                      {{ maintenance.odometer }}
                    </td>
                    <td>
                      <ul>
                        <li
                          *ngFor="
                            let vehicleMaintenance of maintenance.vehicleMaintenances
                          "
                        >
                          {{ vehicleMaintenance.piece.name }} - Próxima troca:
                          {{
                            +vehicleMaintenance.piece.exchangeOdometer +
                              +maintenance.odometer
                          }}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <a
                        (click)="onMaintenanceEdit(maintenance)"
                        class="btn btn-warning btn-xs"
                        title="Editar Multa"
                      >
                        <i class="fa fa-edit"></i>
                      </a>
                      <button
                        (click)="onMaintenanceDelete(maintenance.id)"
                        class="btn btn-danger btn-xs"
                      >
                        <i class="fa fa-trash-o"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-12">
                  <span
                    *ngIf="!maintenances.length"
                    class="label label-danger label-mini"
                  >
                    Nenhuma Manutenção encontrada.
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item" [class.disabled]="!first">
                      <button
                        class="page-link"
                        (click)="onFirst()"
                        tabindex="-1"
                      >
                        Primeira
                      </button>
                    </li>
                    <li class="page-item" *ngIf="pagination.page != 1">
                      <button (click)="onPrevious()" class="page-link">
                        {{ pagination.page - 1 }}
                      </button>
                    </li>
                    <li class="page-item active">
                      <button class="page-link">{{ pagination.page }}</button>
                    </li>
                    <li class="page-item" *ngIf="last">
                      <button (click)="onNext()" class="page-link" href="#">
                        {{ pagination.page + 1 }}
                      </button>
                    </li>
                    <li class="page-item" [class.disabled]="!last">
                      <button class="page-link" (click)="onLast()">
                        Última
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-md-12">
                Mostrando página {{ pagination.page }} de
                {{ pagination.totalPages }} dos
                {{ pagination.totalResults }} Resultados
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-md-12 text-center">
                <a
                  routerLink="/maintenance/car/view/{{ carId }}"
                  class="btn btn-danger"
                  >Voltar</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
