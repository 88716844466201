<div ly-dialog-content [lyPx]="0">

  <div *ngIf="ready">
    <button (click)="cropper.zoomIn()" ly-button appearance="icon" lyTooltip="Aumentar Zoom">
      <ly-icon>zoom_in</ly-icon>
    </button>
    <button (click)="cropper.zoomOut()" ly-button appearance="icon" lyTooltip="Reduzir Zoom">
      <ly-icon>zoom_out</ly-icon>
    </button>
    <button (click)="cropper.center()" ly-button appearance="icon" lyTooltip="Centralizar">
      <ly-icon>filter_center_focus</ly-icon>
    </button>
    <button (click)="cropper.rotate(-90)" ly-button appearance="icon" lyTooltip="Girar">
      <ly-icon>rotate_90_degrees_ccw</ly-icon>
    </button>
    <button (click)="cropper.fit()" ly-button appearance="icon" lyTooltip="Ajustar">
      <ly-icon>fullscreen_exit</ly-icon>
    </button>
    <button (click)="cropper.fitToScreen()" ly-button appearance="icon" lyTooltip="Preencher">
      <ly-icon>fullscreen</ly-icon>
    </button>
    <button (click)="cropper.setScale(1)" ly-button appearance="icon" lyTooltip="(1:1)">
      <ly-icon>zoom_out_map</ly-icon>
    </button>
  </div>

  <ly-img-cropper [config]="myConfig" [(scale)]="scale" (ready)="ready = true" (cleaned)="ready = false"
    (minScale)="minScale = $event" (cropped)="onCropped($event)" (loaded)="onLoaded($event)" (error)="onError($event)">
    <span>Drag and drop image</span>
  </ly-img-cropper>

  <div *ngIf="ready" [className]="classes.sliderContainer">
    <div [class]="classes.slider">
      <ly-slider [thumbVisible]="false" [min]="minScale" [max]="1" [(ngModel)]="scale" (input)="scale = $event.value"
        step="0.000001"></ly-slider>
    </div>
  </div>

</div>

<div container *ngIf="ready" [lyJustifyContent]="'flex-end'" ly-dialog-actions>
  <button ly-button color="primary" (click)="dialogRef.close()">CANCELAR</button>
  <button ly-button color="primary" (click)="dialogRef.close(cropper.crop())">CORTAR</button>
</div>
