import { Address } from './address';
import { DriverFile } from './driver-file';

export class Driver {
    id: number;
    name: string;
    cpf: string;
    birthDate: Date;
    email: string;
    phone: string;
    whatsapp: string;
    rg: string;
    rgShippingSector: string;
    cnh: string;
    cnhCategory: string;
    cnhValidity: Date;
    password: number;
    passwordHash: string;
    active: boolean;
    activationToken: string;
    qualification: number;
    numberRuns: number;
    runningMinutes: number;
    runningHours: number;
    address: Address;
    imageUrl: string;
    files: DriverFile[];
    canResendActivation: boolean;
}
