<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
   <div class="page-content">
      <div class="page-bar">
         <div class="page-title-breadcrumb">
            <div class="container">
               <div class="row">
                  <div class="col-md-10">
                     <div class="page-title">Cadastrar Perfil de Acesso </div>
                  </div>
               </div>
               <div [formGroup]="accessProfileForm">
                  <!--div com o form -->
                  <div class="form-group">
                     <label for="name">Perfil de Acesso</label>
                     <input type="text" class="form-control" id="name" formControlName="name"
                        placeholder="Nome do Perfil de Acesso">
                     <span
                        *ngIf="accessProfileForm.controls.name.errors && accessProfileForm.controls.name.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span
                        *ngIf="(accessProfileForm.controls.name.errors && accessProfileForm.controls.name.errors.minlength) || (accessProfileForm.controls.name.errors && accessProfileForm.controls.name.errors.maxlength)"
                        class="text-danger position-right">*Nome do
                        Perfil deve ter
                        entre 5 e 25 caracteres</span>
                  </div>

                  <div class="form-group ">
                     <label for="description">Descrição</label>
                     <textarea class="form-control" id="description" formControlName="description"
                        placeholder="Descrição do Perfil de Acesso" rows="5"></textarea>
                     <span
                        *ngIf="accessProfileForm.controls.description.errors && accessProfileForm.controls.description.errors.maxlength"
                        class="text-danger position-right">*Descrição deve ter no máximo 250 caracteres</span>
                  </div>

                  <!-- <div class="form-group">
                     <label for="active">Status</label>
                     <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="true" value="true" name="active" formControlName="active"
                           class="custom-control-input">
                        <label class="custom-control-label" for="true">Ativo</label>
                     </div>
                     <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="false" value="false" name="active" formControlName="active"
                           class="custom-control-input">
                        <label class="custom-control-label" for="false">Inativo</label>
                     </div>
                  </div> -->
                  <div class="row">&nbsp;</div>
                  <div class="card card-topline-green">
                     <div class="card-head">
                        <header>Permissões</header>

                     </div>
                     <div class="card-body ">
                        <div formArrayName="permissions">
                           <div class="row space-botton" *ngFor="let permission of permissions.controls; let i=index">
                              <div class="col-md-10">{{ perm[i].name }} </div>
                              <div class="col-md-2">
                                 <div class="setting-item">
                                    <div class="setting-set">
                                       <div class="switch">
                                          <label class="mdl-switch mdl-js-switch mdl-js-ripple-effect" for="{{i}}">
                                             <input type="checkbox" [formControlName]="i" id="{{i}}"
                                                class="mdl-switch__input">
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card-footer" *ngIf="validPermissions()">
                        <span class="text-danger position-right">*Selecione pelo menos 1 Permissão</span>
                     </div>
                  </div>

                  <div class="row">
                     <div class="col-md-3"></div>
                     <div class="col-md-3 text-right">
                        <button (click)="onSubmit()" [disabled]="!accessProfileForm.valid || validPermissions()"
                           class="btn btn-success">Salvar</button>
                     </div>
                     <div class="col-md-3">
                        <a routerLink="/security/access-profile" class="btn btn-danger">Cancelar</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
