import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AccessProfile } from 'src/app/domain/access-profile';
import { Observable } from 'rxjs';
import { AccessProfileDTO } from 'src/app/domain/dto/access-profile.dto';

@Injectable({
  providedIn: 'root'
})
export class AccessProfileService {

  url: string = `${environment.BASE_URL}/access-profiles`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getAccessProfiles(filter: String, active: any, pagination: Pagination) {
    let getActive = active ? `${active}` : "";
    const params = new HttpParams({
      fromObject: {
        page: `${pagination.page}`,
        per_page: `${pagination.per_page}`,
        name: `${filter}`,
        active: getActive
      }
    });
    return this.http.get(this.url,
      { responseType: "json", params: params });

  }
  getAllAccessProfiles() {
    return this.http.get<Array<AccessProfile>>(`${this.url}/all-active`,
      { responseType: "json" });

  }
  getAccessProfile(id: number) {
    return this.http.get<AccessProfile>(`${this.url}/${id}`,
      { responseType: "json" });

  }
  save(accessProfile: AccessProfile) {
    let dto = new AccessProfileDTO(accessProfile);
    dto.active = true;

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(accessProfile: AccessProfile) {
    let dto = new AccessProfileDTO(accessProfile);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${accessProfile.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

}
