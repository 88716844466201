import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Car } from 'src/app/domain/car';
import { ViaCep } from 'src/app/domain/viacep';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { CarService } from 'src/app/service/car/car.service';
import { AddressService } from 'src/app/service/address/address.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Address } from 'src/app/domain/address';
import { Insurer } from 'src/app/domain/insurer';
import { CarModel } from 'src/app/domain/car-model';
import { CarBrand } from 'src/app/domain/car-brand';
import { CarMotor } from 'src/app/domain/car-motor';
import { CarModelService } from 'src/app/service/car-model/car-model.service';
import { CarBrandService } from 'src/app/service/car-brand/car-brand.service';
import { CarMotorService } from 'src/app/service/car-motor/car-motor.service';
import { InsurerService } from 'src/app/service/insurer/insurer.service';
import { Pagination } from 'src/app/domain/pagination';

import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialog } from '../../cropper/cropper-dialog';

@Component({
  selector: 'app-car-update',
  templateUrl: './car-update.component.html',
  styleUrls: ['./car-update.component.scss'],
})
export class CarUpdateComponent implements OnInit {
  carForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  car: Car = new Car();
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];

  years: Array<Number> = [];
  insurers: Array<Insurer> = [];
  vehicleModels: Array<CarModel> = [];
  vehicleBrands: Array<CarBrand> = [];
  motors: Array<CarMotor> = [];

  image: File;
  cropped?: string;

  constructor(
    private service: CarService,
    private serviceAddress: AddressService,
    private serviceModel: CarModelService,
    private serviceBrand: CarBrandService,
    private serviceMotor: CarMotorService,
    private serviceInsurer: InsurerService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef
  ) {
    this.route.params.subscribe((params) => {
      this.car.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getYearList();

    this.getMotors();
    this.getInsurers();

    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;

        this.service.getCar(this.car.id).subscribe(
          (data) => {
            this.car = data;
            this.getBrands();

            if (this.car.city?.state.id) {
              this.serviceAddress
                .getCitiesByStateId(this.car.city.state.id)
                .subscribe(
                  (data) => {
                    this.cities = data;
                    this.setValues();
                  },
                  (error) => {
                    Swal.fire('Erro', 'Erro ao buscar cidades!', 'error');
                  }
                );
            } else {
              this.setValues();
            }
          },
          (error) => {
            this.loading = false;
            Swal.fire('Erro', 'Não foi possível Buscar Veículo!', 'error');
          }
        );
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  getYearList() {
    let lastYear = new Date().getFullYear();
    let firstYear = lastYear - 10;
    for (let i = lastYear; i >= firstYear; i--) {
      this.years.push(i);
    }
  }

  getModels(id: number) {
    this.loading = true;
    this.serviceModel.getCarModelsByBrand(id).subscribe(
      (data) => {
        this.vehicleModels = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível buscar os modelos!', 'error');
      }
    );
  }
  onSelected(event) {
    this.vehicleModels = [];
    this.getModels(event.target.value);
  }

  getBrands() {
    this.serviceBrand.getAllCarBrands().subscribe(
      (data) => {
        this.vehicleBrands = data;

        this.getModels(this.car.model.brand.id);
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível buscar as marcas!', 'error');
      }
    );
  }

  getMotors() {
    this.serviceMotor.getCarMotors().subscribe(
      (data) => {
        this.motors = data['results'];
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível buscar os motores!', 'error');
      }
    );
  }

  getInsurers() {
    this.serviceInsurer.getInsurers(new Insurer(), new Pagination()).subscribe(
      (data) => {
        this.insurers = data['results'];
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível buscar as seguradoras!', 'error');
      }
    );
  }

  createForm() {
    this.carForm = this.fb.group({
      marca: ['', [Validators.required]],
      modelo: ['', [Validators.required]],
      motor: ['', [Validators.required]],
      cor: ['', [Validators.required, Validators.maxLength(10)]],
      combustivel: ['', [Validators.required]],
      placa: ['', [Validators.required, Validators.maxLength(7)]],
      renavam: ['', [Validators.required, Validators.maxLength(11)]],
      chassi: ['', [Validators.required, Validators.maxLength(17)]],
      ipva: ['', [Validators.required]],
      hodometro: ['', [Validators.required, Validators.maxLength(10)]],
      arCondicionado: ['', []],
      categoria: ['', [Validators.required]],
      type: ['', [Validators.required]],
      ano: ['', [Validators.required]],
      proprietario: ['', [Validators.required]],
      seguradora: ['', [Validators.required]],
      insurancePolicy: ['', [Validators.required, Validators.maxLength(30)]],
      dueDateInsurancePolicy: ['', [Validators.required]],
      qtdPassageiros: ['', [Validators.required, Validators.maxLength(2)]],
      nMalasGrandes: ['', [Validators.maxLength(2)]],
      nMalasPequenas: ['', [Validators.maxLength(2)]],
      estado: [''],
      cidade: [''],
      imageUrl: [''],
      // active: ['', Validators.required]
    });
  }

  setValues() {
    this.carForm.controls.marca.setValue(`${this.car.model.brand.id}`);
    this.carForm.controls.modelo.setValue(this.car.model.id);
    this.carForm.controls.motor.setValue(this.car.motor.id);
    this.carForm.controls.cor.setValue(this.car.color);
    this.carForm.controls.cor.setValue(this.car.color);
    this.carForm.controls.ano.setValue(this.car.year);
    this.carForm.controls.placa.setValue(this.car.carPlate);
    this.carForm.controls.renavam.setValue(this.car.renavam);
    this.carForm.controls.chassi.setValue(this.car.chassi);
    this.carForm.controls.ipva.setValue(this.car.expirationDateIpva);
    this.carForm.controls.hodometro.setValue(this.car.pedometer);
    this.carForm.controls.arCondicionado.setValue(`${this.car.carAir}`);
    this.carForm.controls.categoria.setValue(this.car.category);
    this.carForm.controls.type.setValue(this.car.type);
    this.carForm.controls.combustivel.setValue(this.car.fuel);
    this.carForm.controls.seguradora.setValue(this.car.insurer.id);
    this.carForm.controls.insurancePolicy.setValue(this.car.insurancePolicy);
    this.carForm.controls.dueDateInsurancePolicy.setValue(
      this.car.dueDateInsurancePolicy
    );
    this.carForm.controls.qtdPassageiros.setValue(this.car.numberPassengers);
    this.carForm.controls.nMalasGrandes.setValue(this.car.numberLargeSuitcases);
    this.carForm.controls.nMalasPequenas.setValue(
      this.car.numberSmallSuitcases
    );
    this.carForm.controls.proprietario.setValue(this.car.owner);
    this.carForm.controls.cidade.setValue(this.car.city?.id || '');
    this.carForm.controls.estado.setValue(this.car.city?.state?.id || '');
    if (this.car.imageUrl)
      this.carForm.controls.imageUrl.setValue(this.car.imageUrl);
    // this.carForm.controls.active.setValue(`${this.car.active}`);
  }

  onRemoveImage(event) {
    this.cropped = '';
    this.image = null;
    this.carForm.controls.imageUrl.reset('');
  }

  onSelectImage(file) {
    if (file.size > 10000000) {
      this.image = null;
      Swal.fire('Ops...', 'A imagem não pode ser maior que 10MB!', 'error');
      return false;
    } else {
      this.image = file;
      this.onFileReader();
      return true;
    }
  }

  onFileReader() {
    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () =>
      this.carForm.controls.imageUrl.setValue(reader.result);
  }

  onSubmit() {
    this.car.city = new City();
    this.car.vehicleModel = new CarModel();
    this.car.vehicleModel.vehicleBrand = new CarBrand();
    this.car.motor = new CarMotor();
    this.car.insurer = new Insurer();
    this.car.city = new City();
    this.car.vehicleModel.vehicleBrand.id = this.carForm.controls.marca.value;
    this.car.vehicleModel.id = this.carForm.controls.modelo.value;
    this.car.motor.id = this.carForm.controls.motor.value;
    this.car.color = this.carForm.controls.cor.value;
    this.car.year = this.carForm.controls.ano.value;
    this.car.fuel = this.carForm.controls.combustivel.value;
    this.car.carPlate = this.carForm.controls.placa.value;
    this.car.renavam = this.carForm.controls.renavam.value;
    this.car.chassi = this.carForm.controls.chassi.value;
    this.car.expirationDateIpva = this.carForm.controls.ipva.value;
    this.car.pedometer = this.carForm.controls.hodometro.value;
    this.car.carAir = this.carForm.controls.arCondicionado.value;
    this.car.category = this.carForm.controls.categoria.value;
    this.car.type = this.carForm.controls.type.value;
    this.car.insurer.id = this.carForm.controls.seguradora.value;
    this.car.insurancePolicy = this.carForm.controls.insurancePolicy.value;
    this.car.dueDateInsurancePolicy =
      this.carForm.controls.dueDateInsurancePolicy.value;

    this.car.owner = this.carForm.controls.proprietario.value;
    this.car.numberPassengers = this.carForm.controls.qtdPassageiros.value;
    this.car.numberLargeSuitcases = this.carForm.controls.nMalasGrandes.value;
    this.car.numberSmallSuitcases = this.carForm.controls.nMalasPequenas.value;
    this.car.city.id = this.carForm.controls.cidade.value;
    this.car.city.state = this.carForm.controls.estado.value;

    // if (this.carForm.controls.active.value == "true") {
    //   this.car.active = true
    // } else {
    //   this.car.active = false;
    // }
    this.loading = true;
    this.service.update(this.car).subscribe(
      (data) => {
        if (this.image) {
          this.service
            .imageUpload(this.car.id, this.image)
            .subscribe((data) => {
              this.loading = false;
              this.showSuccess();
            }),
            (error) => {
              this.loading = false;
              Swal.fire(
                'Erro ao salvar imagem',
                'Convênio salvo, mas não foi possível salvar a imagem!',
                'error'
              );
            };
        } else {
          this.loading = false;
          this.showSuccess();
        }
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao editar',
          'Não foi possível editar Veículo!',
          'error'
        );
      }
    );
  }

  showSuccess() {
    Swal.fire('Editado', 'Veículo editado com sucesso!!', 'success').then(
      (result) => {
        if (result.value) {
          this.router.navigate(['maintenance/car']);
        }
      }
    );
  }

  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'marca':
          this.carForm.controls.marca.setErrors({ invalid: erro.message });
          break;
        case 'modelo':
          this.carForm.controls.modelo.setErrors({ invalid: erro.message });
          break;
        case 'motor':
          this.carForm.controls.motor.setErrors({ invalid: erro.message });
          break;
        case 'cor':
          this.carForm.controls.cor.setErrors({ invalid: erro.message });
          break;
        case 'ano':
          this.carForm.controls.ano.setErrors({ invalid: erro.message });
          break;
        case 'combustivel':
          this.carForm.controls.combustivel.setErrors({
            invalid: erro.message,
          });
          break;
        case 'placa':
          this.carForm.controls.placa.setErrors({ invalid: erro.message });
          break;
        case 'renavam':
          this.carForm.controls.renavam.setErrors({ invalid: erro.message });
          break;
        case 'chassi':
          this.carForm.controls.chassi.setErrors({ invalid: erro.message });
          break;
        case 'ipva':
          this.carForm.controls.ipva.setErrors({ invalid: erro.message });
          break;
        case 'hodometro':
          this.carForm.controls.hodometro.setErrors({ invalid: erro.message });
          break;
        case 'arCondicionado':
          this.carForm.controls.arCondicionado.setErrors({
            invalid: erro.message,
          });
          break;
        case 'categoria':
          this.carForm.controls.categoria.setErrors({ invalid: erro.message });
          break;
        case 'seguradora':
          this.carForm.controls.seguradora.setErrors({ invalid: erro.message });
          break;
        case 'proprietario':
          this.carForm.controls.proprietario.setErrors({
            invalid: erro.message,
          });
          break;
        case 'qtdPassageiros':
          this.carForm.controls.qtdPassageiros.setErrors({
            invalid: erro.message,
          });
          break;
        case 'nMalasGrandes':
          this.carForm.controls.nMalasGrandes.setErrors({
            invalid: erro.message,
          });
          break;
        case 'nMalasPequenas':
          this.carForm.controls.nMalasPequenas.setErrors({
            invalid: erro.message,
          });
          break;
        case 'cidade':
          this.carForm.controls.cidade.setErrors({ invalid: erro.message });
          break;
        case 'estado':
          this.carForm.controls.estado.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  openCropperDialog(event) {
    const imgFile = event.target.files['0'];
    let selImg = this.onSelectImage(imgFile);

    if (selImg) {
      this.cropped = null!;
      this._dialog
        .open<CropperDialog, Event>(CropperDialog, {
          data: event,
          width: 320,
          disableClose: true,
        })
        .afterClosed.subscribe((result?: ImgCropperEvent) => {
          if (result) {
            this.cropped = result.dataURL;
            this._cd.markForCheck();
          }
        });
    }
  }
}
