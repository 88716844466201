<ngx-loading [show]="loading">
</ngx-loading>
<div class="limiter">
   <div class="container-login100 page-background">
      <div class="wrap-login100">
         <div class="login100-form validate-form" [formGroup]="loginForm">
            <span class="login100-form-logo">
               <img alt="" src="assets/img/logo_2.png">
            </span>
            <span class="login100-form-title p-b-34 p-t-27">
               Login
            </span>
            <div *ngIf="loginForm.controls.login.errors && loginForm.controls.login.errors.invalid"
               class="text-center text-danger p-t-5"> {{ loginForm.controls.login.errors.invalid }} </div>

            <div class="wrap-input100 validate-input" data-validate="Enter username">
               <input class="input100" type="text" name="login" formControlName="login" placeholder="Login">
               <span class="focus-input100" data-placeholder="&#xf207;"></span>
               <span *ngIf="submited && loginForm.controls.login.errors && loginForm.controls.login.errors.required"
                  class="text-danger position-right">*Campo
                  Obrigatório</span>
            </div>
            <div class="wrap-input100 validate-input" data-validate="Enter password">
               <input class="input100" type="password" name="password" formControlName="password" placeholder="Senha">
               <span class="focus-input100" data-placeholder="&#xf191;"></span>
               <span
                  *ngIf="submited && loginForm.controls.password.errors && loginForm.controls.password.errors.required"
                  class="text-danger position-right">*Campo
                  Obrigatório</span>
            </div>
            <!-- <div class="contact100-form-checkbox">
               <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
               <label class="label-checkbox100" for="ckb1">
                  Lembrar-me
               </label>
            </div> -->

            <div class="container-login100-form-btn">
               <button (click)="onSubmit()" class="login100-form-btn">
                  Login
               </button>
            </div>

            <div class="text-center p-t-50">
               <a class="txt1" routerLink="/auth/forgot">
                  Esqueceu sua senha?
               </a>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- <script src="../../assets/js/pages/extra_pages/login.js"></script> -->