import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CostCenter } from 'src/app/domain/cost-center';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cost-center-view',
  templateUrl: './cost-center-view.component.html',
  styleUrls: ['./cost-center-view.component.css']
})
export class CostCenterViewComponent implements OnInit {

  id: number;
  costCenter: CostCenter = new CostCenter();
  loading: boolean = true;

  constructor(
    private service: CostCenterService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getCostCenter();
  }

  getCostCenter() {
    this.loading = true;
    this.service.getCostCenter(this.id).subscribe(data => {
      this.costCenter = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Convênio!', 'error');
  }

}
