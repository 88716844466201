import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.headers.get('external')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else {
      request = request.clone({
        headers: new HttpHeaders({
          'Content-Type': request.headers?.get('content-type') ?? 'text/plain',
        }),
      });
    }

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
          }
        },
        (error) => {
          if (error.status === 401 || error.status === 403) {
            // /convenio
            console.log(this.router.url.substring(0, 9));
            if (this.router.url.substring(0, 9) == '/convenio') {
              this.router.navigate(['/convenio/login']);
            } else {
              this.router.navigate(['login']);
            }
          }
        }
      )
    );
  }
}
