<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mt-3">
              <div class="page-title">Alterar Parcelas</div>
            </div>
          </div>
          <div [formGroup]="billsToPayForm">
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="card card-topline-green">
              <div class="form-group container">
                <table
                  class="table table-bordered mt-5"
                  formArrayName="parcels"
                >
                  <thead>
                    <tr>
                      <th scope="col">Nº da Parcela</th>
                      <th
                        *ngIf="
                          this.billsToPayForm.getRawValue().paymentMethod ==
                          'bank_check'
                        "
                        scope="col"
                      >
                        Nº do Cheque
                      </th>
                      <th scope="col">Valor da Parcela</th>
                      <th scope="col">Valor Pago</th>
                      <th scope="col">Data Vencimento Parcela</th>
                      <th scope="col">Pago</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let parcel of parcels.controls; let i = index"
                      [formGroupName]="i"
                    >
                      <td>{{ parcel.controls.number.value }}</td>
                      <td
                        *ngIf="
                          this.billsToPayForm.getRawValue().paymentMethod ==
                          'bank_check'
                        "
                      >
                        <!-- {{ parcel.controls.bankCheckNumber.value }} -->
                        <input
                          type="text"
                          class="form-control"
                          formControlName="bankCheckNumber"
                          maxlength="10"
                        />
                      </td>
                      <td>
                        {{ parcel.controls.value.value | currency: "BRL" }}
                      </td>
                      <td *ngIf="parcel.controls.paid">
                        <input
                          type="text"
                          class="form-control money2"
                          placeholder="Valor pago"
                          formControlName="paidValue"
                          currencyMask
                          [options]="{
                            prefix: 'R$ ',
                            thousands: '.',
                            decimal: ','
                          }"
                          id="paidValue"
                        />
                        <span
                          *ngIf="
                            parcel.controls.paidValue.errors &&
                            parcel.controls.paidValue.errors.required
                          "
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                      </td>
                      <td *ngIf="!parcel.controls.paid"></td>
                      <td>
                        {{ parcel.controls.dueDate.value | date: "dd/MM/yyyy" }}
                      </td>
                      <td>
                        <input type="checkbox" formControlName="paid" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row mt-3 ml-3 mb-3">
              <div class="col-md-3 text-right">
                <button
                  (click)="onSubmit()"
                  [disabled]="!billsToPayForm.valid"
                  class="btn btn-success"
                >
                  Salvar
                </button>
              </div>
              <div class="col-md-3">
                <a routerLink="/financial/bills-to-pay" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
