import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Collaborator } from '../../domain/collaborator';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CovenantCollaboratorService } from '../../service/covenant-collaborator/covenant-collaborator.service';
import { Pagination } from '../../domain/pagination';
import Swal from 'sweetalert2';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-filter-covenant-collaborator',
  templateUrl: './modal-filter-covenant-collaborator.component.html',
  styleUrls: ['./modal-filter-covenant-collaborator.component.css'],
})
export class ModalFilterCovenantCollaboratorComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private service: CovenantCollaboratorService,
    private modalService: NgbModal
  ) {}

  loading: boolean = false;
  searchForm: FormGroup;
  pagination: Pagination = new Pagination();

  collaborators: Collaborator[] = [];

  collaboratorToEdit: Collaborator;

  modalFireCondition: boolean = false;

  @Output() modalClose: EventEmitter<boolean | Collaborator> =
    new EventEmitter();

  ngOnInit(): void {
    this.createForm();
  }

  open(content: any) {
    this.modalService
      .open(content, { size: 'xl' })
      .result.then((result) => {
        console.log(`Modal closed with: ${result}`);
      })
      .catch((reason) => {
        console.log(`Modal dismissed with: ${reason}`);
      });
  }

  createForm() {
    this.searchForm = this.fb.group({
      name: new FormControl(''),
      role: new FormControl(''),
      active: new FormControl(),
      whatsapp: new FormControl(''),
    });
    this.onSearch();
  }

  onSearch() {
    this.loading = true;

    const collaborator = new Collaborator(this.searchForm.value);

    this.service
      .getGeneralCollaborators(this.pagination, collaborator)
      .subscribe(
        (data) => {
          this.collaborators = data['results'];
          this.setPagination(data);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  setPagination(data: any) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];
  }

  onCollaboratorEdit(covenantCollaborator: Collaborator) {
    this.collaboratorToEdit = covenantCollaborator;
    this.modalFireCondition = true;
  }

  onActive(collaborator: Collaborator) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Colaborador ${collaborator.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.loading = true;
        this.service
          .activeColaborattor(collaborator.covenant.id, collaborator.id)
          .subscribe(
            (data) => {
              this.loading = false;
              Swal.fire({
                title: 'Ativado',
                text: `Colaborador ativado com sucesso!`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              }).then((result) => {
                collaborator.active = !collaborator.active;
              });
            },
            (error) => {
              console.log(error);
              this.loading = false;
              Swal.fire(
                'Erro ao ativar Colaborador!',
                error?.error?.errors?.map((item) => item.message)?.join('\n'),
                'error'
              );
            }
          );
      }
    });
  }
  onDeActive(collaborator: Collaborator) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Colaborador ${collaborator.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.service
          .deactiveColaborattor(collaborator.covenant.id, collaborator.id)
          .subscribe(
            (data) => {
              this.loading = false;
              Swal.fire({
                title: 'Desativado',
                text: `Colaborador desativado com sucesso!`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              }).then((result) => {
                collaborator.active = !collaborator.active;
                this.modalFireCondition = true;
              });
            },
            (error) => {
              this.loading = false;
              Swal.fire(
                'Erro ao desativar Perfil de Acesso!',
                `${error.error.errors[0].message}`,
                'error'
              );
            }
          );
      } else {
        this.modalFireCondition = true;
      }
    });
  }

  onFirst() {
    this.pagination.page = 1;
    this.onSearch();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.onSearch();
  }
  onPrevious() {
    this.pagination.page--;
    this.onSearch();
  }
  onNext() {
    this.pagination.page++;
    this.onSearch();
  }

  onClose() {
    this.modalClose.emit(true);
  }

  onCloseModalCreateCollaborator(res: any) {
    if (res.id) {
      const index = this.collaborators.findIndex((e) => e.id === res.id);
      this.collaborators[index] = res;
    }
    this.modalFireCondition = false;
  }
}
