<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Cancelar Agendamento de Corrida</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="row"><label for="">Convênio/Cliente</label></div>
                  <div class="row">
                    <span *ngIf="data && data.client != null">{{
                      data.client.name
                    }}</span>
                    <span *ngIf="data && data.covenant != null">{{
                      data.covenant.name
                    }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row"><label for="">CNPJ/CPF</label></div>
                  <div class="row">
                    <span *ngIf="data && data.client != null">{{
                      data.client.cpfCnpj
                    }}</span>
                    <span *ngIf="data && data.covenant != null">{{
                      data.covenant.cpfCnpj
                    }}</span>
                  </div>
                </div>
              </div>

              <div [formGroup]="cancelForm">
                <div class="row">
                  <div class="col mt-1">
                    <label for="">Motivo</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Motivo do Cancelamento"
                      formControlName="motive"
                    />
                    <span
                      *ngIf="
                        cancelForm.controls.motive.errors &&
                        cancelForm.controls.motive.errors.required
                      "
                      class="text-danger position-right ml-3"
                      >* Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        cancelForm.controls.motive.errors &&
                        cancelForm.controls.motive.errors.maxLength
                      "
                      class="text-danger position-right ml-3"
                      >* Limite de 80 caracteres</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label for="exampleFormControlTextarea1">Descrição</label>
                    <textarea
                      class="form-control"
                      placeholder="Descrição do Motivo"
                      formControlName="description"
                      rows="5"
                    ></textarea>
                    <span
                      *ngIf="
                        cancelForm.controls.description.errors &&
                        cancelForm.controls.description.errors.required
                      "
                      class="text-danger position-right ml-3"
                      >* Campo Obrigatório</span
                    >
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-3">
                    <button
                      class="btn btn-success"
                      (click)="onSubmit()"
                      [disabled]="!cancelForm.valid"
                    >
                      Salvar
                    </button>
                  </div>
                  <div class="col-sm-3">
                    <a routerLink="/process/trip-request" class="btn btn-danger"
                      >Cancelar</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
