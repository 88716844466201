import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Driver } from 'src/app/domain/driver';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DriverPaymentService {
  url: string = `${environment.BASE_URL}/driver-payments`;

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  driverPaymentCreate(data) {
    return this.http.post(`${this.url}`, data, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getDriverPayments(
    initialDate: string,
    finalDate: string,
    driverId: number,
    paymentType: string,
    paid: boolean,
    pagination: Pagination
  ) {
    const params = {
      initialDate: `${initialDate ?? ''}`,
      finalDate: `${finalDate ?? ''}`,
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      driverId: `${driverId ?? ''}`,
      paymentType: `${paymentType ?? ''}`,
      paid: `${paid}`,
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  findOneDriverPayment(id: number) {
    return this.http.get(`${this.url}/${id}`, { responseType: 'json' });
  }

  setPaidDriverPayment(id: number) {
    return this.http.put(`${this.url}/${id}/set-paid`, {
      responseType: 'json',
    });
  }

  setUnpaidDriverPayment(id: number) {
    return this.http.put(`${this.url}/${id}/set-unpaid`, {
      responseType: 'json',
    });
  }

  exportDriverPayments(filter: any, type: string) {
    let getActive = filter.active ?? '';
    const params = new HttpParams({
      fromObject: {
        initialDate: `${filter.initialDate ?? ''}`,
        finalDate: `${filter.finalDate ?? ''}`,
        driverId: `${filter.driverId ?? ''}`,
        paymentType: `${filter.paymentType ?? ''}`,
        paid: `${filter.paid ?? ''}`,
        type,
      },
    });
    return this.http.get(`${this.url}/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      params: params,
      observe: 'response',
    });
  }
}
