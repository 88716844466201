import { AbstractControl } from '@angular/forms';

export class ValidationsTripRequest {

    static validaEndDate(controls: AbstractControl) {
        if (controls.parent) {
            if (controls.parent.value.scheduled && controls.value.trim().length == 0) {
                return { required: "Campo Obrigatório" };
            }
        }
        return null;
    }

    static validaDriverVehicle(controls: AbstractControl) {
        if (controls.parent) {
            if (!controls.parent.value.scheduled && String(controls.value).trim().length == 0) {
                return { invalid: "Campo Obrigatório" };
            }
        }
        return null;
    }

    static validaClient(controls: AbstractControl) {
        if (controls.parent) {
            if (controls.parent.value.passenger == 'client' && controls.value.length == 0) {
                return { required: "Campo Obrigatório" };
            }
        }
        return null;
    }

    static validaRequestValue(controls: AbstractControl) {
        if (controls.parent) {
            if (controls.parent.value.passenger == 'covenant' && controls.value.trim().length == 0) {
                return { required: "Campo Obrigatório" };
            }
        }
        return null;
    }

}