import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination } from 'src/app/domain/pagination';
import { TripRequest } from 'src/app/domain/trip-request';
import { TripRequestSchedule } from 'src/app/domain/trip-request-schedule';
import { EnumStatusRun } from 'src/app/enumerations/status-run';
import { EnumStatusSolicitation } from 'src/app/enumerations/status-solicitation';
import { CovenantDashboardService } from 'src/app/service/covenant-dashboard/covenant-dashboard.service';
import { CovenantRunRequestService } from 'src/app/service/covenant-run-request/covenant-run-request.service';
import { CovenantRunsService } from 'src/app/service/covenant-runs/covenant-runs.service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Month } from 'src/app/enumerations/month';

@Component({
  selector: 'app-covenant-dashboard',
  templateUrl: './covenant-dashboard.component.html',
  styleUrls: ['./covenant-dashboard.component.css'],
})
export class CovenantDashboardComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [];

  // public lineChartData: ChartDataSets[] = [
  //   { data: [65, 59, 80, 81, 56, 55, 40, 100, 37, 63, 85, 80], label: 'Solicitações' },
  // ];
  // public lineChartLabels: Label[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  // public lineChartOptions: (ChartOptions & {}) = {
  //   responsive: true,
  // };
  // public lineChartColors: Color[] = [
  //   {
  //     borderColor: 'blue',
  //     backgroundColor: 'rgba(0,0,255,0.3)',
  //   },
  // ];
  // public lineChartLegend = true;
  // public lineChartType = 'line';
  // public lineChartPlugins = [];

  runs = [];
  selected = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  dashboardData: [];
  scheduled;
  finished;
  canceled;

  first: boolean = false;
  last: boolean = false;

  filter: Filter;

  constructor(
    private service: CovenantDashboardService,
    private serviceRuns: CovenantRunsService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;

      this.filter = new Filter();
      // this.filter.active = params.active ?? null;
      // this.filter.costCenter = params.cost_center ?? null;
      this.filter.status = params.status;
      // this.filter.paymentMethod = params.payment_method ?? null;
      this.filter.dateStart =
        params.date_start || moment().format('yyyy-MM-DD');
      this.filter.dateEnd = params.date_end;
      this.filter.driver = params.driver;
      this.filter.vehicle = params.vehicle;
    });
  }

  ngOnInit(): void {
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getRuns();
    this.getDashboardData();
    this.createForm();
  }

  createForm() {
    this.searchForm = this.fb.group({
      per_page: [this.pagination.per_page],
      costCenter: [this.filter.costCenter],
      status: [this.filter.status],
      paymentMethod: [this.filter.paymentMethod],
      dateStart: [this.filter.dateStart],
      dateEnd: [this.filter.dateEnd],
      driver: [this.filter.driver],
      vehicle: [this.filter.vehicle],
    });
  }

  onCheckboxChange(e, id) {
    if (e.target.checked) {
      this.selected.push(id);
    } else {
      this.selected.splice(this.selected.indexOf(id), 1);
    }
  }

  shared() {
    if (this.selected.length < 2) {
      Swal.fire(
        'Erro',
        'Você deve selecionar pelo menos duas corridas para serem compartilhadas',
        'error'
      );
    } else {
      const runs = `[${this.selected.toString()}]`;
      this.router.navigate(['/convenio/covenant-share-trip'], {
        queryParams: {
          r: this.selected.toString(),
        },
      });
    }
  }

  getRuns() {
    this.loading = true;

    this.serviceRuns.getRuns(this.filter, this.pagination).subscribe(
      (data) => {
        this.runs = data['results'].map((Run, index, array) => {
          console.log(Run.status);

          Run.status = EnumStatusRun[Run.status];
          return Run;
        });
        this.setPagination(data);
        console.log(this.runs);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }
  onSearch() {
    this.filter.costCenter = this.searchForm.controls.costCenter.value;
    this.filter.status = this.searchForm.controls.status.value;
    this.filter.paymentMethod = this.searchForm.controls.paymentMethod.value;
    this.filter.vehicle = this.searchForm.controls.vehicle.value;
    this.filter.dateStart = this.searchForm.controls.dateStart.value;
    this.filter.dateEnd = this.searchForm.controls.dateEnd.value;

    this.setNavigate();
  }
  async updateRun(id, start, end) {
    const { value: formValues } = await Swal.fire({
      icon: 'info',
      title: 'Alterar Horário da Corrida',
      html:
        `<label>Hora de Início</label><input id="swal-input1" class="swal2-input" value="${start}" type="time">` +
        `<label>Hora de Fim</label><input id="swal-input2" class="swal2-input" value="${end}" type="time">`,
      focusConfirm: false,
      showCloseButton: true,
      confirmButtonText: 'Editar',
      showCancelButton: true,
      cancelButtonText: 'Voltar',
      preConfirm: () => {
        return {
          startHour: document.getElementById('swal-input1')['value'],
          endHour: document.getElementById('swal-input2')['value'],
        };
      },
    });

    const data = {};

    if (formValues.startHour) {
      data['startHour'] = formValues.startHour;
    } else {
      data['startHour'] = undefined;
    }
    if (formValues.endHour) {
      data['endHour'] = formValues.endHour;
    } else {
      data['endHour'] = undefined;
    }

    this.serviceRuns.update(id, formValues).subscribe(
      (data) => {
        Swal.fire('Editado!', 'Hora editada com sucesso', 'success');
        this.setNavigate();
      },
      (error) => {
        Swal.fire('Erro ao Editar Hora', '', 'error');
      }
    );
  }

  cancelRun(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Cancelar Corrida?',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.serviceRuns.cancel(id).subscribe(
          (data) => {
            Swal.fire('Cancelado!', '', 'success');
            this.setNavigate();
          },
          (error) => {
            Swal.fire('Erro ao Cancelar', '', 'error');
          }
        );
      }
    });
  }

  exportTripRequests(type: string) {
    this.loading = true;
    this.serviceRuns.exportTripRequestRuns(this.filter, type).subscribe(
      (response) => {
        const fileName = `Relatorio_Solicitacao_${moment().format(
          'DDMMyyyy'
        )}.${type == 'excel' ? '.xlsx' : 'pdf'}`;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(response.body);
        link.download = fileName;
        link.click();

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  getDashboardData() {
    this.loading = true;
    this.service.getDashboardData().subscribe(
      (data) => {
        this.dashboardData = data['requests'];
        this.dashboardData.reverse();

        this.barChartLabels = this.dashboardData.map((data) => {
          return `${Month[data['month']]}/${data['year']}`;
        });

        this.scheduled = this.dashboardData.map((data) => {
          return data['scheduled'];
        });

        this.barChartData.push({
          data: this.scheduled,
          label: 'Agendados',
          backgroundColor: 'blue',
        });

        this.finished = this.dashboardData.map((data) => {
          return data['finished'];
        });

        this.barChartData.push({
          data: this.finished,
          label: 'Finalizados',
          backgroundColor: 'green',
        });

        this.canceled = this.dashboardData.map((data) => {
          return data['canceled'];
        });

        this.barChartData.push({
          data: this.canceled,
          label: 'Cancelados',
          backgroundColor: 'red',
        });

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/convenio'], {
      queryParams: {
        cost_center: this.filter.costCenter ?? null,
        status: this.filter.status ?? null,
        paymentMethod: this.filter.paymentMethod ?? null,
        date_start: this.filter.dateStart ?? null,
        date_end: this.filter.dateEnd ?? null,
        driver: this.filter.driver ?? null,
        vehicle: this.filter.vehicle ?? null,
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getRuns();
  }
}

class Filter {
  costCenter: number;
  paymentMethod: number;
  dateStart: Date;
  dateEnd: Date;
  driver: number;
  vehicle: number;
  status: string;
  active: boolean;
}
