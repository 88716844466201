import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Pagination } from 'src/app/domain/pagination';
import { BillsToReceive } from 'src/app/domain/bills-to-receive';

@Injectable({
  providedIn: 'root'
})
export class BillsToReceiveService {
  url: string = `${environment.BASE_URL}/bills-to-receive`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) { }

  getBillsToReceive(filter: any, pagination: Pagination) {
    const params = {
      page: `${pagination.page}`,
      per_page: `${pagination.per_page}`,
      ...filter,
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  save(billsToReceive: BillsToReceive) {
    return this.http.post(`${this.url}`, JSON.stringify(billsToReceive), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getBillToReceive(id: number) {
    return this.http.get<BillsToReceive>(`${this.url}/${id}`, {
      responseType: 'json',
    });
  }

  update(billsToReceive: BillsToReceive) {
    return this.http.put(
      `${this.url}/${billsToReceive.id}`,
      JSON.stringify(billsToReceive),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  updateParcels(billsToReceive: BillsToReceive) {
    return this.http.put(
      `${this.url}/${billsToReceive.id}/update-parcels`,
      JSON.stringify(billsToReceive),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
}
