import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Pagination } from 'src/app/domain/pagination';
import { CarRefuel } from 'src/app/domain/car-refuel';
import { CarRefuelDTO } from 'src/app/domain/dto/car-refuel.dto';

@Injectable({
  providedIn: 'root',
})
export class CarRefuelService {
  url: string = `${environment.BASE_URL}/vehicles`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getCarRefuels(carId: number, filter: any, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      ...filter,
    };

    return this.http.get(`${this.url}/${carId}/fuel-supplies`, {
      responseType: 'json',
      params: params,
    });
  }

  save(carId: number, carRefuel: CarRefuel) {
    let dto = new CarRefuelDTO(carRefuel);

    return this.http.post(
      `${this.url}/${carId}/fuel-supplies`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  update(carId: number, carRefuel: CarRefuel) {
    let dto = new CarRefuelDTO(carRefuel);

    return this.http.put(
      `${this.url}/${carId}/fuel-supplies`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  delete(carId: number, id: number) {
    return this.http.delete(`${this.url}/${carId}/fuel-supplies/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getAllCarRefuels(filter: any, pagination: Pagination) {
    const {
      dateStart,
      dateEnd,
      gasStation,
      driver,
      vehicle,
      fuel,
      supplyType,
      invoiceNumber,
    } = filter;

    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      date_start: `${dateStart ?? ''}`,
      date_end: `${dateEnd ?? ''}`,
      gasStation: `${gasStation ?? ''}`,
      driver: `${driver ?? ''}`,
      vehicle: `${vehicle ?? ''}`,
      fuel: `${fuel ?? ''}`,
      supplyType: `${supplyType ?? ''}`,
      invoiceNumber: `${invoiceNumber ?? ''}`,
    };

    return this.http.get(`${this.url}/car-refuels`, {
      responseType: 'json',
      params: params,
    });
  }

  exportCarRefuels(filter: any, type: string) {
    const {
      dateStart,
      dateEnd,
      gasStation,
      driver,
      vehicle,
      fuel,
      supplyType,
      invoiceNumber,
    } = filter;

    const params = new HttpParams({
      fromObject: {
        date_start: `${dateStart ?? ''}`,
        date_end: `${dateEnd ?? ''}`,
        gasStation: `${gasStation ?? ''}`,
        driver: `${driver ?? ''}`,
        vehicle: `${vehicle ?? ''}`,
        fuel: `${fuel ?? ''}`,
        supplyType: `${supplyType ?? ''}`,
        invoiceNumber: `${invoiceNumber ?? ''}`,
        type,
      },
    });
    return this.http.get(`${this.url}/car-refuels/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      params: params,
      observe: 'response',
    });
  }

  imageUpload(id: number, file: File) {
    const formData = new FormData();
    formData.append('files', file);
    return this.http.post(`${this.url}/car-refuels/${id}/upload`, formData);
  }

  fileDelete(id: number) {
    return this.http.delete(`${this.url}/car-refuels/${id}/file-delete`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
