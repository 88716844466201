import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/domain/pagination';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentMethod } from 'src/app/domain/paymentMethod';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  url: string = `${environment.BASE_URL}/payment-methods`;

  constructor(private http: HttpClient) { }

  getPaymentMethods() {
    return this.http.get<Array<PaymentMethod>>(this.url,
      { responseType: "json" });

  }
}
