
export class DestinyPoint {
    id: number
    name: string
    value: number

    constructor(name = '', value) {
        this.name = name;
        this.value = value;
    }
}