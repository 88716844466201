import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { BillsToPayService } from 'src/app/service/bills-to-pay/bills-to-pay.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CostCenter } from 'src/app/domain/cost-center';
import { Provider } from 'src/app/domain/provider';
import Swal from 'sweetalert2';
import { Bank } from 'src/app/domain/bank';
import { CreditCard } from 'src/app/domain/credit-card';
import { BillsToPay } from 'src/app/domain/bills-to-pay';

@Component({
  selector: 'app-bills-to-pay-view',
  templateUrl: './bills-to-pay-view.component.html',
  styleUrls: ['./bills-to-pay-view.component.css'],
})
export class BillsToPayViewComponent implements OnInit {
  billsToPayForm: FormGroup;
  billsToPay: BillsToPay = new BillsToPay();
  loading: boolean = true;
  id: number;

  constructor(
    private service: BillsToPayService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.setForm();
  }

  setForm() {
    this.service.getBillToPay(this.id).subscribe(
      (data) => {
        this.billsToPayForm.controls.provider.setValue(data.provider.id);
        this.billsToPayForm.controls.costCenter.setValue(data.costCenter.id);
        this.billsToPayForm.controls.paymentMethod.setValue(data.paymentMethod);
        this.billsToPayForm.controls.invoiceNumber.setValue(data.invoiceNumber);
        this.billsToPayForm.controls.value.setValue(data.value.toString());
        this.billsToPayForm.controls.paidValue.setValue(
          data.paidValue?.toString()
        );
        this.billsToPayForm.controls.dueDate.setValue(data.dueDate);
        this.billsToPayForm.controls.paymentType.setValue(data.paymentType);
        this.billsToPayForm.controls.paid.setValue(data.paid);
        this.billsToPayForm.controls.frequency.setValue(data.frequency);
        this.billsToPayForm.controls.bank.setValue(data.bank?.id);
        this.billsToPayForm.controls.creditCard.setValue(data.creditCard?.id);
        this.billsToPayForm.controls.bankCheckNumber.setValue(
          data.bankCheckNumber
        );
        this.billsToPayForm.controls.observation.setValue(data.observation);

        this.billsToPay = data;

        this.loading = false;

        if (data.parcels.length) {
          this.billsToPayForm.controls.frequency.setValue(
            this.getFrequency(data)
          );

          this.billsToPayForm.controls.parcAmount.setValue(data.parcels.length);

          data.parcels.forEach((item) => {
            this.parcels.push(
              this.fb.group({
                id: [item.id],
                number: [item.number],
                value: [this.parcelValue()],
                paid: [item.paid],
                paidValue: [item.paidValue],
                bankCheckNumber: [item.bankCheckNumber || ''],
                dueDate: [item.dueDate],
              })
            );
          });
        }
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar A conta a pagar!', 'error');
      }
    );
  }

  createForm() {
    this.billsToPayForm = this.fb.group({
      provider: [''],
      costCenter: [''],
      paymentMethod: [''],
      invoiceNumber: [''],
      value: [''],
      paidValue: [''],
      dueDate: [''],
      paymentType: [''],
      paid: [false],
      parcAmount: [null],
      frequency: [''],
      creditCard: [''],
      bank: [''],
      bankCheckNumber: [''],
      parcels: this.fb.array([]),
      observation: [''],
    });
  }

  get parcels() {
    return this.billsToPayForm.get('parcels') as FormArray;
  }

  setParcValue() {
    const value = this.parcelValue();
    this.parcels.controls.forEach((item) => {
      item.get('value').setValue(value);
    });
  }

  parcelValue() {
    const { value, parcAmount } = this.billsToPayForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value / parcAmount;
  }

  getPaymentMethod(method: string) {
    const paymentMethods = {
      billet: 'Boleto',
      credit_card: 'Cartão de Crédito',
      bank_check: 'Cheque',
      deposit: 'Depósito',
      money: 'Dinheiro',
    };

    return paymentMethods[method];
  }

  getFrequency(item: BillsToPay) {
    const date1 = new Date(item.dueDate);
    const date2 = new Date(item.parcels[0].dueDate);
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = (year2 - year1) * 12 + (month2 - month1);

    let frequency = '';

    switch (diffDays) {
      case 1:
        frequency = 'Dias';
        break;
      case 7:
        frequency = 'Semanas';
        break;
      case 14:
      case 15:
        frequency = 'Quinzenas';
        break;
      default:
        frequency = '';
    }

    if (!frequency) {
      switch (diffMonths) {
        case 1:
          frequency = 'Meses';
          break;
        case 2:
          frequency = 'Bimestres';
          break;
        case 3:
          frequency = 'Trimestres';
          break;
        case 6:
          frequency = 'Semestres';
          break;
        case 12:
          frequency = 'Anos';
          break;
        default:
          frequency = '';
      }
    }

    return frequency;
  }
}
