import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankAccount } from 'src/app/domain/bank-account';
import { BankAccountDTO } from 'src/app/domain/dto/bank-account.dto';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  url: string = `${environment.BASE_URL}/bank-accounts`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getBanksAccounts(filter: any, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      ...filter,
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  getAllActiveBanckAccounts() {
    return this.http.get<Array<BankAccount>>(`${this.url}/all-active`, {
      responseType: 'json',
    });
  }

  getBankAccount(id: number) {
    return this.http.get<BankAccount>(`${this.url}/${id}`, {
      responseType: 'json',
    });
  }
  save(bank: BankAccount) {
    // let dto = new BankAccountDTO(bank);

    return this.http.post(`${this.url}`, JSON.stringify(bank), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(bank) {
    // let dto = new BankAccountDTO(bank);
    // console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${bank.id}`, JSON.stringify(bank), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
