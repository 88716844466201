import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Car } from 'src/app/domain/car';
import { CarService } from 'src/app/service/car/car.service';
import { DriverService } from 'src/app/service/driver/driver.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-link-driver-car',
  templateUrl: './link-driver-car.component.html',
  styleUrls: ['./link-driver-car.component.css']
})
export class LinkDriverCarComponent implements OnInit {

  loading: boolean = false;
  cars: Array<Car> = [];
  linkForm: FormGroup;
  id: number;
  driver;


  constructor(
    private service: DriverService,
    private serviceCars: CarService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getVehicles();
    this.getDriver();
  }

  getDriver() {
    this.loading = true;
    this.service.getDriver(this.id).subscribe(data => {
      this.driver = data;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  getVehicles() {
    this.serviceCars.getCarsActives().subscribe(data => this.cars = data);
  }

  createForm() {
    this.linkForm = this.fb.group({
      vehicle: ['', [Validators.required]],
    });
  }

  onSubmit() {
    const { value } = this.linkForm.controls.vehicle;

    const data = this.driver.driverVehicles.filter(elem => elem.vehicle.id == value);

    if (data.length != 0) {
      return this.linkForm.controls.vehicle.setErrors({ invalid: "Veículo já está relacionado ao Motorista" });
    }

    if (this.linkForm.valid) {
      const vehicle = {
        id: Number(value)
      }
      this.service.addVehicleInDriver(this.id, vehicle).subscribe(data => {
        const car = this.cars.filter(elem => elem['id'] == Number(this.linkForm.controls.vehicle.value));
        console.log(car);

        Swal.fire('Sucesso', 'Veículo adicionado ao Motorista', 'success');
        // this.linkForm.controls.vehicle.setValue('');
        location.reload();
      }, error => {
        Swal.fire('Erro', 'Erro ao adicionar Veículo ao Motorista', 'error');
      });

    }

  }

  onRemove(vehicle: number) {
    this.service.removeVehicleInDriver(this.id, vehicle).subscribe(data => {
      location.reload();
      Swal.fire('Sucesso', 'Veículo removido ao Motorista', 'success');
    }, error => {
      Swal.fire('Erro', 'Erro ao remover Veículo ao Motorista', 'error');
    });
  }

}
