<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
   <div class="page-content">
      <div class="page-bar">
         <div class="page-title-breadcrumb">
            <div class="container">
               <div class="row">
                  <div class="col-md-10">
                     <div class="page-title">Cadastrar Usuário </div>
                  </div>
               </div>
               <div [formGroup]="userForm">
                  <!--div com o form -->
                  <div *ngIf="messageError.length" class="container">
                     <div class="alert alert-danger" role="alert">
                        {{messageError}}
                     </div>
                  </div>
                  <div class="form-group">
                     <label for="name">Nome</label>
                     <input type="text" class="form-control" id="name" formControlName="name"
                        placeholder="Nome do Usuário">
                     <span *ngIf="userForm.controls.name.errors && userForm.controls.name.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span *ngIf="userForm.controls.name.errors && userForm.controls.name.errors.maxlength"
                        class="text-danger position-right">*Nome do
                        Perfil deve ter
                        no máximo 100 caracteres</span>
                  </div>
                  <span *ngIf="userForm.controls.name.errors && userForm.controls.name.errors.invalid"
                     class="text-danger position-right">* {{userForm.controls.name.errors.invalid}} </span>

                  <div class="form-group">
                     <label for="email">Email</label>
                     <input type="text" class="form-control" id="email" formControlName="email"
                        placeholder="Email do Usuário">
                     <span *ngIf="userForm.controls.email.errors && userForm.controls.email.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span *ngIf="userForm.controls.email.errors && userForm.controls.email.errors.email"
                        class="text-danger position-right">*Não é um email válido!</span>
                  </div>
                  <span *ngIf="userForm.controls.email.errors && userForm.controls.email.errors.invalid"
                     class="text-danger position-right">* {{userForm.controls.email.errors.invalid}} </span>
                  <div class="form-group">
                     <label for="birthDate">Data de Nascimento</label>
                     <input type="date" class="form-control" id="birthDate" formControlName="birthDate">
                     <span *ngIf="userForm.controls.birthDate.errors && userForm.controls.birthDate.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span
                        *ngIf="userForm.controls.birthDate.errors && userForm.controls.birthDate.errors.biggerThanToday"
                        class="text-danger position-right">*Data não pode ser Futura.</span>
                     <span *ngIf="userForm.controls.birthDate.errors && userForm.controls.birthDate.errors.invalid"
                        class="text-danger position-right">* {{userForm.controls.birthDate.errors.invalid}} </span>
                  </div>
                  <div class="form-group">
                     <label for="phone">Celular</label>
                     <input type="text" class="form-control phone_with_ddd" id="phone" formControlName="phone"
                        placeholder="Celular do Usuário">

                     <span *ngIf="userForm.controls.phone.errors && userForm.controls.phone.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span *ngIf="userForm.controls.phone.errors && userForm.controls.phone.errors.invalid"
                        class="text-danger position-right">* {{userForm.controls.phone.errors.invalid}} </span>
                  </div>
                  <div class="form-group">
                     <label for="login">Login</label>
                     <input type="text" class="form-control" id="login" formControlName="login"
                        placeholder="Login do Usuário">
                     <span *ngIf="userForm.controls.login.errors && userForm.controls.login.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span *ngIf="userForm.controls.login.errors && userForm.controls.login.errors.invalid"
                        class="text-danger position-right">* {{userForm.controls.login.errors.invalid}} </span>
                     <!-- <span *ngIf="userForm.controls.name.errors && userForm.controls.name.errors.maxlength"
                        class="text-danger position-right">*Nome do
                        Perfil deve ter
                        no máximo 100 caracteres</span> -->
                  </div>


                  <div class="form-group">
                     <label class="control-label">Perfil de Acesso
                     </label>

                     <select class="form-control  select2" formControlName="accessProfile">
                        <option value="">
                           Selecione o Perfil de Acesso </option>
                        <option value="{{ accessProfile.id }}" *ngFor="let accessProfile of accessProfiles">
                           {{ accessProfile.name }} </option>

                     </select>
                     <span
                        *ngIf="userForm.controls.accessProfile.errors && userForm.controls.accessProfile.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                  </div>
                  <div class="form-group">
                     <label for="role">Cargo</label>
                     <input type="text" class="form-control" id="role" formControlName="role"
                        placeholder="Nome do Cargo">
                     <span *ngIf="userForm.controls.role.errors && userForm.controls.role.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span *ngIf="userForm.controls.role.errors && userForm.controls.role.errors.invalid"
                        class="text-danger position-right">* {{userForm.controls.role.errors.invalid}} </span>
                  </div>
                  <div class="form-group">
                     <label for="password">Senha</label>
                     <input type="password" class="form-control" id="password" formControlName="password"
                        placeholder="Senha do Usuário">
                     <span *ngIf="userForm.controls.password.errors">
                        <span *ngIf="userForm.controls.password.errors.required"
                           class="text-danger position-right">*Campo
                           Obrigatório</span>
                        <span *ngIf="userForm.controls.password.errors.minlength"
                           class="text-danger position-right">*Senha deve ter no mínimo 8 caracteres</span>
                        <span
                           *ngIf="!userForm.controls.password.errors.required && !userForm.controls.password.errors.minlength && userForm.controls.password.errors.invalidPassword"
                           class="text-danger position-right">*Senha deve ter letras maiúsculas e minúsculas e
                           números</span>
                     </span>
                  </div>
                  <div class="form-group">
                     <label for="confirmationPassword">Confirme sua Senha</label>
                     <input type="password" class="form-control" id="confirmationPassword"
                        formControlName="confirmationPassword" placeholder="Confirme sua senha">
                     <span
                        *ngIf="userForm.controls.confirmationPassword.errors && userForm.controls.confirmationPassword.errors.required"
                        class="text-danger position-right">*Campo
                        Obrigatório</span>
                     <span
                        *ngIf="userForm.controls.confirmationPassword.errors && userForm.controls.confirmationPassword.errors.differentPasswords"
                        class="text-danger position-right">*Senhas não coincidem!</span>
                  </div>

                  <div class="row">
                     <div class="col-md-3"></div>
                     <div class="col-md-3 text-right">
                        <button (click)="onSubmit()" [disabled]="!userForm.valid"
                           class="btn btn-success">Salvar</button>
                     </div>
                     <div class="col-md-3">
                        <a routerLink="/security/user" class="btn btn-danger">Cancelar</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
