import { AddressDTO } from './address.dto';
import { Collaborator } from '../collaborator';

export class CollaboratorDTO {
  name: string;
  role: string;
  email: string;
  whatsapp: string;
  department: string;
  costCenter: string;
  address: AddressDTO;
  active: boolean;
  requestAppRace: boolean;

  constructor(collaborator: Collaborator) {
    this.name = collaborator.name;
    this.role = collaborator.role;
    this.email = collaborator.email;
    this.whatsapp = collaborator.whatsapp;
    this.department = collaborator.department;
    this.costCenter = collaborator.costCenter;
    this.address = new AddressDTO(collaborator.address);
    this.active = collaborator.active;
    this.requestAppRace = collaborator.requestAppRace;
  }
}
