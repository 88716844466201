<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<!-- <ngx-loading [show]="loading"> -->
<!-- </ngx-loading> -->

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mt-3">
              <div class="page-title">Visualizar Agendamento de Corrida</div>
            </div>
          </div>
          <div *ngIf="tripRequest">
            <div class="card card-topline-green">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <b class="mt-2 col-md-12">Tipo de Solicitação</b>
                    <div class="col-md-12">
                      <div class="col" *ngIf="tripRequest.covenant">
                        Convênio
                      </div>
                      <div class="col" *ngIf="tripRequest.client">Cliente</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <b class="mt-2 col-md-12">Data da Solicitação</b>
                    <div class="col-md-12">
                      <div class="col">
                        {{ moment(tripRequest.createdAt).format("DD/MM/YYYY") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <b class="mt-2 col-md-12">Número da Solicitação</b>
                    <div class="col-md-12">
                      <div class="col">#{{ tripRequest.id }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <b class="mt-2 col-md-12">Cód. Solicitação Convênio</b>
                    <div class="col-md-12">
                      <div class="col">#{{ tripRequest.covenantCode }}</div>
                    </div>
                  </div>
                </div>
                <h3>Dados Cadastrais</h3>
                <div>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <b>Nome</b>
                      <p *ngIf="tripRequest.covenant">
                        {{ tripRequest.covenant.name }}
                      </p>
                      <p *ngIf="tripRequest.client">
                        {{ tripRequest.client.name }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <b>Whatsapp</b>
                      <p *ngIf="tripRequest.covenant">
                        {{
                          tripRequest.covenant.whatsapp
                            | mask : "+00 00 0 0000-0000"
                        }}
                      </p>
                      <p *ngIf="tripRequest.client">
                        {{
                          tripRequest.client.whatsapp
                            | mask
                              : "+00 00
                        0 0000-0000"
                        }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <b>Telefone</b>
                      <p *ngIf="tripRequest.covenant">
                        {{
                          tripRequest.covenant.phone
                            | mask
                              : "(00)
                        0000-0000||(00) 0 0000-0000"
                        }}
                      </p>
                      <p *ngIf="tripRequest.client">
                        {{
                          tripRequest.client.phone
                            | mask
                              : "(00)
                        0000-0000||(00) 0 0000-0000"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="tripRequest.shedule">
                  <h3 class="mt-3">Dados do Agendamento</h3>
                  <div class="row">
                    <div class="col-sm-3 mt-1">
                      <b>Data de Início</b>
                      <p>
                        {{
                          moment(tripRequest.shedule.startDate).format(
                            "DD/MM/YYYY"
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <b>Data Fim</b>
                      <p>
                        {{
                          moment(tripRequest.shedule.endDate).format(
                            "DD/MM/YYYY"
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <b>Hora início</b>
                      <p>{{ tripRequest.startHour }}</p>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <b>Hora Fim</b>
                      <p>{{ tripRequest.endHour }}</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="tripRequest.shedule.runRequestScheduleRecursive">
                  <div class="row">
                    <div class="col-sm-3 row ml-3 mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="recursive"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                        "
                      />
                      <label class="ml-2" style="font-size: 16px"
                        >Recursividade</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col row ml-3 mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="sunday"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                            ?.sunday
                        "
                      />
                      <label class="ml-2" style="font-size: 16px"
                        >Domingo</label
                      >
                    </div>
                    <div class="col row mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="monday"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                            ?.monday
                        "
                      />
                      <label class="ml-2" style="font-size: 16px"
                        >Segunda</label
                      >
                    </div>
                    <div class="col row mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="tuesday"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                            ?.tuesday
                        "
                      />
                      <label class="ml-2" style="font-size: 16px">Terça</label>
                    </div>
                    <div class="col row mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="wednesday"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                            ?.wednesday
                        "
                      />
                      <label class="ml-2" style="font-size: 16px">Quarta</label>
                    </div>
                    <div class="col row mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="thursday"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                            ?.thursday
                        "
                      />
                      <label class="ml-2" style="font-size: 16px">Quinta</label>
                    </div>
                    <div class="col row mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="friday"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                            ?.friday
                        "
                      />
                      <label class="ml-2" style="font-size: 16px">Sexta</label>
                    </div>
                    <div class="col row mt-3">
                      <input
                        class="mt-1"
                        type="checkbox"
                        name="saturday"
                        disabled
                        [checked]="
                          tripRequest.shedule.runRequestScheduleRecursive
                            ?.saturday
                        "
                      />
                      <label class="ml-2" style="font-size: 16px">Sábado</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="tripRequest.covenant">
                  <h3 class="mt-3">Passageiros</h3>
                  <div class="table-responsive mt-3">
                    <table class="table table-striped custom-table table-hover">
                      <thead>
                        <tr>
                          <th>Colaborador</th>
                          <th>Telefone</th>
                          <th>Whatsapp</th>
                          <th>Centro de Custo</th>
                          <th>Principal</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        *ngFor="
                          let passenger of tripRequest.passengers;
                          let i = index
                        "
                      >
                        <tr>
                          <td>
                            {{ passenger.collaborator.name }}
                          </td>
                          <td>
                            {{
                              passenger.collaborator.phone
                                | mask
                                  : "(00) 0000-0000||(00) 0
                            0000-0000"
                            }}
                          </td>
                          <td>
                            {{
                              passenger.collaborator.whatsapp
                                | mask : "+00 00 0 0000-0000"
                            }}
                          </td>
                          <td>
                            {{ passenger.collaborator.costCenter }}
                          </td>
                          <td>
                            <span *ngIf="passenger.isMain">Sim</span>
                            <span *ngIf="!passenger.isMain">Não</span>
                          </td>
                          <td>
                            <a
                              class="btn btn-primary"
                              data-toggle="collapse"
                              href="#collapseOrigin{{ i }}"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseOrigin"
                            >
                              Endereço de Origem
                            </a>
                            <a
                              class="btn btn-primary"
                              data-toggle="collapse"
                              href="#collapseDestiny{{ i }}"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseDestiny"
                              *ngIf="passenger.destiny"
                            >
                              Endereço de Destino
                            </a>
                          </td>
                        </tr>
                        <tr class="collapse" id="collapseOrigin{{ i }}">
                          <td colspan="6" class="hiddenRow">
                            <div class="card card-body">
                              <div class="row">
                                <b>Endereço de Origem</b>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="row"><b>CEP</b></div>
                                  <div class="row">
                                    {{ passenger.origin.zipCode }}
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="row"><b>Logradouro</b></div>
                                  <div class="row">
                                    {{ passenger.origin.publicPlace }}
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="row"><b>Bairro</b></div>
                                  <div class="row">
                                    {{ passenger.origin.neighborhood }}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row"><b>Estado</b></div>
                                  <div class="row">
                                    {{ passenger.origin.city.state.name }}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row"><b>Cidade</b></div>
                                  <div class="row">
                                    {{ passenger.origin.city.name }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr
                          class="collapse"
                          id="collapseDestiny{{ i }}"
                          *ngIf="passenger.destiny"
                        >
                          <td colspan="6" class="hiddenRow">
                            <div class="card card-body">
                              <div class="row">
                                <b>Endereço de Destino</b>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="row"><b>CEP</b></div>
                                  <div class="row">
                                    {{ passenger.destiny.zipCode }}
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="row"><b>Logradouro</b></div>
                                  <div class="row">
                                    {{ passenger.destiny.publicPlace }}
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="row"><b>Bairro</b></div>
                                  <div class="row">
                                    {{ passenger.destiny.neighborhood }}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row"><b>Estado</b></div>
                                  <div class="row">
                                    {{ passenger.destiny.city.state.name }}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row"><b>Cidade</b></div>
                                  <div class="row">
                                    {{ passenger.destiny.city.name }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div *ngIf="tripRequest.client">
                  <div class="row mt-3">
                    <b class="col-12 mb-2">Endereço</b>
                  </div>

                  <b>Origem</b>
                  <div class="row">
                    <div class="col-sm-4 mt-1">
                      <b>CEP</b>
                      <p>{{ tripRequest.passengers[0].origin.zipCode }}</p>
                    </div>
                    <div class="col-sm-8 mt-1">
                      <b>Logradouro</b>
                      <p>{{ tripRequest.passengers[0].origin.publicPlace }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <b class="mt-2">Bairro</b>
                      <p>{{ tripRequest.passengers[0].origin.neighborhood }}</p>
                    </div>
                    <div class="col-sm-4">
                      <b class="mt-2">Estado</b>
                      <p>
                        {{ tripRequest.passengers[0].origin.city.state.name }} -
                        {{
                          tripRequest.passengers[0].origin.city.state.initials
                        }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <b class="mt-2">Cidade</b>
                      <p>{{ tripRequest.passengers[0].origin.city.name }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b class="mt-3">Destino</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p>{{ tripRequest.requestValue.destiny }}</p>
                    </div>
                    <div class="col-md-12"><b class="mt-3">Rotas</b></div>
                    <div
                      *ngFor="let router of tripRequest.requestValue.routers"
                      class="col-md-12"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <p>{{ router.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 mt-1">
                      <b>CEP</b>
                      <p>{{ tripRequest.passengers[0].destiny.zipCode }}</p>
                    </div>
                    <div class="col-sm-8 mt-1">
                      <b>Logradouro</b>
                      <p>{{ tripRequest.passengers[0].destiny.publicPlace }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <b class="mt-2">Bairro</b>
                      <p>
                        {{ tripRequest.passengers[0].destiny.neighborhood }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <b class="mt-2">Estado</b>
                      <p>
                        {{ tripRequest.passengers[0].destiny.city.state.name }}
                        -
                        {{
                          tripRequest.passengers[0].destiny.city.state.initials
                        }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <b class="mt-2">Cidade</b>
                      <p>{{ tripRequest.passengers[0].destiny.city.name }}</p>
                    </div>
                  </div>
                </div>

                <h3 class="mt-5">Dados da Corrida</h3>
                <div class="row">
                  <div class="col-sm-4">
                    <b>Veículo</b>
                    <p *ngIf="tripRequest.vehicle">
                      {{ tripRequest.vehicle.model.name }} -
                      {{ tripRequest.vehicle.carPlate }}
                    </p>
                    <p *ngIf="!tripRequest.vehicle">Não Selecionado</p>
                  </div>
                  <div class="col-sm-4">
                    <b>Motorista</b>
                    <p *ngIf="tripRequest.driver">
                      {{ tripRequest.driver.name }}
                    </p>
                    <p *ngIf="!tripRequest.driver">Não Selecionado</p>
                  </div>
                </div>
                <h3>Financeiro</h3>
                <div class="row">
                  <div class="col-sm-4">
                    <b>Tipo de Pagamento</b>
                    <p>{{ tripRequest.paymentMethod.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <b>Centro de custo</b>
                    <p>{{ tripRequest?.costCenter?.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <b>Valor da Corrida</b>
                    <p>
                      {{ tripRequest.runValue | currency : "BRL" }}
                    </p>
                  </div>
                  <!-- <div *ngIf="tripRequest.client" class="col-sm-4">
                                        <b>Valor da Corrida</b>
                                        <p>{{ tripRequest.value | currency: 'BRL' }}</p>
                                    </div> -->
                </div>
                <div class="row">
                  <div class="col">
                    <b>Observação</b>
                    <p *ngIf="tripRequest.observation">
                      {{ tripRequest.observation }}
                    </p>
                    <p *ngIf="!tripRequest.observation">
                      Nenhuma observação adicionada
                    </p>
                  </div>
                </div>

                <!-- </ng-template> -->
              </div>
              <div class="row mt-3 mb-3 d-flex justify-content-center">
                <div class="">
                  <a routerLink="/process/trip-request" class="btn btn-danger"
                    >Voltar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
