import { Component, OnInit } from '@angular/core';
import { Driver } from 'src/app/domain/driver';
import { DriverService } from 'src/app/service/driver/driver.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-driver-view',
  templateUrl: './driver-view.component.html',
  styleUrls: ['./driver-view.component.scss']
})
export class DriverViewComponent implements OnInit {

  id: number;
  driver: Driver = new Driver();
  loading: boolean = true;

  constructor(
    private service: DriverService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getDriver();
  }

  getDriver() {
    this.loading = true;
    this.service.getDriver(this.id).subscribe(data => {
      this.driver = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Motorista!', 'error');
  }

  onRemoveUploadedFile(file, i) {
    Swal.fire({
      title: 'Excluir anexo',
      text: 'Deseja excluir este anexo? Esta operação é irreversível',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#188ae2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then( (action) => {
      if (action.isConfirmed) this.service.removeUploadedFile(this.driver.id, file.id).subscribe( res => this.driver.files.splice(i, 1));
    });
  }

}
