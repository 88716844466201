import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillsToReceive } from 'src/app/domain/bills-to-receive';
import { Pagination } from 'src/app/domain/pagination';
import { Run } from 'src/app/domain/run';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenerateBillsToReceiveService {
  url: string = `${environment.BASE_URL}/generate-bills-to-receive`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getBillsToReceive(parameters: any): Observable<Run[]>{

    const params = {
      startDate: parameters.startDate,
      endDate: parameters.endDate,
      covenantId: parameters.searchClientCovenant,
      costCenterId: parameters.costCenter,
    }

    return this.http.get<Run[]>(`${this.url}/bills`,
      { responseType: "json", params });
  }

  save(generatedBills) {
    return this.http.post(this.url, JSON.stringify(generatedBills), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getGeneratedBillsToPay(filter: any, pagination: Pagination) {
    const params = {
      page: `${pagination.page}`,
      per_page: `${pagination.per_page}`,
      date_start: filter.dateStart ?? '',
      date_end: filter.dateEnd ?? '',
      client_covenant: filter.clientCovenant ?? '',
      payment_method: filter.paymentMethod ?? '',
      status: filter.status ?? ''
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }

  getGenerateBill(id: number) {
    return this.http.get<any>(`${this.url}/${id}`, {
      responseType: 'json',
    });
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  updateParcels(generateBill: any) {
    return this.http.put(
      `${this.url}/${generateBill.id}/update-parcels`,
      JSON.stringify(generateBill),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  setPaid(id: number) {
    return this.http.put(`${this.url}/${id}/set-paid`, {}, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  setUnpaid(id: number) {
    return this.http.put(`${this.url}/${id}/set-unpaid`, {}, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  exportBills(filter: any, type: 'excel' | 'pdf') {
    const params = new HttpParams({
      fromObject: {
        type: type,
        date_start: filter.dateStart ?? '',
        date_end: filter.dateEnd ?? '',
        client_covenant: filter.clientCovenant ?? '',
        payment_method: filter.paymentMethod ?? '',
        status: filter.status ?? ''
      },
    });
    return this.http.get(`${this.url}/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      params: params,
      observe: 'response',
    });
  }

  exportGeneratedBillReceiptPdf(id: number) {
    return this.http.get(`${this.url}/${id}/export`, {
      headers: this.httpHeaders,
      responseType: 'blob',
      observe: 'response',
    });
  }
}
