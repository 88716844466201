import { CostCenter } from '../cost-center';

export class CostCenterDTO {
    id: number;
    identifier: string;
    name: string;
    type: string;
    parentId: number;
    active: boolean;

    constructor(costCenter: CostCenter) {
        this.id = costCenter.id;
        this.identifier = costCenter.identifier;
        this.name = costCenter.name;
        this.type = costCenter.type;
        this.parentId = costCenter.parent?.id;
        this.active = costCenter.active;
    }
}