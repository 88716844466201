import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { BillsToPayService } from 'src/app/service/bills-to-pay/bills-to-pay.service';
import { CreditCardService } from 'src/app/service/credit-card/credit-card.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CostCenter } from 'src/app/domain/cost-center';
import { Provider } from 'src/app/domain/provider';
import Swal from 'sweetalert2';
import { Bank } from 'src/app/domain/bank';
import { BankService } from 'src/app/service/bank/bank.service';
import { CreditCard } from 'src/app/domain/credit-card';

@Component({
  selector: 'app-bills-to-pay-update',
  templateUrl: './bills-to-pay-update.component.html',
  styleUrls: ['./bills-to-pay-update.component.css'],
})
export class BillsToPayUpdateComponent implements OnInit {
  billsToPayForm: FormGroup;
  loading: boolean = true;
  messageError: string = '';
  providers: Array<Provider> = [];
  costCenters: Array<CostCenter> = [];
  banks: Array<Bank> = [];
  creditCards: Array<CreditCard> = [];
  id: number;

  constructor(
    private providerService: ProviderService,
    private costCenterService: CostCenterService,
    private bankService: BankService,
    private creditCardService: CreditCardService,
    private service: BillsToPayService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();

    this.providerService.getAllActiveProviders().subscribe(
      (data) => {
        this.providers = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os fornecedores!', 'error');
      }
    );

    this.costCenterService.getAllCostCenters().subscribe(
      (data) => {
        this.costCenters = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro',
          'Não foi possível Buscar os centros de custo!',
          'error'
        );
      }
    );

    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );

    this.creditCardService.getAllActiveCreditCards().subscribe(
      (data) => {
        this.creditCards = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire(
          'Erro',
          'Não foi possível Buscar os cartões de crédito!',
          'error'
        );
      }
    );

    this.setForm();
  }

  setForm() {
    this.service.getBillToPay(this.id).subscribe(
      (data) => {
        this.billsToPayForm.controls.provider.setValue(data.provider.id);
        this.billsToPayForm.controls.costCenter.setValue(data.costCenter.id);
        this.billsToPayForm.controls.paymentMethod.setValue(data.paymentMethod);
        this.billsToPayForm.controls.invoiceNumber.setValue(data.invoiceNumber);
        this.billsToPayForm.controls.value.setValue(data.value.toString());
        this.billsToPayForm.controls.paidValue.setValue(data.value.toString());
        this.billsToPayForm.controls.dueDate.setValue(
          this.formatDate(data.dueDate)
        );
        this.billsToPayForm.controls.paymentType.setValue(data.paymentType);
        this.billsToPayForm.controls.paid.setValue(data.paid);

        this.paidChanged(data.paid);

        this.billsToPayForm.controls.frequency.setValue(data.frequency);
        this.billsToPayForm.controls.bank.setValue(data.bank?.id);
        this.billsToPayForm.controls.creditCard.setValue(data.creditCard?.id);
        this.billsToPayForm.controls.bankCheckNumber.setValue(
          data.bankCheckNumber
        );
        this.billsToPayForm.controls.observation.setValue(data.observation);

        if (data.parcels) {
          this.billsToPayForm.controls.frequency.setValue(
            this.getFrequency(data.dueDate, data.parcels[0].dueDate)
          );

          this.billsToPayForm.controls.parcAmount.setValue(data.parcels.length);

          this.parcels.clear();

          data.parcels.forEach((item, i) => {
            this.parcels.push(
              this.fb.group({
                id: [item.id],
                number: [item.number],
                value: [item.value || this.parcelValue()],
                paidValue: [item.paidValue],
                paid: [item.paid],
                bankCheckNumber: [
                  item.bankCheckNumber || '',
                  [Validators.maxLength(10)],
                ],
                dueDate: [this.formatDate(item.dueDate)],
              })
            );

            this.parcelPaidChanged(i, item.paid);
          });
        }
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar A conta a pagar!', 'error');
      }
    );
  }

  createForm() {
    this.billsToPayForm = this.fb.group({
      provider: ['', [Validators.required]],
      costCenter: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      invoiceNumber: ['', [Validators.maxLength(20)]],
      value: ['', [Validators.required]],
      paidValue: [''],
      dueDate: ['', [Validators.required]],
      paymentType: ['', [Validators.required]],
      paid: [false],
      parcAmount: [null, [Validators.maxLength(5)]],
      frequency: [''],
      creditCard: [''],
      bank: [''],
      bankCheckNumber: ['', [Validators.maxLength(10)]],
      parcels: this.fb.array([]),
      observation: [''],
    });

    this.billsToPayForm
      .get('value')
      .valueChanges.subscribe((val) => this.changeValue(val));

    this.billsToPayForm
      .get('dueDate')
      .valueChanges.subscribe((val) => this.changeDueDate(val));

    this.billsToPayForm
      .get('frequency')
      .valueChanges.subscribe((val) => this.changeFrequency(val));

    this.billsToPayForm
      .get('parcAmount')
      .valueChanges.subscribe((val) => this.changeParcAmount(val));

    this.billsToPayForm
      .get('paymentType')
      .valueChanges.subscribe((val) => this.changePaymentType(val));

    this.billsToPayForm
      .get('paymentMethod')
      .valueChanges.subscribe((val) => this.changePaymentMethod());
  }

  changePaymentType(value) {
    if (value === 'in_cash') {
      this.parcels.clear();
      this.billsToPayForm.get('parcAmount').setValue(null);
      this.billsToPayForm.get('frequency').setValue('');
    }
  }

  changePaymentMethod() {
    this.parcels.clear();
    this.billsToPayForm.get('creditCard').setValue('');
    this.billsToPayForm.get('bank').setValue('');
    this.billsToPayForm.get('bankCheckNumber').setValue('');
    if (this.billsToPayForm.get('parcValue')?.value) {
      this.changeParcAmount(this.billsToPayForm.get('parcValue').value);
    }
  }

  changeFrequency(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  changeDueDate(value: string) {
    if (!value) return;

    this.parcels.controls.forEach((item, index) => {
      item.get('dueDate').setValue(this.parcelDueDate(index));
    });
  }

  changeValue(val: string) {
    if (!val) return;
    this.setParcValue();
  }

  changeParcAmount(val: string) {
    const parcAmount = parseInt(val);

    if (isNaN(parcAmount) || parcAmount <= 1) {
      this.parcels.clear();
      return;
    }

    const currentLength = this.parcels.length;

    if (currentLength > parcAmount) {
      for (let i = currentLength + 1; i >= parcAmount; i--) {
        this.parcels.removeAt(i);
      }
    } else if (currentLength < parcAmount) {
      for (let i = currentLength + 1; i <= parcAmount; i++) {
        const dueDate = this.parcelDueDate(i - 1) || '';

        const paid = this.billsToPayForm.get('paid').value;

        this.parcels.push(
          this.fb.group({
            number: [i],
            value: [this.parcelValue()],
            paidValue: [''],
            paid: [paid],
            bankCheckNumber: ['', [Validators.maxLength(10)]],
            dueDate: [dueDate],
          })
        );
      }
    }

    this.setParcValue();
  }

  get parcels() {
    return this.billsToPayForm.get('parcels') as FormArray;
  }

  onlyNumber(event: any): void {
    event.key.match(/[^0-9]/) && event.preventDefault();
  }

  setParcValue() {
    const value = this.parcelValue();
    this.parcels.controls.forEach((item) => {
      item.get('value').setValue(value);
    });
  }

  parcelValue() {
    const { value, parcAmount } = this.billsToPayForm.getRawValue();

    if (!value || !parcAmount) return '';

    return value / parcAmount;
  }

  parcelDueDate(index) {
    const { frequency, dueDate } = this.billsToPayForm.getRawValue();
    if (!frequency || !dueDate) return null;

    const date = new Date(dueDate.replace('-', '/'));

    const iteration = index + 1;

    switch (frequency) {
      case 'days':
        date.setDate(date.getDate() + 1 * iteration);
        break;
      case 'weeks':
        date.setDate(date.getDate() + 7 * iteration);
        break;
      case 'fortnights':
        date.setDate(date.getDate() + 15 * iteration);
        break;
      case 'months':
        date.setMonth(date.getMonth() + 1 * iteration);
        break;
      case 'bimonthly':
        date.setMonth(date.getMonth() + 2 * iteration);
        break;
      case 'quarters':
        date.setMonth(date.getMonth() + 3 * iteration);
        break;
      case 'semesters':
        date.setMonth(date.getMonth() + 6 * iteration);
        break;
      case 'years':
        date.setFullYear(date.getFullYear() + 1 * iteration);
        break;
      default:
        break;
    }

    return date.toDateString();
  }

  onSubmit() {
    this.messageError = '';

    if (this.billsToPayForm.valid) {
      const billsToPay = this.billsToPayForm.getRawValue();

      billsToPay.id = this.id;

      billsToPay.provider = {
        id: Number(this.billsToPayForm.get('provider').value) || null,
      };
      billsToPay.costCenter = {
        id: Number(this.billsToPayForm.get('costCenter').value) || null,
      };
      billsToPay.bank = {
        id: Number(this.billsToPayForm.get('bank').value) || null,
      };
      billsToPay.creditCard = {
        id: Number(this.billsToPayForm.get('creditCard').value) || null,
      };

      billsToPay.parcAmount = Number(
        this.billsToPayForm.get('parcAmount').value
      );

      billsToPay.dueDate = new Date(billsToPay.dueDate.replace('-', '/'));

      this.service.update(billsToPay).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Editada',
            'Conta a pagar editada com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/bills-to-pay']);
            }
          });
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao editar',
            'Não foi possível editar conta a pagar!',
            'error'
          );
        }
      );
    }
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'email':
          this.billsToPayForm.controls.email.setErrors({
            invalid: erro.message,
          });
          break;
        case 'name':
          this.billsToPayForm.controls.name.setErrors({
            invalid: erro.message,
          });
          break;
        case 'role':
          this.billsToPayForm.controls.role.setErrors({
            invalid: erro.message,
          });
          break;
        case 'birthDate':
          this.billsToPayForm.controls.birthDate.setErrors({
            invalid: erro.message,
          });
          break;
        case 'phone':
          this.billsToPayForm.controls.phone.setErrors({
            invalid: erro.message,
          });
          break;
        case 'login':
          this.billsToPayForm.controls.login.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getFrequency(firstDate, secondDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = (year2 - year1) * 12 + (month2 - month1);

    let frequency = '';

    switch (diffDays) {
      case 1:
        frequency = 'days';
        break;
      case 7:
        frequency = 'weeks';
        break;
      case 14:
      case 15:
        frequency = 'fortnights';
        break;
      default:
        frequency = '';
    }

    if (!frequency) {
      switch (diffMonths) {
        case 1:
          frequency = 'months';
          break;
        case 2:
          frequency = 'bimonthly';
          break;
        case 3:
          frequency = 'quarters';
          break;
        case 6:
          frequency = 'semesters';
          break;
        case 12:
          frequency = 'years';
          break;
        default:
          frequency = '';
      }
    }

    return frequency;
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  paidChanged(paid: boolean) {
    if (paid) {
      this.billsToPayForm.get('paidValue').setValidators([Validators.required]);
    } else {
      this.billsToPayForm.get('paidValue').clearValidators();
    }
    this.billsToPayForm.get('paidValue').updateValueAndValidity();
  }

  parcelPaidChanged(i: number, paid: boolean) {
    if (paid) {
      this.parcels.controls[i]
        .get('paidValue')
        .setValidators([Validators.required]);
    } else {
      this.parcels.controls[i].get('paidValue').clearValidators();
    }
    this.parcels.controls[i].get('paidValue').updateValueAndValidity();
    this.checkAllPaidParcels();
  }

  checkAllPaidParcels() {
    const paidParcelsCount = this.parcels.getRawValue().filter((item) => {
      if (item.paid) return item;
    });
    this.billsToPayForm
      .get('paid')
      .setValue(paidParcelsCount.length === this.parcels.getRawValue().length);
    this.paidChanged(
      paidParcelsCount.length === this.parcels.getRawValue().length
    );
  }
}
