import { Component, OnInit } from '@angular/core';
import { Insurer } from 'src/app/domain/insurer';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InsurerService } from 'src/app/service/insurer/insurer.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-insurer',
  templateUrl: './insurer.component.html',
  styleUrls: ['./insurer.component.css']
})
export class InsurerComponent implements OnInit {

  insurers: Array<Insurer> = [];
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  insurer: Insurer = new Insurer();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;


  constructor(
    private service: InsurerService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {

    this.route.queryParams.subscribe(params => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.insurer.name = params.name ?? "";
      this.insurer.contact = params.contact ?? "";
      this.insurer.active = params.active ?? null;
    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getInsurers();

  }

  getInsurers() {
    this.loading = true;
    this.service.getInsurers(this.insurer, this.pagination).subscribe(data => {
      this.insurers = data['results'];
      this.setPagination(data);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });
    this.searchForm = this.fb.group({
      name: [this.insurer.name],
      contact: [this.insurer.contact],
      active: [this.insurer.active]
    });
  }

  onSearch() {
    this.insurer.name = this.searchForm.controls.name.value;
    this.insurer.contact = this.searchForm.controls.contact.value;

    switch (this.searchForm.controls.active.value) {
      case "true":
        this.insurer.active = true;
        break;
      case "false":
        this.insurer.active = false;
        break;
      default:
        this.insurer.active = null;
        break;
    }
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onActive(insurer: Insurer) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Seguradora? ${insurer.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(insurer.id).subscribe(data => {
          // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
          Swal.fire({
            title: 'Ativado',
            text: `Seguradora ativada com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar Seguradora!', 'error');
          });
      }

    });
  }
  onDeActive(insurer: Insurer) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Seguradora? ${insurer.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(insurer.id).subscribe(data => {

          Swal.fire({
            title: 'Desativado',
            text: `Seguradora desativada com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao desativar Seguradora!', 'error');
          });
      }

    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();

  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/parameters/insurer'], {
      queryParams: {
        name: this.insurer.name ?? "",
        active: this.insurer.active ?? "",
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10
      }
    });
    this.getInsurers();
  }


}
