import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialParameter } from 'src/app/domain/financial-parameter';
import { Pagination } from 'src/app/domain/pagination';
import { EnumFinancialType } from 'src/app/enumerations/financial-type';
import { EnumParameterType } from 'src/app/enumerations/parameter-type';
import { FinancialParameterService } from 'src/app/service/financial-parameter/financial-parameter.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-financial-parameter',
  templateUrl: './financial-parameter.component.html',
  styleUrls: ['./financial-parameter.component.css']
})
export class FinancialParameterComponent implements OnInit {

  financialParameters: Array<FinancialParameter> = [];
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  financialParameter: FinancialParameter = new FinancialParameter();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;


  constructor(
    private service: FinancialParameterService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {

    this.route.queryParams.subscribe(params => {


      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.financialParameter.type = params.type ?? "";
      this.financialParameter.parameter = params.parameter ?? "";
      this.financialParameter.active = params.active ?? "";

    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getFinancialParameters();

  }

  getFinancialParameters() {
    this.loading = true;
    this.service.getFinancialParameters(this.financialParameter, this.pagination).subscribe(data => {
      this.financialParameters = data['results'].map((financialParameter, index, array) => {

        financialParameter.type = EnumFinancialType[financialParameter.type];
        financialParameter.parameter = EnumParameterType[financialParameter.parameter];
        return financialParameter;
      });
      this.setPagination(data);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });
    this.searchForm = this.fb.group({
      type: [this.financialParameter.type],
      parameter: [this.financialParameter.parameter],
      active: [this.financialParameter.active]
    });
  }

  onSearch() {
    this.financialParameter.type = this.searchForm.controls.type.value;
    this.financialParameter.parameter = this.searchForm.controls.parameter.value;

    switch (this.searchForm.controls.active.value) {
      case "true":
        this.financialParameter.active = true;
        break;
      case "false":
        this.financialParameter.active = false;
        break;
      default:
        this.financialParameter.active = null;
        break;
    }
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onActive(financialParameter: FinancialParameter) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Parâmetro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(financialParameter.id).subscribe(data => {
          // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
          Swal.fire({
            title: 'Ativado',
            text: `Parâmetro ativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar Parâmetro!', 'error');
          });
      }

    });
  }
  onDeActive(financialParameter: FinancialParameter) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Parâmetro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(financialParameter.id).subscribe(data => {

          Swal.fire({
            title: 'Desativado',
            text: `Parâmetro desativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao desativar Parâmetro!', 'error');
          });
      }

    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();

  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/parameters/financial-parameter'], {
      queryParams: {
        type: this.financialParameter.type ?? "",
        parameter: this.financialParameter.parameter ?? "",
        active: this.financialParameter.active ?? "",
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10
      }
    });
    this.getFinancialParameters();
  }

}
