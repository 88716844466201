import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessProfileService } from 'src/app/service/access-profile/access-profile.service';
import { AccessProfile } from 'src/app/domain/access-profile';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-access-profile-view',
  templateUrl: './access-profile-view.component.html',
  styleUrls: ['./access-profile-view.component.css']
})
export class AccessProfileViewComponent implements OnInit {
  id: number;
  accessProfile: AccessProfile = new AccessProfile();
  loading: boolean = true;

  constructor(
    private service: AccessProfileService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getAccessProfile();
  }

  getAccessProfile() {
    this.loading = true;
    this.service.getAccessProfile(this.id).subscribe(data => {
      this.accessProfile = data;
      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os Perfis de Acesso!', 'error');
  }

}
