import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CovenantLogin } from 'src/app/domain/covenant-login';
import { User } from 'src/app/enumerations/user';
import { CovenantLoginService } from 'src/app/service/covenant-login/covenant-login.service';
import jwt_decode from "jwt-decode";
import { Covenant } from 'src/app/domain/covenant';
import { CovenantService } from 'src/app/service/covenant/covenant.service';

@Component({
  selector: 'app-covenant-login',
  templateUrl: './covenant-login.component.html',
  styleUrls: ['./covenant-login.component.css']
})
export class CovenantLoginComponent implements OnInit {


  login: CovenantLogin = new CovenantLogin();
  loginForm: FormGroup;
  loading: boolean = false;
  submited: boolean = false;

  constructor(
    private service: CovenantLoginService,
    private serviceCovenant: CovenantService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  onSubmit() {
    this.submited = true;

    if (this.loginForm.controls.email.invalid && this.loginForm.controls.email.value) {
      this.loginForm.controls.email.setErrors(null);
    }
    if (!this.loginForm.invalid) {
      this.login.email = this.loginForm.controls.email.value;
      this.login.password = this.loginForm.controls.password.value;
      this.loading = true;

      this.service.login(this.login).subscribe(data => {
        console.log(data);
        var token = data.headers.get('X-Authorization');
        var decoded = jwt_decode(token);
        localStorage.setItem("token", token)
        localStorage.setItem("user", User.COVENANT);
        localStorage.setItem("convenant", JSON.stringify({ convenant: decoded['covenantId'], name: decoded['name'] }));
        this.loading = false;
        this.router.navigate(['/convenio'])
      }, error => {
        this.loading = false;
        this.loginForm.controls.email.setErrors({ invalid: 'Email e/ou senha inválidos!!' })
      });
    } else {
      // if(this.loginForm.controls.email.value.length() === 0){this.loginForm.controls.email.setErrors()}
    }


  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

}
