import { PayDriverViewComponent } from './components/pay-driver/pay-driver-view/pay-driver-view.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { HelpAngularComponent } from './components/help-angular/help-angular.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AccessProfileComponent } from './components/access-profile/access-profile.component';
import { AccessProfileViewComponent } from './components/access-profile/access-profile-view/access-profile-view.component';
import { AccessProfileCreateComponent } from './components/access-profile/access-profile-create/access-profile-create.component';
import { AccessProfileUpdateComponent } from './components/access-profile/access-profile-update/access-profile-update.component';
import { CarComponent } from './components/car/car.component';
import { CarCreateComponent } from './components/car/car-create/car-create.component';
import { CarViewComponent } from './components/car/car-view/car-view.component';
import { CarUpdateComponent } from './components/car/car-update/car-update.component';
import { UserComponent } from './components/user/user.component';
import { UserCreateComponent } from './components/user/user-create/user-create.component';
import { UserViewComponent } from './components/user/user-view/user-view.component';
import { UserUpdateComponent } from './components/user/user-update/user-update.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ProviderComponent } from './components/provider/provider.component';
import { ProviderCreateComponent } from './components/provider/provider-create/provider-create.component';
import { ProviderViewComponent } from './components/provider/provider-view/provider-view.component';
import { ProviderUpdateComponent } from './components/provider/provider-update/provider-update.component';
import { PieceCreateComponent } from './components/piece/piece-create/piece-create.component';
import { PieceUpdateComponent } from './components/piece/piece-update/piece-update.component';
import { PieceViewComponent } from './components/piece/piece-view/piece-view.component';
import { PieceComponent } from './components/piece/piece.component';
import { InsurerComponent } from './components/insurer/insurer.component';
import { InsurerCreateComponent } from './components/insurer/insurer-create/insurer-create.component';
import { InsurerViewComponent } from './components/insurer/insurer-view/insurer-view.component';
import { InsurerUpdateComponent } from './components/insurer/insurer-update/insurer-update.component';
import { BankComponent } from './components/bank/bank.component';
import { BankCreateComponent } from './components/bank/bank-create/bank-create.component';
import { BankViewComponent } from './components/bank/bank-view/bank-view.component';
import { BankUpdateComponent } from './components/bank/bank-update/bank-update.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertCreateComponent } from './components/alert/alert-create/alert-create.component';
import { AlertViewComponent } from './components/alert/alert-view/alert-view.component';
import { AlertUpdateComponent } from './components/alert/alert-update/alert-update.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { CreditCardCreateComponent } from './components/credit-card/credit-card-create/credit-card-create.component';
import { CreditCardViewComponent } from './components/credit-card/credit-card-view/credit-card-view.component';
import { AuthGuardService as AuthGuard } from './security/auth-guard/auth-guard.service';
import { GasStationComponent } from './components/gas-station/gas-station.component';
import { GasStationCreateComponent } from './components/gas-station/gas-station-create/gas-station-create.component';
import { GasStationViewComponent } from './components/gas-station/gas-station-view/gas-station-view.component';
import { GasStationUpdateComponent } from './components/gas-station/gas-station-update/gas-station-update.component';
import { RequestValueComponent } from './components/request-value/request-value.component';
import { RequestValueCreateComponent } from './components/request-value/request-value-create/request-value-create.component';
import { RequestValueViewComponent } from './components/request-value/request-value-view/request-value-view.component';
import { RequestValueUpdateComponent } from './components/request-value/request-value-update/request-value-update.component';
import { DriverComponent } from './components/driver/driver.component';
import { DriverCreateComponent } from './components/driver/driver-create/driver-create.component';
import { DriverViewComponent } from './components/driver/driver-view/driver-view.component';
import { DriverUpdateComponent } from './components/driver/driver-update/driver-update.component';
import { CarRefuelComponent } from './components/car/car-refuel/car-refuel.component';
import { CarMaintenanceComponent } from './components/car/car-maintenance/car-maintenance.component';
import { CarFineComponent } from './components/car/car-fine/car-fine.component';
import { ClientComponent } from './components/client/client.component';
import { ClientCreateComponent } from './components/client/client-create/client-create.component';
import { ClientViewComponent } from './components/client/client-view/client-view.component';
import { ClientUpdateComponent } from './components/client/client-update/client-update.component';
import { CostCenterComponent } from './components/cost-center/cost-center.component';
import { CostCenterCreateComponent } from './components/cost-center/cost-center-create/cost-center-create.component';
import { CostCenterViewComponent } from './components/cost-center/cost-center-view/cost-center-view.component';
import { CostCenterUpdateComponent } from './components/cost-center/cost-center-update/cost-center-update.component';
import { BillsToPayComponent } from './components/bills-to-pay/bills-to-pay.component';
import { BillsToPayCreateComponent } from './components/bills-to-pay/bills-to-pay-create/bills-to-pay-create.component';
import { BillsToPayUpdateParcelsComponent } from './components/bills-to-pay/update-parcels/update-parcels.component';
import { BillsToReceiveUpdateParcelsComponent } from './components/bills-to-receive/update-parcels/update-parcels.component';
import { BillsToPayViewComponent } from './components/bills-to-pay/bills-to-pay-view/bills-to-pay-view.component';
import { BillsToPayUpdateComponent } from './components/bills-to-pay/bills-to-pay-update/bills-to-pay-update.component';
import { BillsToReceiveComponent } from './components/bills-to-receive/bills-to-receive.component';
import { BillsToReceiveCreateComponent } from './components/bills-to-receive/bills-to-receive-create/bills-to-receive-create.component';
import { BillsToReceiveViewComponent } from './components/bills-to-receive/bills-to-receive-view/bills-to-receive-view.component';
import { BillsToReceiveUpdateComponent } from './components/bills-to-receive/bills-to-receive-update/bills-to-receive-update.component';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { SchedulingCreateComponent } from './components/scheduling/scheduling-create/scheduling-create.component';
import { SchedulingViewComponent } from './components/scheduling/scheduling-view/scheduling-view.component';
import { SchedulingUpdateComponent } from './components/scheduling/scheduling-update/scheduling-update.component';
import { TripRequestComponent } from './components/trip-request/trip-request.component';
import { TripRequestCreateComponent } from './components/trip-request/trip-request-create/trip-request-create.component';
import { TripRequestViewComponent } from './components/trip-request/trip-request-view/trip-request-view.component';
import { TripRequestUpdateComponent } from './components/trip-request/trip-request-update/trip-request-update.component';
import { TripContributorsComponent } from './components/trip-contributors/trip-contributors.component';
import { CovenantUserComponent } from './components/covenant/covenant-user/covenant-user.component';
import { CovenantRequestValuesComponent } from './components/covenant/covenant-request-values/covenant-request-values.component';
import { CovenantCollaboratorsComponent } from './components/covenant/covenant-collaborators/covenant-collaborators.component';
import { CovenantComponent } from './components/covenant/covenant.component';
import { CovenantCreateComponent } from './components/covenant/covenant-create/covenant-create.component';
import { CovenantViewComponent } from './components/covenant/covenant-view/covenant-view.component';
import { CovenantUpdateComponent } from './components/covenant/covenant-update/covenant-update.component';
import { CovenantForgotPasswordComponent } from './gateway/covenant-forgot-password/covenant-forgot-password.component';
import { CovenantNewPasswordComponent } from './gateway/covenant-new-password/covenant-new-password.component';
import { CovenantMyaccountComponent } from './gateway/covenant-myaccount/covenant-myaccount.component';
import { CovenantDataComponent } from './gateway/covenant-data/covenant-data.component';
import { CovenantWorkerComponent } from './gateway/covenant-worker/covenant-worker.component';
import { CovenantLoginComponent } from './gateway/covenant-login/covenant-login.component';
import { CovenantDashboardComponent } from './gateway/covenant-dashboard/covenant-dashboard.component';
import { CovenantDashboardUpdateComponent } from './gateway/covenant-dashboard/covenant-dashboard-update/covenant-dashboard-update.component';
import { CovenantDashboardViewComponent } from './gateway/covenant-dashboard/covenant-dashboard-view/covenant-dashboard-view.component';
import { LinkVehicleComponent } from './components/trip-request/link-vehicle/link-vehicle.component';
import { CancelComponent } from './components/trip-request/cancel/cancel.component';
import { CovenantTripRequestComponent } from './gateway/covenant-trip-request/covenant-trip-request.component';
import { CovenantTripRequestListComponent } from './gateway/covenant-trip-request-list/covenant-trip-request-list.component';
import { CovenantShareTripComponent } from './gateway/covenant-share-trip/covenant-share-trip.component';
import { BankAccountComponent } from './components/bank-account/bank-account.component';
import { BankAccountCreateComponent } from './components/bank-account/bank-account-create/bank-account-create.component';
import { BankAccountViewComponent } from './components/bank-account/bank-account-view/bank-account-view.component';
import { BankAccountUpdateComponent } from './components/bank-account/bank-account-update/bank-account-update.component';
import { ActiveAccountComponent } from './components/driver/active-account/active-account.component';
import { LinkDriverCarComponent } from './components/link-driver-car/link-driver-car.component';
import { GenerateBillsToReceiveCreateComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-create/generate-bills-to-receive-create.component';
import { GenerateBillsToReceiveUpdateBillsComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-update-bills/generate-bills-to-receive-update-bills.component';
import { GenerateBillsToReceiveDeleteComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-delete/generate-bills-to-receive-delete.component';
import { GenerateBillsToReceiveComponent } from './components/generate-bills-to-receive/generate-bills-to-receive.component';
import { TripApprovalComponent } from './components/trip-request/trip-approval/trip-approval.component';
import { ActiveAccountUserComponent } from './components/covenant/covenant-user/active-account/active-account-user.component';
import { AuthGuardCovenantService } from './security/auth-guard-covenant/auth-guard-covenant.service';
import { RunsComponent } from './components/runs/runs.component';
import { RunsByRequestComponent } from './components/runs/runs-by-request/runs-by-request.component';
import { RunsViewComponent } from './components/runs/runs-view/runs-view.component';
import { CovenantTripRequestCancelComponent } from './gateway/covenant-trip-request-cancel/covenant-trip-request-cancel.component';
import { CovenantRunViewComponent } from './gateway/covenant-run-view/covenant-run-view.component';
import { CovenantRunTripRequestViewComponent } from './gateway/covenant-run-trip-request-view/covenant-run-trip-request-view.component';
import { CovenantRunTripRequestLinkVehicleComponent } from './gateway/covenant-run-trip-request-link-vehicle/covenant-run-trip-request-link-vehicle.component';
import { CovenantRunLinkVehicleComponent } from './gateway/covenant-run-link-vehicle/covenant-run-link-vehicle.component';
import { ClientActiveAccountComponent } from './components/client/client-active-account/client-active-account.component';
import { FinancialParameterCreateComponent } from './components/financial-parameter/financial-parameter-create/financial-parameter-create.component';
import { FinancialParameterUpdateComponent } from './components/financial-parameter/financial-parameter-update/financial-parameter-update.component';
import { FinancialParameterViewComponent } from './components/financial-parameter/financial-parameter-view/financial-parameter-view.component';
import { FinancialParameterComponent } from './components/financial-parameter/financial-parameter.component';
import { PaymentDriverCreateComponent } from './components/payment-driver/payment-driver-create/payment-driver-create.component';
import { PaymentDriverComponent } from './components/payment-driver/payment-driver.component';
import { AccountBankComponent } from './components/account-bank/account-bank.component';
import { AccountBankCreateComponent } from './components/account-bank/account-bank-create/account-bank-create.component';
import { AccountBankViewComponent } from './components/account-bank/account-bank-view/account-bank-view.component';
import { AccountBankUpdateComponent } from './components/account-bank/account-bank-update/account-bank-update.component';
import { PaymentDriverUpdateComponent } from './components/payment-driver/payment-driver-update/payment-driver-update.component';
import { UserAlterPasswordComponent } from './components/user/user-alter-password/user-alter-password.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CarRefuelListComponent } from './components/car/car-refuel-list/car-refuel-list.component';
import { PayDriverComponent } from './components/pay-driver/pay-driver.component';
import { PayDriverCreateComponent } from './components/pay-driver/pay-driver-create/pay-driver-create.component';
import { ActiveAccountCollaboratorComponent } from './components/covenant/active-account-collaborator/active-account-collaborator.component';
import { RunsUpdateComponent } from './components/runs/runs-update/runs-update.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GenerateBillsToReceivePaidComponent } from './components/generate-bills-to-receive/generate-bills-to-receive-paid/generate-bills-to-receive-paid.component';
import { CashFlowComponent } from './components/cash-flow/cash-flow.component';
import { FinishRunComponent } from './components/runs/finish/finish.component';
import { ChartAmountRunsByDriverComponent } from './components/chart-amount-runs-by-driver/chart-amount-runs-by-driver.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'security',
    children: [
      {
        path: 'access-profile',
        component: AccessProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'access-profile/new',
        component: AccessProfileCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'access-profile/view/:id',
        component: AccessProfileViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'access-profile/edit/:id',
        component: AccessProfileUpdateComponent,
        canActivate: [AuthGuard],
      },
      { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
      {
        path: 'user/new',
        component: UserCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user/view/:id',
        component: UserViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user/edit/:id',
        component: UserUpdateComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'parameters',
    children: [
      {
        path: 'alert',
        component: AlertComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'alert/new',
        component: AlertCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'alert/view/:id',
        component: AlertViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'alert/edit/:id',
        component: AlertUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bank',
        component: BankComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bank/new',
        component: BankCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bank/view/:id',
        component: BankViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bank/edit/:id',
        component: BankUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'credit-card',
        component: CreditCardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'credit-card/new',
        component: CreditCardCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'credit-card/view/:id',
        component: CreditCardViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'financial-parameter',
        component: FinancialParameterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'financial-parameter/new',
        component: FinancialParameterCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'financial-parameter/view/:id',
        component: FinancialParameterViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'financial-parameter/edit/:id',
        component: FinancialParameterUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'gas-station',
        component: GasStationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'gas-station/new',
        component: GasStationCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'gas-station/view/:id',
        component: GasStationViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'gas-station/edit/:id',
        component: GasStationUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'insurer',
        component: InsurerComponent,
        canActivate: [AuthGuard],
      },

      {
        path: 'insurer/new',
        component: InsurerCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'insurer/view/:id',
        component: InsurerViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'insurer/edit/:id',
        component: InsurerUpdateComponent,
        canActivate: [AuthGuard],
      },
      { path: 'piece', component: PieceComponent, canActivate: [AuthGuard] },
      {
        path: 'piece/new',
        component: PieceCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'piece/view/:id',
        component: PieceViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'piece/edit/:id',
        component: PieceUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'provider',
        component: ProviderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'provider/new',
        component: ProviderCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'provider/view/:id',
        component: ProviderViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'provider/edit/:id',
        component: ProviderUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'request-value',
        component: RequestValueComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'request-value/new',
        component: RequestValueCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'request-value/view/:id',
        component: RequestValueViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'request-value/edit/:id',
        component: RequestValueUpdateComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'financial',
    children: [
      {
        path: 'bank-account',
        component: BankAccountComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bank-account/new',
        component: BankAccountCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bank-account/view/:id',
        component: BankAccountViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bank-account/edit/:id',
        component: BankAccountUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-pay',
        component: BillsToPayComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-pay/new',
        component: BillsToPayCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-pay/view/:id',
        component: BillsToPayViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-pay/edit/:id',
        component: BillsToPayUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-pay/update-parcels/:id',
        component: BillsToPayUpdateParcelsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-receive/update-parcels/:id',
        component: BillsToReceiveUpdateParcelsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-receive',
        component: BillsToReceiveComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-receive/new',
        component: BillsToReceiveCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-receive/view/:id',
        component: BillsToReceiveViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'bills-to-receive/edit/:id',
        component: BillsToReceiveUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'pay-driver',
        component: PayDriverComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'pay-driver/create',
        component: PayDriverCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'pay-driver/view/:id',
        component: PayDriverViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'account-bank',
        component: AccountBankComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'account-bank/new',
        component: AccountBankCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'account-bank/view/:id',
        component: AccountBankViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'account-bank/edit/:id',
        component: AccountBankUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cost-center',
        component: CostCenterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cost-center/new',
        component: CostCenterCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cost-center/view/:id',
        component: CostCenterViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cost-center/edit/:id',
        component: CostCenterUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'generate-bills-to-receive',
        component: GenerateBillsToReceiveComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'generate-bills-to-receive/new',
        component: GenerateBillsToReceiveCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'generate-bills-to-receive/update-bills/:id',
        component: GenerateBillsToReceiveUpdateBillsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'generate-bills-to-receive/delete/:id',
        component: GenerateBillsToReceiveDeleteComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'generate-bills-to-receive/paid/:id',
        component: GenerateBillsToReceivePaidComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'maintenance',
    children: [
      { path: 'car', component: CarComponent, canActivate: [AuthGuard] },
      {
        path: 'car/new',
        component: CarCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/view/:id',
        component: CarViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/edit/:id',
        component: CarUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/refuel/new',
        component: CarRefuelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/refuel/:id',
        component: CarRefuelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/maintenance/:id',
        component: CarMaintenanceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/fines/:id',
        component: CarFineComponent,
        canActivate: [AuthGuard],
      },
      { path: 'client', component: ClientComponent, canActivate: [AuthGuard] },
      {
        path: 'client/new',
        component: ClientCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'client/view/:id',
        component: ClientViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'client/edit/:id',
        component: ClientUpdateComponent,
        canActivate: [AuthGuard],
      },
      { path: 'client/active/:token', component: ClientActiveAccountComponent },
      {
        path: 'covenant',
        component: CovenantComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'covenant/new',
        component: CovenantCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'covenant/view/:id',
        component: CovenantViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'covenant/edit/:id',
        component: CovenantUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'covenant/users/:id',
        component: CovenantUserComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'covenant/collaborators/:id',
        component: CovenantCollaboratorsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'covenant/request-values/:id',
        component: CovenantRequestValuesComponent,
        canActivate: [AuthGuard],
      },
      { path: 'driver', component: DriverComponent, canActivate: [AuthGuard] },
      {
        path: 'driver/new',
        component: DriverCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'driver/:id/vehicle',
        component: LinkDriverCarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'driver/view/:id',
        component: DriverViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'driver/edit/:id',
        component: DriverUpdateComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'reports',
    children: [
      {
        path: 'car-refuel',
        component: CarRefuelListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cash-flow',
        component: CashFlowComponent,
        canActivate: [AuthGuard],
      },
    ],
  },

  { path: 'auth/forgot/driver/:token', component: ActiveAccountComponent },
  {
    path: 'auth/forgot/collaborator/:token',
    component: ActiveAccountCollaboratorComponent,
  },
  {
    path: 'user/alter-password',
    component: UserAlterPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'scheduling',
    component: SchedulingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'scheduling/new',
    component: SchedulingCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'scheduling/view/:id',
    component: SchedulingViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'scheduling/edit/:id',
    component: SchedulingUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'help-angular',
    component: HelpAngularComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-driver/new',
    component: PaymentDriverCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-driver',
    component: PaymentDriverComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-driver/edit',
    component: PaymentDriverUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    component: NotificationComponent,
    canActivate: [AuthGuard],
  },
  { path: 'auth/forgot', component: ForgotPasswordComponent },
  { path: 'auth/forgot/:token', component: ResetPasswordComponent },
  {
    path: 'process',
    children: [
      {
        path: 'trip-request',
        component: TripRequestComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trip-request/new',
        component: TripRequestCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trip-request/cancel/:id',
        component: CancelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trip-request/view/:id',
        component: TripRequestViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trip-request/edit/:id',
        component: TripRequestUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trip-request/:id/link-vehicle',
        component: LinkVehicleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trip-request/trip-approval/:id',
        component: TripApprovalComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'runs',
        component: RunsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'runs/:id/edit',
        component: RunsUpdateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'runs/:id/request',
        component: RunsByRequestComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'runs/:id/finish',
        component: FinishRunComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'runs/:id',
        component: RunsViewComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'trip-contributors',
    component: TripContributorsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboards',
    children: [
      {
        path: 'runs-per-hour',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'amount-runs-per-driver',
        component: ChartAmountRunsByDriverComponent,
        canActivate: [AuthGuard],
      },
    ],
  },

  //Requisições de Portal do Convênio
  { path: 'convenio/login', component: CovenantLoginComponent },
  {
    path: 'convenio/user/activate/:token',
    component: ActiveAccountUserComponent,
  },
  { path: 'convenio/auth/forgot', component: CovenantForgotPasswordComponent },
  {
    path: 'convenio/auth/forgot/:token',
    component: CovenantNewPasswordComponent,
  },
  {
    path: 'convenio',
    component: CovenantDashboardComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/dashboard/edit/:id',
    component: CovenantDashboardUpdateComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/dashboard/view/:id',
    component: CovenantDashboardViewComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/my-account',
    component: CovenantMyaccountComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/data',
    component: CovenantDataComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/workers',
    component: CovenantWorkerComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/covenant-trip-request',
    component: CovenantTripRequestComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/covenant-trip-request/:id',
    component: CovenantRunTripRequestViewComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/covenant-trip-request/:id/link-vehicle',
    component: CovenantRunTripRequestLinkVehicleComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/covenant-trip-request-list',
    component: CovenantTripRequestListComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/covenant-trip-request-cancel/:id',
    component: CovenantTripRequestCancelComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/covenant-share-trip',
    component: CovenantShareTripComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/run/view/:id',
    component: CovenantRunViewComponent,
    canActivate: [AuthGuardCovenantService],
  },
  {
    path: 'convenio/run/:id/link-vehicle',
    component: CovenantRunLinkVehicleComponent,
    canActivate: [AuthGuardCovenantService],
  },

  { path: '**', component: NotfoundComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
