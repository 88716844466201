import { Bank } from '../bank';

export class BankDTO {
  name: string;
  number: string;
  active: boolean;

  constructor(bank: Bank) {
    this.name = bank.name;
    this.number = bank.number;
    this.active = bank.active;
  }
}
