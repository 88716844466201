<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <!-- Inserção de Usuários -->
        <div class="container">
          <div class="row mt-4 mb-2">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Usuário do Convênio</div>
            </div>
          </div>
          <div [formGroup]="covenantUserForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input type="text" class="form-control" id="name" formControlName="name" placeholder="Nome">
                  <span *ngIf="covenantUserForm.controls.name.errors">
                    <span *ngIf="covenantUserForm.controls.name.errors.required" class="text-danger position-right">
                      Campo Obrigatório
                    </span>
                    <span *ngIf="covenantUserForm.controls.name.errors.maxlength"
                      class="text-danger position-right">*Nome deve ter
                      no máximo 100 caracteres</span>
                    <span *ngIf="covenantUserForm.controls.name.errors.invalid" class="text-danger position-right">*
                      {{covenantUserForm.controls.name.errors.invalid}} </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input type="text" class="form-control" id="email" formControlName="email" placeholder="E-mail">
                  <span *ngIf="covenantUserForm.controls.email.errors">
                    <span *ngIf="covenantUserForm.controls.email.errors.required" class="text-danger position-right">
                      Campo Obrigatório
                    </span>
                    <span *ngIf="covenantUserForm.controls.email.errors.maxlength"
                      class="text-danger position-right">*E-mail deve ter
                      no máximo 100 caracteres</span>
                    <span *ngIf="covenantUserForm.controls.email.errors.invalid" class="text-danger position-right">*
                      {{covenantUserForm.controls.email.errors.invalid}} </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="role">Cargo</label>
                  <input type="text" class="form-control" id="role" formControlName="role" placeholder="Cargo">
                  <span *ngIf="covenantUserForm.controls.role.errors">
                    <span *ngIf="covenantUserForm.controls.role.errors.required" class="text-danger position-right">
                      Campo Obrigatório
                    </span>
                    <span *ngIf="covenantUserForm.controls.role.errors.maxlength"
                      class="text-danger position-right">*Cargo deve ter
                      no máximo 100 caracteres</span>
                    <span *ngIf="covenantUserForm.controls.role.errors.invalid" class="text-danger position-right">*
                      {{covenantUserForm.controls.role.errors.invalid}} </span>
                  </span>

                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">

              <button type="button" (click)="onSubmit()" *ngIf="!isEditingId" [disabled]="!covenantUserForm.valid"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                Adicionar
              </button>
              <a type="button" (click)="onSubmit()" *ngIf="isEditingId"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                Salvar
              </a>
            </div>
          </div>
          <p class="page-title">Usuários</p>
          <div class="row form-group" [formGroup]="searchForm">
            <div class="col-6">
              <input type="text" class="form-control" placeholder="Nome" formControlName="name" />
            </div>
            <div class="col-5">
              <input type="text" class="form-control" placeholder="Cargo" formControlName="role">
            </div>
            <div class="col-1">
              <a (click)="onSearch()" class="btn btn-info">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped custom-table table-hover">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Cargo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users">
                  <td>
                    {{ user.name }}
                  </td>
                  <td>
                    {{ user.email }}
                  </td>
                  <td>
                    {{ user.role }}
                  </td>
                  <td>
                    <!-- <a (click)="onUserEdit(user)" class="btn btn-warning btn-xs">
                                            <i class="fa fa-pencil"></i>
                                        </a> -->
                    <button (click)="onUserDelete(user)" class="btn btn-danger btn-xs">
                      <i class="fa fa-trash-o "></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-12 ">
                <span *ngIf="!users.length" class="label label-danger label-mini">
                  Nenhum Usuário adicionado.
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-12 text-center">
              <a routerLink="/maintenance/covenant/view/{{covenantId}}" class="btn btn-danger">Voltar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
