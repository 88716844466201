import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Car } from 'src/app/domain/car';
import { CostCenter } from 'src/app/domain/cost-center';
import { Pagination } from 'src/app/domain/pagination';
import { PaymentMethod } from 'src/app/domain/paymentMethod';
import { TripRequest } from 'src/app/domain/trip-request';
import { EnumLocation } from 'src/app/enumerations/location';
import { EnumStatusSolicitation } from 'src/app/enumerations/status-solicitation';
import { CarService } from 'src/app/service/car/car.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { PaymentMethodsService } from 'src/app/service/payment-methods/payment-methods.service';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { WebsocketService } from 'src/app/service/websocket/websocket.service';
import { Passenger } from 'src/app/domain/passenger';
import { Driver } from 'src/app/domain/driver';
import { DriverService } from 'src/app/service/driver/driver.service';
import { Covenant } from 'src/app/domain/covenant';
import { CovenantService } from 'src/app/service/covenant/covenant.service';

@Component({
  selector: 'app-trip-request',
  templateUrl: './trip-request.component.html',
  styleUrls: ['./trip-request.component.css'],
})
export class TripRequestComponent implements OnInit {
  moment: any = moment;

  tripRequests: Array<TripRequest> = [];
  tripRequest: TripRequest;
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  paymentMethods: Array<PaymentMethod> = [];
  costCenters: Array<CostCenter> = [];
  cars: Array<Car> = [];
  drivers: Array<Driver> = [];
  isApprovalRequest: Boolean = false;
  covenants: Covenant[] = [];

  first: boolean = false;
  last: boolean = false;

  filter: Filter;

  constructor(
    private service: TripRequestService,
    private servicePaymentMethod: PaymentMethodsService,
    private serviceCostCenter: CostCenterService,
    private serviceCar: CarService,
    private serviceDriver: DriverService,
    private wsService: WebsocketService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private covenantService: CovenantService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;

      this.filter = new Filter();
      this.filter.active = params.active ?? null;
      this.filter.costCenter = params.cost_center ?? null;
      this.filter.status = params.status ?? 'waiting';
      this.filter.paymentMethod = params.payment_method ?? null;
      this.filter.dateStart =
        params.date_start ?? moment().format('yyyy-MM-DD');
      this.filter.dateEnd = params.date_end ?? null;
      this.filter.driver = params.driver ?? null;
      this.filter.vehicle = params.vehicle ?? null;
      this.filter.covenant = params.covenant ?? null;
      this.filter.passenger = params.passenger ?? null;
    });
  }

  ngOnInit(): void {
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getCovenants();
    this.getPaymentMethods();
    this.getCostCenters();
    this.getCars();
    this.getDrivers();

    this.createForm();
    this.getTripRequests();

    const { allowedPermissions } = this.getDecodedAccessToken();
    if (
      allowedPermissions.find(
        (element) => element.name == 'Aprovação de Corrida'
      )
    ) {
      this.isApprovalRequest = true;
    }

    this.wsService
      .getNewNotification()
      .subscribe((_) => this.getTripRequests());
  }
  onSearch() {
    this.filter.costCenter = this.searchForm.controls.costCenter.value;
    this.filter.status = this.searchForm.controls.status.value;
    this.filter.paymentMethod = this.searchForm.controls.paymentMethod.value;
    this.filter.vehicle = this.searchForm.controls.vehicle.value;
    this.filter.dateStart = this.searchForm.controls.dateStart.value;
    this.filter.dateEnd = this.searchForm.controls.dateEnd.value;
    this.filter.driver = this.searchForm.controls.driver.value;
    this.filter.covenant = this.searchForm.controls.covenant.value;
    this.filter.passenger = this.searchForm.controls.passenger.value;

    this.setNavigate();
  }
  onSelectedResults(e) {
    this.pagination.per_page = e.target.value;
    this.setNavigate();
  }

  getCovenants() {
    this.covenantService
      .getAllCovenants()
      .subscribe((data) => (this.covenants = data));
  }

  getPaymentMethods() {
    this.servicePaymentMethod
      .getPaymentMethods()
      .subscribe((data) => (this.paymentMethods = data));
  }
  getCostCenters() {
    this.serviceCostCenter
      .getAllCostCenters()
      .subscribe((data) => (this.costCenters = data));
  }

  getCars() {
    this.serviceCar.getCarsActives().subscribe((data) => {
      this.cars = data.map((car) => {
        return {
          ...car,
          modelAndName: `${car.model.name} - ${car.carPlate}`,
        };
      });
    });
  }

  getDrivers() {
    this.serviceDriver
      .getAllDriversBasic()
      .subscribe((data) => (this.drivers = data));
  }

  createForm() {
    this.searchForm = this.fb.group({
      costCenter: [this.filter.costCenter],
      status: [this.filter.status],
      paymentMethod: [this.filter.paymentMethod],
      dateStart: [this.filter.dateStart],
      dateEnd: [this.filter.dateEnd],
      driver: [this.filter.driver],
      vehicle: [this.filter.vehicle],
      covenant: [this.filter.covenant],
      passenger: [this.filter.passenger],
    });
  }

  linkVehicle(id: number) {
    Swal.fire('Vincular Motorista', `Numero do Veículo - ${id}`);
  }

  getTripRequests() {
    this.loading = true;

    this.service.getTripRequests(this.filter, this.pagination).subscribe(
      (data) => {
        this.tripRequests = data['results'].map((tripRequest, index, array) => {
          tripRequest.status = EnumStatusSolicitation[tripRequest.status];
          tripRequest.location = EnumLocation[tripRequest.location];
          tripRequest.passengers.forEach(({ isMain, origin, destiny }) => {
            if (isMain && origin) {
              //tripRequest.origin = `${origin.publicPlace} ${origin.neighborhood} ${origin.city.name} ${origin.zipCode}`;
              tripRequest.origin = origin?.city?.name;
              tripRequest.destiny = destiny?.city?.name;
            }
          });
          return tripRequest;
        });
        this.setPagination(data);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  exportTripRequests(type: string) {
    this.loading = true;

    this.service.exportTripRequests(this.filter, type).subscribe(
      (response) => {
        const fileName = `Relatorio_Solicitacao_${moment().format(
          'DDMMyyyy'
        )}.${type == 'excel' ? '.xlsx' : 'pdf'}`;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(response.body);
        link.download = fileName;
        link.click();

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  seeReason(data) {
    Swal.fire(data.motive, data.description, 'error');
  }

  cancelTripRequest(id) {
    Swal.fire({
      title: 'Cancelar Corrida',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Cancelar Solicitação',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return fetch(`//api.github.com/users/${login}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url,
        });
      }
    });
  }
  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/process/trip-request'], {
      queryParams: {
        cost_center: this.filter.costCenter ?? null,
        status: this.filter.status ?? null,
        paymentMethod: this.filter.paymentMethod ?? null,
        date_start: this.filter.dateStart ?? null,
        date_end: this.filter.dateEnd ?? null,
        driver: this.filter.driver ?? null,
        vehicle: this.filter.vehicle ?? null,
        covenant: this.filter.covenant ?? null,
        passenger: this.filter.passenger ?? null,
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getTripRequests();
  }
  getDecodedAccessToken(): any {
    try {
      return jwt_decode(localStorage.getItem('token').substring(7));
    } catch (Error) {
      return null;
    }
  }

  getPassengersName(passengers: Array<Passenger>) {
    const names = [];

    passengers.forEach(({ client, collaborator }) => {
      const _name = client ? client.name : collaborator.name;

      names.push(_name);

      // ? client.name.split(' ')
      // : collaborator.name.split(' ');
      // if (_name[0].length <= 4 && _name.length > 1) {
      //   names.push(`${_name[0]} ${_name[1]}`);
      // } else {
      //   names.push(_name[0]);
      // }
    });
    return names.join('<br>');
  }
}

class Filter {
  costCenter: number;
  paymentMethod: number;
  dateStart: Date;
  dateEnd: Date;
  driver: number;
  vehicle: number;
  status: string;
  active: boolean;
  covenant: string;
  passenger: string;
}
