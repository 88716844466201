import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationsTripRequest } from 'src/app/components/trip-request/validations/validations-trip-request';
import { Car } from 'src/app/domain/car';
import { City } from 'src/app/domain/city';
import { CostCenter } from 'src/app/domain/cost-center';
import { Driver } from 'src/app/domain/driver';
import { PaymentMethod } from 'src/app/domain/paymentMethod';
import { RequestValue } from 'src/app/domain/request-value';
import { State } from 'src/app/domain/state';
import { AddressService } from 'src/app/service/address/address.service';
import { CarService } from 'src/app/service/car/car.service';
import { ClientService } from 'src/app/service/client/client.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { PaymentMethodsService } from 'src/app/service/payment-methods/payment-methods.service';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-dashboard-update',
  templateUrl: './covenant-dashboard-update.component.html',
  styleUrls: ['./covenant-dashboard-update.component.css']
})
export class CovenantDashboardUpdateComponent implements OnInit {
  tripForm: FormGroup;

  cities: Array<Array<City>> = [];
  destinyCities: Array<City> = [];

  loading: boolean;
  states: Array<State> = [];
  paymentMethods: Array<PaymentMethod> = [];
  costCenters: Array<CostCenter> = [];
  cars: Array<Car> = [];
  drivers: Array<Driver> = [];
  requestValues: Array<RequestValue> = [];

  collaborators: {
    id: '',
    name: '',
    phone: '',
    whatsapp: '',
    main: '',
    originAddress: {
      zipcode: '',
      publicPlace: '',
      neighborhood: '',
      state: '',
      city: ''
    }

  }[];

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private serviceAddress: AddressService,
    private serviceTrip: TripRequestService,
    private serviceCostCenter: CostCenterService,
    private carService: CarService,
    private serviceRequestValue: RequestValueService,
    private paymentMethodService: PaymentMethodsService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getStates();
    this.getAllCostCenters();
    this.getAllCars();
  }

  resetDataForm() {
    this.tripForm.controls.covenantId.setValue('');
    this.tripForm.controls.clientId.setValue('');
    this.tripForm.controls.name.setValue('');
    this.tripForm.controls.whatsapp.setValue('');
    this.tripForm.controls.phone.setValue('');
    this.tripForm.controls.starttime.setValue('');
    this.tripForm.controls.endtime.setValue('');
    this.tripForm.controls.costcenter.setValue('');
    this.tripForm.controls.collaborator.setValue('');
    this.tripForm.controls.otherAddress.setValue('');
    this.tripForm.controls.car.setValue('');
    this.tripForm.controls.cost.setValue('');
    this.tripForm.controls.valueSolicitation.setValue('');
    this.tripForm.controls.observation.setValue('');
    this.tripForm.controls.passengers.setValue([]);
    this.tripForm.controls.paymentMethod.setValue('');
  }

  getAllCostCenters() {
    this.serviceCostCenter.getAllCostCenters().subscribe((data) => {
      this.costCenters = data;
    });
  }

  getAllCars() {
    this.carService.getCarsActives().subscribe((data) => {
      this.cars = data;
    });
  }

  getStates() {
    this.serviceAddress.getStates().subscribe(data => {
      this.states = data;
    }, error => {
      Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
    });
  }

  createForm() {
    this.tripForm = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [ValidationsTripRequest.validaEndDate]],
      starttime: ['', [Validators.required]],
      endtime: ['', [Validators.required]],
      collaborator: [''],
      otherAddress: [''],
      car: [''],
      driver: [''],
      valueSolicitation: ['', [ValidationsTripRequest.validaRequestValue]],
      costcenter: ['', [Validators.required]],
      observation: [''],
      passengers: this.fb.array([
      ]),
      paymentMethod: ['', [Validators.required]],
      destinyAddress: this.fb.group({
        zipcode: ['', [ValidationsTripRequest.validaClient]],
        publicPlace: ['', [ValidationsTripRequest.validaClient]],
        neighborhood: ['', [ValidationsTripRequest.validaClient]],
        state: ['', [ValidationsTripRequest.validaClient]],
        city: ['', [ValidationsTripRequest.validaClient]]
      }, Validators.required)
    });
  }

  async buscaCep(cep: string) {
    return await this.utilsService.getCep(cep);
  }

  async onBlurCepCollaborator(event, id) {
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, "");

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          const controls = this.tripForm.controls.passengers.get(id);
          this.loading = false;
        } else {
          this.loading = false;
          this.tripForm.controls.passengers.get(id).get('zipcode').setErrors({ invalid: "CEP inválido!" });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.passengers.get(id).get('zipcode').setErrors({ invalid: "CEP inválido!" });
    }
  }

  get passengers() {
    return this.tripForm.get('passengers') as FormArray;
  }

  onAddCollaborator() {
    const collaborators = this.collaborators.filter(elem => elem['id'] == this.tripForm.controls.collaborator.value);
    const collaboratorsAdd = this.passengers.value.filter(elem => elem['id'] == this.tripForm.controls.collaborator.value);
    if (collaboratorsAdd.length == 0) {
      this.passengers.push(this.newCollaborator(collaborators[0]));
      this.tripForm.controls.collaborator.setValue('');
    } else {
      Swal.fire('Atenção', 'Colaborador já foi adicionado à Solicitação', 'warning');
    }
  }

  removeCollaborator(i: number) {
    this.passengers.removeAt(i);
  }

  newCollaborator(collaborator): FormGroup {
    this.serviceAddress.getCitiesByStateId(collaborator.address.city.state.id).subscribe(data => {
      this.cities.push(data);
    })
    this.serviceAddress.getCitiesByStateId(collaborator.address.city.state.id).subscribe((data => this.cities.push(data)));
    return this.fb.group({
      id: [collaborator.id],
      name: collaborator.name,
      phone: collaborator.phone,
      whatsapp: collaborator.whatsapp,
      costCenter: collaborator.costCenter,
      main: false,
      address: this.fb.group({
        zipcode: [collaborator.address.zipCode, [Validators.maxLength(9)]],
        publicPlace: [collaborator.address.publicPlace, []],
        neighborhood: [collaborator.address.neighborhood, []],
        state: [collaborator.address.city.state.id, []],
        city: [collaborator.address.city.id, []]
      }),
    });

  }

  onSelectedStateCollaborator(event, id) {
    console.log(event);
    console.log(id);

    this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(data => {
      this.cities[id] = data;
    })
  }

  onSelectedStateDestiny(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(data => {
        this.destinyCities = data;
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
      });
    }
  }

  getDriversByCar() {
    if (this.tripForm.controls.car.value.length) {
      this.loading = true;
      this.carService.getDriversByCar(this.tripForm.controls.car.value).subscribe(data => {
        this.drivers = data;
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível carregar os motoristas deste veículo! Selecione o Veículo novamente.', 'error');
      });
    }
  }

  async onBlurCepDestiny(event) {
    // event.target.value
    this.loading = true;

    const cep = event.target.value.replace(/[^0-9,.]+/g, "");

    if (cep.length === 8) {
      (await this.buscaCep(cep)).subscribe((viacep) => {
        if (!viacep.erro) {
          this.tripForm.controls.destinyAddress.get('neighborhood').setValue(viacep.logradouro);
          this.tripForm.controls.destinyAddress.get('publicPlace').setValue(viacep.bairro);
          const state = this.states.find(state => state.initials === viacep.uf.toUpperCase());

          this.tripForm.controls.destinyAddress.get('state').setValue(state.id);

          this.serviceAddress.getCitiesByStateId(state.id).subscribe(data => {
            this.destinyCities = data;
            const city = this.destinyCities.find(elem => elem.name.normalize("NFD") === viacep.localidade.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ''));
            if (city) {
              this.tripForm.controls.destinyAddress.get('city').setValue(city.id);
            }
            this.loading = false;
          }, error => {
            this.loading = false;
            Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
          });
        } else {
          this.loading = false;
          this.tripForm.controls.destinyAddress.get('zipcode').setErrors({ invalid: "CEP inválido!" });
        }
      });
    } else {
      this.loading = false;
      this.tripForm.controls.destinyAddress.get('zipcode').setErrors({ invalid: "CEP inválido!" });
    }


  }

}
