import { Component, OnInit } from '@angular/core';
import { CreditCard } from 'src/app/domain/credit-card';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreditCardService } from 'src/app/service/credit-card/credit-card.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Flag } from 'src/app/enumerations/flag';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})
export class CreditCardComponent implements OnInit {

  creditCards: Array<CreditCard> = [];
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  creditCard: CreditCard = new CreditCard();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;


  constructor(
    private service: CreditCardService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {

    this.route.queryParams.subscribe(params => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.creditCard.number = params.number ?? "";
      this.creditCard.flag = params.flag ?? "";
      this.creditCard.active = params.active ?? null;

    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getCreditCard();

  }

  getCreditCard() {
    this.loading = true;
    this.service.getCreditCards(this.creditCard, this.pagination).subscribe(data => {
      this.creditCards = data['results'].map((credit, index, array) => {
        credit.flag = Flag[credit.flag];
        return credit;
      });

      console.log(this.creditCards);

      this.setPagination(data);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });
    this.searchForm = this.fb.group({
      number: [this.creditCard.number],
      flag: [this.creditCard.flag],
      active: [this.creditCard.active]
    });
  }

  onSearch() {
    this.creditCard.number = this.searchForm.controls.number.value;
    this.creditCard.flag = this.searchForm.controls.flag.value;

    switch (this.searchForm.controls.active.value) {
      case "true":
        this.creditCard.active = true;
        break;
      case "false":
        this.creditCard.active = false;
        break;
      default:
        this.creditCard.active = null;
        break;
    }
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onActive(creditCard: CreditCard) {

    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Cartão de Crédito? ${creditCard.number}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(creditCard.id).subscribe(data => {
          // Swal.fire('Ativado', 'Cartão de Crédito ativado com sucesso!', 'success');
          Swal.fire({
            title: 'Ativado',
            text: `Cartão de Crédito ativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar Cartão de Crédito!', 'error');
          });
      }

    });
  }
  onDeActive(creditCard: CreditCard) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Cartão de Crédito? ${creditCard.number}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(creditCard.id).subscribe(data => {

          Swal.fire({
            title: 'Desativado',
            text: `Cartão de Crédito desativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao desativar Cartão de Crédito!', 'error');
          });
      }

    });
  }
  onDelete(creditCard: CreditCard) {
    Swal.fire({
      title: 'Excluir Cartão de Crédito?',
      text: `final: ${creditCard.number}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero excluir!',
      cancelButtonText: 'Não'
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.service.delete(creditCard.id).subscribe(data => {
          // Swal.fire('Excluído', 'Cartão de Crédito excluído com sucesso!', 'success');
          Swal.fire({
            title: 'Excluído',
            text: `Cartão de Crédito excluído com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          })


        },
          error => {
            Swal.fire('Ops', 'Erro ao excluir Cartão de Crédito!', 'error');
          });
      }

    });

  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();

  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/parameters/credit-card'], {
      queryParams: {
        number: this.creditCard.number ?? "",
        flag: this.creditCard.flag ?? "",
        active: this.creditCard.active ?? "",
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10
      }
    });
    this.getCreditCard();
  }


}
