<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title">Editar Alerta</div>
                        </div>
                    </div>
                    <div [formGroup]="alertForm">
                        <!--div com o form -->
                        <div *ngIf="messageError.length" class="container">
                            <div class="alert alert-danger" role="alert">
                                {{messageError}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control" id="name" formControlName="name"
                                        placeholder="Nome do Alerta">
                                    <span
                                        *ngIf="alertForm.controls.name.errors && alertForm.controls.name.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="alertForm.controls.name.errors && alertForm.controls.name.errors.maxlength"
                                        class="text-danger position-right">*Nome da
                                        Peça deve ter
                                        no máximo 100 caracteres</span>
                                    <span
                                        *ngIf="alertForm.controls.name.errors && alertForm.controls.name.errors.invalid"
                                        class="text-danger position-right">*
                                        {{alertForm.controls.name.errors.invalid}} </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="variable">Variável</label>
                                    <input type="number" class="form-control" id="variable" formControlName="variable"
                                        placeholder="Variável">
                                    <span *ngIf="alertForm.controls.variable.errors">
                                        <span *ngIf="alertForm.controls.variable.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="!alertForm.controls.variable.errors.required && alertForm.controls.variable.errors.invalid"
                                            class="text-danger position-right">*
                                            {{alertForm.controls.variable.errors.invalid}} </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">Tipo de Alerta
                                    </label>

                                    <select class="form-control  select2" formControlName="alertType">
                                        <option value="">
                                            Selecione o Tipo de Alerta </option>
                                        <option value="{{ alertType.id }}" *ngFor="let alertType of alertTypes">
                                            {{ alertType.name }} </option>

                                    </select>
                                    <span
                                        *ngIf="alertForm.controls.alertType.errors && alertForm.controls.alertType.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="alertForm.controls.alertType.errors && alertForm.controls.alertType.errors.invalid"
                                        class="text-danger position-right">*
                                        {{alertForm.controls.alertType.errors.invalid}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="active">Status</label>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="true" value="true" name="active"
                                            formControlName="active" class="custom-control-input">
                                        <label class="custom-control-label" for="true">Ativo</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="false" value="false" name="active"
                                            formControlName="active" class="custom-control-input">
                                        <label class="custom-control-label" for="false">Inativo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-3 text-right">
                                <button (click)="onSubmit()" [disabled]="!alertForm.valid"
                                    class="btn btn-success">Salvar</button>
                            </div>
                            <div class="col-md-3">
                                <a routerLink="/parameters/alert" class="btn btn-danger">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
