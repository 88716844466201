<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Acerto com Motorista</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <header style="width: 100%">
                <div [formGroup]="form" class="row mt-2">
                  <div class="col-sm-4">
                    <p class="ml-3">Período de Geração</p>
                    <div class="border row p-2 ml-1">
                      <div class="col-sm-6">
                        <p>Data Inicial</p>
                        <input
                          type="date"
                          class="form-control"
                          formControlName="initialDate"
                        />
                        <span
                          *ngIf="
                            form.controls.initialDate.errors &&
                            form.controls.initialDate.errors.invalid
                          "
                          class="has-error position-right"
                          >* {{ form.controls.initialDate.errors.invalid }}
                        </span>
                      </div>
                      <div class="col-sm-6">
                        <p>Data Final</p>
                        <input
                          type="date"
                          class="form-control"
                          formControlName="finalDate"
                        />
                        <span
                          *ngIf="
                            form.controls.finalDate.errors &&
                            form.controls.finalDate.errors.invalid
                          "
                          class="has-error position-right"
                          >* {{ form.controls.finalDate.errors.invalid }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 align-self-end">
                    <label style="font-size: 14px" class="mt-2"
                      >Motorista</label
                    >
                    <ng-select
                      class="custom-select"
                      style="font-size: 1rem; font-weight: normal"
                      notFoundText="Nenhum item encontrado"
                      placeholder="Motorista"
                      formControlName="driver"
                      [items]="drivers"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                    <span
                      *ngIf="
                        form.controls.driver.errors &&
                        form.controls.driver.errors.required
                      "
                      class="has-error position-right"
                      >* Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-sm-3 align-self-end">
                    <label style="font-size: 14px" class="mt-2">
                      Desconto da Taxa do Convênio para Cartão de Crédito
                    </label>
                    <label
                      for="issueInvoiceToCreditCard_yes"
                      style="font-size: 14px"
                      class="mt-2"
                    >
                      <input
                        type="radio"
                        class="checkbox"
                        id="issueInvoiceToCreditCard_yes"
                        [value]="true"
                        formControlName="issueInvoiceToCreditCard"
                        (change)="calculate()"
                      />
                      Sim</label
                    >
                    <label
                      for="issueInvoiceToCreditCard_no"
                      style="font-size: 14px"
                      class="mt-2"
                    >
                      <input
                        type="radio"
                        class="checkbox"
                        id="issueInvoiceToCreditCard_no"
                        [value]="false"
                        formControlName="issueInvoiceToCreditCard"
                        (change)="calculate()"
                      />
                      Não</label
                    >
                    <span
                      *ngIf="
                        form.controls.issueInvoiceToCreditCard.errors &&
                        form.controls.issueInvoiceToCreditCard.errors.required
                      "
                      class="has-error"
                      >* Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-sm-1 align-self-end">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="fetchLaunches()"
                    >
                      Gerar
                    </button>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Cod</th>
                      <th>Data / Hora</th>
                      <th>Lançamento</th>
                      <th>Emitir Nota Fiscal</th>
                      <th>Tipo</th>
                      <th>Método de pagamento</th>
                      <th>Valor</th>
                      <th>
                        Seleção:
                        <input
                          type="checkbox"
                          [checked]="allSelected"
                          (click)="allSelect($event.target.checked)"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let launch of launchs">
                      <td>
                        {{ launch.id }}
                      </td>
                      <td>
                        {{ launch.date | date : "dd/MM/yyyy" }}
                        {{ launch.startHour }}
                      </td>
                      <td>
                        {{ launch.runRequest.costCenter?.type }}
                      </td>
                      <td>
                        {{
                          launch.runRequest.covenant?.invoice
                            ? "Emitir Nota Fiscal"
                            : ""
                        }}
                      </td>
                      <td>
                        {{ launch.runRequest.requestValue.type }}
                      </td>
                      <td>
                        {{ launch.runRequest.paymentMethod?.name }}
                      </td>
                      <td>
                        {{ launch.runEndValue | currency : "BRL" }}
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          [checked]="isSelected(launch.id)"
                          (click)="selectLaunch(launch.id)"
                        />
                      </td>
                    </tr>
                    <tr *ngIf="!launchs || !launchs.length">
                      <td colspan="7">
                        <h4 class="text-danger">
                          Nenhum lançamento encontrado
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card">
                <h3 class="ml-4"><b>Total de Créditos</b></h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered custom-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Descriçao</th>
                          <th>Emitir Nota Fiscal</th>
                          <th>Valor</th>
                          <th>Desconto Cartão</th>
                          <th>Desconto Convênio</th>
                          <th>Valor Final</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of credits">
                          <td>{{ item.description }}</td>
                          <td>
                            {{ item.issueInvoice ? "Emitir Nota Fiscal" : "" }}
                          </td>
                          <td>
                            {{ item.value | currency : "BRL" }}
                          </td>
                          <td>
                            {{ item.cardDiscount }}
                          </td>
                          <td>
                            {{ item.covenantDiscount }}
                          </td>
                          <td>
                            {{ item.finalValue | currency : "BRL" }}
                          </td>
                        </tr>
                        <tr *ngIf="credits?.length">
                          <td colspan="2"><b>TOTAL DE CRÉDITOS:</b></td>
                          <td>{{ totalValueCredit | currency : "BRL" }}</td>
                          <td></td>
                          <td></td>
                          <td>{{ finalValueCredit | currency : "BRL" }}</td>
                        </tr>
                        <tr *ngIf="!credits?.length">
                          <td colspan="6">
                            <h4 class="text-danger">
                              Nenhum lançamento encontrado
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- TOTAL ABASTECIMENTOS -->
              <div class="card" *ngIf="fuelSupplies.length">
                <h3 class="ml-4"><b>Abastecimentos</b></h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered custom-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Hora</th>
                          <th>Veículo</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of fuelSupplies">
                          <td>
                            {{ item.date | date : "dd/MM/yyyy" }}
                          </td>
                          <td>
                            {{ item.hour }}
                          </td>
                          <td>
                            {{ item.vehicle.model.name }} -
                            {{ item.vehicle.carPlate }}
                          </td>
                          <td>
                            {{ item.value | currency : "BRL" }}
                          </td>
                        </tr>
                        <tr *ngIf="fuelSupplies?.length">
                          <td colspan="3"><b>TOTAL ABASTECIMENTOS</b></td>
                          <td>
                            {{ finalValueFuelSupplies | currency : "BRL" }}
                          </td>
                        </tr>
                        <tr *ngIf="!fuelSupplies?.length">
                          <td colspan="4">
                            <h4 class="text-danger">
                              Nenhum lançamento encontrado
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- TOTAL PEDÁGIOS -->
              <div class="card" *ngIf="tolls.length">
                <h3 class="ml-4"><b>Pedágios</b></h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered custom-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Veículo</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of tolls">
                          <td>
                            {{ item.date | date : "dd/MM/yyyy" }}
                          </td>
                          <td>
                            {{ item.vehicle.model.name }} -
                            {{ item.vehicle.carPlate }}
                          </td>
                          <td>
                            {{
                              item.runRequest.requestValue.tollAmount
                                | currency : "BRL"
                            }}
                          </td>
                        </tr>
                        <tr *ngIf="tolls?.length">
                          <td colspan="2"><b>TOTAL PEDÁGIOS</b></td>
                          <td>{{ finalValueToll | currency : "BRL" }}</td>
                        </tr>
                        <tr *ngIf="!tolls?.length">
                          <td colspan="3">
                            <h4 class="text-danger">
                              Nenhum lançamento encontrado
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- TOTAL DEBITO -->
              <!--  <div class="card">
                <h3 class="ml-4"><b>Manutenções</b></h3>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered custom-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th width="100">Cod</th>
                          <th>Data</th>
                          <th>Hora</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of maintenances">
                          <td>{{ item.id }}</td>
                          <td>
                            {{ item.date | date: "dd/MM/yyyy" }}
                          </td>
                          <td>
                            {{ item.hour }}
                          </td>
                          <td>
                            {{ item.value | currency: "BRL" }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"><b>TOTAL MANUTENÇÕES</b></td>
                          <td>{{ finalValueDebit | currency: "BRL" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> -->

              <!-- TOTAL GERAL -->
              <div class="card">
                <h3 class="ml-4"><b>Total Geral</b></h3>
                <div class="card-body row">
                  <div class="col-sm-12 border">
                    <div class="table-responsive mt-2">
                      <table
                        class="table table-bordered custom-table table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Descrição</th>
                            <th>+</th>
                            <th>-</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of resume">
                            <td>{{ item.description }}</td>
                            <td>
                              <span *ngIf="item.type === 'credit'">
                                {{ item.value | currency : "BRL" }}
                              </span>
                            </td>
                            <td>
                              <span *ngIf="item.type === 'debit'">
                                {{ item.value | currency : "BRL" }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td><b>TOTAL GERAL</b></td>
                            <td>{{ finalValueCredit | currency : "BRL" }}</td>
                            <td>{{ finalValueDebit | currency : "BRL" }}</td>
                          </tr>
                          <tr>
                            <td colspan="2"><b>TOTAL</b></td>
                            <td>
                              {{
                                finalValueCredit - finalValueDebit
                                  | currency : "BRL"
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h4><b>Valores Avulsos</b></h4>
                    <div class="row border m-1">
                      <div class="col-sm-12">
                        <div [formGroup]="newValueForm" class="row mb-3 mt-2">
                          <div class="col-sm-4">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Descrição"
                              formControlName="description"
                            />
                            <span
                              *ngIf="
                                newValueForm.controls.description.errors &&
                                newValueForm.controls.description.errors
                                  .required
                              "
                              class="has-error position-right"
                              >* Campo Obrigatório
                            </span>
                          </div>
                          <div class="col-sm-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Valor"
                              formControlName="value"
                              currencyMask
                              [options]="{
                                prefix: 'R$ ',
                                thousands: '.',
                                decimal: ','
                              }"
                            />
                            <span
                              *ngIf="
                                newValueForm.controls.value.errors &&
                                newValueForm.controls.value.errors.required
                              "
                              class="has-error position-right"
                              >* Campo Obrigatório
                            </span>
                          </div>
                          <div class="col-sm-2">
                            <select class="form-control" formControlName="type">
                              <option value="">Tipo</option>
                              <option value="credit">Crédito</option>
                              <option value="debit">Débito</option>
                            </select>
                            <span
                              *ngIf="
                                newValueForm.controls.type.errors &&
                                newValueForm.controls.type.errors.required
                              "
                              class="has-error position-right"
                              >* Campo Obrigatório
                            </span>
                          </div>
                          <div class="col-sm-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              (click)="addNewValues()"
                            >
                              Adicionar Novos Valores
                            </button>
                          </div>
                        </div>

                        <div class="table-responsive mt-2">
                          <table
                            class="table table-bordered custom-table table-hover"
                          >
                            <thead>
                              <tr>
                                <th>Descrição</th>
                                <th>+</th>
                                <th>-</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let newValue of newValues">
                                <td>
                                  {{ newValue.description }}
                                </td>
                                <td>
                                  <span *ngIf="newValue.type === 'credit'">
                                    {{ newValue.value | currency : "BRL" }}
                                  </span>
                                </td>
                                <td>
                                  <span *ngIf="newValue.type === 'debit'">
                                    {{ newValue.value | currency : "BRL" }}
                                  </span>
                                </td>
                              </tr>
                              <tr *ngIf="!newValues?.length">
                                <td colspan="3">
                                  <h4 class="text-danger">
                                    Nenhum lançamento encontrado
                                  </h4>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row m-1 mt-3">
                      <div class="col-6">Comissão do Motorista:</div>
                      <div
                        class="col-3"
                        *ngIf="commission['type'] === 'percentage'"
                      >
                        {{ commission["value"] }} %
                      </div>
                      <div
                        class="col-3"
                        *ngIf="commission['type'] === 'monetary'"
                      >
                        {{ commission["value"] | currency : "BRL" }}
                      </div>
                      <div class="col-3">
                        {{ commissionValue | currency : "BRL" }}
                      </div>
                    </div>

                    <!-- TOTAL MULTAS -->
                    <div class="row m-1 mt-3" *ngIf="trafficTickets.length">
                      <h4><b>Multas</b></h4>
                      <div class="table-responsive">
                        <table
                          class="table table-bordered custom-table table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Veículo</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of trafficTickets">
                              <td>
                                {{ item.date | date : "dd/MM/yyyy" }}
                              </td>
                              <td>
                                {{ item.vehicle.model.name }} -
                                {{ item.vehicle.carPlate }}
                              </td>
                              <td>
                                {{ item.value | currency : "BRL" }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2"><b>TOTAL MULTAS</b></td>
                              <td>
                                {{ finalValueTrafficTicket | currency : "BRL" }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row m-1 mt-3">
                      <h4><b>Reembolsos</b></h4>
                      <div class="table-responsive">
                        <table
                          class="table table-bordered custom-table table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Descrição</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of refunds">
                              <td>
                                {{ item.description }}
                              </td>
                              <td>
                                {{ item.value | currency : "BRL" }}
                              </td>
                            </tr>
                            <tr *ngIf="!refunds?.length">
                              <td colspan="2">
                                <h4 class="text-danger">
                                  Nenhum lançamento encontrado
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row m-1 mt-3">
                      <div class="col-6">Valor líquido:</div>
                      <div class="col-3"></div>
                      <div class="col-3">
                        {{ paymentValue | currency : "BRL" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- INFORMACOES DE PAGAMENTO -->
              <div class="card">
                <h3 class="ml-4"><b>Informações de Pagamento</b></h3>
                <div class="card-body">
                  <div [formGroup]="paymentInformation" class="row">
                    <div class="col-sm-3">
                      <label>Forma de Pagamento</label>
                      <select
                        class="form-control"
                        formControlName="paymentType"
                      >
                        <option value="">Forma de Pagamento</option>
                        <option value="money">Dinheiro</option>
                        <option value="bank_check">Cheque</option>
                        <option value="bank_deposit">Depósito</option>
                      </select>
                      <span
                        *ngIf="
                          paymentInformation.controls.paymentType.errors &&
                          paymentInformation.controls.paymentType.errors
                            .required
                        "
                        class="has-error position-right"
                        >* Campo Obrigatório
                      </span>
                    </div>

                    <div class="col-sm-3">
                      <label>Banco</label>
                      <ng-select
                        class="custom-select custom-select"
                        style="font-size: 14px"
                        formControlName="bank"
                        [items]="banks"
                        bindLabel="name"
                        bindValue="id"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                          let-search="searchTerm"
                        >
                          <span>{{ item.name }}</span>
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="col-sm-3">
                      <label>Número do Cheque</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Número do Cheque"
                        formControlName="checkNumber"
                      />
                    </div>

                    <div class="col">
                      <label>&nbsp;</label>
                      <input type="checkbox" formControlName="paid" /> Pago
                    </div>
                  </div>
                </div>
                <div class="row col-12 d-flex justify-content-center my-3">
                  <div>
                    <button
                      (click)="onSubmit()"
                      [disabled]=""
                      class="btn btn-success"
                    >
                      Salvar
                    </button>
                  </div>
                  <div class="ml-4">
                    <a routerLink="/financial/pay-driver" class="btn btn-danger"
                      >Cancelar</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
