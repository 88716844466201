import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Car } from 'src/app/domain/car';
import { Driver } from 'src/app/domain/driver';
import { CostCenter } from 'src/app/domain/cost-center';
import { Pagination } from 'src/app/domain/pagination';
import { PaymentMethod } from 'src/app/domain/paymentMethod';
import { EnumStatusRun } from 'src/app/enumerations/status-run';
import { CarService } from 'src/app/service/car/car.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import { PaymentMethodsService } from 'src/app/service/payment-methods/payment-methods.service';
import { RunService } from 'src/app/service/run/run.service';
import Swal from 'sweetalert2';
import { ValidationsTripRequest } from '../../trip-request/validations/validations-trip-request';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-runs-by-request',
  templateUrl: './runs-by-request.component.html',
  styleUrls: ['./runs-by-request.component.css'],
})
export class RunsByRequestComponent implements OnInit {
  runs = [];
  id: number;
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  runEditForm: FormGroup;
  paymentMethods: Array<PaymentMethod> = [];
  costCenters: Array<CostCenter> = [];
  cars: Array<Car> = [];
  carId: number;
  drivers: Array<Driver> = [];
  driverId: number;

  first: boolean = false;
  last: boolean = false;

  filter: Filter;

  constructor(
    public readonly swalTargets: SwalPortalTargets,
    private service: RunService,
    private servicePaymentMethod: PaymentMethodsService,
    private serviceCostCenter: CostCenterService,
    private serviceCar: CarService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;

      this.filter = new Filter();
      // this.filter.active = params.active ?? null;
      // this.filter.costCenter = params.cost_center ?? null;
      this.filter.status = params.status || null;
      // this.filter.paymentMethod = params.payment_method ?? null;
      this.filter.dateStart = params.date_start || null;
      this.filter.dateEnd = params.date_end || null;
      this.filter.driver = params.driver || null;
      this.filter.vehicle = params.vehicle || null;
    });
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getPaymentMethods();
    this.getCostCenters();
    this.getCars();

    this.createForm();
    this.getRuns();
  }
  onSearch() {
    this.filter.costCenter = this.searchForm.controls.costCenter.value;
    this.filter.status = this.searchForm.controls.status.value;
    this.filter.paymentMethod = this.searchForm.controls.paymentMethod.value;
    this.filter.vehicle = this.searchForm.controls.vehicle.value;
    this.filter.dateStart = this.searchForm.controls.dateStart.value;
    this.filter.dateEnd = this.searchForm.controls.dateEnd.value;

    this.setNavigate();
  }
  onSelectedResults(e) {
    this.pagination.per_page = e.target.value;
    this.setNavigate();
  }

  getPaymentMethods() {
    this.servicePaymentMethod
      .getPaymentMethods()
      .subscribe((data) => (this.paymentMethods = data));
  }
  getCostCenters() {
    this.serviceCostCenter
      .getAllCostCenters()
      .subscribe((data) => (this.costCenters = data));
  }

  getCars() {
    this.serviceCar.getCarsActives().subscribe((data) => {
      this.cars = data;
      for (const car of this.cars)
        car.nameCarPlate = `${car.model.name} ${car.carPlate}`;
    });
  }

  getDriversByCar() {
    if (this.runEditForm.controls.carId.value) {
      this.loading = true;
      this.serviceCar
        .getDriversByCar(this.runEditForm.controls.carId.value)
        .subscribe(
          (data) => {
            this.drivers = data;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            Swal.fire(
              'Erro',
              'Não foi possível carregar os motoristas deste veículo! Selecione o Veículo novamente.',
              'error'
            );
          }
        );
    }
  }

  createForm() {
    this.searchForm = this.fb.group({
      per_page: [this.pagination.per_page],
      costCenter: [this.filter.costCenter],
      status: [this.filter.status],
      paymentMethod: [this.filter.paymentMethod],
      dateStart: [this.filter.dateStart],
      dateEnd: [this.filter.dateEnd],
      driver: [this.filter.driver],
      vehicle: [this.filter.vehicle],
    });

    this.runEditForm = this.fb.group({
      id: [''],
      startHour: ['', Validators.required],
      endHour: ['', [ValidationsTripRequest.validaEndDate]],
      carId: ['', [Validators.required]],
      driverId: ['', [Validators.required]],
    });
  }

  getAtualDate() {
    return moment().format('yyyy-MM-DD');
  }

  linkVehicle(id: number) {
    Swal.fire('Vincular Motorista', `Numero do Veículo - ${id}`);
  }

  getRuns() {
    this.loading = true;

    this.service
      .getRunsByRequest(this.id, this.filter, this.pagination)
      .subscribe(
        (data) => {
          this.runs = data['results'].map((Run, index, array) => {
            Run.status = EnumStatusRun[Run.status];
            return Run;
          });
          this.setPagination(data);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
        }
      );
  }
  seeReason(data) {
    Swal.fire(data.motive, data.description, 'error');
  }
  cancelRun(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Cancelar Corrida?',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.service.cancel(id).subscribe(
          (data) => {
            Swal.fire('Cancelado!', '', 'success');
            this.setNavigate();
          },
          (error) => {
            Swal.fire('Erro ao Cancelar', '', 'error');
          }
        );
      }
    });
  }

  setRunEditForm(id, carId, driverId, start, end) {
    this.runEditForm.controls.id.setValue(id);
    this.runEditForm.controls.startHour.setValue(start);
    this.runEditForm.controls.endHour.setValue(end);
    this.runEditForm.controls.carId.setValue(carId);

    this.getDriversByCar();

    this.runEditForm.controls.driverId.setValue(driverId);
  }

  async updateRun() {
    const formValues = this.runEditForm.value;
    const vehicle = this.cars.filter((car) => car.id == formValues.carId)[0];
    const driver = this.drivers.filter(
      (driver) => driver.id == formValues.driverId
    )[0];
    const data = {
      vehicle,
      driver,
      ...formValues,
    };

    this.service.update(formValues.id, data).subscribe(
      (data) => {
        Swal.fire('Editado!', 'Hora editada com sucesso', 'success');
        this.setNavigate();
      },
      (error) => {
        Swal.fire('Erro ao Editar Hora', '', 'error');
      }
    );
  }
  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate([`process/runs/${this.id}/request`], {
      queryParams: {
        cost_center: this.filter.costCenter ?? null,
        status: this.filter.status ?? null,
        paymentMethod: this.filter.paymentMethod ?? null,
        date_start: this.filter.dateStart ?? null,
        date_end: this.filter.dateEnd ?? null,
        driver: this.filter.driver ?? null,
        vehicle: this.filter.vehicle ?? null,
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getRuns();
  }
}

class Filter {
  costCenter: number;
  paymentMethod: number;
  dateStart: Date;
  dateEnd: Date;
  driver: number;
  vehicle: number;
  status: string;
  active: boolean;
}
