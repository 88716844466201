<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar Perfil de Acesso</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  {{ accessProfile.name }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b>Descrição</b>
                </div>
                <div class="col-md-12">
                  <p>{{ accessProfile.description }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <b>Status</b>
                </div>
                <div class="col-md-10">
                  <span
                    *ngIf="accessProfile.active"
                    class="label label-sucess label-mini"
                    >Ativo</span
                  >
                  <span
                    *ngIf="!accessProfile.active"
                    class="label label-danger label-mini"
                    >Inativo</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b>Permissões</b>
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-striped custom-table table-hover">
                      <tbody>
                        <tr
                          *ngFor="let permission of accessProfile.permissions"
                        >
                          <td>{{ permission.name }}</td>
                        </tr>
                        <span
                          *ngIf="!accessProfile.permissions.length"
                          class="label label-danger label-mini"
                          >Nenhuma Permissão associada a este Perfil.</span
                        >
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-4">
                  <a
                    routerLink="/security/access-profile/edit/{{ accessProfile.id }}"
                    class="btn btn-warning"
                    >Editar</a
                  >
                </div>
                <div class="col-md-4">
                  <a routerLink="/security/access-profile" class="btn btn-info">Voltar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
