<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Parâmetro Financeiro</div>
            </div>
          </div>
          <div [formGroup]="financialParameterForm">
            <!--div com o form -->
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="type">Tipo</label>
                  <select formControlName="type" class="form-control">
                    <option value="">Selecione um tipo</option>
                    <option value="monetary">Monetário</option>
                    <option value="percentage">Porcentagem</option>
                  </select>
                  <span
                    *ngIf="
                      financialParameterForm.controls.type.errors &&
                      financialParameterForm.controls.type.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      financialParameterForm.controls.type.errors &&
                      financialParameterForm.controls.type.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ financialParameterForm.controls.type.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="parameter">Parâmetro</label>
                  <select formControlName="parameter" class="form-control">
                    <option value="">Selecione um Parâmetro</option>
                    <option value="taxiDriverCommission">
                      Comissão Acerto Taxista
                    </option>
                    <option value="taxiDriverDiscountCard">
                      Desconto Acerto Taxista Cartão
                    </option>
                    <option value="taxiDriverDiscountCovenant">
                      Desconto Acerto Taxista Convênio
                    </option>
                  </select>
                  <span
                    *ngIf="
                      financialParameterForm.controls.parameter.errors &&
                      financialParameterForm.controls.parameter.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      financialParameterForm.controls.parameter.errors &&
                      financialParameterForm.controls.parameter.errors.invalid
                    "
                    class="text-danger position-right"
                    >*
                    {{
                      financialParameterForm.controls.parameter.errors.invalid
                    }}
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="value">Valor</label>
                  <input
                    type="number"
                    class="form-control"
                    id="value"
                    formControlName="value"
                    placeholder="Valor"
                  />
                  <span *ngIf="financialParameterForm.controls.value.errors">
                    <span
                      *ngIf="
                        financialParameterForm.controls.value.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !financialParameterForm.controls.value.errors
                          .required &&
                        financialParameterForm.controls.value.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ financialParameterForm.controls.value.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="active">Status</label>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      id="true"
                      value="true"
                      name="active"
                      formControlName="active"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="true">Ativo</label>
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      id="false"
                      value="false"
                      name="active"
                      formControlName="active"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="false"
                      >Inativo</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3 text-right">
                <button
                  (click)="onSubmit()"
                  [disabled]="!financialParameterForm.valid"
                  class="btn btn-success"
                >
                  Salvar
                </button>
              </div>
              <div class="col-md-3">
                <a routerLink="/parameters/financial-parameter" class="btn btn-danger"
                  >Cancelar</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
