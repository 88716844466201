<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Conta a Receber - Parcelas</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" [formGroup]="generateBillForm">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <header style="width: 100%">
                <div class="row">
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Convênio/Cliente</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      generateBillForm.get('covenantClientName').value
                    }}</label>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Centro de Custo</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      generateBillForm.get('costCenterName').value
                    }}</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Data de Vencimento</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      generateBillForm.get('dueDate').value | date: "dd/MM/yyyy"
                    }}</label>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Valor</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      generateBillForm.get('value').value
                        | currency: "BRL"
                    }}</label>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered mt-5"
                      formArrayName="parcels"
                    >
                      <thead>
                        <tr>
                          <th scope="col">Nº</th>
                          <th scope="col">Valor</th>
                          <th scope="col">Data Vencimento</th>
                          <th scope="col">Pago</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let parcel of parcels.controls; let i = index" [formGroupName]="i">
                          <td>{{ parcel.controls.id.value }}</td>
                          <td>
                            {{ parcel.controls.value.value | currency: "BRL" }}
                          </td>
                          <td>
                            {{ parcel.controls.dueDate.value | date: "dd/MM/yyyy" }}
                          </td>
                          <td>
                            <input type="checkbox" formControlName="paid" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mt-3 ml-3 mb-3">
                  <div class="col-md-3 text-right">
                    <button
                      (click)="onSubmit()"
                      class="btn btn-success"
                    >
                      Salvar
                    </button>
                  </div>
                  <div class="col-md-3">
                    <a routerLink="/financial/generate-bills-to-receive" class="btn btn-danger">Cancelar</a>
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
