<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row mt-4 mb-2">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Multas</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Marca</b>
                </div>
                <div class="col-md-12">
                  {{ car.model?.brand.name }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Modelo</b>
                </div>
                <div class="col-md-12">
                  {{ car.model?.name }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <b>Placa</b>
                </div>
                <div class="col-md-12">
                  {{ car.carPlate }}
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="carFineForm">
            <div class="row mt-4">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="data">Data</label>
                  <input
                    type="Date"
                    class="form-control"
                    placeholder="Data"
                    formControlName="data"
                  />
                  <span
                    *ngIf="
                      carFineForm.controls.data.errors &&
                      carFineForm.controls.data.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carFineForm.controls.data.errors &&
                      carFineForm.controls.data.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carFineForm.controls.data.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="tipo">Tipo</label>
                  <input
                    type="text"
                    class="form-control"
                    id="tipo"
                    formControlName="tipo"
                    placeholder="Tipo"
                  />
                  <span
                    *ngIf="
                      carFineForm.controls.tipo.errors &&
                      carFineForm.controls.tipo.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carFineForm.controls.tipo.errors &&
                      carFineForm.controls.tipo.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carFineForm.controls.tipo.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="motorista">Motorista</label>
                  <select
                    formControlName="motorista"
                    class="custom-select custom-select-md"
                  >
                    <option value="">Selecione o Motorista</option>
                    <option
                      value="{{ driver.id }}"
                      *ngFor="let driver of drivers"
                    >
                      {{ driver.name }}
                    </option>
                  </select>
                  <span
                    *ngIf="
                      carFineForm.controls.motorista.errors &&
                      carFineForm.controls.motorista.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carFineForm.controls.motorista.errors &&
                      carFineForm.controls.motorista.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carFineForm.controls.motorista.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="valor">Valor</label>
                  <input
                    type="text"
                    class="form-control money"
                    id="valor"
                    formControlName="valor"
                    currencyMask
                    [options]="{
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ',',
                      align: 'left',
                      allowNegative: false
                    }"
                    placeholder="Valor"
                  />
                  <span
                    *ngIf="
                      carFineForm.controls.valor.errors &&
                      carFineForm.controls.valor.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carFineForm.controls.valor.errors &&
                      !carFineForm.controls.valor.errors.required &&
                      carFineForm.controls.valor.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carFineForm.controls.valor.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="descontarValor"
                    >Descontar Valor do Motorista</label
                  >
                  <div class="row">
                    <div
                      class="custom-control custom-radio custom-control-inline mt-2 ml-3 col-md-5"
                    >
                      <input
                        type="radio"
                        id="true"
                        [value]="true"
                        name="descontarValor"
                        formControlName="descontarValor"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="true">Sim</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline mt-2 ml-3 col-md-6"
                    >
                      <input
                        type="radio"
                        id="false"
                        [value]="false"
                        name="descontarValor"
                        formControlName="descontarValor"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="false"
                        >Não</label
                      >
                    </div>
                  </div>
                  <span
                    *ngIf="
                      carFineForm.controls.descontarValor.errors &&
                      carFineForm.controls.descontarValor.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carFineForm.controls.descontarValor.errors &&
                      carFineForm.controls.descontarValor.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carFineForm.controls.descontarValor.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="descontado">Descontado do Motorista?</label>
                  <div class="row">
                    <div
                      class="custom-control custom-radio custom-control-inline mt-2 ml-3 col-md-5"
                    >
                      <input
                        type="radio"
                        id="descontado_true"
                        [value]="true"
                        name="descontado"
                        formControlName="descontado"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="descontado_true"
                        >Sim</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline mt-2 ml-3 col-md-6"
                    >
                      <input
                        type="radio"
                        id="descontado_false"
                        [value]="false"
                        name="descontado"
                        formControlName="descontado"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="descontado_false"
                        >Não</label
                      >
                    </div>
                  </div>
                  <span
                    *ngIf="
                      carFineForm.controls.descontado.errors &&
                      carFineForm.controls.descontado.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo obrigatório</span
                  >
                  <span
                    *ngIf="
                      carFineForm.controls.descontado.errors &&
                      carFineForm.controls.descontado.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ carFineForm.controls.descontado.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <a
                *ngIf="!!carFineForm.controls.id.value"
                type="button"
                (click)="resetForm()"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 mr-2 btn-circle btn-default"
              >
                Cancelar
              </a>
              <a
                type="button"
                (click)="onSubmit()"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
              >
                Salvar
              </a>
            </div>
          </div>
          <hr />
          <div class="row mt-" [formGroup]="searchCarFineForm">
            <div class="col-md-2">
              <div class="form-group">
                <label for="data">Data Inicial</label>
                <input
                  type="Date"
                  class="form-control"
                  formControlName="start_date"
                />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="data">Data Final</label>
                <input
                  type="Date"
                  class="form-control"
                  formControlName="end_date"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="discount_driver"
                  >Descontar Valor do Motorista</label
                >
                <select
                  formControlName="discount_driver"
                  class="custom-select custom-select-md"
                >
                  <option value="">Todos</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="hasDriverPaymentId">Descontado?</label>
                <select
                  formControlName="hasDriverPaymentId"
                  class="custom-select custom-select-md"
                >
                  <option value="">Todos</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </div>
            </div>
            <div class="col mt-4">
              <!-- <span class="input-group-btn search-btn"> -->
              <a (click)="getFines()" class="btn btn-info">
                <i class="fa fa-search"></i>
              </a>
              <!-- </span> -->
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped custom-table table-hover">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Tipo</th>
                  <th>Motorista</th>
                  <th>Valor</th>
                  <th>Descontar</th>
                  <th>Descontado?</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fine of fines">
                  <td>
                    {{ fine.date | date : "dd/MM/yyyy" }}
                  </td>
                  <td>
                    {{ fine.type }}
                  </td>
                  <td>
                    {{ fine.driver.name }}
                  </td>
                  <td>
                    {{ fine.value | currency : "BRL" }}
                  </td>
                  <td>
                    <span *ngIf="fine.discountDriver"> Sim </span>
                    <span *ngIf="!fine.discountDriver"> Não </span>
                  </td>
                  <td>
                    <span *ngIf="fine.driverPaymentId || fine.discounted">
                      Sim
                    </span>
                    <span *ngIf="!fine.driverPaymentId && !fine.discounted">
                      Não
                    </span>
                  </td>
                  <td>
                    <button
                      *ngIf="!fine.driverPaymentId"
                      (click)="editFine(fine)"
                      class="btn btn-warning btn-xs"
                      title="Editar Multa"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      *ngIf="!fine.driverPaymentId"
                      (click)="onFineDelete(fine.id)"
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-12">
                <span
                  *ngIf="!fines.length"
                  class="label label-danger label-mini"
                >
                  Nenhuma Multa adicionada.
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="!first">
                  <button class="page-link" (click)="onFirst()" tabindex="-1">
                    Primeira
                  </button>
                </li>
                <li class="page-item" *ngIf="pagination.page != 1">
                  <button (click)="onPrevious()" class="page-link">
                    {{ pagination.page - 1 }}
                  </button>
                </li>
                <li class="page-item active">
                  <button class="page-link">{{ pagination.page }}</button>
                </li>
                <li class="page-item" *ngIf="last">
                  <button (click)="onNext()" class="page-link" href="#">
                    {{ pagination.page + 1 }}
                  </button>
                </li>
                <li class="page-item" [class.disabled]="!last">
                  <button class="page-link" (click)="onLast()">Última</button>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-md-12">
            Mostrando página {{ pagination.page }} de
            {{ pagination.totalPages }} dos
            {{ pagination.totalResults }} Resultados
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-12 text-center">
              <a
                routerLink="/maintenance/car/view/{{ carId }}"
                class="btn btn-danger"
                >Voltar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
