import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/components/validations';
import { Address } from 'src/app/domain/address';
import { City } from 'src/app/domain/city';
import { Covenant } from 'src/app/domain/covenant';
import { CovenantUser } from 'src/app/domain/coventant-user';
import { Pagination } from 'src/app/domain/pagination';
import { State } from 'src/app/domain/state';
import { ViaCep } from 'src/app/domain/viacep';
import { AddressService } from 'src/app/service/address/address.service';
import { CovenantDataService } from 'src/app/service/covenant-data/covenant-data.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-data',
  templateUrl: './covenant-data.component.html',
  styleUrls: ['./covenant-data.component.css']
})
export class CovenantDataComponent implements OnInit {

  covenantDataForm: FormGroup;
  covenantUserForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  covenant: Covenant = new Covenant();
  viacep: ViaCep = new ViaCep;
  states: Array<State> = [];
  cities: Array<City> = [];
  users: Array<CovenantUser> = [];
  paymentMethods;
  pagination: Pagination = new Pagination;



  constructor(
    private service: CovenantDataService,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.createUserForm();
    this.getUsers();

    this.serviceAddress.getStates().subscribe(data => {
      this.states = data;

      this.service.getCovenantData().subscribe(data => {
        this.covenant = data;
        this.serviceAddress.getCitiesByStateId(this.covenant.address.city.state.id).subscribe(data => {
          this.cities = data;
          this.setValues();
        }, error => {
          Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
        });

      }, error => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar Posto de Combustível!', 'error');
      });
    }, error => {
      Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
    });

  }

  getUsers() {
    this.loading = true;
    this.service.getCovenantUsers(this.pagination).subscribe(data => {
      this.users = data['results'];
      this.setPagination(data);
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openUserAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];
  }


  openPaymentAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar as formas de pagamento!', 'error');
  }

  openUserAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os usuários!', 'error');
  }

  createForm() {
    this.covenantDataForm = this.fb.group({
      emailContact: ['', [Validators.maxLength(80), Validators.email, Validators.required]],
      phone: ['', [Validations.validPhone]],
      whatsapp: ['', [Validations.validwhatsapp]],
      contact: ['', [Validators.maxLength(100)]],
      zipCode: ['', [Validators.required, Validators.maxLength(9)]],
      publicPlace: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      active: ['', []]
    });
  }

  createUserForm() {
    this.covenantUserForm = this.fb.group({
      name: ['', [Validators.maxLength(100)]],
      email: ['', [Validators.maxLength(100), Validators.email]],
      role: ['', [Validators.maxLength(100)]],
    });
  }

  setValues() {
    this.covenantDataForm.controls.emailContact.setValue(this.covenant.emailContact);
    this.covenantDataForm.controls.phone.setValue(this.covenant.phone);
    this.covenantDataForm.controls.whatsapp.setValue(this.covenant.whatsapp);
    this.covenantDataForm.controls.contact.setValue(this.covenant.contact);
    this.covenantDataForm.controls.zipCode.setValue(this.covenant.address.zipCode);
    this.covenantDataForm.controls.neighborhood.setValue(this.covenant.address.neighborhood);
    this.covenantDataForm.controls.publicPlace.setValue(this.covenant.address.publicPlace);
    this.covenantDataForm.controls.state.setValue(this.covenant.address.city.state.id);
    this.covenantDataForm.controls.city.setValue(this.covenant.address.city.id);
    this.covenantDataForm.controls.active.setValue(`${this.covenant.active}`);
  }

  onSubmit() {
    this.covenant.address = new Address;
    this.covenant.address.city = new City;
    this.covenant.emailContact = this.covenantDataForm.controls.emailContact.value;
    this.covenant.phone = this.covenantDataForm.controls.phone.value;
    this.covenant.whatsapp = this.covenantDataForm.controls.whatsapp.value;
    this.covenant.contact = this.covenantDataForm.controls.contact.value;
    this.covenant.address.zipCode = this.covenantDataForm.controls.zipCode.value;
    this.covenant.address.neighborhood = this.covenantDataForm.controls.neighborhood.value;
    this.covenant.address.publicPlace = this.covenantDataForm.controls.publicPlace.value;
    this.covenant.address.city.id = this.covenantDataForm.controls.city.value;
    this.covenant.address.city.state = this.covenantDataForm.controls.state.value;
    this.covenant.covenantPaymentMethods = [];

    if (this.covenantDataForm.controls.paymentMethods.value) {
      this.covenantDataForm.controls.paymentMethods.value.forEach(method => {
        this.covenant.covenantPaymentMethods.push({ id: method, name: null });
      });
    }

    if (this.covenantDataForm.controls.active.value == "true") {
      this.covenant.active = true
    } else {
      this.covenant.active = false;
    }
    this.loading = true;
    this.service.update(this.covenant).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Convênio editado com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['maintenance/covenant']);
        }
      });
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Convênio!', 'error');
      });
  }
  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, "").length === 8) {
      this.utilsService.getCep(event.target.value.replace(/[^0-9,.]+/g, "")).subscribe(data => {
        this.viacep = data;

        if (!this.viacep.erro) {
          this.covenantDataForm.controls.neighborhood.setValue(this.viacep.logradouro);
          this.covenantDataForm.controls.publicPlace.setValue(this.viacep.bairro);
          const state = this.states.find(state => state.initials === this.viacep.uf.toUpperCase());

          this.covenantDataForm.controls.state.setValue(state.id);

          // TODO - Implementar busca de cidades por UF
          this.serviceAddress.getCitiesByStateId(state.id).subscribe(data => {
            this.cities = data;
            const city = this.cities.find(elem => elem.name.normalize("NFD") === this.viacep.localidade.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ''));
            if (city) {
              this.covenantDataForm.controls.city.setValue(city.id);
            }
          }, error => {
            Swal.fire('Erro ao salvar', 'Erro ao buscar cidades!', 'error');
          });

        } else {
          this.covenantDataForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        }
        this.loading = false;
      }, erro => {
        this.covenantDataForm.controls.zipCode.setErrors({ invalid: "CEP não encontrado!" });
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.covenantDataForm.controls.zipCode.setErrors({ invalid: "CEP inválido!" });
    }
  }
  onSelectedState(event) {
    // TODO - Ao selecionar um estado no formulário de busca ir no servidor e buscar as cidades relacionadas ao estado
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(data => {
        this.cities = data;
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
      });
    }
  }
  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "emailContact":
          this.covenantDataForm.controls.emailContact.setErrors({ invalid: erro.message });
          break;
        case "phone":
          this.covenantDataForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case "whatsapp":
          this.covenantDataForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "contact":
          this.covenantDataForm.controls.contact.setErrors({ invalid: erro.message });
          break;
        case "zipCode":
          this.covenantDataForm.controls.zipCode.setErrors({ invalid: erro.message });
          break;
        case "neighborhood":
          this.covenantDataForm.controls.neighborhood.setErrors({ invalid: erro.message });
          break;
        case "publicPlace":
          this.covenantDataForm.controls.publicPlace.setErrors({ invalid: erro.message });
          break;
        case "state":
          this.covenantDataForm.controls.state.setErrors({ invalid: erro.message });
          break;
        case "city":
          this.covenantDataForm.controls.city.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;

      }
    });

  }

  onSubmitUser() {
    const values = this.covenantUserForm.controls;
    let covenantUser = new CovenantUser();
    covenantUser.name = values.name.value;
    covenantUser.email = values.email.value;
    covenantUser.role = values.role.value;

    this.loading = true;
    this.service.saveUser(covenantUser).subscribe(data => {
      this.loading = false;
      Swal.fire('Salvo', 'Usuário salvo com sucesso!!', 'success');
      this.createUserForm();
      this.getUsers();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar Usuário!', 'error');
      });
  }

  onUserDelete(covenantUser) {
    this.service.deleteUser(covenantUser).subscribe(data => {
      this.loading = false;
      Swal.fire('Excluido!', 'Usuário excluído com sucesso!!', 'success');
      this.getUsers();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao excluir', 'Não foi possível excluir Usuário!', 'error');
      });
  }

  onUserResend(covenantUser) {
    this.service.resendConfirmationUser(covenantUser).subscribe(data => {
      this.loading = false;
      Swal.fire('Enviado!', 'O e-mail de confirmação foi re-enviado para o usuário!', 'success');
      this.getUsers();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao enviar', 'Não foi possível enviar email para o usuário!', 'error');
      });
  }

  resetUserForm() {
    this.covenantUserForm.controls.name.setValue("");
    this.covenantUserForm.controls.email.setValue("");
    this.covenantUserForm.controls.role.setValue("");
  }

  setUSerErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "name":
          this.covenantUserForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "email":
          this.covenantUserForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "role":
          this.covenantUserForm.controls.role.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }

}


