import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BankService } from 'src/app/service/bank/bank.service';
import { Bank } from 'src/app/domain/bank';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bank-create',
  templateUrl: './bank-create.component.html',
  styleUrls: ['./bank-create.component.css'],
})
export class BankCreateComponent implements OnInit {
  bankForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';

  constructor(
    private service: BankService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.bankForm = this.fb.group({
      name: ['', [Validators.required]],
      number: [
        '',
        [Validators.required, Validators.max(999), Validators.min(0)],
      ],
    });
  }

  onSubmit() {
    let bank = new Bank();

    bank.name = this.bankForm.controls.name.value;
    bank.number = String(this.bankForm.controls.number.value);

    this.loading = true;
    this.service.save(bank).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire('Salvo', 'Banco salvo com sucesso!!', 'success').then(
          (result) => {
            if (result.value) {
              this.router.navigate(['parameters/bank']);
            }
          }
        );
        this.createForm();
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar Banco!', 'error');
      }
    );
  }

  resetForm() {
    this.bankForm.controls.name.setValue('');
    this.bankForm.controls.number.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'name':
          this.bankForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case 'number':
          this.bankForm.controls.number.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }
}
