import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Bank } from 'src/app/domain/bank';
import { BankAccount } from 'src/app/domain/bank-account';
import { BankAccountService } from 'src/app/service/bank-account/bank-account.service';
import { BankService } from 'src/app/service/bank/bank.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-bank-update',
  templateUrl: './account-bank-update.component.html',
  styleUrls: ['./account-bank-update.component.css']
})
export class AccountBankUpdateComponent implements OnInit {
  loading: boolean = true;
  bankForm: FormGroup;
  banks: Array<Bank> = [];
  id: number;
  bankAccount: BankAccount;
  messageError =""
  constructor(
    private service: BankAccountService,
    private bankService: BankService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { this.route.params.subscribe((params) => {
    this.id = params['id'];
  });}

  ngOnInit(): void {
    this.getAllBankActive();
    this.createForm();
  }

  getAllBankActive() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;
        this.loading = false;
        this.populate();
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    )
  }

  populate() {
    this.service.getBankAccount(this.id).subscribe(data => {
      this.bankAccount = data;
      this.bankForm.controls.agency.setValue(data.agency)
      this.bankForm.controls.agencyDv.setValue(data.agencyDv)
      this.bankForm.controls.type.setValue(data.type)
      this.bankForm.controls.bankAccount.setValue(data.bankAccount)
      this.bankForm.controls.bankAccountDv.setValue(data.bankAccountDv)
      this.bankForm.controls.receivePayments.setValue(data.receivePayments)
      this.bankForm.controls.status.setValue(data.active ? 'active' : 'inactive')
      this.bankForm.controls.bank.setValue(data.bankId)
      this.bankForm.controls.id.setValue(data.id)
      this.bankForm.controls.socialNumber.setValue(data.socialNumber)
      this.bankForm.controls.socialName.setValue(data.socialName)
    })
  }

  createForm() {
    this.bankForm = this.fb.group({
      id: ['',[Validators.required]],
      agency: ['', [Validators.required]],
      agencyDv: [''],
      type: ['', [Validators.required]],
      bankAccount: ['', [Validators.required]],
      bankAccountDv: ['', [Validators.required]],
      socialNumber: ['', [Validators.required]],
      socialName: ['', [Validators.required]],
      receivePayments: [false],
      bank: ['', [Validators.required]],
      status: ['', [Validators.required]],
    })
  }

  onSubmit() {
    const bankValues = this.bankForm.getRawValue();
    if(this.bankForm.valid) {
      bankValues.bank = {
        id: Number(this.bankForm.get('bank').value) || null,
      };
      bankValues.active = bankValues.status == 'active' ? true : false

      this.loading = true;

      this.service.update(bankValues).subscribe(
        data => {
          Swal.fire(
            'Salvo',
            'Conta Bancária editada com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/account-bank']);
            }
          });
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Conta Bancária!',
            'error'
          );
        }
      )
    }
  }
  setErrors(errors) {
    errors.forEach(erro => {
      if(erro.message == "Já existe uma conta bancária cadastrada com estes dados") {
        this.messageError = erro.message
        return ;
      }
      this.bankForm.controls[erro.fieldName].setErrors({ invalid: erro.message });
      //switch (erro.fieldName) {
      //  case "agency":
      //    this.bankForm.controls.agency.setErrors({ invalid: erro.message });
      //    break;
      //  case "bankAccount":

      //    this.bankForm.controls.bankAccount.setErrors({ invalid: erro.message });
      //    break;
      //  case "bank":
      //    this.bankForm.controls.bank.setErrors({ invalid: erro.message });
      //    break;
      //  default:
          this.messageError += `${erro.message}\n`;
      //}
    });

  }

}
