import { State } from './state';

export class City {
  id: number;
  name: string;
  state: State = new State();

  constructor(attrs?: Partial<City>) {
    Object.assign(this, attrs);
    this.state = new State(attrs?.state);
  }
}
