<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Banco</div>
            </div>
          </div>
          <div [formGroup]="bankForm">
            <!--div com o form -->
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{messageError}}
              </div>
            </div>
            <div class="row">

              <div class="col-md-6">
                <div class="form-group">
                  <label for="number">Número do Banco</label>
                  <input type="number" min="0" max="999" class="form-control" id="number" formControlName="number"
                    placeholder="Número do Banco">
                  <span *ngIf="bankForm.controls.number.errors">
                    <span *ngIf="bankForm.controls.number.errors.required" class="text-danger position-right">*Campo
                      Obrigatório</span>
                    <span *ngIf="!bankForm.controls.number.errors.required && bankForm.controls.number.errors.min"
                      class="text-danger position-right">*Número de banco não pode ser
                      negativo</span>
                    <span *ngIf="!bankForm.controls.number.errors.min && bankForm.controls.number.errors.max"
                      class="text-danger position-right">Número deve ter no máximo 3
                      caracteres</span>
                    <span *ngIf="!bankForm.controls.number.errors.max && bankForm.controls.number.errors.invalid"
                      class="text-danger position-right">*
                      {{bankForm.controls.number.errors.invalid}} </span>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input type="text" class="form-control" id="name" formControlName="name" placeholder="Nome do Banco">
                  <span *ngIf="bankForm.controls.name.errors && bankForm.controls.name.errors.required"
                    class="text-danger position-right">*Campo
                    Obrigatório</span>

                  <span *ngIf="bankForm.controls.name.errors && bankForm.controls.name.errors.invalid"
                    class="text-danger position-right">*
                    {{bankForm.controls.name.errors.invalid}} </span>
                </div>
              </div>
            </div>


            <div class="row mt-2 mb-2">
              <div class="col-md-3">

              </div>
              <div class="col-md-3 text-right">
                <button (click)="onSubmit()" [disabled]="!bankForm.valid" class="btn btn-success">Salvar</button>
              </div>
              <div class="col-md-3">
                <a routerLink="/parameters/bank" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
