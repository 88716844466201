import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CovenantRunsService } from 'src/app/service/covenant-runs/covenant-runs.service';
import { RunService } from 'src/app/service/run/run.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-run-view',
  templateUrl: './covenant-run-view.component.html',
  styleUrls: ['./covenant-run-view.component.css']
})
export class CovenantRunViewComponent implements OnInit {

  id: number;
  run;
  loading: boolean = true;

  constructor(
    private service: CovenantRunsService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getRuns();
  }

  getRuns() {
    this.loading = true;
    this.service.getRun(this.id).subscribe(data => {
      this.run = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar Corrida!', 'error');
  }



}
