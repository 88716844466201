<!-- Botão para abrir o modal -->
<button class="btn btn-primary" (click)="modalFireCondition = true">
  Editar endereço
</button>

<swal
  *ngIf="modalFireCondition"
  [swalFireOnInit]="true"
  (willOpen)="onFormUpdate()"
  (didClose)="modalFireCondition = false"
  [title]="'Editar endereço de ' + covenant.name"
  [showConfirmButton]="false"
  [showCloseButton]="false"
  [showDenyButton]="false"
  [width]="900"
>
  <ngb-alert *ngIf="error">{{ error }}</ngb-alert>
  <!-- This form will be displayed as the alert main content
       Targets the alert's main content zone by default -->
  <form *swalPortal="undefined" [formGroup]="form" class="text-left">
    <hr />
    <div class="row" *ngIf="form">
      <div class="col-md-4">
        <div class="form-group">
          <label for="zipCode">CEP</label>
          <input
            type="text"
            class="form-control cep"
            (blur)="onBlurCep($event)"
            id="zipCode"
            formControlName="zipCode"
            placeholder="CEP"
          />
          <ng-container *ngIf="form.get('zipCode').touched">
            <span
              *ngIf="
                form.controls.zipCode.errors &&
                form.controls.zipCode?.errors?.required
              "
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.zipCode.errors &&
                form.controls.zipCode?.errors?.maxlength
              "
              class="text-danger position-right"
              >*CEP deve ter no máximo 11 caracteres</span
            >
            <span
              *ngIf="
                form.controls.zipCode.errors &&
                form.controls.zipCode?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.zipCode?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label for="publicPlace">Logradouro</label>
          <input
            type="text"
            class="form-control"
            id="publicPlace"
            formControlName="publicPlace"
            placeholder="Rua / Complemento / Número"
          />
          <ng-container *ngIf="form.get('publicPlace').touched">
            <span
              *ngIf="
                form.controls.publicPlace.errors &&
                form.controls.publicPlace?.errors?.required
              "
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.publicPlace.errors &&
                form.controls.publicPlace?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.publicPlace?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="neighborhood">Bairro</label>
          <input
            type="text"
            class="form-control"
            id="neighborhood"
            formControlName="neighborhood"
            placeholder="Bairro"
          />
          <ng-container *ngIf="form.get('neighborhood').touched">
            <span
              *ngIf="
                form.controls.neighborhood.errors &&
                form.controls.neighborhood?.errors?.required
              "
              class="text-danger position-right"
              >*Campo Obrigatório</span
            >
            <span
              *ngIf="
                form.controls.neighborhood.errors &&
                form.controls.neighborhood?.errors?.invalid
              "
              class="text-danger position-right"
              >*
              {{ form.controls.neighborhood?.errors?.invalid }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Estado </label>
          <select
            (change)="onSelectedState($event)"
            class="form-control select2"
            formControlName="stateId"
          >
            <option [value]="null" disabled>Selecione um Estado</option>
            <option
              [value]="state.id"
              *ngFor="let state of states"
              [selected]="form.get('stateId').value == state.id"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Cidade </label>
          <select class="form-control select2" formControlName="cityId">
            <option [value]="null" disabled>Selecione uma Cidade</option>
            <option
              [value]="city.id"
              *ngFor="let city of cities"
              [selected]="form.get('cityId').value == city.id"
            >
              {{ city.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center mt-3">
      <button (click)="onSubmit()" class="btn btn-success">Salvar</button>

      <a type="button" (click)="onClose()" class="btn btn-danger ml-3">
        Cancelar
      </a>
    </div>
  </form>
</swal>
