import { PaymentMethod } from './paymentMethod';
import { Driver } from 'src/app/domain/driver';

export class PayDriver {
  id: number;
  driver: Driver;
  paymentType: string;
  initialDate: Date;
  finalDate: Date;
  value: Number;
  paid: Boolean;

  constructor() {}
}
