<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row bg-white p-3">
            <div class="col-12 d-flex justify-content-between">
              <div class="page-title pt-4 pl-3 mb-5">Acerto com Motorista</div>
              <div>
                <a
                  type="button"
                  href="/payment-driver/new"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                  >Gerar Acerto Motorista
                </a>
              </div>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row col-12 m-0">
                <div class="col-12 col-md-6 col-lg-3 mt-2">
                  <p>Motorista</p>
                  <select formControlName="driver" class="custom-select">
                    <option selected>Motorista</option>
                  </select>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mt-2">
                  <p>Forma de pagamento</p>
                  <select formControlName="paymentType" class="custom-select">
                    <option selected>Forma de pagamento</option>
                  </select>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mt-2">
                  <p>Status</p>
                  <select formControlName="status" class="custom-select">
                    <option selected>Status</option>
                  </select>
                </div>

                <div class="col-12 col-md-6 col-lg-3 d-flex align-items-end">
                  <button
                    type="button"
                    type="submit"
                    class="btn btn-primary mt-3"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>

                <div class="col-12 col-md-6 col-lg-3 mt-5">
                  <select
                    formControlName="findNumber"
                    class="custom-select custom-select-sm"
                  >
                    <option value="10" selected>Mostrar 10 resultados</option>
                    <option value="20">Mostrar 20 resultados</option>
                    <option value="30">Mostrar 30 resultados</option>
                  </select>
                </div>
                <div class="col-12 mt-4">
                  <table class="col-12 table table-striped table-hover p-0">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Motorista</th>
                        <th>Forma de Pagamento</th>
                        <th>Período</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>
                          <a
                            routerLink="/parameters/request-value/view"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <a
                            routerLink="/parameters/request-value/edit"
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>
                          <a
                            routerLink="/parameters/request-value/view"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <a
                            routerLink="/parameters/request-value/edit"
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>
                          <a
                            routerLink="/parameters/request-value/view"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <a
                            routerLink="/parameters/request-value/edit"
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>
                          <a
                            routerLink="/parameters/request-value/view"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <a
                            routerLink="/parameters/request-value/edit"
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>
                          <a
                            routerLink="/parameters/request-value/view"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="fa fa-eye"></i>
                          </a>
                          <a
                            routerLink="/parameters/request-value/edit"
                            class="btn btn-warning btn-xs"
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="col-md-12">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination justify-content-center">
                        <li class="page-item">
                          <button class="page-link" tabindex="-1">
                            Primeira
                          </button>
                        </li>
                        <li class="page-item">
                          <button class="page-link"></button>
                        </li>
                        <li class="page-item active">
                          <button class="page-link"></button>
                        </li>
                        <li class="page-item">
                          <button class="page-link" href="#"></button>
                        </li>
                        <li class="page-item">
                          <button class="page-link">Última</button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="col-md-12">
                    Mostrando página de dos Resultados
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
