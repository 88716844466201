import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Client } from 'src/app/domain/client';
import { Pagination } from 'src/app/domain/pagination';
import { ClientDTO } from 'src/app/domain/dto/client.dto';
import { stringifyWithoutNull } from '../../helpers/json-helper';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  url: string = `${environment.BASE_URL}/clients`;
  paymentMethodsUrl: string = `${environment.BASE_URL}/payment-methods`;

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getClients(client: Client, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${client.name ?? ''}`,
      cpf_cnpj: `${client.cpfCnpj ?? ''}`,
      zip_code: `${client.address?.zipCode.replace(/\D/g, '') ?? ''}`,
      state: `${client.address.city.state.id ?? ''}`,
      city: `${client.address.city.id ?? ''}`,
    };

    return this.http.get(this.url, { responseType: 'json', params: params });
  }
  getClientsSearch(search: string) {
    const params = {
      search: search,
    };

    return this.http.get(`${this.url}/search`, {
      responseType: 'json',
      params: params,
    });
  }
  activeAccount(value: { token: string; password: string }) {
    return this.http.put(`${this.url}/active/token`, JSON.stringify(value), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  getPaymentMethods() {
    return this.http.get(this.paymentMethodsUrl, { responseType: 'json' });
  }

  getClient(id: number) {
    return this.http.get<Client>(`${this.url}/${id}`, { responseType: 'json' });
  }
  save(client: Client) {
    let dto = new ClientDTO(client);

    return this.http.post(`${this.url}`, stringifyWithoutNull(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(client: Client) {
    let dto = new ClientDTO(client);
    return this.http.put<Client>(
      `${this.url}/${client.id}`,
      stringifyWithoutNull(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  resendEmailSender(id: number) {
    return this.http.put(
      `${this.url}/${id}/re-send-activation-token`,
      {},
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }
  getAllActiveClients() {
    return this.http.get<Array<Client>>(`${this.url}/all-active`, {
      responseType: 'json',
    });
  }

  imageUpload(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.url}/${id}/image-upload`, formData);
  }
}
