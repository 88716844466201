import { Component, OnInit } from '@angular/core';
import { Bank } from 'src/app/domain/bank';
import { BankService } from 'src/app/service/bank/bank.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bank-view',
  templateUrl: './bank-view.component.html',
  styleUrls: ['./bank-view.component.css']
})
export class BankViewComponent implements OnInit {

  id: number;
  bank: Bank = new Bank();
  loading: boolean = true;

  constructor(
    private service: BankService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getBanks();
  }

  getBanks() {
    this.loading = true;
    this.service.getBank(this.id).subscribe(data => {
      this.bank = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Banco!', 'error');
  }



}
