import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditCardService } from 'src/app/service/credit-card/credit-card.service';
import { CreditCard } from 'src/app/domain/credit-card';
import Swal from 'sweetalert2';
import { Flag } from 'src/app/enumerations/flag';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credit-card-create',
  templateUrl: './credit-card-create.component.html',
  styleUrls: ['./credit-card-create.component.css']
})
export class CreditCardCreateComponent implements OnInit {

  creditCardForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  flag: Flag;

  constructor(
    private service: CreditCardService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.creditCardForm = this.fb.group({
      number: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }
  onSubmit() {

    const values = this.creditCardForm.controls;
    console.log(this.creditCardForm.controls);


    let creditCard = new CreditCard();
    creditCard.number = this.creditCardForm.controls.number.value.replace(/[^0-9]/g, '');

    this.loading = true;
    this.service.save(creditCard).subscribe(data => {
      this.loading = false;
      Swal.fire('Salvo', 'Cartão de Crédito salvo com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/credit-card']);
        }
      });
      this.createForm();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar Cartão de Crédito!', 'error');
      });

  }

  resetForm() {
    this.creditCardForm.controls.number.setValue("");
    this.flag = null;
  }

  setErrors(errors) {
    errors.forEach(erro => {
      switch (erro.fieldName) {
        case "number":
          this.creditCardForm.controls.number.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }
  onKey(event) {
    this.messageError = '';
    const flag = this.utilsService.getCardFlag(event.target.value);
    if (flag) {
      this.flag = Flag[flag];

    } else {
      this.flag = null;
      this.creditCardForm.controls.number.setErrors({ invalid: "Cartão Inválido," });
    }

  }

}
