import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Covenant } from 'src/app/domain/covenant';
import { CovenantUser } from 'src/app/domain/coventant-user';
import { CovenantUserDTO } from 'src/app/domain/dto/covenant-user.dto';
import { CovenantDTO } from 'src/app/domain/dto/covenant.dto';
import { Pagination } from 'src/app/domain/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CovenantDataService {
  url: string = `${environment.BASE_URL}/registration/covenant`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getCovenantData() {
    return this.http.get<Covenant>(`${this.url}`,
      { responseType: "json" });
  }

  update(covenant: Covenant) {
    let dto = new CovenantDTO(covenant);
    return this.http.put(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  getCovenantUsers(pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
    };
    return this.http.get(`${this.url}/users`,
      { responseType: "json", params: params });
  }

  saveUser(covenantUser: CovenantUser) {
    let dto = new CovenantUserDTO(covenantUser);
    return this.http.post(`${this.url}/users`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  deleteUser(id: number) {
    return this.http.delete(`${this.url}/users/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

  resendConfirmationUser(id: number) {
    return this.http.put(`${this.url}/users/${id}/re-send-activation-token`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
