<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar Veículo</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="car-image">
                    <img
                      class="img-responsive"
                      [src]="
                        car.imageUrl
                          ? car.imageUrl
                          : 'assets/img/vehicle/default.png'
                      "
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Marca</b>
                        </div>
                        <div class="col-md-12">
                          {{ car.model?.brand?.name }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Modelo</b>
                        </div>
                        <div class="col-md-12">
                          {{ car.model?.name }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Ano</b>
                        </div>
                        <div class="col-md-12">
                          {{ car.year }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Motor</b>
                        </div>
                        <div class="col-md-12">
                          {{ car.motor?.name }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Cor</b>
                        </div>
                        <div class="col-md-12">
                          {{ car.color }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Combustível</b>
                        </div>
                        <div class="col-md-12">
                          {{ car.fuel }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Placa</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.carPlate }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>RENAVAM</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.renavam }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Chassi</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.chassi }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Vencimento IPVA</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.expirationDateIpva | date : "dd/MM/yyyy" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Hodômetro</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.pedometer }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Tipo</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.type }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <span
                        *ngIf="car.carAir"
                        class="label label-sucess label-mini"
                        >Tem Ar-Condicionado</span
                      >
                      <span
                        *ngIf="!car.carAir"
                        class="label label-danger label-mini"
                        >Não tem Ar-Condicionado</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Seguradora</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.insurer?.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Número da Apólice</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.insurancePolicy }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Data de Vencimento Seguro</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.dueDateInsurancePolicy | date : "dd/MM/yyyy" }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Quantidade Passageiros</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.numberPassengers }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Malas Grandes</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.numberLargeSuitcases || "Não informado" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Malas Pequenas</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.numberSmallSuitcases || "Não informado" }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Cidade</b>
                    </div>
                    <div class="col-md-12">
                      {{ car.city?.name }}
                      <span *ngIf="!car.city?.name">Não informado</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Estado</b>
                    </div>
                    <div class="col-md-12">
                      {{ car?.city?.state?.name }}
                      <span *ngIf="!car.city?.state?.name">Não informado</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3 mb-3">
              <div class="col-md-4 text-center">
                <a
                  routerLink="/maintenance/car/refuel/{{ car.id }}"
                  class="btn btn-primary"
                >
                  Gerenciar Abastecimentos
                </a>
              </div>
              <div class="col-md-4 text-center">
                <a
                  routerLink="/maintenance/car/maintenance/{{ car.id }}"
                  class="btn btn-primary"
                >
                  Gerenciar Manutenções
                </a>
              </div>
              <div class="col-md-4 text-center">
                <a
                  routerLink="/maintenance/car/fines/{{ car.id }}"
                  class="btn btn-primary"
                >
                  Gerenciar Multas
                </a>
              </div>
            </div>

            <div class="card-footer">
              <div class="row col-12 d-flex justify-content-center">
                <div class="m-2">
                  <a
                    routerLink="/maintenance/car/edit/{{ car.id }}"
                    class="btn btn-warning"
                    >Editar</a
                  >
                </div>
                <div class="m-2">
                  <a routerLink="/maintenance/car" class="btn btn-info"
                    >Voltar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
