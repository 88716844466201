import { Time } from '@angular/common';
import { RequestValue } from './request-value';

export class CovenantRequestValue {
  id: number;
  destiny: string;
  value: number;
  aditionalValue: number;
  tollAmount: number;
  percentage: number;
  initialHour: Time;
  endHour: Time;
  validity: string;
  requestValue: RequestValue;
}
