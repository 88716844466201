<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Visualizar Conta Bancária</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <header style="width: 100%">
                <div class="row">
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Banco</label>
                    <label style="font-size: 14px" class="mt-2"
                      >Banco do Brasil S.A.</label
                    >
                  </div>
                  <div class="col-sm-2">
                    <label style="font-size: 14px" class="mt-2">Agência</label>
                    <label style="font-size: 14px" class="mt-2">0324-7</label>
                  </div>
                  <div class="col-sm-2">
                    <label style="font-size: 14px" class="mt-2"
                      >Conta Bancária</label
                    >
                    <label style="font-size: 14px" class="mt-2">424862-7</label>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 14px" class="mt-2">Tipo</label>
                    <label style="font-size: 14px" class="mt-2"
                      >Conta Corrente</label
                    >
                  </div>
                  <div class="col-sm-2">
                    <label style="font-size: 14px" class="mt-2">Status</label>
                    <label style="font-size: 14px" class="mt-2">Ativo</label>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-3">
                    <a routerLink="/parameters/bank-account" class="btn btn-success">Voltar</a>
                  </div>
                  <div class="col-md-3">
                    <a routerLink="/parameters/bank-account/edit:id" class="btn btn-danger"
                      >Editar</a
                    >
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
