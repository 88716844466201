<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-topline-green">
                        <header class="mt-4 ml-4 mr-4">
                            <div class="row page-title">
                                <label>Detalhes do Agendamento</label>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Data Início</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.startDate}}</label>
                                </div>
                                <div class="col">
                                    <label>Hora Início</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.startHour}}</label>
                                </div>
                                <div class="col">
                                    <label>Data Fim</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.endDate}}</label>
                                </div>
                                <div class="col">
                                    <label>Hora Fim</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.endHour}}</label>
                                </div>
                            </div>
                            <div class="row page-title mt-3">
                                <label>Endereços</label>
                            </div>
                            <div class="container">
                                <div class="row mt-3">
                                    <label>Origem</label>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2">
                                        <label>CEP</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripStart.zipCode}}</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <label>Logradouro</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripStart.zipCode}}</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <label>Bairro</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripStart.zipCode}}</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label>Cidade</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripStart.city.name}}</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label>Estado</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripStart.city.state.initials}}</label>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <label>Destino</label>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2">
                                        <label>CEP</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripEnd.zipCode}}</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <label>Logradouro</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripEnd.zipCode}}</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <label>Bairro</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripEnd.publicPlace}}</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label>Cidade</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripEnd.city.name}}</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label>Estado</label>
                                        <label>{{tripRequestSchedule.runRequestSchedule.runRequest.tripEnd.city.state.initials}}</label>
                                    </div>
                                </div>

                            </div>
                        </header>
                        <div class="container mt-3">
                            <label>Dados da Corrida</label>
                            <div class="row container">
                                <div class="col-sm-6">
                                    <label>Veículo</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.runRequest.car.carPlate}} -
                                        <!-- {{tripRequestSchedule.runRequest.car.model.name}}--></label>
                                </div>
                                <div class="col-sm-6">
                                    <label>Motorista</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.runRequest.driver.name}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="container mt-3">
                            <label>Financeiro</label>
                            <div class="row container">
                                <div class="col-sm-4">
                                    <label>Tipo de Pagamento</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.runRequest.payment.name}}</label>
                                </div>
                                <div class="col-sm-4">
                                    <label>Centro de Custo</label>
                                    <label>{{tripRequestSchedule.runRequestSchedule.runRequest.costCenter.name}}</label>
                                </div>
                                <div class="col-sm-4">
                                    <label>Valor</label>
                                    <!-- <label>{{tripRequestSchedule.runRequestSchedule.runRequest.requestValue.value}}</label> -->
                                </div>
                            </div>
                        </div>

                        <div class="container mt-3 mb-3">
                            <label>Observação</label>
                            <div class="row container">
                                <label>{{tripRequestSchedule.runRequestSchedule.runRequest.observation}}</label>
                            </div>
                        </div>
                        <div class="row mt-3 mb-3">
                            <div class="row button">
                                <a href="/convenio" class="btn btn-danger">Voltar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>