import { Component, OnInit, NgModule } from '@angular/core';
import { User } from 'src/app/domain/user';
import { UserService } from 'src/app/service/user/user.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})

export class UserViewComponent implements OnInit {

  id: number;
  user: User = new User();
  loading: boolean = true;

  constructor(
    private service: UserService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.loading = true;
    this.service.getUser(this.id).subscribe(data => {
      this.user = data;
      this.loading = false;
      console.log(data);

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Usuário!', 'error');
  }

}
