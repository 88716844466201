import { Driver } from '../driver';
import { Address } from '../address';
import { AddressDTO } from './address.dto';

export class DriverDTO {
    id: number;
    name: string;
    cpf: string;
    birthDate: Date;
    email: string;
    phone: string;
    whatsapp: string;
    rg: string;
    rgShippingSector: string;
    cnh: string;
    cnhCategory: string;
    cnhValidity: Date;
    password: number;
    passwordHash: string;
    active: boolean;
    activationToken: string;
    qualification: number;
    numberRuns: number;
    runningMinutes: number;
    runningHours: number;
    address: AddressDTO;

    constructor(driver: Driver) {
        this.id = driver.id;
        this.name = driver.name;
        this.cpf = driver.cpf;
        this.birthDate = driver.birthDate;
        this.email = driver.email;
        this.phone = driver.phone;
        this.whatsapp = driver.whatsapp;
        this.rg = driver.rg;
        this.rgShippingSector = driver.rgShippingSector;
        this.cnh = driver.cnh;
        this.cnhCategory = driver.cnhCategory;
        this.cnhValidity = driver.cnhValidity;
        this.password = driver.password;
        this.passwordHash = driver.passwordHash;
        this.active = driver.active;
        this.activationToken = driver.activationToken;
        this.qualification = driver.qualification;
        this.numberRuns = driver.numberRuns;
        this.runningMinutes = driver.runningMinutes;
        this.runningHours = driver.runningHours;
        this.address = new AddressDTO(driver.address);

    }
}