import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pagination } from 'src/app/domain/pagination';
import { RequestValue } from 'src/app/domain/request-value';
import { CovenantService } from 'src/app/service/covenant/covenant.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { CovenantRequestValue } from 'src/app/domain/covenant-request-value';
import { CovenantRequestValueService } from 'src/app/service/covenant-request-value/covenant-request-value.service';
import { request } from 'http';
import { Validations } from '../../validations';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-covenant-request-values',
  templateUrl: './covenant-request-values.component.html',
  styleUrls: ['./covenant-request-values.component.css'],
})
export class CovenantRequestValuesComponent implements OnInit {
  searchForm: FormGroup;
  covenantRequestValueForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  pagination: Pagination = new Pagination();
  covenantId: number;
  requestValues: Array<RequestValue> = [];
  covenantRequestValues: Array<CovenantRequestValue> = [];
  isEditingId: number = null;
  editingRequestValue: string = '';
  covenantRequestValue: CovenantRequestValue = new CovenantRequestValue();

  first: boolean = false;
  last: boolean = false;

  curr = new CurrencyPipe('en-US', 'BRL');

  constructor(
    private service: CovenantRequestValueService,
    private requestValueService: RequestValueService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.covenantId = params['id'];
    });

    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getRequestValues();
    this.getCovenantRequestValues();
  }

  // ---------------- FUNÇÕES DE LISTAGEM DE Valores --------------------
  getRequestValues() {
    this.loading = true;
    this.requestValueService.getAllActiveRequestValue().subscribe(
      (data) => {
        this.requestValues = data.map((requestValue) => ({
          ...requestValue,
          requestAndValue: `${requestValue.destiny} - ${this.curr.transform(
            requestValue.value,
            'BRL'
          )}`,
        }));
      },
      (error) => {
        this.openAlertError();
      }
    );
  }

  getCovenantRequestValues() {
    this.loading = true;
    this.service
      .getCovenantRequestValues(
        this.covenantId,
        this.pagination,
        this.covenantRequestValue
      )
      .subscribe(
        (data) => {
          this.covenantRequestValues = data['results'];
          this.setPagination(data);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.openAlertError();
        }
      );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire(
      'Ops...',
      'Ocorreu um erro ao carregar os Valores de solicitação!',
      'error'
    );
  }
  // ---------------- FUNÇÕES DE LISTAGEM DE Valores --------------------

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE Valores --------------------
  createForm() {
    this.covenantRequestValueForm = this.fb.group({
      requestValue: ['', [Validators.required]],
      validity: ['', [Validations.validDate]],
    });
    this.isEditingId = null;
    this.editingRequestValue = '';

    this.searchForm = this.fb.group({
      validity: [this.covenantRequestValue.validity],
      destiny: [this.covenantRequestValue.destiny],
      percentage: [this.covenantRequestValue.percentage],
      value: [this.covenantRequestValue.value],
    });
  }

  onSubmit() {
    const values = this.covenantRequestValueForm.controls;
    let covenantRequestValue = new CovenantRequestValue();
    covenantRequestValue.requestValue = new RequestValue();
    if (this.isEditingId) {
      covenantRequestValue.id = this.isEditingId;
    }
    covenantRequestValue.requestValue.id = values.requestValue.value;
    covenantRequestValue.validity = values.validity.value;

    if (!this.isEditingId) {
      this.loading = true;
      this.service.save(this.covenantId, covenantRequestValue).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Salvo',
            'Valor das corridas salvo com sucesso!!',
            'success'
          );
          this.createForm();
          this.getCovenantRequestValues();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Valor das corridas!',
            'error'
          );
        }
      );
    } else {
      this.loading = true;
      this.service.update(this.covenantId, covenantRequestValue).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Salvo',
            'Valor das corridas salvo com sucesso!!',
            'success'
          );
          this.createForm();
          this.getCovenantRequestValues();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Valor das corridas!',
            'error'
          );
        }
      );
    }
  }

  onRequestValueEdit(covenantRequestValue: CovenantRequestValue) {
    this.isEditingId = covenantRequestValue.id;
    this.editingRequestValue = `${
      covenantRequestValue.requestValue.destiny
    } - ${this.curr.transform(covenantRequestValue.requestValue.value, 'BRL')}`;
    this.covenantRequestValueForm.controls.requestValue.setValue(
      covenantRequestValue.requestValue.id
    );

    this.covenantRequestValueForm.controls.validity.setValue(
      covenantRequestValue.validity
    );
  }

  onRequestValueDelete(covenantRequestValue) {
    this.service.delete(this.covenantId, covenantRequestValue).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire(
          'Excluido!',
          'Valor das corridas excluído com sucesso!!',
          'success'
        );
        this.createForm();
        this.getCovenantRequestValues();
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao excluir',
          'Não foi possível excluir Valor das corridas!',
          'error'
        );
      }
    );
  }

  resetForm() {
    this.covenantRequestValueForm.controls.requestValue.setValue('');
    this.covenantRequestValueForm.controls.validity.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'requestValue':
          this.covenantRequestValueForm.controls.requestValue.setErrors({
            invalid: erro.message,
          });
          break;
        case 'validity':
          this.covenantRequestValueForm.controls.validity.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  onSearch() {
    const {
      validity,
      destiny,
      percentage,
      value: requestValue,
    } = this.searchForm.controls;

    this.covenantRequestValue.destiny = destiny.value;
    this.covenantRequestValue.validity = validity.value;
    this.covenantRequestValue.percentage = percentage.value
      ? Number(percentage.value) / 100
      : null;
    this.covenantRequestValue.value = requestValue.value;

    this.getCovenantRequestValues();
  }

  resetSearchForm() {
    const {
      validity,
      destiny,
      percentage,
      value: requestValue,
    } = this.searchForm.controls;

    validity.setValue(null);
    destiny.setValue(null);
    percentage.setValue(null);
    requestValue.setValue(null);
  }
}
