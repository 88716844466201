<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="card-body page-bar" *ngIf="filterGroup">
      <div [formGroup]="filterGroup">
        <div class="row">
          <div class="col-sm-3">
            <label style="font-size: 14px" class="mt-2"> Motorista </label>
            <ng-select
              class="custom-select"
              style="font-size: 1rem; font-weight: normal"
              notFoundText="Nenhum item encontrado"
              placeholder="Motorista"
              formControlName="driverId"
              [items]="drivers"
              bindLabel="name"
              bindValue="id"
              (change)="setDriver($event)"
            >
            </ng-select>
            <p
              *ngIf="filterGroup.controls.driverId.errors?.required"
              class="fild-invalid"
            >
              Necessário informar o motorista!
            </p>
          </div>
          <div class="col-sm-3">
            <label style="font-size: 14px" class="mt-2"> Convênio(s) </label>
            <ng-select
              [multiple]="true"
              class="form-control"
              style="font-size: 1rem; font-weight: normal"
              notFoundText="Nenhum item encontrado"
              placeholder="Convênio"
              formControlName="covenantIds"
              [items]="covenants"
              bindLabel="name"
              bindValue="id"
            >
            </ng-select>
            <p
              *ngIf="filterGroup.controls.covenantIds.errors?.required"
              class="fild-invalid"
            >
              Necessário informar o Convênio!
            </p>
          </div>
          <div class="col">
            <label style="font-size: 14px">Início</label>
            <input
              type="date"
              class="form-control"
              formControlName="dateStart"
            />
            <p
              *ngIf="filterGroup.controls.dateStart.errors?.required"
              class="fild-invalid"
            >
              Necessário informar data de início!
            </p>
          </div>
          <div class="col">
            <label style="font-size: 14px">Fim</label>
            <input type="date" class="form-control" formControlName="dateEnd" />
            <p
              *ngIf="filterGroup.controls.dateEnd.errors?.required"
              class="fild-invalid"
            >
              Necessário informar data final!
            </p>
          </div>
          <div class="col">
            <label for="">&nbsp;</label>
            <button (click)="getRuns()" class="btn btn-info" title="Buscar">
              <i
                class="fa fa-search fa-lg trip-request__button__search"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div class="d-block chart mt-5 container text-center">
        <h3 class="page-title">
          Valores por Motorista <br />
          {{ driver?.name }}
        </h3>
        <canvas
          baseChart
          class="chart"
          [labels]="barChartData.labels"
          [datasets]="barChartData.datasets"
          [options]="barChartOptions"
          [legend]="barLegend"
          [chartType]="barChartType"
        >
        </canvas>
      </div>
    </div>
  </div>
</div>
