import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Pagination } from 'src/app/domain/pagination';
import { CarFine } from 'src/app/domain/car-fine';
import { CarFineDTO } from 'src/app/domain/dto/car-fine.dto';

@Injectable({
  providedIn: 'root',
})
export class CarFineService {
  url: string = `${environment.BASE_URL}/vehicles`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

  constructor(private http: HttpClient) {}

  getCarFines(carId: number, filter: any, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      ...filter,
    };

    return this.http.get(`${this.url}/${carId}/traffic-tickets`, {
      responseType: 'json',
      params: params,
    });
  }

  getCarFine(carId: number, id: number) {
    return this.http.get<CarFine>(
      `${this.url}/${carId}/traffic-tickets/${id}`,
      { responseType: 'json' }
    );
  }

  save(carId: number, carFine: CarFine) {
    let dto = new CarFineDTO(carFine);

    return this.http.post(
      `${this.url}/${carId}/traffic-tickets`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  update(carId: number, carFine: CarFine) {
    let dto = new CarFineDTO(carFine);
    return this.http.put(
      `${this.url}/${carId}/traffic-tickets/${carFine.id}`,
      JSON.stringify(dto),
      {
        headers: this.httpHeaders,
        observe: 'response',
      }
    );
  }

  delete(carId: number, id: number) {
    return this.http.delete(`${this.url}/${carId}/traffic-tickets/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
