import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-angular',
  templateUrl: './help-angular.component.html',
  styleUrls: ['./help-angular.component.css']
})
export class HelpAngularComponent implements OnInit {
  title = 'Golden Taxi';
  constructor() { }

  ngOnInit(): void {
  }

}
