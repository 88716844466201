import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Insurer } from 'src/app/domain/insurer';
import { InsurerService } from 'src/app/service/insurer/insurer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from '../../validations';

@Component({
  selector: 'app-insurer-update',
  templateUrl: './insurer-update.component.html',
  styleUrls: ['./insurer-update.component.css']
})
export class InsurerUpdateComponent implements OnInit {

  insurerForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";
  insurer: Insurer = new Insurer();

  constructor(
    private service: InsurerService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.insurer.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();


    this.service.getInsurer(this.insurer.id).subscribe(data => {
      this.insurer = data;
      this.setValues();
    }, error => {
      this.loading = false;
      Swal.fire('Erro', 'Não foi possível Buscar Peça!', 'error');
    });

  }
  createForm() {
    this.insurerForm = this.fb.group({
      name: ['', [Validators.required]],
      contact: ['',],
      email: ['',],
      phone: ['', [Validators.required, Validations.validPhone]],
      whatsapp: ['', [Validations.validwhatsapp]],
      active: ['', []]
    });
  }
  setValues() {
    this.insurerForm.controls.name.setValue(this.insurer.name);
    this.insurerForm.controls.contact.setValue(this.insurer.contact);
    this.insurerForm.controls.email.setValue(this.insurer.email);
    this.insurerForm.controls.phone.setValue(this.insurer.phone);
    this.insurerForm.controls.whatsapp.setValue(this.insurer.whatsapp);
    this.insurerForm.controls.active.setValue(`${this.insurer.active}`);
  }

  onSubmit() {
    this.insurer.name = this.insurerForm.controls.name.value;
    this.insurer.contact = this.insurerForm.controls.contact.value;
    this.insurer.email = this.insurerForm.controls.email.value;
    this.insurer.phone = this.insurerForm.controls.phone.value;
    this.insurer.whatsapp = this.insurerForm.controls.whatsapp.value;

    if (this.insurerForm.controls.active.value == "true") {
      this.insurer.active = true
    } else {
      this.insurer.active = false;
    }
    this.loading = true;
    this.service.update(this.insurer).subscribe(data => {
      this.loading = false;
      Swal.fire('Editado', 'Seguradora editada com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/insurer']);
        }
      });
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao editar', 'Não foi possível editar Seguradora!', 'error');
      });
  }

  setErrors(errors) {
    errors.forEach(erro => {
      console.log(erro);

      switch (erro.fieldName) {
        case "name":
          this.insurerForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "contact":
          this.insurerForm.controls.contact.setErrors({ invalid: erro.message });
          break;
        case "email":
          this.insurerForm.controls.email.setErrors({ invalid: erro.message });
          break;
        case "phone":
          this.insurerForm.controls.phone.setErrors({ invalid: erro.message });
          break;
        case "whatsapp":
          this.insurerForm.controls.whatsapp.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }

}
