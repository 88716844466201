import { CreditCard } from '../credit-card';

export class CreditCardDTO {

    number: number;
    active: boolean;

    constructor(creditCard: CreditCard) {
        this.number = creditCard.number;
        this.active = creditCard.active;
    }
}