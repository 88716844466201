<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading"> </ngx-loading>
<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="card card-topline-green">
            <div class="card-head">
              <header>Visualizar Seguradora</header>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Nome</b>
                    </div>
                    <div class="col-md-12">
                      {{ insurer.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Contato</b>
                    </div>
                    <div class="col-md-12">
                      {{ insurer.contact }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Email</b>
                    </div>
                    <div class="col-md-12">
                      {{ insurer.email }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Telefone</b>
                    </div>
                    <div class="col-md-12">
                      {{
                        insurer.phone | mask: "(00) 0000-0000||(00) 0 0000-0000"
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Whatsapp</b>
                    </div>
                    <div class="col-md-12">
                      {{ insurer.whatsapp | mask: "+00 00 0 0000-0000" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Status</b>
                    </div>
                    <div class="col-md-12">
                      <span
                        *ngIf="insurer.active"
                        class="label label-sucess label-mini"
                        >Ativo</span
                      >
                      <span
                        *ngIf="!insurer.active"
                        class="label label-danger label-mini"
                        >Inativo</span
                      >
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-4">
                  <a
                    routerLink="/parameters/insurer/edit/{{ insurer.id }}"
                    class="btn btn-warning"
                    >Editar</a
                  >
                </div>
                <div class="col-md-4">
                  <a routerLink="/parameters/insurer" class="btn btn-info">Voltar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
