import { GasStation } from '../gas-station';
import { AddressDTO } from './address.dto';

export class GasStationDTO {

    name: string;
    phone: string;
    whatsapp: string;
    contact: string;
    email: string;
    address: AddressDTO;
    active: boolean;

    constructor(gasStation: GasStation) {
        this.name = gasStation.name;
        this.phone = gasStation.phone;
        this.whatsapp = gasStation.whatsapp;
        this.contact = gasStation.contact;
        this.email = gasStation.email;
        this.active = gasStation.active;
        this.address = new AddressDTO(gasStation.address);
    }
}