import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FinancialParameter } from 'src/app/domain/financial-parameter';
import { EnumFinancialType } from 'src/app/enumerations/financial-type';
import { EnumParameterType } from 'src/app/enumerations/parameter-type';
import { FinancialParameterService } from 'src/app/service/financial-parameter/financial-parameter.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-financial-parameter-view',
  templateUrl: './financial-parameter-view.component.html',
  styleUrls: ['./financial-parameter-view.component.css']
})
export class FinancialParameterViewComponent implements OnInit {
  id: number;
  financialParameter: FinancialParameter = new FinancialParameter();
  loading: boolean = true;

  constructor(
    private service: FinancialParameterService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getFinancialParameters();
  }

  getFinancialParameters() {
    this.loading = true;
    this.service.getFinancialParameter(this.id).subscribe(data => {
      this.financialParameter = data;
      this.financialParameter.type = EnumFinancialType[this.financialParameter.type]
      this.financialParameter.parameter = EnumParameterType[this.financialParameter.parameter]
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Parâmetro Financeiro!', 'error');
  }
}
