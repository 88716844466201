import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from 'src/app/service/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardCovenantService implements CanActivate {

  constructor(
    public auth: LoginService,
    public router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | import("rxjs").Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/convenio/login']);
      return false;
    }
    return true;
  }
}
