import { CarBrand } from './car-brand';

export class CarModel {
  id: number;
  name: string;
  vehicleBrand: CarBrand;
  brand: CarBrand;

  constructor() {
    this.brand = new CarBrand();
    this.vehicleBrand = new CarBrand();
  }
}
