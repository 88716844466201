import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TripRequest } from 'src/app/domain/trip-request';
import { TripRequestSchedule } from 'src/app/domain/trip-request-schedule';
import { CovenantRunRequestService } from 'src/app/service/covenant-run-request/covenant-run-request.service';
import { TripRequestService } from 'src/app/service/trip-request/trip-request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-dashboard-view',
  templateUrl: './covenant-dashboard-view.component.html',
  styleUrls: ['./covenant-dashboard-view.component.css']
})
export class CovenantDashboardViewComponent implements OnInit {

  id: number;
  tripRequestSchedule: TripRequestSchedule = new TripRequestSchedule();
  loading: boolean = true;

  constructor(
    private generalService: TripRequestService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getTripRequestSchedule();
  }

  getTripRequestSchedule() {
    this.loading = true;
    this.generalService.getScheduleByTripRequest(this.id).subscribe(data => {
      this.tripRequestSchedule = data;
      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertScheduleError();
      });
  }

  openAlertScheduleError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados do agendamento!', 'error');
  }

}
