import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-payment-driver-update',
  templateUrl: './payment-driver-update.component.html',
  styleUrls: ['./payment-driver-update.component.css']
})
export class PaymentDriverUpdateComponent implements OnInit {


  form: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.createForm()
  }

  onSubmit() {
    console.log(this.form.value)
  }

  createForm() {
    this.form = new FormGroup({
      initialDate: new FormControl(),
      finalDate: new FormControl(),
      driver: new FormControl(),
      description: new FormControl(),
      releaseType: new FormControl(),
      value: new FormControl(),
      paymentType: new FormControl(),
      bank: new FormControl()
    })
  }

}
