<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container bg-white p-4">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Alerta</div>
            </div>
          </div>
          <div [formGroup]="alertForm" class="mt-5">
            <!--div com o form -->
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    formControlName="name"
                    placeholder="Nome do Alerta"
                  />
                  <span
                    *ngIf="
                      alertForm.controls.name.errors &&
                      alertForm.controls.name.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      alertForm.controls.name.errors &&
                      alertForm.controls.name.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Nome da Peça deve ter no máximo 100 caracteres</span
                  >
                  <span
                    *ngIf="
                      alertForm.controls.name.errors &&
                      alertForm.controls.name.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ alertForm.controls.name.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="variable">Variável</label>
                  <input
                    type="number"
                    class="form-control"
                    id="variable"
                    formControlName="variable"
                    placeholder="Variável"
                  />
                  <span *ngIf="alertForm.controls.variable.errors">
                    <span
                      *ngIf="alertForm.controls.variable.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !alertForm.controls.variable.errors.required &&
                        alertForm.controls.variable.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ alertForm.controls.variable.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">Tipo de Alerta </label>

                  <select
                    class="custom-select select2"
                    formControlName="alertType"
                  >
                    <option value="">Selecione o Tipo de Alerta</option>
                    <option
                      value="{{ alertType.id }}"
                      *ngFor="let alertType of alertTypes"
                    >
                      {{ alertType.name }}
                    </option>
                  </select>
                  <span
                    *ngIf="
                      alertForm.controls.alertType.errors &&
                      alertForm.controls.alertType.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      alertForm.controls.alertType.errors &&
                      alertForm.controls.alertType.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ alertForm.controls.alertType.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Tipo da Origem </label>

                  <select
                    class="custom-select select2"
                    formControlName="alertOrigin"
                    (change)="clearOption()"
                  >
                    <option value="">Selecione o Tipo da Origem</option>
                    <option value="convenant"> Convênio </option>
                    <option value="bills-to-pay"> Contas a Pagar </option>
                    <option value="bills-to-receive"> Contas a receber </option>
                    <option value="driver"> Motorista </option>
                    <option value="gas-station"> Posto de Combustível </option>
                    <option value="vehicle"> Veículo </option>
                  </select>
                  <span
                    *ngIf="
                      alertForm.controls.alertOrigin.errors &&
                      alertForm.controls.alertOrigin.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      alertForm.controls.alertOrigin.errors &&
                      alertForm.controls.alertOrigin.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ alertForm.controls.alertOrigin.errors.invalid }}
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label"> Opções </label>

                  <select
                    class="custom-select select2"
                    formControlName="originNotificationId"
                  >
                    <option value="">Selecione uma opção</option>
                    <option *ngIf="alertForm.get('alertOrigin').value === 'convenant'" value="1"> Data de Vencimento do Valor da Corrida </option>
                    <option *ngIf="alertForm.get('alertOrigin').value === 'bills-to-pay'" value="2"> Data de Vencimento da Conta a Pagar </option>
                    <option *ngIf="alertForm.get('alertOrigin').value === 'bills-to-receive'" value="3"> Data de Vencimento da Conta a Receber </option>
                    <option *ngIf="alertForm.get('alertOrigin').value === 'driver'" value="4"> Data de Vencimento da CNH do Motorista </option>
                    <option *ngIf="alertForm.get('alertOrigin').value === 'gas-station'" value="5"> Término do Crédito </option>
                    <option *ngIf="alertForm.get('alertOrigin').value === 'vehicle'" value="6"> Data do Vencimento do Seguro do Veículo </option>
                    <option *ngIf="alertForm.get('alertOrigin').value === 'vehicle'" value="7"> Manutenção de peça </option>
                  </select>
                  <span
                    *ngIf="
                      alertForm.controls.originNotificationId.errors &&
                      alertForm.controls.originNotificationId.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      alertForm.controls.originNotificationId.errors &&
                      alertForm.controls.originNotificationId.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ alertForm.controls.originNotificationId.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-12 col-md-6 d-block mt-3">
                <label class="control-label">Origem do alerta</label>
                <div class="d-flex">
                  <input
                    type="radio"
                    name="alertOrigin"
                    id="cv"
                    formControlName="alertOrigin"
                    value="convenant"
                  />
                  <label for="cv" class="ml-3">Convênio</label>
                </div>
                <div class="d-flex">
                  <input
                    type="radio"
                    name="alertOrigin"
                    id="cp"
                    formControlName="alertOrigin"
                    value="bills-to-pay"
                  />
                  <label for="cp" class="ml-3">Contas a pagar</label>
                </div>
                <div class="d-flex">
                  <input
                    type="radio"
                    name="alertOrigin"
                    id="cr"
                    formControlName="alertOrigin"
                    value="bills-to-receive"
                  />
                  <label for="cr" class="ml-3">Contas a receber</label>
                </div>
                <div class="d-flex">
                  <input
                    type="radio"
                    name="alertOrigin"
                    id="mot"
                    formControlName="alertOrigin"
                    value="driver"
                  />
                  <label for="mot" class="ml-3">Motorista</label>
                </div>
                <div class="d-flex">
                  <input
                    type="radio"
                    name="alertOrigin"
                    id="ve"
                    formControlName="alertOrigin"
                    value="vehicle"
                  />
                  <label for="ve" class="ml-3">Veículo</label>
                </div>
              </div>

              <div class="col-12 col-md-6 d-block mt-3" formGroupName="options">
                <label class="control-label">Opções</label>
                <div
                  class="d-flex"
                  *ngIf="alertForm.get('alertOrigin').value === 'convenant'"
                >
                  <input
                    type="checkbox"
                    name="alert-options"
                    id="convenant"
                    formControlName="dueSolicitationDate"
                  />
                  <label class="ml-3" for="convenant"
                    >Data de vencimento do Valor da Solicitação</label
                  >
                </div>
                <div
                  class="d-flex"
                  *ngIf="alertForm.get('alertOrigin').value === 'bills-to-pay'"
                >
                  <input
                    type="checkbox"
                    name="alert-options"
                    id="bills-to-pay"
                    formControlName="dueDateBillsToPay"
                  />
                  <label class="ml-3" for="bills-to-pay"
                    >Data de vencimento da Conta a Pagar</label
                  >
                </div>
                <div
                  class="d-flex"
                  *ngIf="
                    alertForm.get('alertOrigin').value === 'bills-to-receive'
                  "
                >
                  <input
                    type="checkbox"
                    name="alert-options"
                    id="bills-to-receive"
                    formControlName="dueDateBillsToReceive"
                  />
                  <label class="ml-3" for="bills-to-receive"
                    >Data de vencimento da Conta a Receber</label
                  >
                </div>
                <div
                  class="d-flex"
                  *ngIf="alertForm.get('alertOrigin').value === 'driver'"
                >
                  <input
                    type="checkbox"
                    name="alert-options"
                    id="driver"
                    formControlName="dueDateCnhDriver"
                  />
                  <label class="ml-3" for="driver"
                    >Data de vencimento da CNH do motorista</label
                  >
                </div>
                <div
                  class="d-flex"
                  *ngIf="alertForm.get('alertOrigin').value === 'vehicle'"
                >
                  <input
                    type="checkbox"
                    name="alert-options"
                    id="vehicle-security"
                    formControlName="dueDateSecurityVehicle"
                  />
                  <label class="ml-3" for="vehicle-security"
                    >Data de vencimento do Seguro do Veículo</label
                  >
                </div>
                <div
                  class="d-flex"
                  *ngIf="alertForm.get('alertOrigin').value === 'vehicle'"
                >
                  <input
                    type="checkbox"
                    name="alert-options"
                    id="piece-maintence"
                    formControlName="maintenancePiece"
                  />
                  <label class="ml-3" for="piece-maintence"
                    >Manutenção de Peça</label
                  >
                </div>-->
              <!-- </div>  -->
            <!-- </div> -->

            <div class="row d-flex justify-content-center my-4">
              <div class="m-2">
                <button (click)="onSubmit()" class="btn btn-success">
                  Salvar
                </button>
              </div>
              <div class="m-2">
                <a routerLink="/parameters/alert" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
