<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Contas a Pagar</div>
            </div>
            <div class="col-md-2">
              <a
                type="button"
                routerLink="/financial/bills-to-pay/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                >Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right">
                <select class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="">Mostrar Todos os Resultados</option>
                </select>
              </div>
              <header>
                <div [formGroup]="searchForm" class="row p-2">
                  <div class="row">
                    <div class="col-12 col-md-6 col-lg-3 mt-2">
                      <label style="font-size: 14px">Inicio Vencimento</label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="due_date_start"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 mt-2">
                      <label style="font-size: 14px">Fim Vencimento</label>
                      <input
                        type="date"
                        class="form-control"
                        formControlName="due_date_end"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 mt-2">
                      <label style="font-size: 14px">Valor inicial</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value_start"
                        currencyMask
                        [options]="{
                          prefix: 'R$ ',
                          thousands: '.',
                          decimal: ','
                        }"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 mt-2">
                      <label style="font-size: 14px">Valor final</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="value_end"
                        currencyMask
                        [options]="{
                          prefix: 'R$ ',
                          thousands: '.',
                          decimal: ','
                        }"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 mt-2">
                      <label style="font-size: 14px">Fornecedor</label>
                      <ng-select
                        class="custom-select"
                        style="font-size: 1rem; font-weight: normal"
                        notFoundText="Nenhum item encontrado"
                        placeholder="Fornecedor"
                        formControlName="provider"
                        [items]="providers"
                        bindLabel="name"
                        bindValue="id"
                      >
                      </ng-select>
                    </div>
                    <!-- <div class="col-md-2">
                      <label style="font-size: 14px">Centro de Custo</label>
                      <select
                        class="custom-select custom-select"
                        style="font-size: 14px"
                        formControlName="cost_center"
                      >
                        <option value="">Centro de Custo</option>
                        <option
                          *ngFor="let costCenter of costCenters"
                          value="{{ costCenter.id }}"
                        >
                          {{ costCenter.name }}
                        </option>
                      </select>
                    </div> -->
                    <div class="col-12 col-md-6 col-lg-3 mt-2">
                      <label style="font-size: 14px">Status</label>
                      <select
                        class="custom-select custom-select"
                        style="font-size: 14px"
                        formControlName="paid"
                      >
                        <option value="null" selected>Status</option>
                        <option value="true">Pago</option>
                        <option value="false">A Pagar</option>
                      </select>
                    </div>
                    <div class="col mt-3">
                      <a class="btn btn-info" (click)="onSearch()">
                        <i class="fa fa-search"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div class="card-body">
              <div class="table-responsive table-mobile">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <!-- <th> Código </th>   -->
                      <th>Fornecedor</th>
                      <!-- <th>Centro de Custo</th> -->
                      <th>Vencimento</th>
                      <th>Valor</th>
                      <th>Parcelas</th>
                      <th>Tipo de Pagamento</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let billsToPay of billsToPays">
                      <!-- <td>{{ billsToPay.id }}</td> -->
                      <td>{{ billsToPay.provider?.name }}</td>
                      <!-- <td>{{ billsToPay.costCenter?.name }}</td> -->
                      <td>
                        {{ billsToPay.dueDate | date: "dd/MM/yyyy":"GMT" }}
                      </td>
                      <td>{{ billsToPay.value | currency: "BRL" }}</td>
                      <td *ngIf="billsToPay.parcels?.length">
                        {{ billsToPay.parcels.length }} X
                        {{ billsToPay.parcels[0].value | currency: "BRL" }}
                      </td>
                      <td *ngIf="!billsToPay.parcels?.length"></td>
                      <td>
                        {{ this.paymentMethods[billsToPay.paymentMethod] }}
                      </td>
                      <td>
                        <span *ngIf="billsToPay.paid">Pago</span>
                        <span *ngIf="!billsToPay.paid">A Pagar</span>
                      </td>
                      <td>
                        <div class="row">
                          <div class="col">
                            <a
                              routerLink="/financial/bills-to-pay/view/{{
                                billsToPay.id
                              }}"
                              class="btn btn-primary btn-xs"
                              alt="Visualizar"
                            >
                              <i class="fa fa-eye"></i>
                            </a>
                            <a
                              routerLink="/financial/bills-to-pay/edit/{{
                                billsToPay.id
                              }}"
                              class="btn btn-warning btn-xs"
                            >
                              <i class="fa fa-pencil"></i>
                            </a>

                            <a
                              *ngIf="billsToPay.parcels.length"
                              routerLink="/financial/bills-to-pay/update-parcels/{{
                                billsToPay.id
                              }}"
                              class="btn btn-info btn-xs"
                            >
                              <i class="fa fa-list"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [class.disabled]="!first">
                <button class="page-link" (click)="onFirst()" tabindex="-1">
                  Primeira
                </button>
              </li>
              <li class="page-item" *ngIf="pagination.page != 1">
                <button (click)="onPrevious()" class="page-link">
                  {{ pagination.page - 1 }}
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">{{ pagination.page }}</button>
              </li>
              <li class="page-item" *ngIf="last">
                <button (click)="onNext()" class="page-link" href="#">
                  {{ pagination.page + 1 }}
                </button>
              </li>
              <li class="page-item" [class.disabled]="!last">
                <button class="page-link" (click)="onLast()">Última</button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
