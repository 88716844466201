import { Component, OnInit } from '@angular/core';
import { RequestValue } from 'src/app/domain/request-value';
import { Pagination } from 'src/app/domain/pagination';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RequestValueService } from 'src/app/service/request-value/request-value.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EnumRequestValueType } from 'src/app/enumerations/request-value-type';

@Component({
  selector: 'app-request-value',
  templateUrl: './request-value.component.html',
  styleUrls: ['./request-value.component.css']
})
export class RequestValueComponent implements OnInit {


  requestValues: Array<RequestValue> = [];
  pagination: Pagination = new Pagination;
  loading: boolean = true;
  requestValue: RequestValue = new RequestValue();
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;


  constructor(
    private service: RequestValueService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {

    this.route.queryParams.subscribe(params => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
      this.requestValue.destiny = params.destiny ?? "";
      this.requestValue.active = params.active ?? null;
      this.requestValue.type = params.type ?? null;
    });
  }

  ngOnInit(): void {
    // TODO - Buscar estados no banco
    this.createForm();
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getRequestValues();

  }

  getRequestValues() {
    this.loading = true;
    this.service.getRequestValues(this.requestValue, this.pagination).subscribe(data => {
      this.requestValues = data['results'].map((requestValue: RequestValue) => {
        requestValue.type = EnumRequestValueType[requestValue.type];
        return requestValue;
      });
      this.setPagination(data);

      this.loading = false;
    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? this.first = false : this.first = true;
    data['totalPages'] <= this.pagination.page ? this.last = false : this.last = true;
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page]
    });

    this.searchForm = this.fb.group({
      destiny: [this.requestValue.destiny],
      initialHour: [this.requestValue.initialHour],
      endHour: [this.requestValue.endHour],
      active: [this.requestValue.active],
      type: [this.requestValue.type],
    });
  }

  getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  onSearch() {
    this.requestValue.destiny = this.searchForm.controls.destiny.value;
    this.requestValue.initialHour = this.searchForm.controls.initialHour.value;
    this.requestValue.endHour = this.searchForm.controls.endHour.value;

    switch (this.searchForm.controls.type.value) {
      case 'client': {
        this.requestValue.type = this.getEnumKeyByEnumValue(EnumRequestValueType, 'Cliente');
        break;
      }

      case 'covenant': {
        this.requestValue.type = this.getEnumKeyByEnumValue(EnumRequestValueType, 'Convênio');
        break;
      }

      default: {
        this.requestValue.type = null;
        break;
      }
    }

    switch (this.searchForm.controls.active.value) {
      case "true":
        this.requestValue.active = true;
        break;
      case "false":
        this.requestValue.active = false;
        break;
      default:
        this.requestValue.active = null;
        break;
    }

    console.log(this.requestValue)

    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onActive(requestValue: RequestValue) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar valor das corrida? ${requestValue.destiny}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(requestValue.id).subscribe(data => {
          Swal.fire({
            title: 'Ativado',
            text: `Valor da corrida ativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao ativar valor da corrida!', 'error');
          });
      }

    });
  }
  onDeActive(requestValue: RequestValue) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar valor da corrida? ${requestValue.destiny}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(requestValue.id).subscribe(data => {

          Swal.fire({
            title: 'Desativado',
            text: `Valor da corrida desativado com sucesso!`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            location.reload();
          });
        },
          error => {
            Swal.fire('Ops', 'Erro ao desativar valor da corrida!', 'error');
          });
      }

    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();

  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/parameters/request-value'], {
      queryParams: {
        destiny: this.requestValue.destiny ?? "",
        initialHour: this.requestValue.initialHour ?? "",
        endHour: this.requestValue.endHour ?? "",
        active: this.requestValue.active ?? "",
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
        type: this.requestValue.type ?? '',
      }
    });
    this.getRequestValues();
  }

}
