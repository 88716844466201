<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Conta a Receber - Marcar como paga</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <header style="width: 100%">
                <div class="row">
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Convênio/Cliente</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      billToPaid?.client?.name || billToPaid?.covenant?.name
                    }}</label>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 18px">CPF/CNPJ</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      billToPaid.client?.cpfCnpj ||
                        billToPaid.covenant?.cpfCnpj
                    }}</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Data de Vencimento</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      billToPaid.dueDate | date: "dd/MM/yyyy"
                    }}</label>
                  </div>
                  <div class="col-sm-3">
                    <label style="font-size: 18px">Valor</label>
                    <label style="font-size: 14px" class="ml-1 mt-1">{{
                      this.getTotalValue(billToPaid.runs)
                        | currency: "BRL"
                    }}</label>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      *ngIf="billToPaid.runs?.length"
                      class="table table-bordered mt-5"
                    >
                      <thead>
                        <tr>
                          <th>Corrida </th>
                          <th>Data</th>
                          <th>Hora</th>
                          <th>Origem</th>
                          <th>Destino</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let run of billToPaid.runs;"
                        >
                          <td>{{ run.id }}</td>
                          <td>
                            {{ run.date | date: "dd/MM/yyyy" }}
                          </td>
                          <td>
                              {{ run.startHour }}
                          </td>
                          <td>{{ run.origin }}</td>
                          <td>{{ run.destiny }}</td>
                          <td>{{ run.runEndValue | currency:'BRL':true }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-3"></div>
                  <div class="col-md-3 text-right">
                    <div class="col-md-4">
                      <a
                        (click)="paidGenerateBill()"
                        class="btn btn-danger"
                        >Marcar como paga</a
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <a routerLink="/financial/generate-bills-to-receive" class="btn btn-secondary"
                      >Cancelar</a
                    >
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
