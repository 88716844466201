<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="row">
                <div class="col-md-10">
                    <div class="page-title"> Dados do Convênio </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-topline-green">
                        <div class="container">
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="name">Contato</label>
                                        <input type="text" class="form-control" id="contact" formControlName="contact"
                                            placeholder="Contato">
                                        <span
                                            *ngIf="covenantDataForm.controls.contact.errors && covenantDataForm.controls.contact.errors.required"
                                            class="text-danger position-right">*Campo Obrigatório</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.contact.errors && covenantDataForm.controls.contact.errors.maxlength"
                                            class="text-danger position-right">*Contato deve ter no máximo 100
                                            caracteres</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.contact.errors && covenantDataForm.controls.contact.errors.invalid"
                                            class="text-danger position-right">*
                                            {{covenantDataForm.controls.contact.errors.invalid}} </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="name">E-mail Contato</label>
                                        <input type="text" class="form-control" id="emailContact"
                                            formControlName="emailContact" placeholder="E-mail Contato">
                                        <span *ngIf="covenantDataForm.controls.emailContact.errors">
                                            <span *ngIf="covenantDataForm.controls.emailContact.errors.required"
                                                class="text-danger position-right">*Campo
                                                Obrigatório</span>
                                            <span
                                                *ngIf="!covenantDataForm.controls.emailContact.errors.required && covenantDataForm.controls.emailContact.errors.maxlength"
                                                class="text-danger position-right">*Email deve ter
                                                no máximo 80 caracteres</span>
                                            <span
                                                *ngIf="!covenantDataForm.controls.emailContact.errors.maxlength && covenantDataForm.controls.emailContact.errors.email"
                                                class="text-danger position-right">*Não é um email válido!</span>
                                            <span
                                                *ngIf="!covenantDataForm.controls.emailContact.errors.email && covenantDataForm.controls.emailContact.errors.invalid"
                                                class="text-danger position-right">*
                                                {{covenantDataForm.controls.emailContact.errors.invalid}} </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="name">WhatsApp</label>
                                        <input type="text" class="form-control" mask="+00 00 0 0000-0000" id="whatsapp"
                                            formControlName="whatsapp" placeholder="WhatsApp">
                                        <span
                                            *ngIf="covenantDataForm.controls.whatsapp.errors && covenantDataForm.controls.whatsapp.errors.required"
                                            class="text-danger position-right">*Campo Obrigatório</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.whatsapp.errors && covenantDataForm.controls.whatsapp.errors.invalid"
                                            class="text-danger position-right">*
                                            {{covenantDataForm.controls.whatsapp.errors.invalid}} </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="name">Telefone</label>
                                        <input type="text" class="form-control" mask="(00) 0000-0000||(00) 0 0000-0000"
                                            id="phone" formControlName="phone" placeholder="Telefone">
                                        <span
                                            *ngIf="covenantDataForm.controls.phone.errors && covenantDataForm.controls.phone.errors.required"
                                            class="text-danger position-right">*Campo Obrigatório</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.phone.errors && covenantDataForm.controls.phone.errors.invalid"
                                            class="text-danger position-right">*
                                            {{covenantDataForm.controls.phone.errors.invalid}} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="zipCode">CEP</label>
                                        <input type="text" class="form-control cep" (blur)="onBlurCep($event)"
                                            id="zipCode" formControlName="zipCode" placeholder="CEP">

                                        <span
                                            *ngIf="covenantDataForm.controls.zipCode.errors && covenantDataForm.controls.zipCode.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.zipCode.errors && covenantDataForm.controls.zipCode.errors.maxlength"
                                            class="text-danger position-right">*CEP deve ter
                                            no máximo 11 caracteres</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.zipCode.errors && covenantDataForm.controls.zipCode.errors.invalid"
                                            class="text-danger position-right">*
                                            {{covenantDataForm.controls.zipCode.errors.invalid}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label for="neighborhood">Logradouro</label>
                                        <input type="text" class="form-control" id="neighborhood"
                                            formControlName="neighborhood" placeholder="Rua / Complemento / Número">
                                        <span
                                            *ngIf="covenantDataForm.controls.neighborhood.errors && covenantDataForm.controls.neighborhood.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.neighborhood.errors && covenantDataForm.controls.neighborhood.errors.invalid"
                                            class="text-danger position-right">*
                                            {{covenantDataForm.controls.neighborhood.errors.invalid}}
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="publicPlace">Bairro</label>
                                        <input type="text" class="form-control" id="publicPlace"
                                            formControlName="publicPlace" placeholder="Bairro">

                                        <span
                                            *ngIf="covenantDataForm.controls.publicPlace.errors && covenantDataForm.controls.publicPlace.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="covenantDataForm.controls.publicPlace.errors && covenantDataForm.controls.publicPlace.errors.invalid"
                                            class="text-danger position-right">*
                                            {{covenantDataForm.controls.publicPlace.errors.invalid}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Estado
                                        </label>

                                        <select (change)="onSelectedState($event)" class="form-control  select2"
                                            formControlName="state">
                                            <option value="">
                                                Selecione o Estado </option>
                                            <option value="{{ state.id }}" *ngFor="let state of states">
                                                {{ state.name }} </option>

                                        </select>
                                        <span
                                            *ngIf="covenantDataForm.controls.state.errors && covenantDataForm.controls.state.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label">Cidade
                                        </label>
                                        <select class="form-control  select2" formControlName="city">
                                            <option value="">
                                                Selecione a Cidade </option>
                                            <option value="{{ city.id }}" *ngFor="let city of cities">
                                                {{ city.name }} </option>

                                        </select>
                                        <span
                                            *ngIf="covenantDataForm.controls.city.errors && covenantDataForm.controls.city.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 mb-3 ml-3">
                                <div class="frame mt-3">
                                    <div class="col">
                                        <img class="client__image" src="assets/img/cliente.jpg">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <input type="file" placeholder="fileupload" formControlName="fileupload">
                            </div>
                            <div class="row mt-3 ml-3 mb-3">
                                <div class="col-md-3">
                                </div>
                                <div class="col-md-3 text-right">
                                    <button (click)="onSubmit()" [disabled]="!covenantDataForm.valid"
                                        class="btn btn-success">Salvar</button>
                                </div>
                                <div class="col-md-3">
                                    <a routerLink="/maintenance/covenant" class="btn btn-danger">Cancelar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title"> Usuários do Convênio </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-topline-green">
                        <header class="mt-4 ml-4">
                            <div [formGroup]="covenantUserForm">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name">Nome</label>
                                            <input type="text" class="form-control" id="name" formControlName="name"
                                                placeholder="Nome">
                                            <span
                                                *ngIf="covenantUserForm.controls.name.errors && covenantUserForm.controls.name.errors.maxlength"
                                                class="text-danger position-right">*Nome deve ter
                                                no máximo 100 caracteres</span>
                                            <span
                                                *ngIf="covenantUserForm.controls.name.errors && covenantUserForm.controls.name.errors.invalid"
                                                class="text-danger position-right">*
                                                {{covenantUserForm.controls.name.errors.invalid}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="email">E-mail</label>
                                            <input type="text" class="form-control" id="email" formControlName="email"
                                                placeholder="E-mail">
                                            <span
                                                *ngIf="covenantUserForm.controls.email.errors && covenantUserForm.controls.email.errors.maxlength"
                                                class="text-danger position-right">*E-mail deve ter
                                                no máximo 100 caracteres</span>
                                            <span
                                                *ngIf="covenantUserForm.controls.email.errors && covenantUserForm.controls.email.errors.invalid"
                                                class="text-danger position-right">*
                                                {{covenantUserForm.controls.email.errors.invalid}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="role">Cargo</label>
                                            <input type="text" class="form-control" id="role" formControlName="role"
                                                placeholder="Cargo">
                                            <span
                                                *ngIf="covenantUserForm.controls.role.errors && covenantUserForm.controls.role.errors.maxlength"
                                                class="text-danger position-right">*Cargo deve ter
                                                no máximo 100 caracteres</span>
                                            <span
                                                *ngIf="covenantUserForm.controls.role.errors && covenantUserForm.controls.role.errors.invalid"
                                                class="text-danger position-right">*
                                                {{covenantUserForm.controls.role.errors.invalid}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 text-center">

                                    <a type="button" (click)="onSubmitUser()"
                                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                                        Adicionar
                                    </a>
                                </div>
                            </div>
                        </header>

                        <div class="card-body ">
                            <div class="table-responsive-md">
                                <table class="table table-striped custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Cargo</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of users">
                                            <td>
                                                {{ user.name }}
                                            </td>
                                            <td>
                                                {{ user.email }}
                                            </td>
                                            <td>
                                                {{ user.role }}
                                            </td>
                                            <td>
                                                <!-- <a (click)="onUserEdit(user)" class="btn btn-warning btn-xs">
                                                    <i class="fa fa-pencil"></i>
                                                </a> -->
                                                <button (click)="onUserDelete(user)" class="btn btn-danger btn-xs">
                                                    <i class="fa fa-trash-o "></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-md-12 ">
                                        <span *ngIf="!users.length" class="label label-danger label-mini">
                                            Nenhum Usuário adicionado.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
