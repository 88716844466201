import { Address } from './address';

export class Client {
    id: number;
    name: string;
    cpfCnpj: string;
    birthDate: Date;
    email: string;
    phone: string;
    whatsapp: string;
    active: boolean;
    paymentMethods: Array<any>;
    address: Address;
    imageUrl: string;
}
