<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title"> Agendamentos </div>
                        </div>
                        <div class="col-md-2">
                            <a type="button" href="/scheduling/new"
                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Adicionar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-topline-green">
                        <div class="card-head">
                            <div class="col-md-4 position-right">
                                <select class="custom-select custom-select-sm">
                                    <option value="10">Mostrar 10 Resultados</option>
                                    <option value="20">Mostrar 20 Resultados</option>
                                    <option value="30">Mostrar 30 Resultados</option>
                                    <option value="">Mostrar Todos os Resultados</option>
                                </select>
                            </div>

                            <header>
                                <div class="row">
                                    <label class="mt-2" style="font-size: 16px;">
                                        Data
                                    </label>
                                    <div class="col">
                                        <input type="date" class="form-control scheduling__input--data"
                                            placeholder="Identificador" formControlName="date">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <label class="mt-2" style="font-size: 16px;">
                                        Status
                                    </label>
                                    <div class="col scheduling__input--status--line">
                                        <select class="custom-select custom-select-sm scheduling__input--status">
                                            <option value="">Selecione</option>
                                            <option value="">Aberta</option>
                                            <option value="">Fechada</option>
                                            <option value="">Cancelada</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-3 ml-3 mb-3">
                                    <div class="col-md-5 text-right">
                                        <button class="btn btn-success">Pesquisar</button>
                                    </div>
                                    <div class="col-md-5">
                                        <a href="/scheduling/new" class="btn btn-danger">Novo</a>
                                    </div>
                                </div>
                            </header>
                        </div>
                        <div class="card-body ">
                            <div class="table-responsive">
                                <table class="table table-striped custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Data</th>
                                            <th>Hora</th>
                                            <th>Passageiro/Cliente</th>
                                            <th>Origem</th>
                                            <th>Destino</th>
                                            <th>Status</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                1963411
                                            </td>
                                            <td>
                                                09/06/2020
                                            </td>
                                            <td>
                                                04:15
                                            </td>
                                            <td>
                                                Bianca, Lucas
                                            </td>
                                            <td>
                                                Residências
                                            </td>
                                            <td>
                                                BRAINFARMA
                                            </td>
                                            <td>
                                                Fechada
                                            </td>
                                            <td>
                                                <div class="row">
                                                    <a href="/scheduling/view/1" class="btn btn-primary btn-xs"
                                                        alt="Visualizar">
                                                        <i class="fa fa-eye"></i>
                                                    </a>
                                                    <a href="/scheduling/edit/1" class="btn btn-warning btn-xs">
                                                        <i class="fa fa-pencil"></i>
                                                    </a>
                                                    <a class="btn btn-danger btn-xs">
                                                        <i class="fa fa-trash-o "></i>
                                                    </a>

                                                    <a class="btn btn-success btn-xs">
                                                        <i class="fa fa-random"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">Mostrando página 1 de 1 dos
                    1 Resultados
                </div>
            </div>
        </div>
    </div>
</div>