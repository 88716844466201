<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title"> Centro de Custo </div>
            </div>
            <div class="col-md-2">
              <a type="button" routerLink="/financial/cost-center/new"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Adicionar
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-topline-green">
            <div class="card-head">
              <div class="col-md-4 position-right" [formGroup]="showResultsForm">
                <select (change)="onSelected($event)" formControlName="per_page" class="custom-select custom-select-sm">
                  <option value="10">Mostrar 10 Resultados</option>
                  <option value="20">Mostrar 20 Resultados</option>
                  <option value="30">Mostrar 30 Resultados</option>
                  <option value="{{ pagination.totalResults }}">Mostrar Todos os Resultados</option>
                </select>
              </div>
              <div class="container">
                <div class="row" [formGroup]="searchForm">
                  <div class="col-4">
                    <input type="text" class="form-control" placeholder="Nome" formControlName="name">
                  </div>
                  <div class="col-4">
                    <select formControlName="type" class="form-control">
                      <option value="null">Tipo</option>
                      <option value="credit">Crédito</option>
                      <option value="debit">Débito</option>
                    </select>
                  </div>
                  <div class="col-3">
                    <select formControlName="active" class="form-control">
                      <option value="null">Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                  <div class="col-1">
                    <a (click)="onSearch()" class="btn btn-info">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body ">
              <div class="table-responsive">
                <table class="table table-striped custom-table table-hover">
                  <thead>
                    <tr>
                      <th>Identificador</th>
                      <th>Nome</th>
                      <th>Tipo</th>
                      <th>Pai</th>
                      <th>Centro de Custo</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let costcenter of costcenters">
                      <td>
                        {{costcenter.identifier}}
                      </td>
                      <td>
                        {{costcenter.name}}
                      </td>
                      <td>
                        {{costcenter.type}}
                      </td>
                      <td>
                        <span span *ngIf="!costcenter.parent">Sim</span>
                        <span span *ngIf="costcenter.parent">Não</span>
                      </td>
                      <td>
                        <!-- <span *ngIf="!costcenter.parent">Centro de Custo Pai</span> -->
                        <span span *ngIf="costcenter.parent">{{ costcenter.parent.name }}</span>
                      </td>
                      <td>
                        <span *ngIf="costcenter.active" class="label label-sucess label-mini">Ativo</span>
                        <span *ngIf="!costcenter.active" class="label label-danger label-mini">Inativo</span>
                      </td>
                      <td>
                        <div class="row">
                          <a routerLink="/financial/cost-center/view/{{costcenter.id}}" class="btn btn-primary btn-xs" alt="Visualizar">
                            <i class="fa fa-eye"></i>
                          </a>
                          <a routerLink="/financial/cost-center/edit/{{costcenter.id}}" class="btn btn-warning btn-xs">
                            <i class="fa fa-pencil"></i>
                          </a>
                          <span *ngIf="costcenter.active">
                            <button (click)="onDeActive(costcenter)" class="btn btn-pink btn-xs">
                              <i class="fa fa-random"></i>
                            </button>
                          </span>
                          <span *ngIf="!costcenter.active">
                            <button (click)="onActive(costcenter)" class="btn btn-success btn-xs">
                              <i class="fa fa-random"></i>
                            </button>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          Mostrando página {{ pagination.page }} de
          {{ pagination.totalPages }} dos
          {{ pagination.totalResults }} Resultados
        </div>
      </div>
    </div>
  </div>
</div>
