import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { Pagination } from 'src/app/domain/pagination';
import { Passenger } from 'src/app/domain/passenger';
import { EnumStatusRun } from 'src/app/enumerations/status-run';
import { RunService } from 'src/app/service/run/run.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-runs-recents',
  templateUrl: './recents.component.html',
  styleUrls: ['./recents.component.css'],
})
export class RecentsComponent implements OnInit {
  now = moment().format('DD/MM/yyyy');
  loading = false;
  runs = [];
  filter = {
    dateStart: moment().format('yyyy-MM-DD'),
    dateEnd: moment().format('yyyy-MM-DD'),
    hasDriverLinked: 'false',
    status: 'scheduled',
  };
  pagination: Pagination = new Pagination();

  constructor(private service: RunService) {
    this.pagination.per_page = 5;
    this.pagination.page = 1;
  }

  ngOnInit(): void {
    this.getRuns();
  }

  getRuns() {
    this.loading = true;

    this.service.getRunsBackoffice(this.filter, this.pagination).subscribe(
      (data) => {
        this.runs = data['results'].map((Run, index, array) => {
          Run.status = EnumStatusRun[Run.status];
          Run.runPassengers?.forEach(({ isMain, origin, destiny }) => {
            if (isMain && origin) {
              Run.origin = origin.city.name;
              Run.destiny = destiny.city.name;
            }
          });
          return Run;
        });
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getPassengersName(passengers: Array<Passenger>) {
    const names = [];
    passengers.forEach(({ client, collaborator }) => {
      const _name = client ? client.name : collaborator.name;
      names.push(_name);
    });
    return names.join('<br>');
  }

  cancelRun(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Cancelar Corrida?',
      confirmButtonText: `Confirmar`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.cancel(id).subscribe(
          (data) => {
            Swal.fire('Cancelado!', '', 'success');
            this.getRuns();
          },
          (error) => {
            Swal.fire('Erro ao Cancelar', '', 'error');
          }
        );
      }
    });
  }
}
