import { City } from './city';

export class Address {
  id: number;
  zipCode: string;
  publicPlace: string;
  neighborhood: string;
  city: City = new City();
  cityId: number;

  constructor(attrs?: Partial<Address>) {
    Object.assign(this, attrs);
    this.cityId = +(attrs?.cityId ?? attrs?.city?.id);
    this.city = new City(attrs?.city);
  }
}
