import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Pagination } from 'src/app/domain/pagination';
import { CarMaintenanceService } from 'src/app/service/car-maintenance/car-maintenance.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CarMaintenance } from 'src/app/domain/car-maintenance';
import { Car } from 'src/app/domain/car';
import { Provider } from 'src/app/domain/provider';
import { Piece } from 'src/app/domain/piece';
import { ProviderService } from 'src/app/service/provider/provider.service';
import { PieceService } from 'src/app/service/piece/piece.service';
import { CarService } from 'src/app/service/car/car.service';
import { Validations } from '../../validations';
import moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-car-maintenance',
  templateUrl: './car-maintenance.component.html',
  styleUrls: ['./car-maintenance.component.css'],
})
export class CarMaintenanceComponent implements OnInit {
  car: Car = new Car();
  carMaintenanceForm: FormGroup;
  searchCarMaintenancesForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  pagination: Pagination = new Pagination();
  carId: number;
  maintenances: Array<CarMaintenance> = [];
  providers: Array<Provider> = [];
  pieces: Array<Piece> = [];
  maintenancePieces: Array<Piece> = [];

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: CarMaintenanceService,
    private serviceCar: CarService,
    private serviceProvider: ProviderService,
    private servicePiece: PieceService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      this.carId = params['id'];
    });

    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.createFormSearch();
    this.getMaintenances();
    this.getProviders();
    this.getPieces();
    this.getCar();
  }

  getProviders() {
    this.loading = true;
    this.serviceProvider.getProvidersSelect().subscribe(
      (data) => {
        this.providers = data['results'];
      },
      (error) => {
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados de Fornecedores!',
          'error'
        );
      }
    );
  }

  createFormSearch() {
    this.searchCarMaintenancesForm = this.fb.group({
      start_date: ['', []],
      end_date: ['', []],
      providerId: ['', []],
      paid: ['', []],
    });
  }

  getPieces() {
    this.loading = true;
    this.servicePiece.getPiecesSelect().subscribe(
      (data) => {
        this.pieces = data;
      },
      (error) => {
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados de Peças!',
          'error'
        );
      }
    );
  }

  // ---------------- FUNÇÕES DE LISTAGEM DE MANUTENÇÕES --------------------
  getMaintenances() {
    this.loading = true;
    this.service
      .getCarMaintenances(
        this.carId,
        this.searchCarMaintenancesForm.getRawValue(),
        this.pagination
      )
      .subscribe(
        (data) => {
          this.maintenances = data['results'];
          this.setPagination(data);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.openAlertError();
        }
      );
  }

  getCar() {
    this.serviceCar.getCar(this.carId).subscribe(
      (data) => {
        this.car = data;
      },
      (error) => {
        Swal.fire(
          'Erro ao carregar dados',
          'Erro ao buscar dados do veículo!',
          'error'
        );
      }
    );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.getMaintenances();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.getMaintenances();
  }
  onPrevious() {
    this.pagination.page--;
    this.getMaintenances();
  }
  onNext() {
    this.pagination.page++;
    this.getMaintenances();
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar as manutenções!', 'error');
  }
  // ---------------- FUNÇÕES DE LISTAGEM DE MANUTENÇÕES --------------------

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE MANUTENÇÕES --------------------
  createForm() {
    this.carMaintenanceForm = this.fb.group({
      id: [''],
      fornecedor: ['', [Validators.required]],
      pecas: ['', [Validators.required]],
      data: ['', [Validators.required, Validations.validDateMoreThanToday]],
      valor: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validations.validValueMoreThanZero,
        ],
      ],
      pago: ['', [Validators.required]],
      odometer: ['', [Validators.required, Validators.maxLength(10)]],
    });

    this.maintenancePieces = [];
  }

  onMaintenanceEdit(maintenance: CarMaintenance) {
    this.carMaintenanceForm.patchValue({
      id: maintenance.id,
      fornecedor: maintenance.provider.id,
      data: maintenance.date,
      valor: maintenance.value,
      pago: maintenance.paid,
      odometer: maintenance.odometer,
    });

    this.maintenancePieces = maintenance.pieces;

    if (this.maintenancePieces.length) {
      this.carMaintenanceForm.controls.pecas.clearValidators();
      this.carMaintenanceForm.controls.pecas.clearAsyncValidators();
    }

    this.carMaintenanceForm.controls.pecas.updateValueAndValidity();
  }

  onSubmit() {
    this.messageError = '';
    const values = this.carMaintenanceForm.controls;

    let carMaintenance = new CarMaintenance();
    carMaintenance.provider = new Provider();

    carMaintenance.provider.id = values.fornecedor.value;

    carMaintenance.date = values.data.value;
    carMaintenance.value = values.valor.value;
    carMaintenance.paid = values.pago.value || false;
    carMaintenance.odometer = values.odometer.value;
    carMaintenance.pieces = this.maintenancePieces;

    this.loading = true;

    if (values.id.value) {
      carMaintenance.id = values.id.value;
      this.service.update(this.carId, carMaintenance).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire('Salvo', 'Manutenção salvo com sucesso!!', 'success');
          this.createForm();
          this.getMaintenances();
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Manutenção!',
            'error'
          );
        }
      );
      return;
    }

    this.service.save(this.carId, carMaintenance).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire('Salvo', 'Manutenção salvo com sucesso!!', 'success');
        this.createForm();
        this.getMaintenances();
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao salvar',
          'Não foi possível salvar Manutenção!',
          'error'
        );
      }
    );
  }

  onMaintenanceDelete(carMaintenance) {
    this.service.delete(this.carId, carMaintenance).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire('Excluido!', 'Manutenção excluída com sucesso!!', 'success');
        this.createForm();
        this.getMaintenances();
      },
      (error) => {
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao excluir',
          'Não foi possível excluir Manutenção!',
          'error'
        );
      }
    );
  }

  resetForm() {
    this.carMaintenanceForm.controls.id.setValue('');
    this.carMaintenanceForm.controls.fornecedor.setValue('');
    this.carMaintenanceForm.controls.pecas.setValue('');
    this.carMaintenanceForm.controls.data.setValue('');
    this.carMaintenanceForm.controls.valor.setValue('');
    this.carMaintenanceForm.controls.pago.setValue('');
    this.carMaintenanceForm.controls.odometer.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'fornecedor':
          this.carMaintenanceForm.controls.fornecedor.setErrors({
            invalid: erro.message,
          });
          break;
        case 'pecas':
          this.carMaintenanceForm.controls.pecas.setErrors({
            invalid: erro.message,
          });
          break;
        case 'data':
          this.carMaintenanceForm.controls.data.setErrors({
            invalid: erro.message,
          });
          break;
        case 'valor':
          this.carMaintenanceForm.controls.valor.setErrors({
            invalid: erro.message,
          });
          break;
        case 'pago':
          this.carMaintenanceForm.controls.pago.setErrors({
            invalid: erro.message,
          });
          break;
        case 'odometer':
          this.carMaintenanceForm.controls.odometer.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  exportCarMaintenance(exportType: string) {
    this.loading = true;

    this.service
      .exportCarMaintenances(
        this.searchCarMaintenancesForm,
        exportType,
        this.carId
      )
      .pipe(take(1))
      .subscribe(
        (response) => {
          const fileName = `Relatorio_Manutenção_${moment().format(
            'DDMMyyyyhmmss'
          )}.${exportType == 'excel' ? 'xlsx' : 'pdf'}`;
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(response.body);
          link.download = fileName;
          link.click();

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
        }
      );
  }

  onPieceAdd(pieceId: number) {
    const piece = this.pieces.find((piece) => piece.id === pieceId);
    this.maintenancePieces.push(piece);
  }

  onPieceDelete(i: number) {
    this.maintenancePieces.splice(i, 1);
    if (!this.maintenancePieces.length) {
      this.carMaintenanceForm.controls.pecas.setValidators([
        Validators.required,
      ]);
      this.carMaintenanceForm.controls.pecas.updateValueAndValidity();
    }
  }
  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE MANUTENÇÕES --------------------
}
