<div class="limiter">
   <div class="container-login100 page-background">
      <div class="wrap-login100">
         <div class="form-404">
            <span class="login100-form-logo">
               <img alt="" src="../../assets/img/logo_2.png">
            </span>
            <span class="form404-title p-b-34 p-t-27">
               Error 404
            </span>
            <p class="content-404">A página que você está procurando não existe.</p>
            <div class="container-login100-form-btn">
               <button (click)="onHomePage()" class="login100-form-btn">
                  Voltar para a Página Inicial
               </button>
            </div>
            <div class="text-center p-t-27">
               <!-- <a class="txt1" href="#">
                  Need Help?
               </a> -->
            </div>
         </div>
      </div>
   </div>
</div>