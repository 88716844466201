import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { WebsocketService } from 'src/app/service/websocket/websocket.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit {
  loading: boolean = true;

  notifications;
  constructor(
    private service: NotificationService,
    private wsService: WebsocketService,
    private router: Router,
  ) {
    // this.route.params.subscribe(params => {
    //   this.id = params['id']
    // });
  }

  ngOnInit(): void {
    this.loading = false;
    this.getNotification();

    this.wsService.getNewNotification().subscribe((notification: string) => {
      this.getNotification();
    });
  }

  getNotification() {
    this.service.getAllNotification().subscribe((response) => {
      this.notifications = response;
    });
  }

  setRead(id: number) {
    this.loading = true;
    this.service.updateToRead(id).subscribe((response) => {
      this.loading = false;
      this.wsService.sendNotification();
      Swal.fire('Salvo', 'A notificação foi marcada como lida!!', 'success');
    });
  }
}
