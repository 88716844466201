import { NgModule } from '@angular/core';
import { BaseChartDirective } from './base-chart.directive';

@NgModule({
  declarations: [
    BaseChartDirective
  ],
  imports: [
  ],
  exports: [
    BaseChartDirective
  ]
})
export class ChartsModule { }
