<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading">
</ngx-loading>

<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="page-title">Cadastrar Peça</div>
                        </div>
                    </div>
                    <div [formGroup]="pieceForm">
                        <!--div com o form -->
                        <div *ngIf="messageError.length" class="container">
                            <div class="alert alert-danger" role="alert">
                                {{messageError}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control" id="name" formControlName="name"
                                        placeholder="Nome da Peça">
                                    <span
                                        *ngIf="pieceForm.controls.name.errors && pieceForm.controls.name.errors.required"
                                        class="text-danger position-right">*Campo
                                        Obrigatório</span>
                                    <span
                                        *ngIf="pieceForm.controls.name.errors && pieceForm.controls.name.errors.maxlength"
                                        class="text-danger position-right">*Nome da
                                        Peça deve ter
                                        no máximo 100 caracteres</span>
                                    <span
                                        *ngIf="pieceForm.controls.name.errors && pieceForm.controls.name.errors.invalid"
                                        class="text-danger position-right">*
                                        {{pieceForm.controls.name.errors.invalid}} </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exchangeOdometer">Hodômetro da Troca</label>
                                    <input type="number" class="form-control" id="exchangeOdometer"
                                        formControlName="exchangeOdometer" placeholder="Hodômetro da Troca">
                                    <span *ngIf="pieceForm.controls.exchangeOdometer.errors">
                                        <span *ngIf="pieceForm.controls.exchangeOdometer.errors.required"
                                            class="text-danger position-right">*Campo
                                            Obrigatório</span>
                                        <span
                                            *ngIf="pieceForm.controls.exchangeOdometer.errors && pieceForm.controls.exchangeOdometer.errors.max"
                                            class="text-danger position-right">*Nome da
                                            Hodômetro deve ter
                                            no máximo 15 caracteres</span>
                                        <span
                                            *ngIf="!pieceForm.controls.exchangeOdometer.errors.required && pieceForm.controls.exchangeOdometer.errors.invalid"
                                            class="text-danger position-right">*
                                            {{pieceForm.controls.exchangeOdometer.errors.invalid}} </span>

                                    </span>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-3 text-right">
                                <button (click)="onSubmit()" [disabled]="!pieceForm.valid"
                                    class="btn btn-success">Salvar</button>
                            </div>
                            <div class="col-md-3">
                                <a routerLink="/parameters/piece" class="btn btn-danger">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
