import { Component, OnInit } from '@angular/core';
import { AccessProfileService } from 'src/app/service/access-profile/access-profile.service';
import { AccessProfile } from 'src/app/domain/access-profile';
import { Pagination } from 'src/app/domain/pagination';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-access-profile',
  templateUrl: './access-profile.component.html',
  styleUrls: ['./access-profile.component.css'],
})
export class AccessProfileComponent implements OnInit {
  accessProfiles: Array<AccessProfile> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  filter: string = '';
  active: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;

  first: boolean = false;
  last: boolean = false;

  constructor(
    private service: AccessProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      // this.filter = params.filter;
      params.per_page != null
        ? (this.pagination.per_page = params.per_page)
        : (this.pagination.per_page = 10);
      params.page != null
        ? (this.pagination.page = params.page)
        : (this.pagination.page = 1);
      params.filter != null
        ? (this.filter = params.filter)
        : (this.filter = '');
      params.active != null
        ? (this.active = params.active)
        : (this.active = null);
    });
  }

  ngOnInit(): void {
    this.createForm();
    // this.pagination.per_page = 10;
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getAccessProfiles();
  }
  getAccessProfiles() {
    this.loading = true;
    this.service
      .getAccessProfiles(
        this.filter,
        this.active ? this.active : '',
        this.pagination
      )
      .subscribe(
        (data) => {
          this.accessProfiles = data['results'];
          this.setPagination(data);

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.openAlertError();
        }
      );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      filter: [this.filter],
      active: [this.active],
    });
  }

  onSearch() {
    this.filter = this.searchForm.controls.filter.value;
    this.active =
      this.searchForm.controls.active.value != 'null'
        ? this.searchForm.controls.active.value
        : '';
    this.setNavigate();
  }
  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }
  onActive(accessProfile: AccessProfile) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar perfil de Acesso? ${accessProfile.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.active(accessProfile.id).subscribe(
          (data) => {
            // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');
            Swal.fire({
              title: 'Ativado',
              text: `Perfil de Acesso ativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire('Ops', 'Erro ao ativar Perfil de Acesso!', 'error');
          }
        );
      }
    });
  }
  onDeActive(accessProfile: AccessProfile) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar perfil de Acesso? ${accessProfile.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deactive(accessProfile.id).subscribe(
          (data) => {
            // Swal.fire('Ativado', 'Perfil de Acesso ativado com sucesso!', 'success');

            Swal.fire({
              title: 'Desativado',
              text: `Perfil de Acesso desativado com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire(
              'Erro ao desativar Perfil de Acesso!',
              `${error.error.errors[0].message}`,
              'error'
            );
          }
        );
      }
    });
  }
  onDelete(accessProfile: AccessProfile) {
    Swal.fire({
      title: 'Excluir perfil de acesso?',
      text: `Perfil: ${accessProfile.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero excluir!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.delete(accessProfile.id).subscribe(
          (data) => {
            // Swal.fire('Excluído', 'Perfil de Acesso excluído com sucesso!', 'success');
            Swal.fire({
              title: 'Excluído',
              text: `Perfil de Acesso excluído com sucesso!`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              location.reload();
            });
          },
          (error) => {
            Swal.fire(
              'Erro ao excluir Perfil de Acesso!',
              `${error.error.errors[0].message}`,
              'error'
            );
          }
        );
      }
    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    let getActive = this.active ? this.active : '';
    this.router.navigate(['/security/access-profile'], {
      queryParams: {
        filter: this.filter,
        active: getActive,
        page: this.pagination.page,
        per_page: this.pagination.per_page,
      },
    });
    this.getAccessProfiles();
  }
}
