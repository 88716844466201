<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="bg-white py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Seguradora</div>
            </div>
          </div>
          <div [formGroup]="insurerForm" class="mb-3">
            <!--div com o form -->
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    formControlName="name"
                    placeholder="Nome da Seguradora"
                  />
                  <span
                    *ngIf="
                      insurerForm.controls.name.errors &&
                      insurerForm.controls.name.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      insurerForm.controls.name.errors &&
                      insurerForm.controls.name.errors.maxlength
                    "
                    class="text-danger position-right"
                    >*Nome da Seguradora deve ter no máximo 100 caracteres</span
                  >
                  <span
                    *ngIf="
                      insurerForm.controls.name.errors &&
                      insurerForm.controls.name.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ insurerForm.controls.name.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="contact">Contato</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contact"
                    formControlName="contact"
                    placeholder="Contato"
                  />
                  <span *ngIf="insurerForm.controls.contact.errors">
                    <span
                      *ngIf="insurerForm.controls.contact.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !insurerForm.controls.contact.errors.required &&
                        insurerForm.controls.contact.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ insurerForm.controls.contact.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    placeholder="Email da Seguradora"
                  />
                  <span
                    *ngIf="
                      insurerForm.controls.email.errors &&
                      insurerForm.controls.email.errors.required
                    "
                    class="text-danger position-right"
                    >*Campo Obrigatório</span
                  >
                  <span
                    *ngIf="
                      insurerForm.controls.email.errors &&
                      insurerForm.controls.email.errors.email
                    "
                    class="text-danger position-right"
                    >*Formato inválido de email</span
                  >
                  <span
                    *ngIf="
                      insurerForm.controls.email.errors &&
                      insurerForm.controls.email.errors.invalid
                    "
                    class="text-danger position-right"
                    >* {{ insurerForm.controls.email.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="phone">Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    mask="(00) 0000-0000||(00) 0 0000-0000"
                    id="phone"
                    formControlName="phone"
                    placeholder="Telefone"
                  />
                  <span *ngIf="insurerForm.controls.phone.errors">
                    <span
                      *ngIf="insurerForm.controls.phone.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !insurerForm.controls.phone.errors.required &&
                        insurerForm.controls.phone.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ insurerForm.controls.phone.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="whatsapp">Whatsapp</label>
                  <input
                    type="text"
                    class="form-control"
                    mask="+00 00 0 0000-0000"
                    id="whatsapp"
                    formControlName="whatsapp"
                    placeholder="Whatsapp"
                  />
                  <span *ngIf="insurerForm.controls.whatsapp.errors">
                    <span
                      *ngIf="insurerForm.controls.whatsapp.errors.required"
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        !insurerForm.controls.whatsapp.errors.required &&
                        insurerForm.controls.whatsapp.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ insurerForm.controls.whatsapp.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="m-2">
                <button
                  (click)="onSubmit()"
                  [disabled]="!insurerForm.valid"
                  class="btn btn-success"
                >
                  Salvar
                </button>
              </div>
              <div class="m-2">
                <a routerLink="/parameters/insurer" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
