import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RequestValue } from 'src/app/domain/request-value';
import { Pagination } from 'src/app/domain/pagination';
import { RequestValueDTO } from 'src/app/domain/dto/resquest-value.dto';

@Injectable({
  providedIn: 'root'
})
export class RequestValueService {

  url: string = `${environment.BASE_URL}/request-values`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getRequestValues(requestValue: RequestValue, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      destiny: `${requestValue.destiny ?? ""}`,
      initialHour: `${requestValue.initialHour ?? ""}`,
      endHour: `${requestValue.endHour ?? ""}`,
      active: `${requestValue.active ?? ''}`,
      type: `${requestValue.type ?? ''}`
    };
    return this.http.get(this.url,
      { responseType: "json", params: params });
  }

  getRequestValue(id: number) {
    return this.http.get<RequestValue>(`${this.url}/${id}`,
      { responseType: "json" });
  }

  getAllActiveRequestValue() {
    return this.http.get<Array<RequestValue>>(`${this.url}/all-active`,
      { responseType: "json" });
  }

  getAllActiveRequestValueClient() {
    return this.http.get<Array<RequestValue>>(`${this.url}/client/all-active`,
      { responseType: "json" });
  }

  save(requestValue: RequestValue) {
    let dto = new RequestValueDTO(requestValue);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(requestValue: RequestValue) {
    let dto = new RequestValueDTO(requestValue);
    return this.http.put(`${this.url}/${requestValue.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
}
