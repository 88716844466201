import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GasStation } from 'src/app/domain/gas-station';
import { Pagination } from 'src/app/domain/pagination';
import { GasStationDTO } from 'src/app/domain/dto/gas-station.dto';

@Injectable({
  providedIn: 'root'
})
export class GasStationService {

  url: string = `${environment.BASE_URL}/gas-stations`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getGasStations(gasStation: GasStation, pagination: Pagination) {

    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
      name: `${gasStation.name ?? ""}`,
      contact: `${gasStation.contact ?? ""}`,
      state: `${gasStation.address.city.state.id ?? ""}`,
      city: `${gasStation.address.city.id ?? ""}`,
      active: `${gasStation.active ?? ''}`
    };

    return this.http.get(this.url,
      { responseType: "json", params: params });

  }

  getGasStationsSelect() {
    return this.http.get(this.url,
      { responseType: "json" });

  }


  getGasStation(id: number) {
    return this.http.get<GasStation>(`${this.url}/${id}`,
      { responseType: "json" });

  }
  save(gasStation: GasStation) {
    let dto = new GasStationDTO(gasStation);

    return this.http.post(`${this.url}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  listCredit(id: number, pagination: Pagination) {
    const params = {
      page: `${pagination.page ?? 1}`,
      per_page: `${pagination.per_page ?? 10}`,
    };

    return this.http.get(`${this.url}/${id}/credit`,
      { responseType: "json", params: params });

  }
  deleteCredit(id) {
    return this.http.delete(`${this.url}/credit/${id}`,
      { responseType: "json" });
  }
  saveCredit(data) {
    return this.http.post(`${this.url}/credit`, JSON.stringify(data), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  update(gasStation: GasStation) {
    let dto = new GasStationDTO(gasStation);
    console.log(JSON.stringify(dto));
    return this.http.put(`${this.url}/${gasStation.id}`, JSON.stringify(dto), {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  active(id: number) {
    return this.http.put(`${this.url}/${id}/active`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }
  deactive(id: number) {
    return this.http.put(`${this.url}/${id}/deactivate`, {
      headers: this.httpHeaders,
      observe: 'response',
    });
  }

}
