import { AbstractControl } from '@angular/forms';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import moment from 'moment';

export class Validations {
  // static ValidaCpf(controle: AbstractControl) {
  //    const cpf = controle.value;

  //    let soma: number = 0;
  //    let resto: number;
  //    let valido: boolean;

  //    const regex = new RegExp('[0-9]{11}');

  //    if (
  //       cpf == '00000000000' ||
  //       cpf == '11111111111' ||
  //       cpf == '22222222222' ||
  //       cpf == '33333333333' ||
  //       cpf == '44444444444' ||
  //       cpf == '55555555555' ||
  //       cpf == '66666666666' ||
  //       cpf == '77777777777' ||
  //       cpf == '88888888888' ||
  //       cpf == '99999999999' ||
  //       !regex.test(cpf)
  //    )
  //       valido = false;
  //    else {
  //       for (let i = 1; i <= 9; i++)
  //          soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  //       resto = (soma * 10) % 11;

  //       if (resto == 10 || resto == 11) resto = 0;
  //       if (resto != parseInt(cpf.substring(9, 10))) valido = false;

  //       soma = 0;
  //       for (let i = 1; i <= 10; i++)
  //          soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  //       resto = (soma * 10) % 11;

  //       if (resto == 10 || resto == 11) resto = 0;
  //       if (resto != parseInt(cpf.substring(10, 11))) valido = false;
  //       valido = true;
  //    }

  //    if (valido) return null;

  //    return { cpfInvalido: true };
  // }

  // static MaiorQue18Anos(controle: AbstractControl) {
  //    const nascimento = controle.value;
  //    const [ano, mes, dia] = nascimento.split('-');
  //    const hoje = new Date();
  //    const dataNascimento = new Date(ano, mes, dia, 0, 0, 0);
  //    const tempoParaTeste = 1000 * 60 * 60 * 24 * 365 * 18; //18 anos em mili segundos...

  //    if (hoje.getTime() - dataNascimento.getTime() >= tempoParaTeste)
  //       return null;

  //    return { menorDeIdade: true };
  // }
  static validCpfOrCnpj(controls: AbstractControl) {
    const cpfCnpj = controls.value;
    if (!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
      return { invalid: 'CPF/CNPJ inválido' };
    }
  }
  static validChassi(controls: AbstractControl) {
    const regex =
      /[a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{9}?[a-h,A-H,j-n,J-N,p,P,r-t,R-T,v-z,V-Z,0-9][a-h,A-H,j-n,J-N,p-z,P-Z,0-9]?[0-9]{6}/;
    const chassi = controls.value;
    if ((chassi.length != 0 && chassi.length != 17) || regex.test(chassi)) {
      return { invalid: 'Chassi Inválido' };
    }
  }
  static biggerThanToday(controls: AbstractControl) {
    const birthDate = controls.value;
    const [year, month, day] = birthDate.split('-');
    const now = new Date();
    const birth = new Date(year, month - 1, day, 0, 0, 0);

    if (now.getTime() <= birth.getTime()) {
      return { biggerThanToday: true };
    }

    return null;
  }
  static equalsPassword(controls: AbstractControl) {
    if (controls.parent) {
      let password = controls.parent.value.password;
      let confirmationPassword = controls.value;
      if (password !== confirmationPassword) {
        return { differentPasswords: true };
      }
    }
    return null;
  }
  static validatePassword(controls: AbstractControl) {
    let password = controls.value;

    if (
      password.toLowerCase() === password ||
      password.toUpperCase() === password
    ) {
      return { invalidPassword: true };
    }

    const regex = /[0-9]/;
    if (!regex.test(password)) {
      return { invalidPassword: true };
    }

    return null;
  }

  static validNegativo(controls: AbstractControl) {
    const value = controls.value;
    if (value < 0) {
      return { invalid: 'Valor não pode ser negativo.' };
    }
  }

  static validPhone(controls: AbstractControl) {
    const value = controls.value;
    if (value && value.replace(/\D/g, '').length < 10) {
      return { invalid: 'Telefone deve conter no mínimo 10 caracteres.' };
    }
  }
  static validwhatsapp(controls: AbstractControl) {
    const value = controls.value;
    if (value && value.replace(/\D/g, '').length < 13) {
      return { invalid: 'Telefone deve conter no mínimo 13 caracteres.' };
    }
  }
  static validPercentage(controls: AbstractControl) {
    const value = controls.value;
    if (value.replace('%', '') > 100) {
      return { invalid: 'Porcentagem não pode ser superior a 100%' };
    }
  }

  static validValueMoreThanZero(controls: AbstractControl) {
    const value = controls.value;
    if (Number(value) < 0.001) {
      return { invalid: 'Valor deve ser maior que 0.' };
    }
  }
  static validDate(controls: AbstractControl) {
    const value = controls.value;

    if (!value) {
      return { invalid: 'Campo Obrigatório' };
    }
    if (!moment(value).isValid()) {
      return { invalid: 'Data inválida' };
    }
    if (moment(value).isValid() && moment(value) <= moment(new Date())) {
      return { invalid: 'Data não pode ser menor ou igual à data atual.' };
    }
  }

  static validDateMoreThanToday(controls: AbstractControl) {
    const value = controls.value;
    if (moment(value) > moment()) {
      return { invalid: 'Data não pode ser maior que a atual' };
    }
  }
}
