<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="page-title">Cadastrar Colaboradores do Convênio</div>
            </div>
          </div>
          <div [formGroup]="covenantWorkerForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input type="text" class="form-control" id="name" formControlName="name" placeholder="Nome" />
                  <span *ngIf="
                      covenantWorkerForm.controls.name.errors &&
                      covenantWorkerForm.controls.name.errors.maxlength
                    " class="text-danger position-right">*Nome do colaborador deve ter no máximo 100 caracteres</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.name.errors &&
                      covenantWorkerForm.controls.name.errors.invalid
                    " class="text-danger position-right">* {{ covenantWorkerForm.controls.name.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input type="text" class="form-control" placeholder="E-mail" formControlName="email" />
                  <span *ngIf="covenantWorkerForm.controls.email.errors">
                    <span *ngIf="covenantWorkerForm.controls.email.errors.required"
                      class="text-danger position-right">*Campo Obrigatório</span>
                    <span *ngIf="
                        !covenantWorkerForm.controls.email.errors.required &&
                        covenantWorkerForm.controls.email.errors.maxlength
                      " class="text-danger position-right">*Email deve ter no máximo 60 caracteres</span>
                    <span *ngIf="
                        !covenantWorkerForm.controls.email.errors.maxlength &&
                        covenantWorkerForm.controls.email.errors.email
                      " class="text-danger position-right">*Não é um email válido!</span>
                    <span *ngIf="
                        !covenantWorkerForm.controls.email.errors.email &&
                        covenantWorkerForm.controls.email.errors.invalid
                      " class="text-danger position-right">* {{ covenantWorkerForm.controls.email.errors.invalid }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="role">Cargo</label>
                  <input type="text" class="form-control" id="role" formControlName="role" placeholder="Cargo" />
                  <span *ngIf="
                      covenantWorkerForm.controls.role.errors &&
                      covenantWorkerForm.controls.role.errors.maxlength
                    " class="text-danger position-right">*Cargo deve ter no máximo 18 caracteres</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.role.errors &&
                      covenantWorkerForm.controls.role.errors.invalid
                    " class="text-danger position-right">* {{ covenantWorkerForm.controls.role.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="department">Departamento</label>
                  <input type="text" class="form-control" id="department" formControlName="department"
                    placeholder="Departamento" />
                  <span *ngIf="
                      covenantWorkerForm.controls.department.errors &&
                      covenantWorkerForm.controls.department.errors.required
                    " class="text-danger position-right">*Campo Obrigatório</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.department.errors &&
                      covenantWorkerForm.controls.department.errors.maxlength
                    " class="text-danger position-right">*Departamento deve ter no máximo 30 caracteres</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.department.errors &&
                      covenantWorkerForm.controls.department.errors.invalid
                    " class="text-danger position-right">*
                    {{ covenantWorkerForm.controls.department.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="whatsapp">WhatsApp</label>
                  <input type="text" class="form-control" mask="+00 00 0 0000-0000" id="whatsapp"
                    formControlName="whatsapp" placeholder="WhatsApp" />
                  <span *ngIf="
                      covenantWorkerForm.controls.whatsapp.errors &&
                      covenantWorkerForm.controls.whatsapp.errors.invalid
                    " class="text-danger position-right">* {{ covenantWorkerForm.controls.whatsapp.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="requestAppRace">Solicita Corrida APP</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="false" value="false" name="requestAppRace" formControlName="requestAppRace"
                      class="custom-control-input">
                    <label class="custom-control-label" for="false">Não</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="true" value="true" name="requestAppRace" formControlName="requestAppRace"
                      class="custom-control-input">
                    <label class="custom-control-label" for="true">Sim</label>
                  </div>
                  <span *ngIf="covenantWorkerForm.controls.requestAppRace.errors">
                    <span *ngIf="covenantWorkerForm.controls.requestAppRace.errors.required"
                      class="text-danger position-right">*Campo
                      Obrigatório</span>
                    <span *ngIf="covenantWorkerForm.controls.requestAppRace.errors.invalid"
                      class="text-danger position-right">*
                      {{covenantWorkerForm.controls.requestAppRace.errors.invalid}} </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="costCenter">Centro de Custo</label>
                  <input type="text" class="form-control" id="costCenter" formControlName="costCenter"
                    placeholder="Centro de Custo" />
                  <span *ngIf="
                      covenantWorkerForm.controls.costCenter.errors &&
                      covenantWorkerForm.controls.costCenter.errors.invalid
                    " class="text-danger position-right">*
                    {{ covenantWorkerForm.controls.costCenter.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="zipCode">CEP</label>
                  <input type="text" class="form-control cep" (blur)="onBlurCep($event)" id="zipCode"
                    formControlName="zipCode" placeholder="CEP" />
                  <span *ngIf="covenantWorkerForm.controls.zipCode.errors &&
                      covenantWorkerForm.controls.zipCode.errors.required"
                      class="text-danger position-right">*Campo
                      Obrigatório</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.zipCode.errors &&
                      covenantWorkerForm.controls.zipCode.errors.maxlength"
                      class="text-danger position-right">*CEP deve ter no máximo 11 caracteres</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.zipCode.errors &&
                      covenantWorkerForm.controls.zipCode.errors.invalid
                    " class="text-danger position-right">*
                    {{ covenantWorkerForm.controls.zipCode.errors.invalid }}
                  </span>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label for="publicPlace">Logradouro</label>
                  <input type="text" class="form-control" id="publicPlace" formControlName="publicPlace"
                    placeholder="Rua / Complemento / Número" />
                  <span *ngIf="covenantWorkerForm.controls.publicPlace.errors &&
                    covenantWorkerForm.controls.publicPlace.errors.required"
                    class="text-danger position-right">*Campo
                    Obrigatório</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.publicPlace.errors &&
                      covenantWorkerForm.controls.publicPlace.errors.invalid
                    " class="text-danger position-right">*
                    {{ covenantWorkerForm.controls.publicPlace.errors.invalid }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="neighborhood">Bairro</label>
                  <input type="text" class="form-control" id="neighborhood" formControlName="neighborhood"
                    placeholder="Bairro" />
                  <span *ngIf="covenantWorkerForm.controls.neighborhood.errors &&
                    covenantWorkerForm.controls.neighborhood.errors.required"
                    class="text-danger position-right">*Campo
                    Obrigatório</span>
                  <span *ngIf="
                      covenantWorkerForm.controls.neighborhood.errors &&
                      covenantWorkerForm.controls.neighborhood.errors.invalid
                    " class="text-danger position-right">*
                    {{
                    covenantWorkerForm.controls.neighborhood.errors.invalid
                    }}
                  </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">Estado </label>
                  <select #selectState (change)="onSelectedState($event)" class="form-control select2"
                    formControlName="state">
                    <option value="">Selecione o Estado</option>
                    <option value="{{ state.id }}" *ngFor="let state of states">
                      {{ state.name }}
                    </option>
                  </select>
                  <span *ngIf="covenantWorkerForm.controls.state.errors &&
                      covenantWorkerForm.controls.state.errors.required"
                      class="text-danger position-right">*Campo
                      Obrigatório</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">Cidade </label>
                  <select class="form-control select2" formControlName="city">
                    <option value="">Selecione a Cidade</option>
                    <option value="{{ city.id }}" *ngFor="let city of cities">
                      {{ city.name }}
                    </option>
                  </select>
                  <span *ngIf="covenantWorkerForm.controls.city.errors &&
                      covenantWorkerForm.controls.city.errors.required"
                      class="text-danger position-right">*Campo
                      Obrigatório</span>
                </div>
              </div>
            </div>

            <div class="col-md-12 text-center">
              <a type="button" (click)="onSubmit()"
                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary mr-3">
                {{ isEditingId ? 'Salvar' : 'Adicionar'}}
              </a>
            </div>
          </div>
          <div class="table-responsive-md">
            <h2>Colaboradores</h2>
            <div class="row form-group" [formGroup]="searchForm">
              <div class="col-3">
                <input type="text" class="form-control" placeholder="Nome" formControlName="name" />
              </div>
              <div class="col-2">
                <input type="text" class="form-control" placeholder="Cargo" formControlName="role">
              </div>
              <div class="col-2">
                <input 
                  type="text"
                  class="form-control"
                  mask="+00 00 0 0000-0000"
                  placeholder="Whatsapp"
                  formControlName="whatsapp"
                >
              </div>
              <div class="col-2">
                <select formControlName="active" class="form-control">
                  <option value='null' selected>Situação</option>
                  <option value='true'>Ativo</option>
                  <option value='false'>Inativo</option>
                </select>
              </div>
              <div class="col-3">
                <button (click)="exportCollaborators('pdf')" class="btn btn-danger mr-3" title="Exportar para PDF">
                  <i class="fa fa-file-pdf-o fa-lg trip-request__button__search" aria-hidden="true"></i>
                </button>
                <button (click)="exportCollaborators('excel')" class="btn btn-success" title="Exportar para Excel">
                  <i class="fa fa-file-excel-o fa-lg trip-request__button__search" aria-hidden="true"></i>
                </button>
                <a (click)="onSearch()" class="btn btn-info ml-5">
                  <i class="fa fa-search"></i>
                </a>
              </div>
            </div>
            <table class="table table-striped custom-table table-hover">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Cargo</th>
                  <th>WhatsApp</th>
                  <!-- <th>Departamento</th>
                                    <th>Centro de Custo</th> -->
                  <th>Situação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let collaborator of collaborators">
                  <td>
                    {{ collaborator.name }}
                  </td>
                  <td>{{ collaborator.email }}</td>
                  <td>
                    {{ collaborator.role }}
                  </td>
                  <td>
                    {{ collaborator.whatsapp | mask: "+00 00 0 0000-0000" }}
                  </td>
                  <!-- <td>
                                        {{ collaborator.department }}
                                    </td>
                                    <td>
                                        {{collaborator.costCenter }}
                                    </td> -->
                  <td>
                    <span *ngIf="collaborator.active">Ativo</span>
                    <span *ngIf="!collaborator.active">Inativo</span>
                  </td>
                  <td>
                    <!-- <a (click)="onCollaboratorEdit(collaborator)" class="btn btn-warning btn-xs">
                                            <i class="fa fa-pencil"></i>
                                        </a> -->
                    <span *ngIf="collaborator.active">
                      <button (click)="onDeActive(collaborator)" class="btn btn-pink btn-xs" title="Desativar">
                        <i class="fa fa-random"></i>
                      </button>
                    </span>
                    <span *ngIf="!collaborator.active">
                      <button (click)="onActive(collaborator)" class="btn btn-success btn-xs" title="Ativar">
                        <i class="fa fa-random"></i>
                      </button>
                    </span>
                    <span>
                      <button (click)="onCollaboratorEdit(collaborator)" class="btn btn-warning btn-xs" title="Editar">
                        <i class="fa fa-pencil"></i>
                      </button>
                    </span>
                    <span *ngIf="collaborator.canResendActivation">
                      <button
                        (click)="resendActivationInstruction(collaborator)"
                        class="btn btn-success btn-xs"
                        title="Reenviar instruções de ativação"
                      >
                        <i class="fa fa-retweet"></i>
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-12">
                <span *ngIf="!collaborators.length" class="label label-danger label-mini">
                  Nenhum Colaborador adicionado.
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="!first">
                  <button class="page-link" (click)="onFirst()" tabindex="-1">
                    Primeira
                  </button>
                </li>
                <li class="page-item" *ngIf="pagination.page != 1">
                  <button (click)="onPrevious()" class="page-link">
                    {{ pagination.page - 1 }}
                  </button>
                </li>
                <li class="page-item active">
                  <button class="page-link">{{ pagination.page }}</button>
                </li>
                <li class="page-item" *ngIf="last">
                  <button (click)="onNext()" class="page-link" href="#">
                    {{ pagination.page + 1 }}
                  </button>
                </li>
                <li class="page-item" [class.disabled]="!last">
                  <button class="page-link" (click)="onLast()">Última</button>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-md-12">
            Mostrando página {{ pagination.page }} de
            {{ pagination.totalPages }} dos
            {{ pagination.totalResults }} Resultados
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
