import { CostCenter } from '../../cost-center';
import { Provider } from '../../provider';

export class BillsToPayFilter {
  due_date_start: Date;
  due_date_end: Date;
  value_start: number;
  value_end: number;
  provider: Provider;
  cost_center: CostCenter;
  paid: boolean;
}
