import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PieceService } from 'src/app/service/piece/piece.service';
import { Piece } from 'src/app/domain/piece';
import { Router } from '@angular/router';
import { Validations } from '../../validations';

@Component({
  selector: 'app-piece-create',
  templateUrl: './piece-create.component.html',
  styleUrls: ['./piece-create.component.css']
})
export class PieceCreateComponent implements OnInit {

  pieceForm: FormGroup;
  loading: boolean = false;
  messageError: string = "";

  constructor(
    private service: PieceService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.pieceForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      exchangeOdometer: ['', [Validators.required, Validators.max(999999999999999), Validations.validNegativo]]
    });
  }
  onSubmit() {

    const values = this.pieceForm.controls;
    console.log(this.pieceForm.controls);


    let piece = new Piece();
    piece.name = this.pieceForm.controls.name.value;
    piece.exchangeOdometer = this.pieceForm.controls.exchangeOdometer.value;

    this.loading = true;
    this.service.save(piece).subscribe(data => {
      this.loading = false;
      Swal.fire('Salvo', 'Peça salva com sucesso!!', 'success').then((result) => {
        if (result.value) {
          this.router.navigate(['parameters/piece']);
        }
      });
      this.createForm();
    },
      error => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire('Erro ao salvar', 'Não foi possível salvar Peça!', 'error');
      });

  }

  resetForm() {
    this.pieceForm.controls.name.setValue("");
    this.pieceForm.controls.exchangeOdometer.setValue("");
  }

  setErrors(errors) {
    errors.forEach(erro => {
      console.log(erro);

      switch (erro.fieldName) {
        case "name":
          this.pieceForm.controls.name.setErrors({ invalid: erro.message });
          break;
        case "exchangeOdometer":
          this.pieceForm.controls.exchangeOdometer.setErrors({ invalid: erro.message });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });

  }


}
