import { CarMaintenance } from '../car-maintenance';
import { Piece } from '../piece';
import { ProviderDTO } from './provider.dto';

export class CarMaintenanceDTO {
    id: number;
    provider: ProviderDTO;
    pieces: Array<Piece>;
    value: number;
    paid: boolean;
    odometer: number;
    date: Date;
    active: boolean;

    constructor(carMaintenance: CarMaintenance) {
        this.id = carMaintenance.id;
        this.provider = new ProviderDTO(carMaintenance.provider);
        this.paid = carMaintenance.paid;
        this.pieces = carMaintenance.pieces;
        this.date = carMaintenance.date;
        this.value = carMaintenance.value;
        this.odometer = carMaintenance.odometer;
        this.active = carMaintenance.active;
    }
}
