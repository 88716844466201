<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"></ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-10 mt-3">
              <div class="page-title">Editar Conta a Pagar</div>
            </div>
          </div>
          <div [formGroup]="billsToPayForm">
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>
            <div class="card card-topline-green">
              <div class="form-group container">
                <div class="row mt-3">
                  <div class="col-sm-4">
                    <label for="provider">Fornecedor</label>
                    <ng-select class="custom-select" style="
                        font-size: 1rem;
                        font-weight: normal;
                        min-width: 230px;
                      " notFoundText="Nenhum item encontrado" placeholder="Fornecedor" formControlName="provider"
                      [items]="providers" bindLabel="name" bindValue="id">
                    </ng-select>
                    <span *ngIf="
                        billsToPayForm.controls.provider.errors &&
                        billsToPayForm.controls.provider.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                  <div class="col-sm-4">
                    <label for="cost-center">Centro de Custo</label>
                    <select class="form-control select2" id="cost-center" formControlName="costCenter">
                      <option value=""></option>
                      <option *ngFor="let costCenter of costCenters" value="{{ costCenter.id }}">
                        {{ costCenter.name }}
                      </option>
                    </select>
                    <span *ngIf="
                        billsToPayForm.controls.costCenter.errors &&
                        billsToPayForm.controls.costCenter.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                  <div class="col-sm-4">
                    <label for="invoice-number">Nota Fiscal / Recibo</label>
                    <input type="text" class="form-control" maxlength="20" placeholder="Nota Fiscal / Recibo"
                      formControlName="invoiceNumber" id="invoice-number" />
                    <span *ngIf="
                        billsToPayForm.controls.invoiceNumber.errors &&
                        billsToPayForm.controls.invoiceNumber.errors.maxlength
                      " class="text-danger position-right">*Nota Fiscal/Recibo deve ter no máximo 20
                      caracteres</span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <label for="value">Valor</label>
                    <input type="text" class="form-control money2" placeholder="Valor" formControlName="value"
                      currencyMask [options]="{
                        prefix: 'R$ ',
                        thousands: '.',
                        decimal: ','
                      }" id="value" />
                    <span *ngIf="
                        billsToPayForm.controls.value.errors &&
                        billsToPayForm.controls.value.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                  <div class="col">
                    <label for="paidValue">Valor pago</label>
                    <input type="text" class="form-control money2" placeholder="Valor pago" formControlName="paidValue"
                      currencyMask [options]="{
                        prefix: 'R$ ',
                        thousands: '.',
                        decimal: ','
                      }" id="paidValue" />
                    <span *ngIf="
                        billsToPayForm.controls.paidValue?.errors &&
                        billsToPayForm.controls.paidValue?.errors?.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                  <div class="col">
                    <label for="dueDate">Vencimento</label>
                    <input type="date" class="form-control" formControlName="dueDate" id="dueDate" />
                    <span *ngIf="
                        billsToPayForm.controls.dueDate.errors &&
                        billsToPayForm.controls.dueDate.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                  <div class="col">
                    <label for="paymentMethod">Tipo de Pagemento</label>
                    <select class="form-control select2" formControlName="paymentMethod" id="paymentMethod">
                      <option value=""></option>
                      <option value="billet">Boleto</option>
                      <option value="credit_card">Cartão de Crédito</option>
                      <option value="bank_check">Cheque</option>
                      <option value="deposit">Depósito</option>
                      <option value="money">Dinheiro</option>
                    </select>
                    <span *ngIf="
                        billsToPayForm.controls.paymentMethod.errors &&
                        billsToPayForm.controls.paymentMethod.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-4 form-group">
                    <label>Forma de Pagamento</label>
                    <div class="form-check form-check-inline">
                      <input type="radio" name="paymentType" value="in_cash" id="in_cash"
                        formControlName="paymentType" />
                      <label for="in_cash" class="form-check-label">A vista</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input type="radio" name="paymentType" value="parceled" id="parceled"
                        formControlName="paymentType" />
                      <label for="parceled" class="form-check-label">Parcelado</label>
                    </div>
                    <span *ngIf="
                        billsToPayForm.controls.paymentType.errors &&
                        billsToPayForm.controls.paymentType.errors.required
                      " class="text-danger position-right">*Campo Obrigatório</span>
                  </div>
                  <div class="col-sm-4">
                    <label for="name">Situação</label>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="paid" formControlName="paid" (change)="
                          paidChanged(billsToPayForm.controls.paid.value)
                        " />
                      <label class="form-check-label" for="paid">Pago</label>
                    </div>
                  </div>
                  <div *ngIf="
                      this.billsToPayForm.getRawValue().paymentMethod ==
                      'credit_card'
                    " class="col-sm-4">
                    <label for="credit_card">Cartão de crédito</label>
                    <select class="form-control" type="text" id="credit_card" formControlName="creditCard">
                      <option value=""></option>
                      <option *ngFor="let creditCard of creditCards" value="{{ creditCard.id }}">
                        {{ creditCard.number + "-" + creditCard.flag }}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="
                    this.billsToPayForm.getRawValue().paymentMethod ==
                    'bank_check'
                  " class="row mt-3">
                  <div class="col-4">
                    <label for="name">Banco</label>
                    <select class="form-control select2" formControlName="bank">
                      <option value=""></option>
                      <option *ngFor="let bank of banks" value="{{ bank.id }}">
                        {{ bank.name }}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="
                      this.billsToPayForm.getRawValue().paymentType !==
                      'parceled'
                    " class="col-4">
                    <label for="name">Nº do cheque</label>
                    <input type="text" class="form-control" maxlength="10" placeholder="Nº do cheque"
                      (keypress)="onlyNumber($event)" formControlName="bankCheckNumber" />
                  </div>
                  <span *ngIf="
                      billsToPayForm.controls.bankCheckNumber.errors &&
                      billsToPayForm.controls.bankCheckNumber.errors.maxlength
                    " class="text-danger position-right">*Máximo 10 de caracteres
                  </span>
                </div>
                <div *ngIf="
                    this.billsToPayForm.getRawValue().paymentType === 'parceled'
                  ">
                  <div class="row mt-3">
                    <div class="col-4">
                      <label for="parcAmount">Quantidade de Parcelas</label>
                      <input type="text" maxlength="5" placeholder="Quantidade de Parcelas" class="form-control"
                        formControlName="parcAmount" id="parcAmount" (keypress)="onlyNumber($event)" />
                      <span *ngIf="
                          billsToPayForm.controls.parcAmount.errors &&
                          billsToPayForm.controls.parcAmount.errors.maxlength
                        " class="text-danger position-right">*Máximo 5 de caracteres
                      </span>
                    </div>
                    <div class="col-4">
                      <label for="name">Periodicidade</label>
                      <select class="form-control" formControlName="frequency">
                        <option></option>
                        <option value="days">Dias</option>
                        <option value="weeks">Semanas</option>
                        <option value="fortnights">Quinzenas</option>
                        <option value="months">Meses</option>
                        <option value="bimonthly">Bimestres</option>
                        <option value="quarters">Trimestres</option>
                        <option value="semesters">Semestres</option>
                        <option value="years">Anos</option>
                      </select>
                    </div>
                  </div>
                </div>
                <table *ngIf="
                    this.parcels.length > 1 &&
                    this.billsToPayForm.getRawValue().paymentType === 'parceled'
                  " class="table table-bordered mt-5" formArrayName="parcels">
                  <thead>
                    <tr>
                      <th scope="col">Nº da Parcela</th>
                      <th *ngIf="
                          this.billsToPayForm.getRawValue().paymentMethod ==
                          'bank_check'
                        " scope="col">
                        Nº do Cheque
                      </th>
                      <th scope="col">Valor da Parcela</th>
                      <th scope="col">Valor Pago</th>
                      <th scope="col">Data Vencimento Parcela</th>
                      <th scope="col">Pago</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let parcel of parcels.controls; let i = index" [formGroupName]="i">
                      <td>{{ parcel.controls.number.value }}</td>
                      <td *ngIf="
                          this.billsToPayForm.getRawValue().paymentMethod ==
                          'bank_check'
                        ">
                        <input type="text" class="form-control" formControlName="bankCheckNumber" maxlength="10" />
                      </td>
                      <td>
                        {{ parcel.controls.value.value | currency: "BRL" }}
                      </td>
                      <td *ngIf="parcel.controls.paid">
                        <input type="text" class="form-control money2" placeholder="Valor pago"
                          formControlName="paidValue" currencyMask [options]="{
                            prefix: 'R$ ',
                            thousands: '.',
                            decimal: ','
                          }" id="paidValue" />
                        <span *ngIf="
                            parcel.controls.paidValue.errors &&
                            parcel.controls.paidValue.errors.required
                          " class="text-danger position-right">*Campo Obrigatório</span>
                      </td>
                      <td *ngIf="!parcel.controls.paid"></td>
                      <td>
                        {{ parcel.controls.dueDate.value | date: "dd/MM/yyyy" }}
                      </td>
                      <td>
                        <input type="checkbox" formControlName="paid" (change)="
                            parcelPaidChanged(i, parcel.controls.paid.value)
                          " />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="row mt-3">
                  <div class="col-12">
                    <label for="parcAmount">Observações</label>
                    <textarea type="text" placeholder="Observações" class="form-control" rows="6"
                      formControlName="observation" id="observation"></textarea>
                    <span *ngIf="billsToPayForm.controls.observation.errors" class="text-danger position-right">{{
                      billsToPayForm.controls.observation.errors }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 ml-3 mb-3">
              <div class="col-md-3 text-right">
                <button (click)="onSubmit()" [disabled]="!billsToPayForm.valid" class="btn btn-success">
                  Salvar
                </button>
              </div>
              <div class="col-md-3">
                <a routerLink="/financial/bills-to-pay" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>