import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/service/login/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from '../validations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  token: string = "";
  resetForm: FormGroup;
  loading: boolean = false;

  constructor(
    private service: LoginService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit(): void {
    this.createForm();

  }
  createForm() {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validations.validatePassword]],
      confirmationPassword: ['', [Validators.required, Validations.equalsPassword]]
    });
  }
  onSubmit() {

    const password = this.resetForm.controls.password.value;
    const confirmationPassword = this.resetForm.controls.confirmationPassword.value;

    if (password !== confirmationPassword) {
      this.resetForm.controls.confirmationPassword.setErrors({ differentPasswords: true })
    }
    if (this.resetForm.valid) {

      this.loading = true;
      this.service.reset(this.token, this.resetForm.controls.password.value).subscribe(data => {
        this.loading = false;
        Swal.fire('Salvo', 'Nova senha salva com sucesso!!', 'success');
        this.router.navigate(['login']);
      },
        error => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire('Erro ao salvar', 'Não foi possível editar senha do Usuário!', 'error');
        });
    }
  }
  setErrors(errors) {
    errors.forEach(erro => {
      if (erro.fieldName === 'password') {
        this.resetForm.controls.password.setErrors({ invalid: erro.message });
      }
      if (erro.fieldName === 'token') {
        this.resetForm.controls.password.setErrors({ token: erro.message });
      }

    });

  }
}
