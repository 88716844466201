import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Pagination } from './../../../domain/pagination';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Driver } from './../../../domain/driver';
import { DriverService } from './../../../service/driver/driver.service';
import { Component, OnInit } from '@angular/core';
import { TripRequest } from 'src/app/domain/trip-request';
import { EnumRequestValueType } from 'src/app/enumerations/request-value-type';
import { Bank } from 'src/app/domain/bank';
import { BankService } from 'src/app/service/bank/bank.service';
import { DriverPaymentService } from 'src/app/service/driver-payment/driver-payment.service';

@Component({
  selector: 'app-pay-driver-create',
  templateUrl: './pay-driver-create.component.html',
  styleUrls: ['./pay-driver-create.component.css'],
})
export class PayDriverCreateComponent implements OnInit {
  private _financialParameters = [];

  loading: boolean;
  banks: Array<Bank> = [];
  drivers: Array<Driver> = [];
  form;
  newValueForm;
  paymentInformation;
  credits = [];
  fuelSupplies = [];
  maintenances = [];
  trafficTickets = [];
  tolls = [];
  resume = [];
  refunds = [];
  commission = {};
  totalValueCredit: number;
  finalValueCredit: number;
  finalValueDebit: number;
  finalValueTrafficTicket: number;
  finalValueFuelSupplies: number;
  finalValueToll: number;
  finalRefundValue: number;
  commissionValue: number;
  paymentValue: number;
  finalValue: number;
  newValues = [];
  allSelected: boolean = false;
  pagination: Pagination = new Pagination();
  showResultsForm: FormGroup;
  first: boolean = false;
  last: boolean = false;

  launchs: Array<TripRequest> = [];

  selectedLaunchs = [];
  constructor(
    private bankService: BankService,
    private driverPaymentService: DriverPaymentService,
    private driverService: DriverService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page ?? 10;
      this.pagination.page = params.page ?? 1;
    });
  }

  ngOnInit(): void {
    this.getDrivers();
    this.createForm();
    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;
    this.getAllBankActive();
  }

  createForm() {
    this.form = this.fb.group({
      initialDate: [''],
      finalDate: [''],
      driver: ['', [Validators.required]],
      issueInvoiceToCreditCard: [null, [Validators.required]],
    });

    this.newValueForm = this.fb.group({
      description: ['', [Validators.required]],
      value: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });

    this.paymentInformation = this.fb.group({
      paymentType: ['', [Validators.required]],
      bank: [''],
      checkNumber: [''],
      paid: [''],
    });

    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
  }

  async allSelect(event) {
    this.selectedLaunchs = [];
    if (event) {
      await Promise.all(
        this.launchs.map((launch) => {
          launch['runRequest'].requestValue.type = launch['runRequest'].covenant
            ? 'Convênio'
            : 'Cliente';
        })
      ).then((_) => {
        this.selectedLaunchs.push(...JSON.parse(JSON.stringify(this.launchs)));
        this.allSelected = true;
        this.calculate();
      });
    }
  }

  isSelected(id: number) {
    return this.selectedLaunchs.find((selected) => selected.id === id);
  }

  selectLaunch(id: number) {
    const index = this.selectedLaunchs.findIndex(
      (selected) => selected.id === id
    );
    this.allSelected = false;
    if (index === -1) {
      this.selectedLaunchs.push(
        this.launchs.find((launch) => launch.id === id)
      );
    } else {
      this.selectedLaunchs = this.selectedLaunchs.filter(
        (selected) => selected.id !== id
      );
    }
    this.calculate();
  }

  addNewValues() {
    this.newValueForm.markAllAsTouched();
    if (this.newValueForm.valid) {
      const newValue = {
        description: this.newValueForm.get('description').value,
        value: this.newValueForm.get('value').value,
        type: this.newValueForm.get('type').value,
      };

      this.newValues.push(newValue);

      this.calculate();
    }
  }

  getDrivers() {
    this.loading = true;
    this.driverService.getActiveDrivers().subscribe(
      (data) => {
        this.drivers = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  getAllBankActive() {
    this.bankService.getAllBanks().subscribe(
      (data) => {
        this.banks = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Erro', 'Não foi possível Buscar os bancos!', 'error');
      }
    );
  }

  fetchLaunches() {
    this.form.controls['driver'].markAsTouched();

    if (this.form.valid) {
      this.newValues = [];
      const { driver, initialDate, finalDate } = this.form.value;
      this.loading = true;
      this.driverService
        .fetchLaunches(driver, initialDate, finalDate)
        .subscribe(
          (res) => {
            //this.setPagination(res);
            this.launchs = res['runs'];
            this.fuelSupplies = res['fuelSupplies'];
            this.trafficTickets = res['trafficTickets'];
            this._financialParameters = res['financialParameters'];
            this.loading = false;
            this.allSelect(true);
          },
          (error) => {
            this.loading = false;
            error.error?.errors.forEach(({ fieldName, message }) =>
              this.form.controls[fieldName].setErrors({ invalid: message })
            );
          }
        );
    }
  }

  calculate() {
    if (!this.launchs.length) return;
    this.credits = [];
    this.resume = [];
    this.refunds = [];

    this.totalValueCredit = 0;
    this.finalValueCredit = 0;
    this.finalValueTrafficTicket = 0;
    this.finalValueToll = 0;
    this.finalValueFuelSupplies = 0;
    this.commissionValue = 0;
    this.finalValue = 0;

    /*-----------------------------------------------------------------------------
      Filtra os registros de pedágio.
    ------------------------------------------------------------------------------*/
    this.tolls = this.selectedLaunchs.filter(
      (item) => item['runRequest'].requestValue.tollAmount > 0
    );

    this.tolls.forEach((toll) => {
      this.finalValueToll += toll['runRequest'].requestValue.tollAmount;
      const resume = this.resume.find((res) => res.description === 'Pedágio');

      if (resume) {
        resume.value += toll['runRequest'].requestValue.tollAmount;
      } else {
        this.resume.push({
          description: 'Pedágio',
          value: toll['runRequest'].requestValue.tollAmount,
          type: 'debit',
        });
      }
    });

    /*-----------------------------------------------------------------------------
      Filtra os registros do tipo cliente, que é pagamento cartão.
    ------------------------------------------------------------------------------*/
    // await Promise.all(
    const clientIssueInvoiceHasCardFee = this.selectedLaunchs.filter(
      (item) =>
        //item['runRequest'].requestValue.type === 'Cliente' &&
        !item.runRequest.covenant && item['runRequest'].paymentMethodId === 2
    );
    // ).then((clientIssueInvoiceHasCardFee) => {
    for (const launch of clientIssueInvoiceHasCardFee) {
      const type = launch.runRequest.requestValue.type;
      const credit = this.credits.find(
        (credit) =>
          credit.description === type &&
          //credit.issueInvoice &&
          credit.hasCardFee
      );

      if (credit) {
        credit.value += launch.runEndValue;
      } else {
        this.credits.push({
          description: type,
          value: launch.runEndValue,
          issueInvoice: false,
          //percentage: launch.runRequest.requestValue.percentage,
          hasCardFee: launch.runRequest.paymentMethodId === 2,
        });
      }
      this.totalValueCredit += launch.runEndValue;
    }
    // });

    /*-------------------------------------------------------------------------------
      Filtra os registros do tipo cliente, que não é pagamento cartão.
    --------------------------------------------------------------------------------*/
    // await Promise.all(
    const clientIssueInvoiceNotHasCardFee = this.selectedLaunchs.filter(
      (item) =>
        //item['runRequest'].requestValue.type === 'Cliente' &&
        !item.runRequest.covenant && item['runRequest'].paymentMethodId !== 2
    );
    // ).then((clientIssueInvoiceNotHasCardFee) => {
    for (const launch of clientIssueInvoiceNotHasCardFee) {
      const type = launch.runRequest.requestValue.type;
      const credit = this.credits.find(
        (credit) =>
          credit.description === type &&
          //credit.issueInvoice &&
          !credit.hasCardFee
      );

      if (credit) {
        credit.value += launch.runEndValue;
      } else {
        this.credits.push({
          description: type,
          value: launch.runEndValue,
          issueInvoice: false,
          //percentage: launch.runRequest.requestValue.percentage,
          hasCardFee: launch.runRequest.paymentMethodId === 2,
        });
      }
      this.totalValueCredit += launch.runEndValue;
    }
    // });

    /*--------------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que emite nf e que não é pagamento cartão.
    ---------------------------------------------------------------------------------*/
    // await Promise.all(
    const covenantIssueInvoiceNotHasCardFee = this.selectedLaunchs.filter(
      (item) =>
        item.runRequest.covenant &&
        item.runRequest.covenant.invoice &&
        item['runRequest'].paymentMethodId !== 2
    );
    //).then((covenantIssueInvoiceNotHasCardFee) => {
    for (const launch of covenantIssueInvoiceNotHasCardFee) {
      const type = launch.runRequest.requestValue.type;
      const credit = this.credits.find(
        (credit) =>
          credit.description === type &&
          credit.issueInvoice &&
          !credit.hasCardFee
      );

      if (credit) {
        credit.value += launch.runEndValue;
      } else {
        this.credits.push({
          description: type,
          value: launch.runEndValue,
          issueInvoice: launch.runRequest.covenant.invoice,
          //percentage: launch.runRequest.requestValue.percentage,
          hasCardFee: launch.runRequest.paymentMethodId === 2,
        });
      }
      this.totalValueCredit += launch.runEndValue;
    }
    //});

    /*--------------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que não emite nf e que é pagamento cartão.
    ---------------------------------------------------------------------------------*/
    // await Promise.all(
    const covenantNotIssueInvoiceHasCardFee = this.selectedLaunchs.filter(
      (item) =>
        item.runRequest.covenant &&
        !item.runRequest.covenant.invoice &&
        item['runRequest'].paymentMethodId === 2
    );
    // ).then((covenantNotIssueInvoiceHasCardFee) => {
    for (const launch of covenantNotIssueInvoiceHasCardFee) {
      const type = launch.runRequest.requestValue.type;
      const credit = this.credits.find(
        (credit) =>
          credit.description === type &&
          !credit.issueInvoice &&
          credit.hasCardFee
      );

      if (credit) {
        credit.value += launch.runEndValue;
      } else {
        this.credits.push({
          description: type,
          value: launch.runEndValue,
          issueInvoice: launch.runRequest.covenant.invoice,
          //percentage: launch.runRequest.requestValue.percentage,
          hasCardFee: launch.runRequest.paymentMethodId === 2,
        });
      }
      this.totalValueCredit += launch.runEndValue;
    }
    // });

    /*-----------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que emite nf e que é pagamento cartão.
    ------------------------------------------------------------------------------*/
    // await Promise.all(
    const covenantIssueInvoiceHasCardFee = this.selectedLaunchs.filter(
      (item) =>
        item.runRequest.covenant &&
        item.runRequest.covenant.invoice &&
        item['runRequest'].paymentMethodId === 2
    );
    // ).then((covenantIssueInvoiceHasCardFee) => {
    for (const launch of covenantIssueInvoiceHasCardFee) {
      const type = launch.runRequest.requestValue.type;
      const credit = this.credits.find(
        (credit) =>
          credit.description === type &&
          credit.issueInvoice ===
            (this.form.get('issueInvoiceToCreditCard').value &&
              launch.runRequest.covenant.invoice) &&
          credit.hasCardFee
      );
      const { credits } = this;

      if (credit) {
        credit.value += launch.runEndValue;
      } else {
        this.credits.push({
          description: type,
          value: launch.runEndValue,
          issueInvoice:
            this.form.get('issueInvoiceToCreditCard').value &&
            launch.runRequest.covenant.invoice,
          //percentage: launch.runRequest.requestValue.percentage,
          hasCardFee: launch.runRequest.paymentMethodId === 2,
        });
      }
      this.totalValueCredit += launch.runEndValue;
    }
    // });

    /*------------------------------------------------------------------------------------
      Filtra os registros do tipo convênio, que não emite nf e que não é pagamento cartão.
    -------------------------------------------------------------------------------------*/
    // await Promise.all(
    const covenantNotIssueInvoiceNotHasCardFee = this.selectedLaunchs.filter(
      (item) =>
        item.runRequest.covenant &&
        !item.runRequest.covenant.invoice &&
        item['runRequest'].paymentMethodId !== 2
    );
    // ).then((covenantNotIssueInvoiceNotHasCardFee) => {
    for (const launch of covenantNotIssueInvoiceNotHasCardFee) {
      const type = launch.runRequest.requestValue.type;
      const credit = this.credits.find(
        (credit) =>
          credit.description === type &&
          !credit.issueInvoice &&
          !credit.hasCardFee
      );

      if (credit) {
        credit.value += launch.runEndValue;
      } else {
        this.credits.push({
          description: type,
          value: launch.runEndValue,
          issueInvoice: launch.runRequest.covenant.invoice,
          //percentage: launch.runRequest.requestValue.percentage,
          hasCardFee: launch.runRequest.paymentMethodId === 2,
        });
      }
      this.totalValueCredit += launch.runEndValue;
    }
    // });

    for (const item of this.fuelSupplies) {
      this.finalValueFuelSupplies += item.value;
      if (item.supplyType === 'DRIVER') {
        const resume = this.refunds.find(
          (res) => res.description === 'Reembolso Abastecimento'
        );

        if (resume) {
          resume.value += item.value;
        } else {
          this.refunds.push({
            description: 'Reembolso Abastecimento',
            value: item.value,
            type: 'credit',
          });
        }
      }
    }

    for (const item of this.trafficTickets) {
      this.finalValueTrafficTicket += item['value'];
      // const resume = this.resume.find((res) => res.description === 'Multas');

      // if (resume) {
      //   resume.value += item['value'];
      // } else {
      //   this.resume.push({
      //     description: 'Multas',
      //     value: item['value'],
      //     type: 'debit',
      //   });
      // }
    }

    for (const item of this.credits) {
      if (!item.finalValue) {
        item.finalValue = item.value;
      }

      if (item.issueInvoice) {
        const taxiDriverDiscountCovenant = this._financialParameters.find(
          (item) => item.parameter === 'taxiDriverDiscountCovenant'
        );

        if (taxiDriverDiscountCovenant) {
          item.covenantDiscount = taxiDriverDiscountCovenant.value;

          if (taxiDriverDiscountCovenant.type === 'percentage') {
            item.covenantDiscount += ` %`;
            item.finalValue -=
              item.value * (taxiDriverDiscountCovenant.value * 0.01);
          } else {
            item.finalValue -= taxiDriverDiscountCovenant.value;
          }
        }
      }

      if (item.hasCardFee) {
        const taxiDriverDiscountCard = this._financialParameters.find(
          (item) => item.parameter === 'taxiDriverDiscountCard'
        );

        if (taxiDriverDiscountCard) {
          item.cardDiscount = taxiDriverDiscountCard.value;

          if (taxiDriverDiscountCard.type === 'percentage') {
            item.cardDiscount += ` %`;
            item.finalValue -=
              item.value * (taxiDriverDiscountCard.value * 0.01);
          } else {
            item.finalValue -= taxiDriverDiscountCard.value;
          }
        }
      }

      this.finalValueCredit += item.finalValue;

      const resume = this.resume.find(
        (res) => res.description === 'Lançamentos'
      );

      if (resume) {
        resume.value += item.finalValue;
      } else {
        this.resume.push({
          description: 'Lançamentos',
          value: item.finalValue,
          type: 'credit',
        });
      }
    }

    const fuelSupplies = {
      description: 'Abastecimentos',
      type: 'debit',
      value: 0,
    };

    for (const item of this.fuelSupplies) {
      fuelSupplies.value += item.value;
    }

    this.resume.push(fuelSupplies);

    this.finalValueDebit =
      // this.finalValueTrafficTicket +
      this.finalValueFuelSupplies + this.finalValueToll;

    this.finalValue = this.finalValueCredit - this.finalValueDebit;

    this.commission = this._financialParameters.find(
      (item) => item.parameter === 'taxiDriverCommission'
    );

    if (this.commission['type'] === 'percentage') {
      this.commissionValue =
        this.finalValue *
        (this.commission ? this.commission['value'] * 0.01 : 0);
    } else {
      this.commissionValue = this.commission['value'];
    }

    this.paymentValue = this.commissionValue - this.finalValueTrafficTicket;

    for (const item of this.newValues) {
      if (item.type === 'credit') {
        this.paymentValue += item.value;
      } else {
        this.paymentValue -= item.value;
      }
    }

    for (const item of this.refunds) {
      this.paymentValue += item.value;
    }
  }

  onSubmit() {
    this.loading = true;

    this.paymentInformation.markAllAsTouched();

    if (this.paymentInformation.valid) {
      const data = {
        fuelSupplies: this.fuelSupplies,
        trafficTickets: this.trafficTickets,
        selectedLaunchs: this.selectedLaunchs,
        driverId: this.form.controls.driver.value,
        paymentType: this.paymentInformation.controls.paymentType.value,
        paid: this.paymentInformation.controls.paid.value,
        paymentOtherLaunches: this.newValues,
        value: this.paymentValue,
      };

      if (this.form.controls.initialDate.value) {
        data['initialDate'] = this.form.controls.initialDate.value;
      }

      if (this.form.controls.finalDate.value) {
        data['finalDate'] = this.form.controls.finalDate.value;
      }

      if (this.paymentInformation.controls.bank.value) {
        data['bankId'] = this.paymentInformation.controls.bank.value;
      }

      if (this.paymentInformation.controls.checkNumber.value) {
        data['checkNumber'] =
          this.paymentInformation.controls.checkNumber.value;
      }

      this.driverPaymentService.driverPaymentCreate(data).subscribe(
        (res) => {
          this.loading = false;
          Swal.fire(
            'Salvo',
            'Acerto com motorista gerado com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['financial/pay-driver']);
            }
          });
          this.router.navigate(['financial/pay-driver']);
        },
        (err) => {
          console.log(err);
          this.loading = false;
          //this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível gerar o acerto com o motorista!',
            'error'
          );
        }
      );
    }
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  onSelected(event) {
    this.pagination.per_page = event.target.value;
    this.setNavigate();
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/financial/pay-driver/create'], {
      queryParams: {
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
  }
}
