import { Component, OnInit } from '@angular/core';
import { Insurer } from 'src/app/domain/insurer';
import { InsurerService } from 'src/app/service/insurer/insurer.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-insurer-view',
  templateUrl: './insurer-view.component.html',
  styleUrls: ['./insurer-view.component.css']
})
export class InsurerViewComponent implements OnInit {

  id: number;
  insurer: Insurer = new Insurer();
  loading: boolean = true;

  constructor(
    private service: InsurerService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.id = params['id']
    });
  }

  ngOnInit(): void {
    this.getInsurers();
  }

  getInsurers() {
    this.loading = true;
    this.service.getInsurer(this.id).subscribe(data => {
      this.insurer = data;
      this.loading = false;

    },
      error => {
        this.loading = false;
        this.openAlertError();
      });
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar o Fornecedor!', 'error');
  }



}
