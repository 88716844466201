import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  public notification$: BehaviorSubject<string> = new BehaviorSubject('');
  private socket = io(environment.WS_URL);

  constructor() {
    this.socket.on('connect', () => {
      //this.socket.emit('notification', 'fetch');
    });
  }

  /* socket = io(environment.BASE_URL, {
    transports: ['websocket'],
  }); */




  public getNewNotification = () => {
    this.socket.on('notification', (notification) => {
      this.notification$.next(notification);
    });

    return this.notification$.asObservable();
  };

  public sendNotification = () => {
    this.socket.emit('notification', 'read');
  }

}
