import { Component, OnInit } from '@angular/core';
import { Permission } from 'src/app/domain/permission';
import { AccessProfileService } from 'src/app/service/access-profile/access-profile.service';
import { PermissionsService } from 'src/app/service/permissions/permissions.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';
import { AccessProfile } from 'src/app/domain/access-profile';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-access-profile-update',
  templateUrl: './access-profile-update.component.html',
  styleUrls: ['./access-profile-update.component.css'],
})
export class AccessProfileUpdateComponent implements OnInit {
  perm: Array<Permission> = [];
  accessProfileForm: FormGroup;
  loading: boolean = true;
  accessProfile: AccessProfile = new AccessProfile();

  constructor(
    private service: AccessProfileService,
    private servicePermissions: PermissionsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.accessProfile.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.createForm();

    this.servicePermissions.getPermissions().subscribe(
      (data) => {
        this.perm = data;
        this.service.getAccessProfile(this.accessProfile.id).subscribe(
          (ret) => {
            this.accessProfile = ret;
            this.setValues();
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            Swal.fire('Ops...', 'Erro ao buscar Perfil de Acesso!!', 'error');
          }
        );
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Erro ao buscar Permissões!!', 'error');
      }
    );
  }
  setValues() {
    this.accessProfileForm.controls.name.setValue(this.accessProfile.name);
    this.accessProfileForm.controls.description.setValue(
      this.accessProfile.description
    );
    this.accessProfileForm.controls.active.setValue(
      `${this.accessProfile.active}`
    );
    this.perm.forEach((element) => {
      if (this.accessProfile.permissions.some((e) => e.id === element.id)) {
        this.addPermissions(true);
      } else {
        this.addPermissions(false);
      }
    });
  }

  createForm() {
    this.accessProfileForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.minLength(5),
        ],
      ],
      description: ['', [Validators.maxLength(250)]],
      active: [''],
      permissions: this.fb.array([]),
    });
  }
  addPermissions(value: boolean) {
    this.permissions.push(this.fb.control(value));
  }

  get permissions() {
    return this.accessProfileForm.get('permissions') as FormArray;
  }
  onSubmit() {
    if (
      !this.accessProfileForm.controls.permissions.value.some(
        (element) => element === true
      )
    ) {
      Swal.fire(
        'Não foi possível salvar',
        'Para cadastrar um novo Perfil de Acesso você deve associá-lo a pelo menos uma Permissão!',
        'error'
      );
    } else {
      this.accessProfile.name = this.accessProfileForm.controls.name.value;
      this.accessProfile.description =
        this.accessProfileForm.controls.description.value;
      if (this.accessProfileForm.controls.active.value == 'true') {
        this.accessProfile.active = true;
      } else {
        this.accessProfile.active = false;
      }
      this.accessProfile.permissions = [];
      for (
        let i = 0;
        i < this.accessProfileForm.controls.permissions.value.length;
        i++
      ) {
        if (this.accessProfileForm.controls.permissions.value[i]) {
          this.accessProfile.permissions.push(this.perm[i]);
        }
      }
      this.loading = true;
      this.service.update(this.accessProfile).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire(
            'Sucesso',
            'Perfil de Acesso editado com sucesso!!',
            'success'
          ).then((result) => {
            if (result.value) {
              this.router.navigate(['security/access-profile']);
            }
          });
        },
        (error) => {
          this.loading = false;

          Swal.fire(
            'Erro ao editar',
            'Não foi possível editar Perfil de Acesso!',
            'error'
          );
        }
      );
    }
  }
  validPermissions() {
    if (
      !this.accessProfileForm.controls.permissions.value.some(
        (element) => element === true
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  // resetForm() {
  //   this.accessProfileForm.controls.name.setValue("");
  //   this.accessProfileForm.controls.description.setValue("");
  //   this.perm.forEach(element => {
  //     this.addPermissions();
  //   });

  // }
}
