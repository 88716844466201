import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  url: string = `${environment.BASE_URL}/addresses`;
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' });

  constructor(private http: HttpClient) { }

  getStates() {
    return this.http.get<Array<State>>(`${this.url}/states`,
      { responseType: "json" });
  }

  getCitiesByStateId(id: number) {
    return this.http.get<Array<City>>(`${this.url}/cities/${id}`,
      { responseType: "json" });

  }
}
