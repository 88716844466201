import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BillsToReceive } from 'src/app/domain/bills-to-receive';
import { CostCenter } from 'src/app/domain/cost-center';
import { Pagination } from 'src/app/domain/pagination';
import { BillsToReceiveService } from 'src/app/service/bills-to-receive/bills-to-receive.service';
import { CostCenterService } from 'src/app/service/cost-center/cost-center.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bills-to-receive',
  templateUrl: './bills-to-receive.component.html',
  styleUrls: ['./bills-to-receive.component.css'],
})
export class BillsToReceiveComponent implements OnInit {
  billsToReceives: Array<BillsToReceive> = [];
  costCenters: Array<CostCenter> = [];
  pagination: Pagination = new Pagination();
  loading: boolean = true;
  searchForm: FormGroup;
  showResultsForm: FormGroup;
  filter: any;

  first: boolean = false;
  last: boolean = false;

  paymentMethods = {
    billet: 'Boleto',
    bank_check: 'Cheque',
    deposit: 'Depósito',
    money: 'Dinheiro',
  };

  constructor(
    private service: BillsToReceiveService,
    private costCenterService: CostCenterService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      params.per_page != null
        ? (this.pagination.per_page = params.per_page)
        : (this.pagination.per_page = 10);
      params.page != null
        ? (this.pagination.page = params.page)
        : (this.pagination.page = 1);
    });
  }

  ngOnInit(): void {
    this.createForm();

    this.pagination.totalPages = 0;
    this.pagination.totalResults = 0;

    this.getBillsToReceive();
    this.getCostCenters();
  }

  createForm() {
    this.showResultsForm = this.fb.group({
      per_page: [this.pagination.per_page],
    });
    this.searchForm = this.fb.group({
      due_date_start: [''],
      due_date_end: [''],
      value_start: [''],
      value_end: [''],
      cost_center: [''],
      payment_method: [''],
      invoice_number: [''],
      client_covenant: [''],
      paid: [''],
    });
  }

  getBillsToReceive(): void {
    this.loading = true;

    this.service.getBillsToReceive(this.filter, this.pagination).subscribe(
      (data) => {
        this.billsToReceives = data['results'];
        this.setPagination(data);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  onSearch() {
    this.setNavigate();
  }

  getCostCenters(): void {
    this.costCenterService.getAllCostCenters().subscribe(
      (data) => {
        this.costCenters = data;

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  openAlertError() {
    Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }

  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }

  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }

  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.filter = this.searchForm.getRawValue();

    this.filter.value_start =
      this.filter.value_start.replace(/[^0-9]/g, '') / 100 || '';
    this.filter.value_end =
      this.filter.value_end.replace(/[^0-9]/g, '') / 100 || '';

    this.router.navigate(['/financial/bills-to-receive'], {
      queryParams: {
        page: this.pagination.page,
        per_page: this.pagination.per_page,
        ...this.filter,
      },
    });
    this.getBillsToReceive();
  }
}
