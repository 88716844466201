<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<ngx-loading [show]="loading"> </ngx-loading>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row mt-4 mb-2">
            <div class="col-md-10">
              <div class="page-title">Editar Convênio</div>
            </div>
          </div>
          <div [formGroup]="covenantForm">
            <!--div com o form -->
            <div *ngIf="messageError.length" class="container">
              <div class="alert alert-danger" role="alert">
                {{ messageError }}
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <div>
                    <div
                      class="al-upload"
                      *ngIf="!cropped"
                      (click)="_fileInput.click()"
                    >
                      <div *ngIf="!cropped">
                        <img
                          class="img-responsive"
                          src="assets/img/empresa.png"
                        />
                        <p>Insira uma logomarca</p>
                      </div>
                    </div>

                    <div *ngIf="cropped">
                      <button
                        class="btn-fechar"
                        (click)="onRemoveImage($event)"
                      >
                        <i class="fa fa-times-circle"></i>
                      </button>
                      <img
                        class="img-responsive"
                        [src]="cropped"
                        (click)="_fileInput.click()"
                      />
                    </div>

                    <input
                      #_fileInput
                      type="file"
                      (change)="openCropperDialog($event)"
                      accept="image/*"
                      hidden
                    />
                  </div>
                </div>
                <div class="col-md-8 col-sm-12">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">Nome</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          formControlName="name"
                          placeholder="Nome do Convênio"
                        />
                        <span
                          *ngIf="
                            covenantForm.controls.name.errors &&
                            covenantForm.controls.name.errors.required
                          "
                          class="text-danger position-right"
                          >*Campo Obrigatório</span
                        >
                        <span
                          *ngIf="
                            covenantForm.controls.name.errors &&
                            covenantForm.controls.name.errors.maxlength
                          "
                          class="text-danger position-right"
                          >*Nome do Convênio deve ter no máximo 100
                          caracteres</span
                        >
                        <span
                          *ngIf="
                            covenantForm.controls.name.errors &&
                            covenantForm.controls.name.errors.invalid
                          "
                          class="text-danger position-right"
                          >* {{ covenantForm.controls.name.errors.invalid }}
                        </span>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="name">CPF/CNPJ</label>
                          <input
                            type="text"
                            class="form-control"
                            mask="CPF_CNPJ"
                            id="cpfCnpj"
                            formControlName="cpfCnpj"
                            placeholder="CPF/CNPJ"
                          />
                          <span
                            *ngIf="
                              covenantForm.controls.cpfCnpj.errors &&
                              covenantForm.controls.cpfCnpj.errors.required
                            "
                            class="text-danger position-right"
                            >*Campo Obrigatório</span
                          >
                          <span
                            *ngIf="
                              covenantForm.controls.cpfCnpj.errors &&
                              covenantForm.controls.cpfCnpj.errors.maxlength
                            "
                            class="text-danger position-right"
                            >*CPF/CNPJ deve ter no máximo 18 caracteres</span
                          >
                          <span
                            *ngIf="
                              covenantForm.controls.cpfCnpj.errors &&
                              covenantForm.controls.cpfCnpj.errors.invalid
                            "
                            class="text-danger position-right"
                            >*
                            {{ covenantForm.controls.cpfCnpj.errors.invalid }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="name">E-mail</label>
                          <input
                            type="text"
                            class="form-control"
                            id="email"
                            formControlName="email"
                            placeholder="E-mail"
                          />
                          <span *ngIf="covenantForm.controls.email.errors">
                            <span
                              *ngIf="
                                covenantForm.controls.email.errors.required
                              "
                              class="text-danger position-right"
                              >*Campo Obrigatório</span
                            >
                            <span
                              *ngIf="
                                !covenantForm.controls.email.errors.required &&
                                covenantForm.controls.email.errors.maxlength
                              "
                              class="text-danger position-right"
                              >*Email deve ter no máximo 80 caracteres</span
                            >
                            <span
                              *ngIf="
                                !covenantForm.controls.email.errors.maxlength &&
                                covenantForm.controls.email.errors.email
                              "
                              class="text-danger position-right"
                              >*Não é um email válido!</span
                            >
                            <span
                              *ngIf="
                                !covenantForm.controls.email.errors.email &&
                                covenantForm.controls.email.errors.invalid
                              "
                              class="text-danger position-right"
                              >*
                              {{ covenantForm.controls.email.errors.invalid }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Inscrição Estadual</label>
                    <input
                      type="text"
                      class="form-control"
                      id="stateRegistration"
                      formControlName="stateRegistration"
                      placeholder="Inscrição Estadual"
                    />
                    <span
                      *ngIf="
                        covenantForm.controls.stateRegistration.errors &&
                        covenantForm.controls.stateRegistration.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.stateRegistration.errors &&
                        covenantForm.controls.stateRegistration.errors.maxlength
                      "
                      class="text-danger position-right"
                      >*Inscrição Estadual deve ter no máximo 30
                      caracteres</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.stateRegistration.errors &&
                        covenantForm.controls.stateRegistration.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{
                        covenantForm.controls.stateRegistration.errors.invalid
                      }}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Inscrição Municipal</label>
                    <input
                      type="text"
                      class="form-control"
                      id="municipalRegistration"
                      formControlName="municipalRegistration"
                      placeholder="Inscrição Municipal"
                    />
                    <span
                      *ngIf="
                        covenantForm.controls.municipalRegistration.errors &&
                        covenantForm.controls.municipalRegistration.errors
                          .required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.municipalRegistration.errors &&
                        covenantForm.controls.municipalRegistration.errors
                          .maxlength
                      "
                      class="text-danger position-right"
                      >*Inscrição Municipal deve ter no máximo 30
                      caracteres</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.municipalRegistration.errors &&
                        covenantForm.controls.municipalRegistration.errors
                          .invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{
                        covenantForm.controls.municipalRegistration.errors
                          .invalid
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Telefone</label>
                    <input
                      type="text"
                      class="form-control"
                      mask="(00) 0000-0000||(00) 0 0000-0000"
                      id="phone"
                      formControlName="phone"
                      placeholder="Telefone"
                    />
                    <span
                      *ngIf="
                        covenantForm.controls.phone.errors &&
                        covenantForm.controls.phone.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.phone.errors &&
                        covenantForm.controls.phone.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ covenantForm.controls.phone.errors.invalid }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">WhatsApp</label>
                    <input
                      type="text"
                      class="form-control"
                      mask="+00 00 0 0000-0000"
                      id="whatsapp"
                      formControlName="whatsapp"
                      placeholder="WhatsApp"
                    />
                    <span
                      *ngIf="
                        covenantForm.controls.whatsapp.errors &&
                        covenantForm.controls.whatsapp.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.whatsapp.errors &&
                        covenantForm.controls.whatsapp.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ covenantForm.controls.whatsapp.errors.invalid }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Contato</label>
                    <input
                      type="text"
                      class="form-control"
                      id="contact"
                      formControlName="contact"
                      placeholder="Contato"
                    />
                    <span
                      *ngIf="
                        covenantForm.controls.contact.errors &&
                        covenantForm.controls.contact.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.contact.errors &&
                        covenantForm.controls.contact.errors.maxlength
                      "
                      class="text-danger position-right"
                      >*Contato deve ter no máximo 100 caracteres</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.contact.errors &&
                        covenantForm.controls.contact.errors.invalid
                      "
                      class="text-danger position-right"
                      >* {{ covenantForm.controls.contact.errors.invalid }}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">E-mail Contato</label>
                    <input
                      type="text"
                      class="form-control"
                      id="emailContact"
                      formControlName="emailContact"
                      placeholder="E-mail Contato"
                    />
                    <span *ngIf="covenantForm.controls.emailContact.errors">
                      <span
                        *ngIf="
                          covenantForm.controls.emailContact.errors.required
                        "
                        class="text-danger position-right"
                        >*Campo Obrigatório</span
                      >
                      <span
                        *ngIf="
                          !covenantForm.controls.emailContact.errors.required &&
                          covenantForm.controls.emailContact.errors.maxlength
                        "
                        class="text-danger position-right"
                        >*Email deve ter no máximo 80 caracteres</span
                      >
                      <span
                        *ngIf="
                          !covenantForm.controls.emailContact.errors
                            .maxlength &&
                          covenantForm.controls.emailContact.errors.email
                        "
                        class="text-danger position-right"
                        >*Não é um email válido!</span
                      >
                      <span
                        *ngIf="
                          !covenantForm.controls.emailContact.errors.email &&
                          covenantForm.controls.emailContact.errors.invalid
                        "
                        class="text-danger position-right"
                        >*
                        {{ covenantForm.controls.emailContact.errors.invalid }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Forma de Pagamento</label>
                    <select
                      (change)="onSelectedPaymentValue($event)"
                      formControlName="paymentMethods"
                      class="custom-select custom-select-md"
                      multiple
                    >
                      <option
                        *ngFor="let paymentMethod of covenantPaymentMethods"
                        value="{{ paymentMethod.id }}"
                      >
                        {{ paymentMethod.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="shareRun"
                      >Tempo máximo para Compartilhar a corrida (Hrs)</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="shareRun"
                      formControlName="shareRun"
                      placeholder="Tempo máximo para Compartilhar a corrida em Horas"
                    />
                    <div *ngIf="covenantForm.controls.shareRun.errors">
                      <span
                        *ngIf="covenantForm.controls.shareRun.errors.required"
                        class="text-danger position-right"
                        >*Campo Obrigatório</span
                      >
                      <span
                        *ngIf="covenantForm.controls.shareRun.errors.invalid"
                        class="text-danger position-right"
                        >*
                        {{
                          covenantForm.controls.shareRun.errors.invalid
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="invoice">Emitir Nota Fiscal</label>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="invoiceFalse"
                        [value]="false"
                        name="invoice"
                        formControlName="invoice"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="invoiceFalse"
                        >Não</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="invoiceTrue"
                        [value]="true"
                        name="invoice"
                        formControlName="invoice"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="invoiceTrue"
                        >Sim</label
                      >
                    </div>
                    <div *ngIf="covenantForm.controls.invoice.errors">
                      <span
                        *ngIf="covenantForm.controls.invoice.errors.required"
                        class="text-danger position-right"
                        >*Campo Obrigatório</span
                      >
                      <span
                        *ngIf="covenantForm.controls.invoice.errors.invalid"
                        class="text-danger position-right"
                        >*
                        {{ covenantForm.controls.invoice.errors.invalid }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="active">Status</label>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="activeFalse"
                        [value]="false"
                        name="active"
                        formControlName="active"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="activeFalse"
                        >Inativo</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="activeTrue"
                        [value]="true"
                        name="active"
                        formControlName="active"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="activeTrue"
                        >Ativo</label
                      >
                    </div>
                    <div *ngIf="covenantForm.controls.active.errors">
                      <span
                        *ngIf="covenantForm.controls.active.errors.required"
                        class="text-danger position-right"
                        >*Campo Obrigatório</span
                      >
                      <span
                        *ngIf="covenantForm.controls.active.errors.invalid"
                        class="text-danger position-right"
                        >*
                        {{ covenantForm.controls.active.errors.invalid }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="zipCode">CEP</label>
                    <input
                      type="text"
                      class="form-control cep"
                      (blur)="onBlurCep($event)"
                      id="zipCode"
                      formControlName="zipCode"
                      placeholder="CEP"
                    />

                    <span
                      *ngIf="
                        covenantForm.controls.zipCode.errors &&
                        covenantForm.controls.zipCode.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.zipCode.errors &&
                        covenantForm.controls.zipCode.errors.maxlength
                      "
                      class="text-danger position-right"
                      >*CEP deve ter no máximo 11 caracteres</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.zipCode.errors &&
                        covenantForm.controls.zipCode.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ covenantForm.controls.zipCode.errors.invalid }}
                    </span>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="publicPlace">Logradouro</label>
                    <input
                      type="text"
                      class="form-control"
                      id="publicPlace"
                      formControlName="publicPlace"
                      placeholder="Rua / Complemento / Número"
                    />
                    <span
                      *ngIf="
                        covenantForm.controls.publicPlace.errors &&
                        covenantForm.controls.publicPlace.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.publicPlace.errors &&
                        covenantForm.controls.publicPlace.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ covenantForm.controls.publicPlace.errors.invalid }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="neighborhood">Bairro</label>
                    <input
                      type="text"
                      class="form-control"
                      id="neighborhood"
                      formControlName="neighborhood"
                      placeholder="Bairro"
                    />

                    <span
                      *ngIf="
                        covenantForm.controls.neighborhood.errors &&
                        covenantForm.controls.neighborhood.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                    <span
                      *ngIf="
                        covenantForm.controls.neighborhood.errors &&
                        covenantForm.controls.neighborhood.errors.invalid
                      "
                      class="text-danger position-right"
                      >*
                      {{ covenantForm.controls.neighborhood.errors.invalid }}
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Estado </label>

                    <select
                      (change)="onSelectedState($event)"
                      class="form-control select2"
                      formControlName="state"
                    >
                      <option value="">Selecione o Estado</option>
                      <option
                        value="{{ state.id }}"
                        *ngFor="let state of states"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                    <span
                      *ngIf="
                        covenantForm.controls.state.errors &&
                        covenantForm.controls.state.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">Cidade </label>
                    <ng-select
                      class="custom-select"
                      style="
                        font-size: 1rem;
                        font-weight: normal;
                        min-width: 230px;
                      "
                      notFoundText="Nenhum item encontrado"
                      placeholder="Cidade"
                      formControlName="city"
                      [items]="cities"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                    <span
                      *ngIf="
                        covenantForm.controls.city.errors &&
                        covenantForm.controls.city.errors.required
                      "
                      class="text-danger position-right"
                      >*Campo Obrigatório</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div class="col-md-3"></div>
            <div class="col-md-3 text-right">
              <button
                (click)="onSubmit()"
                [disabled]="!covenantForm.valid"
                class="btn btn-success"
              >
                Salvar
              </button>
            </div>

            <div class="col-md-3">
              <a routerLink="/maintenance/covenant" class="btn btn-danger"
                >Cancelar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
