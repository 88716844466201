<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>

<div class="page-content-wrapper">
  <div class="page-content">
    <div class="page-bar">
      <div class="page-title-breadcrumb">
        <div class="container">
          <div class="row bg-white">
            <div class="col-12 d-flex justify-content-between">
              <div class="page-title pt-4 pl-3 mb-5">
                Visualizar Conta Bancária
              </div>
            </div>

            <div class="row col-12 m-0 viewBank">
              <div class="col-12 col-lg-6 mt-4">
                <b>Banco</b>
                <p>{{bankAccount?.bank.name}}</p>
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <b>Agência</b>
                <p>{{bankAccount?.agency}}</p>
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <b>Conta Bancária</b>
                <p>{{bankAccount?.bankAccount}}</p>
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <b>Tipo</b>
                <p>{{bankAccount?.type}}</p>
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <b>Recebe pagamento?</b>
                <p>{{bankAccount?.receivePayments ? 'Sim': 'Não'}}</p>
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <b>Status</b>
                <p>{{bankAccount?.active ? 'Ativo': 'Inativo'}}</p>
              </div>
            </div>
            <div class="row col-12 m-0 p-0 d-flex justify-content-center my-5">
              <div class="m-2">
                <a class="btn btn-success" routerLink="/financial/account-bank/edit/{{id}}">Editar</a>
              </div>
              <div class="m-2">
                <a routerLink="/financial/account-bank" class="btn btn-danger">Cancelar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
