import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pagination } from 'src/app/domain/pagination';
import { Collaborator } from 'src/app/domain/collaborator';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CovenantCollaboratorService } from 'src/app/service/covenant-collaborator/covenant-collaborator.service';
import { ViaCep } from 'src/app/domain/viacep';
import { State } from 'src/app/domain/state';
import { City } from 'src/app/domain/city';
import { UtilsService } from 'src/app/service/utils/utils.service';
import { AddressService } from 'src/app/service/address/address.service';
import { Address } from 'src/app/domain/address';
import { Validations } from '../../validations';
import moment from 'moment';

@Component({
  selector: 'app-covenant-collaborators',
  templateUrl: './covenant-collaborators.component.html',
  styleUrls: ['./covenant-collaborators.component.css'],
})
export class CovenantCollaboratorsComponent implements OnInit {
  covenantCollaboratorForm: FormGroup;
  searchForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  pagination: Pagination = new Pagination();
  covenantId: number;
  collaborators: Array<Collaborator> = [];
  isEditingId: number = null;
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];
  files: File[] = [];
  collaborator: Collaborator = new Collaborator();
  first: boolean = false;
  last: boolean = false;

  @ViewChild('selectState') selectState: ElementRef<HTMLElement>;

  constructor(
    private service: CovenantCollaboratorService,
    private fb: FormBuilder,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.covenantId = params['id'];
    });

    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getCollaborators();

    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  onRemoveFile(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onSelectFile(event) {
    this.files.push(...event.addedFiles);
  }

  onImportFile() {}

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            this.viacep = data;

            if (!this.viacep.erro) {
              this.covenantCollaboratorForm.controls.neighborhood.setValue(
                this.viacep.bairro
              );
              this.covenantCollaboratorForm.controls.publicPlace.setValue(
                this.viacep.logradouro
              );

              this.covenantCollaboratorForm.controls.state.setValue('');
              this.covenantCollaboratorForm.controls.city.setValue('');
              const state = this.states.find(
                (state) => state.initials === this.viacep.uf.toUpperCase()
              );

              this.covenantCollaboratorForm.controls.state.setValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      this.viacep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );

                  if (city) {
                    this.covenantCollaboratorForm.controls.city.setValue(
                      city.id
                    );
                  }
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
            } else {
              this.covenantCollaboratorForm.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.covenantCollaboratorForm.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.covenantCollaboratorForm.controls.zipCode.setErrors({
        invalid: 'CEP inválido!',
      });
    }
  }

  onSelectedState(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  // ---------------- FUNÇÕES DE LISTAGEM DE COLABORADORES --------------------
  getCollaborators() {
    this.loading = true;

    this.service
      .getCollaborators(this.covenantId, this.pagination, this.collaborator)
      .subscribe(
        (data) => {
          this.collaborators = data['results'];
          this.setPagination(data);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.openAlertError();
        }
      );
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire(
      'Ops...',
      'Ocorreu um erro ao carregar os colaboradores!',
      'error'
    );
  }

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE COLABORADORES --------------------
  createForm() {
    this.covenantCollaboratorForm = this.fb.group({
      name: ['', [Validators.maxLength(100), Validators.required]],
      email: [''],
      role: ['', [Validators.maxLength(100)]],
      whatsapp: ['', [Validations.validwhatsapp, Validators.required]],
      department: ['', [Validators.maxLength(100)]],
      costCenter: ['', [Validators.maxLength(100)]],
      zipCode: ['', [Validators.maxLength(9)]],
      publicPlace: ['', [Validators.maxLength(100)]],
      neighborhood: ['', []],
      city: ['', []],
      state: ['', []],
      requestAppRace: ['', [Validators.required]],
    });

    this.isEditingId = null;

    this.searchForm = this.fb.group({
      name: [this.collaborator.name],
      role: [this.collaborator.role],
      active: [this.collaborator.active],
      whatsapp: [this.collaborator.whatsapp],
    });
  }

  onSubmit() {
    const values = this.covenantCollaboratorForm.controls;

    let covenantCollaborator = new Collaborator();
    covenantCollaborator.address = new Address();

    if (this.isEditingId) {
      covenantCollaborator.id = this.isEditingId;
    }

    covenantCollaborator.address.city = new City();
    covenantCollaborator.name = values.name.value;
    covenantCollaborator.role = values.role.value;
    covenantCollaborator.email = values.email.value;
    covenantCollaborator.whatsapp = values.whatsapp.value;
    covenantCollaborator.department = values.department.value;
    covenantCollaborator.costCenter = values.costCenter.value;
    covenantCollaborator.requestAppRace = values.requestAppRace.value;
    covenantCollaborator.address.zipCode = values.zipCode.value.replace(
      /[^0-9,.]+/g,
      ''
    );
    covenantCollaborator.address.publicPlace = values.publicPlace.value;
    covenantCollaborator.address.neighborhood = values.neighborhood.value;
    covenantCollaborator.address.city.id = Number(values.city.value);

    switch (values.requestAppRace.value) {
      case 'true': {
        covenantCollaborator.requestAppRace = true;
        break;
      }
      case 'false': {
        covenantCollaborator.requestAppRace = false;
        break;
      }
      default: {
        covenantCollaborator.requestAppRace = null;
        break;
      }
    }

    if (!this.isEditingId) {
      this.loading = true;
      this.service.save(this.covenantId, covenantCollaborator).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire('Salvo', 'Colaborador salvo com sucesso!!', 'success');
          this.getCollaborators();
          this.createForm();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);

          const isActiveInAnotherCovenant = error.error?.errors?.find(
            (item) => item.fieldName === 'isActiveInAnotherCovenant'
          );

          const activeCollaboratorId = error.error?.errors?.find(
            (item) => item.fieldName === 'activeCollaboratorId'
          );

          if (isActiveInAnotherCovenant && activeCollaboratorId) {
            Swal.fire({
              title: 'Colaborador ativo em outro convênio',
              showCancelButton: true,
              confirmButtonText: 'Sim, inativar e cadastrar novamente',
              showLoaderOnConfirm: true,
              text: isActiveInAnotherCovenant.message,
              allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
              if (result.isConfirmed) {
                this.loading = true;
                this.service
                  .deactiveColaborattor(
                    this.covenantId,
                    activeCollaboratorId.message
                  )
                  .subscribe(
                    (data) => this.onSubmit(),
                    (error) => {
                      this.loading = false;
                      Swal.fire(
                        'Erro ao salvar',
                        'Não foi possível salvar Colaborador!',
                        'error'
                      );
                    }
                  );
              }
            });
          } else {
            Swal.fire(
              'Erro ao salvar',
              'Não foi possível salvar Colaborador!',
              'error'
            );
          }
        }
      );
    } else {
      this.loading = true;
      this.service.update(this.covenantId, covenantCollaborator).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire('Salvo', 'Colaborador salvo com sucesso!', 'success');
          this.getCollaborators();
          this.createForm();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Colaborador!',
            'error'
          );
        }
      );
    }
  }

  onCollaboratorEdit(covenantCollaborator) {
    this.isEditingId = covenantCollaborator.id;
    this.covenantCollaboratorForm.controls.name.setValue(
      covenantCollaborator.name
    );
    this.covenantCollaboratorForm.controls.email.setValue(
      covenantCollaborator.email
    );
    this.covenantCollaboratorForm.controls.role.setValue(
      covenantCollaborator.role
    );
    this.covenantCollaboratorForm.controls.whatsapp.setValue(
      covenantCollaborator.whatsapp
    );
    this.covenantCollaboratorForm.controls.department.setValue(
      covenantCollaborator.department
    );
    this.covenantCollaboratorForm.controls.costCenter.setValue(
      covenantCollaborator.costCenter
    );
    this.covenantCollaboratorForm.controls.requestAppRace.setValue(
      covenantCollaborator.requestAppRace.toString()
    );
    this.covenantCollaboratorForm.controls.zipCode.setValue(
      covenantCollaborator.address.zipCode
    );
    this.covenantCollaboratorForm.controls.publicPlace.setValue(
      covenantCollaborator.address.publicPlace
    );
    this.covenantCollaboratorForm.controls.neighborhood.setValue(
      covenantCollaborator.address.neighborhood
    );
    this.covenantCollaboratorForm.controls.city.setValue(
      covenantCollaborator.address.city.id
    );
    this.covenantCollaboratorForm.controls.state.setValue(
      covenantCollaborator.address.city.state.id
    );

    const el: HTMLElement = this.selectState.nativeElement;
    const event: Event = new Event('change');
    el.dispatchEvent(event);
  }

  // onCollaboratorDelete(covenantCollaborator) {
  //   this.service.delete(this.covenantId, covenantCollaborator).subscribe(data => {
  //     this.loading = false;
  //     Swal.fire('Excluido!', 'Colaborador excluído com sucesso!!', 'success');
  //     this.getCollaborators();
  //     this.createForm();
  //   },
  //     error => {
  //       console.log(error);
  //       this.loading = false;
  //       this.setErrors(error.error.errors);
  //       Swal.fire('Erro ao excluir', 'Não foi possível excluir Colaborador!', 'error');
  //     });
  // }

  onActive(colaborattor) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Colaborador ${colaborattor.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.loading = true;
        this.service
          .activeColaborattor(this.covenantId, colaborattor.id)
          .subscribe(
            (data) => {
              this.loading = false;
              Swal.fire({
                title: 'Ativado',
                text: `Colaborador ativado com sucesso!`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              }).then((result) => {
                location.reload();
              });
            },
            (error) => {
              console.log(error)
              this.loading = false;
              Swal.fire('Erro ao ativar Colaborador!', error?.error?.errors?.map(item => item.message)?.join('\n'), 'error');
            }
          );
      }
    });
  }
  onDeActive(colaborattor) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Colaborador ${colaborattor.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.service
          .deactiveColaborattor(this.covenantId, colaborattor.id)
          .subscribe(
            (data) => {
              this.loading = false;
              Swal.fire({
                title: 'Desativado',
                text: `Colaborador desativado com sucesso!`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              }).then((result) => {
                location.reload();
              });
            },
            (error) => {
              this.loading = false;
              Swal.fire(
                'Erro ao desativar Perfil de Acesso!',
                `${error.error.errors[0].message}`,
                'error'
              );
            }
          );
      }
    });
  }

  resetForm() {
    this.covenantCollaboratorForm.controls.name.setValue('');
    this.covenantCollaboratorForm.controls.role.setValue('');
    this.covenantCollaboratorForm.controls.whatsapp.setValue('');
    this.covenantCollaboratorForm.controls.department.setValue('');
    this.covenantCollaboratorForm.controls.costCenter.setValue('');
    this.covenantCollaboratorForm.controls.zipCode.setValue('');
    this.covenantCollaboratorForm.controls.publicPlace.setValue('');
    this.covenantCollaboratorForm.controls.neighborhood.setValue('');
    this.covenantCollaboratorForm.controls.city.setValue('');
    this.covenantCollaboratorForm.controls.state.setValue('');
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'name':
          this.covenantCollaboratorForm.controls.name.setErrors({
            invalid: erro.message,
          });
          break;
        case 'email':
          this.covenantCollaboratorForm.controls.email.setErrors({
            invalid: erro.message,
          });
          break;
        case 'role':
          this.covenantCollaboratorForm.controls.role.setErrors({
            invalid: erro.message,
          });
          break;
        case 'whatsapp':
          this.covenantCollaboratorForm.controls.whatsapp.setErrors({
            invalid: erro.message,
          });
          break;
        case 'department':
          this.covenantCollaboratorForm.controls.department.setErrors({
            invalid: erro.message,
          });
          break;
        case 'costCenter':
          this.covenantCollaboratorForm.controls.costCenter.setErrors({
            invalid: erro.message,
          });
          break;
        case 'zipCode':
          this.covenantCollaboratorForm.controls.zipCode.setErrors({
            invalid: erro.message,
          });
          break;
        case 'publicPlace':
          this.covenantCollaboratorForm.controls.publicPlace.setErrors({
            invalid: erro.message,
          });
          break;
        case 'neighborhood':
          this.covenantCollaboratorForm.controls.neighborhood.setErrors({
            invalid: erro.message,
          });
          break;
        case 'state':
          this.covenantCollaboratorForm.controls.state.setErrors({
            invalid: erro.message,
          });
          break;
        case 'city':
          this.covenantCollaboratorForm.controls.city.setErrors({
            invalid: erro.message,
          });
          break;
        case 'requestAppRace':
          this.covenantCollaboratorForm.controls.requestAppRace.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }
  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(
      [`/maintenance/covenant/collaborators/${this.covenantId}`],
      {
        queryParams: {
          page: this.pagination.page ?? 1,
          per_page: this.pagination.per_page ?? 10,
        },
      }
    );

    this.getCollaborators();
  }

  onSearch() {
    this.collaborator.name = this.searchForm.controls.name.value;
    this.collaborator.role = this.searchForm.controls.role.value;
    this.collaborator.whatsapp = this.searchForm.controls.whatsapp.value;

    switch (this.searchForm.controls.active.value) {
      case 'true':
        this.collaborator.active = true;
        break;
      case 'false':
        this.collaborator.active = false;
        break;
      default:
        this.collaborator.active = null;
        break;
    }

    this.setNavigate();
  }

  exportCollaborators(type: string) {
    this.loading = true;

    this.service
      .exportCollaborators(this.covenantId, type, this.collaborator)
      .subscribe(
        (response) => {
          const fileName = `Relatorio_Collaboradores_${moment().format(
            'DDMMyyyy'
          )}.${type == 'excel' ? 'xlsx' : 'pdf'}`;
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(response.body);
          link.download = fileName;
          link.click();

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
        }
      );
  }

  resetSearchForm() {
    this.searchForm.controls.name.setValue('');
    this.searchForm.controls.role.setValue('');
    this.searchForm.controls.active.setValue(null);
  }
}
