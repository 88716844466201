import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/components/validations';
import { Address } from 'src/app/domain/address';
import { City } from 'src/app/domain/city';
import { Collaborator } from 'src/app/domain/collaborator';
import { Pagination } from 'src/app/domain/pagination';
import { State } from 'src/app/domain/state';
import { ViaCep } from 'src/app/domain/viacep';
import { AddressService } from 'src/app/service/address/address.service';
import { CovenantWorkerService } from 'src/app/service/covenant-worker/covenant-worker.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-covenant-worker',
  templateUrl: './covenant-worker.component.html',
  styleUrls: ['./covenant-worker.component.css'],
})
export class CovenantWorkerComponent implements OnInit {
  covenantWorkerForm: FormGroup;
  searchForm: FormGroup;
  loading: boolean = false;
  messageError: string = '';
  pagination: Pagination = new Pagination();
  covenantId: number;
  collaborators: Array<Collaborator> = [];
  collaborator: Collaborator = new Collaborator();
  isEditingId: number = null;
  viacep: ViaCep = new ViaCep();
  states: Array<State> = [];
  cities: Array<City> = [];
  files: File[] = [];

  first: boolean = false;
  last: boolean = false;

  @ViewChild('selectState') selectState: ElementRef<HTMLElement>;

  constructor(
    private service: CovenantWorkerService,
    private fb: FormBuilder,
    private serviceAddress: AddressService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.covenantId = params['id'];
    });

    this.route.queryParams.subscribe((params) => {
      this.pagination.per_page = params.per_page || 10;
      this.pagination.page = params.page || 1;
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.getCollaborators();

    this.serviceAddress.getStates().subscribe(
      (data) => {
        this.states = data;
      },
      (error) => {
        Swal.fire('Erro', 'Não foi possível Buscar os Estados!', 'error');
      }
    );
  }

  onBlurCep(event) {
    // event.target.value
    this.loading = true;

    if (event.target.value.replace(/[^0-9,.]+/g, '').length === 8) {
      this.utilsService
        .getCep(event.target.value.replace(/[^0-9,.]+/g, ''))
        .subscribe(
          (data) => {
            this.viacep = data;

            if (!this.viacep.erro) {
              this.covenantWorkerForm.controls.neighborhood.setValue(
                this.viacep.bairro
              );
              this.covenantWorkerForm.controls.publicPlace.setValue(
                this.viacep.logradouro
              );
              const state = this.states.find(
                (state) => state.initials === this.viacep.uf.toUpperCase()
              );

              this.covenantWorkerForm.controls.state.setValue(state.id);

              // TODO - Implementar busca de cidades por UF
              this.serviceAddress.getCitiesByStateId(state.id).subscribe(
                (data) => {
                  this.cities = data;
                  const city = this.cities.find(
                    (elem) =>
                      elem.name.normalize('NFD') ===
                      this.viacep.localidade
                        .toUpperCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                  );
                  if (city) {
                    this.covenantWorkerForm.controls.city.setValue(city.id);
                  }
                },
                (error) => {
                  Swal.fire(
                    'Erro ao salvar',
                    'Erro ao buscar cidades!',
                    'error'
                  );
                }
              );
            } else {
              this.covenantWorkerForm.controls.zipCode.setErrors({
                invalid: 'CEP não encontrado!',
              });
            }
            this.loading = false;
          },
          (erro) => {
            this.covenantWorkerForm.controls.zipCode.setErrors({
              invalid: 'CEP não encontrado!',
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.covenantWorkerForm.controls.zipCode.setErrors({
        invalid: 'CEP inválido!',
      });
    }
  }

  onSelectedState(event) {
    if (event.target.value) {
      this.loading = true;
      this.serviceAddress.getCitiesByStateId(event.target.value).subscribe(
        (data) => {
          this.cities = data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire('Ops', 'Erro ao buscar cidades!', 'error');
        }
      );
    }
  }

  // ---------------- FUNÇÕES DE LISTAGEM DE COLABORADORES --------------------
  getCollaborators() {
    this.loading = true;
    this.service.getCollaborators(this.pagination, this.collaborator).subscribe(
      (data) => {
        this.collaborators = data['results'];
        this.setPagination(data);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openAlertError();
      }
    );
  }

  onActive(colaborattor) {
    Swal.fire({
      title: 'Atenção!',
      text: `Ativar Colaborador ${colaborattor.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero ativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.loading = true;
        this.service
          .activeColaborattor(this.covenantId, colaborattor.id)
          .subscribe(
            (data) => {
              this.loading = false;
              Swal.fire({
                title: 'Ativado',
                text: `Colaborador ativado com sucesso!`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              }).then((result) => {
                location.reload();
              });
            },
            (error) => {
              this.loading = false;
              Swal.fire('Ops', 'Erro ao ativar Colaborador!', 'error');
            }
          );
      }
    });
  }
  onDeActive(colaborattor) {
    Swal.fire({
      title: 'Atenção!',
      text: `Desativar Colaborador ${colaborattor.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, quero desativar!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.service
          .deactiveColaborattor(this.covenantId, colaborattor.id)
          .subscribe(
            (data) => {
              this.loading = false;
              Swal.fire({
                title: 'Desativado',
                text: `Colaborador desativado com sucesso!`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
              }).then((result) => {
                location.reload();
              });
            },
            (error) => {
              this.loading = false;
              Swal.fire(
                'Erro ao desativar Perfil de Acesso!',
                `${error.error.errors[0].message}`,
                'error'
              );
            }
          );
      }
    });
  }

  setPagination(data) {
    this.pagination.page = data['page'];
    this.pagination.showingResults = data['showingResults'];
    this.pagination.totalPages = data['totalPages'];
    this.pagination.totalResults = data['totalResults'];

    data['page'] == 1 ? (this.first = false) : (this.first = true);
    data['totalPages'] <= this.pagination.page
      ? (this.last = false)
      : (this.last = true);
  }

  openAlertError() {
    Swal.fire(
      'Ops...',
      'Ocorreu um erro ao carregar os colaboradores!',
      'error'
    );
  }
  // ---------------- FUNÇÕES DE LISTAGEM DE COLABORADORES --------------------

  // ---------------- FUNÇÕES DE CADASTRO e EDIÇÃO DE COLABORADORES --------------------
  createForm() {
    this.covenantWorkerForm = this.fb.group({
      name: ['', [Validators.maxLength(100)]],
      role: ['', [Validators.maxLength(100)]],
      email: ['', [Validators.email]],
      whatsapp: ['', [Validations.validwhatsapp]],
      department: ['', [Validators.maxLength(100)]],
      costCenter: ['', [Validators.maxLength(100)]],
      zipCode: ['', [Validators.required, Validators.maxLength(9)]],
      publicPlace: ['', [Validators.required, Validators.maxLength(100)]],
      neighborhood: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      requestAppRace: ['', [Validators.required]],
    });
    this.isEditingId = null;

    this.searchForm = this.fb.group({
      name: [this.collaborator.name],
      role: [this.collaborator.role],
      active: [this.collaborator.active],
      whatsapp: [this.collaborator.whatsapp],
    });
  }

  onSubmit() {
    const values = this.covenantWorkerForm.controls;
    let covenantCollaborator = new Collaborator();
    if (this.isEditingId) {
      covenantCollaborator.id = this.isEditingId;
    }
    covenantCollaborator.address = new Address();
    covenantCollaborator.address.city = new City();
    covenantCollaborator.name = values.name.value;
    covenantCollaborator.role = values.role.value;
    covenantCollaborator.email = values.email.value;
    covenantCollaborator.whatsapp = values.whatsapp.value;
    covenantCollaborator.department = values.department.value;
    covenantCollaborator.requestAppRace = values.requestAppRace.value;

    covenantCollaborator.costCenter = values.costCenter.value;
    covenantCollaborator.address.zipCode = values.zipCode.value.replace(
      /[^0-9,.]+/g,
      ''
    );
    covenantCollaborator.address.publicPlace = values.publicPlace.value;
    covenantCollaborator.address.neighborhood = values.neighborhood.value;
    covenantCollaborator.address.city.id = Number(values.city.value);

    if (!this.isEditingId) {
      this.loading = true;
      this.service.save(covenantCollaborator).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire('Salvo', 'Colaborador salvo com sucesso!!', 'success');
          this.getCollaborators();
          this.createForm();
        },
        (error) => {
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Colaborador!',
            'error'
          );
        }
      );
    } else {
      this.loading = true;
      this.service.update(covenantCollaborator).subscribe(
        (data) => {
          this.loading = false;
          Swal.fire('Salvo', 'Colaborador salvo com sucesso!', 'success');
          this.getCollaborators();
          this.createForm();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setErrors(error.error.errors);
          Swal.fire(
            'Erro ao salvar',
            'Não foi possível salvar Colaborador!',
            'error'
          );
        }
      );
    }
  }

  onCollaboratorEdit(covenantCollaborator) {
    this.isEditingId = covenantCollaborator.id;
    this.covenantWorkerForm.controls.name.setValue(covenantCollaborator.name);
    this.covenantWorkerForm.controls.email.setValue(covenantCollaborator.email);
    this.covenantWorkerForm.controls.role.setValue(covenantCollaborator.role);
    this.covenantWorkerForm.controls.whatsapp.setValue(
      covenantCollaborator.whatsapp
    );
    this.covenantWorkerForm.controls.department.setValue(
      covenantCollaborator.department
    );
    this.covenantWorkerForm.controls.costCenter.setValue(
      covenantCollaborator.costCenter
    );
    this.covenantWorkerForm.controls.requestAppRace.setValue(
      covenantCollaborator.requestAppRace.toString()
    );

    this.covenantWorkerForm.controls.zipCode.setValue(
      covenantCollaborator.address.zipCode
    );
    this.covenantWorkerForm.controls.publicPlace.setValue(
      covenantCollaborator.address.publicPlace
    );
    this.covenantWorkerForm.controls.neighborhood.setValue(
      covenantCollaborator.address.neighborhood
    );
    this.covenantWorkerForm.controls.city.setValue(
      covenantCollaborator.address.city.id
    );
    this.covenantWorkerForm.controls.state.setValue(
      covenantCollaborator.address.city.state.id
    );

    const el: HTMLElement = this.selectState.nativeElement;
    const event: Event = new Event('change');
    el.dispatchEvent(event);
  }

  onCollaboratorDelete(covenantCollaborator) {
    this.service.delete(covenantCollaborator).subscribe(
      (data) => {
        this.loading = false;
        Swal.fire('Excluido!', 'Colaborador excluído com sucesso!!', 'success');
        this.getCollaborators();
        this.createForm();
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.setErrors(error.error.errors);
        Swal.fire(
          'Erro ao excluir',
          'Não foi possível excluir Colaborador!',
          'error'
        );
      }
    );
  }

  resetForm() {
    this.covenantWorkerForm.controls.name.setValue('');
    this.covenantWorkerForm.controls.role.setValue('');
    this.covenantWorkerForm.controls.whatsapp.setValue('');
    this.covenantWorkerForm.controls.department.setValue('');
    this.covenantWorkerForm.controls.costCenter.setValue({});
    this.covenantWorkerForm.controls.zipCode.setValue('');
    this.covenantWorkerForm.controls.publicPlace.setValue('');
    this.covenantWorkerForm.controls.neighborhood.setValue('');
    this.covenantWorkerForm.controls.city.setValue('');
    this.covenantWorkerForm.controls.state.setValue('');
  }

  exportCollaborators(type: string) {
    this.loading = true;

    this.service.exportCollaborators(type, this.collaborator).subscribe(
      (response) => {
        const fileName = `Relatorio_Collaboradores_${moment().format(
          'DDMMyyyy'
        )}.${type == 'excel' ? '.xlsx' : 'pdf'}`;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(response.body);
        link.download = fileName;
        link.click();

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire('Ops...', 'Ocorreu um erro ao carregar os dados!', 'error');
      }
    );
  }

  resendActivationInstruction(collaborator: Collaborator) {
    this.service.resendActivationInstruction(collaborator.id).subscribe(
      (data) => {
        Swal.fire({
          title: 'Instruções de ativação',
          text: `Instruções de ativação foram reenviadas para o colaborador!`,
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      },
      (error) => {
        Swal.fire(
          'Ops',
          'Erro ao reenviar instruções de ativação ao colaborador!',
          'error'
        );
      }
    );
  }

  setErrors(errors) {
    errors.forEach((erro) => {
      switch (erro.fieldName) {
        case 'name':
          this.covenantWorkerForm.controls.name.setErrors({
            invalid: erro.message,
          });
          break;
        case 'email':
          this.covenantWorkerForm.controls.email.setErrors({
            invalid: erro.message,
          });
          break;
        case 'role':
          this.covenantWorkerForm.controls.role.setErrors({
            invalid: erro.message,
          });
          break;
        case 'whatsapp':
          this.covenantWorkerForm.controls.whatsapp.setErrors({
            invalid: erro.message,
          });
          break;
        case 'department':
          this.covenantWorkerForm.controls.department.setErrors({
            invalid: erro.message,
          });
          break;
        case 'costCenter':
          this.covenantWorkerForm.controls.costCenter.setErrors({
            invalid: erro.message,
          });
          break;
        case 'zipCode':
          this.covenantWorkerForm.controls.zipCode.setErrors({
            invalid: erro.message,
          });
          break;
        case 'publicPlace':
          this.covenantWorkerForm.controls.publicPlace.setErrors({
            invalid: erro.message,
          });
          break;
        case 'neighborhood':
          this.covenantWorkerForm.controls.neighborhood.setErrors({
            invalid: erro.message,
          });
          break;
        case 'state':
          this.covenantWorkerForm.controls.state.setErrors({
            invalid: erro.message,
          });
          break;
        case 'city':
          this.covenantWorkerForm.controls.city.setErrors({
            invalid: erro.message,
          });
          break;
        default:
          this.messageError += `${erro.message}\n`;
      }
    });
  }

  onFirst() {
    this.pagination.page = 1;
    this.setNavigate();
  }
  onLast() {
    this.pagination.page = this.pagination.totalPages;
    this.setNavigate();
  }
  onPrevious() {
    this.pagination.page--;
    this.setNavigate();
  }
  onNext() {
    this.pagination.page++;
    this.setNavigate();
  }

  setNavigate() {
    this.router.navigate(['/convenio/workers'], {
      queryParams: {
        page: this.pagination.page ?? 1,
        per_page: this.pagination.per_page ?? 10,
      },
    });
    this.getCollaborators();
  }

  onSearch() {
    this.collaborator.name = this.searchForm.controls.name.value;
    this.collaborator.role = this.searchForm.controls.role.value;
    this.collaborator.whatsapp = this.searchForm.controls.whatsapp.value;

    switch (this.searchForm.controls.active.value) {
      case 'true':
        this.collaborator.active = true;
        break;
      case 'false':
        this.collaborator.active = false;
        break;
      default:
        this.collaborator.active = null;
        break;
    }

    this.setNavigate();
  }

  resetSearchForm() {
    this.searchForm.controls.name.setValue('');
    this.searchForm.controls.role.setValue('');
    this.searchForm.controls.active.setValue(null);
  }
}
